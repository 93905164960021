import values from 'lodash/fp/values';

export const STRING = 'STRING';
export const NUMERIC = 'NUMERIC';
export const ENUMERATION = 'ENUMERATION';
export const BYTE_ARRAY = 'BYTE_ARRAY';

export const sysParamTypes = {
    [STRING]: {
        id: STRING,
        value: STRING,
        label: 'String',
    },
    [NUMERIC]: {
        id: NUMERIC,
        value: NUMERIC,
        label: 'Numeric',
    },
    [ENUMERATION]: {
        id: ENUMERATION,
        value: ENUMERATION,
        label: 'Enumeration',
    },
    [BYTE_ARRAY]: {
        id: BYTE_ARRAY,
        value: BYTE_ARRAY,
        label: 'ByteArray',
    },
};

export const createSysParamTypesOptions = () => values(sysParamTypes);
