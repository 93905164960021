export const CHANGE_GROUP_EDITOR = 'CHANGE_GROUP_EDITOR';
export const FETCH_SERIAL_NUMBERS_AND_OPEN_EDITOR_OF_GROUP = 'FETCH_SERIAL_NUMBERS_AND_OPEN_EDITOR_OF_GROUP';
export const RESET_GROUP_EDITOR = 'RESET_GROUP_EDITOR';
export const SHOW_GROUP_EDITOR_DIALOG = 'SHOW_GROUP_EDITOR_DIALOG';
export const SHOW_GROUP_DELETION_DIALOG = 'SHOW_GROUP_DELETION_DIALOG';


export function showGroupEditorDialog(payload) {
    return {
        type: SHOW_GROUP_EDITOR_DIALOG,
        payload,
    };
}
export function showGroupDeletionDialog(payload) {
    return {
        type: SHOW_GROUP_DELETION_DIALOG,
        payload,
    };
}

export function resetGroupEditor() {
    return {
        type: RESET_GROUP_EDITOR,
    };
}

export function changeGroupEditor(payload) {
    return {
        type: CHANGE_GROUP_EDITOR,
        payload,
    };
}

export const fetchSerialNumbersAndOpenEditorOfGroup = (groupName) => {
    return {
        type: FETCH_SERIAL_NUMBERS_AND_OPEN_EDITOR_OF_GROUP,
        groupName,
    };
};


