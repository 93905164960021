import concat from 'lodash/fp/concat';
import getOr from 'lodash/fp/getOr';
import map from 'lodash/fp/map';

import { ALL } from '~/features/base/constants/serviceOptions';
import { NOTICE } from '~/features/deviceLogs/constants/logLevelOptions';

const getServiceLogs = getOr([], 'serviceLogs');
const getAllLogs = getOr([], 'allLogs');
const mapAllLogs = map(allLog => ({
    ...allLog,
    serviceId: ALL,
    logLevel: NOTICE,
}));

export const parseDeviceAction = deviceAction => {
    const serviceLogs = getServiceLogs(deviceAction);
    const allLogs = mapAllLogs(getAllLogs(deviceAction));
    return {
        ...deviceAction,
        allLogs,
        logs: concat(allLogs, serviceLogs),
    };
};

export const parseDeviceActions = deviceActions => map(parseDeviceAction, deviceActions);
