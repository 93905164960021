import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';

import { createSelector } from 'reselect';

import { ENTITY_SYS_PARAM_DEFAULT_VALUE } from '~/features/base/constants/entities';

import { entityStoreSelector } from '~/features/higherorder/selectors/dataSelectors';
import {
    shortBaseSwVersionOfDistroSelector,
    shortBaseSwVersionOfDeviceSelector,
} from '~/features/baseSwVersions/selectors/baseSwVersionSelectors';

const sysParamDefaultValueEntitiesSelector = state => entityStoreSelector(state)[ENTITY_SYS_PARAM_DEFAULT_VALUE];

export const sysParamDefaultValueSelector = (state, ownProps) => {
    const defaultValue = get([ownProps.shortBaseSwVersion, ownProps.accessKey],
        sysParamDefaultValueEntitiesSelector(state));
    return defaultValue;
};

export const sysParamDefaultValuesOfDistroSelector = createSelector(
    [sysParamDefaultValueEntitiesSelector, shortBaseSwVersionOfDistroSelector],
    (entities, shortBaseSwVersion) => {
        return getOr({}, shortBaseSwVersion, entities);
    }
);
export const sysParamDefaultValuesOfDeviceSelector = createSelector(
    [sysParamDefaultValueEntitiesSelector, shortBaseSwVersionOfDeviceSelector],
    (entities, shortBaseSwVersion) => {
        return getOr({}, shortBaseSwVersion, entities);
    }
);
