import includes from 'lodash/fp/includes';
import replace from 'lodash/fp/replace';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { FormattedMessage } from 'react-intl';

export const fixUrl = url => {
    const { host } = window.location;
    if (includes('admin-eu', host)) {
        return replace('{subdomain}', 'admin-eu', url);
    }
    if (includes('admin-latam', host)) {
        return replace('{subdomain}', 'admin-latam', url);
    }
    return url;
};

export const resetAllIntervals = () => {
    const highestTimeoutId = setTimeout(()=>{})
    for (let i = 0 ; i < highestTimeoutId ; i++) {
        clearTimeout(i);
    }
}

export const copyToClipboard = (text, showText) => {
    // create temporary element
    var el = document.createElement('textarea');
    // assign text
    el.value = text;
    // set element readonly
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    // select text
    el.select();
    // copy selected text
    document.execCommand('copy');
    // remove selected element
    document.body.removeChild(el);

    Notification.success(
        [<FormattedMessage id='intl-msg:copiedToClipboard'/>, <span className='padding-5'>{showText && text}</span>]);
};

export const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + '...' : str;
};
