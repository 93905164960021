import values from 'lodash/fp/values';
import { STRING, sysParamTypes } from './sysParamTypes';

export const UINT_8 = 'UINT_8';
export const UINT_16 = 'UINT_16';
export const UINT_32 = 'UINT_32';

export const numericTypes = {
    [UINT_8]: {
        id: UINT_8,
        value: UINT_8,
        label: 'uint8',
    },
    [UINT_16]: {
        id: UINT_16,
        value: UINT_16,
        label: 'uint16',
    },
    [UINT_32]: {
        id: UINT_32,
        value: UINT_32,
        label: 'uint32',
    },
};

export const createSysParamNumericTypesOptions = () => values(numericTypes);
export const createSysParamEnumerationTypesOptions = () => [
    sysParamTypes[STRING],
    ...createSysParamNumericTypesOptions(),
];
