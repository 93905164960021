import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';

import classNames from 'classnames';

import {
    createSysParamDefinition,
    updateSysParamDefinition,
} from '~/features/sysParams/actions/sysParamDefinitionActions';
import {
    changeSysParamDefinitionEditor,
} from '~/features/sysParams/actions/sysParamDefinitionEditorActions';

import { sysParamDefinitionEditorSelector } from '~/features/sysParams/selectors/sysParamDefinitionSelectors';

import {
    accessKeyHasDefault,
} from '~/features/sysParams/constants/sysParamGroups';

import {
    STRING,
    NUMERIC,
    ENUMERATION,
    BYTE_ARRAY,
} from '~/features/sysParams/constants/sysParamTypes';

import {
    toShortSemanticVersion,
} from '~/features/base/utils/versionNumberConverter';

import SysParamDefinitionEditorForm from '~/features/sysParams/components/SysParamDefinitionEditorForm';

/**
 * System parameter definition editor dialog
 */
export class SysParamDefinitionEditorDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.onSaveSysParamDefinition = this.onSaveSysParamDefinition.bind(this);
    }
    onSaveSysParamDefinition() {
        const model = this.props.model;
        if (!this.isValid(model)) {
            return;
        }
        const hasDefault = accessKeyHasDefault(model.accessKey);
        const shortBaseSwVersion = toShortSemanticVersion(model.baseSoftwareVersion);
        const sysParamDefinition = {
            shortBaseSwVersion,
            accessKey: model.accessKey,
            baseSoftwareVersion: model.baseSoftwareVersion,
            name: model.name,
            description: model.description,
            created: model.created,
            unit: model.unit,
        };
        const sysParamDefaultValue = hasDefault ? {
            shortBaseSwVersion,
            accessKey: model.accessKey,
            baseSoftwareVersion: model.baseSoftwareVersion,
            name: model.name,
        } : undefined;
        switch (model.type) {
            case NUMERIC:
                sysParamDefinition.numericParameterDefinition = {
                    minValue: model.numericMinValue,
                    maxValue: model.numericMaxValue,
                    type: model.numericType,

                };
                break;
            case ENUMERATION:
                sysParamDefinition.enumParameterDefinition = {
                    enumValues: model.enumValues,
                    numericType: get('enumParameterDefinition.numericType', model) === STRING ?
                        null : model.enumParameterDefinition.numericType,
                };
                break;
            case BYTE_ARRAY:
                sysParamDefinition.byteArrayParameterDefinition = {
                    byteLength: model.byteArrayByteLength,
                };
                break;
            case STRING:
            default:
                sysParamDefinition.stringParameterDefinition = {
                    maxLength: model.stringMaxLength,
                };
                break;
        }
        if (hasDefault) {
            sysParamDefaultValue.value = this.getDefaultValue(model);
        }
        if (model.isNew) {
            this.props.createSysParamDefinition(sysParamDefinition, sysParamDefaultValue);
        } else {
            this.props.updateSysParamDefinition(sysParamDefinition, sysParamDefaultValue);
        }
    }

    isValid(model) {
        return !!(model.accessKey.toString()) && !!model.name;
    }

    getDefaultValue(model) {
        switch (model.type) {
            case NUMERIC:
                return model.numericDefaultValue;
            case ENUMERATION:
                return model.enumValues[model.enumDefaultValueIndex >= 0 ? model.enumDefaultValueIndex : 0].value;
            case STRING:
                return model.stringDefaultValue;
            case BYTE_ARRAY:
            default:
        }
    }

    render() {
        const { model } = this.props;
        const title = this.renderTitle(model);
        const body = this.renderBody(model);
        const footer = this.renderFooter(model);
        return (
            <Dialog className='sys-param-definition-editor-dialog'
                show={true}
                showCloseButton={true}
                onHide={this.props.hideModal}
                title={title}
                body={body}
                footer={footer}
                useOverflow
            />
        );
    }
    renderTitle(model) {
        const { isNew, showBaseSwVersionForm, baseSoftwareVersion, accessKey } = model;
        // TODO Check if we really need this
        const shortBaseSwVersion = toShortSemanticVersion(baseSoftwareVersion);
        if (isNew) {
            return <FormattedMessage id={'intl-msg:createSysParamDefinition.title'} values={{
                showBaseSwVersion: (!showBaseSwVersionForm).toString(),
                shortBaseSwVersion,
            }}/>;
        }
        return <FormattedMessage id={'intl-msg:editSysParamDefinition.title'} values={{
            shortBaseSwVersion,
            accessKey,
        }}/>;
    }
    renderBody(model) {
        return (
            <SysParamDefinitionEditorForm model={model}
                changeSysParamDefinitionEditor={this.props.changeSysParamDefinitionEditor}/>
        );
    }
    renderFooter(model) {
        const isInProgress = getOr(false, 'isInProgress', model);
        const isReady = !isInProgress;
        return (
            <div>
                <button className='btn btn-default' onClick={this.props.hideModal}>
                    <FormattedMessage id='intl-msg:close'/>
                </button>
                <button className={classNames('btn btn-primary', { disabled: !isReady })}
                    onClick={this.onSaveSysParamDefinition}>
                    {
                        isInProgress ?
                            <Spinner text={<FormattedMessage id='intl-msg:save' />} /> :
                            <FormattedMessage id='intl-msg:save' />
                    }
                </button>
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        model: sysParamDefinitionEditorSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        createSysParamDefinition: (sysParamDefinition, sysParamDefaultValue) => {
            dispatch(createSysParamDefinition(sysParamDefinition, sysParamDefaultValue));
        },
        updateSysParamDefinition: (sysParamDefinition, sysParamDefaultValue) => {
            dispatch(updateSysParamDefinition(sysParamDefinition, sysParamDefaultValue));
        },
        changeSysParamDefinitionEditor: payload => {
            dispatch(changeSysParamDefinitionEditor(payload));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SysParamDefinitionEditorDialog);

SysParamDefinitionEditorDialog.defaultProps = {
    model: {},
    createSysParamDefinition: noop,
    updateSysParamDefinition: noop,
    changeSysParamDefinitionEditor: noop,
};

SysParamDefinitionEditorDialog.propTypes = {
    model: PropTypes.object,
    createSysParamDefinition: PropTypes.func,
    updateSysParamDefinition: PropTypes.func,
    changeSysParamDefinitionEditor: PropTypes.func,
};
