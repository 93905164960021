import PropTypes from 'prop-types';

import React from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';
import { systemStateNotificationTypes } from '~/features/systemState/constants/systemStateNotificationTypes';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

export const SystemStateNotificationType = ({ value }) => {
    if (!value) {
        return null;
    }
    return (
        <OverlayTrigger placement='top' overlay={
            <Tooltip id='tooltip' className='top-right'>
                <FormattedMessage id={systemStateNotificationTypes[value].tooltip}/>
            </Tooltip>}>
            <span className={classNames('label label-condensed', systemStateNotificationTypes[value].className)}>
                <FormattedMessage id={systemStateNotificationTypes[value].label}/>
            </span>
        </OverlayTrigger>
    );
};

export default SystemStateNotificationType;

SystemStateNotificationType.propTypes = {
    // props
    value: PropTypes.string,
};
