import noop from 'lodash/fp/noop';
import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { createContextOptions } from '~/features/base/constants/filterOptions';
import { LIVE } from '~/features/base/constants/releaseStates';
import {
    DELIVERABLE_TYPE_APP,
    DELIVERABLE_TYPE_BUNDLE,
    DELIVERABLE_TYPE_CM4G,
    DELIVERABLE_TYPE_DISTRO,
    DELIVERABLE_TYPE_FILE,
} from '~/features/deliverables/constants/deliverablesParameters';
import VersionDependenciesEditorForm from '~/features/base/components/forms/VersionDependenciesEditorForm';
import { ReleaseStateSelectFormItem } from '~/features/base/components/forms/ReleaseStateSelectFormItem';
import { DownloadPrioritySelectFormItem } from '~/features/base/components/forms/DownloadPrioritySelectFormItem';
import SemanticVersionInputFormItem from '~/features/base/components/forms/SemanticVersionInputFormItem';
import ContextSelectFormItem from '~/features/base/components/forms/ContextSelectFormItem';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import DeliverableRolloutTrackingFormGroup from '~/features/base/components/forms/DeliverableRolloutTrackingFormGroup';
import { deliverablesDefaultUrl } from '~/features/deliverables/constants/deliverablesDefaultUrl';
import ReasonForChangeSelectFormItem from '~/features/base/components/forms/ReasonForChangeSelectFormItem';
import InstalledDeliverablesFormGroup from '~/features/groups/components/InstalledDeliverablesFormGroup';
import DeliverableStandaloneFormItem from '~/features/base/components/forms/DeliverableStandaloneFormItem';
import BundleDeliverables from '~/features/deliverables/components/BundleDeliverables';
import DeviceTypeSelect from '~/features/base/components/forms/DeviceTypeSelect';
import { getDefaultDeviceType } from '~/features/deliverables/constants/deviceTypeDefault';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import TechnicalInformationForm from '~/features/deliverables/components/TechnicalInformationForm';
import { isLatamEnv } from '~/features/base/utils/isLatamEnv';

const DeliverableTypeDependencies = {
    ['app']: DELIVERABLE_TYPE_APP.toUpperCase(),
    ['distro']: DELIVERABLE_TYPE_APP.toUpperCase(),
    ['cm4g']: DELIVERABLE_TYPE_CM4G.toUpperCase(),
};

export class DeliverableEditorForm extends PureComponent {

    onIdChange = (event) => {
        this.props.changeDeliverableEditor({
            deliverableId: event.target.value,
        });
    };

    onContextChange = (event) => {
        this.props.changeDeliverableEditor({
            owningContext: event.context,
        });
    };

    onVersionChange = (deliverableVersion) => {
        this.props.changeDeliverableEditor({
            deliverableVersion,
        });
    };

    onDistroInfoBaseSwVersionChange = (baseSoftwareVersion) => {
        this.props.changeDeliverableEditor({
            distroInfo: { baseSoftwareVersion },
        });
    };

    onFileRecipientChange = (event) => {
        this.props.changeDeliverableEditor({
            fileInfo: {
                recipient: event.target.value,
            },
        });
    };

    onAppInstallerURLChange = (event) => {
        this.props.changeDeliverableEditor({
            appInfo: {
                ...this.props.model.appInfo,
                installerUrl: event.target.value,
            },
        });
    };

    onAppIdChange = (event) => {
        this.props.changeDeliverableEditor({
            appInfo: {
                ...this.props.model.appInfo,
                mmtInfo: {
                    ...this.props.model.appInfo.mmtInfo,
                    appId: event.target.value,
                },
            },
        });
    };

    onAppVersionChange = (appVersion) => {
        this.props.changeDeliverableEditor({
            appInfo: {
                ...this.props.model.appInfo,
                mmtInfo: {
                    ...this.props.model.appInfo.mmtInfo,
                    appVersion,
                },
            },
        });
    };

    onAppDownloadURLChange = (event) => {
        this.props.changeDeliverableEditor({
            appInfo: {
                ...this.props.model.appInfo,
                mmtInfo: {
                    ...this.props.model.appInfo.mmtInfo,
                    downloadUrl: event.target.value,
                },
            },
        });
    };

    onAppAdditionalDataChange = (event) => {
        this.props.changeDeliverableEditor({
            appInfo: {
                ...this.props.model.appInfo,
                mmtInfo: {
                    ...this.props.model.appInfo.mmtInfo,
                    additionalData: event.target.value,
                },
            },
        });
    };

    onReleaseStateSelect = (releaseStateOption) => {
        this.props.changeDeliverableEditor({
            releaseState: releaseStateOption.value,
        });
        if (releaseStateOption.value !== LIVE) {
            this.onRolloutReset();
        }
    };

    onReasonForChangeSelect = (reasonForChangeOption) => {
        this.props.changeDeliverableEditor({
            reasonForChange: reasonForChangeOption ? reasonForChangeOption.value : null,
        });
    };

    onDeviceTypeSelect = (deviceType) => {
        this.props.changeDeliverableEditor({
            deviceType: deviceType ? deviceType.value : null,
        });
    };

    onUrlChange = (event) => {
        this.props.changeDeliverableEditor({
            downloadUrl: event.target.value,
        });
    };

    onDownloadPrioritySelect = (priorityOption) => {
        this.props.changeDeliverableEditor({
            priority: priorityOption.value,
        });
    };

    onWhitelistingInfoChange = (whitelisting) => {
        this.props.changeDeliverableEditor({
            whitelisting,
        });
    };

    onDescriptionChange = (event) => {
        this.props.changeDeliverableEditor({
            description: event.target.value,
        });
    };

    onIvdChange = (event) => {
        this.props.changeDeliverableEditor({
            bundleInfo: {ivd: event.target.value},
        });
    };

    onCompatibilityChange = (compatibility) => {
        this.props.changeDeliverableEditor({
            compatibility,
        });
    };

    onDependenciesChange = (deliverables) => {
        this.props.changeDeliverableEditor({
            dependencies: { deliverables },
        });
    };

    onBundleDeliverablesChange = (deliverables) => {
        this.props.changeDeliverableEditor({
            bundleDeliverables: deliverables,
        });
    };

    onNetworkCodeWhitelistChange = (networkCodePresetOption) => {
        this.props.changeDeliverableEditor({
            networkCodePreset: networkCodePresetOption.value,
        });
    };

    onFileSelect = (file) => {
        this.props.changeDeliverableEditor({
            file: file,
        });
    };

    onCheckTrackingRollout = (event) => {
        const trackingEnabled = !!event.target.checked;
        this.props.changeDeliverableEditor({
            rolloutTracking: { ...this.props.model.rolloutTracking, trackingEnabled },
        });
    };
    onTargetsEstimation = (event) => {
        this.props.changeDeliverableEditor({
            rolloutTracking: { ...this.props.model.rolloutTracking, targetsEstimation: event.target.value },
        });
    };

    onRolloutReset = () => {
        this.props.changeDeliverableEditor({
            rolloutTracking: {},
        });
    };

    onIsStandaloneChange = (value) => {
        this.props.changeDeliverableEditor({
            standalone: value,
        });
    };
    onTechnicalInformationChange = (value) => {
        this.props.changeDeliverableEditor({
            bundleInfo: value,
        });
    };

    setDefaultValues = (model, deliverableType) => {
        if (model.isNew) {
            this.props.changeDeliverableEditor({
                downloadUrl: deliverablesDefaultUrl(deliverableType) || '',
                deviceType: getDefaultDeviceType(deliverableType) || '',
            });
        }
    };

    componentWillMount() {
        const { model, deliverableType } = this.props;
        this.setDefaultValues(model, deliverableType);
    }

    // eslint-disable-next-line complexity
    render() {
        const { contexts, deliverableType, groups, model, deliverablesDependencies, hasWarning } = this.props;
        const isNew = model.isNew;
        const isDeliverableVersion = model.isDeliverableVersion;
        const deliverableIdInput = this.renderIdInput(model);
        const deliverableContextInput = this.renderContextInput(model, contexts);
        const deliverableVersionInput = this.renderVersionInput(model);

        const distroInfoBaseSoftwareVersionInput = this.renderDistroInfoBaseSoftwareVersionInput(model);
        const fileRecipientInput = this.renderFileRecipientInput(model);
        const appInfoInput = this.renderAppInfoInput(model);

        const deliverableReleaseStateInput = this.renderReleaseStateInput(model);
        const deliverableReasonForChangeInput = this.renderReasonForChangeInput(model);
        const deviceTypeInput = this.renderDeviceTypeInput(model, deliverableType);
        const deliverableDownloadUrlInput = this.renderDownloadUrlInput(model);
        const deliverableDescriptionInput = this.renderDescriptionInput(model);
        const deliverableIvdInput = this.renderIvdInput(model);
        const deliverableDownloadPriorityInput = this.renderDownloadPriorityInput(model);
        const rolloutTrackingInputs = this.renderRolloutTracking(model, deliverableType);
        const seeRolloutTrack = (deliverableType === DELIVERABLE_TYPE_DISTRO ||
            deliverableType === DELIVERABLE_TYPE_APP || deliverableType === DELIVERABLE_TYPE_CM4G || deliverableType === DELIVERABLE_TYPE_BUNDLE) && model.releaseState === LIVE && !model.isNew;
        const deliverableStandalone = this.renderStandalone(model);
        const bundleDeliverablesTitle = this.renderBundleDeliverablesTitle();
        const bundleTechnicalInformation = this.renderTechnicalInformation(model);
        return (
            <form>
                {isNew && !isDeliverableVersion && deliverableIdInput}
                {deliverableVersionInput}
                {deviceTypeInput}
                {deliverableType === DELIVERABLE_TYPE_BUNDLE && deliverableIvdInput}
                {deliverableType === DELIVERABLE_TYPE_FILE && deliverableContextInput}
                {deliverableType === DELIVERABLE_TYPE_DISTRO && distroInfoBaseSoftwareVersionInput}
                {deliverableType === DELIVERABLE_TYPE_APP && appInfoInput}
                {deliverableType === DELIVERABLE_TYPE_FILE && fileRecipientInput}
                {(deliverableType === DELIVERABLE_TYPE_FILE || deliverableType === DELIVERABLE_TYPE_CM4G || deliverableType === DELIVERABLE_TYPE_BUNDLE) && deliverableReasonForChangeInput}
                {(deliverableType === DELIVERABLE_TYPE_CM4G || deliverableType === DELIVERABLE_TYPE_FILE) && deliverableStandalone}
                {deliverableReleaseStateInput}
                {seeRolloutTrack &&
                    rolloutTrackingInputs}
                {deliverableType !== DELIVERABLE_TYPE_BUNDLE && deliverableDownloadUrlInput}
                {deliverableDescriptionInput}
                {deliverableType === DELIVERABLE_TYPE_BUNDLE && !isLatamEnv && bundleTechnicalInformation}
                {deliverableType === DELIVERABLE_TYPE_BUNDLE &&
                    <ExpanderPanel className='additional-rollout-settings-expander'
                                   open
                                   title={bundleDeliverablesTitle} bsStyle='default'>
                        <BundleDeliverables
                            model={model}
                            values={model.bundleDeliverables}
                            deliverables={deliverablesDependencies}
                            deliverableTypeDependency={DeliverableTypeDependencies[deliverableType]}
                            onChange={this.onBundleDeliverablesChange}
                            hasWarning={this.props.hasWarning}
                        />
                    </ExpanderPanel>
                }
                <ExpanderPanel className='additional-rollout-settings-expander'
                               title={<FormattedMessage id='intl-msg:versions.dependencies'/>} bsStyle='default'>
                    {deliverableType !== DELIVERABLE_TYPE_CM4G && deliverableType !== DELIVERABLE_TYPE_BUNDLE &&
                        <VersionDependenciesEditorForm compatibility={model.compatibility}
                                                       onChange={this.onCompatibilityChange}/>}
                    {(deliverableType === DELIVERABLE_TYPE_APP ||
                            deliverableType === DELIVERABLE_TYPE_DISTRO ||
                            deliverableType === DELIVERABLE_TYPE_CM4G ||
                            deliverableType === DELIVERABLE_TYPE_BUNDLE) &&
                        <InstalledDeliverablesFormGroup values={model.dependencies?.deliverables}
                                                        noCompatibilitySeparator={!(deliverableType === DELIVERABLE_TYPE_DISTRO) && !(deliverableType === DELIVERABLE_TYPE_APP)}
                                                        deliverableTypeDependency={DeliverableTypeDependencies[deliverableType]}
                                                        deliverables={deliverablesDependencies}
                                                        onChange={this.onDependenciesChange}/>}
                </ExpanderPanel>
                {deliverableDownloadPriorityInput}
            </form>
        );
    }

    getDeliverableNamePerType = (deliverableType) => {
        switch (deliverableType) {
            case DELIVERABLE_TYPE_FILE:
                return <FormattedMessage id='intl-msg:fileName'/>;
            case DELIVERABLE_TYPE_APP:
                return <FormattedMessage id='intl-msg:appName'/>;
            case DELIVERABLE_TYPE_DISTRO:
                return <FormattedMessage id='intl-msg:distroName'/>;
            case DELIVERABLE_TYPE_CM4G:
                return <FormattedMessage id='intl-msg:cm4gName'/>;
            case DELIVERABLE_TYPE_BUNDLE:
                return <FormattedMessage id='intl-msg:bundleName'/>;
            default:
                return <FormattedMessage id='intl-msg:fileName'/>;
        }
    };

    getVersionLabel = (deliverableType) => {
        switch (deliverableType) {
            case DELIVERABLE_TYPE_DISTRO:
                return 'intl-msg:distroVersion';
            case DELIVERABLE_TYPE_APP:
                return 'intl-msg:packageVersion';
            case DELIVERABLE_TYPE_FILE:
                return 'intl-msg:fileVersion';
            case DELIVERABLE_TYPE_CM4G:
                return 'intl-msg:cm4gVersion';
            case DELIVERABLE_TYPE_BUNDLE:
                return 'intl-msg:bundleVersion';
            default:
                return 'intl-msg:version';
        }
    };

    renderBundleDeliverablesTitle = () => {
        const { hasWarning } = this.props;
        if (hasWarning) {
            return (
                <div>
                    <FormattedMessage id='intl-msg:bundleDeliverables'/>
                    <OverlayTrigger
                        placement='top'
                        overlay={
                            <Tooltip id='tooltip' className='top-right'>
                                <FormattedMessage
                                    id={'intl-msg:deliverables.bundleDeliverableReleaseStateWarning'}/>
                            </Tooltip>
                        }
                    >
                            <span
                                className='rioglyph rioglyph-info-sign text-color-warning text-size-20 margin-left-5'/>
                    </OverlayTrigger></div>
            );
        }
        return (
            <div>
                <FormattedMessage id='intl-msg:bundleDeliverables'/>
            </div>
        );
    };

    renderIdInput(model) {
        return (
            <div className='form-group form-group-file-id'>
                <label className='control-label'>
                    {this.getDeliverableNamePerType(this.props.deliverableType)}
                </label>
                <FormattedMessage id='intl-msg:name.prompt'>
                    {placeHolder =>
                        <input type='text' className='form-control'
                               value={model.deliverableId}
                               placeholder={placeHolder}
                               onChange={this.onIdChange}/>
                    }
                </FormattedMessage>
            </div>
        );
    }

    renderContextInput(model, contexts) {
        const contextOptions = createContextOptions(contexts, false);
        return (
            <div className='form-group'>
                <ContextSelectFormItem value={model.owningContext}
                                       options={contextOptions}
                                       onChange={this.onContextChange}/>
            </div>
        );
    }

    renderVersionInput(model) {
        if (model.isNew) {
            return (
                <SemanticVersionInputFormItem value={model.deliverableVersion}
                                              label={this.getVersionLabel(this.props.deliverableType)}
                                              onChange={this.onVersionChange}/>
            );
        }
    }

    renderDistroInfoBaseSoftwareVersionInput(model) {
        if (model.isNew) {
            return (
                <SemanticVersionInputFormItem value={model.distroInfo.baseSoftwareVersion}
                                              onChange={this.onDistroInfoBaseSwVersionChange}/>
            );
        }
    }

    renderFileRecipientInput(model) {
        return (
            <div className='form-group form-group-recipient'>
                <label className='control-label'>
                    <FormattedMessage id='intl-msg:recipient'/>
                </label>
                <FormattedMessage id='intl-msg:recipient.prompt'>
                    {placeHolder =>
                        <input type='text' className='form-control'
                               value={model.fileInfo.recipient}
                               placeholder={placeHolder}
                               onChange={this.onFileRecipientChange}/>
                    }
                </FormattedMessage>
            </div>
        );
    }

    renderAppInfoInput(model) {
        return (
            <div>
                <div className='form-group form-group-recipient'>
                    <label className='control-label'>
                        <FormattedMessage id='intl-msg:installerBaseUrl'/>
                    </label>
                    <FormattedMessage id='intl-msg:url.prompt'>
                        {placeHolder =>
                            <input type='text' className='form-control'
                                   value={model.appInfo.installerUrl}
                                   placeholder={placeHolder}
                                   onChange={this.onAppInstallerURLChange}/>
                        }
                    </FormattedMessage>
                </div>
                <div>
                    <ExpanderPanel className='additional-rollout-settings-expander margin-bottom-15'
                                   title={<FormattedMessage id='intl-msg:bundleMmtInfo'/>} bsStyle='default'>
                        <div className='form-group form-group-recipient'>
                            <label className='control-label'>
                                <FormattedMessage id='intl-msg:appId'/>
                            </label>
                            <FormattedMessage id='intl-msg:appId.prompt'>
                                {placeHolder =>
                                    <input type='text' className='form-control'
                                           value={model.appInfo.mmtInfo?.appId}
                                           placeholder={placeHolder}
                                           onChange={this.onAppIdChange}/>
                                }
                            </FormattedMessage>
                        </div>
                        <SemanticVersionInputFormItem value={model.appInfo.mmtInfo?.appVersion}
                                                      label={'intl-msg:version'}
                                                      onChange={this.onAppVersionChange}/>
                        <div className='form-group form-group-recipient'>
                            <label className='control-label'>
                                <FormattedMessage id='intl-msg:downloadUrl'/>
                            </label>
                            <FormattedMessage id='intl-msg:url.prompt'>
                                {placeHolder =>
                                    <input type='text' className='form-control'
                                           value={model.appInfo.mmtInfo?.downloadUrl}
                                           placeholder={placeHolder}
                                           onChange={this.onAppDownloadURLChange}/>
                                }
                            </FormattedMessage>
                        </div>
                        <div className='form-group form-group-recipient'>
                            <label className='control-label'>
                                <FormattedMessage id='intl-msg:additionalData'/>
                            </label>
                            <FormattedMessage id='intl-msg:additionalData.prompt'>
                                {placeHolder =>
                                    <input type='text' className='form-control'
                                           value={model.appInfo.mmtInfo?.additionalData}
                                           placeholder={placeHolder}
                                           onChange={this.onAppAdditionalDataChange}/>
                                }
                            </FormattedMessage>
                        </div>

                    </ExpanderPanel>
                </div>
            </div>
        );
    }

    renderReleaseStateInput(model) {
        if (!model.isNew) {
            return (
                <ReleaseStateSelectFormItem onChange={this.onReleaseStateSelect} value={model.releaseState}/>
            );
        }
    }

    renderReasonForChangeInput(model) {
        return (
            <ReasonForChangeSelectFormItem onChange={this.onReasonForChangeSelect} value={model.reasonForChange}/>
        );
    }

    renderDeviceTypeInput(model, deliverableType) {
        return (
            <DeviceTypeSelect onChange={this.onDeviceTypeSelect}
                              value={model.deviceType}
                              deliverableType={deliverableType}/>
        );
    }

    renderDownloadUrlInput(model) {
        return (
            <div className='form-group form-group-url'>
                <label className='control-label'>
                    <FormattedMessage id='intl-msg:url'/>
                </label>
                <FormattedMessage id='intl-msg:url.prompt'>
                    {placeHolder =>
                        <input type='text' className='form-control'
                               value={model.downloadUrl}
                               placeholder={placeHolder}
                               onChange={this.onUrlChange}/>
                    }
                </FormattedMessage>
            </div>
        );
    }

    renderDownloadPriorityInput(model) {
        return (
            <DownloadPrioritySelectFormItem onChange={this.onDownloadPrioritySelect} value={model.priority}/>
        );
    }

    renderDescriptionInput(model) {
        return (
            <div className='form-group form-group-description'>
                <label className='control-label'>
                    <FormattedMessage id='intl-msg:description'/>
                </label>
                <FormattedMessage id='intl-msg:description.prompt'>
                    {placeHolder =>
                        <input type='text' className='form-control'
                               value={model.description}
                               placeholder={placeHolder}
                               onChange={this.onDescriptionChange}/>
                    }
                </FormattedMessage>
            </div>
        );
    }

    renderIvdInput(model) {
        return (
            <div className="form-group form-group-description">
                <label className="control-label">
                    <FormattedMessage id="intl-msg:ivd"/>
                </label>
                <FormattedMessage id="intl-msg:description.prompt">
                    {placeHolder =>
                        <input type="text" className="form-control"
                               value={model.bundleInfo.ivd}
                               placeholder={placeHolder}
                               onChange={this.onIvdChange}/>
                    }
                </FormattedMessage>
            </div>
        );
    }

    renderRolloutTracking(model, deliverableType) {
        return (
            <DeliverableRolloutTrackingFormGroup model={model} onTargetsEstimation={this.onTargetsEstimation}
                                                 onCheckTrackingRollout={this.onCheckTrackingRollout}
                                                 deliverableType={deliverableType}/>
        );
    }

    renderStandalone(model) {
        return (
            <DeliverableStandaloneFormItem model={model} onChange={this.onIsStandaloneChange}/>
        );
    }

    renderTechnicalInformation(model) {
        return (<TechnicalInformationForm model={model} onChange={this.onTechnicalInformationChange}/>);
    }

}

export default DeliverableEditorForm;

// TODO Obviously it doesn't work without knowledge about model
DeliverableEditorForm.defaultProps = {
    // props
    model: {
        deliverableId: '',
        context: '',
        file: undefined,
    },
    deliverableType: '',
    contexts: [],
    groups: [],
    apps: [],
    // functions
    changeDeliverableEditor: noop,
    fetchFilteredDeliverables: noop,
};

DeliverableEditorForm.propTypes = {
    // props
    model: PropTypes.object,
    deliverableType: PropTypes.string,
    contexts: PropTypes.array,
    groups: PropTypes.array,
    apps: PropTypes.array,
    // functions
    changeDeliverableEditor: PropTypes.func,
    fetchFilteredDeliverables: PropTypes.func,
};
