import size from 'lodash/fp/size';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';
import DeviceSystemStateNotificationsTable from '~/features/devices/components/DeviceSystemStateNotificationsTable';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';

/**
 * Component for the device systemstates list
 */

export class DeviceSystemStateList extends PureComponent {
    render() {
        const { systemStateNotifications, systemStateNotificationsLoading } = this.props;
        if (!size(systemStateNotifications)) {
            return (
                <DefaultWhiteColumn className='padding-top-20 padding-bottom-20'>
                    {systemStateNotificationsLoading ?
                        <Spinner/>
                        :
                        <NotFoundState fullWidth
                                       headline={<FormattedMessage
                                           id='intl-msg:nothingFound'/>}
                                       message={<FormattedMessage
                                           id='intl-msg:noUpdateEventsReportedYet'/>}/>}
                </DefaultWhiteColumn>
            );
        }

        return <DefaultWhiteColumn className='padding-top-20 padding-bottom-5'>
            <DeviceSystemStateNotificationsTable
                systemStateNotifications={systemStateNotifications}/>
        </DefaultWhiteColumn>;
    }
}

export default DeviceSystemStateList;

DeviceSystemStateList.defaultProps = {
    // props
    systemStateNotifications: [],
    systemStateNotificationsLoading: false,
};

DeviceSystemStateList.propTypes = {
    // props
    systemStateNotifications: PropTypes.array,
    systemStateNotificationsLoading: PropTypes.bool,
};
