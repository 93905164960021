import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import {
    ENTITY_CONTROL_DEVICE_SCOPE_DEVICES_ASSIGNED,
    ENTITY_CONTROL_DEVICE,
} from '~/features/base/constants/entities';

import { indicesStoreSelector } from '~/features/higherorder/selectors/dataSelectors';

const devicesAssignedPagesSelector = state =>
    getOr({}, ENTITY_CONTROL_DEVICE_SCOPE_DEVICES_ASSIGNED, indicesStoreSelector(state)[ENTITY_CONTROL_DEVICE]);

export const devicesAssignedPageNumberSelector = state => get('number', devicesAssignedPagesSelector(state));
export const devicesAssignedPageCountSelector = state => get('totalPages', devicesAssignedPagesSelector(state));

