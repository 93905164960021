import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import ListTable from '~/features/base/components/table/ListTable';
import { connect } from 'react-redux';
import { systemStateCheckedColumnSettingsSelector } from '~/features/systemState/selectors/systemStateSelectors';
import { systemStateNotificationsColumnDescriptors } from '~/features/devices/constants/deviceSystemStateColumnDescriptors';

export class DeviceSystemStateNotificationsTable extends PureComponent {
    render() {
        const { systemStateNotifications } = this.props;
        return (
            <ListTable items={systemStateNotifications}
                       itemKey='id'
                       columnDescriptors={systemStateNotificationsColumnDescriptors}/>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        selectedSystemStateColumns: systemStateCheckedColumnSettingsSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSystemStateNotificationsTable);

DeviceSystemStateNotificationsTable.defaultProps = {
    // props
    systemStateNotifications: [],
};

DeviceSystemStateNotificationsTable.propTypes = {
    // props
    systemStateNotifications: PropTypes.array,
};
