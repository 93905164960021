import { call, put, select, fork, takeEvery, takeLatest } from 'redux-saga/effects';

import {
    FETCH_SYS_PARAM_DEFINITIONS,
    CREATE_SYS_PARAM_DEFINITION,
    UPDATE_SYS_PARAM_DEFINITION,
    DELETE_SYS_PARAM_DEFINITION,
    COPY_SYS_PARAM_DEFINITIONS,
} from '~/features/sysParams/actions/sysParamDefinitionActions';

import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';
import { hideModal } from '~/features/base/actions/ui/modalsActions';

import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';
import { sysParamDefaultValueSelector } from '~/features/sysParams/selectors/sysParamDefaultValueSelectors';
import { stringifyToQuery } from '~/features/base/utils/query';

import { doHandleErrorSaga, getHTTP, postHTTP, putHTTP, deleteHTTP } from '~/features/base/sagas/sagaUtil';
import {
    toShortSemanticVersion,
} from '~/features/base/utils/versionNumberConverter';

import {
    doCreateSysParamDefaultValueSaga,
    doUpdateSysParamDefaultValueSaga,
} from '~/features/sysParams/sagas/sysParamDefaultValuesSaga';
import {
    parseSysParamDefinitions,
    parseSysParamDefinition,
} from '~/features/sysParams/transforms/parseSysParamDefinitions';
import { mergeEntities, mergeEntity, deleteEntity } from '~/features/higherorder/actions/entityActions';
import { ENTITY_SYS_PARAM_DEFINITION } from '~/features/base/constants/entities';
import { failedAction, finishedAction } from '~/features/higherorder/transforms/actionTransforms';

const entityName = ENTITY_SYS_PARAM_DEFINITION;

export function* getSysParamsURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/system-params`;
}
export function* getSysParamDefinitionsURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/system-params/param-definitions`;
}

export function* doFetchSysParamDefinitionsSaga(action) {
    const { shortBaseSwVersion } = action.payload;
    try {
        const sysParamDefinitionsServiceURL = yield call(getSysParamDefinitionsURL);
        const query = stringifyToQuery({
            swVersion: shortBaseSwVersion,
        });
        const response = yield call(getHTTP, `${sysParamDefinitionsServiceURL}?${query}`);
        const entities = parseSysParamDefinitions(response.content);
        yield put(mergeEntities(entities, { entityName, shortBaseSwVersion }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doCreateSysParamDefinitionSaga(action) {
    try {
        const sysParamDefinitionsServiceURL = yield call(getSysParamDefinitionsURL);
        const definition = action.payload.sysParamDefinition;
        const defaultValue = action.payload.sysParamDefaultValue;
        const shortBaseSwVersion = definition.shortBaseSwVersion;
        const response = yield call(postHTTP, `${sysParamDefinitionsServiceURL}`,
            JSON.stringify(definition));
        if (defaultValue) {
            yield doCreateSysParamDefaultValueSaga(action);
        }
        const parsedResponse = parseSysParamDefinition(response);
        yield put(mergeEntity(parsedResponse, { entityName, shortBaseSwVersion }));
        yield put(hideModal());
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
        yield put(failedAction(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doUpdateSysParamDefinitionSaga(action) {
    try {
        const sysParamDefinitionsServiceURL = yield call(getSysParamDefinitionsURL);
        const definition = action.payload.sysParamDefinition;
        const defaultValue = action.payload.sysParamDefaultValue;
        const shortBaseSwVersion = definition.shortBaseSwVersion;
        const response = yield call(putHTTP, `${sysParamDefinitionsServiceURL}`,
            JSON.stringify(definition));

        if (action.payload.sysParamDefaultValue) {
            // need to check whether default already exists
            const existingDefaultValue = yield select(sysParamDefaultValueSelector, defaultValue);
            if (existingDefaultValue) {
                yield doUpdateSysParamDefaultValueSaga(action);
            } else {
                yield doCreateSysParamDefaultValueSaga(action);
            }
        }
        const parsedResponse = parseSysParamDefinition(response);
        yield put(mergeEntity(parsedResponse, { entityName, shortBaseSwVersion }));
        yield put(hideModal());
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
        yield put(failedAction(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doDeleteSysParamDefinitionSaga(action) {
    try {
        const sysParamDefinitionsServiceURL = yield call(getSysParamDefinitionsURL);
        // TODO Do I really have to transform here?
        const shortBaseSwVersion = toShortSemanticVersion(action.payload.baseSoftwareVersion);
        const encodedShortBaseSwVersion = encodeURIComponent(shortBaseSwVersion);
        const encodedAccessKey = encodeURIComponent(`${action.payload.accessKey}`);
        const url = `${sysParamDefinitionsServiceURL}/${encodedShortBaseSwVersion}/${encodedAccessKey}`;
        const response = yield call(deleteHTTP, url);
        const parsedResponse = parseSysParamDefinition(response);
        yield put(deleteEntity(parsedResponse, { entityName, shortBaseSwVersion }));
        yield put(hideModal());
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doCopySysParamDefinitionsSaga(action) {
    try {
        const sysParamsUrl = yield call(getSysParamsURL);
        yield call(postHTTP, `${sysParamsUrl}/copy`,
            JSON.stringify({
                clearBeforeCopy: true,
                destVersion: action.payload.target,
                srcVersion: action.payload.source,
            }));
        yield put(hideModal());
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* fetchSysParamDefinitionsSaga() {
    yield takeLatest(FETCH_SYS_PARAM_DEFINITIONS, doFetchSysParamDefinitionsSaga);
}

export function* createSysParamDefinitionSaga() {
    yield takeEvery(CREATE_SYS_PARAM_DEFINITION, doCreateSysParamDefinitionSaga);
}

export function* updateSysParamDefinitionSaga() {
    yield takeEvery(UPDATE_SYS_PARAM_DEFINITION, doUpdateSysParamDefinitionSaga);
}

export function* deleteSysParamDefinitionSaga() {
    yield takeEvery(DELETE_SYS_PARAM_DEFINITION, doDeleteSysParamDefinitionSaga);
}

export function* copySysParamDefinitionsSaga() {
    yield takeLatest(COPY_SYS_PARAM_DEFINITIONS, doCopySysParamDefinitionsSaga);
}
