export const SHOW_ARTIFACT_EDITOR_DIALOG = 'SHOW_ARTIFACT_EDITOR_DIALOG';
export const SHOW_ARTIFACT_DELETION_DIALOG = 'SHOW_ARTIFACT_DELETION_DIALOG';
export const SHOW_ARTIFACT_PERMISSION_DIALOG = 'SHOW_ARTIFACT_PERMISSION_DIALOG';

export const RESET_ARTIFACT_EDITOR = 'RESET_ARTIFACT_EDITOR';
export const CHANGE_ARTIFACT_EDITOR = 'CHANGE_ARTIFACT_EDITOR';

export const ADD_NEW_CONTEXT = 'ADD_NEW_CONTEXT';
export const UPDATE_CONTEXT_OWNER = 'UPDATE_CONTEXT_OWNER';
export const UPDATE_CONTEXT_OWNER_MODE_ADD = 'ADD';
export const UPDATE_CONTEXT_OWNER_MODE_REMOVE = 'REMOVE';
export const FETCH_CONTEXT_DETAILS = 'FETCH_CONTEXT_DETAILS';
export const FETCH_FILTERED_ARTIFACTS = 'FETCH_FILTERED_ARTIFACTS';
export const FETCH_DELIVERABLES_USER_INFO = 'FETCH_DELIVERABLES_USER_INFO';
export const CREATE_ARTIFACT = 'CREATE_ARTIFACT';
export const SET_ARTIFACTS = 'SET_ARTIFACTS';
export const RESET_ARTIFACTS = 'RESET_ARTIFACTS';
export const SET_ARTIFACT_FILES = 'SET_ARTIFACT_FILES';
export const UPDATE_ARTIFACT = 'UPDATE_ARTIFACT';
export const DELETE_ARTIFACT = 'DELETE_ARTIFACT';

export function showArtifactEditorDialog(payload) {
    return {
        type: SHOW_ARTIFACT_EDITOR_DIALOG,
        payload,
    };
}

export function showArtifactDeletionDialog(payload) {
    return {
        type: SHOW_ARTIFACT_DELETION_DIALOG,
        payload,
    };
}

export function resetArtifactEditor() {
    return {
        type: RESET_ARTIFACT_EDITOR,
    };
}

export function changeArtifactEditor(payload) {
    return {
        type: CHANGE_ARTIFACT_EDITOR,
        payload,
    };
}

export function showDeliverablePermissionsDialog(payload) {
    return {
        type: SHOW_ARTIFACT_PERMISSION_DIALOG,
        payload,
    };
}

export const fetchContextDetails = (contextName) => {
    return {
        type: FETCH_CONTEXT_DETAILS,
        contextName
    };
};

export const addNewContext = (newContextName) => {
    return {
        type: ADD_NEW_CONTEXT,
        newContextName
    };
};

export const addContextOwner = (contextName, ownerName) => {
    return {
        type: UPDATE_CONTEXT_OWNER,
        mode: UPDATE_CONTEXT_OWNER_MODE_ADD,
        contextName,
        ownerName
    };
};

export const removeContextOwner = (contextName, ownerName) => {
    return {
        type: UPDATE_CONTEXT_OWNER,
        mode: UPDATE_CONTEXT_OWNER_MODE_REMOVE,
        contextName,
        ownerName
    };
};

export const fetchFilteredArtifacts = ({ page, scope, searchCriteria }) => {
    return {
        type: FETCH_FILTERED_ARTIFACTS,
        payload: {
            page,
            scope,
            searchCriteria,
        },
    };
};

export const fetchDeliverablesUserInfo = () => {
    return {
        type: FETCH_DELIVERABLES_USER_INFO,
    };
};

export const createArtifact = (artifact) => {
    return {
        type: CREATE_ARTIFACT,
        payload: artifact,
    };
};

export const setArtifacts = (artifactType, artifacts) => {
    return {
        type: SET_ARTIFACTS,
        artifactType,
        artifacts,
    };
};

export const resetArtifacts = () => {
    return {
        type: RESET_ARTIFACTS,
    };
};

export const setArtifactFiles = (artifactId, artifactFiles) => {
    return {
        type: SET_ARTIFACT_FILES,
        artifactId,
        artifactFiles,
    };
};

export const updateArtifact = (artifact) => {
    return {
        type: UPDATE_ARTIFACT,
        payload: artifact,
    };
};

export const deleteArtifact = (artifactId) => {
    return {
        type: DELETE_ARTIFACT,
        payload: {
            artifactId,
        },
    };
};
