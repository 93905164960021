import flow from 'lodash/fp/flow';
import pick from 'lodash/fp/pick';

import { call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import {
    CREATE_CONTROL_DEVICE,
    FETCH_BLOCKED_DEVICE_COUNT,
    FETCH_CONTROL_DEVICE,
    FETCH_CONTROL_DEVICE_COUNT,
    FETCH_CONTROL_DEVICE_COUNTS_BY_BASE_SW,
    FETCH_CONTROL_DEVICE_COUNTS_BY_BASE_SW_IN_CUSTOMER_FLEET,
    FETCH_CONTROL_DEVICE_COUNTS_BY_DISTRO,
    FETCH_CONTROL_DEVICE_COUNTS_BY_DISTRO_IN_CUSTOMER_FLEET,
    FETCH_CONTROL_DEVICE_COUNTS_BY_HW_VARIANT,
    FETCH_CONTROL_DEVICE_COUNTS_BY_HW_VARIANT_IN_CUSTOMER_FLEET,
    FETCH_ERROR_DEVICE_COUNT,
    FETCH_FILTERED_CONTROL_DEVICES,
    FETCH_IN_CUSTOMER_FLEET_COUNT,
    FETCH_TEST_DEVICE_COUNT,
    FETCH_WARNING_DEVICE_COUNT,
    TRIGGER_CHECK_FOR_UPDATE_ON_CONTROL_DEVICE,
    UPDATE_CONTROL_DEVICE,
} from '~/features/devices/actions/controlDeviceActions';
import { mergeEntities, mergeEntity } from '~/features/higherorder/actions/entityActions';
import { mergePage } from '~/features/higherorder/actions/paginationActions';
import { mergeAggregation } from '~/features/higherorder/actions/aggregationActions';
import { endLoading, startLoading } from '~/features/higherorder/actions/loadingActions';

import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';
import { hideModal } from '~/features/base/actions/ui/modalsActions';

import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';
import { stringifyToQuery } from '~/features/base/utils/query';

import { doHandleErrorSaga, getHTTP, postHTTP, putHTTP } from '~/features/base/sagas/sagaUtil';

import {
    generatePerBaseSwVersion,
    generatePerDistroVersion,
    generatePerHwVariant,
    parseControlDevice,
    parseControlDevices,
} from '~/features/devices/transforms/parseControlDevices';

import {
    ENTITY_CONTROL_DEVICE,
    ENTITY_DEVICE_STATUS_NAMES_ALL,
    ENTITY_DEVICE_STATUS_TYPES_ALL,
} from '~/features/base/constants/entities';
import { failedAction, finishedAction } from '~/features/higherorder/transforms/actionTransforms';
import { CM4, TBM3, VCM } from '~/features/devices/constants/deviceTypes';
import {
    FETCH_DEVICE_STATUS_NAMES,
    FETCH_DEVICE_STATUS_TYPES,
} from '~/features/devices/actions/ui/controlDeviceDetailsActions';

export function* getControlDeviceURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/control-device`;
}

export function* doFetchControlDeviceTBM3CountSaga(action) {
    try {
        const url = yield call(getControlDeviceURL);
        const response = yield call(postHTTP, `${url}/count`, JSON.stringify({
            ...action.searchCriteria,
            type: TBM3,
        }));
        yield put(mergeAggregation({
            entityName: ENTITY_CONTROL_DEVICE,
            scope: 'totalTBM3Count',
        }, response.count));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchControlDeviceVCMCountSaga(action) {
    try {
        const url = yield call(getControlDeviceURL);
        const response = yield call(postHTTP, `${url}/count`, JSON.stringify({
            ...action.searchCriteria,
            type: VCM,
        }));
        yield put(mergeAggregation({
            entityName: ENTITY_CONTROL_DEVICE,
            scope: 'totalVCMCount',
        }, response.count));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchControlDeviceCM4CountSaga(action) {
    try {
        const url = yield call(getControlDeviceURL);
        const response = yield call(postHTTP, `${url}/count`, JSON.stringify({
            ...action.searchCriteria,
            type: CM4,
        }));
        yield put(mergeAggregation({
            entityName: ENTITY_CONTROL_DEVICE,
            scope: 'totalCM4Count',
        }, response.count));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchTestDeviceCountSaga(action) {
    try {
        const url = yield call(getControlDeviceURL);
        const response = yield call(postHTTP, `${url}/count`, JSON.stringify({ testReleasesActive: true }));
        yield put(mergeAggregation({
            entityName: ENTITY_CONTROL_DEVICE,
            scope: 'testCount',
        }, response.count));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchBlockedDeviceCountSaga(action) {
    try {
        const url = yield call(getControlDeviceURL);
        const response = yield call(postHTTP, `${url}/count`, JSON.stringify({ updatesActive: false }));
        yield put(mergeAggregation({
            entityName: ENTITY_CONTROL_DEVICE,
            scope: 'blockedCount',
        }, response.count));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchWarningDeviceCountSaga(action) {
    try {
        const url = yield call(getControlDeviceURL);
        const response = yield call(postHTTP, `${url}/count`, JSON.stringify({ status: 'WARN' }));
        yield put(mergeAggregation({
            entityName: ENTITY_CONTROL_DEVICE,
            scope: 'warningDeviceCount',
        }, response.count));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchErrorDeviceCountSaga(action) {
    try {
        const url = yield call(getControlDeviceURL);
        const response = yield call(postHTTP, `${url}/count`, JSON.stringify({ status: 'ERROR' }));
        yield put(mergeAggregation({
            entityName: ENTITY_CONTROL_DEVICE,
            scope: 'errorDeviceCount',
        }, response.count));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchInCustomerFleetCountSagaTBM3(action){
    const url = yield call(getControlDeviceURL);
    const response = yield call(postHTTP, `${url}/count`, JSON.stringify({
        ...action.searchCriteria,
        type: TBM3,
        inCustomerFleet: true,
    }));
    if (response && !response.error) {
        yield put(mergeAggregation({
            entityName: ENTITY_CONTROL_DEVICE,
            scope: `inCustomerFleetCountTBM3`,
        }, response.count));
    } else {
        yield put(showErrorMessage(response.error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchInCustomerFleetCountSagaCM4(action){
    const url = yield call(getControlDeviceURL);
    const response = yield call(postHTTP, `${url}/count`, JSON.stringify({
        ...action.searchCriteria,
        type: CM4,
        inCustomerFleet: true,
    }));
    if (response && !response.error) {
        yield put(mergeAggregation({
            entityName: ENTITY_CONTROL_DEVICE,
            scope: `inCustomerFleetCountCM4`,
        }, response.count));
    } else {
        yield put(showErrorMessage(response.error));
    }
    yield put(finishedAction(action.type));}

export function* doFetchControlDeviceCountsByBaseSwSaga(action) {
    try {
        const url = yield call(getControlDeviceURL);
        const response = yield call(postHTTP, `${url}/counts-by/base-software-version`,
            JSON.stringify(action.searchCriteria));
        const parsedResponse = generatePerBaseSwVersion(response);
        yield put(mergeAggregation({
            entityName: ENTITY_CONTROL_DEVICE,
            scope: 'countsByBaseSw',
        }, parsedResponse));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchControlDeviceCountsByDistroSaga(action) {
    try {
        const url = yield call(getControlDeviceURL);
        const response = yield call(postHTTP, `${url}/counts-by/distro-version`,
            JSON.stringify(action.searchCriteria));
        const parsedResponse = generatePerDistroVersion(response);
        yield put(mergeAggregation({
            entityName: ENTITY_CONTROL_DEVICE,
            scope: 'countsByDistro',
        }, parsedResponse));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchControlDeviceCountsByHwVariant(action) {
    try {
        const url = yield call(getControlDeviceURL);
        const response = yield call(postHTTP, `${url}/counts-by/hw-variant`,
            JSON.stringify(action.searchCriteria));
        const parsedResponse = generatePerHwVariant(response);
        yield put(mergeAggregation({
            entityName: ENTITY_CONTROL_DEVICE,
            scope: 'countsByHwVariant',
        }, parsedResponse));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchControlDeviceCountsByBaseSwInCustomerFleetSaga(action) {
    try {
        const url = yield call(getControlDeviceURL);
        const response = yield call(postHTTP, `${url}/counts-by/base-software-version`,
            JSON.stringify(action.searchCriteria));
        const parsedResponse = generatePerBaseSwVersion(response);
        yield put(mergeAggregation({
            entityName: ENTITY_CONTROL_DEVICE,
            scope: 'countsByBaseSwInCustomerFleet',
        }, parsedResponse));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchControlDeviceCountsByDistroInCustomerFleetSaga(action) {
    try {
        const url = yield call(getControlDeviceURL);
        const response = yield call(postHTTP, `${url}/counts-by/distro-version`,
            JSON.stringify(action.searchCriteria));
        const parsedResponse = generatePerDistroVersion(response);
        yield put(mergeAggregation({
            entityName: ENTITY_CONTROL_DEVICE,
            scope: 'countsByDistroInCustomerFleet',
        }, parsedResponse));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchControlDeviceCountsByHwVariantInCustomerFleet(action) {
    try {
        const url = yield call(getControlDeviceURL);
        const response = yield call(postHTTP, `${url}/counts-by/hw-variant`,
            JSON.stringify(action.searchCriteria));
        const parsedResponse = generatePerHwVariant(response);
        yield put(mergeAggregation({
            entityName: ENTITY_CONTROL_DEVICE,
            scope: 'countsByHwVariantInCustomerFleet',
        }, parsedResponse));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* receiveFilteredControlDevicesSaga(action) {
    try {
        const url = yield call(getControlDeviceURL);
        const query = flow(pick(['page', 'size']), stringifyToQuery)(action);
        if (action.searchCriteria.distroVersion && (!action.searchCriteria.distroVersion.year
            || !action.searchCriteria.distroVersion.week
            || !action.searchCriteria.distroVersion.number)) {
            action.searchCriteria.distroVersion = {
                year: 1,
                week: 1,
                number: 1,
            };
        }
        const { content, ...pagination } = yield call(postHTTP, `${url}/search/default-view?${query}`,
            JSON.stringify(action.searchCriteria));
        const parsedResponse = { content: parseControlDevices(content), ...pagination };
        return parsedResponse;
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
}

export function* mergeFilteredControlDevicesSaga(response, scope) {
    yield put(mergeEntities(response.content, { entityName: ENTITY_CONTROL_DEVICE }));
    yield put(mergePage({ entityName: ENTITY_CONTROL_DEVICE, scope }, response));
}

export function* doFetchFilteredControlDevicesSaga(action) {
    yield put(startLoading(ENTITY_CONTROL_DEVICE, action.scope));
    const response = yield call(receiveFilteredControlDevicesSaga, action);
    if (response) {
        yield call(mergeFilteredControlDevicesSaga, response, action.scope);
    }
    yield put(endLoading(ENTITY_CONTROL_DEVICE, action.scope));
    yield put(finishedAction(action.type));
}

export function* doFetchControlDeviceSaga(action) {
    const { serialNumber } = action.payload;
    try {
        const url = yield call(getControlDeviceURL);
        const response = yield call(getHTTP, `${url}/${serialNumber}`);
        const parsedResponse = parseControlDevice(response);
        yield put(mergeEntity(parsedResponse, { entityName: ENTITY_CONTROL_DEVICE }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doCreateControlDeviceSaga(action) {
    try {
        const url = yield call(getControlDeviceURL);
        yield call(postHTTP, `${url}`, JSON.stringify(action.payload));
        yield put(hideModal());
        yield put(triggerDataFetcher());
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doUpdateControlDeviceSaga(action) {
    try {
        const url = yield call(getControlDeviceURL);
        const response = yield call(putHTTP, `${url}`, JSON.stringify(action.payload));
        const parsedResponse = parseControlDevice(response);
        yield put(mergeEntity(parsedResponse, { entityName: ENTITY_CONTROL_DEVICE }));
        yield put(hideModal());
    } catch (error) {
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doTriggerCheckForUpdateSaga(action) {
    try {
        const url = yield call(getControlDeviceURL);
        const { serialNumber } = action.payload;
        yield call(postHTTP, `${url}/send-trigger/${serialNumber}`);
        yield put(hideModal());
    } catch (error) {
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchDeviceStatusNamesSaga(action) {
    try {
        const url = yield call(getControlDeviceURL);
        const response = yield call(getHTTP, `${url}/status-names`);
        yield put(mergeEntity(response.content, { entityName: ENTITY_DEVICE_STATUS_NAMES_ALL }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchDeviceStatusTypesSaga(action) {
    try {
        const url = yield call(getControlDeviceURL);
        const response = yield call(getHTTP, `${url}/status-types`);
        yield put(mergeEntity(response.content, { entityName: ENTITY_DEVICE_STATUS_TYPES_ALL }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* fetchControlDeviceCountSaga() {
    yield takeLatest(FETCH_CONTROL_DEVICE_COUNT, doFetchControlDeviceTBM3CountSaga);
    yield takeLatest(FETCH_CONTROL_DEVICE_COUNT, doFetchControlDeviceVCMCountSaga);
    yield takeLatest(FETCH_CONTROL_DEVICE_COUNT, doFetchControlDeviceCM4CountSaga);
}

export function* fetchInCustomerFleetCountSaga() {
    yield takeLatest(FETCH_IN_CUSTOMER_FLEET_COUNT, doFetchInCustomerFleetCountSagaTBM3);
    yield takeLatest(FETCH_IN_CUSTOMER_FLEET_COUNT, doFetchInCustomerFleetCountSagaCM4);
}

export function* fetchTestDeviceCountSaga() {
    yield takeLatest(FETCH_TEST_DEVICE_COUNT, doFetchTestDeviceCountSaga);
}

export function* fetchBlockedDeviceCountSaga() {
    yield takeLatest(FETCH_BLOCKED_DEVICE_COUNT, doFetchBlockedDeviceCountSaga);
}

export function* fetchWarningDeviceCountSaga() {
    yield takeLatest(FETCH_WARNING_DEVICE_COUNT, doFetchWarningDeviceCountSaga);
}

export function* fetchErrorDeviceCountSaga() {
    yield takeLatest(FETCH_ERROR_DEVICE_COUNT, doFetchErrorDeviceCountSaga);
}

export function* fetchControlDeviceCountsByBaseSwSaga() {
    yield takeLatest(FETCH_CONTROL_DEVICE_COUNTS_BY_BASE_SW, doFetchControlDeviceCountsByBaseSwSaga);
}

export function* fetchControlDeviceCountsByDistroSaga() {
    yield takeLatest(FETCH_CONTROL_DEVICE_COUNTS_BY_DISTRO, doFetchControlDeviceCountsByDistroSaga);
}

export function* fetchControlDeviceCountsByBaseSwInCustomerFleetSaga() {
    yield takeLatest(FETCH_CONTROL_DEVICE_COUNTS_BY_BASE_SW_IN_CUSTOMER_FLEET,
        doFetchControlDeviceCountsByBaseSwInCustomerFleetSaga);
}

export function* fetchControlDeviceCountsByDistroInCustomerFleetSaga() {
    yield takeLatest(FETCH_CONTROL_DEVICE_COUNTS_BY_DISTRO_IN_CUSTOMER_FLEET,
        doFetchControlDeviceCountsByDistroInCustomerFleetSaga);
}

export function* fetchControlDeviceCountsByHwVariantInCustomerFleetSaga() {
    yield takeLatest(FETCH_CONTROL_DEVICE_COUNTS_BY_HW_VARIANT_IN_CUSTOMER_FLEET,
        doFetchControlDeviceCountsByHwVariantInCustomerFleet);
}

export function* fetchControlDeviceCountsByHwVariantSaga() {
    yield takeLatest(FETCH_CONTROL_DEVICE_COUNTS_BY_HW_VARIANT,
        doFetchControlDeviceCountsByHwVariant);
}

export function* fetchFilteredControlDevicesSaga() {
    yield takeLatest(FETCH_FILTERED_CONTROL_DEVICES, doFetchFilteredControlDevicesSaga);
}

export function* fetchControlDeviceSaga() {
    yield takeLatest(FETCH_CONTROL_DEVICE, doFetchControlDeviceSaga);
}

export function* createControlDeviceSaga() {
    yield takeEvery(CREATE_CONTROL_DEVICE, doCreateControlDeviceSaga);
}

export function* updateControlDeviceSaga() {
    yield takeEvery(UPDATE_CONTROL_DEVICE, doUpdateControlDeviceSaga);
}

export function* triggerCheckForUpdateOnControlDeviceSaga() {
    yield takeEvery(TRIGGER_CHECK_FOR_UPDATE_ON_CONTROL_DEVICE, doTriggerCheckForUpdateSaga);
}

export function* fetchDeviceStatusNamesSaga() {
    yield takeLatest(FETCH_DEVICE_STATUS_NAMES, doFetchDeviceStatusNamesSaga);
}

export function* fetchDeviceStatusTypesSaga() {
    yield takeLatest(FETCH_DEVICE_STATUS_TYPES, doFetchDeviceStatusTypesSaga);
}
