export const PACKAGE_UPDATE = 'PACKAGE_UPDATE';
export const PACKAGE_INSTALL = 'PACKAGE_INSTALL';
export const DISTRO_UPDATE = 'DISTRO_UPDATE';
export const FILE_DOWNLOAD = 'FILE_DOWNLOAD';
export const DELIVERABLE_UPDATE = 'DELIVERABLE_UPDATE';
export const DELIVERABLE_INSTALL = 'DELIVERABLE_INSTALL';
export const BUNDLE_UPDATE = 'BUNDLE_UPDATE';
export const BUNDLE_INSTALL = 'BUNDLE_INSTALL';

export const updateEventTypes = {
    [PACKAGE_UPDATE]: {
        id: PACKAGE_UPDATE,
        label: 'intl-msg:updateEventType.packageUpdate',
        tooltip: 'intl-msg:updateEventType.packageUpdate.tooltip',
        className: 'label-info',
    },
    [PACKAGE_INSTALL]: {
        id: PACKAGE_INSTALL,
        label: 'intl-msg:updateEventType.packageInstall',
        tooltip: 'intl-msg:updateEventType.packageInstall.tooltip',
        className: 'label-success',
    },
    [DISTRO_UPDATE]: {
        id: DISTRO_UPDATE,
        label: 'intl-msg:updateEventType.distroUpdate',
        tooltip: 'intl-msg:updateEventType.distroUpdate.tooltip',
        className: 'label-default',
    },
    [FILE_DOWNLOAD]: {
        id: FILE_DOWNLOAD,
        label: 'intl-msg:updateEventType.fileDownload',
        tooltip: 'intl-msg:updateEventType.fileDownload.tooltip',
        className: 'label-default',
    },
    [DELIVERABLE_UPDATE]: {
        id: DELIVERABLE_UPDATE,
        label: 'intl-msg:deviceUpdateEventType.deliverableUpdate',
        tooltip: 'intl-msg:deviceUpdateEventType.deliverableUpdate.tooltip',
        className: 'label-info',
    },
    [DELIVERABLE_INSTALL]: {
        id: DELIVERABLE_INSTALL,
        label: 'intl-msg:deviceUpdateEventType.deliverableInstall',
        tooltip: 'intl-msg:deviceUpdateEventType.deliverableInstall.tooltip',
        className: 'label-info',
    },
    [BUNDLE_UPDATE]: {
        id: BUNDLE_UPDATE,
        label: 'intl-msg:deviceUpdateEventType.bundleUpdate',
        tooltip: 'intl-msg:deviceUpdateEventType.bundleUpdate.tooltip',
        className: 'label-info',
    },
    [BUNDLE_INSTALL]: {
        id: BUNDLE_INSTALL,
        label: 'intl-msg:deviceUpdateEventType.bundleInstall',
        tooltip: 'intl-msg:deviceUpdateEventType.bundleInstall.tooltip',
        className: 'label-info',
    },

};
