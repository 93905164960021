import PropTypes from 'prop-types';

import React from 'react';

import NoData from '~/features/base/components/NoData';

export const FieldOrNoData = ({ field }) => {
    if (field || field === 0) {
        return field;
    }
    return <NoData/>;
};

export default FieldOrNoData;

FieldOrNoData.propTypes = {
    // props
    field: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
};
