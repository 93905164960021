import { RESET_DELIVERABLES, SET_DELIVERABLES } from '~/features/artifacts/actions/deliverableActions';
import {
    DELIVERABLE_TYPE_APP,
    DELIVERABLE_TYPE_DISTRO,
    DELIVERABLE_TYPE_FILE,
} from '~/features/deliverables/constants/deliverablesParameters';

import { keyBy } from 'lodash/collection';

const defaultState = {
    file: {},
    app: {},
    distro: {},
};

const resetDeliverables = () => {
    return defaultState;
};

const setDeliverables = (state, action) => {
    const deliverables = keyBy(action.deliverables, 'deliverableId');
    switch (action.deliverableType) {
        case DELIVERABLE_TYPE_FILE:
            return {
                ...state,
                file: { ...state.file, ...deliverables },
            };
        case DELIVERABLE_TYPE_APP:
            return {
                ...state,
                app: deliverables,
            };
        case DELIVERABLE_TYPE_DISTRO:
            return {
                ...state,
                distro: deliverables,
            };
        default:
            return state;
    }
};

export default function deliverableReducer(state = defaultState, action = {}) {
    switch (action.type) {
        case SET_DELIVERABLES:
            return setDeliverables(state, action);
        case RESET_DELIVERABLES:
            return resetDeliverables(state, action);
        default:
            return state;
    }
}
