import React, { useState } from 'react';
import { ControlDeviceSearchCriteria } from '~/api/models';
import TableSearch from '@rio-cloud/rio-uikit/lib/es/TableSearch';
import { injectIntl } from 'react-intl';
import { searchSelector } from '~/features/base/selectors/locationSelectors';
import { useSelector } from 'react-redux';
import { parseQuery } from '~/features/base/utils/query';

interface VehicleListSearchProps {
    onCriteriaChange: (criteria: ControlDeviceSearchCriteria) => void;
}

const VehicleListSearch: React.FC<VehicleListSearchProps> = injectIntl((props) => {

    const { intl, onCriteriaChange } = props;
    const urlParams = useSelector((state) => searchSelector(state));
    const { vinPrefix } = parseQuery(urlParams);
    const [search, setSearch] = useState<string|undefined>(vinPrefix);

    const handleInputChange = (input: string | undefined) => {
        setSearch(input);
        onCriteriaChange({
            vinPrefix: input,
        });
    };
    const searchFieldLabel = intl.formatMessage({ id: 'intl-msg:findVin' });
    return (
        <div className='table-toolbar-column width-300'>
            <TableSearch
                value={search}
                onChange={handleInputChange}
                onClear={() => handleInputChange(undefined)}
                placeholder={searchFieldLabel}
            />
        </div>
    );
});

export default VehicleListSearch;
