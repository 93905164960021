import * as React from 'react';
import noop from 'lodash/fp/noop';
import PropTypes from 'prop-types';

import { ALL } from '~/features/base/constants/filterOptions';

import { FormattedMessage } from 'react-intl';
import { selectionByValue } from '~/features/base/utils/selectUtils';
import { createReleaseStateOptions, DRAFT } from '~/features/base/constants/releaseStates';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';

export const ReleaseStateSelectFormItem = ({
    value,
    onChange,
}) => {
    const releaseStatePreset = value || DRAFT;
    const optionsWithSelection = selectionByValue(releaseStatePreset)(createReleaseStateOptions());
    return (
        <div className='form-group form-group-release-state'>
            <label className='control-label'>
                <FormattedMessage id='intl-msg:releaseState'/>
            </label>
            <div>
                <Select options={optionsWithSelection} onChange={onChange}/>
            </div>
        </div>
    );
};

ReleaseStateSelectFormItem.defaultProps = {
    // props
    value: ALL,
    // functions
    onChange: noop,
};

ReleaseStateSelectFormItem.propTypes = {
    // props
    value: PropTypes.string,
    // functions
    onChange: PropTypes.func,
};
