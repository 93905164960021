import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { setShowFilterView } from '~/features/base/actions/ui/filterActions';

import { showFilterViewSelector } from '~/features/base/selectors/filterSelectors';

import SidebarNavigation from '~/features/base/components/SidebarNavigation';
import RenderingError from '~/features/base/components/RenderingError';
import Sidebar from '@rio-cloud/rio-uikit/lib/es/Sidebar';

const sidebarHeader = (
    <FormattedMessage id='intl-msg:filters'/>
);

export class SidebarContainer extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: undefined,
        };
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error,
        };
    }

    onToggleFilterView = () => {
        const { showFilterView } = this.props;
        this.props.setShowFilterView(!showFilterView);
    };
    onDisableFilterView = () => {
        this.props.setShowFilterView(false);
    };

    render() {
        const { showFilterView, children } = this.props;

        const toRender = [
            <SidebarNavigation
                key={'SidebarNavigation'}
                navItems={children ? [{ className: 'rioglyph-filter' }] : []}
                expanded={showFilterView}
                onNavButtonClick={this.onToggleFilterView}
                onCollapseButtonClick={this.onToggleFilterView}
                onExpandButtonClick={this.onToggleFilterView}/>,
        ];

        const content = this.state.hasError ? (
            <RenderingError error={this.state.error}/>
        ) : children;

        if (content) {
            toRender.push(
                <Sidebar title={sidebarHeader}
                         key={sidebarHeader}
                         enableFullscreenToggle={false}
                         width={300}
                         closed={!showFilterView}
                         onClose={this.onDisableFilterView}>
                    {content}
                </Sidebar>,
            );
        }
        return toRender;
    }
}

export const mapStateToProps = (state) => {
    return {
        showFilterView: showFilterViewSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        setShowFilterView: (showFilterView) => {
            dispatch(setShowFilterView(showFilterView));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarContainer);
