import map from 'lodash/fp/map';
import values from 'lodash/fp/values';

import { createSelector } from 'reselect';

import { PERMISSION_SYS_PARAM_COPY } from '~/features/base/constants/permissions';

import { permissionsSelector } from '~/features/user/selectors/permissionSelectors';
import {
    sysParamDefinitionEntitiesOfDistroSelector,
    sysParamDefinitionEntitiesOfDeviceSelector,
} from '~/features/sysParams/selectors/sysParamDefinitionSelectors';
import {
    sysParamDefaultValuesOfDistroSelector,
    sysParamDefaultValuesOfDeviceSelector,
} from '~/features/sysParams/selectors/sysParamDefaultValueSelectors';
import { sysParamSystemValuesOfDeviceSelector } from './sysParamSystemValueSelectors';
import { sysParamDeviceValuesOfDeviceSelector } from './sysParamDeviceValueSelectors';

export const canCopySysParamsSelector = state =>
    permissionsSelector(state).includes(PERMISSION_SYS_PARAM_COPY);

export const sysParamsOfDistroSelector = createSelector(
    [
        sysParamDefinitionEntitiesOfDistroSelector,
        sysParamDefaultValuesOfDistroSelector,
    ], (sysParamDefinitionEntitiesOfDistro,
        sysParamDefaultValuesOfDistro) => {
        const sysParamDefinitionsOfDistro = values(sysParamDefinitionEntitiesOfDistro);
        const sysParamsOfDistro = map(sysParamDefinition => ({
            ...sysParamDefinition,
            defaultValue: sysParamDefaultValuesOfDistro[sysParamDefinition.accessKey],
        }), sysParamDefinitionsOfDistro);
        return sysParamsOfDistro;
    }
);

export const sysParamsOfDeviceSelector = createSelector(
    [
        sysParamDefinitionEntitiesOfDeviceSelector,
        sysParamDefaultValuesOfDeviceSelector,
        sysParamSystemValuesOfDeviceSelector,
        sysParamDeviceValuesOfDeviceSelector,
    ], (sysParamDefinitionEntitiesOfDevice,
        sysParamDefaultValuesOfDevice,
        sysParamSystemValuesOfDevice,
        sysParamDeviceValuesOfDevice) => {
        const sysParamDefinitionsOfDevice = values(sysParamDefinitionEntitiesOfDevice);
        const sysParamsOfDevice = map(sysParamDefinition => ({
            ...sysParamDefinition,
            defaultValue: sysParamDefaultValuesOfDevice[sysParamDefinition.accessKey],
            systemValue: sysParamSystemValuesOfDevice[sysParamDefinition.accessKey],
            deviceValue: sysParamDeviceValuesOfDevice[sysParamDefinition.accessKey],
        }), sysParamDefinitionsOfDevice);
        return sysParamsOfDevice;
    }
);
