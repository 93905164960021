import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

// Routes
import {
    LIST_PATH,
} from '~/features/base/constants/routes';

// Groups
import GroupListContainer from '~/features/groups/components/GroupListContainer';
import GroupDetailsContainer from '~/features/groups/components/GroupDetailsContainer';
import { sendGoogleAnalyticsEvent } from '~/index';
import { useDataInterestCleaner } from '~/features/base/hooks/useDataInterestCleaner';
import DeliverableContainer from '~/features/deliverables/components/DeliverableContainer';

export const GroupDetailsRoute = ({ match }) => {
    const groupName = match.params.groupName;
    return (
        <GroupDetailsContainer groupName={groupName}/>
    );
};

export const GroupsRoute = ({ match }) => {
    useDataInterestCleaner();
    // send Google Analytics Event
    sendGoogleAnalyticsEvent('Groups Page', 'View Groups');
    return (
        <Switch>
            <Route path={`${match.path}/${LIST_PATH}`} component={(props) => <GroupListContainer {...props}/>} />
            <Route path={`${match.path}/:groupName`} component={(props) => <GroupDetailsRoute {...props}/>} />
            <Redirect to={`${match.url}/${LIST_PATH}`} />
        </Switch>
    );
};

export default GroupsRoute;
