import PropTypes from 'prop-types';
import React from 'react';

import PropertiesTable from '~/features/base/components/table/PropertiesTable';

const tableClassName = 'table table-condensed table-hover';

export const DevicePropertiesTable = ({ controlDeviceData, rowDescriptors }) => {
    return <PropertiesTable showHeader={false} className={tableClassName} data={controlDeviceData}
                            rowDescriptors={rowDescriptors}/>;
};

export default DevicePropertiesTable;

DevicePropertiesTable.defaultProps = {
    controlDeviceData: undefined,
    rowDescriptors: [],
};

DevicePropertiesTable.propTypes = {
    // props
    controlDeviceData: PropTypes.object,
    rowDescriptors: PropTypes.array,
};
