import { call, put, takeLatest } from 'redux-saga/effects';
import {
    SYS_PARAM_SYSTEM_VALUE_DELETE_MODAL,
    SYS_PARAM_SYSTEM_VALUE_EDITOR_MODAL,
} from '~/features/base/constants/modals';

import { showModal } from '~/features/base/actions/ui/modalsActions';
import {
    changeSysParamSystemValueEditor,
    resetSysParamSystemValueEditor,
    SHOW_DELETE_SYS_PARAM_SYSTEM_VALUE_DIALOG,
    SHOW_SYS_PARAM_SYSTEM_VALUE_EDITOR,
} from '~/features/sysParams/actions/sysParamSystemValueEditorActions';
import findIndex from 'lodash/fp/findIndex';

export function* doPrepareForNewSysParamSystemValueSaga(payload) {
    const sysParamDefinition = payload.sysParamDefinition;
    const sysParamDefaultValue = payload.sysParamDefaultValue;
    const hwSerial = payload.serialNumber;
    const value = sysParamDefaultValue ? sysParamDefaultValue.value : undefined;
    let editorModel = {
        hwSerial,
        sysParamDefinition,
        sysParamDefaultValue,
        isNew: true,
        accessKey: sysParamDefinition.accessKey,
        baseSoftwareVersion: sysParamDefinition.baseSoftwareVersion,
        name: sysParamDefinition.name,
        value,
    };

    // find enum value index
    if (sysParamDefinition.type === 'ENUMERATION' && value) {
        let enumValueIndex = findIndex(o => o.value === value,
            sysParamDefinition.enumParameterDefinition.enumValues);
        editorModel = {
            ...editorModel,
            enumValueIndex,
        };
    }

    yield put(changeSysParamSystemValueEditor(editorModel));
}

export function* doPrepareForExistingSysParamSystemValueSaga(payload) {
    const sysParamDefinition = payload.sysParamDefinition;
    const sysParamDefaultValue = payload.sysParamDefaultValue;
    const sysParamSystemValue = payload.sysParamSystemValue;
    const editorModel = {
        sysParamDefinition,
        sysParamDefaultValue,
        isNew: false,
        accessKey: sysParamDefinition.accessKey,
        baseSoftwareVersion: sysParamDefinition.baseSoftwareVersion,
        hwSerial: sysParamSystemValue.hwSerial,
        name: sysParamDefinition.name,
        value: sysParamSystemValue ? sysParamSystemValue.value : undefined,
    };
    yield put(changeSysParamSystemValueEditor(editorModel));
}

export function* doShowSysParamSystemValueEditorSaga(action) {
    yield put(resetSysParamSystemValueEditor());
    if (action.payload.isNew) {
        yield call(doPrepareForNewSysParamSystemValueSaga, action.payload);
    } else {
        yield call(doPrepareForExistingSysParamSystemValueSaga, action.payload);
    }
    yield put(showModal({
        modalType: SYS_PARAM_SYSTEM_VALUE_EDITOR_MODAL,
        modalProps: {},
    }));
}

export function* doShowSysParamSystemValueDeletionDialogSaga(action) {
    yield put(resetSysParamSystemValueEditor());
    yield call(doPrepareForExistingSysParamSystemValueSaga, action.payload);
    yield put(showModal({
        modalType: SYS_PARAM_SYSTEM_VALUE_DELETE_MODAL,
        modalProps: {},
    }));
}

export function* showSysParamSystemValueEditorSaga() {
    yield takeLatest(SHOW_SYS_PARAM_SYSTEM_VALUE_EDITOR, doShowSysParamSystemValueEditorSaga);
}

export function* showSysParamSystemValueDeletionDialogSaga() {
    yield takeLatest(SHOW_DELETE_SYS_PARAM_SYSTEM_VALUE_DIALOG, doShowSysParamSystemValueDeletionDialogSaga);
}
