import {
    SHOW_DELIVERABLE_FILE_DELETION_DIALOG,
} from '~/features/artifacts/actions/deliverableActions';

export const UPLOAD_DELIVERABLE_FILE = 'UPLOAD_DELIVERABLE_FILE';
export const FETCH_DELIVERABLE_FILES = 'FETCH_DELIVERABLE_FILES';
export const DELETE_DELIVERABLE_FILE = 'FETCH_DELIVERABLE_FILE';
export const DOWNLOAD_DELIVERABLE_FILE = 'DOWNLOAD_DELIVERABLE_FILE';

export function showDeliverableFileDeletionDialog(payload) {
    return {
        type: SHOW_DELIVERABLE_FILE_DELETION_DIALOG,
        payload,
    };
}

export const uploadDeliverableFile = (deliverableId, file) => {
    return {
        type: UPLOAD_DELIVERABLE_FILE,
        payload: {
            deliverableId,
            file,
        },
    };
};

export const fetchDeliverableFiles = ({ deliverableId, page }) => {
    return {
        type: FETCH_DELIVERABLE_FILES,
        payload: {
            deliverableId,
            page,
        },
    };
};

export const deleteDeliverableFile = (deliverableId, fileName) => {
    return {
        type: DELETE_DELIVERABLE_FILE,
        payload: {
            deliverableId,
            fileName,
        },
    };
};

export const downloadDeliverableFile = (deliverableId, fileName) => {
    return {
        type: DOWNLOAD_DELIVERABLE_FILE,
        payload: {
            deliverableId,
            fileName,
        },
    };
};
