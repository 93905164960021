import find from 'lodash/fp/find';
import includes from 'lodash/fp/includes';
import size from 'lodash/fp/size';
import noop from 'lodash/fp/noop';
import isString from 'lodash/fp/isString';

import PropTypes from 'prop-types';
import Group from '~/prop-types/groupPropType';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { groupColumnDescriptors } from '~/features/groups/constants/groupColumnDescriptors';

import { groupSearchValue } from '~/features/groups/selectors/groupSelectors';
import { connect } from 'react-redux';
import ListTable from '~/features/base/components/table/ListTable';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';

export class GroupListTable extends Component {

    constructor(props) {
        super(props);

        this.actionsColumn = {
            id: 'actions',
            field: 'name',
            className: 'table-action',
            format: field => {
                const items = [];
                const { canUpdateGroups, canDeleteGroups } = this.props;
                if (canUpdateGroups) {
                    items.push({
                        value: (
                            <div key={'editGroup'} className='' onClick={this.onEditButtonClick} data-key={field}>
                                <span className='rioglyph rioglyph-pencil margin-right-10'></span>
                                <span><FormattedMessage id='intl-msg:editGroup'/></span>
                            </div>
                        ),
                    });
                }
                if (canDeleteGroups) {
                    items.push({
                        value: (
                            <div key={'deleteGroup'} onClick={this.onDeleteButtonClick} data-key={field}>
                                <span className='rioglyph rioglyph-trash margin-right-10'></span>
                                <span><FormattedMessage id='intl-msg:deleteGroup'/></span>
                            </div>
                        ),
                    });
                }
                if (size(items)) {
                    return (
                        <ButtonDropdown
                            key={'btnDropdown'}
                            title={<span className='rioglyph rioglyph-option-vertical'/>}
                            className='pull-right'
                            bsStyle='link'
                            iconOnly
                            items={items}/>
                    );
                }
            },
            formatLabel: () => '',
        };
    }

    checkRecursively(node, nodeType, className) {
        if (node.localName === nodeType) {
            return includes(className, node.classList);
        } else if (node.localName === 'td') {
            return false;
        }
        return this.checkRecursively(node.parentNode, nodeType, className);
    }

    onRowClick = (event) => {
        if (isString(event) || event.target.localName === 'input') {
            return;
        }
        const itemKey = event.currentTarget.getAttribute('data-key');
        const isButton = this.checkRecursively(event.target, 'button', 'btn');
        const isDropdown = this.checkRecursively(event.target, 'ul', 'dropdown-menu');
        const isSelection = this.checkRecursively(event.target, 'td', 'selection');
        const isClickable = this.checkRecursively(event.target, 'span', 'clickable');
        if (itemKey) {
            if (isButton) {
                return;
            }
            if (isDropdown) {
                return;
            }
            if (isClickable) {
                return;
            }
            if (isSelection) {
                this.props.onSelectItem(itemKey);
            } else {
                this.props.onShowItem(itemKey);
            }
        }
    };

    onEditButtonClick = (event) => {
        const key = event.currentTarget.getAttribute('data-key');
        const group = find(item => item.name === key, this.props.groups);
        this.props.onEditGroup({
            isNew: false,
            ...group,
        });
    };
    onDeleteButtonClick = (event) => {
        const key = event.currentTarget.getAttribute('data-key');
        const group = find(item => item.name === key, this.props.groups);
        this.props.onDeleteGroup(group);
    };

    // TODO Why do we need this?
    onSelectAllClick = () => {
        this.props.onSelectAll();
    };

    render() {
        const { groups, selectedItems, selectedAll, searchValue } = this.props;
        const columns = [...groupColumnDescriptors, this.actionsColumn];
        return [
            <ListTable items={groups}
                       searchValue={searchValue}
                       itemKey='name'
                       columnDescriptors={columns}
                       allowSelection={false}
                       allowSorting={true}
                       selectedItems={selectedItems}
                       selectedAll={selectedAll}
                       onRowClick={this.onRowClick}
                       onSelectItem={this.props.onSelectItem}
                       onSelectAll={this.props.onSelectAll}
                       onShowItem={this.props.onShowItem}/>,
        ];
    }
}

export const mapStateToProps = (state) => {
    return {
        searchValue: groupSearchValue(state),
    };
};

export default connect(mapStateToProps, null)(GroupListTable);

GroupListTable.defaultProps = {
    // props
    canUpdateGroups: false,
    canDeleteGroups: false,
    groups: [],
    selectedItems: [],
    selectedAll: false,
    // functions
    onSelectItem: noop,
    onSelectAll: noop,
    onShowItem: noop,
    onEditGroup: noop,
    onDeleteGroup: noop,
};

GroupListTable.propTypes = {
    // props
    canUpdateGroups: PropTypes.bool,
    canDeleteGroups: PropTypes.bool,
    groups: PropTypes.arrayOf(Group),
    selectedItems: PropTypes.array,
    selectedAll: PropTypes.bool,
    // functions
    onSelectItem: PropTypes.func,
    onSelectAll: PropTypes.func,
    onShowItem: PropTypes.func,
    onEditGroup: PropTypes.func,
    onDeleteGroup: PropTypes.func,
};
