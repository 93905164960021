import defaults from 'lodash/fp/defaults';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { followRoute } from '~/features/base/actions/ui/routeActions';

import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';
import { baseSwVersionsSelector } from '~/features/baseSwVersions/selectors/baseSwVersionSelectors';

import { ALL, createContextOptions } from '~/features/base/constants/filterOptions';

import { parseQuery } from '~/features/base/utils/query';
import { deliverablesContextsSelector } from '~/features/artifacts/selectors/deliverableSelectors';
import ContextSelectFormItem from '~/features/base/components/forms/ContextSelectFormItem';
import DeliverableNamePrefixFormItem from '~/features/base/components/forms/DeliverableNamePrefixFormItem';
import { resetDeliverables } from '~/features/artifacts/actions/deliverableActions';

const defaultFilters = {
    context: ALL,
    deliverableNamePrefix: '',
};

export class DeliverableFiltersContainer extends PureComponent {

    onSearchFilterChange = ({ deliverableNamePrefix }) => {
        this.updateFilter({
            deliverableNamePrefix,
        });
    };

    onDeliverableContextFilterChange = ({ context }) => {
        this.props.resetDeliverables();
        this.updateFilter({
            context,
        });
    };

    clearFilters = () => {
        this.updateFilter({
            deliverableNamePrefix: '',
            context: undefined,
        });
    };

    updateFilter(filters) {
        const { pathname, search } = this.props;
        const query = parseQuery(search);
        this.props.followRoute({
            route: pathname, query: {
                ...query,
                ...filters,
                page: 1,
            },
        });
    }

    render() {
        const { search, contexts } = this.props;
        const parsedQuery = parseQuery(search);
        const defaultedQuery = defaults(defaultFilters, parsedQuery);

        const contextOptions = createContextOptions(contexts, true);

        return (
            <div className='margin-20'>
                <form>
                    <DeliverableNamePrefixFormItem value={defaultedQuery.deliverableNamePrefix}
                                                   onChange={this.onSearchFilterChange}/>
                    <div className='form-group'>
                        <ContextSelectFormItem value={defaultedQuery.context}
                                               options={contextOptions}
                                               onChange={this.onDeliverableContextFilterChange}/>
                    </div>
                    <div className='form-group pull-right margin-top-15'>
                        <a id='clear-filters-button'
                           className='btn btn-default'
                           onClick={this.clearFilters}>
                            <span className='rioglyph rioglyph-trash' aria-hidden='true'></span>&nbsp;
                            <FormattedMessage id='intl-msg:clearFilters'/>
                        </a>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    pathname: pathnameSelector(state),
    search: searchSelector(state),
    contexts: deliverablesContextsSelector(state),
    baseSoftwareVersions: baseSwVersionsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    followRoute: (options) => {
        dispatch(followRoute(options));
    },
    resetDeliverables: () => {
        dispatch(resetDeliverables());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableFiltersContainer);

DeliverableFiltersContainer.defaultProps = {
    // props
    pathname: '',
    search: '',
    // functions
    followRoute: noop,
    fetchBaseSwVersions: noop,
    resetDeliverables: noop,
};

DeliverableFiltersContainer.propTypes = {
    // props
    pathname: PropTypes.string,
    search: PropTypes.string,
    // functions
    followRoute: PropTypes.func,
    fetchBaseSwVersions: PropTypes.func,
    resetDeliverables: PropTypes.func,
};
