import {
    SET_DELIVERABLE_FILES,
} from '~/features/artifacts/actions/deliverableActions';

import { keyBy } from 'lodash/collection';

const defaultState = {};

const setDeliverableFiles = (state, action) => {
    state[action.deliverableId] = keyBy(action.deliverableFiles, 'fileName');
    return state;
};

export default function deliverableFilesReducer(state = defaultState, action = {}) {
    switch (action.type) {
        case SET_DELIVERABLE_FILES:
            return setDeliverableFiles(state, action);
        default:
            return state;
    }
}
