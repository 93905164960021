import PropTypes from 'prop-types';

export const WhitelistingInfo = PropTypes.shape({
    whitelistedForAll: PropTypes.bool,
    deviceWhitelist: PropTypes.arrayOf(PropTypes.string),
    deviceWhitelistByVINs: PropTypes.arrayOf(PropTypes.string),
    deviceBlacklist: PropTypes.arrayOf(PropTypes.string),
    vehicleGroupWhitelist: PropTypes.arrayOf(PropTypes.string),
    vehicleGroupBlacklist: PropTypes.arrayOf(PropTypes.string),
});
