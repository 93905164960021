import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import NoData from '~/features/base/components/NoData';

export const Priority = ({ priority }) => {
   if (priority)
       return (
        <OverlayTrigger placement='top' overlay={
            <Tooltip id='tooltip' className='top-right'>
                <FormattedMessage id='intl-msg:priority.tooltip'/>
            </Tooltip>}>
            <span className={'label label-condensed label-primary'}>
                <FormattedMessage id={`intl-msg:priority.${priority?.toLocaleLowerCase()}`}/>
            </span>
        </OverlayTrigger> );
   return <NoData/>
};

export default Priority;

Priority.propTypes = {
    // props
    priority: PropTypes.string,
};
