import noop from 'lodash/fp/noop';
import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import ContextSuggestFormItem from '~/features/base/components/forms/ContextSuggestFormItem';
import { createContextOptions } from '~/features/base/constants/filterOptions';
import FilePicker from '@rio-cloud/rio-uikit/lib/es/FilePicker';

export class ArtifactEditorForm extends PureComponent {

    onArtifactNameChange = (event) => {
        this.props.changeArtifactEditor({
            artifactName: event.target.value,
        });
    };

    onContextNameChange = (event) => {
        this.props.changeArtifactEditor({
            owningContext: event.context,
        });
    };

    onFileSelect = (file) => {
        this.props.changeArtifactEditor({
            file: file,
        });
    };

    render() {
        const { model, contexts } = this.props;
        const artifactNameInput = this.renderArtifactNameInput(model);
        const artifactContextInput = this.renderArtifactContextInput(model, contexts);
        const artifactSelectedFileInput = this.renderArtifactSelectedFileInput(model);

        return (
            <form>
                {artifactNameInput}
                {/*{artifactContextInput}*/}
                {artifactSelectedFileInput}
            </form>
        );
    }

    renderArtifactNameInput(model) {
        return (
            <div className='form-group form-group-file-id'>
                <label className='control-label'>
                    <FormattedMessage id='intl-msg:deliverableName'/>
                </label>
                <FormattedMessage id='intl-msg:deliverableName.prompt'>
                    {placeHolder =>
                        <input type='text' className='form-control'
                               value={model.deliverableName}
                               placeholder={placeHolder}
                               onChange={this.onArtifactNameChange}/>
                    }
                </FormattedMessage>
            </div>
        );
    }

    renderArtifactContextInput(model, contexts) {
        const contextOptions = createContextOptions(contexts, false);
        return (
            <div className='form-group form-group-file-id'>
                <ContextSuggestFormItem value={model.context}
                                        options={contextOptions}
                                        onChange={this.onContextNameChange}/>
            </div>
        );
    }

    renderArtifactSelectedFileInput(model) {
        const fileName = model.file ? model.file[0].name : '';
        return (
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-1'>
                        <FilePicker label={<FormattedMessage id='intl-msg:addFile'/>}
                                    onPick={(acceptedFiles) => this.onFileSelect(acceptedFiles)}/>
                    </div>
                    <div className='col-sm-2'>
                        <label>
                            {fileName}
                        </label>
                    </div>
                </div>
            </div>
        );
    }

}

export default ArtifactEditorForm;

ArtifactEditorForm.defaultProps = {
    // props
    model: {
        artifactName: '',
        type: '',
        otaId: '',
        otaVersion: '',
        context: '',
        file: undefined,
    },
    contexts: [],
    // functions
    changeArtifactEditor: noop,
};

ArtifactEditorForm.propTypes = {
    // props
    model: {
        artifactName: PropTypes.string,
        type: PropTypes.string,
        otaId: PropTypes.string,
        otaVersion: PropTypes.string,
        context: PropTypes.string,
        file: PropTypes.object,
    },
    contexts: PropTypes.array,
    // functions
    changeArtifactEditor: PropTypes.func,
};
