import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { deleteDeliverable } from '~/features/artifacts/actions/deliverableActions';
import { deliverableEditorSelector } from '~/features/artifacts/selectors/deliverableSelectors';
import { DeliverableFileDeletionDialog } from '~/features/artifacts/components/DeliverableFileDeletionDialog';
import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';

/**
 * Deliverable deletion dialog
 */
export class DeliverableDeletionDialog extends PureComponent {

    onDeleteDeliverable = () => {
        const { deliverableId, deleteDeliverable } = this.props;
        deleteDeliverable(deliverableId);
    };

    render() {
        const { deliverableName } = this.props;
        return (
            <ConfirmationDialog className='file-deliverable-deletion-dialog'
                                show={true}
                                title={<FormattedMessage id='intl-msg:deleteArtifact'/>}
                                content={<FormattedMessage id='intl-msg:deleteArtifact.prompt' values={{
                                    deliverableName,
                                }}/>}
                                cancelButtonText={<FormattedMessage id='intl-msg:cancel'/>}
                                confirmButtonText={<FormattedMessage id='intl-msg:ok'/>}
                                onClickCancel={this.props.hideModal}
                                onClickConfirm={this.onDeleteDeliverable}/>
        );
    }
}

export const mapStateToProps = (state) => {
    return {};
};

export const mapDispatchToProps = (dispatch) => {
    return {
        deleteDeliverable: (deliverableId) => {
            dispatch(deleteDeliverable(deliverableId));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableDeletionDialog);

DeliverableDeletionDialog.defaultProps = {
    // functions
    deleteDeliverable: noop,
};

DeliverableDeletionDialog.propTypes = {
    // functions
    deleteDeliverable: PropTypes.func,
};
