import noop from 'lodash/fp/noop';
import isEmpty from 'lodash/fp/isEmpty';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import DeviceAssociatedDeliverablesForm
    from '~/features/devices/components/dialogs/associatedDeliverables/DeviceAssociatedDeliverablesForm';
import { fetchControlDeviceReferencedDeliverables } from '~/features/devices/actions/controlDeviceAssociatedDeliverablesActions';
import { hotkeys } from 'react-keyboard-shortcuts';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';

export const DEVICE_ASSOCIATED_DELIVERABLE_MODE_WHITELISTED = 'WHITELISTED';
export const DEVICE_ASSOCIATED_DELIVERABLE_MODE_BLACKLISTED = 'BLACKLISTED';

export class DeviceAssociatedDeliverablesDialog extends PureComponent {
    constructor() {
        super();
        this.state = {
            selectedDeliverableType: DEVICE_ASSOCIATED_DELIVERABLE_MODE_WHITELISTED,
        };
    }

    onDeliverableTypeChange = (selectedDeliverableType) => {
        this.setState({
            ...this.state,
            selectedDeliverableType,
        }, () => this.props.fetchControlDeviceAssociatedDeliverables(this.props.serialNumber,
            this.state.selectedDeliverableType));
    };

    isValid(model) {
        return !isEmpty(model.selectedDeliverableVersion);
    }

    // eslint-disable-next-line camelcase
    hot_keys = {
        'esc': {
            priority: 1,
            handler: () => {
                this.props.hideModal();
            },
        },
    };

    renderTitle() {
        return (
            <FormattedMessage id="intl-msg:controlDeviceAssociatedDeliverables.title"/>
        );
    }

    renderBody() {
        const { serialNumber } = this.props;
        return (
            <DeviceAssociatedDeliverablesForm
                onDeliverableTypeChange={this.onDeliverableTypeChange}
                changeControlDeviceQuickWhitelisting={payload => this.props.changeControlDeviceQuickWhitelisting(
                    payload)}
                serialNumber={serialNumber}
            />
        );
    }

    renderFooter() {
        return (
            <div>
                <button className="btn btn-default" onClick={this.props.hideModal}>
                    <FormattedMessage id="intl-msg:close"/>
                </button>
            </div>
        );
    }

    render() {
        const title = this.renderTitle();
        const body = this.renderBody();
        const footer = this.renderFooter();
        return (
            <Dialog className="device-editor-dialog"
                    show={true}
                    showCloseButton={true}
                    onHide={this.props.hideModal}
                    title={title}
                    body={body}
                    footer={footer}
                    useOverflow
            />
        );
    }

    componentDidMount() {
        this.props.fetchControlDeviceReferencedDeliverables(this.props.serialNumber,
            this.state.selectedDeliverableType);
    }
}

export const mapStateToProps = (state) => {
    return {};
};

export const mapDispatchToProps = (dispatch) => {
    return {
        fetchControlDeviceReferencedDeliverables: (serialNumber, selectedDeliverableType) => {
            dispatch(fetchControlDeviceReferencedDeliverables(serialNumber, selectedDeliverableType));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(hotkeys(DeviceAssociatedDeliverablesDialog));

DeviceAssociatedDeliverablesDialog.defaultProps = {
    // props
    serialNumber: '',
    mode: '',
    model: undefined,
    // functions
    fetchControlDeviceReferencedDeliverables: noop,
    addControlDeviceToDeliverableWhitelist: noop,
    changeControlDeviceQuickWhitelisting: noop,
    removeControlDeviceFromDeliverableWhitelist: noop,
};

DeviceAssociatedDeliverablesDialog.propTypes = {
    // props
    serialNumber: PropTypes.string,
    mode: PropTypes.string,
    model: PropTypes.object,
    // functions
    fetchControlDeviceReferencedDeliverables: PropTypes.func,
    addControlDeviceToDeliverableWhitelist: PropTypes.func,
    changeControlDeviceQuickWhitelisting: PropTypes.func,
    removeControlDeviceFromDeliverableWhitelist: PropTypes.func,
};
