import noop from 'lodash/fp/noop';
import size from 'lodash/fp/size';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';


import JiraIssueEditorForm from '~/features/atlassian/components/dialogs/JiraIssueEditorForm';
import { atlassianJiraIssueEditorSelector } from '~/features/atlassian/selectors/atlassianJiraSelectors';
import {
    changeJiraIssueEditor,
    createJiraIssue,
    findJiraIssues,
} from '~/features/atlassian/actions/atlassianJiraActions';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';

export class JiraIssueEditorDialog extends PureComponent {

    onCreateJiraIssue = () => {
        const model = this.props.model;
        if (!this.isValid(model)) {
            return;
        }
        this.props.createJiraIssue(model);
    };

    isValid(model) {
        return size(this.props.model.summary) > 0;
    }

    renderTitle() {
        return <FormattedMessage id='intl-msg:jiraBugTickets'/>;
    }

    renderBody(model) {
        return (
            <JiraIssueEditorForm model={model}
                                 changeJiraIssueEditor={payload => this.props.changeJiraIssueEditor(
                                     payload)}
                                 onCreateJiraIssue={this.onCreateJiraIssue}
            />
        );
    }

    renderFooter(model) {
        return (
            <div className={'btn-toolbar justify-content-end'} >
                <button className='btn btn-default' onClick={this.props.hideModal}>
                    <FormattedMessage id='intl-msg:close'/>
                </button>
            </div>
        );
    }

    render() {
        const { model } = this.props;
        const title = this.renderTitle(model);
        const body = this.renderBody(model);
        const footer = this.renderFooter(model);
        return (
            <Dialog className='device-editor-dialog'
                    show={true}
                    showCloseButton={true}
                    onHide={this.props.hideModal}
                    title={title}
                    body={body}
                    footer={footer}
                    useOverflow
            />
        );
    }

    componentDidMount() {
        const { controlDevice } = this.props.model;
        this.props.findJiraIssues({ serialNumber: controlDevice.serialNumber });
    }
}

export const mapStateToProps = (state) => {
    return {
        model: atlassianJiraIssueEditorSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        createJiraIssue: payload => {
            dispatch(createJiraIssue(payload));
        },
        changeJiraIssueEditor: payload => {
            dispatch(changeJiraIssueEditor(payload));
        },
        findJiraIssues: payload => {
            dispatch(findJiraIssues(payload));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(JiraIssueEditorDialog);

JiraIssueEditorDialog.defaultProps = {
    // props
    model: undefined,
    // functions
    createJiraIssue: noop,
    changeJiraIssueEditor: noop,
    findJiraIssues: noop,
};

JiraIssueEditorDialog.propTypes = {
    // props
    model: PropTypes.object,
    // functions
    createJiraIssue: PropTypes.func,
    changeJiraIssueEditor: PropTypes.func,
    findJiraIssues: PropTypes.func,
};
