import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    controlDeviceEligiblePackageVersionsSelector,
    controlDeviceInfoSelector,
} from '~/features/devices/selectors/controlDeviceSelectors';

import DevicePackages from '~/features/devices/components/DevicePackages';
import noop from 'lodash/fp/noop';
import uid from '~/features/base/utils/uid';

/**
 * Container for the device packages
 */
export class DevicePackagesContainer extends PureComponent {

    constructor(props) {
        super(props);
        this.name = uid();
    }

    render() {
        const { controlDeviceInfo, controlDeviceEligiblePackageVersions } = this.props;
        return (
            <DevicePackages controlDeviceInfo={controlDeviceInfo}
                            controlDeviceEligiblePackageVersions={controlDeviceEligiblePackageVersions}/>
        );
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        controlDeviceInfo: controlDeviceInfoSelector(state, ownProps),
        controlDeviceEligiblePackageVersions: controlDeviceEligiblePackageVersionsSelector(state, ownProps),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DevicePackagesContainer);

DevicePackagesContainer.defaultProps = {
    // props
    serialNumber: '',
    controlDeviceInfo: undefined,
    controlDeviceEligiblePackageVersions: undefined,
    // functions
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    triggerDataFetcher: noop,
};

DevicePackagesContainer.propTypes = {
    // props
    serialNumber: PropTypes.string,
    controlDeviceInfo: PropTypes.object,
    controlDeviceEligiblePackageVersions: PropTypes.object,
    // functions
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
};
