import size from 'lodash/fp/size';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';
import noop from 'lodash/fp/noop';
import { parseQuery } from '~/features/base/utils/query';
import { fetchDeliverableFiles } from '~/features/artifacts/actions/deliverableFileActions';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import { connect } from 'react-redux';
import {
    allDeliverableFilesSelector,
    deliverableFilesLoadingSelector,
} from '~/features/artifacts/selectors/deliverableFileSelectors';
import DeliverableFilesTable from '~/features/artifacts/components/DeliverableFilesTable';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';

/**
 * Component for the deliverable files
 */
export class DeliverableFiles extends PureComponent {
    render() {
        const { deliverableFilesLoading, deliverableId, deliverableFilesMap } = this.props;
        const deliverableFiles = deliverableFilesMap[deliverableId];

        if (!deliverableId) {
            return null;
        }
        if (deliverableFilesLoading) {
            return (
                <DefaultWhiteColumn className='padding-top-20 padding-bottom-20'>
                    <Spinner/>
                </DefaultWhiteColumn>
            );
        }
        if (!size(deliverableFiles)) {
            return (
                <DefaultWhiteColumn className='padding-top-10'>
                    <NotFoundState fullWidth
                                   headline={<FormattedMessage id='intl-msg:nothingFound'/>}
                                   message={<FormattedMessage id='intl-msg:noFileVersionsAvailableYet'/>}/>
                </DefaultWhiteColumn>
            );
        }
        return (
            <DefaultWhiteColumn className='padding-top-10'>
                <DeliverableFilesTable deliverableId={deliverableId} files={deliverableFiles}/>
            </DefaultWhiteColumn>
        );
    }

    registerDataInterest() {
        const { deliverableId, search } = this.props;
        const parsedQuery = parseQuery(search);
        const { page } = parsedQuery;

        this.props.registerDataInterest(this.name, [
            fetchDeliverableFiles({
                page: (page && page > 0) ? (page - 1) : 0,
                deliverableId,
            }),
        ]);
    }

    componentWillMount() {
        this.registerDataInterest();
        this.props.triggerDataFetcher();
        this.interval = setInterval(() => {
            this.props.triggerDataFetcher();
        }, 60000);
    }

    componentWillUnmount() {
        this.props.unregisterDataInterest(this.name);
        clearInterval(this.interval);
    }

    // FIXME REACT16 Should probably be componentDidUpdate(prevProps, prevState, snapshot)
    componentWillReceiveProps(nextProps) {
        const { search } = nextProps;
        if (search !== this.props.search) {
            this.registerDataInterest();
            nextProps.triggerDataFetcher();
        }
    }
}

export const mapStateToProps = (state) => {
    return {
        deliverableFilesMap: allDeliverableFilesSelector(state),
        deliverableFilesLoading: deliverableFilesLoadingSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        triggerDataFetcher: () => {
            dispatch(triggerDataFetcher());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableFiles);

DeliverableFiles.defaultProps = {
    // props
    filesLoading: false,
    deliverableId: '',
    deliverableFiles: [],
    // functions
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    triggerDataFetcher: noop,
};

DeliverableFiles.propTypes = {
    // props
    filesLoading: PropTypes.bool,
    controlDeviceInfo: PropTypes.object,
    deliverableId: PropTypes.string,
    deliverableFiles: PropTypes.array,
    // functions
};
