import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// Routes
import {
    DELIVERABLES_APPS_PATH,
    DELIVERABLES_DISTROS_PATH, DELIVERABLES_FILES_PATH,
    REPORTS_BILLING_PATH,
} from '~/features/base/constants/routes';
// files
import ReportsBillingContainer from '~/features/reportsbilling/components/ReportsBillingContainer';
import { sendGoogleAnalyticsEvent } from '~/index';
import DeliverableContainer from '~/features/deliverables/components/DeliverableContainer';

export const ReportsBillingRoute = ({ match }) => {
    // send Google Analytics Event
    sendGoogleAnalyticsEvent('Reports Billing Page (New)', 'View Reports Billing (New)');
    return (
        <Switch>
            {/*<Route path={`${match.path}/:deliverableType?/:deliverableId?/:deliverableVersion?`}*/}
            {/*       component={ReportsBillingContainer}/>*/}
            <Route path={`${match.path}`} component={ReportsBillingContainer}/>
            <Redirect to={`${match.url}`}/>
        </Switch>
    );
};

export default ReportsBillingRoute;
