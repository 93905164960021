import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import NumberInput from '@rio-cloud/rio-uikit/lib/es/NumberInput';

export class DistroVersionInputFormItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clearing: false,
        };
        this.onYearChange = this.onYearChange.bind(this);
        this.onWeekChange = this.onWeekChange.bind(this);
        this.onNumberChange = this.onNumberChange.bind(this);
        this.onClearButtonClick = this.onClearButtonClick.bind(this);
    }

    onYearChange(year) {
        if (this.state.clearing) {
            return;
        }
        this.props.onChange({
            ...this.props.value,
            year,
        });
    }
    onWeekChange(week) {
        if (this.state.clearing) {
            return;
        }
        this.props.onChange({
            ...this.props.value,
            week,
        });
    }
    onNumberChange(number) {
        if (this.state.clearing) {
            this.setState({
                clearing: false,
            });
            return;
        }
        this.props.onChange({
            ...this.props.value,
            number,
        });
    }
    onClearButtonClick() {
        this.setState({
            clearing: true,
        });
        this.props.onChange(undefined);
    }

    render() {
        const { label, value } = this.props;
        const clearButton = this.renderClearButton();
        return (
            <div className='form-group form-group-distro-version'>
                <label className='control-label'>
                    <FormattedMessage id={label} />
                </label>
                <div className='row'>
                    <div className='col-xs-3 col-sm-3 col-md-2'>
                        <NumberInput min={0} value={value.year}
                            onValueChanged={this.onYearChange}/>
                    </div>
                    <div className='col-xs-3 col-sm-3 col-md-2'>
                        <NumberInput min={0} value={value.week}
                            onValueChanged={this.onWeekChange}/>
                    </div>
                    <div className='col-xs-3 col-sm-3 col-md-2'>
                        <NumberInput min={0} value={value.number}
                            onValueChanged={this.onNumberChange}/>
                    </div>
                    {clearButton}
                </div>
            </div>
        );
    }

    renderClearButton() {
        if (this.props.isClearable) {
            return (
                <div className='col-xs-3 col-sm-3 col-md-6'>
                    <FormattedMessage id='intl-msg:clear'>
                        {
                            clearMessage => (
                                <button type='button' title={clearMessage}
                                    className='btn btn-muted btn-icon-only'
                                    onClick={this.onClearButtonClick}>
                                    <span className='rioglyph rioglyph-remove-sign' aria-hidden='true'></span>
                                </button>
                            )
                        }
                    </FormattedMessage>
                </div>
            );
        }
    }
}

export default DistroVersionInputFormItem;

DistroVersionInputFormItem.defaultProps = {
    // props
    label: 'intl-msg:distroVersion',
    value: {
        year: 0,
        week: 0,
        number: 0,
    },
    isClearable: false,
    // functions
    onChange: noop,
};

DistroVersionInputFormItem.propTypes = {
    // props
    label: PropTypes.string,
    value: PropTypes.object,
    isClearable: PropTypes.bool,
    // functions
    onChange: PropTypes.func,
};
