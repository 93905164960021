export const DETAILS = 'DETAILS';
export const PACKAGES = 'PACKAGES';
export const FILES = 'FILES';
export const DELIVERABLES = 'DELIVERABLES';
export const UPDATE_EVENTS = 'UPDATE_EVENTS';
export const REPORT = 'REPORT';
export const LOGS = 'LOGS';
export const LOGSNEO = 'LOGSNEO';
export const SYSTEM_PARAMETERS = 'SYSTEM_PARAMETERS';
export const ADAPT_ORDERS = 'ADAPT_ORDERS';
