import { SAVE_FILTER_SETTINGS, SET_SHOW_FILTER_VIEW } from '~/features/base/actions/ui/filterActions';

const defaultState = {
    showFilters: true,
    filterSettings: [],
}

export const showFilterSettings = (state, { payload }) => {
    const newState = {
        ...state,
        showFilters: payload,
    };
    return newState;
};

export const saveFilterSettings = (state, { filterName, payload }) => {
    const filterSettings = {
        ...filterSettings,
        [filterName]: payload,
    }
    const newState = {
        ...state,
        filterSettings
    };
    return newState;
};

export const filtersReducer = (state = defaultState, action = {}) => {
    switch (action.type) {
        case SET_SHOW_FILTER_VIEW:
            return showFilterSettings(state, action);
        case SAVE_FILTER_SETTINGS:
            return saveFilterSettings(state, action);
        default:
            return state;
    }
};

export default filtersReducer;
