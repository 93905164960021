import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import map from 'lodash/fp/map';

import { createSelector } from 'reselect';

import {
    entityStoreSelector,
    indicesStoreSelector,
    loadingStoreSelector,
    aggregationStoreSelector,
} from '~/features/higherorder/selectors/dataSelectors';
import {
    ENTITY_UPDATE_EVENT,
    ENTITY_UPDATE_EVENT_SCOPE_DEVICE,
    ENTITY_UPDATE_EVENT_SCOPE_DISTRO,
    ENTITY_UPDATE_EVENT_SCOPE_PACKAGE,
    ENTITY_UPDATE_EVENT_SCOPE_ALL,
    ENTITY_UPDATE_EVENT_REPORT,
    ENTITY_UPDATE_EVENT_SCOPE_FILE,
} from '~/features/base/constants/entities';

const updateEventAggregationsSelector = state => aggregationStoreSelector(state)[ENTITY_UPDATE_EVENT];
const updateEventReportAggregationsSelector = state => aggregationStoreSelector(state)[ENTITY_UPDATE_EVENT_REPORT];
const updateEventEntitiesSelector = state => entityStoreSelector(state)[ENTITY_UPDATE_EVENT];
const updateEventIndicesSelector = state => indicesStoreSelector(state)[ENTITY_UPDATE_EVENT];
const updateEventsLoadingSelector = state => loadingStoreSelector(state)['updateEvents'];

export const updateEventCountSelector = (state, ownProps) => {
    return getOr(0, ownProps.scope,
        updateEventAggregationsSelector(state));
};

export const updateEventsSuccessAndFailedSelector = (state, ownProps) => {
    return getOr({ success: 0, failed: 0 }, ownProps.scope,
        updateEventAggregationsSelector(state));
};

export const updateEventStatsSelector = (state, ownProps) => {
    return getOr([], ownProps.scope,
        updateEventAggregationsSelector(state));
};
export const updateEventReportSelector = (state, ownProps) => {
    return getOr({ success: 0, stats: [], trend: [] }, ownProps.scope,
        updateEventReportAggregationsSelector(state));
};

export const allUpdateEventsLoadingSelector = state => getOr(false, ENTITY_UPDATE_EVENT_SCOPE_ALL,
    updateEventsLoadingSelector(state));
export const deviceUpdateEventsLoadingSelector = state => getOr(false, ENTITY_UPDATE_EVENT_SCOPE_DEVICE,
    updateEventsLoadingSelector(state));
export const distroUpdateEventsLoadingSelector = state => getOr(false, ENTITY_UPDATE_EVENT_SCOPE_DISTRO,
    updateEventsLoadingSelector(state));
export const packageUpdateEventsLoadingSelector = state => getOr(false, ENTITY_UPDATE_EVENT_SCOPE_PACKAGE,
    updateEventsLoadingSelector(state));
export const fileUpdateEventsLoadingSelector = state => getOr(false, ENTITY_UPDATE_EVENT_SCOPE_FILE,
    updateEventsLoadingSelector(state));

export const updateEventReportsLoadingSelector = (state, ownProps) => {
    return getOr(false, ownProps.scope,
        loadingStoreSelector(state)['updateEventReports']);
};

const allUpdateEventsPagesSelector = state => getOr({}, ENTITY_UPDATE_EVENT_SCOPE_ALL,
    updateEventIndicesSelector(state));
const deviceUpdateEventsPagesSelector = state => getOr({}, ENTITY_UPDATE_EVENT_SCOPE_DEVICE,
    updateEventIndicesSelector(state));
const distroUpdateEventsPagesSelector = state => getOr({}, ENTITY_UPDATE_EVENT_SCOPE_DISTRO,
    updateEventIndicesSelector(state));
const packageUpdateEventsPagesSelector = state => getOr({}, ENTITY_UPDATE_EVENT_SCOPE_PACKAGE,
    updateEventIndicesSelector(state));
const fileUpdateEventsPagesSelector = state => getOr({}, ENTITY_UPDATE_EVENT_SCOPE_FILE,
    updateEventIndicesSelector(state));

// TODO Redo as structure
export const allUpdateEventsPageNumberSelector = state => get('number', allUpdateEventsPagesSelector(state));
export const allUpdateEventsPageCountSelector = state => get('totalPages', allUpdateEventsPagesSelector(state));
export const allUpdateEventsTotalElementsSelector = state => get('totalElements', allUpdateEventsPagesSelector(state));

// TODO Redo as structure
export const deviceUpdateEventsPageNumberSelector = state => get('number', deviceUpdateEventsPagesSelector(state));
export const deviceUpdateEventsPageCountSelector = state => get('totalPages', deviceUpdateEventsPagesSelector(state));
export const deviceUpdateEventsTotalElementsSelector = state => get('totalElements', deviceUpdateEventsPagesSelector(state));

// TODO Redo as structure
export const distroUpdateEventsPageNumberSelector = state => get('number', distroUpdateEventsPagesSelector(state));
export const distroUpdateEventsPageCountSelector = state => get('totalPages', distroUpdateEventsPagesSelector(state));
export const distroUpdateEventsTotalElementsSelector = state => get('totalElements', distroUpdateEventsPagesSelector(state));

// TODO Redo as structure
export const packageUpdateEventsPageNumberSelector = state => get('number', packageUpdateEventsPagesSelector(state));
export const packageUpdateEventsPageCountSelector = state => get('totalPages', packageUpdateEventsPagesSelector(state));
export const packageUpdateEventsTotalElementsSelector = state => get('totalElements', packageUpdateEventsPagesSelector(state));

// TODO Redo as structure
export const fileUpdateEventsPageNumberSelector = state => get('number', fileUpdateEventsPagesSelector(state));
export const fileUpdateEventsPageCountSelector = state => get('totalPages', fileUpdateEventsPagesSelector(state));
export const fileUpdateEventsTotalElementsSelector = state => get('totalElements', fileUpdateEventsPagesSelector(state));

/*
const packageUpdateEventSortedIdsSelector = (state, ownProps) =>
    get(ownProps.packageId, updateEventIndicesSelector(state));
const deviceUpdateEventSortedIdsSelector = (state, ownProps) =>
    get(ownProps.serialNumber, updateEventIndicesSelector(state));
*/
export const allUpdateEventsSelector = createSelector(
    [allUpdateEventsPagesSelector, updateEventEntitiesSelector, allUpdateEventsPageNumberSelector],
    (pages, entities, pageNumber) => {
        return map(id => entities[id], getOr([], pageNumber, pages));
    }
);

export const deviceUpdateEventsSelector = createSelector(
    [deviceUpdateEventsPagesSelector, updateEventEntitiesSelector, deviceUpdateEventsPageNumberSelector],
    (pages, entities, pageNumber) => {
        return map(id => entities[id], getOr([], pageNumber, pages));
    }
);

export const distroUpdateEventsSelector = createSelector(
    [distroUpdateEventsPagesSelector, updateEventEntitiesSelector, distroUpdateEventsPageNumberSelector],
    (pages, entities, pageNumber) => {
        return map(id => entities[id], getOr([], pageNumber, pages));
    }
);

export const packageUpdateEventsSelector = createSelector(
    [packageUpdateEventsPagesSelector, updateEventEntitiesSelector, packageUpdateEventsPageNumberSelector],
    (pages, entities, pageNumber) => {
        return map(id => entities[id], getOr([], pageNumber, pages));
    }
);

export const fileUpdateEventsSelector = createSelector(
    [fileUpdateEventsPagesSelector, updateEventEntitiesSelector, fileUpdateEventsPageNumberSelector],
    (pages, entities, pageNumber) => {
        return map(id => entities[id], getOr([], pageNumber, pages));
    }
);
