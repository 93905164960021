// This is the runtime configuration being used for production
// where authorization is done via the `authorization-server`.

const clientId = '8c10dd09-422c-4da3-b1d4-96d66d6428be';
const authority = 'https://login.microsoftonline.com/7e4c72b8-86d8-4e3a-90d8-4f7b7b2513c2';
const oauthService = `${authority}/oauth2/v2.0`;
const appUri = 'https://ota.admin-latam.rio.cloud';
const redirectUri = `${appUri}/redirect.html`;

export const productionLATAMConfig = {
    id: 'env.production.latam',
    region: 'LATAM',
    backend: {
        AUTHENTICATION_SERVICE: oauthService,
        DEVICE_MANAGEMENT_SERVICE: 'https://ota-service.device-ota-updates-latam.rio.cloud/api/mdmservice',
        DEVICE_SYSTEM_STATE_SERVICE: 'https://system-state.device-ota-updates-latam.rio.cloud/api/tbm3systemstatenotification',
        DELIVERABLE_MANAGEMENT_SERVICE: 'https://deliverable-mgmt.device-ota-updates-latam.rio.cloud',
        DEVICE_LOG_DOWNLOAD_SERVICE: 'https://log-service.device-ota-updates-latam.rio.cloud/api/tbm3log/v1/devices',
        LOG_SERVICE: 'https://mdm-logging-service.device-ota-updates.rio.cloud/api/mdm/logging',
        ATLASSIAN_CONNECTOR: 'https://atlassian-connector.device-ota-updates.rio.cloud/api/mdmotaac',
        REPORTS_BILLING_CHARTS_DATA_SERVICE: 'https://mdm-reporting-service.device-ota-updates.rio.cloud/api/mdm/reporting/v1/traffic/search',
        REPORTS_BILLING_CHARTS_DATA_VERSIONS_SERVICE: 'https://6182843e84c2020017d89efa.mockapi.io/dataTraffic/dataTrafficTypes',
    },
    homeRoute: 'https://admin-latam.rio.cloud',
    appMenuUri: 'https://admin-latam.rio.cloud/#app-menu',
    userMenuUri: 'https://admin-latam.rio.cloud/#user-menu',
    login: {
        authority: authority,
        clientId: clientId,
        resource: clientId,
        mockAuthorization: false,
        preventRedirect: false,
        redirectUri: redirectUri,
        silentRedirectUri: redirectUri,
    },
    logoutUri: `${oauthService}/logout?redirect_uri=https://admin-latam.rio.cloud`,
    downloadBaseUrl: 'https://transfer.rioconnect.eu/release/',
    packageVersionInstallerBaseUrl: 'https://transfer.rioconnect.eu/release/packages/app-installer_2020.09.04',
    showAdaptOrder: false,
    networkCodes: {
        0: { name: 'Unknown', iso2: 'xx' }, //used if network code is '000' || ''
        334: { name: 'Mexico', iso2: 'mx' },
        702: { name: 'Belize', iso2: 'bz' },
        704: { name: 'Guatemala', iso2: 'gt' },
        706: { name: 'El Salvador', iso2: 'sv' },
        708: { name: 'Honduras', iso2: 'hn' },
        710: { name: 'Nicaragua', iso2: 'ni' },
        712: { name: 'Costa Rica', iso2: 'cr' },
        714: { name: 'Panama', iso2: 'pa' },
        716: { name: 'Peru', iso2: 'pe' },
        722: { name: 'Argentina', iso2: 'ar' },
        724: { name: 'Brazil', iso2: 'br' },
        730: { name: 'Chile', iso2: 'cl' },
        732: { name: 'Colombia', iso2: 'co' },
        734: { name: 'Venezuela', iso2: 've' },
        736: { name: 'Bolivia', iso2: 'bo' },
        738: { name: 'Guyana', iso2: 'gy' },
        740: { name: 'Ecuador', iso2: 'ec' },
        742: { name: 'French Guiana (France)', iso2: 'gf' },
        744: { name: 'Paraguay', iso2: 'py' },
        746: { name: 'Suriname', iso2: 'sr' },
        748: { name: 'Uruguay', iso2: 'uy' },
    },
    networkCodesWhiteList: [702, 704, 706, 708, 710, 712, 714, 716, 722, 724, 730, 732, 734, 736, 738, 740, 742, 744, 746, 748],
};
