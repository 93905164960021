import map from 'lodash/fp/map';

import React from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';

import { logLevelOptions } from '~/features/deviceLogs/constants/logLevelOptions';
import { serviceOptionsWithAll, ALL } from '~/features/base/constants/serviceOptions';

import DeviceChip from '~/features/devices/components/DeviceChip';

export const deviceActionRowDescriptors = [{
    field: 'hwSerial',
    label: 'intl-msg:hwSerial',
    format: field => <DeviceChip serialNumber={field}/>,
}, {
    field: 'created',
    label: 'intl-msg:createdTimestamp',
    format: field => {
        if (field) {
            return <FormattedTime value={field} year='numeric' month='2-digit' day='2-digit'/>;
        }
    },
}, {
    field: 'logs',
    label: 'intl-msg:logs',
    format: field => {
        const logs = map(log => {
            // TODO Make component out of this
            return (
                <div>
                    <strong>{`${log.serviceId}, `}</strong>
                    {
                        !logLevelOptions[log.logLevel] ? null :
                            <span>({serviceOptionsWithAll[log.serviceId].name}), </span>
                    }
                    {
                        log.serviceId === ALL ? null :
                            <span><FormattedMessage id={logLevelOptions[log.logLevel].label}/>, </span>
                    }
                    <span>
                        <FormattedTime value={log.from} year='numeric' month='2-digit' day='2-digit'/>
                        <span> &rarr; </span>
                        <FormattedTime value={log.to} year='numeric' month='2-digit' day='2-digit'/>
                    </span>
                </div>
            );
        }, field);
        return logs;
    },
}];
