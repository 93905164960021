import React, { FC } from 'react';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import { FormattedMessage } from 'react-intl';
import FieldOrNoData from '~/features/base/components/FieldOrNoData';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import { bundleReleaseStateWarningSelector } from '../../selectors/deliverableSelectors';
import {useSelector} from 'react-redux';
import DeliverableChip from '~/features/deliverables/components/DeliverableChip';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

interface BundleDeliverableDetailsProps {
    field: any;
    data: any;
}

const BundleDeliverableDetails: FC<BundleDeliverableDetailsProps> = ({
    field,
    data,
}) => {
    const warnings = useSelector(state => bundleReleaseStateWarningSelector(state, data, false));

    const deliverableHasWarning = (value) => {
        return warnings ? warnings?.find((warn) => warn.deliverableId === value.deliverableId && toShortSemanticVersion(warn.deliverableVersion) === toShortSemanticVersion(value.deliverableVersion)) : false;
    }
    return (
    field || (data) ? (
        data?.bundleDeliverables?.length ? (
            data.bundleDeliverables.map((deliverable) => (
                <div className={'margin-top-10'} key={deliverable.deliverableId}>
                    <div className={deliverableHasWarning(deliverable) ? 'text-color-warning' : ''}>
                        <span className='text-bold margin-right-10'>{deliverable.deliverableId}</span>
                        <DeliverableChip deliverableId={deliverable.deliverableId}
                                         deliverableType={deliverable.deliverableType}
                                         deliverableVersion={toShortSemanticVersion(deliverable.deliverableVersion)}/>

                        {deliverableHasWarning(deliverable) &&
                            <OverlayTrigger
                                placement='top'
                                overlay={
                                    <Tooltip id='tooltip' className='top-right'>
                                        <FormattedMessage
                                            id={'intl-msg:deliverables.bundleDeliverableReleaseStateWarning'}/>
                                    </Tooltip>
                                }
                        >
                            <span
                                className='rioglyph rioglyph-info-sign text-color-warning text-size-20 margin-left-10'/>
                        </OverlayTrigger>}
                    </div>
                </div>
            ))
        ) : (
            <FieldOrNoData />
        )
    ) : (
        <FieldOrNoData />
    )
)}

export default BundleDeliverableDetails;
