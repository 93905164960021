
import map from 'lodash/fp/map';

import { call, put, select, fork, takeLatest } from 'redux-saga/effects';
import download from 'downloadjs';

import {
    ENTITY_CONTROL_DEVICE,
    ENTITY_DEVICE_LOGS,
    ENTITY_CONTROL_DEVICE_SCOPE_DEVICES_WITH_LOGS,
} from '~/features/base/constants/entities';

import {
    FETCH_DEVICES_WITH_LOGS,
    FETCH_DEVICES_WITH_LOGS_COUNT,
    FETCH_DEVICE_LOGS,
    DOWNLOAD_DEVICE_LOG_FILE,
} from '~/features/deviceLogs/actions/deviceLogsActions';
import { fetchFilteredControlDevices } from '~/features/devices/actions/controlDeviceActions';
import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';
import { mergeEntity } from '~/features/higherorder/actions/entityActions';
import { mergeAggregation } from '~/features/higherorder/actions/aggregationActions';

import { getDeviceLogDownloadBackend } from '~/features/base/selectors/backendSelectors';

import { doFetchFilteredControlDevicesSaga } from '~/features/devices/sagas/controlDeviceSaga';

import { authorizedFetch, doHandleErrorSaga, getHTTP } from '~/features/base/sagas/sagaUtil';
import { parseDeviceLogs } from '~/features/deviceLogs/transforms/parseDeviceLogs';

import { failedAction, finishedAction } from '~/features/higherorder/transforms/actionTransforms';

export function* getDeviceLogsURL() {
    const serviceURL = yield select(getDeviceLogDownloadBackend);
    return serviceURL;
}

export function* doFetchDevicesWithLogsCountSaga(action) {
    try {
        const serviceURL = yield call(getDeviceLogsURL);
        const response = yield call(getHTTP, `${serviceURL}`);
        yield put(mergeAggregation({
            entityName: ENTITY_CONTROL_DEVICE,
            scope: 'countsOfDevicesWithLogs',
        }, response.length));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchDevicesWithLogsSaga(action) {
    try {
        const serviceURL = yield call(getDeviceLogsURL);
        const response = yield call(getHTTP, `${serviceURL}`);
        const serialNumbers = map(item => item.serial, response);
        yield doFetchFilteredControlDevicesSaga(fetchFilteredControlDevices({
            scope: ENTITY_CONTROL_DEVICE_SCOPE_DEVICES_WITH_LOGS,
            page: action.page,
            size: action.size,
            searchCriteria: {
                whitelistingInfo: {
                    deviceWhitelist: serialNumbers,
                },
            },
        }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchDeviceLogsSaga(action) {
    try {
        const serviceURL = yield call(getDeviceLogsURL);
        const { serialNumber } = action.payload;
        const response = yield call(getHTTP, `${serviceURL}/${serialNumber}`);
        const parsedResponse = {
            serialNumber,
            logs: parseDeviceLogs(response.logs),
        };
        yield put(mergeEntity(parsedResponse, { entityName: ENTITY_DEVICE_LOGS }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doDownloadDeviceLogFileSaga(action) {
    try {
        const serviceURL = yield call(getDeviceLogsURL);
        const { serialNumber, logFile } = action.payload;
        const downloadUrl = `${serviceURL}/${serialNumber}/logs/${logFile.id}`;
        const requestObject = {
            method: 'GET',
            headers: {
                Accept: 'application/octet-stream',
                'Content-Type': 'application/octet-stream',
            },
        };
        const configuredFetch = authorizedFetch;
        const response = yield call(configuredFetch, downloadUrl, requestObject);
        const extension = logFile.operationId === 'logstream' ? 'txt.gz' : logFile.extension;
        const fileName = `${serialNumber}_${logFile.name}.${extension}`;
        response.blob().then(data => download(data, fileName, 'application/octet-stream'));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
    }
}

export function* fetchDevicesWithLogsCountSaga() {
    yield takeLatest(FETCH_DEVICES_WITH_LOGS_COUNT, doFetchDevicesWithLogsCountSaga);
}
export function* fetchDevicesWithLogsSaga() {
    yield takeLatest(FETCH_DEVICES_WITH_LOGS, doFetchDevicesWithLogsSaga);
}
export function* fetchDeviceLogsSaga() {
    yield takeLatest(FETCH_DEVICE_LOGS, doFetchDeviceLogsSaga);
}
export function* downloadDeviceLogFileSaga() {
    yield takeLatest(DOWNLOAD_DEVICE_LOG_FILE, doDownloadDeviceLogFileSaga);
}
