import size from 'lodash/fp/size';
import sortBy from 'lodash/fp/sortBy';
import includes from 'lodash/fp/includes';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import DeviceManagedPackagesTable from '~/features/devices/components/DeviceManagedPackagesTable';
import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import TableSearch from '@rio-cloud/rio-uikit/lib/es/TableSearch';
import Switch from '@rio-cloud/rio-uikit/lib/es/Switch';

const MANAGED_PACKAGES = 'managedPackages';
const ALL_PACKAGES = 'allPackages';

/**
 * Component for the device packages
 */
export class DevicePackages extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            mode: MANAGED_PACKAGES,
            search: '',
        };
    }

    onPackageSearchChange = (text) => {
        this.setState({
            search: text,
        });
    };

    onPackageDisplayOptionChange = () => {
        if (this.state.mode === MANAGED_PACKAGES) {
            this.setState({
                mode: ALL_PACKAGES,
            });
        } else {
            this.setState({
                mode: MANAGED_PACKAGES,
            });
        }
    };
    mergePackageWithEligiblePackageVersions = (lastPackageVersionsReported, eligiblePackageVersions) => {
        let mergedPackages = [];
        // add installed packages
        lastPackageVersionsReported.map((currentPackage) => {
            // add eligible package version if available
            if (eligiblePackageVersions) {
                const eligiblePackageVersion = eligiblePackageVersions[currentPackage.packageId];
                if (eligiblePackageVersion) {
                    currentPackage.eligiblePackageVersion = eligiblePackageVersion;
                    delete eligiblePackageVersions[currentPackage.packageId];
                }
            }
            mergedPackages.push(currentPackage);
        });
        // add eligible only packages
        if (eligiblePackageVersions) {
            Object.entries(eligiblePackageVersions).map((currentPackageVersion) => {
                mergedPackages.push({
                    packageId: currentPackageVersion[0],
                    eligiblePackageVersion: currentPackageVersion[1],
                });
            });
        }
        return mergedPackages;
    };

    render() {
        const { controlDeviceInfo, controlDeviceEligiblePackageVersions } = this.props;
        if (!controlDeviceInfo) {
            return null;
        }
        const viewModeSelect = this.renderViewModeSelect();

        if (!size(controlDeviceInfo.lastPackageVersionsReported) && !size(controlDeviceEligiblePackageVersions) && this.state.mode !== ALL_PACKAGES) {
            return (
                <DefaultWhiteColumn className='padding-top-20 padding-bottom-20'>
                    <div className={'display-flex justify-content-end margin-bottom-10 padding-top-10'}>
                        {viewModeSelect}
                    </div>
                    <NotFoundState fullWidth
                                   headline={<FormattedMessage id='intl-msg:nothingFound'/>}
                                   message={<FormattedMessage id='intl-msg:noPackagesReportedYet'/>}/>
                </DefaultWhiteColumn>
            );
        }
        const table = this.renderTable(controlDeviceInfo, { ...controlDeviceEligiblePackageVersions });
        return [
            <DefaultWhiteColumn>
                <div className='table-toolbar pull-left width-200 padding-bottom-5'>
                    <TableSearch
                        id='packageSearchInput'
                        value={this.state.search}
                        onChange={this.onPackageSearchChange}
                        placeholder={'Search package name'}
                    />
                </div>
                <div className='btn-toolbar pull-right padding-top-10'>
                    {viewModeSelect}
                </div>
            </DefaultWhiteColumn>,
            table,
        ];
    }

    renderViewModeSelect() {
        return (
            <div className='btn-toolbar'>
                <span>
                    <label><FormattedMessage id='intl-msg:allPackages'/></label>
                </span>
                <span>
                    <Switch onChange={() => this.onPackageDisplayOptionChange()}
                            checked={this.state.mode === ALL_PACKAGES}/>
                </span>
            </div>
        );
    }

    renderTable(controlDeviceInfo, controlDeviceEligiblePackageVersions) {
        if (this.state.mode === ALL_PACKAGES) {
            // sort package names
            const sortedPackages = sortBy('packageId', controlDeviceInfo.lastPackageVersionStringsReported);
            const filteredPackages = sortedPackages.filter(
                currentPackage => includes(this.state.search.toLowerCase(), currentPackage.packageId.toLowerCase()));
            return (
                <DefaultWhiteColumn>
                    <DeviceManagedPackagesTable packages={filteredPackages}/>
                </DefaultWhiteColumn>
            );
        }
        // sort package names
        const sortedPackages = sortBy('packageId', controlDeviceInfo.lastPackageVersionsReported);
        const filteredPackages = sortedPackages.filter(
            currentPackage => includes(this.state.search.toLowerCase(), currentPackage.packageId.toLowerCase()));
        return (
            <DefaultWhiteColumn>
                <DeviceManagedPackagesTable
                    packages={this.mergePackageWithEligiblePackageVersions(
                        filteredPackages, controlDeviceEligiblePackageVersions)}/>
            </DefaultWhiteColumn>
        );
    }

    componentDidMount() {
        const input = document.getElementById('packageSearchInput');
        if (input) {
            input.focus();
        }
    }
}

export default DevicePackages;

DevicePackages.propTypes = {
    // props
    controlDeviceInfo: PropTypes.object,
    controlDeviceEligiblePackageVersions: PropTypes.object,
    // functions
};
