/**
 * Action type for showing a modal dialog
 * @type {string}
 */
export const SHOW_MODAL = 'SHOW_MODAL';

/**
 * Action type for hiding a modal dialog
 * @type {string}
 */
export const HIDE_MODAL = 'HIDE_MODAL';

/**
 * Action creator to show modal
 * @param {object} payload The payload
 * @returns {{type: string, payload: object}} The action to show modal
 */
export const showModal = (payload) => {
    return {
        type: SHOW_MODAL,
        payload,
    };
};

/**
 * Action creator to show modal
 * @returns {{type: string}} The action to hide modal
 */
export const hideModal = () => {
    return {
        type: HIDE_MODAL,
    };
};
