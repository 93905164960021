import React, { PureComponent } from 'react';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';

class DeliverableColumnMenu extends PureComponent {
    render() {
        return (
            // size(items) > 0 &&
            <ButtonDropdown
                title={<span className='rioglyph rioglyph-option-vertical'/>}
                className='pull-right position-relative'
                dropdownClassName='position-fixed z-index-10. width-150'
                bsStyle='link'
                iconOnly
                items={this.props.items}/>
        );
    }
}

export default DeliverableColumnMenu;
