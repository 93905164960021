import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { deleteDeliverableVersion } from '~/features/deliverables/actions/deliverableActions';
import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';

/**
 * Deliverable version deletion dialog
 */
export class DeliverableVersionDeletionDialog extends PureComponent {

    onDeleteDeliverableVersion = (deliverableId, deliverableType, deliverableVersion) => {
        this.props.deleteDeliverableVersion(deliverableId, deliverableType, deliverableVersion);
    };

    render() {
        const { deliverableId, deliverableType, deliverableVersion } = this.props;
        return (
            <ConfirmationDialog className='file-deliverable-deletion-dialog'
                                show={true}
                                title={<FormattedMessage id='intl-msg:deleteDeliverableVersion'/>}
                                content={<FormattedMessage id='intl-msg:deleteDeliverableVersion.prompt' values={{
                                    deliverableVersion,
                                }}/>}
                                cancelButtonText={<FormattedMessage id='intl-msg:cancel'/>}
                                confirmButtonText={<FormattedMessage id='intl-msg:ok'/>}
                                onClickCancel={this.props.hideModal}
                                onClickConfirm={() => this.onDeleteDeliverableVersion(deliverableId, deliverableType,
                                    deliverableVersion)}/>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteDeliverableVersion: (deliverableId, deliverableType, deliverableVersion) => {
            dispatch(deleteDeliverableVersion(deliverableId, deliverableType, deliverableVersion));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableVersionDeletionDialog);

DeliverableVersionDeletionDialog.defaultProps = {
    // functions
    deleteDeliverableVersion: noop,
};

DeliverableVersionDeletionDialog.propTypes = {
    // functions
    deleteDeliverableVersion: PropTypes.func,
};
