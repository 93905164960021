import map from 'lodash/fp/map';
import { toDistroVersion, toSemanticVersion } from '~/features/base/utils/versionNumberConverter';

export const parseGroup = group => {
    return group;
};

export const parseGroups = groups => {
    return map(parseGroup, groups);
};

export const parseDeviceGroup = group => {
    return {
        //id: `${group.type}:${group.name}`,
        name: group.name,
        type: group.type,
    };
};

export const parseDeviceGroups = groups => {
    return map(group => parseDeviceGroup(group), groups);
};

export const parseDistroReference = toDistroVersion;
export const parsePackageReference = ({ packageId, packageVersion }) => {
    return {
        packageId,
        packageVersion: toSemanticVersion(packageVersion),
    };
};

export const parseGroupReferences = references => {
    return {
        ...references,
        distroVersions: map(parseDistroReference, references.distros),
        packageReleases: map(parsePackageReference, references.packages),
    };
};
