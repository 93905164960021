import moment from 'moment';

import React from 'react';
import { FormattedMessage } from 'react-intl';

export const createCustomPresets = (withAllOption) => {
    const presets = [{
        text: <FormattedMessage id='intl-msg:dateRangePicker.preset.today'/>,
        startValue: moment().startOf('day'),
        endValue: moment(),
    }, {
        text: <FormattedMessage id='intl-msg:dateRangePicker.preset.last7Days'/>,
        startValue: moment().startOf('day').subtract(7, 'days'),
        endValue: moment(),
    }, {
        text: <FormattedMessage id='intl-msg:dateRangePicker.preset.last30Days'/>,
        startValue: moment().startOf('day').subtract(30, 'days'),
        endValue: moment(),
    }];
    return withAllOption ? [{
        text: <FormattedMessage id='intl-msg:all'/>,
        startValue: moment(new Date(0)),
        endValue: moment().add(15, 'minutes'),
    }, ...presets] : presets;
};
