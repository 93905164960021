import { iconsByVehicleVariant } from '~/features/base/constants/iconsByVehicleVariant';

export const getVehicleIcon = (vehicleVariant: string): string => {
    const allKeys = Object.keys(iconsByVehicleVariant);
    const key = allKeys.find(k => vehicleVariant?.startsWith(k.toLowerCase()));
    if (key) {
        return iconsByVehicleVariant[key];
    }
    return iconsByVehicleVariant['other'];
}

