import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFetchVehicleVariantsQuery } from '~/api/controlDevice/ControlDevice.api';
import MultiSelectOTA from '~/features/base/components/MultiSelectOTA';

interface VehicleVariantsFormItemProps {
    onChange: (vehicleVariantsWhitelist: { vehicleVariantsWhitelist: string[] }) => void;
    whiteListedVehicleVariants: string[];
}

const VehicleVariantsFormItem: React.FC<VehicleVariantsFormItemProps> = ({
    onChange,
    whiteListedVehicleVariants
}) => {
    const { data: vehicleVariantNames } = useFetchVehicleVariantsQuery({});

    const optionsWithSelection = vehicleVariantNames?.map(variant => ({
        label: variant,
        id: variant,
        value: variant
    }));

    const handleChange = (variants: string[]) => {
        onChange({
            vehicleVariantsWhitelist: [...variants],
        });
    };
    return (
        <div className='form-group'>
            <label className='control-label'>
                <FormattedMessage id={'intl-msg:vehicleVariant'}/>
            </label>
            <div className='form-group'>
                <MultiSelectOTA
                    options={optionsWithSelection}
                    placeholder={<FormattedMessage id={'intl-msg:vehicleVariant'}/>}
                    onChange={handleChange}
                    value={whiteListedVehicleVariants ? [...whiteListedVehicleVariants] : []}
                />

            </div>
        </div>
    );

};

export default VehicleVariantsFormItem;
