
import classNames from 'classnames';

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { releaseStates } from '~/features/base/constants/releaseStates';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

export const ReleaseState = ({ releaseState, className }) => {
    if (!releaseState) {
        return null;
    }
    return (
        <OverlayTrigger placement='top' overlay={
            <Tooltip id='tooltip' className='top-right'>
                <FormattedMessage id={releaseStates[releaseState].tooltip}/>
            </Tooltip>}>
            <span className={classNames('label label-condensed', releaseStates[releaseState].className, className)}>
                <FormattedMessage id={releaseStates[releaseState].label} />
            </span>
        </OverlayTrigger>
    );
};

export default ReleaseState;

ReleaseState.propTypes = {
    // props
    releaseState: PropTypes.string,
    className: PropTypes.string,
};
