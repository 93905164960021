import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { followRoute } from '~/features/base/actions/ui/routeActions';

import {
    triggerDataFetcher,
} from '~/features/base/actions/ui/dataFetcherActions';
import {
    registerDataInterest,
    unregisterDataInterest,
} from '~/features/base/actions/ui/dataInterestActions';

import {
    showGroupEditorDialog,
    showGroupDeletionDialog,
} from '~/features/groups/actions/groupEditorActions';
import { fetchGroup, fetchSerialNumbersOfGroup } from '~/features/groups/actions/groupActions';

import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';
import {
    canReadGroupsSelector,
    canUpdateGroupsSelector,
    canDeleteGroupsSelector,
    groupWithSerialNumbersSelector,
} from '~/features/groups/selectors/groupSelectors';

import GroupDetails from '~/features/groups/components/GroupDetails';
import uid from '~/features/base/utils/uid';

/**
 * Container for the group
 */
export class GroupDetailsContainer extends PureComponent {

    constructor(props) {
        super(props);
        this.name = uid();
        this.registerDataInterest();
        this.props.triggerDataFetcher();
        this.interval = setInterval(() => {
            this.props.triggerDataFetcher();
        }, 60000);
    }

    onEditGroup(group) {
        this.props.showGroupEditorDialog({
            isNew: false,
            ...group,
        });
    }

    onDeleteGroup(group) {
        this.props.showGroupDeletionDialog({
            ...group,
        });
    }

    render() {
        return (
            <GroupDetails {...this.props}
                onEditGroup={(group) => this.onEditGroup(group)}
                onDeleteGroup={(group) => this.onDeleteGroup(group)}/>
        );
    }

    registerDataInterest() {
        const { groupName } = this.props;
        this.props.registerDataInterest(this.name, [
            fetchGroup(groupName),
            fetchSerialNumbersOfGroup(groupName),
        ]);
    }

    componentWillUnmount() {
        this.props.unregisterDataInterest(this.name);
        clearInterval(this.interval);
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        pathname: pathnameSelector(state),
        search: searchSelector(state),
        canReadGroups: canReadGroupsSelector(state),
        canUpdateGroups: canUpdateGroupsSelector(state),
        canDeleteGroups: canDeleteGroupsSelector(state),
        group: groupWithSerialNumbersSelector(state, ownProps),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        followRoute: (options) => {
            dispatch(followRoute(options));
        },
        triggerDataFetcher: () => {
            dispatch(triggerDataFetcher());
        },
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        showGroupEditorDialog: payload => {
            dispatch(showGroupEditorDialog(payload));
        },
        showGroupDeletionDialog: payload => {
            dispatch(showGroupDeletionDialog(payload));
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupDetailsContainer));

GroupDetailsContainer.defaultProps = {
    // props
    pathname: '',
    search: '',
    canReadGroups: false,
    canUpdateGroups: false,
    canDeleteGroups: false,
    groupName: '',
    group: undefined,
    // functions
    followRoute: noop,
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    triggerDataFetcher: noop,
    showGroupEditorDialog: noop,
    showGroupDeletionDialog: noop,
};

GroupDetailsContainer.propTypes = {
    // props
    match: PropTypes.object,
    pathname: PropTypes.string,
    search: PropTypes.string,
    canReadGroups: PropTypes.bool,
    canUpdateGroups: PropTypes.bool,
    canDeleteGroups: PropTypes.bool,
    groupName: '',
    group: PropTypes.object,
    // functions
    followRoute: PropTypes.func,
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
    showGroupEditorDialog: PropTypes.func,
    showGroupDeletionDialog: PropTypes.func,
};
