import { select, put, call, takeLatest, fork } from 'redux-saga/effects';
import { FETCH_DEFAULT_NETWORK_CODE_LIST } from '~/features/base/actions/ui/networkCodeFetcherActions';
import { finishedAction } from '~/features/higherorder/transforms/actionTransforms';
import { endLoading, startLoading } from '~/features/higherorder/actions/loadingActions';
import { ENTITY_DEFAULT_NETWORK_CODE_LIST } from '~/features/base/constants/entities';
import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';
import { doHandleErrorSaga, getHTTP } from '~/features/base/sagas/sagaUtil';
import { mergeEntity } from '~/features/higherorder/actions/entityActions';
import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';

export function* getDeliverablesURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/deliverable`;
}

export function* doFetchDefaultNetworkCodeListSaga(action) {
    yield put(startLoading(FETCH_DEFAULT_NETWORK_CODE_LIST));
    try {
        const url = yield call(getDeliverablesURL);
        const response = yield call(getHTTP, `${url}/network-codes`);
        yield put(mergeEntity(response, { entityName: ENTITY_DEFAULT_NETWORK_CODE_LIST }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(FETCH_DEFAULT_NETWORK_CODE_LIST));
    yield put(finishedAction(action.type));
}

export function* fetchDefaultNetworkCodeListSaga() {
    yield takeLatest(FETCH_DEFAULT_NETWORK_CODE_LIST, doFetchDefaultNetworkCodeListSaga);
}
