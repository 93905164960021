import { put, takeLatest } from 'redux-saga/effects';

import { UPDATE_EVENT_REPORT_MODAL } from '~/features/base/constants/modals';

import { showModal } from '~/features/base/actions/ui/modalsActions';
import { SHOW_UPDATE_EVENT_REPORT_DIALOG } from '~/features/updateEvents/actions/updateEventReportActions';

export function* doShowUpdateEventReportDialogSaga(action) {
    yield put(showModal({
        modalType: UPDATE_EVENT_REPORT_MODAL,
        modalProps: action.payload,
    }));
}

export function* showUpdateEventReportDialogSaga() {
    yield takeLatest(SHOW_UPDATE_EVENT_REPORT_DIALOG, doShowUpdateEventReportDialogSaga);
}
