import { toFinished } from '~/features/higherorder/transforms/actionTransforms';
import {
    CHANGE_DELIVERABLE_EDITOR,
    CREATE_DELIVERABLE,
    RESET_DELIVERABLE_EDITOR,
    UPDATE_DELIVERABLE,
} from '~/features/artifacts/actions/deliverableActions';

const defaultState = {
    permissions: {},
};

export const resetDeliverableEditor = (state = defaultState) => {
    return state;
};
export const changeDeliverableEditor = (state, { payload }) => {
    const newState = {
        ...state,
        ...payload,
    };
    return newState;
};
export const setInProgress = (state) => {
    return {
        ...state,
        isInProgress: true,
    };
};
export const setNotInProgress = (state) => {
    return {
        ...state,
        isInProgress: false,
    };
};

export default function deliverablePermissionsReducer(state = defaultState, action = {}) {
    switch (action.type) {
        case RESET_DELIVERABLE_EDITOR:
            return resetDeliverableEditor();
        case CHANGE_DELIVERABLE_EDITOR:
            return changeDeliverableEditor(state, action);
        case CREATE_DELIVERABLE:
        case UPDATE_DELIVERABLE:
            return setInProgress(state);
        case toFinished(CREATE_DELIVERABLE):
        case toFinished(UPDATE_DELIVERABLE):
            return setNotInProgress(state);
        default:
            return state;
    }
}
