import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import {
    accessKeyToGroup,
    sysParamGroups,
} from '~/features/sysParams/constants/sysParamGroups';

export const SysParamGroup = ({ accessKey }) => {
    const group = accessKeyToGroup(accessKey);
    return (
        <span className={classNames('label label-condensed', sysParamGroups[group].className)}>
            {sysParamGroups[group].label}
        </span>
    );
};

export default SysParamGroup;

SysParamGroup.defaultProps = {
    accessKey: 0,
};

SysParamGroup.propTypes = {
    // props
    accessKey: PropTypes.number,
};
