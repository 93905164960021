import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ActionReport } from '~/api/models/ActionReport';
import { reportActions } from '~/features/deviceReports/constants/reportActions';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import { actionStatusSettings } from '~/features/deviceReports/constants/actionStatusSettings';
import {DeliverableType} from "~/features/deliverables/constants/DeliverableType";

interface ActionsByStatus {
    actions: ActionReport[],
    status: string,
}

const ActionsByStatus = ({ actions, status }: ActionsByStatus) => {
    const { badge, label } = actionStatusSettings[status];

    const getActionTypeLabel = (actionType) => {
        return reportActions[actionType] ?
            reportActions[actionType].label :
            `intl-msg:report.action.unknown.${actionType.type}`;
    };

    const getDeliverableIdLabel = (deliverable) => {
        if(deliverable.deliverableId && (deliverable.deliverableId?.toUpperCase() !== DeliverableType.DISTRO))
        {
            return  `(${deliverable.deliverableId}) `;
        }
        return '';
    }

    const renderErrorLabel = (errors) => {
        const errorCodes = errors && Object.keys(errors);
        if (errorCodes?.length > 0) {
            return (
                <>
                    <FormattedMessage id='intl-msg:report.reason'/> {errorCodes.map(errorCode =>
                    <OverlayTrigger placement='bottom' overlay={
                        errors[errorCode] !== null && <Tooltip id='tooltip' className='top-left'>
                            {errors[errorCode]
                            }
                        </Tooltip>}>
                        <span> {errorCode}</span>
                    </OverlayTrigger>)}
                </>
            );
        }
    };

    if (actions?.length > 0) {
        return (<div>
            {actions.map(a => {
                const deliverableId = getDeliverableIdLabel(a);
                return (
                    <div>
                        <span className={`margin-right-10  text-size-16 ${badge}`}/>
                        <FormattedMessage
                            id={getActionTypeLabel(a.deviceActionType)}/> {deliverableId}<FormattedMessage
                        id={label}/>
                        {renderErrorLabel(a?.errorMap)}
                    </div>);
            })
            }
        </div>);
    }
    return <></>;

};

export default ActionsByStatus;
