import map from 'lodash/fp/map';
import isEqual from 'lodash/fp/isEqual';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';
import uid from '~/features/base/utils/uid';

// table-bordered
const tableClassName = 'table table-layout-fixed table-column-overflow-hidden table-head-filled table-sticky';

/**
 * Table component for properties
 * TODO Make this a function
 */
export class PropertiesTable extends PureComponent {

    render() {
        const { data } = this.props;
        if (!data) {
            return <FormattedMessage id='intl-msg:notFound'/>;
        }
        const { className, rowDescriptors, historyData, showHeader } = this.props;
        const rows = this.renderRows(rowDescriptors, data, historyData);
        const historyHead = historyData ? (
            <th className='user-select-none'>
                <FormattedMessage id='intl-msg:previousValue'/>
            </th>
        ) : null;
        return (
            <table className={classNames(tableClassName, className)}>
                {showHeader ?
                    <thead className='table-head'>
                    <tr>
                        <th className='user-select-none'><FormattedMessage id='intl-msg:field'/></th>
                        <th className='user-select-none'><FormattedMessage id='intl-msg:value'/></th>
                        {historyHead}
                    </tr>
                    </thead>
                    : null
                }
                <tbody>
                {rows}
                </tbody>
            </table>
        );
    }

    renderRows(rowDescriptors, data, historyData) {
        return map(rowDescriptor => this.renderRow(rowDescriptor, data, historyData), rowDescriptors);
    }

    renderRow(rowDescriptor, data, historyData) {
        if (rowDescriptor.fieldCondition && !rowDescriptor.fieldCondition(data)) {
            return;
        }
        const value = data[rowDescriptor.field];
        const historyValue = (historyData) ? historyData[rowDescriptor.field] : '';
        const valueTd = (<td>{rowDescriptor.format ? rowDescriptor.format(value, data) : value}</td>);

        const historyTd = historyData ?
            <td>{rowDescriptor.format ? rowDescriptor.format(historyValue, historyData) : historyValue}</td> : <></>;

        // compare tds because we need the formatted values
        const hasChanged = (historyData && !(isEqual(valueTd, historyTd)));

        return (
            <tr data-field={rowDescriptor.field} key={rowDescriptor.field} className={hasChanged ? 'success' : ''}>
                <td className='text-color-dark' >
                    {this.toLabel(rowDescriptor)}
                    {rowDescriptor.labelTooltip}
                </td>
                {valueTd}
                {historyTd}
            </tr>
        );
    }

    toLabel(rowDescriptor) {
        const { formatLabel, field, label } = rowDescriptor;
        if (formatLabel) {
            return formatLabel(field);
        }
        if (label) {
            return <FormattedMessage id={label}/>;
        }
        return <FormattedMessage id={`intl-msg:field.unknown.${field}`}/>;
    }
}

export default PropertiesTable;

PropertiesTable.defaultProps = {
    // props
    rowDescriptors: [],
    showHeader: true,
};

PropertiesTable.propTypes = {
    // props
    className: PropTypes.string,
    data: PropTypes.object,
    historyData: PropTypes.object,
    showHeader: PropTypes.bool,
    rowDescriptors: PropTypes.array,
};
