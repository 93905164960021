import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

export const DeliverableReferenceType = ({label = '', toolTipMessage = '', className}) => {
    return (
        <OverlayTrigger placement='top' overlay={
            <Tooltip id='tooltip' className='top-right'>
                <FormattedMessage id={toolTipMessage}/>
            </Tooltip>}>
            <span className={classNames('label', className)}>
                <FormattedMessage id={label} />
            </span>
        </OverlayTrigger>
    );
};

export default DeliverableReferenceType;
