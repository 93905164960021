import map from 'lodash/fp/map';

import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedNumber } from 'react-intl';
import { Other, Unknown } from '../constants/LegendTitles';

import { stringifyToQuery } from '~/features/base/utils/query';
import uuid from 'uuid';

export const Legend = ({ data, route, query }) => {
    const items = map(item => (
        <Link key={uuid()} className={item.label.toLowerCase() === Other || item.label.toLowerCase() === Unknown
            ? 'width-50pct pull-left padding-left-5 padding-right-5 disabled'
            : 'width-50pct pull-left padding-left-5 padding-right-5'}
              to={`${route}?${stringifyToQuery({
                  ...query,
                  ...item.filters,
              })}`}>
            <span className='text-size-12' style={{ color: item.color }}>{item.label}</span>
            <span className='badge text-size-smallest margin-top-5 pull-right'
                  style={{ backgroundColor: item.color }}>
                <FormattedNumber value={item.value}/>
            </span>
        </Link>
    ), data);
    return (
        <div className='width-100pct clearfix'>
            {items}
        </div>
    );
};

export default Legend;

Legend.propTypes = {
    // props
    data: PropTypes.array,
    route: PropTypes.string,
    query: PropTypes.object,
};
