import identity from 'lodash/fp/identity';
import includes from 'lodash/fp/includes';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';
import sortedUniq from 'lodash/fp/sortedUniq';
import without from 'lodash/fp/without';

import {
    MERGE_ENTITIES,
    MERGE_ENTITY,
    DELETE_ENTITY,
} from '~/features/higherorder/actions/entityActions';
import { mapReducer } from './mapReducer';

const sortByIdentity = flow(sortBy(identity), sortedUniq);

const acceptedActions = [MERGE_ENTITIES, MERGE_ENTITY, DELETE_ENTITY];

const defaultState = [];
const mapDefaultState = {};

const mergeEntity = (state, action, entityIdentifier) => {
    const newState = sortByIdentity([
        ...state,
        action.entity[entityIdentifier],
    ]);
    return newState;
};
const mergeEntities = (state, action, entityIdentifier) => {
    const newState = sortByIdentity([
        //...state, // Took this out as it is problematic with editable base software versions
        ...map(entityIdentifier, action.entities),
    ]);
    return newState;
};
const deleteEntity = (state, action, entityIdentifier) => {
    const newState = without([action.entity[entityIdentifier]], state);
    return newState;
};

export const sortedIdsReducer = (state, action, entityIdentifier) => {
    switch (action.type) {
        case MERGE_ENTITIES:
            return mergeEntities(state, action, entityIdentifier);
        case MERGE_ENTITY:
            return mergeEntity(state, action, entityIdentifier);
        case DELETE_ENTITY:
            return deleteEntity(state, action, entityIdentifier);
        default:
            return state;
    }
};

export const createSortedIdsReducer = (entityName, entityIdentifier) => {
    return (state = defaultState, action = {}) => {
        if (includes(action.type, acceptedActions)) {
            if (action.mergeProps.entityName === entityName) {
                return sortedIdsReducer(state, action, entityIdentifier);
            }
        }
        return state;
    };
};

export const createMappedSortedIdsReducer = (entityName = 'entity', entityIdentifier = 'id', scopeProp = 'scope') => {
    return (state = mapDefaultState, action = {}) => {
        if (includes(action.type, acceptedActions)) {
            if (action.mergeProps.entityName === entityName) {
                return mapReducer(state, action, scopeProp,
                    createSortedIdsReducer(entityName, entityIdentifier));
            }
        }
        return state;
    };
};
