import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

export class GroupDetailsToolbar extends PureComponent {
    render() {
        const editButton = this.renderEditButton();
        const deleteButton = this.renderDeleteButton();
        return (
            <div className='btn-toolbar margin-bottom-25'>
                {editButton}
                {deleteButton}
            </div>
        );
    }
    renderEditButton() {
        if (this.props.canUpdateGroups) {
            return (
                <a id='edit-group-button'
                    className='btn btn-primary'
                    onClick={this.props.onEditGroup}>
                    <span className='rioglyph rioglyph-pencil' aria-hidden='true'></span>&nbsp;
                    <FormattedMessage id='intl-msg:editGroup'/>
                </a>
            );
        }
    }
    renderDeleteButton() {
        if (this.props.canDeleteGroups) {
            return (
                <a id='delete-group-button'
                    className='btn btn-default'
                    onClick={this.props.onDeleteGroup}>
                    <span className='rioglyph rioglyph-trash' aria-hidden='true'></span>&nbsp;
                    <FormattedMessage id='intl-msg:deleteGroup'/>
                </a>
            );
        }
    }
}

export default GroupDetailsToolbar;

GroupDetailsToolbar.defaultProps = {
    // props
    canUpdateGroups: false,
    canDeleteGroups: false,
    // functions
    onEditGroup: noop,
    onDeleteGroup: noop,
};

GroupDetailsToolbar.propTypes = {
    // props
    canUpdateGroups: PropTypes.bool,
    canDeleteGroups: PropTypes.bool,
    // functions
    onEditGroup: PropTypes.func,
    onDeleteGroup: PropTypes.func,
};
