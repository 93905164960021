import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import {
    importSysParams,
} from '~/features/sysParams/actions/sysParamsActions';

import { distroEditorSelector } from '~/features/deliverables/features/distros/selectors/distroSelectors';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import FilePicker from '@rio-cloud/rio-uikit/lib/es/FilePicker';

/**
 * Import system parameter definitions dialog
 * Goal is to import system parameter definitions and default values
 */
export class ImportSysParamDefinitionsDialog extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            selectedFile: undefined,
        };
        this.onPick = this.onPick.bind(this);
        this.onImport = this.onImport.bind(this);
    }

    onPick(selectedFiles) {
        this.setState({
            selectedFile: selectedFiles[0],
        });
    }

    onImport() {
        const baseSoftwareVersion = this.props.model.softwareVersion;

        if (this.state.selectedFile) {
            const fileReader = new FileReader();
            fileReader.onload = (event) => {
                const definitions = JSON.parse(event.target.result);

                const defaultValues = map(definition => {
                    return {
                        accessKey: definition.accessKey,
                        name: definition.name,
                        value: definition.defaultValue,
                    };
                }, filter(definition => definition.hasOwnProperty('defaultValue'), definitions));
                this.props.importSysParams(definitions, defaultValues, baseSoftwareVersion);
            };
            fileReader.readAsText(this.state.selectedFile);
        }
    }

    render() {
        const title = this.renderTitle();
        const body = this.renderBody();
        const footer = this.renderFooter();
        return (
            <Dialog className='import-sys-param-definitions-dialog'
                show={true}
                showCloseButton={true}
                onHide={this.props.hideModal}
                title={title}
                body={body}
                footer={footer}
                useOverflow
            />
        );
    }
    renderTitle() {
        return <FormattedMessage id='intl-msg:importSysParamDefinitions' />;
    }
    renderBody() {
        return (
            <div>
                <FormattedMessage id='intl-msg:importSysParamDefinitions.prompt'/>
                <FilePicker onPick={this.onPick}/>
            </div>
        );
    }
    renderFooter() {
        return (
            <div>
                <button className='btn btn-default' onClick={this.props.hideModal}>
                    <FormattedMessage id='intl-msg:close'/>
                </button>
                <button className='btn btn-primary' onClick={this.onImport}>
                    <FormattedMessage id='intl-msg:ok'/>
                </button>
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        model: distroEditorSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        importSysParams: (sysParamDefinitions, sysParamDefaultValues, baseSoftwareVersion) => {
            dispatch(importSysParams(sysParamDefinitions,
                sysParamDefaultValues, baseSoftwareVersion));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportSysParamDefinitionsDialog);

ImportSysParamDefinitionsDialog.defaultProps = {
    model: undefined,
    importSysParams: noop,
};

ImportSysParamDefinitionsDialog.propTypes = {
    model: PropTypes.object,
    importSysParams: PropTypes.func,
};
