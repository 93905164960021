export const MERGE_SELECT = '@@select/MERGE_SELECT';
export const MERGE_SELECT_ALL = '@@select/MERGE_SELECT_ALL';

export const mergeSelect = (entityName, select, id) => {
    return {
        type: MERGE_SELECT,
        entityName,
        select,
        id,
    };
};

export const mergeSelectAll = (entityName, selectAll) => {
    return {
        type: MERGE_SELECT_ALL,
        entityName,
        selectAll,
    };
};
