import React, { PureComponent } from 'react';
import SortDirection from '@rio-cloud/rio-uikit/lib/es/SortDirection';
import uid from '~/features/base/utils/uid';
import TableSearch from '@rio-cloud/rio-uikit/lib/es/TableSearch';
import { DEFAULT_LANG } from '~/features/lang/lang';
import PropTypes from 'prop-types';

const defaultColumnOrder = [
    'icon',
    'name',
    'value',
];
const defaultColumnsDetails = {
    icon: {
        width: 50,
        defaultWidth: 50,
        maxWidth: 50,
    },
    name: {
        width: 150,
        defaultWidth: 150,
        maxWidth: 150,
    },
    value: {
        width: 100,
        defaultWidth: 100,
        maxWidth: 100,
    },
};

export class DistributionTileTable extends PureComponent {
    constructor(props) {
        super(props);
        this.name = uid();
        this.state = {
            searchValue: '',
            sortBy: defaultColumnOrder[1],
            sortDir: SortDirection.ASCENDING,
            columnOrder: defaultColumnOrder,
            columnsDetails: defaultColumnsDetails,
        };
    }

    renderTableHead(column, label, columnDetails) {
        const tableHeadClassNames = 'user-select-none sort-column text-size-20';
        return (
            <th
                key={column}
                className={tableHeadClassNames}
                onClick={this.onSortChange}
                width={columnDetails.width}
                data-field={column}
                data-sortby={column}
                title={label}>
                {column === 'icon'
                    ?
                    <span>
                        <span className='tableHeadClassNames'>{label}</span>
                    </span>
                    :
                    <span>
                        <span className='tableHeadClassNames'>{label}</span>
                    </span>
                }
            </th>
        );
    }

    render() {
        const {
            tableData,
            searchValue,
            onSearchValueChange,
            tileDescriptor,
        } = this.props;
        const {
            columnOrder,
        } = this.state;
        const tableClassNames = 'table table-layout-fixed table-column-overflow-hidden table-bordered table-sticky table-head-filled';
        const columns = columnOrder;
        const filteredChartData = !searchValue
            ? tableData
            : tableData.filter(row => (
                row.name.toLowerCase().startsWith(searchValue.toLowerCase())
            ));
        const additionalClass = tileDescriptor.distributionType === 'country' ? '' : 'h1';
        const searchPlaceholder = tileDescriptor.distributionType === 'country' ? 'Search country ...'
            : 'Search type ...';
        const flagStyle = tileDescriptor.distributionType === 'country'
            ? 'drop-shadow(0px 0px 2px rgb(0 0 0 / 0.8))'
            : null
        return (
            <div className={'bg-white z-index-1 position-absolute'}>
                <div className='border height-450 overflow-y-scroll '>
                    <div className='table-toolbar-group-right'>
                        <div className='table-toolbar-column'>
                            <TableSearch
                                value={searchValue}
                                onChange={(e) => onSearchValueChange(e)}
                                placeholder={searchPlaceholder}
                            />
                        </div>
                    </div>
                    <table className={tableClassNames}>
                        <tbody>
                        {filteredChartData.map((row, index) => (
                            <tr key={index}>
                                {columns.map(col => (
                                    <td key={col} data-field={col}
                                        className={`${col === 'icon' ? 'width-150' : ''} ${col === 'value' ? 'text-right' : 'text-left'}`}>
                                        {col === 'icon'
                                            ? <span className={`${additionalClass} ${row['classes']}`}
                                                    style={{
                                                        'width': '50px',
                                                        'height': '38px',
                                                        'color': `${row['color']}`,
                                                        filter: flagStyle,
                                                    }}
                                            ></span>
                                            : col === 'name'
                                                ? <span className='text-size-20 margin-left-20'>{row['label']}</span>
                                                : <span
                                                    className='text-size-20 padding-right-20 margin-right-20'>{row[col].toLocaleString(
                                                    DEFAULT_LANG)}</span>
                                        }
                                    </td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

DistributionTileTable.propTypes = {
    // props
    tableData: PropTypes.array,
    searchValue: PropTypes.string,
    // functions
    onSearchValueChange: PropTypes.func,
};
