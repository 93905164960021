import size from 'lodash/fp/size';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
// import { NotFoundState, Spinner } from 'rio-uikit';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';

import { parseQuery } from '~/features/base/utils/query';
import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';

import uid from '~/features/base/utils/uid';
import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';
import { connect } from 'react-redux';
import ArtifactListToolbar from '~/features/deliverables/features/artifacts/components/ArtifactListToolbar';
import { fetchFilteredArtifacts } from '~/features/deliverables/features/artifacts/actions/artifactActions';
import {
    artifactPageItemsSelector,
    artifactsLoadingSelector,
} from '~/features/deliverables/features/artifacts/selectors/artifactSelectors';
import { fetchDeliverableFiles } from '~/features/artifacts/actions/deliverableFileActions';
import ArtifactFilesTable from '~/features/deliverables/features/artifacts/components/ArtifactFilesTable';

export class ArtifactList extends PureComponent {

    constructor(props) {
        super(props);
        this.name = uid();
    }

    onShowPage = (page) => {
        const { pathname, search } = this.props;
        const query = parseQuery(search);
        this.props.followRoute({
            route: pathname, query: {
                ...query,
                page,
            },
        });
    };

    onCreateArtifact = () => {
        const { deliverableType, deliverableId, deliverableVersion, owningContext } = this.props;
        this.props.onCreateArtifact({
            deliverableType,
            deliverableId,
            deliverableVersion,
            owningContext,
            isNew: true,
        });
    };

    render() {
        const { artifacts, artifactsLoading, onLoadMore, deliverableId, deliverableVersion } = this.props;
        return [
            size(artifacts) > 0 &&
            <DefaultWhiteColumn className='padding-bottom-10 clearfix'>
                <ArtifactListToolbar onCreateArtifact={this.onCreateArtifact}>
                    <div className='display-inline-block padding-left-5'>
                        {artifactsLoading ? <Spinner/> : null}
                    </div>
                </ArtifactListToolbar>
            </DefaultWhiteColumn>,
            <div>
                {size(artifacts) ?
                    <ArtifactFilesTable artifactId={deliverableId} files={artifacts}/>
                    :
                    <NotFoundState fullWidth
                                   headline={<FormattedMessage id='intl-msg:nothingFound'/>}
                                   message={<FormattedMessage id='intl-msg:noArtifactsFound'/>}
                    >
                        <a id='create-deliverable-button'
                           className='btn btn-default'
                           onClick={this.onCreateArtifact}>
                            <span className='rioglyph rioglyph-plus' aria-hidden='true'></span>&nbsp;
                            <FormattedMessage id='intl-msg:createArtifact'/>
                        </a>
                    </NotFoundState>
                }
            </div>,
            // <DefaultWhiteColumn className='text-center padding-bottom-20'>
            //     {size(artifacts) ?
            //         <a id='load-more-button'
            //            className='btn btn-default'
            //            onClick={onLoadMore}
            //         >
            //             <FormattedMessage id='intl-msg:loadMore'/>
            //         </a>
            //         : null
            //     }
            // </DefaultWhiteColumn>,
        ];
    }

    componentWillMount() {
        const { owningContext, deliverableId, deliverableVersion, deliverableType, search } = this.props;
        const parsedQuery = parseQuery(search);
        const { deliverableNamePrefix } = parsedQuery;
        this.props.fetchFilteredArtifacts(deliverableType, deliverableId, deliverableVersion, deliverableNamePrefix,
            owningContext);
    }

    registerDataInterest() {
        const { owningContext, deliverableId, deliverableVersion, deliverableType, search } = this.props;
        const parsedQuery = parseQuery(search);
        const { page, deliverableNamePrefix } = parsedQuery;
        this.props.registerDataInterest(this.name, [
            fetchFilteredArtifacts({
                page: (page && page > 0) ? (page - 1) : 0,
                searchCriteria: {
                    type: deliverableType.toLowerCase(),
                    otaIdPrefix: deliverableId,
                    otaVersionPrefix: deliverableVersion,
                    contextList: owningContext ? [owningContext] : [],
                    deliverableNamePrefix,
                },
            }),
        ]);
    }
}

export const mapStateToProps = (state) => {
    return {
        pathname: pathnameSelector(state),
        search: searchSelector(state),
        artifacts: artifactPageItemsSelector(state),
        artifactsLoading: artifactsLoadingSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        fetchDeliverableFiles: (page, deliverableId) => (
            dispatch(fetchDeliverableFiles({
                page,
                deliverableId,
            }))),
        fetchFilteredArtifacts: (deliverableType, deliverableId, deliverableVersion, deliverableNamePrefix,
            owningContext) => {
            dispatch(fetchFilteredArtifacts({
                page: 0,
                searchCriteria: {
                    type: deliverableType.toLowerCase(),
                    otaIdPrefix: deliverableId,
                    otaVersionPrefix: deliverableVersion,
                    contextList: owningContext ? [owningContext] : [],
                    deliverableNamePrefix,
                },
            }));
        },
    }
        ;
};

export default connect(mapStateToProps, mapDispatchToProps)(ArtifactList);

ArtifactList.defaultProps = {
    // props
    owningContext: '',
    deliverableType: '',
    deliverableId: '',
    deliverableVersion: '',
    artifacts: [],
    deliverablesLoading: false,
    // functions
    followRoute: noop,
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    triggerDataFetcher: noop,
    onShowPage: noop,
    onLoadMore: noop,
    onCreateArtifact: noop,
    onEditArtifact: noop,
    onDeleteArtifact: noop,
};

ArtifactList.propTypes = {
    // props
    owningContext: PropTypes.string,
    deliverableType: PropTypes.string,
    deliverableId: PropTypes.string,
    deliverableVersion: PropTypes.string,
    artifacts: PropTypes.array,
    deliverablesLoading: PropTypes.bool,
    // functions
    followRoute: PropTypes.func,
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
    onShowPage: PropTypes.func,
    onLoadMore: PropTypes.func,
    onCreateArtifact: PropTypes.func,
    onEditArtifact: PropTypes.func,
    onDeleteArtifact: PropTypes.func,
};
