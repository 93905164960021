export const SHOW_DELIVERABLE_EDITOR_DIALOG = 'SHOW_DELIVERABLE_EDITOR_DIALOG';
export const SHOW_DELIVERABLE_DELETION_DIALOG = 'SHOW_DELIVERABLE_DELETION_DIALOG';
export const SHOW_DELIVERABLE_FILE_DELETION_DIALOG = 'SHOW_DELIVERABLE_FILE_DELETION_DIALOG';

export const SHOW_DELIVERABLE_PERMISSION_DIALOG = 'SHOW_DELIVERABLE_PERMISSION_DIALOG';

export const RESET_DELIVERABLE_EDITOR = 'RESET_DELIVERABLE_EDITOR';
export const CHANGE_DELIVERABLE_EDITOR = 'CHANGE_DELIVERABLE_EDITOR';

export const ADD_NEW_CONTEXT = 'ADD_NEW_CONTEXT';
export const UPDATE_CONTEXT_OWNER = 'UPDATE_CONTEXT_OWNER';
export const UPDATE_CONTEXT_OWNER_MODE_ADD = 'ADD';
export const UPDATE_CONTEXT_OWNER_MODE_REMOVE = 'REMOVE';
export const FETCH_CONTEXT_DETAILS = 'FETCH_CONTEXT_DETAILS';
export const FETCH_FILTERED_DELIVERABLES = 'FETCH_FILTERED_DELIVERABLES';
export const FETCH_DELIVERABLES_USER_INFO = 'FETCH_DELIVERABLES_USER_INFO';
export const CREATE_DELIVERABLE = 'CREATE_DELIVERABLE';
export const SET_DELIVERABLES = 'SET_DELIVERABLES';
export const RESET_DELIVERABLES = 'RESET_DELIVERABLES';
export const SET_DELIVERABLE_FILES = 'SET_DELIVERABLE_FILES';
export const UPDATE_DELIVERABLE = 'UPDATE_DELIVERABLE';
export const DELETE_DELIVERABLE = 'DELETE_DELIVERABLE';

export function showDeliverableEditorDialog(payload) {
    return {
        type: SHOW_DELIVERABLE_EDITOR_DIALOG,
        payload,
    };
}

export function showDeliverableDeletionDialog(payload) {
    return {
        type: SHOW_DELIVERABLE_DELETION_DIALOG,
        payload,
    };
}

export function resetDeliverableEditor() {
    return {
        type: RESET_DELIVERABLE_EDITOR,
    };
}

export function changeDeliverableEditor(payload) {
    return {
        type: CHANGE_DELIVERABLE_EDITOR,
        payload,
    };
}

export function showDeliverablePermissionsDialog(payload) {
    return {
        type: SHOW_DELIVERABLE_PERMISSION_DIALOG,
        payload,
    };
}

export const fetchContextDetails = (contextName) => {
    return {
        type: FETCH_CONTEXT_DETAILS,
        contextName
    };
};

export const addNewContext = (newContextName) => {
    return {
        type: ADD_NEW_CONTEXT,
        newContextName
    };
};

export const addContextOwner = (contextName, ownerName) => {
    return {
        type: UPDATE_CONTEXT_OWNER,
        mode: UPDATE_CONTEXT_OWNER_MODE_ADD,
        contextName,
        ownerName
    };
};

export const removeContextOwner = (contextName, ownerName) => {
    return {
        type: UPDATE_CONTEXT_OWNER,
        mode: UPDATE_CONTEXT_OWNER_MODE_REMOVE,
        contextName,
        ownerName
    };
};

export const fetchFilteredDeliverables = ({ page, scope, searchCriteria }) => {
    return {
        type: FETCH_FILTERED_DELIVERABLES,
        payload: {
            page,
            scope,
            searchCriteria,
        },
    };
};

export const fetchDeliverablesUserInfo = () => {
    return {
        type: FETCH_DELIVERABLES_USER_INFO,
    };
};

export const createDeliverable = (deliverable) => {
    return {
        type: CREATE_DELIVERABLE,
        payload: deliverable,
    };
};

export const setDeliverables = (deliverableType, deliverables) => {
    return {
        type: SET_DELIVERABLES,
        deliverableType,
        deliverables,
    };
};

export const resetDeliverables = () => {
    return {
        type: RESET_DELIVERABLES,
    };
};

export const setDeliverableFiles = (deliverableId, deliverableFiles) => {
    return {
        type: SET_DELIVERABLE_FILES,
        deliverableId,
        deliverableFiles,
    };
};

export const updateDeliverable = (deliverable) => {
    return {
        type: UPDATE_DELIVERABLE,
        payload: deliverable,
    };
};

export const deleteDeliverable = (deliverableId) => {
    return {
        type: DELETE_DELIVERABLE,
        payload: {
            deliverableId,
        },
    };
};
