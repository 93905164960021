import React, { PureComponent } from 'react';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import { FormattedMessage } from 'react-intl';

export class FlagLabel extends PureComponent {

    onMouseOver = (e) => {
        const { name } = this.props;
        const tooltip = document.getElementsByClassName(`tooltip-${name}`)[0];
        tooltip.setAttributeNS(null, 'visibility', 'visible');
    };

    onMouseLeave = (e) => {
        const { name } = this.props;
        const tooltip = document.getElementsByClassName(`tooltip-${name}`)[0];
        tooltip.setAttributeNS(null, 'visibility', 'hidden');
    };

    render() {

        const { code, classes, ratio, flag, value, name, midAngle } = this.props;
        let { x, y } = this.props;
        const flagHeight = 38;
        const flagWidth = 48;

        if (midAngle >= 0 && midAngle < 45) {
            y -= flagHeight / 2;
        }
        if (midAngle >= 45 && midAngle < 135) {
            y -= flagHeight;
            if (midAngle >= 90) {
                x -= flagWidth;
            }
        }
        if (midAngle >= 135 && midAngle < 225) {
            x -= flagWidth;
            y -= flagHeight / 2;
        }
        if (midAngle >= 225 && midAngle < 315) {
            if (midAngle <= 270) {
                x -= flagWidth;
            }
        }
        if (midAngle >= 315 && midAngle <= 360) {
            y -= flagHeight / 2;
        }
        const flagClasses = classes;
        const flagStyle = { 'position': 'absolute', 'height': '38px', 'width': '50px' };
        const foStyle = { filter: 'drop-shadow(2px 6px 8px rgb(0 0 0 / 0.8))' };
        return <foreignObject x={x} y={y} height={38} width={50} style={foStyle}>
            <OverlayTrigger placement='top' overlay={
                <Tooltip id='flag-label-tooltip' className='top-right'>
                    <FormattedMessage id={`${name}: ${value.toLocaleString()}`}/>
                </Tooltip>}>
                <div className='width-100pct height-100pct'>
                    <span className={flagClasses} style={flagStyle}></span>
                </div>
            </OverlayTrigger>
        </foreignObject>;
    }
}
