import React from 'react';
import DeviceStateSummaryItem from '~/features/deviceReports/components/DeviceStateSummaryItem';
import ActionsByStatus from '~/features/deviceReports/components/ActionsByStatus';
import { actionStatusSettings } from '~/features/deviceReports/constants/actionStatusSettings';
import { actionStatus } from '~/features/deviceReports/constants/actionStatus';

export const DeviceReportSummary = ({ operation }) => {
    if (operation) {
        const { resultsSummary, deviceState } = operation;
        return (
            <div className={'display-flex flex-column padding-bottom-20'}>
                {deviceState?.isRebootPending &&
                    <DeviceStateSummaryItem label={actionStatusSettings[actionStatus.REBOOT_PENDING].label}
                                            message={actionStatusSettings[actionStatus.REBOOT_PENDING].message}
                                            badge={actionStatusSettings[actionStatus.REBOOT_PENDING].badge}/>
                }
                {resultsSummary &&
                    <>
                        <ActionsByStatus actions={resultsSummary[actionStatus.FAILURE]} status={actionStatus.FAILURE}/>
                        <ActionsByStatus actions={resultsSummary[actionStatus.SUCCESS]} status={actionStatus.SUCCESS}/>
                        <ActionsByStatus actions={resultsSummary[actionStatus.ONGOING]} status={actionStatus.ONGOING}/>
                    </>}
            </div>
        );
    } else {
        return <></>;
    }

};

export default DeviceReportSummary;
