import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import FotaL3Container from '~/features/fotaL3/components/FotaL3Container';

export const FotaL3Route = ({ match }) => {
    return (
        <Switch>
            {/*<Route path={`${match.path}/:deliverableType?/:deliverableId?/:deliverableVersion?`}*/}
            {/*       component={ReportsBillingContainer}/>*/}
            <Route path={`${match.path}`} component={FotaL3Container}/>
            <Redirect to={`${match.url}`}/>
        </Switch>
    );
};

export default FotaL3Route;
