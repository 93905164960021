import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import { loginStoreSelector } from '~/features/higherorder/selectors/dataSelectors';

export const profileSelector = (state) => {
    return loginStoreSelector(state)['userProfile'];
};

export const getUserProfile =
    getOr(null, 'login.userProfile');

export const hasUserSessionEverExpired =
    get('login.hasUserSessionEverExpired');

export const isUserSessionExpired =
    get('login.userSessionExpired');



