import React, { useCallback } from 'react';
import { deliverableColumnDescriptors } from '~/features/deliverables/constants/deliverableColumnDescriptors';
import { useValidateDeliverableQuery } from '~/api/deliverables/DeliverablesManagement.api';
import PropertiesTable from '~/features/base/components/table/PropertiesTable';
import isEqual from 'lodash/fp/isEqual';
import { lowerCase } from 'lodash';
import { DELIVERABLE_TYPE_FILE } from '~/features/deliverables/constants/deliverablesParameters';
import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import { FormattedMessage } from 'react-intl';
import ArtifactListContainer from '~/features/deliverables/features/artifacts/components/ArtifactListContainer';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import { isLatamEnv } from '~/features/base/utils/isLatamEnv';

interface DeliverablePropertiesContainerProps {
    selectedDeliverableDetails: any;
}

const DeliverablePropertiesContainer: React.FC<DeliverablePropertiesContainerProps> = ({
    selectedDeliverableDetails,
}) => {
    const filteredDeliverableColumnDescriptors = !isLatamEnv ?
        deliverableColumnDescriptors[selectedDeliverableDetails.deliverableType] : deliverableColumnDescriptors[selectedDeliverableDetails.deliverableType]
            .filter(desc => desc.label !== 'intl-msg:technicalInformation');
    const { data: result } = useValidateDeliverableQuery(
        {
            deliverable: selectedDeliverableDetails,
            isEdit: false
        }
    );

    const onLoadMore = useCallback(() => {
        // Implement the onLoadMore logic here
    }, [/* Dependencies if needed */]);

    return (
        <>
            <div className='padding-top-20 padding-bottom-20'>
                <PropertiesTable
                    showHeader={false}
                    data={selectedDeliverableDetails}
                    rowDescriptors={filteredDeliverableColumnDescriptors}
                />
            </div>
            {isEqual(lowerCase(selectedDeliverableDetails.deliverableType), DELIVERABLE_TYPE_FILE) && (
                <div>
                    <ExpanderPanel
                        title={<FormattedMessage id='intl-msg:artifacts'/>}
                        bsStyle='primary'
                    >
                        <ArtifactListContainer
                            deliverableId={selectedDeliverableDetails.deliverableId}
                            deliverableVersion={toShortSemanticVersion(
                                selectedDeliverableDetails.deliverableVersion,
                            )}
                            owningContext={selectedDeliverableDetails.owningContext}
                            deliverableType={selectedDeliverableDetails.deliverableType}
                            onLoadMore={onLoadMore}
                        />
                    </ExpanderPanel>
                </div>
            )}
        </>
    );
};

export default DeliverablePropertiesContainer;
