export const OK = 'ACTIVATED';
export const WARN = 'WARN';
export const ERROR = 'ERROR';
export const OFFLINE = 'OFFLINE';
export const NOT_CERTIFIED = 'NOT_CERTIFIED';

export const deviceHealthStates = {
    [OK]: {
        id: OK,
        label: 'intl-msg:deviceHealthState.ok',
        tooltip: 'intl-msg:deviceHealthState.ok.tooltip',
        className: 'label-success',
    },
    [WARN]: {
        id: WARN,
        label: 'intl-msg:deviceHealthState.warning',
        tooltip: 'intl-msg:deviceHealthState.warning.tooltip',
        className: 'label-warning',
    },
    [ERROR]: {
        id: ERROR,
        label: 'intl-msg:deviceHealthState.error',
        tooltip: 'intl-msg:deviceHealthState.error.tooltip',
        className: 'label-danger',
    },
    [OFFLINE]: {
        id: OFFLINE,
        label: 'intl-msg:deviceHealthState.offline',
        tooltip: 'intl-msg:deviceHealthState.offline.tooltip',
        className: 'label-warning',
    },
    [NOT_CERTIFIED]: {
        id: NOT_CERTIFIED,
        label: 'intl-msg:deviceHealthState.notCertified',
        tooltip: 'intl-msg:deviceHealthState.notCertified.tooltip',
        className: 'label-warning',
    },
};
