import trim from 'lodash/fp/trim';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

export class HwVariantPrefixFormItem extends Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        const hwVariantPrefix = trim(event.target.value.toUpperCase());
        this.props.onChange({
            hwVariantPrefix,
        });
    }

    render() {
        const { label, value } = this.props;

        return (
            <div className='form-group'>
                <label className='control-label'>
                    <FormattedMessage id={label}/>
                </label>
                <span className='input-group width-100pct'>
                    <FormattedMessage id='intl-msg:hwVariantPrefix.prompt'>
                        { placeHolder =>
                            <input className='form-control'
                                type='text'
                                value={value}
                                placeholder={placeHolder}
                                onChange={this.onChange}/>
                        }
                    </FormattedMessage>
                </span>
            </div>
        );
    }
}

export default HwVariantPrefixFormItem;

HwVariantPrefixFormItem.defaultProps = {
    // props
    label: 'intl-msg:hwVariantPrefix',
    value: '',
    // functions
    onChange: noop,
};

HwVariantPrefixFormItem.propTypes = {
    // props
    label: PropTypes.string,
    value: PropTypes.string,
    // functions
    onChange: PropTypes.func,
};
