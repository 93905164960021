import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import noop from 'lodash/fp/noop';
import { deleteArtifactFile } from '~/features/deliverables/features/artifacts/actions/artifactFileActions';
import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';

/**
 * Artifact file deletion dialog
 */
export class ArtifactFileDeletionDialog extends PureComponent {

    onDeleteArtifactFile = () => {
        const { artifactId, fileName, deleteArtifactFile } = this.props;
        deleteArtifactFile(artifactId, fileName);
    };

    render() {
        const { fileName } = this.props;
        return (
            <ConfirmationDialog className='file-deliverable-deletion-dialog'
                                show={true}
                                title={<FormattedMessage id='intl-msg:deleteFile'/>}
                                content={<FormattedMessage id='intl-msg:deleteFile.prompt' values={{
                                    fileId: fileName,
                                }}/>}
                                cancelButtonText={<FormattedMessage id='intl-msg:cancel'/>}
                                confirmButtonText={<FormattedMessage id='intl-msg:ok'/>}
                                onClickCancel={this.props.hideModal}
                                onClickConfirm={this.onDeleteArtifactFile}/>
        );
    }
}

export const mapStateToProps = (state) => {
    return {};
};

export const mapDispatchToProps = (dispatch) => {
    return {
        deleteArtifactFile: (artifactId, fileName) => {
            dispatch(deleteArtifactFile(artifactId, fileName));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArtifactFileDeletionDialog);

ArtifactFileDeletionDialog.defaultProps = {
    // functions
    deleteArtifactFile: noop,
};

ArtifactFileDeletionDialog.propTypes = {
    // functions
    deleteArtifactFile: PropTypes.func,
};
