import { toFinished } from '~/features/higherorder/transforms/actionTransforms';
import {
    CHANGE_DELIVERABLE_EDITOR,
    CREATE_DELIVERABLE,
    RESET_DELIVERABLE_EDITOR,
    UPDATE_DELIVERABLE,
} from '~/features/deliverables/actions/deliverableActions';
import { NORMAL } from '~/features/base/constants/downloadPriorities';
import { DRAFT } from '~/features/base/constants/releaseStates';
import { env } from '~/env';

const defaultWhitelistInfo = {
    vehicleGroupWhitelist: [],
    deviceWhitelist: [],
    vehicleGroupBlacklist: [],
    deviceBlacklist: [],
    isValidDeviceWhitelist: true, // TODO Is it better to move it out of whitelisting info?
    isValidDeviceBlacklist: true, // TODO Is it better to move it out of whitelisting info?
};

const defaultState = {
    isNew: true,
    isDeliverableVersion: false,
    deliverableId: '',
    owningContext: '',
    deliverableVersion: {
        major: 0,
        minor: 0,
        patch: 0,
    },
    fileInfo: {
        recipient: '',
    },
    appInfo: {
        installerUrl: env.runtimeConfig.packageVersionInstallerBaseUrl,
        mmtInfo: {
            appId: '',
            appVersion: {
                major: 0,
                minor: 0,
                patch: 0,
            },
            downloadUrl: 'https://',
            additionalData: '',
        },
    },
    distroInfo: {
        baseSoftwareVersion: {
            major: 0,
            minor: 0,
            patch: 0,
        },
    },
    bundleInfo: {
        ivd: '',
    },
    bundleDeliverables: [
        {
            deliverableId: '',
            deliverableVersion: {
                'major': 0,
                'minor': 0,
                'patch': 0,
            },
            deliverableType: '',
            sequence: 0,
        },
    ],
    compatibility: {
        baseSoftwareVersionRange: {
            maxValue: {
                major: 0,
                minor: 0,
                patch: 0,
            },
            minValue: {
                major: 0,
                minor: 0,
                patch: 0,
            },
        },
        mdmcAppVersionRange: {
            maxValue: {
                major: 0,
                minor: 0,
                patch: 0,
            },
            minValue: {
                major: 0,
                minor: 0,
                patch: 0,
            },
        },
    },
    priority: NORMAL,
    whitelisting: defaultWhitelistInfo,
    isInProgress: false,
    releaseState: DRAFT,
    downloadUrl: 'https://',
    description: '',
    standalone: true,
    deviceType: '',
};

export const resetDeliverableEditor = (state = defaultState) => {
    return state;
};
export const changeDeliverableEditor = (state, { payload }) => {
    const newState = {
        ...state,
        ...payload,
    };
    return newState;
};
export const setInProgress = (state) => {
    return {
        ...state,
        isInProgress: true,
    };
};
export const setNotInProgress = (state) => {
    return {
        ...state,
        isInProgress: false,
    };
};

export default function deliverableEditorReducerNeo(state = defaultState, action = {}) {
    switch (action.type) {
        case RESET_DELIVERABLE_EDITOR:
            return resetDeliverableEditor();
        case CHANGE_DELIVERABLE_EDITOR:
            return changeDeliverableEditor(state, action);
        case CREATE_DELIVERABLE:
        case UPDATE_DELIVERABLE:
            return setInProgress(state);
        case toFinished(CREATE_DELIVERABLE):
        case toFinished(UPDATE_DELIVERABLE):
            return setNotInProgress(state);
        default:
            return state;
    }
}
