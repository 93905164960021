import noop from 'lodash/fp/noop';
import split from 'lodash/fp/split';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { GROUPS_PATH, DETAILS_PATH, DEVICES_ASSIGNED_PATH, REFERENCES_PATH } from '~/features/base/constants/routes';

import GroupDetailsToolbar from '~/features/groups/components/GroupDetailsToolbar';
import GroupDetailsTabs from '~/features/groups/components/GroupDetailsTabs';
import PageHeader from '~/features/base/components/PageHeader';
import PageHeaderLink from '~/features/base/components/PageHeaderLink';

import GroupPropertiesContainer from '~/features/groups/components/GroupPropertiesContainer';
import GroupDevicesAssignedContainer from '~/features/groups/components/GroupDevicesAssignedContainer';
import GroupReferences from '~/features/groups/components/GroupReferences';

export class GroupDetails extends PureComponent {

    render() {
        const { groupName } = this.props;
        const content = this.renderContent();
        return (
            <div className='group-details row'>
                <PageHeader pageTitle={`${groupName}`}
                    pageLink={
                        <PageHeaderLink to={`/${GROUPS_PATH}`}>
                            <FormattedMessage id='intl-msg:backToList'/>
                        </PageHeaderLink>
                    }/>
                {content}
            </div>
        );
    }

    renderContent() {
        const { canReadGroups } = this.props;

        if (!canReadGroups) {
            return <FormattedMessage id='intl-msg:accessNotAllowed'/>;
        }
        const toolbar = this.renderToolbar();
        const tabs = this.renderTabs();
        const routes = this.renderRoutes();
        return [
            <div className='col-md-12'>
                {toolbar}
            </div>,
            <div className='col-md-12'>
                {tabs}
            </div>,
            routes,
        ];
    }

    renderRoutes() {
        const { groupName, match } = this.props;
        if (!groupName || !match) {
            return;
        }
        // TODO Move DefaultWhiteColumn down into views
        return (
            <Switch>
                <Route path={`${match.path}/${DETAILS_PATH}`} render={() =>
                    <GroupPropertiesContainer groupName={groupName}/>}/>
                <Route path={`${match.path}/${DEVICES_ASSIGNED_PATH}`} render={() =>
                    <GroupDevicesAssignedContainer groupName={groupName}/>} />
                <Route path={`${match.path}/${REFERENCES_PATH}`} render={() =>
                    <GroupReferences groupName={groupName}/>} />
                <Redirect to={`${match.url}/${DETAILS_PATH}`} />
            </Switch>
        );
    }

    renderToolbar() {
        const {
            group,
            canUpdateGroups,
            canDeleteGroups,
        } = this.props;
        // TODO STANDARD_RENDER_PATTERN
        if (!group) {
            return;
        }
        return (
            <GroupDetailsToolbar
                canUpdateGroups={canUpdateGroups}
                canDeleteGroups={canDeleteGroups}
                onEditGroup={() => this.props.onEditGroup({ isNew: false, ...group })}
                onDeleteGroup={() => this.props.onDeleteGroup(group)}/>
        );
    }

    renderTabs() {
        const { pathname, groupName, group } = this.props;
        // TODO STANDARD_RENDER_PATTERN
        if (!group) {
            return;
        }
        // TODO Move this up or down into tabs
        const view = split(/[/ ]+/, pathname).pop();
        return (
            <GroupDetailsTabs
                basePath={`/${GROUPS_PATH}/${groupName}`}
                view={view}/>
        );
    }
}

export default GroupDetails;

GroupDetails.defaultProps = {
    // props
    pathname: '',
    canReadGroups: false,
    canUpdateGroups: false,
    canDeleteGroups: false,
    groupName: '',
    group: {},
    // functions
    onEditGroup: noop,
    onDeleteGroup: noop,
};

GroupDetails.propTypes = {
    // props
    pathname: PropTypes.string,
    canReadGroups: PropTypes.bool,
    canUpdateGroups: PropTypes.bool,
    canDeleteGroups: PropTypes.bool,
    groupName: PropTypes.string,
    group: PropTypes.object,
    // functions
    onEditGroup: PropTypes.func,
    onDeleteGroup: PropTypes.func,
};
