import size from 'lodash/fp/size';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';
import DeviceFileVersionsTable from '~/features/devices/components/DeviceFileVersionsTable';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';

/**
 * Component for the device file versions
 */
export class DeviceFileVersions extends PureComponent {
    mergeFilesWithEligibleFileVersions = (latestKnownFileVersions, eligibleFileVersions) => {
        let mergedFiles = [];
        // add installed files
        if (latestKnownFileVersions) {
            latestKnownFileVersions.map((currentFileVersion) => {
                // add eligible files version if available
                if (eligibleFileVersions) {
                    const eligibleFileVersion = eligibleFileVersions[currentFileVersion.fileId];
                    if (eligibleFileVersion) {
                        currentFileVersion.eligibleFileVersion = eligibleFileVersion;
                        delete eligibleFileVersions[currentFileVersion.fileId];
                    }
                }
                mergedFiles.push(currentFileVersion);
            });
        }

        // add eligible only packages
        if (eligibleFileVersions) {
            Object.entries(eligibleFileVersions).map((currentFileVersion) => {
                mergedFiles.push({
                    fileId: currentFileVersion[0],
                    eligibleFileVersion: currentFileVersion[1],
                });
            });
        }
        return mergedFiles;
    };

    render() {
        const { controlDeviceInfo, controlDeviceEligibleFileVersions } = this.props;
        if (!controlDeviceInfo) {
            return null;
        }
        if (!size(controlDeviceInfo.latestKnownFileVersions) && !size(controlDeviceEligibleFileVersions)) {
            return (
                <DefaultWhiteColumn className='padding-top-20 padding-bottom-20'>
                    <NotFoundState fullWidth
                                   headline={<FormattedMessage id='intl-msg:nothingFound'/>}
                                   message={<FormattedMessage id='intl-msg:noFileVersionsAvailableYet'/>}/>
                </DefaultWhiteColumn>
            );
        }
        return (
            <DefaultWhiteColumn className='padding-bottom-20'>
                <div className='alert alert-dismissible alert-info'>
                    <span className='rioglyph rioglyph-info-sign' aria-hidden='true'></span>
                    <FormattedMessage
                        id='intl-msg:deviceDetails.files.info'/>
                </div>
                <DeviceFileVersionsTable
                    fileVersions={this.mergeFilesWithEligibleFileVersions(controlDeviceInfo.latestKnownFileVersions,
                        controlDeviceEligibleFileVersions)}/>
            </DefaultWhiteColumn>
        );
    }
}

export default DeviceFileVersions;

DeviceFileVersions.propTypes = {
    // props
    controlDeviceInfo: PropTypes.object,
    controlDeviceEligibleFileVersions: PropTypes.object,
    // functions
};
