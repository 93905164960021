import { combineReducers } from '@reduxjs/toolkit';

import {
    ENTITY_CONTROL_DEVICE,
    ENTITY_DELIVERABLE,
    ENTITY_DELIVERABLE_ID,
    ENTITY_DEVICE_ACTION,
    ENTITY_DISTRO,
    ENTITY_FILE,
    ENTITY_FILE_DELIVERABLE,
    ENTITY_PACKAGE,
    ENTITY_PACKAGE_DELIVERABLE,
    ENTITY_UPDATE_EVENT,
    ENTITY_UPDATE_EVENT_REPORT,
    ENTITY_VEHICLES,
} from '~/features/base/constants/entities';

// higher order
import createAggregationReducer from '~/features/higherorder/reducers/aggregationReducer';

export const aggregationsReducer = combineReducers({
    [ENTITY_CONTROL_DEVICE]: createAggregationReducer([ENTITY_CONTROL_DEVICE]),
    [ENTITY_VEHICLES]: createAggregationReducer([ENTITY_VEHICLES]),
    [ENTITY_DELIVERABLE]: createAggregationReducer([ENTITY_DELIVERABLE]),
    [ENTITY_DELIVERABLE_ID]: createAggregationReducer([ENTITY_DELIVERABLE_ID]),
    [ENTITY_DISTRO]: createAggregationReducer([ENTITY_DISTRO]),
    [ENTITY_PACKAGE]: createAggregationReducer([ENTITY_PACKAGE]),
    [ENTITY_PACKAGE_DELIVERABLE]: createAggregationReducer([ENTITY_PACKAGE_DELIVERABLE]),
    [ENTITY_FILE]: createAggregationReducer([ENTITY_FILE]),
    [ENTITY_FILE_DELIVERABLE]: createAggregationReducer([ENTITY_FILE_DELIVERABLE]),
    [ENTITY_DEVICE_ACTION]: createAggregationReducer([ENTITY_DEVICE_ACTION]),
    [ENTITY_UPDATE_EVENT]: createAggregationReducer([ENTITY_UPDATE_EVENT]),
    [ENTITY_UPDATE_EVENT_REPORT]: createAggregationReducer([ENTITY_UPDATE_EVENT_REPORT]),
});
export default aggregationsReducer;
