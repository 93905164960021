import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';
import classNames from 'classnames';
import map from 'lodash/fp/map';
import FieldOrNoData from '~/features/base/components/FieldOrNoData';
import DeliverableFiles from '~/features/artifacts/components/DeliverableFiles';
import { connect } from 'react-redux';
import { uploadDeliverableFile } from '~/features/artifacts/actions/deliverableFileActions';
import { showDeliverableDeletionDialog } from '~/features/artifacts/actions/deliverableActions';
import { canManageDeliverablesSelector } from '~/features/user/selectors/permissionSelectors';
import { deliverablesContextsSelector } from '~/features/artifacts/selectors/deliverableSelectors';
import { hasAccessibleContextForDeliverable } from '~/features/deliverables/utils/utils';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import FilePicker from '@rio-cloud/rio-uikit/lib/es/FilePicker';

// table-bordered
const tableClassName = 'table table-layout-fixed table-column-overflow-hidden table-head-filled table-sticky';

export class DeliverablesTable extends PureComponent {

    onDeleteButtonClick = (event) => {
        const deliverableId = event.currentTarget.getAttribute('data-key-deliverable-id');
        const deliverableName = event.currentTarget.getAttribute('data-key-deliverable-name');
        this.props.showDeliverableDeletionDialog({
            deliverableId,
            deliverableName,
        });
    };

    onFileSelect = (deliverableId, file) => {
        this.props.uploadDeliverableFile(deliverableId, file);
    };

    render() {
        const { deliverables } = this.props;
        const rows = this.renderRows(deliverables);
        return (
            <table className={classNames(tableClassName)}>
                <thead className='table-head'>
                <tr>
                    <th className='user-select-none'><FormattedMessage id='intl-msg:deliverableName'/></th>
                    <th className='user-select-none'><FormattedMessage id='intl-msg:context'/></th>
                    <th className='user-select-none'><FormattedMessage id='intl-msg:creator'/></th>
                    <th className='user-select-none'><FormattedMessage id='intl-msg:creationTime'/></th>
                    <th className='user-select-none'></th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        );
    }

    renderRows(deliverables) {
        return map(deliverable => {
            const buttonOptions = this.renderOption(deliverable);
            return (
                [<tr>
                    <td>
                        {deliverable.deliverableName}
                    </td>
                    <td>
                        {deliverable.context}
                    </td>
                    <td>
                        {deliverable.creator}
                    </td>
                    <td>
                        <FieldOrNoData field={deliverable.creationTime ?
                            <FormattedTime value={deliverable.creationTime} year='numeric' month='2-digit'
                                           day='2-digit'/> : null}/>
                    </td>
                    <td className='table-action'>
                        <ButtonDropdown
                            title={<span className='rioglyph rioglyph-option-vertical'/>}
                            className='pull-right'
                            bsStyle='link'
                            iconOnly
                            items={buttonOptions}
                        />
                    </td>
                </tr>,
                    <tr>
                        <ExpanderPanel open={this.props.expanderOpen} title={'Show Files'} iconLeft>
                            <div style={{ width: 1000 }}>
                                <div className='row bg-light padding-bottom-10'>
                                    <div className='padding-10'>
                                        {this.props.canManageDeliverables &&
                                            <FilePicker
                                                label={<FormattedMessage id='intl-msg:addFile'/>}
                                                onPick={(acceptedFiles) => this.onFileSelect(
                                                    deliverable.deliverableId, acceptedFiles)}
                                            />
                                        }
                                    </div>
                                    <div>
                                        <DeliverableFiles deliverableId={deliverable.deliverableId}/>
                                    </div>
                                </div>
                            </div>
                        </ExpanderPanel>
                    </tr>]
            );
        }, deliverables);
    }

    renderOption(deliverable) {
        const buttonOptions = [];
        const { canDeleteDeliverable, canManageDeliverables, deliverablesContexts } = this.props;
        const hasAccessibleContext = hasAccessibleContextForDeliverable(deliverablesContexts, deliverable);
        buttonOptions.push({
            value: (
                <div
                    onClick={this.onDeleteButtonClick}
                    data-key-deliverable-id={deliverable.deliverableId}
                    data-key-deliverable-name={deliverable.deliverableName}
                >
                    <span className='rioglyph rioglyph-trash margin-right-10'></span>
                    <span><FormattedMessage id='intl-msg:delete'/></span>
                </div>
            ),
            disabled: !(canDeleteDeliverable && canManageDeliverables && hasAccessibleContext),
        });
        return buttonOptions;
    }
}

export const mapStateToProps = (state) => ({
    canManageDeliverables: canManageDeliverablesSelector(state),
    deliverablesContexts: deliverablesContextsSelector(state),
});

export const mapDispatchToProps = (dispatch) => {
    return {
        uploadDeliverableFile: (deliverableId, file) => {
            dispatch(uploadDeliverableFile(deliverableId, file));
        },
        showDeliverableDeletionDialog: payload => {
            dispatch(showDeliverableDeletionDialog(payload));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverablesTable);

DeliverablesTable.defaultProps = {
    // props
    expanderOpen: false,
    deliverables: [],
    deliverablesContexts: [],
    canDeleteDeliverable: true,
    canManageDeliverables: true,
    // functions
    onFileSelect: noop,
    onDeleteFileDeliverable: noop,
};

DeliverablesTable.propTypes = {
    // props
    deliverables: PropTypes.array,
    deliverablesContexts: PropTypes.array,
    canDeleteDeliverable: PropTypes.bool,
    canManageDeliverables: PropTypes.bool,
    // functions
    onFileSelect: PropTypes.func,
    onDeleteFileDeliverable: PropTypes.func,
};
