import PropTypes from 'prop-types';
import { WhitelistingInfo } from '~/prop-types/whitelistingInfoPropType';

export const PackageVersion = PropTypes.shape({
    major: PropTypes.number,
    minor: PropTypes.number,
    patch: PropTypes.number,
});

export const PackageRelease = PropTypes.shape({
    packageId: PropTypes.string,
    packageVersion: PropTypes.instanceOf(PackageVersion),
    releaseState: PropTypes.string,
    description: PropTypes.string,
    whitelistingInfo: PropTypes.instanceOf(WhitelistingInfo),
    downloadBaseUrl: PropTypes.string,
    networkCodeWhitelist: PropTypes.array,
});

export default PackageRelease;
