import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import NumberInput from '@rio-cloud/rio-uikit/lib/es/NumberInput';

export class SemanticVersionInputFormItem extends Component {

    onMajorChange = (major) => {
        this.props.onChange({
            ...this.props.value,
            major,
        });
    };

    onMinorChange = (minor) => {
        this.props.onChange({
            ...this.props.value,
            minor,
        });
    };

    onPatchChange = (patch) => {
        this.props.onChange({
            ...this.props.value,
            patch,
        });
    };

    onClearButtonClick = () => {
        this.props.onChange(undefined);
    };

    render() {
        const { label, value, classNameClearButton, classNameInputField } = this.props;
        const clearButton = this.renderClearButton(classNameClearButton);
        return (
            <div className='form-group form-group-semantic-version'>
                <label className='control-label'>
                    <FormattedMessage id={label} />
                </label>
                <div className='row'>
                    <div className={classNameInputField}>
                        <NumberInput min={0} value={value.major}
                            onValueChanged={this.onMajorChange} />
                    </div>
                    <div className={classNameInputField}>
                        <NumberInput min={0} value={value.minor}
                            onValueChanged={this.onMinorChange} />
                    </div>
                    <div className={classNameInputField}>
                        <NumberInput min={0} value={value.patch}
                            onValueChanged={this.onPatchChange} />
                    </div>
                    {clearButton}
                </div>
            </div>
        );
    }

    renderClearButton(classNameClearButton) {
        if (this.props.isClearable) {
            return (
                <div className={classNameClearButton}>
                    <FormattedMessage id='intl-msg:clear'>
                        {
                            clearMessage => (
                                <button type='button' title={clearMessage}
                                    className='btn btn-muted btn-icon-only'
                                    onClick={this.onClearButtonClick}>
                                    <span className='rioglyph rioglyph-remove-sign' aria-hidden='true'></span>
                                </button>
                            )
                        }
                    </FormattedMessage>
                </div>
            );
        }
    }
}

export default SemanticVersionInputFormItem;

SemanticVersionInputFormItem.defaultProps = {
    // props
    label: 'intl-msg:baseSoftwareVersion',
    classNameInputField: 'col-xs-3 col-sm-3 col-md-2',
    classNameClearButton: 'col-xs-3 col-sm-3 col-md-6',
    value: {
        major: 0,
        minor: 0,
        patch: 0,
    },
    isClearable: false,
    // functions
    onChange: noop,
};

SemanticVersionInputFormItem.propTypes = {
    // props
    label: PropTypes.string,
    classNameInputField: PropTypes.string,
    classNameClearButton: PropTypes.string,
    value: PropTypes.object,
    isClearable: PropTypes.bool,
    // functions
    onChange: PropTypes.func,
};
