import {LogsRequestRequestedSystemsEnum} from "~/api/models/logs-request";
import values from "lodash/fp/values";

const { NAD, VUC, CHIPPS } = LogsRequestRequestedSystemsEnum;

export const serviceOptionsCM4 = {
    [NAD]: {
        id: NAD,
        label: NAD,
        value: NAD,
    },
    [VUC]: {
        id: VUC,
        label: VUC,
        value: VUC,
    },
    [CHIPPS]: {
        id: CHIPPS,
        label: CHIPPS,
        value: CHIPPS,
    }
};

export const createCM4SystemFilterOptions = () => values(serviceOptionsCM4);
