import React from 'react';
import size from 'lodash/fp/size';
import map from 'lodash/fp/map';

export const rawDeliverableTypeKeys = ['distros', 'apps', 'files'];
export const rawAdditionalTypeKeys = ['checkForUpdateRequests', 'logFileRequests'];//, 'systemParameterRequests'];
export const deliverableTypeKeys = ['Distros', 'Apps', 'Files'];
export const additionalTypeKeys = ['CheckForUpdate', 'LogFile'];//, 'systemParameterRequests'];
export const downloadPropertiesPair = ['totalUploadBytes', 'totalDownloadBytes'];
export const requestPropertiesPair = ['totalUploadRequests', 'totalDownloadRequests'];
export const chartDomains = ['bytes', 'requests', 'costs'];
const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const BYTE_TO_MB = 1048576;
export const COSTS_PER_GB = 1.55;
const yAxisDataVolumeCostsDivider = BYTE_TO_MB * 1024;
const yAxisNoDownloadsDivider = 1000;

export const getStartDate = () => {
    const now = new Date();
    const past = new Date(now.getFullYear(), now.getMonth() - 2, 1);
    return past.getTime();
};

export const getEndDate = () => {
    return new Date().getTime();
};

export const createIntermediateObject = (item) => {
    return processRawDataItem(item);
};

export const keyMapping = (key) => {
    const mapping = key.includes('Requests') ? key.replace('Requests', '') : key;
    return mapping.charAt(0).toUpperCase() + mapping.slice(1);
}

const addAdditionalTypeKeys = (retObj, topLevelItem) => {
    map((key) => {
        const displayKey = keyMapping(key);
        if(topLevelItem[key]){
            retObj[displayKey] = topLevelItem[key]['totalDataTrafficDetails']
        }
    }, rawAdditionalTypeKeys)
}

const processRawDataItem = (topLevelItem) => {
    const types = topLevelItem['deliverables'];
    const retObj = {
        timeRangeType: topLevelItem['timeRange']['timeRangeType'],
        timeRangeName: `${monthNames[new Date(
            topLevelItem['timeRange']['timeRangeValue']).getMonth()]} ${topLevelItem['timeRange']['timeRangeValue'].split(
            '-')[0].slice(2)}`,
        timeRangeValue: topLevelItem['timeRange']['timeRangeValue'],
    };

    addAdditionalTypeKeys(retObj, topLevelItem);

    for (let [key, val] of Object.entries(types)) {
        const typeDeliverables = val['deliverables'];
        const displayKey = keyMapping(key);
        retObj[displayKey] = {};
        retObj[displayKey]['totals'] = processDeliverableTypeTotals(val, key);
        retObj[displayKey]['details'] = processDeliverableTypeDeliverables(typeDeliverables);
    }
    return retObj;
};

const processDeliverableTypeTotals = (type) => {
    let retObj = {};
    retObj = type['totalDeliverableDataTrafficDetails'];
    return retObj;
};

const processDeliverableTypeDeliverables = (typeDeliverablesArr) => {
    const retArr = [];
    for (let i = 0; i < size(typeDeliverablesArr); i++) {
        const currentObj = typeDeliverablesArr[i];
        const versions = currentObj['deliverableVersions'];
        for (let v = 0; v < size(versions); v++) {
            const retObj = {};
            const version = versions[v];
            retObj.id = currentObj['deliverableId'];
            retObj.version = version['deliverableVersion'];
            Object.assign(retObj, version['deliverableDataTrafficDetails']);
            retArr.push(retObj);
        }
    }
    return retArr;
};

export const createTotalChartsData = (interObj) => {
    const totalChartsData = {};
    map((domain) => {
        totalChartsData[domain] = buildTotalChartsDataObjects(
            interObj,
            domain === 'requests'
                ? { keyPair: requestPropertiesPair, divider: yAxisNoDownloadsDivider }
                : { keyPair: downloadPropertiesPair, divider: yAxisDataVolumeCostsDivider },
            domain === 'costs'
                ? { factor: COSTS_PER_GB, decimals: 1 }
                : { factor: 1, decimals: 0 });
    }, chartDomains);
    return totalChartsData;
};

const buildTotalChartsDataObjects = (interObj, props, calc) => {
    const targetArr = [];
    map((data) => {
        targetArr.push(calculateTypesForTotal(data, props, calc));
    }, interObj);
    return targetArr;
};

const calculateTypesForTotal = (intermediateDataItem, domainProps, calc) => {
    const retObj = {};
    const parseTotal = (data) => {
        const value = data[domainProps.keyPair[0]] + data[domainProps.keyPair[1]];
        return calc.decimals === 0
            ? parseInt((value / domainProps.divider * calc.factor).toFixed(calc.decimals), 10)
            : parseFloat((value / domainProps.divider * calc.factor).toFixed(calc.decimals));
    }
    map((type) => {
        const displayType = keyMapping(type);
        const deliverableTypeObj = intermediateDataItem[displayType];
        retObj['name'] = intermediateDataItem['timeRangeName'];
        retObj['value'] = intermediateDataItem['timeRangeValue'];
        retObj[`${displayType}`] = parseTotal(deliverableTypeObj['totals']);
    }, deliverableTypeKeys);
    map((additionalType) => {
        const displayType = keyMapping(additionalType);
        const deliverableTypeObj = intermediateDataItem[displayType];
        if(deliverableTypeObj) {
            retObj[`${displayType}`] = parseTotal(deliverableTypeObj);
        }
    }, additionalTypeKeys);
    return retObj;
};

export const sourceDataDetails = (interObj, range, type) => {
    const retValue = interObj.filter((item) => item.timeRangeValue === range);
    return retValue[0][type]['details'];
};

export const createDetailedPieChartData = (details, domain) => {
    const retArr = [];
    const checkForDuplicate = (detail) => {
        const duplicate = retArr.filter(element => element.name === detail.id);
        return duplicate.length > 0 ? `${detail.id} (${duplicate.length})` : detail.id;
    }
    if (domain === 'bytes') {
        map((detail) => {
            const o = {};
            o.name = checkForDuplicate(detail);
            o.version = detail.version;
            o.upload = (detail[`${downloadPropertiesPair[0]}`] / yAxisDataVolumeCostsDivider).toFixed(2);
            o.download = (detail[`${downloadPropertiesPair[1]}`] / yAxisDataVolumeCostsDivider).toFixed(2);
            const value = detail[`${downloadPropertiesPair[0]}`] + detail[`${downloadPropertiesPair[1]}`];
            o.value = value / yAxisDataVolumeCostsDivider;
            o.costs = (value / yAxisDataVolumeCostsDivider * COSTS_PER_GB).toFixed(2);
            retArr.push(o);
        }, details);
    }
    if (domain === 'requests') {
        map((detail) => {
            const o = {};
            o.name = checkForDuplicate(detail);
            o.version = detail.version;
            o.upload = detail[`${requestPropertiesPair[0]}`].toLocaleString();
            o.download = detail[`${requestPropertiesPair[1]}`].toLocaleString();
            const value = detail[`${requestPropertiesPair[0]}`] + detail[`${requestPropertiesPair[1]}`];
            o.value = value;
            const cost = detail[`${downloadPropertiesPair[0]}`] + detail[`${downloadPropertiesPair[1]}`];
            o.costs = ((cost) / yAxisDataVolumeCostsDivider * COSTS_PER_GB).toFixed(2);
            retArr.push(o);
        }, details);
    }
    if (domain === 'costs') {
        map((detail) => {
            const o = {};
            const gBytes = (detail[`${downloadPropertiesPair[0]}`] + detail[`${downloadPropertiesPair[1]}`]) / yAxisDataVolumeCostsDivider;
            o.name = checkForDuplicate(detail);
            o.version = detail.version;
            o.bytes = gBytes;
            o.requests = detail[`${requestPropertiesPair[0]}`] + detail[`${requestPropertiesPair[1]}`];
            o.costs = gBytes * COSTS_PER_GB;
            retArr.push(o);
        }, details);
    }
    return retArr;
};

