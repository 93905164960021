import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface VehicleState {
    vehicleSelected?: string;
}

const initialState: VehicleState = {
    vehicleSelected: undefined,
};

export const vehiclesSlice = createSlice(
    {
        name: 'vehicles',
        initialState,
        reducers: {
            setVehicleSelected: (state, action: PayloadAction<string | undefined>) => {
                state.vehicleSelected = action.payload;
            }
        }

    }
);

export const getSelectedVehicle = (state) => state.vehicles.vehicleSelected;
export const { setVehicleSelected } = vehiclesSlice.actions;
export default vehiclesSlice.reducer;
