import getOr from 'lodash/fp/getOr';

import { createSelector } from 'reselect';

import { ENTITY_SYS_PARAM_DEVICE_VALUE } from '~/features/base/constants/entities';

import { entityStoreSelector } from '~/features/higherorder/selectors/dataSelectors';
import { shortBaseSwVersionOfDeviceSelector } from '~/features/baseSwVersions/selectors/baseSwVersionSelectors';

const mapBySerialsSelector = state => entityStoreSelector(state)[ENTITY_SYS_PARAM_DEVICE_VALUE];
const mapByBaseSwVersionsSelector = (state, { serialNumber }) => {
    return getOr({}, serialNumber, mapBySerialsSelector(state));
};

export const sysParamDeviceValuesOfBaseSwVersionSelector = (state, { serialNumber, shortBaseSwVersion }) => {
    return getOr({}, shortBaseSwVersion, mapByBaseSwVersionsSelector(state, { serialNumber }));
};

export const sysParamDeviceValuesOfDeviceSelector = createSelector(
    [mapByBaseSwVersionsSelector, shortBaseSwVersionOfDeviceSelector],
    (mapOfBaseSwVersions, shortBaseSwVersion) => {
        return getOr({}, shortBaseSwVersion, mapOfBaseSwVersions);
    }
);
