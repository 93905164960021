import classNames from 'classnames';

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { registrationMethods, UNKNOWN } from '~/features/devices/constants/registrationMethods';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

export const DeviceRegistrationMethod = ({ registrationMethod }) => {
    const registrationMethodObject = registrationMethods[registrationMethod] || registrationMethods[UNKNOWN];
    return (
        <span>
            <OverlayTrigger placement='top' overlay={
                <Tooltip id='tooltip' className='top-right'>
                    <FormattedMessage id={registrationMethodObject.tooltip}/>
                </Tooltip>}>
                <span className={classNames('label label-condensed margin-right-5', registrationMethodObject.className)}>
                    <FormattedMessage id={registrationMethodObject.label} />
                </span>
            </OverlayTrigger>
        </span>
    );
};

export default DeviceRegistrationMethod;

DeviceRegistrationMethod.defaultProps = {
};

DeviceRegistrationMethod.propTypes = {
    registrationMethod: PropTypes.string,
};
