import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * Log requests list toolbar component
 */
export class LogRequestsListToolbar extends PureComponent {
    render() {
        const { children } = this.props;
        const createButton = this.renderCreateButton();
        return (
            <div className='row device-action-list-toolbar'>
                <div className='col-xs-6'>
                    <div className='btn-toolbar'>
                        {children}
                    </div>
                </div>
            </div>
        );
    }

    renderCreateButton() {
        if (this.props.canCreateDeviceActions) {
            return (
                <a id='create-device-action-button'
                   className='btn btn-default'
                   onClick={this.props.onShowDeviceActionEditor}>
                    <span className='rioglyph rioglyph-plus' aria-hidden='true'></span>&nbsp;
                    <FormattedMessage id='intl-msg:createLogRequest'/>
                </a>
            );
        }
    }
}

export default LogRequestsListToolbar;

LogRequestsListToolbar.defaultProps = {
    canCreateDeviceActions: false,
    onShowDeviceActionEditor: noop,
};

LogRequestsListToolbar.propTypes = {
    // props
    canCreateDeviceActions: PropTypes.bool,
    // functions
    onShowDeviceActionEditor: PropTypes.func,
};
