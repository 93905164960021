import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import map from 'lodash/fp/map';
import includes from 'lodash/fp/includes';
import differenceWith from 'lodash/fp/differenceWith';
import isEqual from 'lodash/fp/isEqual';
import reverse from 'lodash/fp/reverse';

import { createSelector } from 'reselect';

import {
    ENTITY_BASE_SW,
    ENTITY_BASE_SW_SCOPE_ALL,
    ENTITY_BASE_SW_SCOPE_EDITABLE,
    ENTITY_BASE_SW_VERSIONS,
} from '~/features/base/constants/entities';

import { distroSelector, distroEditorSelector, distroPageItemsSelector } from '~/features/deliverables/features/distros/selectors/distroSelectors';
import { entityStoreSelector, indicesStoreSelector } from '~/features/higherorder/selectors/dataSelectors';
import { controlDeviceSelector } from '~/features/devices/selectors/controlDeviceSelectors';

const toDistroWithIsBaseSwEditable = (distro, editableBaseSwVersions) => ({
    ...distro,
    isBaseSwEditable: includes(distro.shortBaseSwVersion, editableBaseSwVersions),
});

const baseSwVersionsEntitiesSelector = state => entityStoreSelector(state)[ENTITY_BASE_SW];
const baseSwVersionsSortedIdsSelector = state => indicesStoreSelector(state)[ENTITY_BASE_SW];
const allSortedIdsSelector = state => getOr([], ENTITY_BASE_SW_SCOPE_ALL, baseSwVersionsSortedIdsSelector(state));

export const editableBaseSwVersionsSelector = state => {
    return getOr([], ENTITY_BASE_SW_SCOPE_EDITABLE, baseSwVersionsSortedIdsSelector(state));
};

export const baseSwEntitiesSelector = state => entityStoreSelector(state)[ENTITY_BASE_SW_VERSIONS];

export const baseSwVersionsSelector = createSelector(
    [allSortedIdsSelector, baseSwVersionsEntitiesSelector],
    (sortedIds, entities) => {
        return map(id => entities[id], sortedIds);
    }
);

export const shortBaseSwVersionOfDistroSelector = createSelector(
    [distroSelector],
    distro => get('shortBaseSwVersion', distro)
);

export const shortBaseSwVersionOfDeviceSelector = createSelector(
    [controlDeviceSelector],
    device => get('shortLastBaseSoftwareVersionReported', device)
);

export const availableBaseSwVersionsSelector = createSelector(
    [distroEditorSelector, baseSwVersionsSelector],
    (model, baseSwVersions) => {
        return reverse(differenceWith((a, b) => isEqual(a.softwareVersion, b.softwareVersion), baseSwVersions, [model]));
    }
);

export const distroWithBaseSwSelector = createSelector(
    [distroSelector, editableBaseSwVersionsSelector],
    (distro, editableBaseSwVersions) => {
        const result = distro ? toDistroWithIsBaseSwEditable(distro, editableBaseSwVersions) : undefined;
        return result;
    }
);

export const distroPageItemsWithEditableBaseSwSelector = createSelector(
    [distroPageItemsSelector, editableBaseSwVersionsSelector],
    (distros, editableBaseSwVersions) => {
        const result = map(distro => toDistroWithIsBaseSwEditable(distro, editableBaseSwVersions), distros);
        return result;
    }
);
