import includes from 'lodash/fp/includes';
import map from 'lodash/fp/map';

export const selectionByValue = value => map(option => ({
    ...option,
    selected: option.value === value,
}));
export const selectionByValues = values => map(option => ({
    ...option,
    selected: includes(option.value, values),
}));
