import PropTypes from 'prop-types';
import React from 'react';

import { deviceManagedPackageColumnDescriptors } from '~/features/deliverables/features/packages/constants/packageColumnDescriptors';

import ListTable from '~/features/base/components/table/ListTable';

export const DeviceManagedPackagesTable = ({ packages }) => {
    if (packages) {
        return [
            <ListTable showHeader={false}
                       items={packages}
                       itemKey='packageId'
                       columnDescriptors={deviceManagedPackageColumnDescriptors}/>,
        ];
    }
};

export default DeviceManagedPackagesTable;

DeviceManagedPackagesTable.defaultProps = {
    packages: [],
};

DeviceManagedPackagesTable.propTypes = {
    // props
    packages: PropTypes.array,
};
