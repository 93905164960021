import React from 'react';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { useCreateManualAdaptOrderMutation, useUpdateAdaptOrderMutation } from '~/api/adaptOrder/AdaptOrder.api';
import { ManualAdaptOrder } from '~/api/models/manualAdaptOrder';
import DeliverableIdVersionSelect from '~/features/base/components/forms/DeliverableIdVersionSelect';
import InputOTA from '~/features/base/components/forms/InputOTA';
import {udfDataToArr, udfDataToObj} from "~/features/adaptOrders/utils/udfDataParse";

interface AdaptOrderFromDialogProps {
    isNew: boolean;
    onHide: () => void;
    show: boolean;
    vin: string;
    adaptOrder: ManualAdaptOrder | undefined;
}

const AdaptOrderFromDialog: React.FC<AdaptOrderFromDialogProps> = ({ isNew, onHide, show, vin, adaptOrder }) => {

    const {
        handleSubmit,
        control,
    } = useForm({
        defaultValues: {
            deliverableId: adaptOrder?.deliverableId,
            deliverableVersion: adaptOrder?.deliverableVersion,
            udfData: udfDataToObj(adaptOrder?.udfData)
        }
    });

    const [createManualAdaptOrder] = useCreateManualAdaptOrderMutation();
    const [updateAdaptOrder] = useUpdateAdaptOrderMutation();

    const apiCall = isNew ? createManualAdaptOrder : updateAdaptOrder;

    const handleRequest = async(requestParams: { adaptOrder: ManualAdaptOrder; vin: string }) => {
        await apiCall(requestParams)
            .unwrap()
            .then(() => {
                onHide();
                Notification.success(<FormattedMessage
                    id={isNew ? 'intl-msg:adaptOrder.create.successMsg' : 'intl-msg:adaptOrder.edit.successMsg'}/>);
            })
            .catch((error) => {
                console.error(error);
                Notification.error(
                    <FormattedMessage id='intl-msg:adaptOrder.create.errorMsg'
                                      values={{ error: error?.data?.message }}/>);
            });
    };

    const onSubmit = data => {

        const {deliverableId, deliverableVersion, udfData} = data
        const requestParams: { adaptOrder: ManualAdaptOrder; vin: string } = {
            vin,
            adaptOrder: {
                deliverableId: deliverableId,
                deliverableVersion: deliverableVersion,
                udfData: udfDataToArr(udfData)
            }
        };
        handleRequest(requestParams);
    };

    const title = <FormattedMessage id={isNew ? 'intl-msg:adaptOrder.create.form' : 'intl-msg:adaptOrder.edit.form'}/>;

    const body = (<div>
        <form onSubmit={handleSubmit(onSubmit)}>

            <Controller
                name="deliverableId"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Controller
                        name="deliverableVersion"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange: onChangeVersion, value: valueVersion }, fieldState: { error: errorVersion } }) => (
                            <DeliverableIdVersionSelect
                                deliverableId={value}
                                deliverableVersion={valueVersion}
                                onDeliverableIdChange={onChange}
                                onDeliverableVersionChange={onChangeVersion}
                                deliverableVersionError={!!errorVersion}
                                deliverableIdError={!!error}
                                readOnly={!isNew}
                            />
                        )}
                    />
                )}
            />
            <div className={'display-flex gap-20 margin-top-20'}>
                <div className='width-50pct'>
                    <Controller
                        control={control}
                        name='udfData.vehicleManufacturerEOLProgInfoCode'
                        rules={{ required: true }}
                        render={({ field : {onChange, value}, fieldState:{error}}) =>
                            <InputOTA
                                title={<FormattedMessage id='intl-msg:adaptOrder.udfDataEOLInfoCode'/>}
                                type='text'
                                className='form-control'
                                value={value}
                                placeholder={'Enter EOL program info code'}
                                onChange={onChange}
                                hasError={!!error}
                            />                    }
                    />
                </div>
                <div className='width-50pct'>
                    <Controller
                        control={control}
                        name='udfData.integrityValidationDataExpectedValueIVDMD'
                        rules={{ required: true }}
                        render={({ field : {onChange, value}, fieldState:{error}}) =>
                            <InputOTA
                                title={<FormattedMessage id='intl-msg:adaptOrder.udfDataMasterIVD'/>}
                                type='text'
                                className='form-control'
                                value={value}
                                placeholder={'Enter master IVD'}
                                onChange={onChange}
                                hasError={!!error}
                            />
                    }
                    />
                </div>
            </div>
            <div className={'display-flex justify-content-end margin-top-20'}>
                <button type={'submit'} className={classNames('btn btn-primary')} onClick={()=>{}}>
                    <FormattedMessage id='intl-msg:save'/>
                </button>
            </div>
        </form>
    </div>);

    return (<Dialog className={'device-adapt-order-dialog'}
                    show={true}
                    showCloseButton={true}
                    onHide={onHide}
                    title={title}
                    body={body}
                    footer={<></>}
                    useOverflow
    />)
}

export default AdaptOrderFromDialog;
