import classNames from 'classnames';

import PropTypes from 'prop-types';
import React from 'react';

export const YesOrNo = ({ field }) => {
    const classes = field ? 'rioglyph-ok text-color-highlight' : 'rioglyph-minus text-color-light';
    return (
        <span className={classNames('rioglyph text-size-16 align-middle', classes)}/>
    );
};

export default YesOrNo;

YesOrNo.propTypes = {
    // props
    field: PropTypes.bool,
};
