export const FETCH_DEVICE_ACTION_COUNT = 'FETCH_DEVICE_ACTION_COUNT';
export const FETCH_DEVICE_ACTIONS = 'FETCH_DEVICE_ACTIONS';
export const FETCH_DEVICE_ACTION = 'FETCH_DEVICE_ACTION';
export const CREATE_DEVICE_ACTION = 'CREATE_DEVICE_ACTION';
export const UPDATE_DEVICE_ACTION = 'UPDATE_DEVICE_ACTION';
export const DELETE_DEVICE_ACTION = 'DELETE_DEVICE_ACTION';

export function fetchDeviceActionCount() {
    return {
        type: FETCH_DEVICE_ACTION_COUNT,
    };
}

export function fetchDeviceActions() {
    return {
        type: FETCH_DEVICE_ACTIONS,
    };
}

export function fetchDeviceAction({ serialNumber, silent = false }) {
    return {
        type: FETCH_DEVICE_ACTION,
        serialNumber,
        silent,
    };
}

export function createDeviceAction(deviceAction) {
    return {
        type: CREATE_DEVICE_ACTION,
        payload: deviceAction,
    };
}

export function updateDeviceAction(deviceAction) {
    return {
        type: UPDATE_DEVICE_ACTION,
        payload: deviceAction,
    };
}

export function deleteDeviceAction(deviceAction) {
    return {
        type: DELETE_DEVICE_ACTION,
        payload: deviceAction,
    };
}
