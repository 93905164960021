import { call, put, fork, select, takeLatest } from 'redux-saga/effects';

import { ENTITY_SERIAL_NUMBER } from '~/features/base/constants/entities';

import {
    FETCH_SERIAL_NUMBERS_OF_GROUP,
} from '~/features/groups/actions/groupActions';

import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';

import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';

import { getHTTP, doHandleErrorSaga } from '~/features/base/sagas/sagaUtil';

import { finishedAction, failedAction } from '~/features/higherorder/transforms/actionTransforms';
import { mergeEntities } from '~/features/higherorder/actions/entityActions';

export function* getGroupsURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/vehicle-group`;
}

export function* getControlDeviceURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/control-device`;
}

export function* doFetchSerialNumbersOfGroupSaga(action) {
    try {
        const url = yield call(getGroupsURL);
        const encodedName = encodeURIComponent(action.groupName);
        const response = yield call(getHTTP, `${url}/${encodedName}/serial-numbers`);
        yield put(mergeEntities(response.content, { entityName: ENTITY_SERIAL_NUMBER, groupName: action.groupName }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* fetchSerialNumbersOfGroupSaga() {
    yield takeLatest(FETCH_SERIAL_NUMBERS_OF_GROUP, doFetchSerialNumbersOfGroupSaga);
}


