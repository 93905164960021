import PropTypes from 'prop-types';

import React from 'react';

import { FormattedMessage } from 'react-intl';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';

export const FieldVersionNumberDependencies = ({ field, data }) => {

    function addMinVersion(softwareVersionRange) {
        const prefix = !softwareVersionRange.maxValue ? 'min ' : '';
        return <span>
            {softwareVersionRange.minValue ? prefix + toShortSemanticVersion(softwareVersionRange.minValue) : ''}
        </span>;
    }

    function addMaxVersion(softwareVersionRange) {
        const prefix = !softwareVersionRange.minValue ? 'max ' : '';
        return <span>
            {softwareVersionRange.maxValue ? prefix + toShortSemanticVersion(softwareVersionRange.maxValue) : ''}
        </span>;
    }

    return (
        <span>
            {
                field?.baseSoftwareVersionRange ?
                    <div>
                    <span>
                        <span className='text-bold'>
                            <FormattedMessage id='intl-msg:baseSwVersion'/>
                        </span>
                        <span className='tag tag-muted margin-left-15 text-size-14 text-bold'>
                            {addMinVersion(field.baseSoftwareVersionRange)}
                            {field.baseSoftwareVersionRange.minValue && field.baseSoftwareVersionRange.maxValue ?
                                <span className='margin-left-5 margin-right-5'>
                                    <FormattedMessage id='intl-msg:to'/>
                                </span>
                                : ''
                            }
                            {addMaxVersion(field.baseSoftwareVersionRange)}
                        </span>
                    </span>
                    </div>
                    : ''
            }
            {
                field?.mdmcAppVersionRange ?
                    <div>
                        <span>
                            <span className='text-bold'>
                                <FormattedMessage id='intl-msg:mdmcAppVersion'/>
                            </span>
                                <span className='tag tag-muted margin-left-15 text-size-14 text-bold'>
                                {addMinVersion(field.mdmcAppVersionRange)}
                                    {field.mdmcAppVersionRange.minValue && field.mdmcAppVersionRange.maxValue ?
                                        <span className='margin-left-5 margin-right-5'>
                                        <FormattedMessage id='intl-msg:to'/>
                                    </span>
                                        : ''
                                    }
                                    {addMaxVersion(field.mdmcAppVersionRange)}
                            </span>
                        </span>
                    </div>
                    : ''
            }
            {
                data?.dependencies?.deliverables?.length ?
                    data.dependencies.deliverables.map(deliverable =>
                        <div>
                        <span>
                            <span className='text-bold'>
                                {deliverable.deliverableId}
                            </span>
                                <span className='tag tag-muted margin-left-15 text-size-14 text-bold'>
                                {addMinVersion(deliverable.versionRange)}
                                    {deliverable.versionRange.minValue && deliverable.versionRange.maxValue ?
                                        <span className='margin-left-5 margin-right-5'>
                                        <FormattedMessage id='intl-msg:to'/>
                                    </span>
                                        : ''
                                    }
                                    {addMaxVersion(deliverable.versionRange)}
                            </span>
                        </span>
                        </div>
                    ) : ''
            }
        </span>
    );
};

export default FieldVersionNumberDependencies;

FieldVersionNumberDependencies.propTypes = {
    // props
    field: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.object,
    ]),
};
