import React from 'react';
import { FormattedMessage } from 'react-intl';

// display-flex
const classes = 'text-color-lighter text-size-12 text-uppercase white-space-nowrap align-items-center';

export const NoData = () => {
    return (
        <span className={classes}>
            <FormattedMessage id='intl-msg:noData'/>
        </span>
    );
};

export default NoData;
