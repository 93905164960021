import React from 'react';
import { FormattedMessage } from 'react-intl';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

export const Cm4gType = () => {
    return (
        <OverlayTrigger placement='top' overlay={
            <Tooltip id='tooltip' className='top-right'>
                CM4G
            </Tooltip>}>
            <span className={'label label-condensed label-primary'}>
                CM4G
            </span>
        </OverlayTrigger>
    );
};

export default Cm4gType;
