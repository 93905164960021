import { SET_SHOW_GROUP_TABLE_VIEW, UPDATE_GROUP_SEARCH_VALUE } from '~/features/groups/actions/groupViewActions';

const defaultState = {
    showTableView: true,
    searchValue: '',
};

export default function groupViewReducer(state = defaultState, action = {}) {
    let newState;
    switch (action.type) {
        case SET_SHOW_GROUP_TABLE_VIEW:
            newState = {
                ...state,
                showTableView: action.showTableView,
            };
            return newState;
        case UPDATE_GROUP_SEARCH_VALUE:
            newState = {
                ...state,
                searchValue: action.searchValue,
            };
            return newState;
        default:
            return state;
    }
}
