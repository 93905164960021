import keyBy from 'lodash/fp/keyBy';
import includes from 'lodash/fp/includes';
import omit from 'lodash/fp/omit';

import {
    MERGE_ENTITIES,
    MERGE_ENTITY,
    DELETE_ENTITY,
    RESET_ENTITY,
} from '~/features/higherorder/actions/entityActions';

const acceptedActions = [MERGE_ENTITIES, MERGE_ENTITY, DELETE_ENTITY, RESET_ENTITY];

const defaultState = {
};

const mergeEntity = (state, action, entityIdentifier) => {
    const newState = {
        ...state,
        [action.entity[entityIdentifier]]: action.entity,
    };
    return newState;
};
const mergeEntities = (state, action, entityIdentifier) => {
    const newState = {
        ...state,
        ...keyBy(entityIdentifier, action.entities),
    };
    return newState;
};
const deleteEntity = (state, action, entityIdentifier) => {
    const newState = {
        ...omit([action.entity[entityIdentifier]], state),
    };
    return newState;
};

export const entityMapReducer = (state = defaultState, action = {}, entityIdentifier) => {
    switch (action.type) {
        case MERGE_ENTITIES:
            return mergeEntities(state, action, entityIdentifier);
        case MERGE_ENTITY:
            return mergeEntity(state, action, entityIdentifier);
        case DELETE_ENTITY:
            return deleteEntity(state, action, entityIdentifier);
        case RESET_ENTITY:
            return null;
        default:
            return state;
    }
};

export const createEntityMapReducer = (entityName = 'entity', entityIdentifier) => {
    return (state = defaultState, action = {}) => {
        if (includes(action.type, acceptedActions)) {
            if (action.mergeProps.entityName === entityName) {
                return entityMapReducer(state, action, entityIdentifier);
            }
        }
        return state;
    };
};

export default createEntityMapReducer;
