import noop from 'lodash/fp/noop';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Pager from '@rio-cloud/rio-uikit/Pager';

export const PageNavigationComponent = ({ pageCount, activePage, loadNewPage }) => {
    return (
        <div
            className='  display-flex gap-15 justify-content-center text-center  padding-15 '>
            <Pager
                alignRight
                title={<FormattedMessage id='intl-msg:previous'/>}
                disabled={activePage === 1}
                variant={Pager.VARIANT_COMPACT}
                onClick={() => loadNewPage(Math.max(1, activePage - 1))}
            />
            <div className=' display-flex align-self-center '>
                <span className={'text-size-14 text-bold '} aria-hidden='true'>
                            {`${activePage} / ${pageCount}`}
                </span>
            </div>
            <Pager title={<FormattedMessage id='intl-msg:next'/>}
                   disabled={activePage === pageCount}
                   onClick={() => loadNewPage(Math.min(pageCount, activePage + 1))}
                   variant={Pager.VARIANT_COMPACT}
            />
        </div>
    );
};

export default PageNavigationComponent;

PageNavigationComponent.defaultProps = {
    pageCount: 1,
    activePage: 1,
    loadNewPage: noop,
};

PageNavigationComponent.propTypes = {
    className: PropTypes.string,
    pageCount: PropTypes.number,
    activePage: PropTypes.number,
    loadNewPage: PropTypes.func,
};
