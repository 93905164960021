export const MERGE_ENTITIES = '@@data/MERGE_ENTITIES';
export const MERGE_ENTITY = '@@data/MERGE_ENTITY';
export const DELETE_ENTITY = '@@data/DELETE_ENTITY';
export const RESET_ENTITY = '@@data/RESET_ENTITY';

export const mergeEntities = (entities, mergeProps) => {
    return {
        type: MERGE_ENTITIES,
        entities,
        mergeProps,
    };
};

export const mergeEntity = (entity, mergeProps) => {
    return {
        type: MERGE_ENTITY,
        entity,
        mergeProps,
    };
};

export const deleteEntity = (entity, mergeProps) => {
    return {
        type: DELETE_ENTITY,
        entity,
        mergeProps,
    };
};

export const resetEntity = (entity, mergeProps) => {
    return {
        type: RESET_ENTITY,
        entity,
        mergeProps
    };
};
