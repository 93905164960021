import includes from 'lodash/fp/includes';

import { START_LOADING, END_LOADING } from '~/features/higherorder/actions/loadingActions';
import { mapReducer } from '~/features/higherorder/reducers/mapReducer';

const acceptedActions = [START_LOADING, END_LOADING];

const defaultState = true;
const mapDefaultState = {};

const startLoading = (action) => {
    return true;
};
const endLoading = (action) => {
    return false;
};

export const loadingReducer = (state, action = {}) => {
    switch (action.type) {
        case START_LOADING:
            return startLoading(action);
        case END_LOADING:
            return endLoading(action);
        default:
            return state;
    }
};

export const createLoadingReducer = (entityName) => {
    return (state = defaultState, action = {}) => {
        if (includes(action.type, acceptedActions)) {
            if (action.mergeProps.entityName === entityName) {
                return loadingReducer(state, action);
            }
        }
        return state;
    };
};

export const createMappedLoadingReducer = (entityName, scopeProp = 'scope') => {
    return (state = mapDefaultState, action = {}) => {
        if (includes(action.type, acceptedActions)) {
            if (action.mergeProps.entityName === entityName) {
                return mapReducer(state, action, scopeProp,
                    createLoadingReducer(entityName));
            }
        }
        return state;
    };
};

export default createLoadingReducer;
