import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';


import {
    READ_ONLY,
    sysParamGroups,
    createSysParamGroupOptions,
} from '~/features/sysParams/constants/sysParamGroups';

import {
    STRING,
    NUMERIC,
    ENUMERATION,
    BYTE_ARRAY,
    createSysParamTypesOptions,
} from '~/features/sysParams/constants/sysParamTypes';

import SysParamDefinitionStringEditorForm
    from '~/features/sysParams/components/SysParamDefinitionStringEditorForm';
import SysParamDefinitionNumericEditorForm
    from '~/features/sysParams/components/SysParamDefinitionNumericEditorForm';
import SysParamDefinitionEnumerationEditorForm
    from '~/features/sysParams/components/SysParamDefinitionEnumerationEditorForm';
import SysParamDefinitionByteArrayEditorForm
    from '~/features/sysParams/components/SysParamDefinitionByteArrayEditorForm';
import { selectionByValue } from '~/features/base/utils/selectUtils';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import NumberInput from '@rio-cloud/rio-uikit/lib/es/NumberInput';

/**
 * System parameter definition editor form.
 */
export class SysParamDefinitionEditorForm extends PureComponent {
    constructor(props) {
        super(props);
        this.onSysParamGroupSelect = this.onSysParamGroupSelect.bind(this);
        this.onAccessKeyChange = this.onAccessKeyChange.bind(this);
        this.onSoftwareVersionMajorChange = this.onSoftwareVersionMajorChange.bind(this);
        this.onSoftwareVersionMinorChange = this.onSoftwareVersionMinorChange.bind(this);
        this.onSoftwareVersionPatchChange = this.onSoftwareVersionPatchChange.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onDescriptionChange = this.onDescriptionChange.bind(this);
        this.onUnitChange = this.onUnitChange.bind(this);
        this.onTypeSelect = this.onTypeSelect.bind(this);
    }
    onSysParamGroupSelect(sysParamGroup) {
        const value = sysParamGroup.value;
        const accessKey = sysParamGroups[value].min;
        const hasDefault = sysParamGroups[value].hasDefault;
        this.props.changeSysParamDefinitionEditor({
            sysParamGroup: value,
            accessKey,
            hasDefault,
        });
    }
    onAccessKeyChange(value) {
        this.props.changeSysParamDefinitionEditor({
            accessKey: value,
        });
    }
    onSoftwareVersionMajorChange(value) {
        this.props.changeSysParamDefinitionEditor({
            baseSoftwareVersion: {
                ...this.props.model.baseSoftwareVersion,
                major: value,
            },
        });
    }
    onSoftwareVersionMinorChange(value) {
        this.props.changeSysParamDefinitionEditor({
            baseSoftwareVersion: {
                ...this.props.model.baseSoftwareVersion,
                minor: value,
            },
        });
    }
    onSoftwareVersionPatchChange(value) {
        this.props.changeSysParamDefinitionEditor({
            baseSoftwareVersion: {
                ...this.props.model.baseSoftwareVersion,
                patch: value,
            },
        });
    }
    onNameChange(event) {
        this.props.changeSysParamDefinitionEditor({
            name: event.target.value,
        });
    }
    onDescriptionChange(event) {
        this.props.changeSysParamDefinitionEditor({
            description: event.target.value,
        });
    }
    onUnitChange(event) {
        this.props.changeSysParamDefinitionEditor({
            unit: event.target.value,
        });
    }
    onTypeSelect(type) {
        this.props.changeSysParamDefinitionEditor({
            type: type.value,
        });
    }

    render() {
        const { model } = this.props;
        const sysParamGroup = this.renderSysParamGroup(model);
        const accessKey = this.renderAccessKey(model);
        const baseswVersion = this.renderBaseSoftwareVersion(model);
        const type = this.renderType(model);
        const typeForm = this.renderTypeForm(model);
        return (
            <form>
                {sysParamGroup}
                {accessKey}
                {baseswVersion}
                <div className='form-group form-group-name'>
                    <label className='control-label'>
                        <FormattedMessage id='intl-msg:name' />
                    </label>
                    <FormattedMessage id='intl-msg:name.prompt'>
                        {placeHolder =>
                            <input type='text' className='form-control'
                                value={model.name}
                                placeholder={placeHolder}
                                onChange={this.onNameChange} />
                        }
                    </FormattedMessage>
                </div>
                <div className='form-group form-group-description'>
                    <label className='control-label'>
                        <FormattedMessage id='intl-msg:description' />
                    </label>
                    <FormattedMessage id='intl-msg:descriptionOptional'>
                        {placeHolder =>
                            <input type='text' className='form-control'
                                value={model.description}
                                placeholder={placeHolder}
                                onChange={this.onDescriptionChange} />
                        }
                    </FormattedMessage>
                </div>
                <div className='form-group form-group-unit'>
                    <label className='control-label'>
                        <FormattedMessage id='intl-msg:unit' />
                    </label>
                    <FormattedMessage id='intl-msg:unitOptional'>
                        {placeHolder =>
                            <input type='text' className='form-control'
                                value={model.unit}
                                placeholder={placeHolder}
                                onChange={this.onUnitChange} />
                        }
                    </FormattedMessage>
                </div>
                {type}
                {typeForm}
            </form>
        );
    }
    renderSysParamGroup(model) {
        if (model.isNew) {
            const sysParamGroup = model.sysParamGroup || READ_ONLY;
            const optionsWithSelection = selectionByValue(sysParamGroup)(createSysParamGroupOptions());
            return (
                <div className='form-group form-group-sys-param-group'>
                    <label className='control-label'>
                        <FormattedMessage id='intl-msg:sysParamGroup' />
                    </label>
                    <div>
                        <Select options={optionsWithSelection}
                            onChange={this.onSysParamGroupSelect} />
                    </div>
                </div>
            );
        }
    }
    renderAccessKey(model) {
        if (model.isNew) {
            const sysParamGroup = model.sysParamGroup || READ_ONLY;
            return (
                <div className='form-group form-group-access-key'>
                    <label className='control-label'>
                        <FormattedMessage id='intl-msg:accessKey' />
                    </label>
                    <NumberInput
                        min={sysParamGroups[sysParamGroup].min}
                        max={sysParamGroups[sysParamGroup].max}
                        value={model.accessKey}
                        onValueChanged={this.onAccessKeyChange}/>
                </div>
            );
        }
    }
    renderBaseSoftwareVersion(model) {
        if (model.showBaseSwVersionForm) {
            return (
                <div className='form-group form-group-software-version'>
                    <label className='control-label'>
                        <FormattedMessage id='intl-msg:baseSoftwareVersion' />
                    </label>
                    <div className='row'>
                        <div className='col-xs-4 col-sm-3 col-md-2'>
                            <NumberInput min={0} value={model.baseSoftwareVersion.major}
                                onValueChanged={this.onSoftwareVersionMajorChange}/>
                        </div>
                        <div className='col-xs-4 col-sm-3 col-md-2'>
                            <NumberInput min={0} value={model.baseSoftwareVersion.minor}
                                onValueChanged={this.onSoftwareVersionMinorChange}/>
                        </div>
                        <div className='col-xs-4 col-sm-3 col-md-2'>
                            <NumberInput min={0} value={model.baseSoftwareVersion.patch}
                                onValueChanged={this.onSoftwareVersionPatchChange}/>
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderType(model) {
        const type = model.type || STRING;
        const optionsWithSelection = selectionByValue(type)(createSysParamTypesOptions());
        return <div className='form-group form-group-type'>
            <label className='control-label'>
                <FormattedMessage id='intl-msg:type' />
            </label>
            <div>
                <Select options={optionsWithSelection}
                    onChange={this.onTypeSelect} />
            </div>
        </div>;
    }

    renderTypeForm(model) {
        switch (model.type) {
            case NUMERIC:
                return this.renderNumericForm(model);
            case ENUMERATION:
                return this.renderEnumerationForm(model);
            case BYTE_ARRAY:
                return this.renderByteArrayForm(model);
            case STRING:
            default:
                return this.renderStringForm(model);
        }
    }
    renderStringForm(model) {
        return <SysParamDefinitionStringEditorForm model={model}
            changeSysParamDefinitionEditor={
                payload => this.props.changeSysParamDefinitionEditor(payload)
            } />;
    }
    renderNumericForm(model) {
        return <SysParamDefinitionNumericEditorForm model={model}
            changeSysParamDefinitionEditor={
                payload => this.props.changeSysParamDefinitionEditor(payload)
            } />;
    }
    renderEnumerationForm(model) {
        return <SysParamDefinitionEnumerationEditorForm model={model}
            changeSysParamDefinitionEditor={
                payload => this.props.changeSysParamDefinitionEditor(payload)
            } />;
    }
    renderByteArrayForm(model) {
        return <SysParamDefinitionByteArrayEditorForm model={model}
            changeSysParamDefinitionEditor={
                payload => this.props.changeSysParamDefinitionEditor(payload)
            } />;
    }
}

export default SysParamDefinitionEditorForm;

SysParamDefinitionEditorForm.defaultProps = {
    model: {},
    changeSysParamDefinitionEditor: noop,
};

SysParamDefinitionEditorForm.propTypes = {
    // props
    model: PropTypes.object,
    // functions
    changeSysParamDefinitionEditor: PropTypes.func,
};
