import find from 'lodash/fp/find';
import noop from 'lodash/fp/noop';
import size from 'lodash/fp/size';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { distroSysParamColumnDescriptors } from '~/features/sysParams/constants/sysParamColumnDescriptors';

import ListTable from '~/features/base/components/table/ListTable';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';
import uid from '~/features/base/utils/uid';

// TODO Refactor to work on base software
// TODO Render only if base software is known
/**
 * Table component for system parameter definitions
 */
export class DistroSysParamsTable extends PureComponent {
    constructor(props) {
        super(props);
        this.actionsColumn = {
            id: 'actions',
            field: 'accessKey',
            className: 'table-action',
            format: field => {
                const items = [];
                const { canUpdateSysParamDefinitions, canDeleteSysParamDefinitions } = this.props;
                if (canUpdateSysParamDefinitions) {
                    items.push({
                        value: (
                            <div key={'editBtn'} className='' onClick={this.onEditButtonClick} data-key={field}>
                                <span className='rioglyph rioglyph-pencil margin-right-10'></span>
                                <span><FormattedMessage id='intl-msg:editSysParamDefinition'/></span>
                            </div>
                        ),
                    });
                }
                if (canDeleteSysParamDefinitions) {
                    items.push({
                        value: (
                            <div key={'deleteBtn'} onClick={this.onDeleteButtonClick} data-key={field}>
                                <span className='rioglyph rioglyph-trash margin-right-10'></span>
                                <span><FormattedMessage id='intl-msg:deleteSysParamDefinition'/></span>
                            </div>
                        ),
                    });
                }
                if (size(items)) {
                    return (
                        <ButtonDropdown
                            title={<span className='rioglyph rioglyph-option-vertical' />}
                            className='pull-right'
                            bsStyle='link'
                            iconOnly
                            items={items}/>
                    );
                }
            },
            formatLabel: () => '',
        };
    }

    onEditButtonClick = (event) => {
        const key = parseInt(event.currentTarget.getAttribute('data-key'), 10);
        const sysParam = find(item => item.accessKey === key, this.props.sysParams);
        this.props.onEditSysParamDefinition(sysParam);
    }

    onDeleteButtonClick = (event) => {
        const key = parseInt(event.currentTarget.getAttribute('data-key'), 10);
        const sysParam = find(item => item.accessKey === key, this.props.sysParams);
        this.props.onDeleteSysParamDefinition(sysParam);
    }

    render() {
        const { sysParams } = this.props;
        const columns = [...distroSysParamColumnDescriptors, this.actionsColumn];
        return [
            <ListTable key={uid()} items={sysParams}
                itemKey='accessKey'
                columnDescriptors={columns}/>,
        ];
    }
}

export default DistroSysParamsTable;

DistroSysParamsTable.defaultProps = {
    // props
    canUpdateSysParamDefinitions: false,
    canDeleteSysParamDefinitions: false,
    isBaseSwEditable: false,
    sysParams: [],
    // functions
    onEditSysParamDefinition: noop,
    onDeleteSysParamDefinition: noop,
};

DistroSysParamsTable.propTypes = {
    // props
    canUpdateSysParamDefinitions: PropTypes.bool,
    canDeleteSysParamDefinitions: PropTypes.bool,
    isBaseSwEditable: PropTypes.bool,
    sysParams: PropTypes.array,
    // functions
    onEditSysParamDefinition: PropTypes.func,
    onDeleteSysParamDefinition: PropTypes.func,
};
