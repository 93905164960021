export const SET_DEVICE_COUNTS_BY_COUNTRIES = 'SET_DEVICE_COUNTS_BY_COUNTRIES';
export const RESET_DEVICE_COUNTS_BY_COUNTRIES = 'RESET_DEVICE_COUNTS_BY_COUNTRIES';

export const FETCH_DEVICE_COUNTS_BY_COUNTRIES = 'FETCH_DEVICE_COUNTS_BY_COUNTRIES';

export const SET_DEVICE_COUNTS_BY_VEHICLES = 'SET_DEVICE_COUNTS_BY_VEHICLES';
export const RESET_DEVICE_COUNTS_BY_VEHICLES = 'RESET_DEVICE_COUNTS_BY_VEHICLES';

export const FETCH_DEVICE_COUNTS_BY_VEHICLES = 'FETCH_DEVICE_COUNTS_BY_VEHICLES';

export const fetchDeviceCountsByCountries = () => {
    return {
        type: FETCH_DEVICE_COUNTS_BY_COUNTRIES,
    };
}

export const fetchDeviceCountsByVehicles = (payload) => {
    return {
        type: FETCH_DEVICE_COUNTS_BY_VEHICLES,
        payload
    };
}
