import React from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';
import DeviceOffline from '~/features/devices/components/DeviceOffline';
import FieldOrNoData from '~/features/base/components/FieldOrNoData';
import DeviceHealthState from '~/features/devices/components/DeviceHealthState';
import { copyToClipboard } from '~/features/apps/utils/appsUtils';
import VehicleVariant from '~/features/vehicles/components/VehicleVariant';
import GroupChipList from '~/features/groups/components/GroupChipList';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import classNames from 'classnames';
import { env } from '~/env';

const { networkCodes, networkCodesWhiteList } = env.runtimeConfig;

export const VehicleDetailsRowDescriptors = [{
    id: 'vin',
    field: 'vin',
    label: 'intl-msg:VIN',
    className: 'hidden-xs hidden-ls hidden-sm',
    format: field => [<FieldOrNoData field={field}/>,
        field && <span className='rioglyph rioglyph-duplicate text-color-dark text-size-15 padding-left-5'
                       onClick={(event) => {
                           event.stopPropagation();
                           copyToClipboard(field, true);
                       }}></span>,
    ],
}, {
    id: 'vehicleVariant',
    field: 'vehicleVariant',
    label: 'intl-msg:vehicleVariant',
    format: (field, item) => (
        <span id={`vehicleVariant-${item.vin}`} className='white-space-nowrap'>
            <span className='margin-right-5'>
                <VehicleVariant vehicleVariant={item.vehicleVariant}/>
            </span>
        </span>
    ),
}, {
    id: 'deviceHealthState',
    field: 'status',
    label: 'intl-msg:deviceHealthState',
    format: (field, item) => [
        <DeviceHealthState controlDevice={item}/>,
    ],
},

    {
        field: 'vehicleGroupNames',
        label: 'intl-msg:groups',
        format: field => <GroupChipList groups={field}/>,
    }, {
        field: 'lastNetworkCodeReported',
        label: 'intl-msg:deviceNetworkLocation',
        format: field => {
            let lastNetworkReportedCountry;
            let countryCode;
            if (field) {
                countryCode = field.split(' ')[0];
                lastNetworkReportedCountry = networkCodes[countryCode] || '';
            }
            return (
                <span>
                {
                    lastNetworkReportedCountry ? lastNetworkReportedCountry
                        : <FormattedMessage id='intl-msg:networkCountryCode' values={{ countryCode: countryCode }}/>
                }
                    <OverlayTrigger placement='top' overlay={
                        <Tooltip id='tooltip' className='top-right'>
                            {
                                countryCode && networkCodesWhiteList.includes(parseInt(countryCode, 0)) ?
                                    <FormattedMessage id='intl-msg:deviceNetworkCanUseServices'/>
                                    :
                                    <FormattedMessage id='intl-msg:report.reason.disallowedNetwork'/>
                            }
                        </Tooltip>}>
                            <span className={classNames('label label-condensed margin-right-5')}>
                            {
                                countryCode && networkCodesWhiteList.includes(parseInt(countryCode, 0)) ?
                                    <span
                                        className='text-success rioglyph rioglyph-ok-sign margin-right-10 text-size-16'/>
                                    :
                                    <span
                                        className='text-danger rioglyph rioglyph-error-sign margin-right-10 text-size-16'/>
                            }
                            </span>
                        </OverlayTrigger>
            </span>
            );
        },
    },
    {
        id: 'lastCheckForUpdateTimestamp',
        field: 'lastCheckForUpdateTimestamp',
        label: 'intl-msg:lastCheckForUpdateTimestamp',
        format: field => {
            return (
                <span className='white-space-nowrap'>
                <FieldOrNoData field={field ? <FormattedTime value={field} year='numeric'
                                                             month='2-digit' day='2-digit'/> : null}/>
                <DeviceOffline timestamp={field}/>
            </span>
            );
        },
        sortable: true,
    }, {
        id: 'registrationTimestamp',
        field: 'registrationTimestamp',
        label: 'intl-msg:registrationTimestamp',
        className: 'hidden-xs hidden-ls hidden-sm hidden-md',
        format: field => {
            return (
                <FieldOrNoData field={field ? <FormattedTime value={field} year='numeric'
                                                             month='2-digit' day='2-digit'/> : null}/>
            );
        },
        hidden: true,
    }];
