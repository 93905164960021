import React, { PureComponent } from 'react';
import SortDirection from '@rio-cloud/rio-uikit/lib/es/SortDirection';
import SortArrows from '@rio-cloud/rio-uikit/lib/es/SortArrows';
import uid from '~/features/base/utils/uid';
import { sortByProperty } from '@rio-cloud/rio-uikit/lib/es/SortUtils';
import TableSearch from '@rio-cloud/rio-uikit/lib/es/TableSearch';

const defaultColumnOrder = [
    'name',
    'costs',
    'bytes',
    'requests',
];
const defaultColumnsDetails = {
    name: {
        width: 250,
        defaultWidth: 250,
        maxWidth: 250,
    },
    costs: {
        width: 60,
        defaultWidth: 60,
        maxWidth: 60,
    },
    bytes: {
        width: 60,
        defaultWidth: 60,
        maxWidth: 60,
    },
    requests: {
        width: 60,
        defaultWidth: 60,
        maxWidth: 60,
    },
};

export class ReportsBillingLegendTableCosts extends PureComponent {
    constructor(props) {
        super(props);
        this.name = uid();

        this.state = {
            searchValue: '',
            sortBy: defaultColumnOrder[0],
            sortDir: SortDirection.ASCENDING,
            showTableSettingsDialog: false,
            columnOrder: defaultColumnOrder,
            hiddenColumns: [],
            columnsDetails: defaultColumnsDetails,
            date: Date.now(),
        };
        this.onSortChange = this.onSortChange.bind(this);
    }

    getSortDir(sortBy, previousSortBy) {
        if (sortBy === previousSortBy) {
            const dir = this.state.sortDir === SortDirection.ASCENDING ? SortDirection.DESCENDING
                : SortDirection.ASCENDING;
            return dir;
        }
        return SortDirection.ASCENDING;
    }

    onSortChange(event) {
        const sortBy = event.currentTarget.dataset.sortby;
        const sortDir = this.getSortDir(sortBy, this.state.sortBy);
        this.setState({
            ...this.state,
            sortBy,
            sortDir,
        });
    }

    renderTableHead(column, label, columnDetails, sortBy, sortDir) {
        const tableHeadClassNames = 'user-select-none sort-column';
        return (
            <th
                key={column}
                className={tableHeadClassNames}
                onClick={this.onSortChange}
                width={columnDetails.width}
                data-field={column}
                data-sortby={column}
                title={label}>
                <span className={column === 'name' ? '' : 'float-right'}>
                    {sortBy === column
                        ? <SortArrows direction={sortDir}/>
                        : <SortArrows/>
                    }
                    <span>{label}</span>
                </span>
            </th>
        );
    }

    onMouseEnter = (e, name) => {
        const pieSectors = document.querySelectorAll('g.recharts-pie-sector');
        // eslint-disable-next-line no-return-assign
        pieSectors.forEach((el) => el.style.opacity = '.1');
        const sectorSelector = `g.recharts-pie-sector path[name='${name}']`;
        const pieSectorPath = document.querySelector(sectorSelector);
        const gSector = pieSectorPath.closest('g');
        gSector.style.opacity = '1';
        const pieLabels = document.querySelectorAll('g.recharts-pie-labels g');
        // eslint-disable-next-line no-return-assign
        pieLabels.forEach((el) => el.style.opacity = '0');
        const labelSelector = `g.recharts-pie-labels text[name='${name}']`;
        const labelPath = document.querySelector(labelSelector);
        const gLabel = labelPath.closest('g');
        gLabel.style.opacity = '1';
        gLabel.style.color = 'black';
    };
    onMouseLeave = () => {
        const pieSectors = document.querySelectorAll('g.recharts-pie-sector');
        // eslint-disable-next-line no-return-assign
        pieSectors.forEach((el) => el.style.opacity = '1');
        const pieLabels = document.querySelectorAll('g.recharts-pie-labels g');
        // eslint-disable-next-line no-return-assign
        pieLabels.forEach((el) => el.style.opacity = '1');
    };

    render() {
        const {
            columnOrder,
            sortBy,
            sortDir,
            columnsDetails,
        } = this.state;
        let { data } = this.props;
        const {
            onSearchValueChange,
            searchValue,
            dialogParams,
        } = this.props;
        const tableClassNames = 'table table-layout-fixed table-column-overflow-hidden table-bordered table-sticky table-head-filled';
        const columns = columnOrder;
        const sortedChartData = sortBy
            ? sortByProperty(data, sortBy, sortDir)
            : data;

        const columnLabels = {
            ['name']: dialogParams.deliverableType.toUpperCase(),
            ['costs']: '€',
            ['bytes']: 'Data Volume (GB)',
            ['requests']: '#',
        };
        return (
            <div className={'bg-white'}>
                <div className='table-toolbar-group-right'>
                    <div className='table-toolbar-column'>
                        <TableSearch
                            value={searchValue}
                            onChange={onSearchValueChange}
                            placeholder={`Search ${dialogParams.deliverableType} ...`}
                        />
                    </div>
                </div>
                <div className='border height-500 overflow-y-scroll'>
                    <table className={tableClassNames}>
                        <thead>
                        <tr>
                            {columns.map(column =>
                                this.renderTableHead(column, columnLabels[column], columnsDetails[column],
                                    sortBy, sortDir),
                            )}
                        </tr>
                        </thead>
                        <tbody>
                        {sortedChartData.map((row, index) => (
                            <tr key={index} onMouseEnter={(e) => this.onMouseEnter(e, row.name)}
                                onMouseLeave={this.onMouseLeave}>
                                {columns.map(col => (
                                    col === 'name'
                                        ? <td key={col} data-field={columnLabels[col]} className={'text-left'}>
                                            <span>{row[col]}</span>
                                            <span className="label label-primary label-condensed margin-left-10">{row['version']}</span>
                                        </td>
                                        : <td key={col} data-field={columnLabels[col]} className={'text-right'}>
                                            {
                                                col === 'requests'
                                                    ? <span>{row[col].toLocaleString()}</span>
                                                    : <span>{row[col].toLocaleString(undefined,
                                                        { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                            }
                                        </td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
