import { Bundle } from '~/api/models/Bundle';
import { SemanticVersion } from '~/api/models';

export class LastReportedEssentialImage implements Bundle {
    id: string;
    version?: SemanticVersion;

    private constructor(id: string, version?: SemanticVersion) {
        this.id = id;
        this.version = version;

    }

    static create(id?: string, version?: SemanticVersion): LastReportedEssentialImage | undefined {
        if (id === undefined) {
            return undefined;
        }
        return new LastReportedEssentialImage(id, version);
    }

}
