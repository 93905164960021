import { SHOW_MODAL, HIDE_MODAL } from '~/features/base/actions/ui/modalsActions';

const defaultState = {
    modalType: undefined,
    modalProps: {},
};

/**
 * Reducer for system parameter definition information
 * @param {object} state The current state
 * @param {object} action The action to be processed
 * @returns {object} The new state
 */
export default function modalsReducer(state = defaultState, action = {}) {
    let newState;
    switch (action.type) {
        case SHOW_MODAL:
            newState = {
                modalType: action.payload.modalType,
                modalProps: action.payload.modalProps,
            };
            return newState;
        case HIDE_MODAL:
            return defaultState;
        default:
            return state;
    }
}
