import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ALL } from '~/features/base/constants/filterOptions';
import { selectionByValue } from '~/features/base/utils/selectUtils';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';

export class ContextSelectFormItem extends Component {
    onChange = (item) => {
        const context = item.id === ALL ? undefined : item.value;
        this.props.onChange({
            context,
        });
    }

    render() {
        const { horizontal, label, options, value } = this.props;
        const optionsWithSelection = selectionByValue(value)(options);
        return (
            horizontal ?
                <div className='row form-group'>
                    <div className='col-md-4 padding-top-5 text-right'>
                        <label className='control-label'>
                            <FormattedMessage id={label}/>
                        </label>
                    </div>
                    <div className='col-md-8 padding-0'>
                        <Select options={optionsWithSelection}
                                onChange={this.onChange}/>
                    </div>
                </div>
                :
                <div className='form-group'>
                    <label className='control-label'>
                        <FormattedMessage id={label}/>
                    </label>
                    <Select options={optionsWithSelection}
                            onChange={this.onChange}/>
                </div>
        );
    }
}

export default ContextSelectFormItem;

ContextSelectFormItem.defaultProps = {
    // props
    label: 'intl-msg:context',
    horizontal: false,
    options: [],
    value: ALL,
    // functions
    onChange: noop,
};

ContextSelectFormItem.propTypes = {
    // props
    label: PropTypes.string,
    horizontal: PropTypes.bool,
    options: PropTypes.array,
    value: PropTypes.string,
    // functions
    onChange: PropTypes.func,
};
