import values from 'lodash/fp/values';

import React from 'react';
import { FormattedMessage } from 'react-intl';

export const EMERGENCY = 0;
export const ALERT = 1;
export const CRITICAL = 2;
export const ERROR = 3;
export const WARNING = 4;
export const NOTICE = 5;
export const INFO = 6;
export const DEBUG = 7;

export const logLevelsEnum = [EMERGENCY, ALERT, CRITICAL, ERROR, WARNING, NOTICE, INFO, DEBUG];

export const logLevelOptions = {
    [EMERGENCY]: {
        id: `${EMERGENCY}`,
        label: <FormattedMessage id='intl-msg:logLevel.emergency'/>,
        value: EMERGENCY,
    },
    [ALERT]: {
        id: `${ALERT}`,
        label: <FormattedMessage id='intl-msg:logLevel.alert'/>,
        value: ALERT,
    },
    [CRITICAL]: {
        id: `${CRITICAL}`,
        label: <FormattedMessage id='intl-msg:logLevel.critical'/>,
        value: CRITICAL,
    },
    [ERROR]: {
        id: `${ERROR}`,
        label: <FormattedMessage id='intl-msg:logLevel.error'/>,
        value: ERROR,
    },
    [WARNING]: {
        id: `${WARNING}`,
        label: <FormattedMessage id='intl-msg:logLevel.warning'/>,
        value: WARNING,
    },
    [NOTICE]: {
        id: `${NOTICE}`,
        label: <FormattedMessage id='intl-msg:logLevel.notice'/>,
        value: NOTICE,
    },
    [INFO]: {
        id: `${INFO}`,
        label: <FormattedMessage id='intl-msg:logLevel.info'/>,
        value: INFO,
    },
    [DEBUG]: {
        id: `${DEBUG}`,
        label: <FormattedMessage id='intl-msg:logLevel.debug'/>,
        value: DEBUG,
    },
};

export const createLogLevelFilterOptions = () => values(logLevelOptions);
