import Sidebar from '@rio-cloud/rio-uikit/lib/es/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import React, { useRef } from 'react';
import { getSelectedVehicle } from '~/features/vehicles/vehiclesSlice';
import { setVehicleSelected } from '~/features/vehicles/vehiclesSlice';
import { useFetchVehicleDetailsQuery } from '~/api/vehicles/VehicleManagement.api';
import VehicleSummary from '~/features/vehicles/components/details/VehicleSummary';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';
import useOutsideClick from '~/features/base/hooks/useOutsideClick';
import VehicleDetailsTabs from '~/features/vehicles/components/details/VehicleDetailsTabs';

const VehicleSideBarContainer = () => {
    const dispatch = useDispatch();
    const ref = useRef(null);
    const selectedVehicle = useSelector(getSelectedVehicle);
    const { data: vehicleDetails, isFetching } = useFetchVehicleDetailsQuery(
        selectedVehicle, { skip: !selectedVehicle });

    const handleOnCloseSidebar = () => {
        dispatch(setVehicleSelected(undefined));
    };

    const handleClickOutside = (event: React.MouseEvent<HTMLElement>) => {
        const selectedRow = (event.target as HTMLElement).closest('.tableRow');
        if (!selectedRow) {
            handleOnCloseSidebar();
        }
    };
    useOutsideClick(ref, handleClickOutside);

    return (<div ref={ref}><Sidebar
        fly
        width={1000}
        closed={!selectedVehicle}
        onClose={handleOnCloseSidebar}

    >
        {isFetching ? <Spinner/>
            : vehicleDetails &&
            <DefaultWhiteColumn>
                <VehicleSummary vehicleDetails={vehicleDetails}/>
                <VehicleDetailsTabs vehicleDetails={vehicleDetails}/>
            </DefaultWhiteColumn>
        }
    </Sidebar></div>);
};

export default VehicleSideBarContainer;
