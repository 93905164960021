/**
 * Action type for showing the system parameter system value editor
 * @type {string}
 */
export const SHOW_SYS_PARAM_SYSTEM_VALUE_EDITOR = 'SHOW_SYS_PARAM_SYSTEM_VALUE_EDITOR';

/**
 * Action type for showing the delete system parameter system value dialog
 * @type {string}
 */
export const SHOW_DELETE_SYS_PARAM_SYSTEM_VALUE_DIALOG = 'SHOW_DELETE_SYS_PARAM_SYSTEM_VALUE_DIALOG';

/**
 * Action type for resetting the system parameter system value editor model
 * @type {string}
 */
export const RESET_SYS_PARAM_SYSTEM_VALUE_EDITOR = 'RESET_SYS_PARAM_SYSTEM_VALUE_EDITOR';

/**
 * Action type for changing the system parameter system value editor model
 * @type {string}
 */
export const CHANGE_SYS_PARAM_SYSTEM_VALUE_EDITOR = 'CHANGE_SYS_PARAM_SYSTEM_VALUE_EDITOR';

/**
 * Action creator for showing system parameter system value editor
 * @param {object} payload The payload
 * @returns {{type: string}} The action for showing system parameter system value editor
 */
export function showSysParamSystemValueEditor(payload) {
    return {
        type: SHOW_SYS_PARAM_SYSTEM_VALUE_EDITOR,
        payload,
    };
}

/**
 * Action creator for showing system parameter system value deletion dialog
 * @param {object} payload The payload
 * @returns {{type: string}} The action for showing system parameter system value deletion dialog
 */
export function showSysParamSystemValueDeletionDialog(payload) {
    return {
        type: SHOW_DELETE_SYS_PARAM_SYSTEM_VALUE_DIALOG,
        payload,
    };
}

/**
 * Action creator for resetting system parameter system value editor
 * @returns {{type: string}} The action for resetting system parameter system value editor
 */
export function resetSysParamSystemValueEditor() {
    return {
        type: RESET_SYS_PARAM_SYSTEM_VALUE_EDITOR,
    };
}

/**
 * Action creator for changing system parameter system value editor
 * @param {object} payload The payload
 * @returns {{type: string}} The action for changing system parameter system value editor
 */
export function changeSysParamSystemValueEditor(payload) {
    return {
        type: CHANGE_SYS_PARAM_SYSTEM_VALUE_EDITOR,
        payload,
    };
}
