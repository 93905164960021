import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { OVERVIEW_PATH } from '~/features/base/constants/routes';

import PageHeader from '~/features/base/components/PageHeader';
import PageHeaderLink from '~/features/base/components/PageHeaderLink';

import AllUpdateEventsContainer from '~/features/updateEvents/components/AllUpdateEventsContainer';

export class UpdateEvents extends PureComponent {

    render() {
        const content = this.renderContent();
        return (
            <div className='update-events-list row'>
                <PageHeader pageTitle={<FormattedMessage id='intl-msg:updateEvents'/>}
                    pageLink={
                        <PageHeaderLink to={`/${OVERVIEW_PATH}`}>
                            <FormattedMessage id='intl-msg:backToOverview'/>
                        </PageHeaderLink>
                    }/>
                {content}
            </div>
        );
    }

    renderContent() {
        return [
            <AllUpdateEventsContainer/>,
        ];
    }
}

export default UpdateEvents;

UpdateEvents.defaultProps = {
    // props
    // functions
};

UpdateEvents.propTypes = {
    // props
    // functions
};
