import map from 'lodash/fp/map';
import size from 'lodash/fp/size';
import toPairs from 'lodash/fp/toPairs';

import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';

import {
    fetchBaseSwVersions,
} from '~/features/baseSwVersions/actions/baseSwActions';
import {
    copySysParamDefinitions,
} from '~/features/sysParams/actions/sysParamDefinitionActions';

import { availableBaseSwVersionsSelector } from '~/features/baseSwVersions/selectors/baseSwVersionSelectors';
import { distroEditorSelector } from '~/features/deliverables/features/distros/selectors/distroSelectors';

/**
 * Copy system parameter definitions dialog
 * Goal is to show all distro from which we can copy system parameters
 */
export class CopySysParamDefinitionsDialog extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            selectedShortBaseSwVersionIndex: '0',
        };
        this.fetchData();
    }

    onCopySysParamDefinitions = () => {
        const { model, availableBaseSwVersions } = this.props;
        const source = availableBaseSwVersions[this.state.selectedShortBaseSwVersionIndex].softwareVersion;
        const target = model.softwareVersion;
        this.props.copySysParamDefinitions(source, target);
    }

    onSoftwareVersionChange = (option) => {
        this.setState({
            selectedShortBaseSwVersionIndex: option.id,
        });
    }

    render() {
        const title = this.renderTitle();
        const body = this.renderBody();
        const footer = this.renderFooter();
        return (
            <Dialog className='copy-sys-param-definitions-dialog'
                show={true}
                showCloseButton={true}
                onHide={this.props.hideModal}
                title={title}
                body={body}
                footer={footer}
            />
        );
    }
    renderTitle() {
        return <FormattedMessage id='intl-msg:copySysParamDefinitions' />;
    }
    renderSoftwareVersionsDropdown() {
        const { availableBaseSwVersions } = this.props;
        const { selectedShortBaseSwVersionIndex } = this.state;

        if (size(availableBaseSwVersions)) {
            const itemsWithSelection = map(([index, baseSwVersion]) => {
                const id = index;
                const { shortBaseSwVersion } = baseSwVersion;
                return {
                    id,
                    label: shortBaseSwVersion,
                    selected: id === selectedShortBaseSwVersionIndex,
                };
            }, toPairs(availableBaseSwVersions));
            return (
                <div className='col-md-3'>
                    <Select options={itemsWithSelection}
                        onChange={this.onSoftwareVersionChange}/>
                </div>
            );
        }
        return (
            <div className='col-md-12'>
                <Spinner/>
            </div>
        );
    }
    renderBody() {
        const dropdown = this.renderSoftwareVersionsDropdown();
        return (
            <form>
                <div className='row' style={{marginBottom: 200}}>
                    <div className='col-md-12'>
                        <FormattedMessage id='intl-msg:copySysParamDefinitions.prompt'/>
                    </div>
                    {dropdown}
                </div>
            </form>
        );
    }
    renderFooter() {
        return (
            <div>
                <button className='btn btn-default' onClick={this.props.hideModal}>
                    <FormattedMessage id='intl-msg:close'/>
                </button>
                <button className='btn btn-primary' onClick={this.onCopySysParamDefinitions}>
                    <FormattedMessage id='intl-msg:ok'/>
                </button>
            </div>
        );
    }

    fetchData() {
        this.props.fetchBaseSwVersions();
    }
}

export const mapStateToProps = (state) => {
    return {
        model: distroEditorSelector(state),
        availableBaseSwVersions: availableBaseSwVersionsSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        fetchBaseSwVersions: (options) => {
            dispatch(fetchBaseSwVersions(options));
        },
        copySysParamDefinitions: (sourceSoftwareVersion, targetSoftwareVersion) => {
            dispatch(copySysParamDefinitions(sourceSoftwareVersion, targetSoftwareVersion));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CopySysParamDefinitionsDialog);

CopySysParamDefinitionsDialog.defaultProps = {
    model: undefined,
    availableBaseSwVersions: [],
    fetchBaseSwVersions: noop,
    copySysParamDefinitions: noop,
};

CopySysParamDefinitionsDialog.propTypes = {
    model: PropTypes.object,
    availableBaseSwVersions: PropTypes.array,
    fetchBaseSwVersions: PropTypes.func,
    copySysParamDefinitions: PropTypes.func,
};
