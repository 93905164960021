import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ENTITY_USER } from '~/features/base/constants/entities';

import {
    FETCH_USER,
    SHOW_MISSING_USER_ROLE_WARNING_DIALOG,
    showMissingUserRoleWarningDialog,
} from '~/features/user/actions/userActions';
import { mergeEntity } from '~/features/higherorder/actions/entityActions';
import { endLoading, startLoading } from '~/features/higherorder/actions/loadingActions';

import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';

import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';

import { doHandleErrorSaga, getHTTP } from '~/features/base/sagas/sagaUtil';
import { failedAction, finishedAction, successAction } from '~/features/higherorder/transforms/actionTransforms';
import { showModal } from '~/features/base/actions/ui/modalsActions';
import { MISSING_USER_ROLE_WARNING_MODAL } from '~/features/base/constants/modals';
import { hasSufficientRolesSelector } from '~/features/user/selectors/permissionSelectors';

export function* getUserUrl() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/users`;
}

export function* doFetchUserSaga(action) {
    yield put(startLoading(ENTITY_USER));
    try {
        const url = yield call(getUserUrl);
        const response = yield call(getHTTP, `${url}`);
        yield put(mergeEntity(response, { entityName: ENTITY_USER }));

        const hasSufficientRoles = yield select(hasSufficientRolesSelector);
        if (hasSufficientRoles) {
            yield put(successAction(action.type));
        } else {
            yield put(showMissingUserRoleWarningDialog());
        }
    } catch (error) {

        /*if (isEmpty(error)) {
            const emptyUser = { permissions: [], otaRoles: [] };
            yield put(mergeEntity(emptyUser, { entityName: ENTITY_USER }));
            yield put(successAction(action.type));
        } else {*/
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
        yield put(failedAction(action.type, error));
        //}
    }
    yield put(endLoading(ENTITY_USER));
    yield put(finishedAction(action.type));
}

export function* doShowMissingUserRoleWarningDialogSaga(action) {
    // yield put(resetDeliverableEditor());
    // if (action.payload.isNew) {
    // yield call(doPrepareForNewDeliverableSaga, action.payload);
    // } else {
    //     yield call(doPrepareForExistingDeliverableSaga, action.payload);
    // }

    // yield call(doFetchFilteredGroupsSaga, fetchFilteredGroups());
    yield put(showModal({
        modalType: MISSING_USER_ROLE_WARNING_MODAL,
        modalProps: {},
    }));
}

export function* fetchUserSaga() {
    yield takeLatest(FETCH_USER, doFetchUserSaga);
}

export function* showMissingUserRoleWarningDialogSaga() {
    yield takeLatest(SHOW_MISSING_USER_ROLE_WARNING_DIALOG, doShowMissingUserRoleWarningDialogSaga);
}

