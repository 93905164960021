import { entityStoreSelector } from '~/features/higherorder/selectors/dataSelectors';
import {
    ENTITY_DEVICE_COUNTS_BY_COUNTRIES,
    ENTITY_DEVICE_COUNTS_BY_VEHICLES,
} from '~/features/base/constants/entities';

const deviceCountsByCountriesEntitySelector = state => entityStoreSelector(state)[ENTITY_DEVICE_COUNTS_BY_COUNTRIES];
const deviceCountsByVehiclesEntitySelector = state => entityStoreSelector(state)[ENTITY_DEVICE_COUNTS_BY_VEHICLES];

export const deviceCountsByCountriesSelector = state => deviceCountsByCountriesEntitySelector(state);
export const deviceCountsByVehiclesSelector = state => deviceCountsByVehiclesEntitySelector(state);
