import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';


import classNames from 'classnames';
import { connect } from 'react-redux';
import { showGroupDeletionDialog, showGroupEditorDialog } from '~/features/groups/actions/groupEditorActions';
import { setShowGroupTableView, updateGroupSearchValue } from '~/features/groups/actions/groupViewActions';
import { groupSearchValue, showGroupTableViewSelector } from '~/features/groups/selectors/groupSelectors';
import TableSearch from '@rio-cloud/rio-uikit/lib/es/TableSearch';

/**
 * Control device list toolbar component
 */
export class GroupListToolbar extends PureComponent {
    onTableViewClick = () => {
        this.props.onShowTableView(true);
    };

    onCardsViewClick = () => {
        this.props.onShowTableView(false);
    };

    handleSearchValueChange = (value) => {
        this.props.updateGroupSearchValue(value);
    };

    render() {
        const { children, searchValue, showTableView } = this.props;
        const createButton = this.renderCreateButton();
        const viewButtonGroup = this.renderViewButtonGroup();
        return (
            <div className='row'>
                <div className='col-xs-6'>
                    <div className='btn-toolbar'>
                        {children}
                    </div>
                </div>
                <div className='col-xs-6'>
                    <div className='btn-toolbar pull-right'>
                        {
                            showTableView && <div className='btn-group min-width-200'>
                                <TableSearch
                                    value={searchValue}
                                    onChange={this.handleSearchValueChange}
                                    placeholder={'Search table ...'}
                                    className={'width-100pct'}
                                />
                            </div>
                        }
                        {createButton}
                        {viewButtonGroup}
                    </div>
                </div>
            </div>
        );
    }

    renderCreateButton() {
        if (this.props.canCreateGroups) {
            return (
                <a id='create-group-button'
                   className='btn btn-default'
                   onClick={this.props.onShowItemEditor}>
                    <span className='rioglyph rioglyph-plus' aria-hidden='true'></span>&nbsp;
                    <FormattedMessage id='intl-msg:createGroup'/>
                </a>
            );
        }
    }

    renderViewButtonGroup() {
        const { showTableView } = this.props;
        return (
            <div className='btn-group'>
                <div className={classNames('btn btn-default btn-icon-only', { active: showTableView })}
                     onClick={this.onTableViewClick}>
                    <span className='rioglyph rioglyph-table-view'></span>
                </div>
                <div className={classNames('btn btn-default btn-icon-only', { active: !showTableView })}
                     onClick={this.onCardsViewClick}>
                    <span className='rioglyph rioglyph-split-view'></span>
                </div>
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        showTableView: showGroupTableViewSelector(state),
        searchValue: groupSearchValue(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        showGroupEditorDialog: (options) => {
            dispatch(showGroupEditorDialog(options));
        },
        showGroupDeletionDialog: (options) => {
            dispatch(showGroupDeletionDialog(options));
        },
        setShowGroupTableView: (showTableView) => {
            dispatch(setShowGroupTableView(showTableView));
        },
        updateGroupSearchValue: (searchValue) => {
            dispatch(updateGroupSearchValue(searchValue));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(GroupListToolbar);

GroupListToolbar.defaultProps = {
    // props
    canCreateGroups: false,
    showTableView: false,
    searchValue: '',
    // functions
    onShowItemEditor: noop,
    onShowTableView: noop,
    updateGroupSearchValue: noop,
};

GroupListToolbar.propTypes = {
    // props
    canCreateGroups: PropTypes.bool,
    showTableView: PropTypes.bool,
    searchValue: PropTypes.string,
    // functions
    onShowItemEditor: PropTypes.func,
    onShowTableView: PropTypes.func,
    updateGroupSearchValue: PropTypes.func,
};
