import PropTypes from 'prop-types';
import Package from '~/prop-types/packagePropType';
import React from 'react';
import {
    DELIVERABLES_MANAGEMENT_PATH,
} from '~/features/base/constants/routes';
import Chip from '~/features/base/components/Chip';
import { openInNewTab } from '~/features/base/utils/openInNewTab';
import classNames from 'classnames';
import { getDeliverableTypePath } from '~/features/deliverables/utils/utils';

export const DeliverableIdChip = ({ deliverableId, bsStyle, className, deliverableType }) => {
    const deliverablePath = getDeliverableTypePath(deliverableType);
    const handleOnClick = () => openInNewTab(`/#${DELIVERABLES_MANAGEMENT_PATH}/${deliverablePath}?deliverableIdPrefix=${deliverableId}`);

    return (

        <Chip onClick={handleOnClick} Style={bsStyle}
              className={classNames('clickable', className)}>{deliverableId}</Chip>
    );
};

export default DeliverableIdChip;

DeliverableIdChip.propTypes = {
    // props
    deliverableId: PropTypes.oneOfType([
        PropTypes.instanceOf(Package),
        PropTypes.string,
    ]).isRequired,
    bsStyle: PropTypes.string,
    className: PropTypes.string,
};
