/* eslint-disable camelcase, no-console */
import { UserManager } from 'oidc-client';
import join from 'lodash/fp/join';
import getOr from 'lodash/fp/getOr';
import { mapUserProfile } from './userProfile';
import { env } from '~/env';

const { runtimeConfig, featureToggles } = env;

const trace = featureToggles.tracing ? (...args) => console.log(`[oidcLogin]`, ...args) : () => {
};

const pullLocale = getOr('en-GB', 'profile.locale');

const RETRY_SIGNIN_TIMEOUT_IN_MS = 30000;

const retrySigninSilent = (oauthConfig, userManager) => {
    userManager.signinSilent().catch(error => {
        trace('oidc.signinSilent failed!', error);
        if (error.message === 'login_required') {
            trace('oidc.sessionExpired');
            oauthConfig.onSessionExpired();
        } else {
            trace('oidc.schedulingRetry');
            setTimeout(() => retrySigninSilent(oauthConfig, userManager), RETRY_SIGNIN_TIMEOUT_IN_MS);
        }
    });
};

export const adaptPublishedInfo = (result = {}) => ({
    accessToken: result.access_token,
    expiresInSeconds: result.expires_in,
    idToken: result.profile,
    locale: pullLocale(result),
    profile: mapUserProfile(result.profile),
});

export const createUserManager = () => {
    const redirectUri = runtimeConfig.login.redirectUri;
    const silentRedirectUri = runtimeConfig.login.silentRedirectUri;
    const resource = runtimeConfig.login.resource;

    const settings = {
        authority: `${runtimeConfig.login.authority}`,
        client_id: `${runtimeConfig.login.clientId}`,
        loadUserInfo: false,
        redirect_uri: `${redirectUri}`,
        response_type: `id_token token`,
        resource: `${resource}`,
        scope: join(' ', runtimeConfig.login.oauthScope),
        silent_redirect_uri: `${silentRedirectUri || redirectUri}`,
        includeIdTokenInSilentRenew: false,
        automaticSilentRenew: true,
    };

    trace('oidc.auth.settings', settings);

    return new UserManager(settings);
};

export const configureUserManager = (oauthConfig, userManager) => {
    userManager.events.addUserLoaded(user => {
        trace('oidc.signinSilent success!', user);
        oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
    });

    userManager.events.addUserUnloaded(() => {
        trace('oidc.sessionExpired!');
        oauthConfig.onSessionExpired();
    });

    userManager.events.addSilentRenewError(error => {
        trace('oidc.silentRenewError', error);
        retrySigninSilent(oauthConfig, userManager);
    });

    userManager.events.addUserSignedOut((...args) => {
        trace('oidc.userSignedOut', ...args);
        oauthConfig.onSessionExpired();
    });

    userManager.events.addAccessTokenExpired((...args) => {
        trace('oidc.accessTokenExpired', ...args);
        oauthConfig.onSessionExpired();
    })

    userManager.events.addAccessTokenExpiring((...args) => {
        trace('oidc.accessTokenExpiring', ...args);
        retrySigninSilent(oauthConfig, userManager);
    });

    return userManager;
};

export const configureMockUserManager = ({ onSessionRenewed }) => {
    // eslint-disable-next-line no-console
    console.warn(`[feature/login/oidc-session] Using mocked authorization due to config setting`);

    const signinSilent = () => {
        onSessionRenewed(
            adaptPublishedInfo({
                access_token: (featureToggles && featureToggles.mockedToken && featureToggles.mockedToken.replace(
                    'Bearer ', '')) || undefined,
                // eslint-disable-next-line no-magic-numbers
                expires_in: 60 * 60 * 24 * 365,
                profile: {
                    locale: runtimeConfig.login.mockLocale,
                    unique_name: 'anton.schertenleib-ext@my-rio.de',
                },
            }),
        );
        return Promise.resolve();
    };
    return { signinSilent };
};
