import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { deleteGroup } from '~/features/groups/actions/groupActions';

import { groupEditorSelector } from '~/features/groups/selectors/groupSelectors';
import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';

export class GroupDeletionDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.onDeleteGroup = this.onDeleteGroup.bind(this);
    }

    onDeleteGroup() {
        const { model } = this.props;
        this.props.deleteGroup(model);
    }

    render() {
        const { model } = this.props;
        return (
            <ConfirmationDialog className='group-deletion-dialog'
                show={true}
                title={<FormattedMessage id='intl-msg:deleteGroup.header' values={{
                    name: model.name,
                }}/>}
                content={<FormattedMessage id='intl-msg:deleteGroup.prompt' values={{
                    name: model.name,
                }}/>}
                cancelButtonText={<FormattedMessage id='intl-msg:cancel'/>}
                confirmButtonText={<FormattedMessage id='intl-msg:ok'/>}
                onClickCancel={this.props.hideModal}
                onClickConfirm={this.onDeleteGroup}/>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        model: groupEditorSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        deleteGroup: (shortGroupVersion) => {
            dispatch(deleteGroup(shortGroupVersion));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupDeletionDialog);

GroupDeletionDialog.defaultProps = {
    model: {},
    deleteGroup: noop,
};

GroupDeletionDialog.propTypes = {
    model: PropTypes.object,
    deleteGroup: PropTypes.func,
};
