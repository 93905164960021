import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { mergeEntity } from '~/features/higherorder/actions/entityActions';

import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';
import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';

import { doHandleErrorSaga, getHTTP } from '~/features/base/sagas/sagaUtil';

import { ENTITY_HW_VARIANTS_ALL } from '~/features/base/constants/entities';
import { finishedAction } from '~/features/higherorder/transforms/actionTransforms';
import { FETCH_HW_VARIANTS } from '~/features/hwVariants/actions/hwVariantsActions';

export function* getControlDeviceURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/control-device`;
}

export function* doFetchHwVariantsSaga(action) {
    try {
        const hwVariantsServiceURL = yield call(getControlDeviceURL);
        const response = yield call(getHTTP, `${hwVariantsServiceURL}/hardware-variants`);
        yield put(mergeEntity(response.content, { entityName: ENTITY_HW_VARIANTS_ALL }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* fetchHwVariantsSaga() {
    yield takeLatest(FETCH_HW_VARIANTS, doFetchHwVariantsSaga);
}
