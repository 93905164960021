import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import map from 'lodash/fp/map';
// import {
//     PERMISSION_CONTROL_DEVICE_CREATE,
//     PERMISSION_CONTROL_DEVICE_READ,
//     PERMISSION_CONTROL_DEVICE_UPDATE,
// } from '~/features/base/constants/permissions';
//
// import { isTestManager, permissionsSelector } from '~/features/user/selectors/permissionSelectors';
//
// /*import { deviceGroupsSelector } from '~/features/groups/selectors/groupSelectors';*/
import {
//     aggregationStoreSelector,
    entityStoreSelector,
    indicesStoreSelector,
    loadingStoreSelector,
//     loadingStoreSelector,
//     selectionStoreSelector,
} from '~/features/higherorder/selectors/dataSelectors';
// import {
//     ENTITY_CONTROL_DEVICE,
//     ENTITY_CONTROL_DEVICE_ELIGIBLE_FILE_VERSIONS,
//     ENTITY_CONTROL_DEVICE_ELIGIBLE_PACKAGE_VERSIONS,
//     ENTITY_CONTROL_DEVICE_INFO,
//     ENTITY_CONTROL_DEVICE_SCOPE_DEVICES,
//     ENTITY_DEVICE_STATUS_NAMES_ALL,
//     ENTITY_DEVICE_STATUS_TYPES_ALL,
// } from '~/features/base/constants/entities';
// import { filterSettingsSelector } from '~/features/base/selectors/filterSelectors';
//
export const vehicleDetailsUiSelector = state => state.ui.vehicleDetails;
//
// const deviceStatusNamesEntitySelector = state => entityStoreSelector(state)[ENTITY_DEVICE_STATUS_NAMES_ALL];
// const deviceStatusTypesEntitySelector = state => entityStoreSelector(state)[ENTITY_DEVICE_STATUS_TYPES_ALL];
// export const controlDeviceInfoDataSelector = state => entityStoreSelector(state)[ENTITY_CONTROL_DEVICE_INFO];
// const controlDeviceEligiblePackageVersionsDataSelector = state => entityStoreSelector(
//     state)[ENTITY_CONTROL_DEVICE_ELIGIBLE_PACKAGE_VERSIONS];
// const controlDeviceEligibleFileVersionsDataSelector = state => entityStoreSelector(
//     state)[ENTITY_CONTROL_DEVICE_ELIGIBLE_FILE_VERSIONS];
// export const controlDeviceAggregationsSelector = state => aggregationStoreSelector(state)[ENTITY_CONTROL_DEVICE];
// export const controlDeviceEntitiesSelector = state => entityStoreSelector(state)[ENTITY_CONTROL_DEVICE];
export const vehicleEntitiesSelector = state => entityStoreSelector(state)[ENTITY_VEHICLES];
export const vehicleInfoEntitiesSelector = state => entityStoreSelector(state)[ENTITY_VEHICLE_INFO];
// const controlDeviceSelectionSelector = state => selectionStoreSelector(state)[ENTITY_CONTROL_DEVICE];
const loadingSelector = state => loadingStoreSelector(state)['vehicles'];
export const vehiclesLoadingSelector = createSelector(
    [loadingSelector],
    loading => loading.vehicles,
);
// export const devicesAssignedLoadingSelector = createSelector(
//     [loadingSelector],
//     loading => loading.devicesAssigned,
// );
// export const distroWhitelistLoadingSelector = createSelector(
//     [loadingSelector],
//     loading => loading.distroWhitelist,
// );
// export const packageDeliverableWhitelistLoadingSelector = createSelector(
//     [loadingSelector],
//     loading => loading.packageDeliverableWhitelist,
// );
// export const fileDeliverableWhitelistLoadingSelector = createSelector(
//     [loadingSelector],
//     loading => loading.fileDeliverableWhitelist,
// );

import {
    ENTITY_VEHICLE_INFO,
    ENTITY_VEHICLES,
    ENTITY_VEHICLES_SCOPE_DEVICES,
} from '~/features/base/constants/entities';

const vehiclePagesSelector = state => getOr({}, ENTITY_VEHICLES_SCOPE_DEVICES,
    indicesStoreSelector(state)[ENTITY_VEHICLES]);

// export const controlDeviceTBM3CountSelector = state => controlDeviceAggregationsSelector(state).totalTBM3Count;
// export const controlDeviceVCMCountSelector = state => controlDeviceAggregationsSelector(state).totalVCMCount;
// export const testDeviceCountSelector = state => controlDeviceAggregationsSelector(state).testCount;
// export const blockedDeviceCountSelector = state => controlDeviceAggregationsSelector(state).blockedCount;
// export const warningDeviceCountSelector = state => controlDeviceAggregationsSelector(state).warningDeviceCount;
// export const errorDeviceCountSelector = state => controlDeviceAggregationsSelector(state).errorDeviceCount;
// export const inCustomerFleetCountSelector = state => controlDeviceAggregationsSelector(state).inCustomerFleetCount;
//
export const vehiclePageNumberSelector = state => get('number', vehiclePagesSelector(state));
// export const controlDevicePageCountSelector = state => get('totalPages', controlDevicePagesSelector(state));
// export const controlDeviceTotalElementsSelector = state => get('totalElements', controlDevicePagesSelector(state));

import { createSelector } from 'reselect';

export const vehiclePageItemsSelector = createSelector(
    [vehiclePagesSelector, vehicleEntitiesSelector, vehiclePageNumberSelector],
    (pages, entities, pageNumber) => {
        return map(id => entities[id], getOr([], pageNumber, pages));
    },
);

// export const controlDeviceSelector = (state, ownProps) => controlDeviceEntitiesSelector(state)[ownProps.serialNumber];
// export const controlDeviceInfoSelector = (state, ownProps) =>
//     controlDeviceInfoDataSelector(state)[ownProps.serialNumber];
// export const controlDeviceEligiblePackageVersionsSelector = createSelector(
//     [controlDeviceEligiblePackageVersionsDataSelector],
//     (entities) => {
//         return entities ? entities : [];
//     },
// );
// // export const controlDeviceEligiblePackageVersionsSelector = (state, ownProps) => {
// //     let eligiblePackageVersions = controlDeviceEligiblePackageVersionsDataSelector(state)[ownProps.serialNumber];
// // cleanup
// // if (eligiblePackageVersions) {
// // delete eligiblePackageVersions.serialNumber;
// // }
// // return eligiblePackageVersions;
// // }
//
// export const controlDeviceEligibleFileVersionsSelector = createSelector(
//     [controlDeviceEligibleFileVersionsDataSelector],
//     (entities) => {
//         return entities ? entities : [];
//     },
// );
// // export const controlDeviceEligibleFileVersionsSelector = (state, ownProps) => {
// //     let eligibleFileVersions = controlDeviceEligibleFileVersionsDataSelector(state)[ownProps.serialNumber];
// // cleanup
// // if (eligibleFileVersions) {
// // delete eligibleFileVersions.serialNumber;
// // }
// // return eligibleFileVersions;
// // }
//
// export const combinedControlDeviceSelector = createSelector(
//     [controlDeviceSelector, controlDeviceInfoSelector/*, deviceGroupsSelector*/],
//     (controlDevice, controlDeviceInfo/*, deviceGroups*/) => ({
//         ...controlDevice,
//         ...controlDeviceInfo,
//         //groups: deviceGroups,
//     }),
// );
//
// export const controlDeviceCountsByBaseSwSelector = state => controlDeviceAggregationsSelector(state).countsByBaseSw;
// export const controlDeviceCountsByDistroSelector = state => controlDeviceAggregationsSelector(state).countsByDistro;
// export const controlDeviceCountsByHwVariantSelector = state =>
//     controlDeviceAggregationsSelector(state).countsByHwVariant;
//
// export const controlDeviceCountsByBaseSwInCustomerFleetSelector = state =>
//     controlDeviceAggregationsSelector(state).countsByBaseSwInCustomerFleet;
//
// export const controlDeviceCountsByDistroInCustomerFleetSelector = state =>
//     controlDeviceAggregationsSelector(state).countsByDistroInCustomerFleet;
//
// export const controlDeviceCountsByHwVariantInCustomerFleetSelector = state =>
//     controlDeviceAggregationsSelector(state).countsByHwVariantInCustomerFleet;
//
// export const canReadControlDevicesSelector = state =>
//     permissionsSelector(state).includes(PERMISSION_CONTROL_DEVICE_READ);
//
// export const canCreateControlDevicesSelector = state =>
//     permissionsSelector(state).includes(PERMISSION_CONTROL_DEVICE_CREATE);
//
// export const canUpdateControlDevicesSelector = (state, ownProps) => {
//     const controlDevice = controlDeviceSelector(state, ownProps);
//     return permissionsSelector(state).includes(PERMISSION_CONTROL_DEVICE_UPDATE) || (isTestManager(state)
//         && controlDevice && controlDevice.testReleasesActive);
// };
//
// export const canQuickWhitelistControlDevicesSelector = (state, ownProps) => {
//     const controlDevice = controlDeviceSelector(state, ownProps);
//     return (isTestManager(state) && controlDevice && controlDevice.testReleasesActive);
// };
//
// export const selectedAllControlDevicesSelector = state => controlDeviceSelectionSelector(state).selectedAll;
// export const selectedControlDevicesSelector = state => controlDeviceSelectionSelector(state).selectedIds;
//
// export const selectedControlDeviceCountSelector = createSelector(
//     [selectedAllControlDevicesSelector, selectedControlDevicesSelector, controlDeviceTotalElementsSelector],
//     (selectedAll, selected, totalElements) => {
//         if (selectedAll) {
//             return totalElements;
//         }
//         return size(selected);
//     },
// );
//
// export const filterSettingsControlDevicesSelector = createSelector(
//     [filterSettingsSelector],
//     (filterSettings) => filterSettings[ENTITY_CONTROL_DEVICE],
// );
//
// export const controlDeviceEditorSelector = state => state.ui.controlDeviceEditor;

export const showVehicleDetailsSidebarSelector = createSelector(
    [vehicleDetailsUiSelector],
    vehicleDetailsUi => vehicleDetailsUi.showVehicleDetailsSidebar,
);

export const getSelectedTabIdSelector = createSelector(
    [vehicleDetailsUiSelector],
    deviceDetailsUi => deviceDetailsUi.tabId,
);

// export const getCurrentlySelectedDeviceSerialNumberSelector = createSelector(
//     [deviceDetailsUiSelector],
//     deviceDetailsUi => deviceDetailsUi.currentSerialNumber,
// );
//
// // export const controlDeviceInfoSelector = (state, ownProps) => {
// //     const currentSerialNumber = deviceDetailsUiSelector().serialNumber;
// //     return controlDeviceInfoDataSelector(state)[currentSerialNumber];
// // }

export const getCurrentlySelectedVehicleInfoSelector = createSelector(
    [vehicleDetailsUiSelector, vehicleEntitiesSelector],
    (vehicleDetailsUi, vehicles) => {
        return vehicles[vehicleDetailsUi.currentVin];
    },
);

export const getCurrentlySelectedVehicleDevicesSelector = createSelector(
    [vehicleDetailsUiSelector, vehicleInfoEntitiesSelector],
    (vehicleDetailsUi, selectedVehicleInfo) => {
        return selectedVehicleInfo[vehicleDetailsUi.currentVin]?.devices;
    },
);

export const getCurrentlySelectedVehicleRolloutStatesSelector = createSelector(
    [vehicleDetailsUiSelector, vehicleInfoEntitiesSelector],
    (vehicleDetailsUi, selectedVehicleInfo) => {
        return selectedVehicleInfo[vehicleDetailsUi.currentVin]?.lastRolloutState;
    },
);

// export const deviceStatusNamesSelector = createSelector(
//     [deviceStatusNamesEntitySelector],
//     (deviceStatusNames) => {
//         return deviceStatusNames;
//     },
// );
//
// export const deviceStatusTypesSelector = createSelector(
//     [deviceStatusTypesEntitySelector],
//     (deviceStatusTypes) => {
//         return deviceStatusTypes;
//     },
// );
