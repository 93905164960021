import isEqual from 'lodash/fp/isEqual';
import map from 'lodash/fp/map';
import noop from 'lodash/fp/noop';

import classNames from 'classnames';

import PropTypes from 'prop-types';

import React, { Component } from 'react';

const CollapseButton = ({ onCollapseButtonClick }) => {
    const classes = 'rioglyph rioglyph rioglyph-chevron-left text-color-white margin-0';
    return (
        <button type='button' className='btn btn-link width-50 height-50 collapse-button bottom-0 position-absolute'
            onClick={onCollapseButtonClick}>
            <span className={classes} aria-hidden='true'></span>
        </button>
    );
};
const ExpandButton = ({ onExpandButtonClick }) => {
    const classes = 'rioglyph rioglyph rioglyph-chevron-right text-color-white margin-0';
    return (
        <button type='button' className='btn btn-link width-50 height-50 expand-button bottom-0 position-absolute'
            onClick={onExpandButtonClick}>
            <span className={classes} aria-hidden='true'></span>
        </button>
    );
};
const NavButton = ({ expanded, className, onNavButtonClick }) => {
    const classes = classNames('rioglyph text-color-white text-size-h2 margin-0', className);
    const arrowClasses = `rioglyph rioglyph-triangle-left text-color-white text-size-20
        position-absolute padding-1 left-10`;
    return (
        <button type='button' className='btn btn-link width-50 height-50'
            onClick={onNavButtonClick}>
            <span className={classes} aria-hidden='true'/>
            { expanded ? <span className='position-absolute left-25 top-15'>
                <span className={arrowClasses}/>
            </span> : null}
        </button>
    );
};

export class SidebarNavigation extends Component {
    shouldComponentUpdate(nextProps) {
        const { expanded, navItems } = this.props;
        return !isEqual(expanded, nextProps.expanded) || !isEqual(navItems, nextProps.navItems);
    }

    render() {
        const { expanded, navItems, onNavButtonClick } = this.props;
        const navButtons = map(navItem => <NavButton onNavButtonClick={onNavButtonClick} {...navItem} expanded={expanded}/>, navItems);
        return (
            <div className='sidebar-navigation bg-black height-100pct width-50 position-relative'
                style={{ 'backgroundColor': 'black' }}>
                <div className='btn-toolbar'>
                    {navButtons}
                </div>
                {expanded ? <CollapseButton {...this.props} /> : <ExpandButton {...this.props} />}
                {

                /*<NavList isOpen={expanded} items={navItems} />*/
                }
            </div>
        );
    }
}

//const byType = ({ filters }) => matches({ meta: { type: filters.navigation } });

export default SidebarNavigation;

SidebarNavigation.defaultProps = {
    expanded: true,
    navItems: [],
    onNavButtonClick: noop,
    onCollapseButtonClick: noop,
    onExpandButtonClick: noop,
};

SidebarNavigation.propTypes = {
    expanded: PropTypes.bool,
    navItems: PropTypes.array,
    onNavButtonClick: PropTypes.func,
    onCollapseButtonClick: PropTypes.func,
    onExpandButtonClick: PropTypes.func,

};

/*const Navigation = compose(
    filterItems(byType),
    surround({ append: NavSection }),
);

export default Navigation;*/
