import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { showModal } from '~/features/base/actions/ui/modalsActions';
import {
    FETCH_CONTEXT_DETAILS,
    fetchContextDetails,
    fetchDeliverablesUserInfo,
    SHOW_DELIVERABLE_PERMISSION_DIALOG,
    UPDATE_CONTEXT_OWNER,
} from '~/features/artifacts/actions/deliverableActions';
import { DELIVERABLE_PERMISSIONS_MODAL } from '~/features/base/constants/modals';
import { endLoading, startLoading } from '~/features/higherorder/actions/loadingActions';
import { ENTITY_DELIVERABLES_CONTEXT_INFO } from '~/features/base/constants/entities';
import { doHandleErrorSaga, getHTTP, patchHTTP } from '~/features/base/sagas/sagaUtil';
import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';
import { finishedAction } from '~/features/higherorder/transforms/actionTransforms';
import { mergeEntity } from '~/features/higherorder/actions/entityActions';
import { getDeliverableManagementBackend } from '~/features/base/selectors/backendSelectors';

export function* getDeliverablesContextURL() {
    const serviceURL = yield select(getDeliverableManagementBackend);
    return `${serviceURL}/context/v1`;
}

export function* doShowDeliverablePermissionsDialogSaga(action) {
    yield put(fetchDeliverablesUserInfo());
    yield put(showModal({
        modalType: DELIVERABLE_PERMISSIONS_MODAL,
        modalProps: {},
    }));
}

export function* doFetchContextDetailsSaga(action) {
    yield put(startLoading(ENTITY_DELIVERABLES_CONTEXT_INFO));
    try {
        const url = yield call(getDeliverablesContextURL);
        const contextName = action.contextName;
        const response = yield call(getHTTP, `${url}/${contextName}`);
        yield put(mergeEntity(response, { entityName: ENTITY_DELIVERABLES_CONTEXT_INFO }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_DELIVERABLES_CONTEXT_INFO));
    yield put(finishedAction(action.type));
}

export function* doUpdateContextOwnerSaga(action) {
    yield put(startLoading(ENTITY_DELIVERABLES_CONTEXT_INFO));
    try {
        const url = yield call(getDeliverablesContextURL);
        const contextName = action.contextName;
        const mode = action.mode;
        const ownerName = action.ownerName;
        yield call(patchHTTP, `${url}/${contextName}?mode=${mode}`, `["${ownerName}"]`);
        yield put(fetchContextDetails(contextName));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_DELIVERABLES_CONTEXT_INFO));
    yield put(finishedAction(action.type));
}

export function* showDeliverablePermissionDialogSaga() {
    yield takeLatest(SHOW_DELIVERABLE_PERMISSION_DIALOG, doShowDeliverablePermissionsDialogSaga);
}

export function* fetchContextDetailsSaga() {
    yield takeLatest(FETCH_CONTEXT_DETAILS, doFetchContextDetailsSaga);
}

export function* updateContextOwnerSaga() {
    yield takeLatest(UPDATE_CONTEXT_OWNER, doUpdateContextOwnerSaga);
}
