import {
    RESET_FILE_EDITOR,
    CHANGE_FILE_EDITOR,
} from '~/features/deliverables/features/files/actions/fileEditorActions';

const defaultState = {
    isNew: true,
    fileId: '',
    fileType: 'APP',
    name: '',
    partition: 'TELEMATICS',
    description: '',
    owners: [],
};

export const resetFileEditor = (state = defaultState) => {
    return state;
};
export const changeFileEditor = (state, { payload }) => {
    const newState = {
        ...state,
        ...payload,
    };
    return newState;
};

export default function fileEditorReducer(state = defaultState, action = {}) {
    switch (action.type) {
        case RESET_FILE_EDITOR:
            return resetFileEditor();
        case CHANGE_FILE_EDITOR:
            return changeFileEditor(state, action);
        default:
            return state;
    }
}
