export const GET_ALL_LOGS = 'GET_ALL_LOGS';
export const GET_DEVICEMANAGED_PARAMS = 'GET_DEVICEMANAGED_PARAMS';
export const GET_LOGS = 'GET_LOGS';
export const GET_SERVICE_LOGS = 'GET_SERVICE_LOGS';
export const GET_SYS_PARAMS = 'GET_SYS_PARAMS';
export const INSTALLATIONS_FINISHED = 'INSTALLATIONS_FINISHED';
export const SET_PARAMETERS = 'SET_PARAMETERS';
export const UPDATE_BASE_SOFTWARE = 'UPDATE_BASE_SOFTWARE';
export const UPDATE_DISTRO = 'UPDATE_DISTRO';
export const UPDATE_BUNDLE = 'UPDATE_BUNDLE';
export const UPDATE_PACKAGE = 'UPDATE_PACKAGE';
export const INSTALL_PACKAGE = 'INSTALL_PACKAGE';
export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';
export const UPDATE_DELIVERABLE = 'UPDATE_DELIVERABLE';
export const INSTALL_DELIVERABLE = 'INSTALL_DELIVERABLE';
export const UNINSTALL_PACKAGE = 'UNINSTALL_PACKAGE';

export const reportActions = {
    [GET_ALL_LOGS]: {
        id: GET_ALL_LOGS,
        label: 'intl-msg:report.action.getAllLogs',
    },
    [GET_DEVICEMANAGED_PARAMS]: {
        id: GET_DEVICEMANAGED_PARAMS,
        label: 'intl-msg:report.action.getDeviceManagedParams',
    },
    [GET_LOGS]: {
        id: GET_LOGS,
        label: 'intl-msg:report.action.getLogs',
    },
    [GET_SERVICE_LOGS]: {
        id: GET_SERVICE_LOGS,
        label: 'intl-msg:report.action.getServiceLogs',
    },
    [GET_SYS_PARAMS]: {
        id: GET_SYS_PARAMS,
        label: 'intl-msg:report.action.getSysParams',
    },
    [INSTALLATIONS_FINISHED]: {
        id: INSTALLATIONS_FINISHED,
        label: 'intl-msg:report.action.installationsFinished',
    },
    [SET_PARAMETERS]: {
        id: SET_PARAMETERS,
        label: 'intl-msg:report.action.setParameters',
    },
    [UPDATE_BASE_SOFTWARE]: {
        id: UPDATE_BASE_SOFTWARE,
        label: 'intl-msg:report.action.updateBaseSoftware',
    },
    [UPDATE_DISTRO]: {
        id: UPDATE_DISTRO,
        label: 'intl-msg:report.action.updateDistro',
    },
    [UPDATE_BUNDLE]: {
        id: UPDATE_BUNDLE,
        label: 'intl-msg:report.action.updateBundle',
    },
    [UPDATE_PACKAGE]: {
        id: UPDATE_PACKAGE,
        label: 'intl-msg:report.action.updatePackage',
    },
    [INSTALL_PACKAGE]: {
        id: INSTALL_PACKAGE,
        label: 'intl-msg:report.action.installPackage',
    },
    [UNINSTALL_PACKAGE]: {
        id: UNINSTALL_PACKAGE,
        label: 'intl-msg:report.action.uninstallPackage',
    },
    [DOWNLOAD_FILE]: {
        id: DOWNLOAD_FILE,
        label: 'intl-msg:report.action.downloadFile',
    },
    [UPDATE_DELIVERABLE]: {
        id: UPDATE_DELIVERABLE,
        label: 'intl-msg:report.action.updateDeliverable',
    },
    [INSTALL_DELIVERABLE]: {
        id: INSTALL_DELIVERABLE,
        label: 'intl-msg:report.action.installDeliverable',
    },
};
