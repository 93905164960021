import React from 'react';
import { useFetchVehicleVariantsQuery } from "~/api/controlDevice/ControlDevice.api";
import { useDispatch, useSelector } from 'react-redux';
import {getSelectedVehicleVariant, setSelectedVariant} from "~/features/overviewNew/slices/overviewSlice";
import SelectOTA from "~/features/base/components/forms/SelectOTA";
import { FormattedMessage } from 'react-intl';

interface VehicleVariantsSelectProps {}

const VehicleVariantsSelect: React.FC<VehicleVariantsSelectProps> = () => {
    const dispatch = useDispatch();
    const option = useSelector((value) => getSelectedVehicleVariant(value));

    const { data: vehicleVariantNames = [] } = useFetchVehicleVariantsQuery({});

    const emptyOption = {
        label: <FormattedMessage id='intl-msg:allVehicles' />,
        id: 'all',
        value: undefined
    };

    const optionsWithSelection = [emptyOption, ...vehicleVariantNames.map(variant => ({
        label: variant,
        id: variant,
        value: variant
    }))];

    const handleOnChange = (value) => {
        dispatch(setSelectedVariant(value.id));
    };

    return (
        <div className="width-200">
            <SelectOTA
                id="16"
                bsSize="lg"
                options={optionsWithSelection}
                selectedOptionText={
                    <h1 className={'margin-0'} style={{ fontFamily: 'MANEUROPECONDENSED' }}>
                        {optionsWithSelection.find(elem => elem.id === option)?.label}
                    </h1>
                }
                onChange={handleOnChange}
                btnClassName="btn btn-link height-auto padding-0 focus-shadow-none hover-bg-none"
            />
        </div>
    );
};

export default VehicleVariantsSelect;
