import get from 'lodash/fp/get';
import React from 'react';
import classNames from 'classnames';

export const renderTableData = (item, columnDescriptor) => {
    if (columnDescriptor.hidden) {
        return;
    }
    return (
        <td key={`td-${columnDescriptor.id}`} className={classNames(columnDescriptor.className)}>
            {
                columnDescriptor.format ?
                    columnDescriptor.format(get(columnDescriptor.field, item), item) :
                    item[columnDescriptor.field]
            }
        </td>
    );
};
