import { connect } from 'react-redux';
import { followRoute } from '~/features/base/actions/ui/routeActions';

import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';

import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';
import DeliverableList from '~/features/artifacts/components/DeliverableList';
import { deliverablesLoadingSelector } from '~/features/artifacts/selectors/deliverableSelectors';
import {
    showDeliverableDeletionDialog,
    showDeliverableEditorDialog,
} from '~/features/artifacts/actions/deliverableActions';

export const mapStateToProps = (state, ownProps) => {
    return {
        pathname: pathnameSelector(state),
        search: searchSelector(state),
        deliverablesLoading: deliverablesLoadingSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        triggerDataFetcher: () => {
            dispatch(triggerDataFetcher());
        },
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        onCreateDeliverable: (options) => {
            dispatch(showDeliverableEditorDialog(options));
        },
        onEditDeliverable: (options) => {
            dispatch(showDeliverableEditorDialog(options));
        },
        onDeleteDeliverable: (options) => {
            dispatch(showDeliverableDeletionDialog(options));
        },
        followRoute: (options) => {
            dispatch(followRoute(options));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableList);
