import noop from 'lodash/fp/noop';
import _map from 'lodash/map';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
    controlDeviceReferencedDeliverablesSelector,
} from '~/features/devices/selectors/controlDeviceAssociatedDeliverablesSelectors';
import {
    deliverablesColumnDescriptors,
} from '~/features/devices/constants/deviceAssociatedDeliverablesColumnDescriptors';
import DeviceAssociatedDeliverablesListTable
    from '~/features/devices/components/dialogs/associatedDeliverables/DeviceAssociatedDeliverablesListTable';

/**
 * Control device associated artifacts form
 */
export class DeviceAssociatedDeliverablesForm extends PureComponent {
    onDeliverableTypeChange = (selectedDeliverableType) => {
        this.props.onDeliverableTypeChange(selectedDeliverableType);
    };

    render() {
        const { referencedDeliverables, serialNumber } = this.props;

        const deliverableEntries = [0];
        _map(referencedDeliverables, (deliverableIds, deliverableType) => {
            _map(deliverableIds, (deliverableVersions, deliverableId) => {
                _map(deliverableVersions, (properties, deliverableVersion) => {

                    deliverableEntries.push({
                        deliverableId,
                        deliverableType,
                        deliverableVersion,
                        referenceTypesAndGroups: properties,
                    });
                });
            });
        });

        return [
            <div className={'padding-bottom-20'}>
                <h6 className={'text-color-darker'}><FormattedMessage
                    id='intl-msg:controlDeviceAssociatedDeliverables.description'/>
                </h6>
            </div>,
            <div className={'height-500'}>
                <DeviceAssociatedDeliverablesListTable showHeader={false}
                                                       items={deliverableEntries}
                                                       columnDescriptors={deliverablesColumnDescriptors}
                                                       serialNumber={serialNumber}
                />
            </div>,
        ];
    }
}

const mapStateToProps = (state) => ({
    referencedDeliverables: controlDeviceReferencedDeliverablesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceAssociatedDeliverablesForm);

DeviceAssociatedDeliverablesForm.defaultProps = {
    // props
    serialNumber: '',
    referencedDeliverables: {},
    // functions
    onDeliverableTypeChange: noop,
    changeControlDeviceQuickWhitelisting: noop,
    fetchFilteredDeliverables: noop,
    fetchFilteredDeliverableIds: noop,
};

DeviceAssociatedDeliverablesForm.propTypes = {
    // props
    serialNumber: PropTypes.string,
    referencedDeliverables: PropTypes.object,
    // functions
    onDeliverableTypeChange: PropTypes.func,
    changeControlDeviceQuickWhitelisting: PropTypes.func,
    fetchFilteredDeliverables: PropTypes.func,
    fetchFilteredDeliverableIds: PropTypes.func,
};
