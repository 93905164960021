import omit from 'lodash/fp/omit';

import {
    REGISTER_DATA_INTEREST, UNREGISTER_ALL_DATA_INTEREST,
    UNREGISTER_DATA_INTEREST,
} from '~/features/base/actions/ui/dataInterestActions';

const defaultState = {
};

/**
 * Reducer for managing data interest by various views
 * TODO API needs to be more concrete ... right now, anything could be done
 * @param {object} state The current state
 * @param {object} action The action to be processed
 * @returns {object} The new state
 */
export default function dataInterestReducer(state = defaultState, action = {}) {
    let newState;
    switch (action.type) {
        case REGISTER_DATA_INTEREST:
            newState = {
                ...state,
                [action.view]: action.interest,
            };
            return newState;
        case UNREGISTER_DATA_INTEREST: {
            newState = omit(action.view, state);
            return newState;
        }
        case UNREGISTER_ALL_DATA_INTEREST: {
            return defaultState;
        }
        default:
            return state;
    }
}
