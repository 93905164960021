import PropTypes from 'prop-types';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

//const OFFLINE_TIMESPAN_MONTH = 2629746000;
const OFFLINE_TIMESPAN_WEEK = 604800000;

export const DeviceOffline = ({ timestamp }) => {
    if (timestamp && timestamp < (new Date().getTime() - OFFLINE_TIMESPAN_WEEK)) {
        return (
            <OverlayTrigger placement='top' overlay={
                <Tooltip id='tooltip' className='top-left'>
                    <FormattedMessage id='intl-msg:device.offline.tooltip'/>
                </Tooltip>}>
                <span className={'label label-condensed margin-left-10 label-warning'}>
                    <FormattedMessage id='intl-msg:device.offline'/>
                </span>
            </OverlayTrigger>
        );
    }
    return null;
};

export default DeviceOffline;

DeviceOffline.propTypes = {
    // props
    timestamp: PropTypes.number,
};
