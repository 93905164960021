import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useLazyDownloadLogZipQuery,} from '~/api/logs/LoggingService.api';
import download from 'downloadjs';
import OverlayTrigger from "@rio-cloud/rio-uikit/lib/es/OverlayTrigger";
import Tooltip from "@rio-cloud/rio-uikit/lib/es/Tooltip";

interface LogFilesDownloadZipButtonProps {
    id: string;
}

const LogFilesDownloadZipButton: React.FC<LogFilesDownloadZipButtonProps> = ({id}) => {
    const [downloadLogZip] = useLazyDownloadLogZipQuery<any>();

    const handleOnClick = async () => {
        await downloadLogZip(id).unwrap().then(async (result) => {
            const response = await fetch(result, {
                method: 'GET',
                headers: {
                    Accept: 'application/octet-stream',
                    'Content-Type': 'application/octet-stream',
                },
            });
            response.blob().then(data => download(data, `outputZip_${id}.zip`, 'application/octet-stream'));

        });
    };

    return (
        <OverlayTrigger placement='top' overlay={
            <Tooltip id='tooltip' className='top-right'>
                <FormattedMessage id={'intl-msg:logs.downloadZip.tooltip'}/>
            </Tooltip>}>
            <div className='btn-toolbar pull-right'>
                <div className='btn-group'>
                    <FormattedMessage id='intl-msg:download'>
                        {title =>
                            <button className='btn btn-link'
                                    title={title}
                                    data-key={id}
                                    onClick={handleOnClick}>
                                <span className='margin-left-20 rioglyph rioglyph-download' aria-hidden='true'></span>
                            </button>
                        }
                    </FormattedMessage>
                </div>
            </div>
        </OverlayTrigger>
        );

};

export default LogFilesDownloadZipButton;
