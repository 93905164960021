import getOr from 'lodash/fp/getOr';

import { createSelector } from 'reselect';

import { ENTITY_SYS_PARAM_SYSTEM_VALUE } from '~/features/base/constants/entities';

import {
    PERMISSION_SYS_PARAM_VALUES_READ,
    PERMISSION_SYS_PARAM_VALUES_CREATE,
    PERMISSION_SYS_PARAM_VALUES_UPDATE,
    PERMISSION_SYS_PARAM_VALUES_DELETE,
} from '~/features/base/constants/permissions';

import { permissionsSelector } from '~/features/user/selectors/permissionSelectors';
import { entityStoreSelector } from '~/features/higherorder/selectors/dataSelectors';
import { shortBaseSwVersionOfDeviceSelector } from '~/features/baseSwVersions/selectors/baseSwVersionSelectors';

const mapBySerialsSelector = state => entityStoreSelector(state)[ENTITY_SYS_PARAM_SYSTEM_VALUE];
const mapByBaseSwVersionsSelector = (state, { serialNumber }) => {
    return getOr({}, serialNumber, mapBySerialsSelector(state));
};

export const canReadSysParamSystemValues = state =>
    permissionsSelector(state).includes(PERMISSION_SYS_PARAM_VALUES_READ);

export const canCreateSysParamSystemValues = state =>
    permissionsSelector(state).includes(PERMISSION_SYS_PARAM_VALUES_CREATE);

export const canUpdateSysParamSystemValues = state =>
    permissionsSelector(state).includes(PERMISSION_SYS_PARAM_VALUES_UPDATE);

export const canDeleteSysParamSystemValues = state =>
    permissionsSelector(state).includes(PERMISSION_SYS_PARAM_VALUES_DELETE);

export const sysParamSystemValuesOfDeviceSelector = createSelector(
    [mapByBaseSwVersionsSelector, shortBaseSwVersionOfDeviceSelector],
    (mapOfBaseSwVersions, shortBaseSwVersion) => {
        return getOr({}, shortBaseSwVersion, mapOfBaseSwVersions);
    },
);

export const sysParamSystemValueEditorSelector = state =>
    state.ui.sysParamSystemValueEditor;
