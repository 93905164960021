import compact from 'lodash/fp/compact';
import size from 'lodash/fp/size';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';

import { OVERVIEW_PATH } from '~/features/base/constants/routes';

import GroupListToolbar from '~/features/groups/components/GroupListToolbar';
import GroupListTable from '~/features/groups/components/GroupListTable';
import GroupCards from '~/features/groups/components/GroupCards';
import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';
import PageHeader from '~/features/base/components/PageHeader';
import PageHeaderLink from '~/features/base/components/PageHeaderLink';

export class GroupList extends PureComponent {

    render() {
        const content = this.renderContent();
        return (
            <div className='group-list'>
                <div className='row'>
                    <PageHeader pageTitle={<FormattedMessage id='intl-msg:groups'/>}
                        pageLink={
                            <PageHeaderLink to={`/${OVERVIEW_PATH}`}>
                                <FormattedMessage id='intl-msg:backToOverview'/>
                            </PageHeaderLink>
                        }/>
                </div>
                {content}
            </div>
        );
    }

    renderContent() {
        const { canReadGroups, canCreateGroups, groups, groupsLoading, onCreateGroup } = this.props;

        if (!canReadGroups || (!groupsLoading && !size(groups))) {
            return [
                <div key={'notFoundState'} className='col-md-12'>
                    <NotFoundState fullWidth
                        headline={<FormattedMessage id='intl-msg:nothingFound'/>}
                        message={<FormattedMessage id='intl-msg:groups.notFound'/>}
                        buttons={compact([canCreateGroups ? {
                            text: <FormattedMessage id= 'intl-msg:createGroup'/>,
                            onClick: onCreateGroup,
                        } : null])}/>
                </div>,
            ];
        }

        const { totalElements, selectedCount, showTableView, showFilterView, onShowTableView,
            onToggleFilterView } = this.props;

        const listContent = showTableView ? this.renderListContent() : this.renderCardsContent();
        return [
            <div key={'groupListToolbar'} className='row'>
                <DefaultWhiteColumn className='padding-top-20 padding-bottom-20 clearfix'>
                    <GroupListToolbar
                        canCreateGroups={canCreateGroups}
                        showTableView={showTableView}
                        showFilterView={showFilterView}
                        onShowItemEditor={onCreateGroup}
                        onShowTableView={onShowTableView}
                        onToggleFilterView={onToggleFilterView}>
                        <span className='padding-top-5'>
                            <FormattedMessage id='intl-msg:totalGroups.phrase'
                                values={{
                                    totalCount: totalElements || 0,
                                    selectedCount: selectedCount,
                                }}/>
                        </span>
                        <div className='display-inline-block padding-left-5'>
                            {groupsLoading ? <Spinner/> : null}
                        </div>
                    </GroupListToolbar>
                </DefaultWhiteColumn>
            </div>,
            listContent,
        ];
    }

    renderListContent() {
        const { canUpdateGroups, canDeleteGroups, groups, selectedItems, selectedAll,
            onSelectItem, onSelectAll, onShowItem, onEditGroup, onDeleteGroup } = this.props;
        return [
            <div key={'listContent'} className='row'>
                <DefaultWhiteColumn className='padding-bottom-5'>
                    <GroupListTable
                        canUpdateGroups={canUpdateGroups}
                        canDeleteGroups={canDeleteGroups}
                        groups={groups}
                        selectedItems={selectedItems}
                        selectedAll={selectedAll}
                        onSelectItem={onSelectItem}
                        onSelectAll={onSelectAll}
                        onShowItem={onShowItem}
                        onEditGroup={onEditGroup}
                        onDeleteGroup={onDeleteGroup}/>
                </DefaultWhiteColumn>
            </div>,
        ];
    }

    renderCardsContent() {
        const { canReadGroups } = this.props;
        if (!canReadGroups) {
            return <FormattedMessage key={'notAllowed'} id='intl-msg:accessNotAllowed'/>;
        }
        const { canUpdateGroups, canDeleteGroups, groups, selectedItems, selectedAll,
            onSelectItem, onSelectAll, onShowItem, onEditGroup, onDeleteGroup } = this.props;

        return [
            <GroupCards
                canUpdateGroups={canUpdateGroups}
                canDeleteGroups={canDeleteGroups}
                groups={groups}
                selectedItems={selectedItems}
                selectedAll={selectedAll}
                onSelectItem={onSelectItem}
                onSelectAll={onSelectAll}
                onShowItem={onShowItem}
                onEditGroup={onEditGroup}
                onDeleteGroup={onDeleteGroup}/>,
        ];
    }
}

export default GroupList;

GroupList.defaultProps = {
    // props
    canReadGroups: false,
    canCreateGroups: false,
    canUpdateGroups: false,
    canDeleteGroups: false,
    totalElements: 0,
    groups: [],
    groupsLoading: false,
    selectedItems: [],
    selectedAll: false,
    selectedCount: 0,
    showTableView: false,
    // functions
    onSelectItem: noop,
    onSelectAll: noop,
    onShowItem: noop,
    onCreateGroup: noop,
    onEditGroup: noop,
    onDeleteGroup: noop,
    onShowTableView: noop,
};

GroupList.propTypes = {
    // props
    canReadGroups: PropTypes.bool,
    canCreateGroups: PropTypes.bool,
    canUpdateGroups: PropTypes.bool,
    canDeleteGroups: PropTypes.bool,
    totalElements: PropTypes.number,
    groups: PropTypes.array,
    groupsLoading: PropTypes.bool,
    selectedItems: PropTypes.array,
    selectedAll: PropTypes.bool,
    selectedCount: PropTypes.number,
    showTableView: PropTypes.bool,
    // functions
    onSelectItem: PropTypes.func,
    onSelectAll: PropTypes.func,
    onShowItem: PropTypes.func,
    onCreateGroup: PropTypes.func,
    onEditGroup: PropTypes.func,
    onDeleteGroup: PropTypes.func,
    onShowTableView: PropTypes.func,
};
