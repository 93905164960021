import PropTypes from 'prop-types';
import React from 'react';

import {
    fileVersionsColumnDescriptors,
} from '~/features/deliverables/features/files/constants/fileColumnDescriptors';

import ListTable from '~/features/base/components/table/ListTable';

export const DeviceFileVersionsTable = ({ fileVersions }) => {
    if (fileVersions) {
        return [
            <ListTable items={fileVersions}
                       itemKey='fileVersionId'
                       columnDescriptors={fileVersionsColumnDescriptors}/>,
        ];
    }
};

export default DeviceFileVersionsTable;

DeviceFileVersionsTable.defaultProps = {
    fileVersions: [],
};

DeviceFileVersionsTable.propTypes = {
    // props
    fileVersions: PropTypes.array,
};
