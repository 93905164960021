import { call, put, select, fork, takeLatest } from 'redux-saga/effects';

import {
    FETCH_SYS_PARAM_DEVICE_VALUES,
} from '~/features/sysParams/actions/sysParamDeviceValueActions';
import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';
import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';
import { stringifyToQuery } from '~/features/base/utils/query';

import { doHandleErrorSaga, getHTTP } from '~/features/base/sagas/sagaUtil';
import { parseSysParamDeviceValues } from '~/features/sysParams/transforms/parseSysParamDeviceValues';
import { ENTITY_SYS_PARAM_DEVICE_VALUE } from '~/features/base/constants/entities';
import { mergeEntities } from '~/features/higherorder/actions/entityActions';
import { finishedAction, failedAction } from '~/features/higherorder/transforms/actionTransforms';

const entityName = ENTITY_SYS_PARAM_DEVICE_VALUE;

export function* getSysParamDeviceValuesURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/system-params/device-managed-params`;
}

export function* doFetchSysParamDeviceValuesSaga(action) {
    const serialNumber = action.payload.serialNumber;
    const shortBaseSwVersion = action.payload.shortBaseSwVersion;
    try {
        const sysParamDeviceValuesServiceURL = yield call(getSysParamDeviceValuesURL);
        const query = stringifyToQuery({
            hwSerial: serialNumber,
            swVersion: shortBaseSwVersion,
        });
        const response = yield call(getHTTP, `${sysParamDeviceValuesServiceURL}?${query}`);
        const entities = parseSysParamDeviceValues(response.content);
        yield put(mergeEntities(entities, { entityName, serialNumber, shortBaseSwVersion }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
        yield put(failedAction(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* fetchSysParamDeviceValuesSaga() {
    yield takeLatest(FETCH_SYS_PARAM_DEVICE_VALUES, doFetchSysParamDeviceValuesSaga);
}
