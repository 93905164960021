import { entityStoreSelector } from '~/features/higherorder/selectors/dataSelectors';
import {
    ENTITY_REPORTS_BILLING,
    ENTITY_REPORTS_BILLING_DETAILS,
} from '~/features/base/constants/entities';
import getOr from 'lodash/fp/getOr';

const reportsBillingEntitiesSelector = state => entityStoreSelector(state)[ENTITY_REPORTS_BILLING];
const reportsBillingDetailsEntitiesSelector = state => entityStoreSelector(state)[ENTITY_REPORTS_BILLING_DETAILS];

export const reportsBillingInitialDataSelector = (state) =>
    getOr([], 'chartsInitialData', reportsBillingEntitiesSelector(state));

export const reportsBillingDetailsInitialDataSelector = (state) =>
    getOr([], 'chartsInitialData', reportsBillingDetailsEntitiesSelector(state));

export const reportsBillingDataSelector = (state) =>
    getOr([], 'chartsData', reportsBillingEntitiesSelector(state));

export const reportsBillingVersionsSelector = (state) =>
    getOr([], 'versionsData', reportsBillingEntitiesSelector(state));
