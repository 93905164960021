import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';


import { selectionByValue } from '~/features/base/utils/selectUtils';
import { jiraProjectNameOptions, MDM } from '~/features/atlassian/constants/projectNameOptions';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';

export class JiraIssueProjectNameFormItem extends Component {

    onChange = (item) => {
        this.props.onChange(item.value);
    };

    render() {
        const { label, value } = this.props;
        const optionsWithSelection = selectionByValue(value.toString())(jiraProjectNameOptions);
        return (
            <div className='form-group'>
                <label className='control-label'>
                    <FormattedMessage id={label}/>
                </label>
                <Select options={optionsWithSelection}
                        onChange={this.onChange}/>
            </div>
        );
    }
}

export default JiraIssueProjectNameFormItem;

JiraIssueProjectNameFormItem.defaultProps = {
    // props
    label: 'intl-msg:jiraProject',
    value: '',
    showOptionAll: false,
    // functions
    onChange: noop,
};

JiraIssueProjectNameFormItem.propTypes = {
    // props
    label: PropTypes.string,
    value: PropTypes.string,
    showOptionAll: PropTypes.bool,
    // functions
    onChange: PropTypes.func,
};
