import {
    createApi,
} from '@reduxjs/toolkit/query/react';
import { VehicleDetailsView } from '~/api/models';
import { dynamicBaseQuery } from '~/api/common/dynamicBaseQuery';
import {VehicleGroup} from "~/api/models/vehicleGroup";
import {ResponseValueListSemanticVersion} from "~/api/models/response-value-list-semantic-version";
import {toShortSemanticVersion} from "~/features/base/utils/versionNumberConverter";

const baseUrl: string = 'v1/admin/vehicle-group';

export const vehicleGroupApi = createApi({
    reducerPath: 'vehicleGroupApi',
    baseQuery: dynamicBaseQuery,
    endpoints: (builder) => ({

        fetchVehicleGroups: builder.query<VehicleGroup[], {}>({
            query: () => ({
                url: `${baseUrl}`,
                method: 'GET',
            }),
            transformResponse: (data: {content: VehicleGroup[]}) => data.content,
        })
    }),
});

export const { useFetchVehicleGroupsQuery } = vehicleGroupApi;
