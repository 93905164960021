import React from 'react';
import size from 'lodash/fp/size';
import { FormattedTime } from 'react-intl';
import FieldOrNoData from '~/features/base/components/FieldOrNoData';
import SystemStateNotificationType from '~/features/systemState/components/SystemStateNotificationType';
import SystemStateNotificationTopCpu from '~/features/systemState/components/SystemStateNotificationTopCpu';

export const SYSTEM_STATE_COLUMN_SETTINGS_LOCAL_STORAGE_KEY = 'SYSTEM_STATE_COLUMN_SETTINGS_LOCAL_STORAGE_KEY';

export const systemStateNotificationsColumnDescriptors = [{
    id: 'timestamp',
    field: 'timestamp',
    label: 'intl-msg:time',
    selected: true,
    format: field => <FormattedTime value={field} year='numeric' month='2-digit' day='2-digit'/>,
}, {
    id: 'messageType',
    field: 'messageTypeEnum',
    label: 'intl-msg:controlDevice.systemstate.messageType',
    format: field => <SystemStateNotificationType value={field}/>,
}, {
    id: 'uptime',
    field: 'uptime',
    label: 'intl-msg:controlDevice.systemstate.uptime',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'average_cpu',
    field: 'average_cpu',
    label: 'intl-msg:controlDevice.systemstate.average_cpu',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'cpu_usage',
    field: 'cpu_usage',
    label: 'intl-msg:controlDevice.systemstate.cpu_usage',
    format: (field, item) => <SystemStateNotificationTopCpu field={field} topProcesses={item && item.topCpuProcesses}/>,
}, {
    id: 'cpu0_usage',
    field: 'cpu0_usage',
    label: 'intl-msg:controlDevice.systemstate.cpu0_usage',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'cpu1_usage',
    field: 'cpu1_usage',
    label: 'intl-msg:controlDevice.systemstate.cpu1_usage',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'topCpu_processName',
    field: 'topCpuProcesses',
    label: 'intl-msg:controlDevice.systemstate.topCpu_processName',
    format: (field, item) => <FieldOrNoData field={size(item.topCpuProcesses) > 0 && item.topCpuProcesses[0].name}/>,
}, {
    id: 'topCpu_usage',
    field: 'topCpuProcesses',
    label: 'intl-msg:controlDevice.systemstate.topCpu_usage',
    format: (field, item) => <FieldOrNoData field={size(item.topCpuProcesses) > 0 && item.topCpuProcesses[0].cpu_usage}/>,
}, {
    id: 'topCpu_avgUsage',
    field: 'topCpuProcesses',
    label: 'intl-msg:controlDevice.systemstate.topCpu_avgUsage',
    format: (field, item) => <FieldOrNoData field={size(item.topCpuProcesses) > 0 && item.topCpuProcesses[0].average_cpu}/>,
}, {
    id: 'loadavg_1',
    field: 'loadavg_1',
    label: 'intl-msg:controlDevice.systemstate.loadavg_1',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'loadavg_5',
    field: 'loadavg_5',
    label: 'intl-msg:controlDevice.systemstate.loadavg_5',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'loadavg_15',
    field: 'loadavg_15',
    label: 'intl-msg:controlDevice.systemstate.loadavg_15',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'mem_usage',
    field: 'mem_usage',
    label: 'intl-msg:controlDevice.systemstate.mem_usage',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'mem_free',
    field: 'mem_free',
    label: 'intl-msg:controlDevice.systemstate.mem_free',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'processLogLevels',
    field: 'processLogLevels',
    label: 'intl-msg:controlDevice.systemstate.processLogLevels',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'restartedProcesses',
    field: 'restartedProcesses',
    label: 'intl-msg:controlDevice.systemstate.restartedProcesses',
    format: (field, item) => <FieldOrNoData
        field={size(item.restartedProcesses) > 0 && item.restartedProcesses[0].name}/>,
}, {
    id: 'processesNotRunning',
    field: 'processesNotRunning',
    label: 'intl-msg:controlDevice.systemstate.processesNotRunning',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'system_state_version',
    field: 'system_state_version',
    label: 'intl-msg:controlDevice.systemstate.system_state_version',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'base_sw_version',
    field: 'base_sw_version',
    label: 'intl-msg:controlDevice.systemstate.base_sw_version',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'emmcHealthInfo_user',
    field: 'emmcHealthInfo',
    label: 'intl-msg:controlDevice.systemstate.emmcHealthInfo_user',
    format: field => <FieldOrNoData field={field ? field.user : ''}/>,
}, {
    id: 'emmcHealthInfo_system',
    field: 'emmcHealthInfo',
    label: 'intl-msg:controlDevice.systemstate.emmcHealthInfo_system',
    format: field => <FieldOrNoData field={field ? field.system : ''}/>,
}, {
    id: 'disc_usage',
    field: 'disc_usage',
    label: 'intl-msg:controlDevice.systemstate.disc_usage',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'disc_usage_common',
    field: 'disc_usage_common',
    label: 'intl-msg:controlDevice.systemstate.disc_usage_common',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'disc_usage_handover',
    field: 'disc_usage_handover',
    label: 'intl-msg:controlDevice.systemstate.disc_usage_handover',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'boot_bank_id',
    field: 'boot_bank_id',
    label: 'intl-msg:controlDevice.systemstate.boot_bank_id',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'boot_bank_switch_reason',
    field: 'boot_bank_switch_reason',
    label: 'intl-msg:controlDevice.systemstate.boot_bank_switch_reason',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'partition',
    field: 'partition',
    label: 'intl-msg:controlDevice.systemstate.partition',
    format: field => <FieldOrNoData field={field}/>,
}];
