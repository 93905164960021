import noop from 'lodash/fp/noop';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import size from 'lodash/fp/size';
import map from 'lodash/fp/map';
import _ from 'lodash';
import classNames from 'classnames';
import { FETCH_DELIVERABLES_AMOUNT_INCREMENT } from '~/features/deliverables/constants/deliverablesParameters';
import DeliverableColumnMenu from '~/features/deliverables/components/DeliverableColumnMenu';
import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';
import { FormattedMessage } from 'react-intl';
import uid from '~/features/base/utils/uid';
import { getDeliverableId } from '~/features/deliverables/utils/utils';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import DeliverableIdPrefixFormItem from '~/features/base/components/forms/DeliverableIdPrefixFormItem';
import defaults from 'lodash/fp/defaults';
import { parseQuery } from '~/features/base/utils/query';
import { defaultFilters } from '~/features/deliverables/utils/defaultFilters';
import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';
import { followRoute } from '~/features/base/actions/ui/routeActions';
import { connect } from 'react-redux';
import { showDeliverableEditorDialog } from '~/features/deliverables/actions/deliverableActions';
import isEmpty from 'lodash/fp/isEmpty';

export class DeliverableIdList extends Component {
    constructor(props) {
        super(props);
        this.name = uid();

    }

    onSearchFilterChange = ({ deliverableIdPrefix }) => {
        this.updateFilter({
            deliverableIdPrefix,
        });
    };

    onCreateDeliverableId = (event) => {
        const deliverableId = event.currentTarget.getAttribute('data-key-deliverable-id');
        this.props.onShowDeliverableEditorDialog({
            isNew: true,
            isDeliverableVersion: false,
            deliverableId,
        });
    };

    updateFilter(filters) {
        let { pathname, search, match } = this.props;
        const query = parseQuery(search);
        ['deliverableIdPrefix', 'context', 'releaseState'].map((prop) => {
            if (filters[prop]) {
                query[prop] = filters[prop] === 'all' ? undefined : filters[prop];
            }
        });
        const { deliverableId, deliverableVersion } = match.params;
        if (!isEmpty(deliverableVersion)) {
            pathname = pathname.replace(`/${deliverableVersion}`, '/');
        }
        if (!isEmpty(deliverableId) || filters['deliverableIdPrefix']) {
            pathname = pathname.replace(`/${deliverableId}/`, '');
        }

        this.props.followRoute({
            route: pathname, query: {
                ...query,
                ...filters,
                page: 0,
                size: FETCH_DELIVERABLES_AMOUNT_INCREMENT,
            },
        });
    }

    render() {
        const {
            deliverableIds,
            deliverableIdsLoading,
            deliverableType,
            selectedDeliverableId,
            tableClassNames,
            search,
            deliverables,
        } = this.props;
        const parsedQuery = parseQuery(search);
        const defaultedQuery = defaults(defaultFilters, parsedQuery);
        const selectedDeliverableIdChecked = deliverableIds && getDeliverableId(deliverableIds,
            selectedDeliverableId);
        const tip = this.props.getDeliverableTooltipText(deliverableType);
        const showLoadMore = !!this.props.deliverableIds && (this.props.deliverableIds.length
            < this.props.deliverableIdsTotalCount);

        const deliverablesLoadedType = deliverables && deliverables[0].deliverableType;
        const isSameDeliverableLoadedType = !deliverablesLoadedType?.toLowerCase() || deliverablesLoadedType?.toLowerCase() === deliverableType?.toLowerCase();
        return (
            <div className='col-md-2'>
                <div className={classNames('bg-white')}>
                    <div className='panel panel-primary margin-bottom-25'>
                        <div className='panel-heading height-50'>
                            <div className='col-md-8 text-size-16 padding-top-5'>
                                {this.props.getDeliverableLabelName(deliverableType)}
                                {` (${this.props.deliverableIdsTotalCount})`}
                            </div>
                            <div className='col-md-4 text-right'>
                                <OverlayTrigger
                                    placement='top'
                                    overlay={
                                        <Tooltip id='tooltip' className='top-right'>
                                            {tip}
                                        </Tooltip>
                                    }
                                >
                                    <button type='button' className='btn btn-default width-50'
                                            onClick={this.onCreateDeliverableId}>
                                        <span className={'rioglyph rioglyph-plus margin-0'}></span>
                                    </button>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className='table-toolbar-group-right padding-10'>
                            <div className='table-toolbar-column'>
                                <DeliverableIdPrefixFormItem value={defaultedQuery.deliverableIdPrefix}
                                                             onChange={this.onSearchFilterChange}
                                                             showHeader={false}/>
                            </div>
                        </div>
                        <div>
                            {size(deliverableIds) > 0 && isSameDeliverableLoadedType ?
                                <div className='overflow-y-scroll max-height-75vh padding-bottom-50'>
                                    <table className={tableClassNames}>
                                        <tbody>
                                        {this.renderDeliverableRows(deliverableIds, deliverableType,
                                            selectedDeliverableIdChecked)}
                                        </tbody>
                                    </table>
                                    {showLoadMore &&
                                        <DefaultWhiteColumn className='text-center padding-bottom-20'>
                                            <a id='load-more-button'
                                               className='btn btn-default'
                                               onClick={this.props.onLoadMore}>
                                                <FormattedMessage id='intl-msg:loadMore'/>
                                            </a>
                                        </DefaultWhiteColumn>}
                                </div>
                                : !deliverableIdsLoading && isSameDeliverableLoadedType ?
                                    <NotFoundState headline='Nothing found' message='Please refine your search'/>
                                    : <div className='padding-20 text-center'>
                                        <div className='display-inline-block'>
                                            <Spinner/>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderDeliverableRows(deliverableIds, deliverableType, selectedDeliverableId) {
        return map(deliverableId => {
            const items = [];

            return (
                <tr key={uid()} className={(selectedDeliverableId === deliverableId) ? 'active' : undefined}
                    onClick={() => {
                        this.props.onIdSelect(deliverableType, deliverableId);
                    }}>
                    <td>
                        <div>
                            <h6>
                                {deliverableId}
                            </h6>
                        </div>
                    </td>
                    {size(items) > 0 &&
                        <td>
                            <DeliverableColumnMenu items={items}></DeliverableColumnMenu>
                        </td>
                    }
                </tr>
            );
        }, deliverableIds);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps.deliverableType !== this.props.deliverableType) {
            return true;
        }
        if (!_.isEqual(nextProps.deliverableIds, this.props.deliverableIds)) {
            if (nextProps.deliverableIds === null && this.props.deliverableIds !== null) {
                return false;
            }
            return true;
        }

        if (nextProps.selectedDeliverableId !== this.props.selectedDeliverableId) {
            return true;
        }

        if (nextProps.deliverableIdsLoading !== this.props.deliverableIdsLoading) {
            return true;
        }

        return false;
    }
}

const mapStateToProps = (state) => ({
    pathname: pathnameSelector(state),
    search: searchSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    onShowDeliverableEditorDialog: (options) => {
        dispatch(showDeliverableEditorDialog(options));
    },
    followRoute: (options) => {
        dispatch(followRoute(options));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableIdList);

DeliverableIdList.defaultProps = {
    // props
    deliverableIdsLoading: false,
    pathname: '',
    search: '',
    // functions
    onShowDeliverableEditorDialog: noop,
    followRoute: noop,
    triggerDataFetcher: noop,
    registerDataInterest: noop,
};

DeliverableIdList.propTypes = {
    // props
    deliverableIdsLoading: PropTypes.bool,
    pathname: PropTypes.string,
    search: PropTypes.string,
    // functions
    onShowDeliverableEditorDialog: PropTypes.func,
    followRoute: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
    registerDataInterest: PropTypes.func,
};
