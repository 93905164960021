export const FETCH_CONTROL_DEVICE_REFERENCED_DELIVERABLES = 'FETCH_CONTROL_DEVICE_REFERENCED_DELIVERABLES';
export const SHOW_CONTROL_DEVICE_REFERENCED_DELIVERABLES = 'SHOW_CONTROL_DEVICE_REFERENCED_DELIVERABLES';

export function fetchControlDeviceReferencedDeliverables(serialNumber, mode) {
    return {
        type: FETCH_CONTROL_DEVICE_REFERENCED_DELIVERABLES,
        payload: {
            serialNumber,
            mode,
        },
    };
}

/**
 * Action creator for showing control device referenced artifacts dialog
 * @param serialNumber of device
 * @returns {{type: string}} The action for showing control device quick whitelisting dialog
 */
export function showControlDeviceAssociatedDeliverables(serialNumber) {
    return {
        type: SHOW_CONTROL_DEVICE_REFERENCED_DELIVERABLES,
        serialNumber,
    };
}
