import compact from 'lodash/fp/compact';

import React from 'react';
import { FormattedMessage, FormattedNumber, FormattedTime } from 'react-intl';
import DeviceRolloutState from '~/features/devices/components/DeviceRolloutState';
import DeviceRegistrationMethod from '~/features/devices/components/DeviceRegistrationMethod';
import DeviceOffline from '~/features/devices/components/DeviceOffline';

import ShortBaseSwVersion from '~/features/baseSwVersions/components/ShortBaseSwVersion';
import DistroChip from '~/features/deliverables/components/DistroChip';
import GroupChipList from '~/features/groups/components/GroupChipList';
import YesOrNo from '~/features/base/components/YesOrNo';
import FieldOrNoData from '~/features/base/components/FieldOrNoData';

import { env } from '~/env';
import { DeviceTypeLabel } from '~/features/devices/components/DeviceType';
import { TBM3, VCM } from '~/features/devices/constants/deviceTypes';
import classNames from 'classnames';
import { updateOutcome } from '~/features/devices/constants/deviceRolloutStates';
import { copyToClipboard } from '~/features/apps/utils/appsUtils';
import DeviceChip from '~/features/devices/components/DeviceChip';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

const { networkCodes } = env.runtimeConfig;
const { showRolloutState } = env.featureToggles;

/**
 * TODO Move somewhere else, be it data, together with tiles.
 */
export const deviceRowDescriptors = compact([{
//     field: 'serialNumber',
//     label: 'intl-msg:hwSerialLinkedSerialCombined',
//     format: (field, controlDevice) => [
//         controlDevice.linkedSerial ?
//             <div>
//                 <span className='margin-right-5'>→</span>
//                 <DeviceTypeLabel deviceType={controlDevice.type === TBM3 ? VCM : TBM3}/>
//                 <DeviceChip serialNumber={controlDevice.linkedSerial} className='clickable'/>
//                 <span className='rioglyph rioglyph-duplicate text-color-dark text-size-15 padding-left-5'
//                       onClick={() => copyToClipboard(controlDevice.linkedSerial, true)}></span>
//             </div>
//             : <YesOrNo/>,
//     ],
// }, {
//     field: 'vehicleGroupNames',
//     label: 'intl-msg:groups',
//     format: field => <GroupChipList groups={field}/>,
// }, {
    field: 'lastDistroVersionReported',
    formatLabel: () => [
        // REACT16 Should now be possble with Fragments
        <FormattedMessage id='intl-msg:distroVersion'/>,
        <FormattedMessage id='intl-msg:lastReportedToTarget'/>,
    ],
    format: (field, controlDevice) => {
        return (
            <span className='white-space-nowrap'>
                <DistroChip distroVersion={field}/>
                {
                    controlDevice.targetDistroRelease ? [
                        <span className='margin-left-5 margin-right-5'>→</span>,
                        <DistroChip distroVersion={controlDevice.targetDistroRelease}/>,
                    ] : null
                }
            </span>
        );
    },
}, {
    field: 'lastBaseSoftwareVersionReported',
    formatLabel: () => [
        <FormattedMessage id='intl-msg:baseSoftwareVersion'/>,
        <FormattedMessage id='intl-msg:lastReportedToTarget'/>,
    ],
    format: (field, controlDevice) => {
        return (
            <span className='white-space-nowrap'>
                <ShortBaseSwVersion baseSwVersion={field}/>
                {
                    controlDevice.targetBaseSoftwareRelease ? [
                        <span className='margin-left-5 margin-right-5'>→</span>,
                        <ShortBaseSwVersion baseSwVersion={controlDevice.targetBaseSoftwareRelease}/>,
                    ] : null
                }
            </span>
        );
    },
}, {
    field: 'lastCheckForUpdateTimestamp',
    label: 'intl-msg:lastCheckForUpdateTimestamp',
    format: field => {
        return (
            <span className='white-space-nowrap'>
                <FieldOrNoData field={field ? <FormattedTime value={field} year='numeric'
                                                             month='2-digit' day='2-digit'/> : null}/>
                <DeviceOffline timestamp={field}/>
            </span>
        );
    },
}, {
    field: 'lastRolloutState',
    label: 'intl-msg:lastRolloutState',
    format: field => {
        try {
            if (Array.isArray(field)) {

                return (
                    field.map(rolloutState => {
                        if (rolloutState.type.startsWith("PACKAGE_")) {
                            return <div>
                                <FormattedMessage id={updateOutcome[rolloutState.outcome].tooltip}/>:
                                [{rolloutState.deliverableId}@<ShortBaseSwVersion
                                baseSwVersion={rolloutState.version}/>]
                            </div>
                        } else {
                            return <div>
                                <FormattedMessage id={updateOutcome[rolloutState.outcome].tooltip}/>:
                                [{rolloutState.deliverableId}@<ShortBaseSwVersion
                                baseSwVersion={rolloutState.baseSoftwareVersion}/>]
                            </div>
                        }
                    })
                );
            } else {
                return <FieldOrNoData field={field}/>;
            }
        } catch (e) {
            return <FormattedMessage id={'intl-msg:error.somethingWentWrong'}/>;
        }
    },
}, {
    field: 'vehicleManufacturerSparePartNumber',
    label: 'intl-msg:manufacturerSparePartNumber',
    format: field => <FieldOrNoData field={field}/>,
// }, {
//     field: 'assetId',
//     label: 'intl-msg:assetId',
//     format: field => <FieldOrNoData field={field}/>,
// }, {
//     field: 'lastFleetEvent',
//     label: 'intl-msg:lastFleetEvent',
//     format: field => <FieldOrNoData field={field ? <FormattedTime value={field} year='numeric'
//                                                                   month='2-digit' day='2-digit'/> : null}/>,
}, {
    field: 'registrationMethod',
    label: 'intl-msg:registrationMethod',
    format: field => <DeviceRegistrationMethod registrationMethod={field}/>,
}, {
    field: 'registrationTimestamp',
    label: 'intl-msg:registrationTimestamp',
    format: field => <FieldOrNoData field={field ? <FormattedTime value={field} year='numeric'
                                                                  month='2-digit' day='2-digit'/> : null}/>,
}, {
    field: 'bootBank',
    label: 'intl-msg:bootBank',
    format: field => <FieldOrNoData field={field}/>,
}, {
    field: 'bootCounter',
    label: 'intl-msg:bootCounter',
    format: field => <FieldOrNoData field={field}/>,
}, showRolloutState && {
    field: 'rolloutState',
    label: 'intl-msg:rolloutState',
    format: field => <DeviceRolloutState rolloutState={field}/>,
}]);
