import React from 'react';
import classNames from 'classnames';

import {FormattedMessage} from 'react-intl';

import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import {reasonsForChange} from "~/features/base/constants/reasonForChange";

interface ReasonForChangeProps {
    reasonForChange: string;
    className: string;
}

const ReasonForChange = ({reasonForChange, className}: ReasonForChangeProps) => {
    if (!reasonForChange) {
        return null;
    }
    return (
        <OverlayTrigger placement='top' overlay={
            <Tooltip id='tooltip' className='top-right'>
                <FormattedMessage id={reasonsForChange[reasonForChange]?.tooltip}/>
            </Tooltip>}>
            <span className={classNames('label label-condensed', reasonsForChange[reasonForChange]?.className, className)}>
                <FormattedMessage id={reasonsForChange[reasonForChange]?.label}/>
            </span>
        </OverlayTrigger>
    );
};

export default ReasonForChange;
