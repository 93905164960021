import classNames from 'classnames';

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const DeviceProductionModeLabel = () => {
    return (
        <span>
            <span className={classNames('label label-condensed margin-right-5', 'label-danger')}>
                    <FormattedMessage id={'intl-msg:deviceProdMode'}/>
                </span>
        </span>
    );
};

export const DeviceProductionMode = ({ controlDevice }) => {
    if (!controlDevice || !controlDevice.productionMode) {
        return null;
    }
    return <DeviceProductionModeLabel/>;
};

export default DeviceProductionMode;

DeviceProductionMode.defaultProps = {};

DeviceProductionMode.propTypes = {
    // props
    controlDevice: PropTypes.object,
};
