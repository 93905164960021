
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const ACTIVE = 'ACTIVE';
export const COMPLETED = 'COMPLETED';
export const FAILED = 'FAILED';
export const OPEN = 'OPEN';

export const rolloutStates = {
    [ACTIVE]: {
        id: ACTIVE,
        label: 'Active',
        icon: <span className='rioglyph rioglyph-record padding-right-10'></span>,
        className: 'bg-rating-4',
    },
    [COMPLETED]: {
        id: COMPLETED,
        label: 'Completed',
        icon: <span className='rioglyph rioglyph-ok padding-right-10'></span>,
        className: 'bg-rating-5',
    },
    [FAILED]: {
        id: FAILED,
        label: 'Failed',
        icon: <span className='rioglyph rioglyph-flash padding-right-10'></span>,
        className: 'bg-rating-1',
    },
    [OPEN]: {
        id: OPEN,
        label: 'Open',
        icon: <span className='rioglyph rioglyph-stopover padding-right-10'></span>,
        className: 'bg-gray',
    },
};

export default rolloutStates;
