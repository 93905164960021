/**
 * Action type for fetching control device operations
 * @type {string}
 */
export const FETCH_CONTROL_DEVICE_OPERATIONS = 'FETCH_CONTROL_DEVICE_OPERATIONS';

/**
 * Action creator for fetching of a control device operations
 * @param {object} options The options for fetching the control device operations
 * @param {string} options.serialNumber The serialNumber of the control device
 * @param {number} options.page The serialNumber of the control device
 * @param {number} options.size The serialNumber of the control device
 * @returns {{type: string, payload: object}} The action for fetching of a control device operations
 */
export function fetchControlDeviceOperations(searchCriteria) {
    return {
        type: FETCH_CONTROL_DEVICE_OPERATIONS,
        payload: {
            searchCriteria
        },
    };
}
