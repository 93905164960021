import compact from 'lodash/fp/compact';

import React from 'react';
import { FormattedMessage, FormattedNumber, FormattedTime } from 'react-intl';
import DeviceRolloutState from '~/features/devices/components/DeviceRolloutState';
import DeviceRegistrationMethod from '~/features/devices/components/DeviceRegistrationMethod';
import DeviceOffline from '~/features/devices/components/DeviceOffline';

import ShortBaseSwVersion from '~/features/baseSwVersions/components/ShortBaseSwVersion';
import GroupChipList from '~/features/groups/components/GroupChipList';
import FieldOrNoData from '~/features/base/components/FieldOrNoData';

import { env } from '~/env';
import classNames from 'classnames';
import { updateOutcome } from '~/features/devices/constants/deviceRolloutStates';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import some from 'lodash/fp/some';
import LastReportedBundle from '~/features/devices/components/LastReportedBundle';
import {copyToClipboard} from "~/features/apps/utils/appsUtils";
const { networkCodes } = env.runtimeConfig;
const { showRolloutState } = env.featureToggles;

/**
 * TODO Move somewhere else, be it data, together with tiles.
 */
export const cm4DeviceRowDescriptors = compact([
{
    field: 'hwVariant',
    label: 'intl-msg:hwVariant',
    format: field => <FieldOrNoData field={field}/>,
}, {
    field: 'vehicleVariant',
    label: 'intl-msg:vehicleVariant',
    format: field => <FieldOrNoData field={field}/>,
}, {
    field: 'vehicleGroupNames',
    label: 'intl-msg:groups',
    format: field => <GroupChipList groups={field}/>,
}, {
    field: 'lastCheckForUpdateTimestamp',
    label: 'intl-msg:lastCheckForUpdateTimestamp',
    format: field => {
        return (
            <span className='white-space-nowrap'>
                <FieldOrNoData field={field ? <FormattedTime value={field} year='numeric'
                                                             month='2-digit' day='2-digit'/> : null}/>
                <DeviceOffline timestamp={field}/>
            </span>
        );
    },
}, {
    field: 'lastNetworkCodeReported',
    label: 'intl-msg:deviceNetworkLocation',
    format: (field, data) => {
        let lastNetworkReportedCountry;
        let countryCode;
        if (field) {
            countryCode = parseInt(field.split(' ')[0], 10);
            lastNetworkReportedCountry = networkCodes[countryCode]?.name || '';
        }
        const validNetworkCode = some((networkCode) => parseInt(networkCode.replace(/\D/g, ''), 10) === countryCode, data.networkCodeList);
        return (
            <span>
                {
                    lastNetworkReportedCountry ? lastNetworkReportedCountry
                        : <FormattedMessage id='intl-msg:networkCountryCode' values={{ countryCode: countryCode }}/>
                }
                <OverlayTrigger placement='top' overlay={

                    <Tooltip id='tooltip' className='top-right'>
                        {
                            countryCode && validNetworkCode ?
                                <FormattedMessage id='intl-msg:deviceNetworkCanUseServices'/>
                                :
                                <FormattedMessage id='intl-msg:report.reason.disallowedNetwork'/>
                        }
                    </Tooltip>}>
                            <span className={classNames('label-condensed margin-right-5')}>
                            {
                                countryCode && validNetworkCode ?
                                    <span
                                        className='text-success rioglyph rioglyph-ok-sign margin-right-10 text-size-16'/>
                                    :
                                    <span
                                        className='text-danger rioglyph rioglyph-error-sign margin-right-10 text-size-16'/>
                            }
                            </span>
                        </OverlayTrigger>
            </span>
        );
    },
}, {
    field: 'lastMileageReported',
    label: 'intl-msg:mileageWithUnit',
    format: field => {
        return field ? <FieldOrNoData field={<FormattedNumber value={field}/>}/> : <FieldOrNoData field={field}/>;
    },
}, {
    field: 'description',
    label: 'intl-msg:description',
    format: field => <FieldOrNoData field={field}/>,
}, {
    field: 'lastRolloutState',
    label: 'intl-msg:lastRolloutState',
    format: field => {
        try {
            if (Array.isArray(field)) {

                return (
                    field.map(rolloutState => {
                        return <div>
                            <FormattedMessage id={updateOutcome[rolloutState.outcome].tooltip}/>:
                            [{rolloutState.deliverableId}@<ShortBaseSwVersion
                            baseSwVersion={rolloutState.version}/>]
                        </div>;
                    })
                );
            } else {
                return <FieldOrNoData field={field}/>;
            }
        } catch (e) {
            return <FormattedMessage id={'intl-msg:error.somethingWentWrong'}/>;
        }
    },
}, {
        field: 'ivd',
        label: 'intl-msg:ivd',
        labelTooltip: (
            <OverlayTrigger
                key={1}
                placement={'right-start'}
                overlay={
                    <Tooltip id='tooltip' allowOnTouch>
                        <FormattedMessage id='intl-msg:ivdTooltip'/>
                    </Tooltip>
                }
            >
                <span className="rioglyph rioglyph-info-sign margin-left-10 label-info" style={{fontSize: '18px'}} aria-hidden="true"></span>
            </OverlayTrigger>
        ),
        format: field => {
            if (field) {
                let displayValue = field
                if (field.length > 40) {
                    displayValue = field.substring(0, 10) + "...." + field.substring(field.length - 10, field.length)
                }
                return (
                    <div>
                        <FieldOrNoData field={displayValue}/>
                        <OverlayTrigger placement='top' overlay={
                            <Tooltip id='tooltip' className='top-right'>
                                <FormattedMessage id='intl-msg:copyIvd'/>
                            </Tooltip>}>
                            <span onClick={() => copyToClipboard(field, true)}
                                  className='rioglyph rioglyph-duplicate text-color-dark text-size-20 padding-left-5'>
                            </span>
                        </OverlayTrigger>
                    </div>
                )
            } else {
                return <FieldOrNoData field={field}/>;
            }
        }
    }, {
        field: 'lastEssentialImageReported',
        label: 'intl-msg:lastReportedEssentialImage',
        format: (field, data) => {
            return <LastReportedBundle serialNumber={data.serialNumber} deliverableId={field?.id}
                                       currentVersion={field?.version}/>;
        },
    },{
    field: 'vehicleManufacturerSparePartNumber',
    label: 'intl-msg:manufacturerSparePartNumber',
    format: field => <FieldOrNoData field={field}/>,
}, {
    field: 'vin',
    label: 'intl-msg:VIN',
    format: field => <FieldOrNoData field={field}/>,
}, {
    field: 'assetId',
    label: 'intl-msg:assetId',
    format: field => <FieldOrNoData field={field}/>,
}, {
    field: 'lastFleetEvent',
    label: 'intl-msg:lastFleetEvent',
    format: field => <FieldOrNoData field={field ? <FormattedTime value={field} year='numeric'
                                                                  month='2-digit' day='2-digit'/> : null}/>,
}, {
    field: 'registrationMethod',
    label: 'intl-msg:registrationMethod',
    format: field => <DeviceRegistrationMethod registrationMethod={field}/>,
}, {
    field: 'registrationTimestamp',
    label: 'intl-msg:registrationTimestamp',
    format: field => <FieldOrNoData field={field ? <FormattedTime value={field} year='numeric'
                                                                  month='2-digit' day='2-digit'/> : null}/>,
}, showRolloutState && {
    field: 'rolloutState',
    label: 'intl-msg:rolloutState',
    format: field => <DeviceRolloutState rolloutState={field}/>,
}]);
