import { isSemanticVersionZero } from '~/features/base/utils/versionNumberConverter';

export const filterEmptyDependencies = deliverables => {
    if (deliverables && deliverables.length) {
        deliverables.forEach(((deliverable, i) => {
            if (deliverable.versionRange?.minValue && isSemanticVersionZero(deliverable.versionRange?.minValue)) {
                delete deliverable.versionRange['minValue'];
            }
            if (deliverable.versionRange?.maxValue && isSemanticVersionZero(deliverable.versionRange?.maxValue)) {
                delete deliverable.versionRange['maxValue'];
            }
            if (!deliverable.versionRange.minValue && !deliverable.versionRange.maxValue) {
                //Remove element from array if all fields are empty
                if (!deliverable.deliverableId) {
                    deliverables.splice(i, 1);
                } else {
                    //Delete version range if there is no min and max versions
                    delete deliverable['versionRange'];
                }
            }
        }));
    }
    if (deliverables?.length === 0) {
        deliverables = null;
    }
};
