import PropTypes from 'prop-types';

import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedNumber } from 'react-intl';

import Tile from '~/features/overview/components/Tile';
import { stringifyToQuery } from '~/features/base/utils/query';

const labelWrapperClasses = 'position-absolute bottom-25 padding-left-20 padding-right-20 bg-darkest';
const labelClasses = 'text-size-20 text-bold text-uppercase text-color-white';

export const NumberTile = ({ label, value, tileDescriptor }) => {
    return (
        <Tile>
            <Link to={`${tileDescriptor.link}?${stringifyToQuery(tileDescriptor.query)}`}>
                <div data-name={name} className='cursor-pointer bg-white bg-tile height-500'>
                    <div className='display-flex justify-content-center align-items-center height-100pct'>
                        <span className='text-size-18 text-bold text-color-darkest'>
                            <span className='text-size-200pct'><FormattedNumber value={value}/></span>
                        </span>
                    </div>
                    <div className='position-relative'>
                        <div className={labelWrapperClasses}>
                            <span className={labelClasses}>{label}</span>
                        </div>
                    </div>
                </div>
            </Link>
        </Tile>
    );
};

export default NumberTile;

NumberTile.propTypes = {
    // props
    label: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
    ]),
    value: PropTypes.number,
    tileDescriptor: PropTypes.object,
};
