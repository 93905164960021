import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

export const DefaultWhiteColumn = ({ className, children }) => {
    return (
        <div className='col-12'>
            <div className={classNames('bg-white padding-left-20 padding-right-20', className)}>
                {children}
            </div>
        </div>
    );
};

export default DefaultWhiteColumn;

DefaultWhiteColumn.propTypes = {
    // props
    className: PropTypes.string,
};
