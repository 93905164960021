export const PROJECT_MDM = 'PROJECT_MDM';
export const PROJECT_MDMOPS = 'PROJECT_MDMOPS';
export const PROJECT_MTBDPC = 'PROJECT_MTBDPC';
export const PROJECT_RIOBF = 'PROJECT_RIOBF';
export const MDM = 'MDM';
export const MDMOPS = 'MDMOPS';
export const MTBDPC = 'MTBDPC';
export const RIOBF = 'RIOBF';

export const jiraProjectNameOptions = [{
    id: PROJECT_MDM,
    label: 'MDM',
    value: 'MDM',
}, {
    id: PROJECT_MDMOPS,
    label: 'MDMOPS',
    value: 'MDMOPS',
}, {
    id: PROJECT_MTBDPC,
    label: 'MTBDPC',
    value: 'MTBDPC',
// }, {
//     id: PROJECT_RIOBF,
//     label: 'RIOBF',
//     value: 'RIOBF',
}];
