import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import {
    addContextOwner,
    addNewContext,
    changeDeliverableEditor,
    createDeliverable,
    removeContextOwner,
    updateDeliverable,
} from '~/features/deliverables/actions/deliverableActions';
import {
    deliverableEditorSelector,
    deliverablePermissionSelector,
    deliverablesContextsSelector,
    selectedContextOwnersSelector,
} from '~/features/deliverables/selectors/deliverableSelectors';
import DeliverablePermissionForm from '~/features/deliverables/components/DeliverablePermissionForm';
import { canFullyManageOwnerContextSelector } from '~/features/user/selectors/permissionSelectors';
import { fetchContextDetails } from '~/features/deliverables/features/artifacts/actions/artifactActions';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';

/**
 * Deliverable permission management dialog
 */
export class DeliverablePermissionDialog extends PureComponent {
    constructor() {
        super();
        this.state = {
            selectedContextName: '',
            newContextName: '',
        };
    }

    onUpdateNewOwnerName = (newOwnerName) => {
        this.setState({ newOwnerName });
    };

    onSelectContextName = (event) => {
        const contextName = event.currentTarget.getAttribute('data-key');
        this.props.fetchContextDetails(contextName);
        this.setState({ selectedContextName: contextName });
    };

    onRemoveOwner = (event) => {
        const { selectedContextName } = this.state;
        const ownerName = event.currentTarget.getAttribute('data-key');
        this.props.removeContextOwner(selectedContextName, ownerName);
    };

    onAddOwner = () => {
        const { selectedContextName, newOwnerName } = this.state;
        this.props.addContextOwner(selectedContextName, newOwnerName);
    };

    onUpdateNewContextName = (newContextName) => {
        this.setState({ newContextName });
    };

    onAddNewContext = () => {
        const { newContextName } = this.state;
        this.props.addNewContext(newContextName);
    };

    render() {
        const { contexts } = this.props;
        const { selectedContextName } = this.state;
        const title = this.renderTitle();
        const body = this.renderBody(selectedContextName, contexts);
        const footer = this.renderFooter();
        return (
            <Dialog className='file-deliverable-editor-dialog'
                    show={true}
                    showCloseButton={true}
                    onHide={this.props.hideModal}
                    title={title}
                    body={body}
                    footer={footer}
                    useOverflow
            />
        );
    }

    renderTitle() {
        return <FormattedMessage id='intl-msg:managePermissionsDeliverable'/>;
    }

    renderBody(selectedContextName, contexts) {
        return (
            <DeliverablePermissionForm selectedContextName={selectedContextName}
                                       canFullyManageOwnerContext={this.props.canFullyManageOwnerContext}
                                       contexts={contexts}
                                       owners={this.props.selectedContextOwners}
                                       onSelectContextName={this.onSelectContextName}
                                       onAddNewContext={this.onAddNewContext}
                                       onUpdateNewContextName={this.onUpdateNewContextName}
                                       onUpdateNewOwnerName={this.onUpdateNewOwnerName}
                                       onAddOwner={this.onAddOwner}
                                       onRemoveOwner={this.onRemoveOwner}
            />
        );
    }

    renderFooter() {
        return (
            <div>
                <button className='btn btn-default' onClick={this.props.hideModal}>
                    <FormattedMessage id='intl-msg:close'/>
                </button>
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        model: deliverableEditorSelector(state),
        canFullyManageOwnerContext: canFullyManageOwnerContextSelector(state),
        permissions: deliverablePermissionSelector(state),
        contexts: deliverablesContextsSelector(state),
        selectedContextOwners: selectedContextOwnersSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        createDeliverable: (newDeliverable) => {
            dispatch(createDeliverable(newDeliverable));
        },
        updateDeliverable: (softwareVersion) => {
            dispatch(updateDeliverable(softwareVersion));
        },
        changeDeliverableEditor: (payload) => {
            dispatch(changeDeliverableEditor(payload));
        },
        addNewContext: (newContextName) => {
            dispatch(addNewContext(newContextName));
        },
        addContextOwner: (contextName, ownerName) => {
            dispatch(addContextOwner(contextName, ownerName));
        },
        removeContextOwner: (contextName, ownerName) => {
            dispatch(removeContextOwner(contextName, ownerName));
        },
        fetchContextDetails: (contextName) => {
            dispatch(fetchContextDetails(contextName));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverablePermissionDialog);

DeliverablePermissionDialog.defaultProps = {
    // props
    model: {},
    canFullyManageOwnerContext: false,
    permissions: {},
    selectedContextOwners: [],
    contexts: [],
    newOwnerName: '',
    // functions
    createDeliverable: noop,
    updateDeliverable: noop,
    changeDeliverableEditor: noop,
    onUpdateNewOwnerName: noop,
    onUpdateNewContextName: noop,
    onSelectContextName: noop,
    addNewContext: noop,
    addContextOwner: noop,
    removeContextOwner: noop,
    fetchContextDetails: noop,
};

DeliverablePermissionDialog.propTypes = {
    // props
    model: PropTypes.object,
    canFullyManageOwnerContext: PropTypes.bool,
    permissions: PropTypes.object,
    selectedContextOwners: PropTypes.array,
    contexts: PropTypes.array,
    newOwnerName: PropTypes.string,
    // functions
    onUpdateNewOwnerName: PropTypes.func,
    onUpdateNewContextName: PropTypes.func,
    createDeliverable: PropTypes.func,
    updateDeliverable: PropTypes.func,
    changeDeliverableEditor: PropTypes.func,
    onSelectContextName: PropTypes.func,
    addNewContext: PropTypes.func,
    addContextOwner: PropTypes.func,
    removeContextOwner: PropTypes.func,
    fetchContextDetails: PropTypes.func,
};
