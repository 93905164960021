import getOr from 'lodash/fp/getOr';

import { ENTITY_USER } from '~/features/base/constants/entities';
import {
    ROLE_DIAG_LOG_FILE_MANAGER,
    ROLE_DEVICE_MONITOR_USER,
    ROLE_DEVICE_LOGS_USER,
    ROLE_FILE_TRANSFER_MANAGER,
    ROLE_UPDATE_MANAGER,
    ROLE_BASIC_USER,
    ROLE_TEST_MANAGER,
    ROLE_APP_UPDATE_MANAGER, ROLE_CAMPAIGN_MANAGER,
} from '~/features/base/constants/permissions';

import { entityStoreSelector } from '~/features/higherorder/selectors/dataSelectors';

const userDataSelector = state => entityStoreSelector(state)[ENTITY_USER];

export const permissionsFetchedSelector = (state) =>
    !!userDataSelector(state);

export const rolesSelector = (state) =>
    getOr([], 'otaRoles', userDataSelector(state));

export const otherRolesSelector = (state) =>
    getOr([], 'otherRoles', userDataSelector(state));

export const permissionsSelector = (state) =>
    getOr([], 'permissions', userDataSelector(state));

export const hasSufficientRolesSelector = (state) =>
    rolesSelector(state).includes(ROLE_BASIC_USER) ||
    rolesSelector(state).includes(ROLE_TEST_MANAGER) ||
    rolesSelector(state).includes(ROLE_CAMPAIGN_MANAGER) ||
    rolesSelector(state).includes(ROLE_UPDATE_MANAGER) ||
    rolesSelector(state).includes(ROLE_APP_UPDATE_MANAGER) ||
    rolesSelector(state).includes(ROLE_FILE_TRANSFER_MANAGER);

export const isDiagLogFileManagerSelector = (state) =>
    otherRolesSelector(state).includes(ROLE_DIAG_LOG_FILE_MANAGER) ||
    rolesSelector(state).includes(ROLE_DEVICE_LOGS_USER);

export const canManageDeliverablesSelector = (state) =>
    (rolesSelector(state).includes(ROLE_FILE_TRANSFER_MANAGER) ||
    rolesSelector(state).includes(ROLE_UPDATE_MANAGER)) && true;

export const canFullyManageOwnerContextSelector = (state) =>
    rolesSelector(state).includes(ROLE_UPDATE_MANAGER);

export const isDeviceMonitorUserSelector = (state) =>
    otherRolesSelector(state).includes(ROLE_DEVICE_MONITOR_USER);

export const canTriggerCheck4UpdateSelector = (state) =>
    rolesSelector(state).includes(ROLE_TEST_MANAGER) ||
    rolesSelector(state).includes(ROLE_UPDATE_MANAGER);

export const hasAdaptOrderPermissionsSelector = (state) =>
    rolesSelector(state).includes(ROLE_TEST_MANAGER) ||
    rolesSelector(state).includes(ROLE_UPDATE_MANAGER);

export const isTestManager = (state) =>
    rolesSelector(state).includes(ROLE_TEST_MANAGER);

export const isUpdateManager = (state) =>
    rolesSelector(state).includes(ROLE_UPDATE_MANAGER);

export const isTestOrUpdateManager = (state) =>
    rolesSelector(state).includes(ROLE_TEST_MANAGER) ||
    rolesSelector(state).includes(ROLE_UPDATE_MANAGER);
