import { actionStatus } from '~/features/deviceReports/constants/actionStatus';

export const actionStatusSettings = {
    [actionStatus.SUCCESS]: {
        badge: 'text-success rioglyph rioglyph-ok-sign ',
        label: 'intl-msg:report.successful'
    },
    [actionStatus.FAILURE]: {
        badge: 'text-danger rioglyph rioglyph-remove-sign',
        label: 'intl-msg:report.failed',
    },
    [actionStatus.ONGOING]: {
        badge: 'text-warning rioglyph rioglyph-question-sign',
        label: 'intl-msg:report.notFinished',
    },
    [actionStatus.REBOOT_PENDING]: {
        label: 'intl-msg:report.state.isRebootPending.label',
        message: 'intl-msg:report.state.isRebootPending.message',
        badge: 'text-warning rioglyph rioglyph-time',
    },
};
