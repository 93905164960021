import { combineReducers } from '@reduxjs/toolkit';

import {
    ENTITY_CONTROL_DEVICE,
    ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS,
    ENTITY_DELIVERABLE,
    ENTITY_DELIVERABLE_FILES,
    ENTITY_DELIVERABLE_ID,
    ENTITY_DEVICE_ACTION,
    ENTITY_DISTRO,
    ENTITY_FILE,
    ENTITY_FILE_DELIVERABLE,
    ENTITY_GROUP,
    ENTITY_PACKAGE,
    ENTITY_PACKAGE_DELIVERABLE,
    ENTITY_UPDATE_EVENT,
    ENTITY_UPDATE_EVENT_REPORT, ENTITY_VEHICLES,
} from '~/features/base/constants/entities';
// higher order
import { createLoadingReducer, createMappedLoadingReducer } from '~/features/higherorder/reducers/loadingReducer';

export const loadingReducer = combineReducers({
    controlDevices: createMappedLoadingReducer(ENTITY_CONTROL_DEVICE, 'scope'),
    vehicles: createMappedLoadingReducer(ENTITY_VEHICLES, 'scope'),
    controlDeviceSystemStateNotifications: createMappedLoadingReducer(ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS,
        'scope'),
    deviceActions: createLoadingReducer(ENTITY_DEVICE_ACTION),
    groups: createLoadingReducer(ENTITY_GROUP),
    distros: createLoadingReducer(ENTITY_DISTRO),
    packages: createLoadingReducer(ENTITY_PACKAGE),
    packageDeliverables: createLoadingReducer(ENTITY_PACKAGE_DELIVERABLE),
    files: createLoadingReducer(ENTITY_FILE),
    fileDeliverables: createLoadingReducer(ENTITY_FILE_DELIVERABLE),
    deliverables: createLoadingReducer(ENTITY_DELIVERABLE),
    deliverableIds: createLoadingReducer(ENTITY_DELIVERABLE_ID),
    deliverableFiles: createLoadingReducer(ENTITY_DELIVERABLE_FILES),
    updateEvents: createMappedLoadingReducer(ENTITY_UPDATE_EVENT, 'scope'),
    updateEventReports: createMappedLoadingReducer(ENTITY_UPDATE_EVENT_REPORT, 'scope'),
});
export default loadingReducer;
