import map from 'lodash/fp/map';
import noop from 'lodash/fp/noop';
import size from 'lodash/fp/size';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import DeviceReportOperation from '~/features/deviceReports/components/DeviceReportOperation';
import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';
import DateRangePicker from '@rio-cloud/rio-uikit/lib/es/DateRangePicker';
import NumberControl from '@rio-cloud/rio-uikit/lib/es/NumberControl';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import {
    FETCH_REPORT_FROM_OPERATION_ID,
    FETCH_REPORT_UNTIL_OPERATION_ID,
} from '~/features/devices/constants/deviceReportValues';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';

const renderOperations = (operations, onCopyDeviceReportLink) => {

    return map(operation => <DeviceReportOperation operation={operation} copyLink={onCopyDeviceReportLink}/>,
        operations);
};

const renderFilter = (onDateRangeChange, onOperationIdMinimumChange, onOperationIdMaximumChange, onCopyDeviceReportLink,
    onResetFilter, startDate, endDate, operationIdStart, operationIdEnd) => {
    return [
        <div className='col-12'>
            <div className='row'>
                <div className='col-5'>
                    <label><FormattedMessage id='intl-msg:range'/></label>
                    {(startDate && endDate) ?
                        <DateRangePicker
                            startValue={startDate ? startDate : 0}
                            endValue={endDate ? endDate : 0}
                            onRangeChange={onDateRangeChange}/>
                        :
                        [ //without the brackets the DateRangePicker will not be rendered correctly after resetting
                            <DateRangePicker onRangeChange={onDateRangeChange}/>,
                        ]
                    }
                </div>
                <div className='col-3'>
                    <label><FormattedMessage id='intl-msg:minOperationId'/></label>
                    <div className='form-group'>
                        <NumberControl
                            min={FETCH_REPORT_FROM_OPERATION_ID}
                            max={FETCH_REPORT_UNTIL_OPERATION_ID}
                            value={operationIdStart}
                            step={1}
                            onValueChanged={(value) => onOperationIdMinimumChange(value)}
                        />
                    </div>
                </div>
                <div className='col-3'>
                    <label><FormattedMessage id='intl-msg:maxOperationId'/></label>
                    <div className='form-group'>
                        <NumberControl
                            min={FETCH_REPORT_FROM_OPERATION_ID}
                            max={FETCH_REPORT_UNTIL_OPERATION_ID}
                            value={operationIdEnd}
                            step={1}
                            onValueChanged={(value) => onOperationIdMaximumChange(value)}
                        />
                    </div>
                </div>
                <div className='col-1'>
                    <label><FormattedMessage id='Actions'/></label>
                    <div className='device-details-toolbar'>
                        <div className='form-group'>
                            <div className='padding-top-5'>
                                <OverlayTrigger placement='top' overlay={
                                    <Tooltip id='tooltip' className='top-left'>
                                        <FormattedMessage id='intl-msg:copyDirectReportLink'/>
                                    </Tooltip>}>
                                <span
                                    className='rioglyph rioglyph rioglyph-link padding-top-5 padding-right-5 text-size-20'
                                    onClick={value => onCopyDeviceReportLink(null)}
                                ></span>
                                </OverlayTrigger>
                                <OverlayTrigger placement='top' overlay={
                                    <Tooltip id='tooltip' className='top-left'>
                                        <FormattedMessage id='intl-msg:deviceReportFilter.clear'/>
                                    </Tooltip>}>
                                <span
                                    className='rioglyph rioglyph-filter text-size-20' aria-hidden='true'
                                    onClick={value => onResetFilter()}
                                ></span>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
    ];
};

export const DeviceReport = ({
    report,
    onLoadMore,
    onDateRangeChange,
    onOperationIdMinimumChange,
    onOperationIdMaximumChange,
    onCopyDeviceReportLink,
    onResetFilter,
    startDate,
    endDate,
    operationIdStart,
    operationIdEnd,
    totalElements,
}) => {
    const filterSection = renderFilter(onDateRangeChange, onOperationIdMinimumChange, onOperationIdMaximumChange,
        onCopyDeviceReportLink, onResetFilter, startDate, endDate, operationIdStart, operationIdEnd);
    if (!report.operations) {
        return [
            filterSection,
            <div className='col-12'><Spinner/></div>,
        ];
    }
    if (!size(report.operations)) {
        return [
            <div className='row'>
                {filterSection}
            </div>,
            <DefaultWhiteColumn className='padding-top-20 padding-bottom-20'>
                <NotFoundState fullWidth
                               headline={<FormattedMessage id='intl-msg:nothingFound'/>}
                               message={<FormattedMessage id='intl-msg:noReportsAvailable'/>}/>
            </DefaultWhiteColumn>,
        ];
    }
    const operationList = renderOperations(report.operations, onCopyDeviceReportLink);
    const showLoadMore = totalElements > operationList.length;
    return [
        filterSection,
        <DefaultWhiteColumn className='padding-top-20 padding-bottom-5'>
            {operationList}
        </DefaultWhiteColumn>,
        showLoadMore &&
        <DefaultWhiteColumn className='text-center padding-bottom-20'>
            <a id='load-more-button'
               className='btn btn-default'
               onClick={onLoadMore}>
                <FormattedMessage id='intl-msg:loadMore'/>
            </a>
        </DefaultWhiteColumn>,
    ];
};

export default DeviceReport;

DeviceReport.defaultProps = {
    // props
    report: {},
    startDate: 0,
    endDate: 0,
    operationIdStart: 0,
    operationIdEnd: 0,
    pageNumber: 0,
    totalElements: 0,
    // functions
    onLoadMore: noop,
    onOperationIdMinimumChange: noop,
    onOperationIdMaximumChange: noop,
    onDateRangeChange: noop,
    onCopyDeviceReportLink: noop,
    onResetFilter: noop,
};

DeviceReport.propTypes = {
    // props
    report: PropTypes.object,
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    operationIdEnd: PropTypes.number,
    pageNumber: PropTypes.number,
    totalElements: PropTypes.number,
    // functions
    onLoadMore: PropTypes.func,
    onOperationIdMinimumChange: PropTypes.func,
    onOperationIdMaximumChange: PropTypes.func,
    onDateRangeChange: PropTypes.func,
    onCopyDeviceReportLink: PropTypes.func,
    onResetFilter: PropTypes.func,
};

