import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    fetchControlDeviceInformation,
} from '~/features/devices/actions/controlDeviceInfoActions';

import {
    controlDeviceEligibleFileVersionsSelector,
    controlDeviceInfoSelector,
} from '~/features/devices/selectors/controlDeviceSelectors';
import DeviceFileVersions from '~/features/devices/components/DeviceFileVersions';
import noop from 'lodash/fp/noop';
import uid from '~/features/base/utils/uid';

/**
 * Container for the device file versions
 */
export class DeviceFileVersionsContainer extends PureComponent {

    constructor(props) {
        super(props);
        this.name = uid();
    }

    render() {
        const { controlDeviceInfo, controlDeviceEligibleFileVersions } = this.props;
        return (
            <DeviceFileVersions controlDeviceInfo={controlDeviceInfo}
                                controlDeviceEligibleFileVersions={controlDeviceEligibleFileVersions}
            />
        );
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        controlDeviceInfo: controlDeviceInfoSelector(state, ownProps),
        controlDeviceEligibleFileVersions: controlDeviceEligibleFileVersionsSelector(state, ownProps),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        fetchControlDeviceInformation: (options) => {
            dispatch(fetchControlDeviceInformation(options));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceFileVersionsContainer);

DeviceFileVersionsContainer.defaultProps = {
    // props
    serialNumber: '',
    // functions
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    triggerDataFetcher: noop,
};

DeviceFileVersionsContainer.propTypes = {
    // props
    serialNumber: PropTypes.string,
    controlDeviceInfo: PropTypes.object,
    controlDeviceEligibleFileVersions: PropTypes.object,
    // functions
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
};
