import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * Log requests details toolbar component
 */
export class LogRequestDetailsToolbar extends PureComponent {
    constructor(props) {
        super(props);
        this.onEditDeviceAction = this.onEditDeviceAction.bind(this);
        this.onDeleteDeviceAction = this.onDeleteDeviceAction.bind(this);
    }

    onEditDeviceAction() {
        const { deviceAction } = this.props;
        this.props.onEditDeviceAction({
            isNew: false,
            deviceAction,
        });
    }

    onDeleteDeviceAction() {
        const { deviceAction } = this.props;
        this.props.onDeleteDeviceAction({
            deviceAction,
        });
    }

    render() {
        const deleteButton = this.renderDeleteButton();
        return (
            <div className='device-action-toolbar row margin-bottom-25'>
                <div className='col-md-12'>
                    <div className='btn-toolbar'>
                        {deleteButton}
                    </div>
                </div>
            </div>
        );
    }

    renderDeleteButton() {
        if (this.props.canDeleteDeviceActions) {
            return (
                <a id='delete-device-action-button'
                   className='btn btn-default'
                   onClick={this.onDeleteDeviceAction}>
                    <span className='rioglyph rioglyph-trash' aria-hidden='true'></span>&nbsp;
                    <FormattedMessage id='intl-msg:deleteLogRequest'/>
                </a>
            );
        }
    }
}

export default LogRequestDetailsToolbar;

LogRequestDetailsToolbar.defaultProps = {
    // props
    canUpdateDeviceActions: false,
    canDeleteDeviceActions: false,
    // functions
    onEditDeviceAction: noop,
    onDeleteDeviceAction: noop,
};

LogRequestDetailsToolbar.propTypes = {
    // props
    canUpdateDeviceActions: PropTypes.bool,
    canDeleteDeviceActions: PropTypes.bool,
    // functions
    onEditDeviceAction: PropTypes.func,
    onDeleteDeviceAction: PropTypes.func,
};
