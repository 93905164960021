import compact from 'lodash/fp/compact';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import trim from 'lodash/fp/trim';
import join from 'lodash/fp/join';
import split from 'lodash/fp/split';
import size from 'lodash/fp/size';
import noop from 'lodash/fp/noop';

import classNames from 'classnames';

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { validateHwSerials } from '~/features/base/utils/hwSerialValidator';
import uid from '~/features/base/utils/uid';

export class SerialNumbersFormItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: join(', ', props.serialNumbers),
            isValid: validateHwSerials(props.serialNumbers),
            focused: false,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.focused) {
            return {
                value: join(', ', nextProps.serialNumbers),
                isValid: validateHwSerials(nextProps.serialNumbers),
                focused: false,
            };
        }
        return prevState;
    }

    onFocus = () => {
        this.setState({
            focused: true,
        });
    };
    onBlur = () => {
        this.setState({
            focused: false,
        });
    };
    onChange = (event) => {
        const value = event.target.value;
        const serialNumbers = flow(
            split(','),
            map(serialNumber => trim(serialNumber)),
            compact,
        )(value);
        const isValid = validateHwSerials(serialNumbers);
        this.setState({
            value,
            isValid,
        });
        this.props.onChange({
            serialNumbers,
            isValid,
        });
    };

    render() {
        const { label, serialNumbers, disabled } = this.props;
        const { value, isValid/*, focused*/ } = this.state;

        const helpBlockMessage = !isValid ?
            <FormattedMessage id='intl-msg:listContainsInvalidSerialNumber'/> : undefined;
        return (
            <div className={classNames('form-group', { 'has-error': !isValid })}>
                <label className='control-label'>
                    <FormattedMessage id={label}/>
                    <span className='margin-left-10'/>
                    <span className='badge bg-info'><FormattedNumber value={size(serialNumbers)}/></span>
                    {

                        /*focused ?
                            <span className='badge bg-danger'>{'Focused'}</span> : null*/
                    }
                </label>
                <FormattedMessage id='intl-msg:serialNumbers.prompt'>
                    {placeHolder =>
                        <textarea className={classNames('form-control', { disabled })}
                                  value={value}
                                  placeholder={placeHolder}
                                  style={{ height: '100px' }}
                                  onFocus={this.onFocus}
                                  onBlur={this.onBlur}
                                  onChange={this.onChange}/>
                    }
                </FormattedMessage>
                <span className='help-block'>{helpBlockMessage}</span>
            </div>
        );
    }
}

export default SerialNumbersFormItem;

// TODO Obviously it doesn't work without knowledge about whitelistingInfo
SerialNumbersFormItem.defaultProps = {
    // props
    serialNumbers: [],
    disabled: false,
    label: 'intl-msg:includeDevices',
    // functions
    onChange: noop,
};

SerialNumbersFormItem.propTypes = {
    // props
    serialNumbers: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool,
    label: PropTypes.string,
    // functions
    onChange: PropTypes.func,
};
