import assignAll from 'lodash/fp/assignAll';
import compact from 'lodash/fp/compact';
import noop from 'lodash/fp/noop';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { followRoute } from '~/features/base/actions/ui/routeActions';
import { fetchHomeData } from '~/features/overview/actions/homeActions';
import { permissionsSelector } from '~/features/user/selectors/permissionSelectors';
import { packageCountSelector } from '~/features/deliverables/features/packages/selectors/packageSelectors';
import {
    packageDeliverableCountSelector,
} from '~/features/deliverables/features/packages/selectors/packageDeliverableSelectors';
import { logRequestsCountSelector } from '~/features/deviceLogs/selectors/deviceActionSelectors';

import { devicesWithLogsCountSelector } from '~/features/deviceLogs/selectors/devicesWithLogsSelector';
import { updateEventCountSelector } from '~/features/updateEvents/selectors/updateEventSelectors';
import {
    blockedDeviceCountSelector,
    controlDeviceCM4CountSelector,
    controlDeviceCountsByBaseSwInCustomerFleetSelector,
    controlDeviceCountsByBaseSwSelector,
    controlDeviceCountsByDistroInCustomerFleetSelector,
    controlDeviceCountsByDistroSelector,
    controlDeviceCountsByHwVariantInCustomerFleetSelector,
    controlDeviceCountsByHwVariantSelector,
    controlDeviceTBM3CountSelector,
    controlDeviceVCMCountSelector,
    errorDeviceCountSelector,
    inCustomerFleetCountSelectorCM4,
    inCustomerFleetCountSelectorTBM3,
    testDeviceCountSelector,
    warningDeviceCountSelector,
} from '~/features/devices/selectors/controlDeviceSelectors';
import {
    APPS_PATH,
    DELIVERABLES_MANAGEMENT_PATH,
    DEVICES_PATH,
    EVENTS_PATH,
    LIST_PATH,
    LOG_REQUESTS_PATH,
    OVERVIEW_PATH,
} from '~/features/base/constants/routes';
import {
    PERMISSION_CONTROL_DEVICE_READ,
    PERMISSION_DEVICE_ACTION_READ,
    PERMISSION_PACKAGE_DELIVERABLE_READ,
} from '~/features/base/constants/permissions';
import Home from '~/features/overview/components/Home';
import { CM4, TBM3, VCM } from '~/features/devices/constants/deviceTypes';
import {
    deviceCountsByCountriesSelector,
    deviceCountsByVehiclesSelector,
} from '~/features/devices/selectors/deviceDistributionsSelectors';
import { env } from '~/env';
import {
    distroCountsByReleaseStateSelector,
    liveAppsSelector,
    liveBundlesSelector,
    liveCM4GsSelector,
    liveDistrosSelector,
} from '~/features/deliverables/selectors/deliverableSelectors';
import {
    APPS_ROLLOUT,
    BUNDLES_ROLLOUT,
    CM4G_ROLLOUT,
    DISTROS_ROLLOUT,
} from '~/features/deliverables/constants/deliverablesRollout.js';
import { ENTITY_LIVE_BUNDLES } from '~/features/base/constants/entities';

const { region } = env.runtimeConfig;

const beforeSixMonths = () => new Date().getTime() - 15778800000;

// TODO Add selector to tile
export const tileDescriptors = compact([{
    id: 'inCustomerFleetDevices',
    label: <FormattedMessage id='intl-msg:controlDevices'/>,
    type: 'multipleNumberTile',
    values:
        [
            {
                id: 'inCustomerFleetDevices',
                label: <FormattedMessage id='intl-msg:activatedTBM3'/>,
                permission: PERMISSION_CONTROL_DEVICE_READ,
                dataPath: ['inCustomerFleetCountTBM3'],
                link: `/${DEVICES_PATH}/${LIST_PATH}`,
                query: {
                    deviceType: TBM3,
                    inCustomerFleet: true,
                    lastCheckForUpdateAfter: beforeSixMonths(),
                },
            },
            {
                id: 'inCustomerFleetDevices',
                label: <FormattedMessage id='intl-msg:totalTBM3'/>,
                permission: PERMISSION_CONTROL_DEVICE_READ,
                dataPath: ['controlDeviceTBM3Count'],
                link: `/${DEVICES_PATH}/${LIST_PATH}`,
                query: {
                    deviceType: TBM3,
                    lastCheckForUpdateAfter: beforeSixMonths(),
                },
            },
            {
                id: 'inCustomerFleetDevices',
                label: <div className='margin-bottom-10'><FormattedMessage id='intl-msg:totalVCM'/></div>,
                permission: PERMISSION_CONTROL_DEVICE_READ,
                dataPath: ['controlDeviceVCMCount'],
                link: `/${DEVICES_PATH}/${LIST_PATH}`,
                query: {
                    deviceType: VCM,
                    lastCheckForUpdateAfter: beforeSixMonths(),
                },
            },
            {
                id: 'inCustomerFleetDevices',
                label: <FormattedMessage id='intl-msg:activatedCM4'/>,
                permission: PERMISSION_CONTROL_DEVICE_READ,
                dataPath: ['inCustomerFleetCountCM4'],
                link: `/${DEVICES_PATH}/${LIST_PATH}`,
                query: {
                    deviceType: CM4,
                    inCustomerFleet: true,
                    lastCheckForUpdateAfter: beforeSixMonths(),
                },
            },
            {
                id: 'inCustomerFleetDevices',
                label: <div className='margin-bottom-10'><FormattedMessage id='intl-msg:totalCM4'/></div>,
                permission: PERMISSION_CONTROL_DEVICE_READ,
                dataPath: ['controlDeviceCM4Count'],
                link: `/${DEVICES_PATH}/${LIST_PATH}`,
                query: {
                    deviceType: CM4,
                    lastCheckForUpdateAfter: beforeSixMonths(),
                },
            },
            {
                id: 'testDevices',
                label: <FormattedMessage id='intl-msg:deviceState.test'/>,
                type: 'numberTile',
                dataPath: ['testDeviceCount'],
                permission: PERMISSION_CONTROL_DEVICE_READ,
                link: `/${DEVICES_PATH}/${LIST_PATH}`,
                query: {
                    testReleasesActive: true,
                },
            },
            {
                id: 'blockedDevices',
                label: <FormattedMessage id='intl-msg:blocked'/>,
                type: 'numberTile',
                dataPath: ['blockedDeviceCount'],
                permission: PERMISSION_CONTROL_DEVICE_READ,
                link: `/${DEVICES_PATH}/${LIST_PATH}`,
                query: {
                    updatesActive: false,
                },
            },
            {
                id: 'warningDevices',
                label: <div className='bg-rating-2'><FormattedMessage id='intl-msg:deviceHealthState.warning'/></div>,
                type: 'numberTile',
                dataPath: ['warningDeviceCount'],
                permission: PERMISSION_CONTROL_DEVICE_READ,
                link: `/${DEVICES_PATH}/${LIST_PATH}`,
                query: {
                    deviceStatusType: 'WARN',
                },
            },
            {
                id: 'errorDevices',
                label: <div className='bg-rating-1'><FormattedMessage id='intl-msg:deviceHealthState.error'/></div>,
                type: 'numberTile',
                dataPath: ['errorDeviceCount'],
                permission: PERMISSION_CONTROL_DEVICE_READ,
                link: `/${DEVICES_PATH}/${LIST_PATH}`,
                query: {
                    deviceStatusType: 'ERROR',
                },
            },
        ],
}, {
    id: 'carouselTiles2',
    type: 'carouselTile',
    values:
        [
            {
                id: 'devicesByCountryTile',
                label: 'Countries',
                type: 'distributionTile',
                region: region,
                dataPath: ['deviceCountsByCountry'],
                distributionType: 'country',
                searchPath: 'devicesByCountry',
                permission: PERMISSION_CONTROL_DEVICE_READ,
            }, {
            id: 'devicesByVehicleTile',
            label: 'Vehicles',
            type: 'distributionTile',
            region: region,
            dataPath: ['deviceCountsByVehicle'],
            distributionType: 'vehicles',
            searchPath: 'devicesByVehicle',
            permission: PERMISSION_CONTROL_DEVICE_READ,
            query: {
                lastCheckForUpdateAfter: beforeSixMonths(),
                type: 'TBM3',
            },
        },
        ],
}, {
    id: 'carouselTiles3',
    type: 'carouselTile',
    values:
        [
            {
                id: BUNDLES_ROLLOUT,
                label: <FormattedMessage id='intl-msg:overview.rollout.bundles'/>,
                type: 'liveRolloutsTile',
                permission: PERMISSION_PACKAGE_DELIVERABLE_READ,
                dataPath: [ENTITY_LIVE_BUNDLES],
            },
            {
                id: DISTROS_ROLLOUT,
                label: <FormattedMessage id='intl-msg:overview.rollout.distros'/>,
                type: 'liveRolloutsTile',
                permission: PERMISSION_PACKAGE_DELIVERABLE_READ,
                dataPath: ['liveDistros'],
            },
            {
                id: APPS_ROLLOUT,
                label: <FormattedMessage id='intl-msg:overview.rollout.apps'/>,
                type: 'liveRolloutsTile',
                permission: PERMISSION_PACKAGE_DELIVERABLE_READ,
                dataPath: ['liveApps'],
            },
            {
                id: CM4G_ROLLOUT,
                label: <FormattedMessage id='intl-msg:overview.rollout.cm4gs'/>,
                type: 'liveRolloutsTile',
                permission: PERMISSION_PACKAGE_DELIVERABLE_READ,
                dataPath: ['liveCM4Gs'],
            },
        ],
}, {
    id: 'carouselTiles1',
    type: 'carouselTile',
    values:
        [
            {
                id: 'perDistroVersion',
                label: <FormattedMessage id='intl-msg:perDistroVersion'/>,
                type: 'donutTile',
                dataPath: ['countsByDistro'],
                searchPath: 'distroVersion',
                permission: PERMISSION_CONTROL_DEVICE_READ,
                link: `/${DEVICES_PATH}/${LIST_PATH}`,
                query: {
                    lastCheckForUpdateAfter: beforeSixMonths(),
                },
            },
            {
                id: 'perBaseSWVersion',
                label: <FormattedMessage id='intl-msg:perBaseSWVersion'/>,
                type: 'donutTile',
                searchPath: 'swVersion',
                dataPath: ['countsByBaseSw'],
                permission: PERMISSION_CONTROL_DEVICE_READ,
                link: `/${DEVICES_PATH}/${LIST_PATH}`,
                query: {
                    lastCheckForUpdateAfter: beforeSixMonths(),
                },
            },
        ],
}, {
    id: 'quarterNumberTiles',
    label: <FormattedMessage id=''/>,
    type: 'quarterNumberTile',
    values:
        [
            {
                id: 'packages',
                label: <FormattedMessage id='intl-msg:packages'/>,
                type: 'numberTile',
                permission: PERMISSION_PACKAGE_DELIVERABLE_READ,
                dataPath: ['packageCount'],
                link: `/${DELIVERABLES_MANAGEMENT_PATH}/${APPS_PATH}`,
            }, {
            id: 'packageDeliverables',
            label: <FormattedMessage id='intl-msg:packageDeliverables'/>,
            type: 'numberTile',
            permission: PERMISSION_PACKAGE_DELIVERABLE_READ,
            dataPath: ['packageDeliverableCount'],
            link: `/${DELIVERABLES_MANAGEMENT_PATH}/${APPS_PATH}`,
        }, {
            id: 'updateEvents',
            label: <FormattedMessage id='intl-msg:updateEvents'/>,
            type: 'numberTile',
            dataPath: ['updateEventCount'],
            link: `/${OVERVIEW_PATH}/${EVENTS_PATH}`,
        }, {
            id: 'logRequests',
            label: <FormattedMessage id='intl-msg:openLogRequests'/>,
            type: 'numberTile',
            dataPath: ['logRequestsCount'],
            permission: PERMISSION_DEVICE_ACTION_READ,
            link: `/${OVERVIEW_PATH}/${LOG_REQUESTS_PATH}`,
        },
        ],
}, {
    id: 'carouselTiles',
    type: 'carouselTile',
    values:
        [
            {
                id: 'perHwVariant',
                label: <FormattedMessage id='intl-msg:perHwVariant'/>,
                type: 'donutTile',
                dataPath: ['countsByHwVariant'],
                // searchPath: '`hwVariantPrefix`',
                permission: PERMISSION_CONTROL_DEVICE_READ,
                link: `/${DEVICES_PATH}/${LIST_PATH}`,
                query: {
                    lastCheckForUpdateAfter: beforeSixMonths(),
                },
            }, {
            id: 'perDistroVersionActivated',
            label: <FormattedMessage id='intl-msg:perDistroVersionActivated'/>,
            type: 'donutTile',
            dataPath: ['countsByDistroInCustomerFleet'],
            searchPath: 'distroVersion',
            permission: PERMISSION_CONTROL_DEVICE_READ,
            link: `/${DEVICES_PATH}/${LIST_PATH}`,
            query: {
                inCustomerFleet: true,
                lastCheckForUpdateAfter: beforeSixMonths(),
            },
        }, {
            id: 'perBaseSWVersionActivated',
            label: <FormattedMessage id='intl-msg:perBaseSWVersionActivated'/>,
            type: 'donutTile',
            dataPath: ['countsByBaseSwInCustomerFleet'],
            searchPath: 'swVersion',
            permission: PERMISSION_CONTROL_DEVICE_READ,
            link: `/${DEVICES_PATH}/${LIST_PATH}`,
            query: {
                inCustomerFleet: true,
                lastCheckForUpdateAfter: beforeSixMonths(),
            },
        }, {
            id: 'perHwVariantActivated',
            label: <FormattedMessage id='intl-msg:perHwVariantActivated'/>,
            type: 'donutTile',
            dataPath: ['countsByHwVariantInCustomerFleet'],
            searchPath: 'hwVariantPrefix',
            permission: PERMISSION_CONTROL_DEVICE_READ,
            link: `/${DEVICES_PATH}/${LIST_PATH}`,
            query: {
                inCustomerFleet: true,
                lastCheckForUpdateAfter: beforeSixMonths(),
            },
        },
        ],
},
]);

/**
 * Container for home
 */
export class HomeContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.onTileClick = this.onTileClick.bind(this);
    }

    onTileClick(tile, customQuery) {
        if (tile.link) {
            const route = tile.link;
            const query = assignAll({}, tile.query, customQuery);
            this.props.followRoute({ route, query });
        }
    }

    render() {
        return (
            <Home tileDescriptors={tileDescriptors}
                  {...this.props}
                  onTileClick={this.onTileClick}/>
        );
    }

    componentWillMount() {
        // TODO Should not happen here, instead we just want the range from selector
        this.props.fetchHomeData();

        this.interval = setInterval(() => {
            this.props.fetchHomeData();
        }, 60000);
    }

    componentWillUnmount() {
        //this.props.unregisterDataInterest(this.name);
        clearInterval(this.interval);
    }
}

export const mapStateToProps = (state) => {
    return {
        permissions: permissionsSelector(state),
        packageCount: packageCountSelector(state),
        packageDeliverableCount: packageDeliverableCountSelector(state),
        logRequestsCount: logRequestsCountSelector(state),
        countsByReleaseState: distroCountsByReleaseStateSelector(state),
        controlDeviceTBM3Count: controlDeviceTBM3CountSelector(state),
        controlDeviceVCMCount: controlDeviceVCMCountSelector(state),
        controlDeviceCM4Count: controlDeviceCM4CountSelector(state),
        testDeviceCount: testDeviceCountSelector(state),
        blockedDeviceCount: blockedDeviceCountSelector(state),
        warningDeviceCount: warningDeviceCountSelector(state),
        errorDeviceCount: errorDeviceCountSelector(state),
        inCustomerFleetCountTBM3: inCustomerFleetCountSelectorTBM3(state),
        inCustomerFleetCountCM4: inCustomerFleetCountSelectorCM4(state),
        countsByBaseSw: controlDeviceCountsByBaseSwSelector(state),
        countsByDistro: controlDeviceCountsByDistroSelector(state),
        countsByHwVariant: controlDeviceCountsByHwVariantSelector(state),
        countsByBaseSwInCustomerFleet: controlDeviceCountsByBaseSwInCustomerFleetSelector(state),
        countsByDistroInCustomerFleet: controlDeviceCountsByDistroInCustomerFleetSelector(state),
        countsByHwVariantInCustomerFleet: controlDeviceCountsByHwVariantInCustomerFleetSelector(state),
        devicesWithLogsCount: devicesWithLogsCountSelector(state),
        updateEventCount: updateEventCountSelector(state, { scope: 'totalCount' }),
        deviceCountsByCountry: deviceCountsByCountriesSelector(state),
        deviceCountsByVehicle: deviceCountsByVehiclesSelector(state),
        liveDistros: liveDistrosSelector(state),
        liveApps: liveAppsSelector(state),
        liveCM4Gs: liveCM4GsSelector(state),
        liveBundles: liveBundlesSelector(state),

    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        followRoute: (options) => {
            dispatch(followRoute(options));
        },

        /*triggerDataFetcher: () => {
            dispatch(triggerDataFetcher());
        },*/
        fetchHomeData: () => {
            dispatch(fetchHomeData());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);

HomeContainer.defaultProps = {
    // props
    permissions: [],
    logRequestsCount: 0,
    distroCount: 0,
    controlDeviceCount: 0,
    testDeviceCount: 0,
    blockedDeviceCount: 0,
    warningDeviceCount: 0,
    errorDeviceCount: 0,
    inCustomerFleetCount: 0,
    countsByBaseSw: [],
    countsByDistro: [],
    countsByHwVariant: [],
    countsByBaseSwInCustomerFleet: [],
    countsByDistroInCustomerFleet: [],
    countsByHwVariantInCustomerFleet: [],
    devicesWithLogsCount: 0,
    updateEventCount: 0,
    deviceCountsByCountry: [],
    deviceCountsByVehicle: [],
    liveDistros: [],
    liveApps: [],
    liveCM4Gs: [],

    // functions
    followRoute: noop,
    //triggerDataFetcher: noop,
    fetchHomeData: noop,
};

HomeContainer.propTypes = {
    // props
    permissions: PropTypes.array,
    logRequestsCount: PropTypes.number,
    distroCount: PropTypes.number,
    controlDeviceCount: PropTypes.number,
    testDeviceCount: PropTypes.number,
    blockedDeviceCount: PropTypes.number,
    warningDeviceCount: PropTypes.number,
    errorDeviceCount: PropTypes.number,
    inCustomerFleetCount: PropTypes.number,
    countsByBaseSw: PropTypes.array,
    countsByDistro: PropTypes.array,
    countsByHwVariant: PropTypes.array,
    countsByBaseSwInCustomerFleet: PropTypes.array,
    countsByDistroInCustomerFleet: PropTypes.array,
    countsByHwVariantInCustomerFleet: PropTypes.array,
    devicesWithLogsCount: PropTypes.number,
    updateEventCount: PropTypes.number,
    deviceCountsByCountry: PropTypes.object,
    deviceCountsByVehicle: PropTypes.object,
    liveDistros: PropTypes.array,
    liveApps: PropTypes.array,
    // functions
    followRoute: PropTypes.func,
    //triggerDataFetcher: PropTypes.func,
    fetchHomeData: PropTypes.func,
};
