import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import ListTable from '~/features/base/components/table/ListTable';
import { systemStateNotificationsColumnDescriptors } from '~/features/systemState/constants/systemStateNotificationColumnDescriptors';
import { connect } from 'react-redux';
import { systemStateCheckedColumnSettingsSelector } from '~/features/systemState/selectors/systemStateSelectors';
import { recoverSystemStateColumnSettings } from '~/features/systemState/actions/systemStateActions';
import noop from 'lodash/fp/noop';

export class SystemStateNotificationsTable extends PureComponent {
    render() {
        const { systemStateNotifications, selectedSystemStateColumns } = this.props;
        let filteredDes = [];
        if (selectedSystemStateColumns.length > 0) {
            const columnIdsMap = selectedSystemStateColumns.map(({ id }) => id);
            const columnIds = Object.values(columnIdsMap);
            filteredDes = systemStateNotificationsColumnDescriptors.filter(
                (currentDescriptor => columnIds.includes(currentDescriptor.id)));
        }
        return (
            <ListTable items={systemStateNotifications}
                       itemKey='id'
                       columnDescriptors={filteredDes}/>
        );
    }

    componentWillMount() {
        const { triggerRecoverColumnSettings } = this.props;
        triggerRecoverColumnSettings();
    }
}

export const mapStateToProps = (state) => {
    return {
        selectedSystemStateColumns: systemStateCheckedColumnSettingsSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        triggerRecoverColumnSettings: () => {
            dispatch(recoverSystemStateColumnSettings());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemStateNotificationsTable);

SystemStateNotificationsTable.defaultProps = {
    // props
    systemStateNotifications: [],
    // functions
    triggerRecoverColumnSettings: noop,
};

SystemStateNotificationsTable.propTypes = {
    // props
    systemStateNotifications: PropTypes.array,
    // functions
    triggerRecoverColumnSettings: PropTypes.func,
};
