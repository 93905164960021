export const FETCH_CONTROL_DEVICE_INFORMATION = 'FETCH_CONTROL_DEVICE_INFORMATION';
export const FETCH_CONTROL_DEVICE_ELIGIBLE_DELIVERABLES_VERSIONS = 'FETCH_CONTROL_DEVICE_ELIGIBLE_DELIVERABLES_VERSIONS';

export function fetchControlDeviceInformation({ serialNumber }) {
    return {
        type: FETCH_CONTROL_DEVICE_INFORMATION,
        payload: {
            serialNumber,
        },
    };
}

export function fetchControlDeviceEligibleDeliverablesVersions({ serialNumber, deliverableType }) {
    return {
        type: FETCH_CONTROL_DEVICE_ELIGIBLE_DELIVERABLES_VERSIONS,
        payload: {
            serialNumber,
            deliverableType,
        },
    };
}


