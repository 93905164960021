export const MERGE_PAGE = '@@data/MERGE_PAGE';
export const CLEAR_PAGE = '@@data/CLEAR_PAGE';

export const mergePage = (mergeProps, payload) => {
    return {
        type: MERGE_PAGE,
        mergeProps,
        payload,
    };
};
export const clearPage = (mergeProps) => {
    return {
        type: CLEAR_PAGE,
        mergeProps,
    };
};

/*export const mergePageOf = (entityName) => {
    return () => (mergeProps, payload) => {
        return {
            type: `${MERGE_PAGE}/${entityName}`,
            mergeProps,
            payload,
        };
    };
};*/
