import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';
import moment from 'moment';
import CustomOptions from '~/features/base/components/DateRangePickerOTA/CustomOptions';
import DateRange from '~/features/base/components/DateRangePickerOTA/DateRange';

interface DateRangePickerOTAProps {
    hasError: boolean;
    errorMsg?: string;
    initialStartValue?: moment.Moment;
    initialEndValue?: moment.Moment;
    onRangeChange: (startValue: moment.Moment, endValue: moment.Moment) => void;
}

const dateFormat = 'dd MM/DD/YYYY, hh:mm A';

const DateRangePickerOTA: React.FC<DateRangePickerOTAProps> = ({
    onRangeChange,
    hasError,
    errorMsg='intl-msg:form:requiredField',
    initialStartValue,
    initialEndValue
}) => {

    const highlightError = hasError ? 'form-group has-feedback has-error' : '';
    const [startValue, setStartValue] = useState<moment.Moment>(moment());
    const [endValue, setEndValue] = useState<moment.Moment>(moment());
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [isDateRangeCustom, setIsDateCustom] = useState<boolean>(false);
    const [title, setTitle] = useState<string>();

    const setTimeRange = (start: moment.Moment, end: moment.Moment) => {
        setDropdownOpen(false);
        setTitle(`${start.format(dateFormat)} → ${end.format(dateFormat)}`);
        onRangeChange(start, endValue);
    };

    const dropdownTitle = () => {

        if (title) {
            return title;
        }
        return (<span className={'text-color-gray'}>
            <FormattedMessage id={'intl-msg:dateTimeRange.placeholder'}/>
        </span>);

    };

    const dropdownContent = (): React.ReactNode => {

        if (isDateRangeCustom) {
            return (<DateRange startValue={startValue} endValue={endValue} setEndValue={setEndValue}
                               setStartValue={setStartValue} setTimeRange={setTimeRange}
                               setDropdownOpen={setDropdownOpen}/>
            );

        } else {
            return (
                <CustomOptions setIsDateCustom={setIsDateCustom} setTimeRange={setTimeRange}/>
            );
        }

    };

    useEffect(() => {
        if (initialStartValue && initialEndValue) {
            setTitle(`${initialStartValue.format(dateFormat)} → ${initialEndValue.format(dateFormat)}`);
        }
    }, []);

    return (
        <div>
            <div className={highlightError}>
                <ButtonDropdown
                    open={dropdownOpen}
                    className={'border-width-1 border-style-solid border-color-gray '}
                    title={<div onClick={() => {
                        setIsDateCustom(false);
                        setDropdownOpen(!dropdownOpen);
                    }} className={'display-flex width-100pct  justify-content-between'}>
                        <div>{dropdownTitle()}</div>
                        <div>
                            <span className={'caret'}> </span>
                        </div>
                    </div>}

                    bsStyle='link'
                    noCaret
                    toggleClassName='text-color-darkest width-100pct '
                    dropdownClassName='width-100pct  display-flex   '
                    customDropdown={dropdownContent()}
                />
            </div>
            {
                hasError &&
                <div className={'form-group has-feedback has-error padding-bottom-15'}>
                    <span className='help-block'>
                        <span><FormattedMessage id={errorMsg}/></span>
                    </span>
                </div>
            }</div>
    );
};

export default DateRangePickerOTA;
