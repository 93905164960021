import includes from 'lodash/fp/includes';
import map from 'lodash/fp/map';

import { MERGE_PAGE, CLEAR_PAGE } from '~/features/higherorder/actions/paginationActions';
import { mapReducer } from '~/features/higherorder/reducers/mapReducer';

const acceptedActions = [MERGE_PAGE, CLEAR_PAGE];

const defaultState = {};
const mapDefaultState = {};

const mergePage = (state, action, entityIdentifier) => {
    const { content, ...pagination } = action.payload;
    const newState = {
        ...state,
        ...pagination,
        [action.payload.number]: map(entityIdentifier, content), // TODO Get rid of identifier
    };
    return newState;
};
const clearPage = () => {
    return {};
};

export const paginationReducer = (state = defaultState, action = {}, entityIdentifier) => {
    if (action.type === MERGE_PAGE) {
        return mergePage(state, action, entityIdentifier);
    }
    if (action.type === CLEAR_PAGE) {
        return clearPage(state, action);
    }
    return state;
};

export const createPaginationReducer = (entityName, entityIdentifier) => {
    return (state = defaultState, action = {}) => {
        if (includes(action.type, acceptedActions)) {
            if (action.mergeProps.entityName === entityName) {
                return paginationReducer(state, action, entityIdentifier);
            }
        }
        return state;
    };
};

export const createMappedPaginationReducer = (entityName, entityIdentifier, scopeProp = 'scope') => {
    return (state = mapDefaultState, action = {}) => {
        if (includes(action.type, acceptedActions)) {
            if (action.mergeProps.entityName === entityName) {
                return mapReducer(state, action, scopeProp,
                    createPaginationReducer(entityName, entityIdentifier));
            }
        }
        return state;
    };
};
