import map from 'lodash/fp/map';

import { call, put, takeLatest } from 'redux-saga/effects';

import {
    DEVICE_ACTION_EDITOR_MODAL,
    DEVICE_ACTION_DELETE_MODAL,
} from '~/features/base/constants/modals';

import { showModal } from '~/features/base/actions/ui/modalsActions';
import {
    resetDeviceActionEditor,
    changeDeviceActionEditor,
    SHOW_DEVICE_ACTION_EDITOR,
    SHOW_DELETE_DEVICE_ACTION_DIALOG,
} from '~/features/deviceLogs/actions/deviceActionEditorActions';

import uid from '~/features/base/utils/uid';

export function* doPrepareForNewDeviceActionSaga(payload) {
    yield put(changeDeviceActionEditor({
        isNew: true,
        hwSerial: payload.hwSerial,
        hwSerialEditable: !payload.hwSerial,
        isValidHwSerial: !!payload.hwSerial,
    }));
}

export function* doPrepareForExistingDeviceActionSaga(payload) {
    const deviceAction = payload.deviceAction;
    yield put(changeDeviceActionEditor({
        isNew: false,
        hwSerial: deviceAction.hwSerial,
        hwSerialEditable: false,
        isValidHwSerial: true,
        logs: map(log => ({ ...log, id: uid() }), deviceAction.logs),
    }));
}

export function* doShowDeviceActionEditorSaga(action) {
    yield put(resetDeviceActionEditor());
    if (action.payload.isNew) {
        yield call(doPrepareForNewDeviceActionSaga, action.payload);
    } else {
        yield call(doPrepareForExistingDeviceActionSaga, action.payload);
    }
    yield put(showModal({
        modalType: DEVICE_ACTION_EDITOR_MODAL,
        modalProps: {},
    }));
}

export function* doShowLogRequestDeletionDialogSaga(action) {
    yield put(resetDeviceActionEditor());
    const deviceAction = action.payload.deviceAction;
    yield put(changeDeviceActionEditor({
        ...deviceAction,
    }));
    yield put(showModal({
        modalType: DEVICE_ACTION_DELETE_MODAL,
        modalProps: {},
    }));
}

export function* showDeviceActionEditorSaga() {
    yield takeLatest(SHOW_DEVICE_ACTION_EDITOR, doShowDeviceActionEditorSaga);
}

export function* showLogRequestDeletionDialogSaga() {
    yield takeLatest(SHOW_DELETE_DEVICE_ACTION_DIALOG, doShowLogRequestDeletionDialogSaga);
}
