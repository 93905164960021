/**
 * Action type for showing the device action editor
 * @type {string}
 */
export const SHOW_DEVICE_ACTION_EDITOR = 'SHOW_DEVICE_ACTION_EDITOR';

/**
 * Action type for showing the delete device action dialog
 * @type {string}
 */
export const SHOW_DELETE_DEVICE_ACTION_DIALOG = 'SHOW_DELETE_DEVICE_ACTION_DIALOG';

/**
 * Action type for resetting the device action editor model
 * @type {string}
 */
export const RESET_DEVICE_ACTION_EDITOR = 'RESET_DEVICE_ACTION_EDITOR';

/**
 * Action type for changing the device action editor model
 * @type {string}
 */
export const CHANGE_DEVICE_ACTION_EDITOR = 'CHANGE_DEVICE_ACTION_EDITOR';

/**
 * Action creator for showing device action editor
 * @param {object} payload The payload
 * @returns {{type: string}} The action for showing device action editor
 */
export function showDeviceActionEditor(payload) {
    return {
        type: SHOW_DEVICE_ACTION_EDITOR,
        payload,
    };
}

/**
 * Action creator for showing device action deletion dialog
 * @param {object} payload The payload
 * @returns {{type: string}} The action for showing device action deletion dialog
 */
export function showLogRequestDeletionDialog(payload) {
    return {
        type: SHOW_DELETE_DEVICE_ACTION_DIALOG,
        payload,
    };
}

/**
 * Action creator for resetting device action editor
 * @returns {{type: string}} The action for resetting device action editor
 */
export function resetDeviceActionEditor() {
    return {
        type: RESET_DEVICE_ACTION_EDITOR,
    };
}

/**
 * Action creator for changing device action editor
 * @param {object} payload The payload
 * @returns {{type: string}} The action for changing device action editor
 */
export function changeDeviceActionEditor(payload) {
    return {
        type: CHANGE_DEVICE_ACTION_EDITOR,
        payload,
    };
}
