import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchReferencesOfGroup} from '~/features/groups/actions/groupActions';
import {groupReferencesSelector} from '~/features/groups/selectors/groupReferencesSelectors';
import DeliverableChipList from "~/features/base/components/DeliverableChipList";
import {capitalizeFirstLetter} from "~/features/base/utils/stringUtils";
import PropertiesTable from "~/features/base/components/table/PropertiesTable";
import DefaultWhiteColumn from "~/features/base/components/DefaultWhiteColumn";
import NotFoundState from "@rio-cloud/rio-uikit/lib/es/NotFoundState";
import { FormattedMessage } from 'react-intl';


interface Deliverable {
    deliverableType: string;
}

interface Props {
    groupName: string;
}

const GroupReferences: React.FC<Props> = ({ groupName }) => {
    const dispatch = useDispatch();
    const references = useSelector((state) => groupReferencesSelector(state, { groupName }));

    useEffect(() => {
        dispatch(fetchReferencesOfGroup(groupName));
    }, [groupName, dispatch]);

    if(!references?.deliverables?.length){
        return (
            <DefaultWhiteColumn className='padding-top-20 padding-bottom-20'>
                <NotFoundState fullWidth
                               headline={<FormattedMessage id='intl-msg:nothingFound'/>}
                               message={<FormattedMessage id='intl-msg:references.emptyStatement'/>}/>
            </DefaultWhiteColumn>
        );

    }

    //map references by deliverable type
    const deliverableTypeMap = references.deliverables?.reduce((acc: Record<string, Deliverable[]>, deliverable: Deliverable) => {
        const { deliverableType } = deliverable;
        if (!acc[deliverableType]) {
            acc[deliverableType] = [];
        }
        acc[deliverableType].push(deliverable);
        return acc;
    }, {}) || {};

    //create dynamic descriptors for each deliverable type
    const descriptors = Object.keys(deliverableTypeMap).map(deliverableType => ({
        field: deliverableType,
        label: `${capitalizeFirstLetter(deliverableType)}s`,
        format: () => (
            <DeliverableChipList type={deliverableType} distroVersions={deliverableTypeMap[deliverableType]} />
        ),
    }));

    return (
        <DefaultWhiteColumn className='padding-top-20 padding-bottom-20'>
            <PropertiesTable data={references} rowDescriptors={descriptors}/>
        </DefaultWhiteColumn>
    );
};

export default GroupReferences;
