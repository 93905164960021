/* tslint:disable */
/* eslint-disable */
/**
 * OTA Admin Service
 * Old API to manage software deliverables and OTA updates
 *
 * OpenAPI spec version: v1
 * Contact: man-team-mdm@man.eu
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DeliverableCompatibilitySearchCriteria } from './deliverable-compatibility-search-criteria';
import { SemanticVersion } from './semantic-version';
/**
 * Search criteria for software info
 * @export
 * @interface DeliverableSearchCriteria
 */
export interface DeliverableSearchCriteria {
    /**
     * Deliverable type
     * @type {string}
     * @memberof DeliverableSearchCriteria
     */
    deliverableType?: DeliverableSearchCriteriaDeliverableTypeEnum;
    /**
     * Deliverable id. May only be present is deliverableIdPrefix is not set.
     * @type {string}
     * @memberof DeliverableSearchCriteria
     */
    deliverableId?: string;
    /**
     *
     * @type {SemanticVersion}
     * @memberof DeliverableSearchCriteria
     */
    deliverableVersion?: SemanticVersion;
    /**
     * Release state of deliverable, any of the given
     * @type {Array<string>}
     * @memberof DeliverableSearchCriteria
     */
    releaseStates?: Array<DeliverableSearchCriteriaReleaseStatesEnum>;
    /**
     * Deliverable id prefix. May only be present is deliverableId is not set.
     * @type {string}
     * @memberof DeliverableSearchCriteria
     */
    deliverableIdPrefix?: string;
    /**
     *
     * @type {DeliverableCompatibilitySearchCriteria}
     * @memberof DeliverableSearchCriteria
     */
    compatibilityCriteria?: DeliverableCompatibilitySearchCriteria;
    /**
     *
     * @type {SemanticVersion}
     * @memberof DeliverableSearchCriteria
     */
    distroBaseSoftwareVersion?: SemanticVersion;
    /**
     * Context that is the owner of this deliverable
     * @type {string}
     * @memberof DeliverableSearchCriteria
     */
    owningContext?: string;
    /**
     * Standalone marking a deliverable as a standalone or non-standalone
     */
    isStandalone?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum DeliverableSearchCriteriaDeliverableTypeEnum {
    DISTRO = 'DISTRO',
    APP = 'APP',
    FILE = 'FILE',
    CM4G = 'CM4G',
    BUNDLE = 'BUNDLE'
}
/**
    * @export
    * @enum {string}
    */
export enum DeliverableSearchCriteriaReleaseStatesEnum {
    DRAFT = 'DRAFT',
    LIVE = 'LIVE',
    TEST = 'TEST'
}

