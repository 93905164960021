import defaults from 'lodash/fp/defaults';
import noop from 'lodash/fp/noop';
import size from 'lodash/fp/size';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { followRoute } from '~/features/base/actions/ui/routeActions';
import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';
import {
    baseSwEntitiesSelector,
    baseSwVersionsSelector,
} from '~/features/baseSwVersions/selectors/baseSwVersionSelectors';
import {
    createBaseSoftwareOptions,
    createContextOptions,
    releaseStateOptionsWithAll,
} from '~/features/base/constants/filterOptions';
import { parseQuery } from '~/features/base/utils/query';
import { deliverablesContextsSelector } from '~/features/deliverables/selectors/deliverableSelectors';
import ContextSelectFormItem from '~/features/base/components/forms/ContextSelectFormItem';
import { fetchDeliverablesUserInfo, resetDeliverables } from '~/features/deliverables/actions/deliverableActions';
import { selectionByValue } from '~/features/base/utils/selectUtils';
import { toSemanticVersion, toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import uid from '~/features/base/utils/uid';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import {
    DELIVERABLE_TYPE_DISTRO,
    FETCH_DELIVERABLES_AMOUNT_INCREMENT,
} from '~/features/deliverables/constants/deliverablesParameters';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import { defaultFilters } from '~/features/deliverables/utils/defaultFilters';
import { DELIVERABLE_TYPE_FILE } from '~/features/deliverables/constants/deliverablesParameters';

export class DeliverableFiltersRowContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.name = uid();
    }

    // onSearchFilterChange = ({ deliverableIdPrefix }) => {
    //     this.updateFilter({
    //         deliverableIdPrefix,
    //     });
    // };
    //
    onDeliverableContextFilterChange = ({ context }) => {
        this.updateFilter({
            context,
        });
    };

    onReleaseStateFilterChange = (releaseState) => {
        this.updateFilter({
            releaseState: releaseState.value,
        });
    };

    onBaseSoftwareVersionFilterChange = (baseSoftwareVersion) => {
        this.updateFilter({
            baseSoftwareVersion: baseSoftwareVersion.value,
        });
    };

    onCompatibilityChange = (compatibility) => {
        if (compatibility.baseSoftwareVersion && toShortSemanticVersion(compatibility.baseSoftwareVersion)
            !== '0.0.0') {
            this.updateFilter({
                baseSoftwareVersion: toShortSemanticVersion(compatibility.baseSoftwareVersion),
            });
        } else {
            this.updateFilter({
                baseSoftwareVersion: undefined,
            });
        }

        if (compatibility.mdmcAppVersion && toShortSemanticVersion(compatibility.mdmcAppVersion) !== '0.0.0') {
            this.updateFilter({
                mdmcAppVersion: toShortSemanticVersion(compatibility.mdmcAppVersion),
            });
        } else {
            this.updateFilter({
                mdmcAppVersion: undefined,
            });
        }
    };

    clearFilters = () => {
        this.updateFilter(defaultFilters);
    };

    updateFilter(filters) {
        const { pathname, search } = this.props;
        const query = parseQuery(search);
        ['deliverableIdPrefix', 'context', 'releaseState', 'distroBaseSoftwareVersion'].map((prop) => {
            if (filters[prop]) {
                query[prop] = filters[prop] === 'all' ? undefined : filters[prop];
            }
        });
        this.props.followRoute({
            route: pathname, query: {
                ...query,
                ...filters,
                page: 0,
                size: FETCH_DELIVERABLES_AMOUNT_INCREMENT,
            },
        });
    }

    render() {
        const { search, contexts, baseSoftware, deliverableType, test } = this.props;
        const parsedQuery = parseQuery(search);
        const defaultedQuery = defaults(defaultFilters, parsedQuery);
        let baseSoftwareOptionsWithSelection;
        const contextOptions = createContextOptions(contexts, true);
        const releaseStateOptionsWithSelection = selectionByValue(defaultedQuery.releaseState)(
            releaseStateOptionsWithAll);
        if (size(baseSoftware) > 0) {
            // baseSoftwareOptions = createBaseSoftwareOptions(baseSoftware, true);
            baseSoftwareOptionsWithSelection = selectionByValue(defaultedQuery.baseSoftwareVersion)(createBaseSoftwareOptions(baseSoftware, true));
        }

        let compatibility = {
            baseSoftwareVersion: {
                major: 0,
                minor: 0,
                patch: 0,
            },
            mdmcAppVersion: {
                major: 0,
                minor: 0,
                patch: 0,
            },
        };
        if (defaultedQuery.baseSoftwareVersion) {
            compatibility.baseSoftwareVersion = toSemanticVersion(defaultedQuery.baseSoftwareVersion);
        }
        if (defaultedQuery.mdmcAppVersion) {
            compatibility.mdmcAppVersion = toSemanticVersion(defaultedQuery.mdmcAppVersion);
        }

        return (
            <div className='display-flex justify-content-start padding-bottom-10'>
                {/*<DeliverableIdPrefixFormItem value={defaultedQuery.deliverableIdPrefix}*/}
                {/*                             onChange={this.onSearchFilterChange}*/}
                {/*                             showHeader={true}/>*/}
                {/*<div className='col-md-2'>*/}
                {/*    <label>*/}
                {/*        <FormattedMessage id='intl-msg:filter'/>*/}
                {/*    </label>*/}
                {/*</div>*/}
                {deliverableType === DELIVERABLE_TYPE_FILE &&
                    <div className='col-md-2 padding-right-25'>
                        <ContextSelectFormItem value={defaultedQuery.context}
                                               horizontal={true}
                                               options={contextOptions}
                                               onChange={this.onDeliverableContextFilterChange}/>
                    </div>}
                <div className='col-md-2'>
                    <div className='display-flex align-content-center'>
                        <div className='col-md-4 padding-top-5 text-right min-width-100'>
                            <label>
                                <FormattedMessage id='intl-msg:releaseState'/>
                            </label>
                        </div>
                        <div className='col-md-8'>
                            <Select options={releaseStateOptionsWithSelection}
                                    onChange={this.onReleaseStateFilterChange}/>
                        </div>
                    </div>
                </div>
                {deliverableType === DELIVERABLE_TYPE_DISTRO &&
                    <div className='col-md-3'>
                        <div className='display-flex align-content-center'>
                            <div className='col-md-4 padding-top-5 text-right min-width-100'>
                                <label>
                                    <FormattedMessage id='intl-msg:baseSoftwareVersion'/>
                                </label>
                            </div>
                            <div className='col-md-8'>
                                <Select options={baseSoftwareOptionsWithSelection}
                                        onChange={this.onBaseSoftwareVersionFilterChange}/>
                            </div>
                        </div>
                    </div>
                }
                {/*<ExpanderPanel className='additional-rollout-settings-expander'*/}
                {/*               title={<FormattedMessage id='intl-msg:compatibility'/>} bsStyle='default'>*/}
                {/*    <VersionCompatibilityEditorForm*/}
                {/*        compatibility={compatibility} onChange={this.onCompatibilityChange}/>*/}
                {/*</ExpanderPanel>*/}
                <div className='col-md-1'>
                    <a id='clear-filters-button'
                       className='btn btn-default'
                       onClick={this.clearFilters}>
                        <span className='rioglyph rioglyph-trash' aria-hidden='true'></span>&nbsp;
                        <FormattedMessage id='intl-msg:clearFilters'/>
                    </a>
                </div>
            </div>
        );
    }

    componentWillMount() {
        this.registerDataInterest();
        this.props.triggerDataFetcher();
    }

    componentWillUnmount() {
        this.props.unregisterDataInterest(this.name);
    }

    registerDataInterest() {
        this.props.registerDataInterest(this.name, [
            fetchDeliverablesUserInfo(),
        ]);
    }
}

const mapStateToProps = (state) => ({
    pathname: pathnameSelector(state),
    search: searchSelector(state),
    contexts: deliverablesContextsSelector(state),
    baseSoftwareVersions: baseSwVersionsSelector(state),
    baseSoftware: baseSwEntitiesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    registerDataInterest: (name, options) => {
        dispatch(registerDataInterest(name, options));
    },
    unregisterDataInterest: (name) => {
        dispatch(unregisterDataInterest(name));
    },
    followRoute: (options) => {
        dispatch(followRoute(options));
    },
    resetDeliverables: () => {
        dispatch(resetDeliverables());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableFiltersRowContainer);

DeliverableFiltersRowContainer.defaultProps = {
    // props
    pathname: '',
    search: '',
    baseSoftwareVersions: [],
    // functions
    followRoute: noop,
    fetchBaseSwVersions: noop,
    resetDeliverables: noop,
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    triggerDataFetcher: noop,
};

DeliverableFiltersRowContainer.propTypes = {
    // props
    pathname: PropTypes.string,
    search: PropTypes.string,
    baseSoftwareVersions: PropTypes.array,
    // functions
    followRoute: PropTypes.func,
    fetchBaseSwVersions: PropTypes.func,
    resetDeliverables: PropTypes.func,
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
};
