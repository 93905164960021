import map from 'lodash/fp/map';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { AUTOMATIC, createGroupTypeOptions, USER } from '~/features/groups/constants/groupTypes';

import SerialNumbersFormItem from '~/features/base/components/forms/SerialNumbersFormItem';
import GroupCriteriaEditorForm from '~/features/groups/components/GroupCriteriaEditorForm';
import { validateGroupName } from '~/features/base/utils/groupNameValidator';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';

export class GroupEditorForm extends PureComponent {
    constructor() {
        super();
        this.groupNameInput = React.createRef();
    }

    onNameChange = (event) => {
        const groupName = event.target.value;
        const isValidGroupName = validateGroupName(groupName);
        this.props.changeGroupEditor({
            name: groupName,
            isValidGroupName,
        });
    };
    onTypeChange = (groupType) => {
        this.props.changeGroupEditor({
            type: groupType.id,
        });
    };
    onDescriptionChange = (event) => {
        this.props.changeGroupEditor({
            description: event.target.value,
        });
    };
    onCriteriaChange = (autoGroupCriteria) => {
        this.props.changeGroupEditor({
            autoGroupCriteria,
        });
    };
    onSerialNumbersChange = ({ serialNumbers, isValid }) => {
        this.props.changeGroupEditor({
            serialNumbers,
            isValidSerialNumbers: isValid,
        });
    };

    render() {
        const { model } = this.props;
        const nameInput = this.renderNameInput(model);
        const typeInput = this.renderTypeInput(model);
        const descriptionInput = this.renderDescriptionInput(model);
        const serialNumbersInput = this.renderSerialNumbersInput(model);
        const criteriaInput = this.renderCriteriaInput(model);
        //const criteriaAsJson = this.renderCriteriaAsJson(model);
        return (
            <form>
                {nameInput}
                {typeInput}
                {descriptionInput}
                {serialNumbersInput}
                {criteriaInput}
                {/*criteriaAsJson*/}
            </form>
        );
    }

    renderNameInput(model) {
        if (!model.isNew) {
            return;
        }
        const helpBlock = !model.isValidGroupName ? <FormattedMessage id='intl-msg:invalidGroupName'/> : undefined;
        return (
            <div className='form-group'>
                <label className='control-label'><FormattedMessage id='intl-msg:name'/></label>
                <FormattedMessage id='intl-msg:invalidGroupNameExample'>
                    {placeHolder =>
                        <input className='form-control'
                               type='text'
                               ref={this.groupNameInput}
                               placeholder={placeHolder}
                               value={model.name}
                               onChange={this.onNameChange}/>
                    }
                </FormattedMessage>
                <span className='help-block'>
                    {helpBlock}
                </span>
            </div>
        );
    }

    renderTypeInput(model) {
        if (!model.isNew) {
            return;
        }
        const type = model.type;
        const typesWithSelection = map(option => ({
            ...option,
            selected: option.id === type,
        }), createGroupTypeOptions());
        return (
            <div className='form-group'>
                <label className='control-label'><FormattedMessage id='intl-msg:type'/></label>
                <Select options={typesWithSelection}
                        onChange={this.onTypeChange}/>
            </div>
        );
    }

    renderDescriptionInput(model) {
        return (
            <div className='form-group'>
                <label className='control-label'><FormattedMessage id='intl-msg:description'/></label>
                <FormattedMessage id='intl-msg:description.prompt'>
                    {placeHolder =>
                        <input className='form-control'
                               type='text'
                               placeholder={placeHolder}
                               value={model.description}
                               onChange={this.onDescriptionChange}/>
                    }
                </FormattedMessage>
            </div>
        );
    }

    renderCriteriaInput(model) {
        if (model.type !== AUTOMATIC) {
            return;
        }
        return (
            <ExpanderPanel className='filter-expander'
                           title={<FormattedMessage id='intl-msg:filter'/>} bsStyle='default' open>
                <GroupCriteriaEditorForm autoGroupCriteria={model.autoGroupCriteria}
                                         onChange={this.onCriteriaChange}/>
            </ExpanderPanel>
        );
    }

    /*renderCriteriaAsJson(model) {
        if (model.type !== AUTOMATIC) {
            return;
        }
        return (
            <ExpanderPanel className='json-expander'
                title={<FormattedMessage id='intl-msg:filtersAsJSON'/>} bsStyle='default'>
                <pre>
                    {JSON.stringify(model.autoGroupCriteria, null, 4)}
                </pre>
            </ExpanderPanel>
        );
    }*/

    renderSerialNumbersInput(model) {
        if (model.type !== USER) {
            return;
        }
        return (
            <SerialNumbersFormItem label='intl-msg:devicesAssigned'
                                   serialNumbers={model.serialNumbers}
                                   onChange={this.onSerialNumbersChange}/>
        );
    }

    componentDidMount() {
        this.groupNameInput.current && this.groupNameInput.current.focus();
    }
}

export default GroupEditorForm;

// TODO Obviously it doesn't work without knowledge about model
GroupEditorForm.defaultProps = {
    // props
    model: {},
    // functions
    changeGroupEditor: noop,
};

GroupEditorForm.propTypes = {
    // props
    //model: PropTypes.object,
    model: PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string,
    }),
    // functions
    changeGroupEditor: PropTypes.func,
};
