import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
    FOLLOW_ROUTE,
} from '~/features/base/actions/ui/routeActions';

import { stringifyToQuery } from '~/features/base/utils/query';

export function* doFollowRouteSaga(action) {
    const { options } = action.payload;
    const { route, query } = options;
    yield put(push(`${route}?${stringifyToQuery(query)}`));
}

export function* followRouteSaga() {
    yield takeEvery(FOLLOW_ROUTE, doFollowRouteSaga);
}
