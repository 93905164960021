import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { ALL, deviceStatusNameOptions, deviceStatusTypeOptions } from '~/features/base/constants/filterOptions';
import { selectionByValue } from '~/features/base/utils/selectUtils';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';

export class DeviceStatusFormItem extends Component {

    onDeviceStatusNamesChange = (item) => {
        this.props.onDeviceStatusNamesChange({
            deviceStatusNames: item.id === ALL ? undefined : item.value,
        });
    };

    onDeviceStatusTypeChange = (item) => {
        this.props.onDeviceStatusTypeChange({
            deviceStatusType: item.id === ALL ? undefined : item.value,
        });
    };

    render() {
        const {
            labelDeviceStatusNames,
            labelDeviceStatusTypes,
            valueDeviceStatusNames,
            valueDeviceStatusTypes,
        } = this.props;
        const nameOptionsWithSelection = selectionByValue(valueDeviceStatusNames.toString())(deviceStatusNameOptions);
        const typeOptionsWithSelection = selectionByValue(valueDeviceStatusTypes.toString())(deviceStatusTypeOptions);
        return (
            <div className='form-group'>
                <div className='row'>
                    <div className='form-group col-md-6'>
                        <label className='control-label'>
                            <FormattedMessage id={labelDeviceStatusNames}/>
                        </label>
                        <Select options={nameOptionsWithSelection}
                                onChange={this.onDeviceStatusNamesChange}/>
                    </div>
                    <div className='form-group col-md-6'>
                        <label className='control-label'>
                            <FormattedMessage id={labelDeviceStatusTypes}/>
                        </label>
                        <Select options={typeOptionsWithSelection}
                                onChange={this.onDeviceStatusTypeChange}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default DeviceStatusFormItem;

DeviceStatusFormItem.defaultProps = {
    // props
    labelDeviceStatusNames: 'intl-msg:deviceStatus',
    labelDeviceStatusTypes: 'intl-msg:deviceHealthTypes',
    valueDeviceStatusNames: ALL,
    valueDeviceStatusTypes: ALL,
    deviceStatusNames: [],
    deviceStatusTypes: [],
    // functions
    onDeviceStatusNamesChange: noop,
    onDeviceStatusTypeChange: noop,
};

DeviceStatusFormItem.propTypes = {
    // props
    labelDeviceStatusNames: PropTypes.string,
    labelDeviceStatusTypes: PropTypes.string,
    valueDeviceStatusNames: PropTypes.string,
    valueDeviceStatusTypes: PropTypes.string,
    deviceStatusNames: PropTypes.array,
    deviceStatusTypes: PropTypes.array,
    // functions
    onDeviceStatusNamesChange: PropTypes.func,
    onDeviceStatusTypeChange: PropTypes.func,
};
