import React from 'react';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

export const BundleType = () => {
    return (
        <OverlayTrigger placement='top' overlay={
            <Tooltip id='tooltip' className='top-right'>
                Bundle
            </Tooltip>}>
            <span className={'label label-condensed label-primary'}>
                Bundle
            </span>
        </OverlayTrigger>
    );
};

export default BundleType;
