import noop from 'lodash/fp/noop';
import size from 'lodash/fp/size';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import UpdateEventsTable from '~/features/updateEvents/components/UpdateEventsTable';
import UpdateEventListTableToolbar from '~/features/updateEvents/components/UpdateEventListTableToolbar';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';

class DeliverableUpdateEventList extends PureComponent {

    render() {
        const {
            updateEvents,
            updateEventsLoading,
            totalElements,
            showReportButton,
            showSerialNumber,
            showDeliverableId,
            onShowReport,
            onExportUpdateEvents,
            onLoadMore,
            filters,
            onCriteriaChange
        } = this.props;
        const showLoadMore = totalElements > updateEvents.length;
        const toolbar =
            <div key={'updateEventListTableToolbar'} className='padding-top-20 padding-left-10'>
                <UpdateEventListTableToolbar
                    showReportButton={showReportButton}
                    onShowReport={onShowReport}
                    onExportUpdateEvents={onExportUpdateEvents}
                    filters={filters}
                    showFilters
                    onCriteriaChange={onCriteriaChange}>
                    <span className='padding-top-5'>
                        <FormattedMessage id='intl-msg:totalUpdateEvents.phrase'
                                          values={{
                                              totalCount: totalElements,
                                          }}/>
                    </span>
                    <div className='display-inline-block padding-5'>
                        {updateEventsLoading ? <Spinner/> : null}
                    </div>
                </UpdateEventListTableToolbar>
            </div>

        if (!size(updateEvents) && !updateEventsLoading) {
            return (
                <div className='padding-top-20 padding-bottom-20'>
                    {toolbar}
                    <NotFoundState fullWidth
                                   headline={<FormattedMessage id='intl-msg:nothingFound'/>}
                                   message={<FormattedMessage id='intl-msg:noUpdateEventsReportedYet'/>}/>
                </div>
            );
        }

        return [
            <div>
                {toolbar}
            </div>,
            <div key={'updateEventsTable'} className='padding-top-20 padding-bottom-5'>
                <UpdateEventsTable showDeliverableId={showDeliverableId}
                                   showSerialNumber={showSerialNumber}
                                   updateEvents={updateEvents}/>
            </div>,
            (showLoadMore &&
                <div key={'loadMoreBtn'} className='text-center padding-bottom-20'>
                    <a id='load-more-button'
                       className='btn btn-default'
                       onClick={onLoadMore}>
                        <FormattedMessage id='intl-msg:loadMore'/>
                    </a>
                </div>
            )
        ];
    }
}

export default DeliverableUpdateEventList;

DeliverableUpdateEventList.defaultProps = {
    // props
    showReportButton: false,
    showSerialNumber: true,
    showDeliverableId: true,
    updateEvents: [],
    updateEventsLoading: false,
    totalElements: 0,
    // functions
    onShowReport: noop,
    onExportUpdateEvents: noop,
    onLoadMore: noop,
};

DeliverableUpdateEventList.propTypes = {
    // props
    showReportButton: PropTypes.bool,
    showSerialNumber: PropTypes.bool,
    showDeliverableId: PropTypes.bool,
    updateEvents: PropTypes.array,
    updateEventsLoading: PropTypes.bool,
    totalElements: PropTypes.number,
    // functions
    onShowReport: PropTypes.func,
    onExportUpdateEvents: PropTypes.func,
    onLoadMore: PropTypes.func,
};
