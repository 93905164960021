import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';


import SysParamDefinitionStringValueForm from '~/features/sysParams/components/SysParamDefinitionStringValueForm';
import NumberInput from '@rio-cloud/rio-uikit/lib/es/NumberInput';

/**
 * System parameter definition string editor form.
 */
export class SysParamDefinitionStringEditorForm extends PureComponent {

    onStringMaxLengthChange(value) {
        this.props.changeSysParamDefinitionEditor({
            stringMaxLength: value,
        });
    }

    onDefaultValueChange(value) {
        this.props.changeSysParamDefinitionEditor({
            stringDefaultValue: value,
        });
    }

    render() {
        const model = this.props.model;
        const defaultValue = this.renderDefaultValue(model);
        return (
            <div>
                <div className='form-group'>
                    <label className='control-label'>
                        <FormattedMessage id='intl-msg:maxLength' />
                    </label>
                    <NumberInput min={0} value={model.stringMaxLength}
                        onValueChanged={value => this.onStringMaxLengthChange(value)}/>
                </div>
                {defaultValue}
            </div>
        );
    }

    renderDefaultValue(model) {
        if (model.hasDefault) {
            return (
                <SysParamDefinitionStringValueForm
                    value={model.stringDefaultValue}
                    maxLength={model.stringMaxLength}
                    onValueChanged={this.onDefaultValueChange.bind(this)}/>
            );
        }
    }
}

export default SysParamDefinitionStringEditorForm;

SysParamDefinitionStringEditorForm.defaultProps = {
    model: {},
    changeSysParamDefinitionEditor: noop,
};

SysParamDefinitionStringEditorForm.propTypes = {
    // props
    model: PropTypes.object,
    // functions
    changeSysParamDefinitionEditor: PropTypes.func,
};
