import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import DeviceReportSummary from '~/features/deviceReports/components/DeviceReportSummary';
import DeviceReportJSON from '~/features/deviceReports/components/DeviceReportJSON';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import { FormattedMessage } from 'react-intl';

const formatDateTime = date => date.format('DD.MM.YYYY HH:mm:ss');

const renderCopyLink = (copyLink, id) => {
    return <OverlayTrigger placement='top' overlay={
        <Tooltip id='tooltip' className='top-left'>
            <FormattedMessage id='intl-msg:copyDirectReportLinkId' values={{ id }}/>
        </Tooltip>}>
        <span onClick={() => copyLink(id)} className='rioglyph rioglyph-link text-color-dark text-size-h5 margin-left-15'>
        </span>
    </OverlayTrigger>;
};

const renderHeadline = (operation, copyLink) => {
    const from = moment(operation.actionsAddedTimestamp);
    return (
        <p className='text-size-16 text-bold'>
            {`#${operation.operationId} ${formatDateTime(from)}`}
            {renderCopyLink(copyLink, operation.operationId)}
        </p>
    );
};

const renderContent = (operation, copyLink) => {
    const headline = renderHeadline(operation, copyLink);
    return (
        <div>
            {headline}
            {operation.report || operation?.deviceState?.isRebootPending ? <DeviceReportSummary operation={operation}/> : null}
            <DeviceReportJSON operation={operation}/>
        </div>
    );
};

export const DeviceReportOperation = ({ operation, copyLink }) => {
    const content = renderContent(operation, copyLink);
    return (
        <div className='margin-top-25'>
            {content}
        </div>
    );
};

export default DeviceReportOperation;

DeviceReportOperation.defaultProps = {
    operation: {},
};

DeviceReportOperation.propTypes = {
    // props
    operation: PropTypes.object,
};
