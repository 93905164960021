import {
    entityStoreSelector,
    loadingStoreSelector,
    uiStoreSelector,
} from '~/features/higherorder/selectors/dataSelectors';
import { ENTITY_DELIVERABLES_USER_INFO } from '~/features/base/constants/entities';
import { createSelector } from 'reselect';
import {
    DELIVERABLE_USER_INFO_PERMISSIONS,
    PERMISSION_DELIVERABLE_FILE_DELETE,
    PERMISSION_DELIVERABLE_FILE_READ,
    PERMISSION_DELIVERABLE_FILE_WRITE,
} from '~/features/base/constants/permissions';

const deliverableUserInfoEntitiesSelector = state => entityStoreSelector(state)[ENTITY_DELIVERABLES_USER_INFO];
export const deliverableUserFilePermissionsSelector = createSelector(
    [deliverableUserInfoEntitiesSelector],
    (entities) => {
        return entities ? entities[DELIVERABLE_USER_INFO_PERMISSIONS] : [];
    },
);

export const canReadDeliverableFilesSelector = state =>
    deliverableUserFilePermissionsSelector(state).includes(PERMISSION_DELIVERABLE_FILE_READ);
export const canWriteDeliverableFilesSelector = state =>
    deliverableUserFilePermissionsSelector(state).includes(PERMISSION_DELIVERABLE_FILE_WRITE);
export const canDeleteDeliverableFilesSelector = state =>
    deliverableUserFilePermissionsSelector(state).includes(PERMISSION_DELIVERABLE_FILE_DELETE);


export const allDeliverableFilesSelector = state => uiStoreSelector(state)['deliverableFiles'];
export const deliverableFilesLoadingSelector = state => loadingStoreSelector(state)['deliverableFiles'];
