import defaults from 'lodash/fp/defaults';
import split from 'lodash/fp/split';
import compact from 'lodash/fp/compact';
import noop from 'lodash/fp/noop';
import isEmpty from 'lodash/fp/isEmpty';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
    ALL,
    createBaseSwVersionOptions,
    createDistroVersionOptions,
    createHwVariantOptions,
    createVehicleSparePartNumberOptions,
} from '~/features/base/constants/filterOptions';

import { followRoute } from '~/features/base/actions/ui/routeActions';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import { fetchFilteredGroups } from '~/features/groups/actions/groupActions';

import { distroVersionsSelector } from '~/features/deliverables/features/distros/selectors/distroSelectors';
import { baseSwVersionsSelector } from '~/features/baseSwVersions/selectors/baseSwVersionSelectors';
import { groupsSelector } from '~/features/groups/selectors/groupSelectors';
import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';

import WhitelistingDetailsEditorFormItem from '~/features/base/components/forms/WhitelistingDetailsEditorFormItem';
import ActivatedFormItem from '~/features/base/components/forms/ActivatedFormItem';
import BlockedFormItem from '~/features/base/components/forms/BlockedFormItem';
import TestReleaseActiveFormItem from '~/features/base/components/forms/TestReleaseActiveFormItem';
import SerialNumberPrefixFormItem from '~/features/base/components/forms/SerialNumberPrefixFormItem';
import SuggestFormItem from '~/features/base/components/forms/SuggestFormItem';

import { parseQuery } from '~/features/base/utils/query';
import uid from '~/features/base/utils/uid';
import FromFormItem from '~/features/base/components/forms/FromFormItem';
import { ENTITY_CONTROL_DEVICE } from '~/features/base/constants/entities';
import { saveFilterSettings } from '~/features/base/actions/ui/filterActions';
import { filterSettingsControlDevicesSelector } from '~/features/devices/selectors/controlDeviceSelectors';
import DeviceTypeFormItem from '~/features/base/components/forms/DeviceTypeFormItem';
import { hwVariantsSelector } from '~/features/hwVariants/selectors/hwVariantsSelectors';
import VINFormItem from '~/features/base/components/forms/VINFormItem';
import {
    vehicleSparePartNumbersSelector,
} from '~/features/vehicleSparePartNumbers/selectors/vehicleSparePartNumbersSelectors';
import AutoSuggest from '@rio-cloud/rio-uikit/lib/es/AutoSuggest';
import { fetchFilteredDistros } from '~/features/deliverables/features/distros/actions/distroActions';

const defaultFilters = {
    serialNumberPrefix: '',
    vinPrefix: '',
    hwVariant: ALL,
    distroVersion: ALL,
    excludeDistroVersions: [],
    swVersion: ALL,
    vehicleGroupWhitelist: [],
    deviceWhitelist: [],
    vehicleGroupBlacklist: [],
    deviceBlacklist: [],
    inCustomerFleet: ALL,
    testReleasesActive: ALL,
    updatesActive: ALL,
    lastCheckForUpdateAfter: null,
};

export const toUpdateEventsScope = (serialNumber) => serialNumber;

export class DeviceDetailsContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.name = uid();
        // TODO Is this still needed?
        this.state = {
            isValidDeviceWhitelist: true,
            isValidDeviceBlacklist: true,
        };
    }

    onSearchFilterChange = ({ serialNumberPrefix }) => {
        this.updateFilter({
            serialNumberPrefix,
        });
    };
    onVINFilterChange = ({ vinPrefix }) => {
        this.updateFilter({
            vinPrefix,
        });
    };
    onHwVariantFilterChange = ({ suggestionId }) => {
        this.updateFilter({
            hwVariant: suggestionId,
        });
    };
    onDistroVersionFilterChange = ({ suggestionId }) => {
        this.updateFilter({
            distroVersion: suggestionId,
        });
    };
    onExcludeDistroVersionsFilterChange = ({ excludeDistroVersions }) => {
        this.updateFilter({
            excludeDistroVersions,
        });
    };
    onBaseSwVersionFilterChange = ({ suggestionId }) => {
        this.updateFilter({
            swVersion: suggestionId,
        });
    };
    onWhitelistingInfoChange = (info) => {
        const {
            isValidDeviceWhitelist,
            deviceWhitelist,
            vehicleGroupWhitelist,
            isValidDeviceBlacklist,
            deviceBlacklist,
            vehicleGroupBlacklist,
        } = info;

        const filterUpdate = {
            vehicleGroupWhitelist,
            vehicleGroupBlacklist,
            deviceWhitelist: isValidDeviceWhitelist ? deviceWhitelist : undefined,
            deviceBlacklist: isValidDeviceBlacklist ? deviceBlacklist : undefined,
        };
        this.setState({
            isValidDeviceWhitelist: isValidDeviceWhitelist,
            isValidDeviceBlacklist: isValidDeviceBlacklist,
        });
        this.updateFilter(filterUpdate);
    };
    onAddVehicleSparePartNumber = (_, { suggestion }) => {
        this.updateFilter({
            vehicleManufacturerSparePartNumber: suggestion.label,
        });
    };
    onClearVehicleManufacturerSparePartNumber = () => {
        this.updateFilter({
            vehicleManufacturerSparePartNumber: undefined,
        });
    };
    onDeviceTypeFilterChange = ({ deviceType }) => {
        this.updateFilter({
            deviceType,
        });
    };
    onInCustomerFleetFilterChange = ({ inCustomerFleet }) => {
        this.updateFilter({
            inCustomerFleet,
        });
    };
    onUpdatesActiveFilterChange = ({ updatesActive }) => {
        this.updateFilter({
            updatesActive,
        });
    };
    onTestReleasesActiveFilterChange = ({ testReleasesActive }) => {
        this.updateFilter({
            testReleasesActive,
        });
    };
    onLastCheckForUpdateChange = ({ value }) => {
        this.updateFilter({
            lastCheckForUpdateAfter: value,
        });
    };
    onClearFiltersClicked = () => {
        this.updateFilter({
            serialNumberPrefix: undefined,
            vinPrefix: undefined,
            hwVariant: undefined,
            deviceType: undefined,
            distroVersion: undefined,
            excludeDistroVersions: undefined,
            swVersion: undefined,
            vehicleManufacturerSparePartNumber: undefined,
            vehicleGroupWhitelist: undefined,
            deviceWhitelist: undefined,
            vehicleGroupBlacklist: undefined,
            deviceBlacklist: undefined,
            inCustomerFleet: undefined,
            updatesActive: undefined,
            testReleasesActive: undefined,
            lastCheckForUpdateAfter: undefined,
        });
    };
    updateFilter = (filters) => {
        const skipSendRequest = filters.lastCheckForUpdateAfter !== undefined && isNaN(filters.lastCheckForUpdateAfter);
        if (skipSendRequest) {
            return;
        }
        const { pathname, search } = this.props;
        const query = parseQuery(search);
        this.props.followRoute({
            route: pathname, query: {
                ...query,
                ...filters,
                page: 1,
            },
        });
    };

    render() {
        const { distros, baseSoftwareVersions, hwVariants, vehicleSparePartNumbers, groups, search } = this.props;
        const parsedQuery = parseQuery(search);
        const properQuery = {
            ...parsedQuery,
            excludeDistroVersions: compact(split(',', parsedQuery.excludeDistroVersions)),
            vehicleGroupWhitelist: compact(split(',', parsedQuery.vehicleGroupWhitelist)),
            deviceWhitelist: compact(split(',', parsedQuery.deviceWhitelist)),
            vehicleGroupBlacklist: compact(split(',', parsedQuery.vehicleGroupBlacklist)),
            deviceBlacklist: compact(split(',', parsedQuery.deviceBlacklist)),
            isValidDeviceWhitelist: this.state.isValidDeviceWhitelist,
            isValidDeviceBlacklist: this.state.isValidDeviceBlacklist,
        };
        const defaultedQuery = defaults(defaultFilters, properQuery);

        const distroVersionOptions = createDistroVersionOptions(distros);
        // const excludeDistroVersionOptions = createExcludeDistroVersionOptions(distros);
        const baseSwVersionOptions = createBaseSwVersionOptions(baseSoftwareVersions);
        const hwVariantOptions = createHwVariantOptions(hwVariants);
        const vehicleSparePartNumberOptions = createVehicleSparePartNumberOptions(vehicleSparePartNumbers);
        return ([
                <div className='text-center margin-top-15'>
                    <a id='clear-filters-button'
                       className='btn btn-default'
                       onClick={this.onClearFiltersClicked}>
                        <span className='rioglyph rioglyph-trash' aria-hidden='true'></span>&nbsp;
                        <FormattedMessage id='intl-msg:clearFilters'/>
                    </a>
                </div>,
                <div className='margin-20'>
                    <form>
                        <SerialNumberPrefixFormItem value={defaultedQuery.serialNumberPrefix}
                                                    onChange={this.onSearchFilterChange}/>
                        <VINFormItem value={defaultedQuery.vinPrefix} onChange={this.onVINFilterChange}/>
                        <SuggestFormItem value={defaultedQuery.hwVariant}
                                         label='intl-msg:hwVariant'
                                         options={hwVariantOptions}
                                         onChange={this.onHwVariantFilterChange}/>
                        <DeviceTypeFormItem value={defaultedQuery.deviceType}
                                            showOptionAll={true}
                                            onChange={this.onDeviceTypeFilterChange}/>
                        <SuggestFormItem value={defaultedQuery.distroVersion}
                                         label='intl-msg:distroVersion'
                                         options={distroVersionOptions}
                                         onChange={this.onDistroVersionFilterChange}/>
                        {/*<ExcludeDistroSelectFormItem values={defaultedQuery.excludeDistroVersions}*/}
                        {/*                             options={excludeDistroVersionOptions}*/}
                        {/*                             onChange={this.onExcludeDistroVersionsFilterChange}/>*/}
                        <SuggestFormItem value={defaultedQuery.swVersion}
                                         label='intl-msg:baseSwVersion'
                                         options={baseSwVersionOptions}
                                         onChange={this.onBaseSwVersionFilterChange}/>
                        <WhitelistingDetailsEditorFormItem whitelistingInfo={defaultedQuery}
                                                           groups={groups}
                                                           deviceWhitelistLabel='intl-msg:includeDevices'
                                                           groupWhitelistLabel='intl-msg:includeGroups'
                                                           deviceBlacklistLabel='intl-msg:excludeDevices'
                                                           groupBlacklistLabel='intl-msg:excludeGroups'
                                                           onWhitelistingInfoChange={this.onWhitelistingInfoChange}/>
                        <label className='control-label'>
                            <FormattedMessage id='intl-msg:manufacturerSparePartNumber'/>
                        </label>
                        <div className='form-group'>
                            <AutoSuggest
                                inputProps={{
                                    value: defaultedQuery.vehicleManufacturerSparePartNumber,
                                    onClear: this.onClearVehicleManufacturerSparePartNumber,
                                }}
                                suggestions={vehicleSparePartNumberOptions}
                                onSuggestionSelected={this.onAddVehicleSparePartNumber}
                            />
                        </div>
                        <ActivatedFormItem value={defaultedQuery.inCustomerFleet}
                                           onChange={this.onInCustomerFleetFilterChange}/>
                        <BlockedFormItem value={defaultedQuery.updatesActive}
                                         onChange={this.onUpdatesActiveFilterChange}/>
                        <TestReleaseActiveFormItem value={defaultedQuery.testReleasesActive}
                                                   onChange={this.onTestReleasesActiveFilterChange}/>
                        <FromFormItem
                            value={defaultedQuery.lastCheckForUpdateAfter}
                            label='intl-msg:lastcheckForUpdateAfter'
                            onChange={this.onLastCheckForUpdateChange}/>


                    </form>
                </div>,
            ]
        );
    }

    componentWillMount() {
        this.props.registerDataInterest(this.name, [
            fetchFilteredDistros({ // TODO Replace by something smaller, just a list of shortDistroVersions
                page: 0,
                size: 1000,
                searchCriteria: {},
            }),
            fetchFilteredGroups(),
        ]);

        // recover previous filter settings
        const { pathname, search } = this.props;
        let query = parseQuery(search);
        // recover previous filters settings only if no new filter settings in routing path
        if (isEmpty(query)) {
            query = this.props.previousFilterSettings;
        }
        this.props.saveFilterSettings(query);
        this.props.followRoute({
            route: pathname, query: {
                ...query,
                page: 1,
            },
        });
    }

    componentWillUnmount() {
        const { search } = this.props;
        const query = parseQuery(search);
        this.props.saveFilterSettings(query);
        this.props.unregisterDataInterest(this.name);
    }
}

const mapStateToProps = (state, ownProps) => ({
    pathname: pathnameSelector(state),
    search: searchSelector(state),
    distros: distroVersionsSelector(state, ownProps),
    baseSoftwareVersions: baseSwVersionsSelector(state),
    hwVariants: hwVariantsSelector(state),
    vehicleSparePartNumbers: vehicleSparePartNumbersSelector(state),
    groups: groupsSelector(state),
    previousFilterSettings: filterSettingsControlDevicesSelector(state),
});

const mapDispatchToProps = dispatch => ({
    saveFilterSettings: (payload) => {
        dispatch(saveFilterSettings(ENTITY_CONTROL_DEVICE, payload));
    },
    followRoute: (options) => {
        dispatch(followRoute(options));
    },
    registerDataInterest: (name, options) => {
        dispatch(registerDataInterest(name, options));
    },
    unregisterDataInterest: (name) => {
        dispatch(unregisterDataInterest(name));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetailsContainer);

DeviceDetailsContainer.defaultProps = {
    // props
    pathname: '',
    search: '',
    distros: [],
    baseSoftwareVersions: [],
    hwVariants: [],
    vehicleSparePartNumbers: [],
    selectedVehicleSparePartNumbers: [],
    groups: [],
    previousFilterSettings: [],
    // functions
    followRoute: noop,
    saveFilterSettings: noop,
    registerDataInterest: noop,
    unregisterDataInterest: noop,
};

DeviceDetailsContainer.propTypes = {
    // props
    pathname: PropTypes.string,
    search: PropTypes.string,
    distros: PropTypes.array,
    baseSoftwareVersions: PropTypes.array,
    hwVariants: PropTypes.array,
    vehicleSparePartNumbers: PropTypes.array,
    selectedVehicleSparePartNumbers: PropTypes.array,
    groups: PropTypes.array,
    previousFilterSettings: PropTypes.array,
    // functions
    followRoute: PropTypes.func,
    saveFilterSettings: PropTypes.func,
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
};
