import PropTypes from 'prop-types';
import React from 'react';
import { DELIVERABLES_PATH, APPS_PATH } from '~/features/base/constants/routes';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import Chip from '~/features/base/components/Chip';
import { openInNewTab } from '~/features/base/utils/openInNewTab';
import classNames from 'classnames';

// TODO This component required shortPackageVersion, but gets packageVersion ...
export const PackageReleaseChip = ({
    packageId,
    packageVersion,
    toolTipMessage = '',
    showPackageId,
    bsStyle,
    className,
    isShortVersion,
}) => {
    const shortPackageVersion = !isShortVersion ? toShortSemanticVersion(packageVersion) : packageVersion;
    const label = showPackageId ? `${packageId}@${shortPackageVersion}` : `${shortPackageVersion}`;
    const handleOnClick = () => openInNewTab(`/#${DELIVERABLES_PATH}/${APPS_PATH}?deliverableIdPrefix=${packageId}&deliverableVersion=${shortPackageVersion}`);
    return (
        <Chip onClick={handleOnClick} bsStyle={bsStyle} className={classNames('clickable', className)}
              toolTipMessage={toolTipMessage}>{label}</Chip>
    );
};
export default PackageReleaseChip;

PackageReleaseChip.defaultProps = {
    // props
    showPackageId: false,
};

PackageReleaseChip.propTypes = {
    // props
    packageId: PropTypes.string,
    packageVersion: PropTypes.object,
    showPackageId: PropTypes.bool,
    bsStyle: PropTypes.string,
    className: PropTypes.string,
};
