import getOr from 'lodash/fp/getOr';
import noop from 'lodash/fp/noop';

import classNames from 'classnames';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { deliverablesContextsSelector } from '~/features/artifacts/selectors/deliverableSelectors';
import { DELIVERABLE_SCHEMA } from '~/features/deliverables/constants/deliverablesParameters';
import ArtifactEditorForm from '~/features/deliverables/features/artifacts/components/ArtifactEditorForm';
import {
    changeArtifactEditor,
    createArtifact,
    updateArtifact,
} from '~/features/deliverables/features/artifacts/actions/artifactActions';
import { artifactEditorSelector } from '~/features/deliverables/features/artifacts/selectors/artifactSelectors';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';

/**
 * Artifact editor dialog
 */
export class ArtifactEditorDialog extends PureComponent {

    onSaveDeliverable = () => {
        const { model } = this.props;

        // if (!this.isValid(model)) {
        //     return;
        // }
        if (model.isNew) {
            const newDeliverable = {
                'context': model.owningContext,
                'otaId': model.deliverableId,
                'otaVersion': model.deliverableVersion,
                'deliverableName': model.artifactName,
                'schema': DELIVERABLE_SCHEMA,
                'type': model.deliverableType,
                'file': model.file,
            };
            this.props.createArtifact(newDeliverable);
        } else {
            // this.props.updateArtifact(model);
        }
    };

    isValid(model) {
        return !!model.deliverableName;
    }

    render() {
        const { model, contexts } = this.props;
        const title = this.renderTitle(model);
        const body = this.renderBody(model, contexts);
        const footer = this.renderFooter(model);
        return (
            <Dialog className='file-deliverable-editor-dialog'
                    show={true}
                    showCloseButton={true}
                    onHide={this.props.hideModal}
                    title={title}
                    body={body}
                    footer={footer}
                    useOverflow
            />
        );
    }

    renderTitle(model) {
        const isNew = getOr(true, 'isNew', model);
        if (isNew) {
            return <FormattedMessage id='intl-msg:createArtifact'/>;
        }
        return <FormattedMessage id='intl-msg:editDeliverable.title' values={{
            artifactName: model.artifactName,
        }}/>;
    }

    renderBody(model, contexts) {
        return (
            <ArtifactEditorForm model={model}
                                contexts={contexts}
                                changeArtifactEditor={this.props.changeArtifactEditor}/>
        );
    }

    renderFooter(model) {
        const isValid = true;
        const isInProgress = getOr(false, 'isInProgress', model);

        const isReady = isValid && !isInProgress;
        return (
            <div>
                <button className='btn btn-default' onClick={this.props.hideModal}>
                    <FormattedMessage id='intl-msg:close'/>
                </button>
                <button className={classNames('btn btn-primary', { disabled: !isReady })}
                        onClick={this.onSaveDeliverable}>
                    {
                        isInProgress ?
                            <Spinner text={<FormattedMessage id='intl-msg:save'/>}/> :
                            <FormattedMessage id='intl-msg:save'/>
                    }
                </button>
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        model: artifactEditorSelector(state),
        contexts: deliverablesContextsSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        createArtifact: (newArtifact) => {
            dispatch(createArtifact(newArtifact));
        },
        updateArtifact: (softwareVersion) => {
            dispatch(updateArtifact(softwareVersion));
        },
        changeArtifactEditor: payload => {
            dispatch(changeArtifactEditor(payload));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArtifactEditorDialog);

ArtifactEditorDialog.defaultProps = {
    // props
    model: {},
    contexts: [],
    // functions
    createArtifact: noop,
    updateArtifact: noop,
    changeArtifactEditor: noop,
};

ArtifactEditorDialog.propTypes = {
    // props
    model: PropTypes.object,
    contexts: PropTypes.array,
    // functions
    createArtifact: PropTypes.func,
    updateArtifact: PropTypes.func,
    changeArtifactEditor: PropTypes.func,
};
