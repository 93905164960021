export const FETCH_CONTROL_DEVICE_ROLLOUT_STATES = 'FETCH_CONTROL_DEVICE_ROLLOUT_STATES';
export const SET_SHOW_DEVICE_DETAILS_SIDEBAR = 'SET_SHOW_DEVICE_DETAILS_SIDEBAR';
export const SET_SELECTED_TAB_DEVICE_DETAILS_SIDEBAR = 'SET_SELECTED_TAB_DEVICE_DETAILS_SIDEBAR';
export const TOGGLE_DEVICE_DETAILS_SIDEBAR = 'TOGGLE_DEVICE_DETAILS_SIDEBAR';
export const HIDE_DEVICE_DETAILS_SIDEBAR = 'HIDE_DEVICE_DETAILS_SIDEBAR';
export const FETCH_DEVICE_STATUS_NAMES = 'FETCH_DEVICE_STATUS_NAMES';
export const FETCH_DEVICE_STATUS_TYPES = 'FETCH_DEVICE_STATUS_TYPES';

export function setShowDeviceDetailsSidebar(serialNumber) {
    return {
        type: SET_SHOW_DEVICE_DETAILS_SIDEBAR,
        serialNumber,
    };
}

export function setSelectedTabDeviceDetailsSidebar(tabId) {
    return {
        type: SET_SELECTED_TAB_DEVICE_DETAILS_SIDEBAR,
        tabId,
    };
}

export function toggleDeviceDetailsSidebar() {
    return {
        type: TOGGLE_DEVICE_DETAILS_SIDEBAR,
    };
}

export function hideDeviceDetailsSidebar() {
    return {
        type: HIDE_DEVICE_DETAILS_SIDEBAR,
    };
}

export const fetchControlDeviceRolloutStates = ({ serialNumber, limit }) => {
    return {
        type: FETCH_CONTROL_DEVICE_ROLLOUT_STATES,
        payload: {
            serialNumber,
            limit,
        },
    };
};

export const fetchDeviceStatusNames = () => {
    return {
        type: FETCH_DEVICE_STATUS_NAMES,
    };
};

export const fetchDeviceStatusTypes = () => {
    return {
        type: FETCH_DEVICE_STATUS_TYPES,
    };
};
