import map from 'lodash/fp/map';

import {
    toShortSemanticVersion,
} from '~/features/base/utils/versionNumberConverter';

export const parseSysParamSystemValue = sysParamSystemValue => {
    const shortBaseSwVersion = toShortSemanticVersion(sysParamSystemValue.baseSoftwareVersion);
    return {
        ...sysParamSystemValue,
        shortBaseSwVersion,
    };
};

export const parseSysParamSystemValues = sysParamSystemValues => map(parseSysParamSystemValue, sysParamSystemValues);
