import React, { useEffect } from 'react';

const useOutsideClick = (ref: React.MutableRefObject<any>, callback: (event: React.MouseEvent<HTMLElement>) => void) => {

    useEffect(() => {
        const handleClick = (event) => {
            if ((ref.current instanceof Element) && !ref?.current.contains(event.target as Node)) {
                callback(event);
            }
        };
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    return ref;
};

export default useOutsideClick;
