import React from 'react';
import { FormattedTime } from 'react-intl';
import FieldOrNoData from '~/features/base/components/FieldOrNoData';
import SystemStateNotificationType from '~/features/systemState/components/SystemStateNotificationType';
import SystemStateNotificationTopCpu from '~/features/systemState/components/SystemStateNotificationTopCpu';

export const systemStateNotificationsColumnDescriptors = [{
    id: 'timestamp',
    field: 'timestamp',
    label: 'intl-msg:time',
    selected: true,
    format: field => <FormattedTime value={field} year='numeric' month='2-digit' day='2-digit'/>,
}, {
    id: 'messageType',
    field: 'messageTypeEnum',
    label: 'intl-msg:controlDevice.systemstate.messageType',
    format: field => <SystemStateNotificationType value={field}/>,
}, {
    id: 'cpu_usage',
    field: 'cpu_usage',
    label: 'intl-msg:controlDevice.systemstate.cpu_usage',
    format: (field, item) => <SystemStateNotificationTopCpu field={field} topProcesses={item.topCpuProcesses}/>,
}, {
    id: 'mem_usage',
    field: 'mem_usage',
    label: 'intl-msg:controlDevice.systemstate.mem_usage',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'mem_free',
    field: 'mem_free',
    label: 'intl-msg:controlDevice.systemstate.mem_free',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'processesNotRunning',
    field: 'processesNotRunning',
    label: 'intl-msg:controlDevice.systemstate.processesNotRunning',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'boot_bank_switch_reason',
    field: 'boot_bank_switch_reason',
    label: 'intl-msg:controlDevice.systemstate.boot_bank_switch_reason',
    format: field => <FieldOrNoData field={field}/>,
}];
