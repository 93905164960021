
export const SET_SHOW_FILTER_VIEW = 'SET_SHOW_FILTER_VIEW';
export const SAVE_FILTER_SETTINGS = 'SAVE_FILTER_SETTINGS';

export function setShowFilterView(payload) {
    return {
        type: SET_SHOW_FILTER_VIEW,
        payload,
    };
}

export function saveFilterSettings(filterName, payload) {
    return {
        type: SAVE_FILTER_SETTINGS,
        filterName,
        payload,
    };
}
