import {
    RESET_CONTROL_DEVICE_EDITOR,
    CHANGE_CONTROL_DEVICE_EDITOR,
} from '~/features/devices/actions/controlDeviceEditorActions';
import {
    CREATE_CONTROL_DEVICE,
    UPDATE_CONTROL_DEVICE,
} from '~/features/devices/actions/controlDeviceActions';
import { toFinished } from '~/features/higherorder/transforms/actionTransforms';
import { TBM3 } from '~/features/devices/constants/deviceTypes';

/*const DEFAULT_SOFTWARE_VERSION = {
    major: 1,
    minor: 1,
    patch: 13,
};*/
const defaultState = {
    isNew: false,
    isInProgress: false,
    serialNumber: undefined,
    type: TBM3,
    //lastBaseSoftwareVersionReported: DEFAULT_SOFTWARE_VERSION,
    isValidSerialNumber: false,
    updatesActive: true,
    testReleasesActive: false,
    inCustomerFleet: false,
    description: '',
};

/**
 * Reducer for control device editor
 * @param {object} state The current state
 * @param {object} action The action to be processed
 * @returns {object} The new state
 */
export default function controlDeviceEditorReducer(state = defaultState, action = {}) {
    let newState;
    switch (action.type) {
        case RESET_CONTROL_DEVICE_EDITOR:
            newState = {
                ...defaultState,
            };
            return newState;
        case CHANGE_CONTROL_DEVICE_EDITOR:
            newState = {
                ...state,
                ...action.payload,
            };
            return newState;
        case CREATE_CONTROL_DEVICE:
        case UPDATE_CONTROL_DEVICE:
            newState = {
                ...state,
                isInProgress: true,
            };
            return newState;
        case toFinished(CREATE_CONTROL_DEVICE):
        case toFinished(UPDATE_CONTROL_DEVICE):
            newState = {
                ...state,
                isInProgress: false,
            };
            return newState;
        default:
            return state;
    }
}
