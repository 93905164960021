import size from 'lodash/fp/size';
import sortBy from 'lodash/fp/sortBy';
import includes from 'lodash/fp/includes';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import TableSearch from '@rio-cloud/rio-uikit/lib/es/TableSearch';
import Switch from '@rio-cloud/rio-uikit/lib/es/Switch';
import DeviceManagedDeliverablesTable from '~/features/oldVehicles/components/devices/DeviceManagedDeliverablesTable';

const MANAGED_DELIVERABLES = 'managedDeliverables';
const ALL_DELIVERABLES = 'allDeliverables';

/**
 * Component for the device artifacts
 */
const deliverableTypes = [{
    id: '1',
    label: 'APP',
}, {
    id: '2',
    label: 'FILE',
}, {
    id: '3',
    label: 'DISTRO',
}];

export class DeviceDeliverables extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            mode: MANAGED_DELIVERABLES,
            search: '',
            deliverableTypes: [],
        };
    }

    onDeliverableSearchChange = (text) => {
        this.setState({
            search: text,
        });
    };

    onDeliverableTypeChange = (type) => {
        this.setState({
            deliverableTypes: type,
        });
    };

    onPackageDisplayOptionChange = () => {
        if (this.state.mode === MANAGED_DELIVERABLES) {
            this.setState({
                mode: ALL_DELIVERABLES,
            });
        } else {
            this.setState({
                mode: MANAGED_DELIVERABLES,
            });
        }
    };
    mergePackageWithEligiblePackageVersions = (lastPackageVersionsReported, eligiblePackageVersions) => {
        let mergedPackages = [];
        // add installed packages
        lastPackageVersionsReported.map((currentPackage) => {
            // add eligible package version if available
            if (eligiblePackageVersions) {
                const eligiblePackageVersion = eligiblePackageVersions[currentPackage.packageId];
                if (eligiblePackageVersion) {
                    currentPackage.eligiblePackageVersion = eligiblePackageVersion;
                    delete eligiblePackageVersions[currentPackage.packageId];
                }
            }
            mergedPackages.push(currentPackage);
        });
        // add eligible only packages
        if (eligiblePackageVersions) {
            Object.entries(eligiblePackageVersions).map((currentPackageVersion) => {
                mergedPackages.push({
                    packageId: currentPackageVersion[0],
                    eligiblePackageVersion: currentPackageVersion[1],
                });
            });
        }
        return mergedPackages;
    };

    render() {
        const { controlDeviceInfo, controlDeviceEligiblePackageVersions } = this.props;
        if (!controlDeviceInfo) {
            return null;
        }
        const viewModeSelect = this.renderViewModeSelect();

        if (!size(controlDeviceInfo.installedDeliverables) && !size(controlDeviceEligiblePackageVersions)) {
            return (
                <DefaultWhiteColumn className='padding-top-20 padding-bottom-20'>
                    <div className={'display-flex justify-content-end margin-bottom-10 padding-top-10'}>
                        {viewModeSelect}
                    </div>
                    <NotFoundState fullWidth
                                   headline={<FormattedMessage id='intl-msg:nothingFound'/>}
                                   message={<FormattedMessage id='intl-msg:noPackagesReportedYet'/>}/>
                </DefaultWhiteColumn>
            );
        }
        const table = this.renderTable(controlDeviceInfo, controlDeviceEligiblePackageVersions);
        return [
            <div>
                <div className='table-toolbar col-md-4 padding-bottom-5 margin-left-20'>
                    <TableSearch
                        id='packageSearchInput'
                        value={this.state.search}
                        onChange={this.onDeliverableSearchChange}
                        placeholder={'Search deliverable name'}
                    />
                </div>
                {/*<div className='col-md-4'>*/}
                {/*    <Multiselect*/}
                {/*        placeholder={'Select deliverable type...'}*/}
                {/*        options={deliverableTypes}*/}
                {/*        value={this.state.deliverableTypes}*/}
                {/*        onChange={this.onDeliverableTypeChange}*/}
                {/*    />*/}
                {/*</div>*/}
                <div className='btn-toolbar col-md-3 padding-top-10'>
                    {viewModeSelect}
                </div>
            </div>,
            <div>
                {table}
            </div>,
        ];
    }

    renderViewModeSelect() {
        return (
            <div className='btn-toolbar'>
                <span>
                    <label><FormattedMessage id='intl-msg:allPackages'/></label>
                </span>
                <span>
                    <Switch onChange={() => this.onPackageDisplayOptionChange()}
                            checked={this.state.mode === ALL_DELIVERABLES}/>
                </span>
            </div>
        );
    }

    renderTable(controlDeviceInfo, controlDeviceEligiblePackageVersions) {
        if (this.state.mode === ALL_DELIVERABLES) {
            // sort deliverable names
            const sortedDeliverables = sortBy('deliverableId', controlDeviceInfo.installedDeliverablesString);
            const filteredDeliverables = sortedDeliverables.filter(
                currentPackage => includes(this.state.search.toLowerCase(),
                    currentPackage.deliverableId.toLowerCase()));
            return (
                <div className='col-md-12 padding-top-15'>
                    <DeviceManagedDeliverablesTable deliverables={filteredDeliverables}/>
                </div>
            );
        }
        // sort package names
        const sortedDeliverables = sortBy('deliverableId', controlDeviceInfo.installedDeliverables);
        const filteredDeliverables = sortedDeliverables.filter(
            currentPackage => {
                return includes(this.state.search.toLowerCase(), currentPackage.deliverableId.toLowerCase());
            },
        );
        return (
            <div className='col-md-12 padding-top-15'>
                <DeviceManagedDeliverablesTable
                    deliverables={this.mergePackageWithEligiblePackageVersions(
                        filteredDeliverables, controlDeviceEligiblePackageVersions)}/>
            </div>
        );
    }

    componentDidMount() {
        const input = document.getElementById('packageSearchInput');
        if (input) {
            input.focus();
        }
    }
}

export default DeviceDeliverables;

DeviceDeliverables.propTypes = {
    // props
    controlDeviceInfo: PropTypes.object,
    controlDeviceEligiblePackageVersions: PropTypes.object,
    // functions
};
