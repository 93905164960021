import { connect } from 'react-redux';
import { followRoute } from '~/features/base/actions/ui/routeActions';
import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';
import FotaL3Details from '~/features/fotaL3/components/FotaL3Details';

const mapStateToProps = (state) => {
    return {
        pathname: pathnameSelector(state),
        search: searchSelector(state),
        // serialNumber: '210119-0193',
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        followRoute: (options) => {
            dispatch(followRoute(options));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FotaL3Details);
