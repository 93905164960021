import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

// Import language configuration
import { hideSessionExpiredDialog } from '~/features/app/actions';
import { getSessionExpiredAcknowledged } from '~/features/app/selectors';
import { getLanguageData, getLocale } from '~/features/lang/selectors';
import { isUserSessionExpired } from '~/features/login/selectors';

import ApplicationFrame from '~/features/base/components/ApplicationFrame';

import { env } from '~/env';
const { homeRoute, appMenuUri, userMenuUri } = env.runtimeConfig;

export class AppContainer extends PureComponent {

    render() {
        const {
            hideSessionDialog,
            languageData,
            showSessionExpired,
            userLocale,
        } = this.props;

        return (
            <ApplicationFrame
                homeRoute={homeRoute}
                appMenuUri={appMenuUri}
                userMenuUri={userMenuUri}
                userLocale={userLocale}
                languageData={languageData}
                showSessionExpired={showSessionExpired}
                onHideSessionDialog={hideSessionDialog}>
                {this.props.children}
            </ApplicationFrame>
        );
    }
}

export const mapDispatchToProps = (dispatch) => ({
    hideSessionDialog: () => dispatch(hideSessionExpiredDialog()),
});

export const mapStateToProps = (state) => ({
    languageData: getLanguageData(state),
    showSessionExpired: isUserSessionExpired(state) && !getSessionExpiredAcknowledged(state),
    userLocale: getLocale(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
