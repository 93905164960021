import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    OVERVIEW_PATH,
    LOG_REQUESTS_PATH,
} from '~/features/base/constants/routes';

import { followRoute } from '~/features/base/actions/ui/routeActions';

import { fetchDeviceActions } from '~/features/deviceLogs/actions/deviceActionActions';
import {
    showDeviceActionEditor,
    showLogRequestDeletionDialog,
} from '~/features/deviceLogs/actions/deviceActionEditorActions';

import {
    deviceActionsSelector,
    canCreateDeviceActionsSelector,
    canUpdateDeviceActionsSelector,
    canDeleteDeviceActionsSelector,
    deviceActionsLoadingSelector,
} from '~/features/deviceLogs/selectors/deviceActionSelectors';

import LogRequestsList from '~/features/deviceLogs/components/LogRequestsList';

/**
 * Container for the log requests
 */
export class LogRequestsListContainer extends PureComponent {

    onShowItem(serialNumber) {
        const route = `/${OVERVIEW_PATH}/${LOG_REQUESTS_PATH}/${serialNumber}`;
        this.props.followRoute({ route });
    }

    onShowDeviceActionEditor() {
        this.props.showDeviceActionEditor({
            isNew: true,
        });
    }

    onEditDeviceAction(deviceAction) {
        this.props.showDeviceActionEditor({
            isNew: false,
            deviceAction,
        });
    }

    onDeleteDeviceAction(deviceAction) {
        this.props.showLogRequestDeletionDialog({
            deviceAction,
        });
    }

    render() {
        return (
            <LogRequestsList {...this.props}
                onShowDeviceActionEditor={() => this.onShowDeviceActionEditor()}
                onShowItem={(serialNumber) => this.onShowItem(serialNumber)}
                onEditDeviceAction={(deviceAction) => this.onEditDeviceAction(deviceAction)}
                onDeleteDeviceAction={(deviceAction) => this.onDeleteDeviceAction(deviceAction)}/>
        );
    }

    componentWillMount() {
        this.fetchData();
        this.interval = setInterval(() => {
            this.fetchData();
        }, 60000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    fetchData() {
        this.props.fetchDeviceActions();
    }
}

export const mapStateToProps = (state) => {
    return {
        canCreateDeviceActions: canCreateDeviceActionsSelector(state),
        canUpdateDeviceActions: canUpdateDeviceActionsSelector(state),
        canDeleteDeviceActions: canDeleteDeviceActionsSelector(state),
        deviceActionsLoading: deviceActionsLoadingSelector(state),
        deviceActions: deviceActionsSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        followRoute: (options) => {
            dispatch(followRoute(options));
        },
        showDeviceActionEditor: payload => {
            dispatch(showDeviceActionEditor(payload));
        },
        showLogRequestDeletionDialog: payload => {
            dispatch(showLogRequestDeletionDialog(payload));
        },
        fetchDeviceActions: () => {
            dispatch(fetchDeviceActions());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogRequestsListContainer);

LogRequestsListContainer.defaultProps = {
    // props
    canCreateDeviceActions: false,
    canUpdateDeviceActions: false,
    canDeleteDeviceActions: false,
    deviceActionsLoading: false,
    deviceActions: [],
    // functions
    followRoute: noop,
    showDeviceActionEditor: noop,
    showLogRequestDeletionDialog: noop,
    fetchDeviceActions: noop,
};

LogRequestsListContainer.propTypes = {
    // props
    canCreateDeviceActions: PropTypes.bool,
    canUpdateDeviceActions: PropTypes.bool,
    canDeleteDeviceActions: PropTypes.bool,
    deviceActionsLoading: PropTypes.bool,
    deviceActions: PropTypes.array,
    // functions
    followRoute: PropTypes.func,
    showDeviceActionEditor: PropTypes.func,
    showLogRequestDeletionDialog: PropTypes.func,
    fetchDeviceActions: PropTypes.func,
};
