export const colors = [
    'rgba(83,62,110,1.00)',
    'rgba(178,54,113,1.00)',
    'rgba(200,119,140,1.00)',
    'rgba(200,119,140,0.76)',
    'rgba(200,119,140,0.64)',
    'rgba(200,119,140,0.52)',
    'rgba(200,119,140,0.40)',
    'rgba(200,119,140,0.28)',
    'rgba(200,119,140,0.16)',
    'rgba(170,170,170,1)',
];
export const otherColor = 'rgba(170,170,170,1)';

export const colorsChart = [
    'color-warmup-crimson',
    'color-warmup-victoria',
    'color-warmup-cadillac',
    'color-warmup-raspberry',
    'color-warmup-cerise',
    'color-warmup-charm',
    'color-warmup-salmon',
    'color-warmup-cherokee',
    'color-warmup-corn',
    'gray',
];
export const otherColorChart = 'gray';

export const tickColor = '#d0d8de';
export const primaryColor = '#30b4c0';
export const successColor = '#5cb85c';
export const dangerColor = '#d90000';
