import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { ALL, deviceTypeOptions, deviceTypeWithAllOptions } from '~/features/base/constants/filterOptions';
import { selectionByValue } from '~/features/base/utils/selectUtils';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import RadioButton from "@rio-cloud/rio-uikit/RadioButton";

interface option {
    id: string;
    value: string;
    label: string | React.JSX.Element;
}

interface DeviceTypeFormItemProps {
    label?: string;
    value?: string;
    showOptionAll?: boolean;
    onChange: (item: {deviceType: string | undefined}) => void;
    isRadioBtnGroup?: boolean
}

const DeviceTypeFormItem: FC<DeviceTypeFormItemProps> = ({
                                                             label = 'intl-msg:deviceType',
                                                             value = ALL,
                                                             showOptionAll = false,
                                                             onChange,
                                                             isRadioBtnGroup = false,

                                                         }) => {

    const handleChange = (item: option) => {
        onChange({
            deviceType: item.id === ALL ? undefined : item.value,
        });
    };
    const isSelected = (item) => {
        return item.id === ALL && value === undefined || item.value === value;
    }
    const getSelectedClass = item =>
        isSelected(item) ? 'border-color-highlight bg-highlight text-color-white' : 'hover-bg-highlight-decent';

    const options = showOptionAll ? deviceTypeWithAllOptions : deviceTypeOptions;
    const optionsWithSelection = selectionByValue(value.toString())(options);

    if (isRadioBtnGroup) {
        return (
            <div className='btn-toolbar display-flex justify-content-end'>
                {
                    options.map(item =>(
                        <RadioButton
                            name='noTickRadioBoxes'
                            custom
                            className={`width-70 padding-5 border rounded ${getSelectedClass(item)}`}
                            onChange={() => handleChange(item)}
                            checked={isSelected(item)}
                        >
                            <div className='text-center text-size-14 text-medium'>{item.label}</div>
                        </RadioButton>
                    ))
                }
            </div>
        )
    }

    return (
        <div className='form-group'>
            <label className='control-label'>
                <FormattedMessage id={label}/>
            </label>
            <Select options={optionsWithSelection} onChange={handleChange}/>
        </div>
    );
};

export default DeviceTypeFormItem;
