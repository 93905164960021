import PropTypes from 'prop-types';

import React from 'react';

import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';

import NoData from '~/features/base/components/NoData';

export const ShortBaseSwVersion = ({ baseSwVersion }) => {
    const shortBaseSwVersion = toShortSemanticVersion(baseSwVersion);
    if (shortBaseSwVersion === 'unknown') {
        return <NoData/>;
    }
    return <span>{shortBaseSwVersion}</span>;
};

export default ShortBaseSwVersion;

ShortBaseSwVersion.propTypes = {
    // props
    baseSwVersion: PropTypes.object,
};
