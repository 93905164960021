import { TBM3, VCM } from '~/features/devices/constants/deviceTypes';
import React from 'react';

export const vinNumbers = [
    { id: 'a', label: 'WMAPRTFM381111111', value: '190503-0240', selected: false },
    { id: 'b', label: 'WMAPRTFM181217082', value: '181217-0082', selected: false },
    { id: 'c', label: 'WMAA24ZZ2KF008269', value: '180703-0316', selected: false },
    { id: 'd', label: 'WMAA24ZZ9JF008154', value: '180611-0066', selected: false },
];

export const ecuTypes = [
    { id: '1', label: 'Rahmen I.O.', value: '180611-0066', selected: false },
    { id: '2', label: 'TBM3', value: '180611-0066', selected: false },
    { id: '3', label: 'VCM', value: '180611-0066', selected: false },
];
