import { ENTITY_UPDATE_EVENT_SCOPE_DEVICE } from '~/features/base/constants/entities';

export const FETCH_UPDATE_EVENT_COUNT = 'FETCH_UPDATE_EVENT_COUNT';

export const FETCH_UPDATE_EVENT_SUCCESS_AND_FAILED = 'FETCH_UPDATE_EVENT_SUCCESS_AND_FAILED';

export const FETCH_UPDATE_EVENT_COUNTS_BY_TIME_INTERVAL = 'FETCH_UPDATE_EVENT_COUNTS_BY_TIME_INTERVAL';

export const FETCH_UPDATE_EVENT_REPORT = 'FETCH_UPDATE_EVENT_REPORT';

export const FETCH_FILTERED_UPDATE_EVENTS = 'FETCH_FILTERED_UPDATE_EVENTS';

export const EXPORT_FILTERED_UPDATE_EVENTS = 'EXPORT_FILTERED_UPDATE_EVENTS';

export const fetchUpdateEventCount = ({
    scope = 'totalCount',
    searchCriteria = {},
}) => ({
    type: FETCH_UPDATE_EVENT_COUNT,
    scope,
    searchCriteria,
});

export const fetchUpdateEventSuccessAndFailed = ({
    scope = 'successAndFailed',
    searchCriteria = {},
}) => ({
    type: FETCH_UPDATE_EVENT_SUCCESS_AND_FAILED,
    scope,
    searchCriteria,
});

export const fetchUpdateEventCountsByTimeInterval = ({
    scope = 'totalStats',
    timeInterval = 'MONTH',
    searchCriteria = {},
}) => ({
    type: FETCH_UPDATE_EVENT_COUNTS_BY_TIME_INTERVAL,
    scope,
    timeInterval,
    searchCriteria,
});

export const fetchUpdateEventReport = ({
    scope = 'report',
    timeInterval = 'MONTH',
    searchCriteria = {},
}) => ({
    type: FETCH_UPDATE_EVENT_REPORT,
    scope,
    timeInterval,
    searchCriteria,
});

export const fetchFilteredUpdateEvents = ({
    scope = ENTITY_UPDATE_EVENT_SCOPE_DEVICE,
    page,
    size,
    searchCriteria,
}) => ({
    type: FETCH_FILTERED_UPDATE_EVENTS,
    scope,
    payload: {
        page,
        size,
        searchCriteria,
    },
});

export const exportFilteredUpdateEvents = ({
    format = 'csv',
    searchCriteria,
}) => ({
    type: EXPORT_FILTERED_UPDATE_EVENTS,
    format,
    searchCriteria,
});
