
export const EXPORT_DEVICES = 'EXPORT_DEVICES';

export function exportDevices({ format = 'csv', totalElements = 0, searchCriteria = {} }) {
    return {
        type: EXPORT_DEVICES,
        format,
        totalElements,
        searchCriteria,
    };
}
