import getOr from 'lodash/fp/getOr';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';

import classNames from 'classnames';

import { createGroup, updateGroup } from '~/features/groups/actions/groupActions';
import { changeGroupEditor } from '~/features/groups/actions/groupEditorActions';

import { groupEditorSelector } from '~/features/groups/selectors/groupSelectors';

import GroupEditorForm from '~/features/groups/components/GroupEditorForm';
import { hotkeys } from 'react-keyboard-shortcuts';
import {
    fetchAllDeliverableIds,
} from '~/features/deliverables/actions/deliverableActions';
import {
    DELIVERABLE_TYPE_APP, DELIVERABLE_TYPE_BUNDLE,
    DELIVERABLE_TYPE_CM4G,
    DELIVERABLE_TYPE_FILE,
} from '~/features/deliverables/constants/deliverablesParameters';
import { deliverableIdEntitiesSelector } from '~/features/deliverables/selectors/deliverableSelectors';
import { validateVINs } from '~/features/base/utils/vinValidator';

export class GroupEditorDialog extends PureComponent {
    onSave = () => {
        const model = this.props.model;
        const originalModel = this.state.originalModel;
        if (!this.isValid(model)) {
            return;
        }
        if (model.isNew) {
            this.props.createGroup(model);
        } else {
            this.props.updateGroup(model, originalModel);
        }
    };

    isValid(model) {
        return !!model.name && model.isValidSerialNumbers && validateVINs(model.autoGroupCriteria?.vinList);
    }

    // eslint-disable-next-line camelcase
    hot_keys = {
        'esc': {
            priority: 1,
            handler: () => {
                this.props.hideModal();
            },
        },
    };

    renderTitle(model) {
        const isNew = getOr(true, 'isNew', model);
        if (isNew) {
            return <FormattedMessage id='intl-msg:createGroup'/>;
        }
        return <FormattedMessage id='intl-msg:editGroup.title' values={{
            name: model.name,
        }}/>;
    }

    renderBody(model, apps) {
        return (
            <GroupEditorForm model={model}
                             apps={apps}
                             changeGroupEditor={payload => this.props.changeGroupEditor(payload)}/>
        );
    }

    renderFooter(model) {
        const { hideModal } = this.props;
        const isValid = this.isValid(model);
        const isInProgress = getOr(false, 'isInProgress', model);
        const isReady = isValid && !isInProgress;
        return (
            <div className= 'btn-toolbar justify-content-end' >
                <button className='btn btn-default' onClick={hideModal}>
                    <FormattedMessage id='intl-msg:close'/>
                </button>
                <button className={classNames('btn btn-primary', { disabled: !isReady })}
                        onClick={this.onSave}>
                    {
                        isInProgress ?
                            <Spinner text={<FormattedMessage id='intl-msg:save'/>}/> :
                            <FormattedMessage id='intl-msg:save'/>
                    }
                </button>
            </div>
        );
    }

    render() {
        const { model, apps, hideModal } = this.props;
        const title = this.renderTitle(model);
        const body = this.renderBody(model, apps);
        const footer = this.renderFooter(model);
        return (
            <Dialog className='group-editor-dialog'
                    show={true}
                    showCloseButton={true}
                    onHide={hideModal}
                    title={title}
                    body={body}
                    footer={footer}
                    useOverflow
            />
        );
    }

    componentWillMount() {
        this.setState({
            originalModel: this.props.model,
        });
        this.props.fetchFilteredDeliverables(DELIVERABLE_TYPE_APP);
        this.props.fetchFilteredDeliverables(DELIVERABLE_TYPE_CM4G);
        this.props.fetchFilteredDeliverables(DELIVERABLE_TYPE_FILE);
        this.props.fetchFilteredDeliverables(DELIVERABLE_TYPE_BUNDLE);

    }
}

export const mapStateToProps = (state) => {
    return {
        model: groupEditorSelector(state),
        apps: deliverableIdEntitiesSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        createGroup: (softwareVersion) => {
            dispatch(createGroup(softwareVersion));
        },
        updateGroup: (group, originalGroup) => {
            dispatch(updateGroup(group, originalGroup));
        },
        changeGroupEditor: payload => {
            dispatch(changeGroupEditor(payload));
        },
        fetchFilteredDeliverables: (deliverableType) => {
            dispatch(fetchAllDeliverableIds(
                {
                    page: 0,
                    searchCriteria: {
                        deliverableType: deliverableType,
                    },
                },
            ));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(hotkeys(GroupEditorDialog));

GroupEditorDialog.defaultProps = {
    model: {},
    createGroup: noop,
    updateGroup: noop,
    changeGroupEditor: noop,
    fetchFilteredDeliverables: noop,
    apps: [],
};

GroupEditorDialog.propTypes = {
    model: PropTypes.object,
    createGroup: PropTypes.func,
    updateGroup: PropTypes.func,
    changeGroupEditor: PropTypes.func,
    fetchFilteredDeliverables: PropTypes.func,
    apps: PropTypes.array
};
