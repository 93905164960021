import getOr from 'lodash/fp/getOr';
import find from 'lodash/fp/find';
import sortBy from 'lodash/fp/sortBy';
import reverse from 'lodash/fp/reverse';
import map from 'lodash/fp/map';

import { createSelector } from 'reselect';

import { entityStoreSelector, indicesStoreSelector } from '~/features/higherorder/selectors/dataSelectors';
import { ENTITY_CONTROL_DEVICE_OPERATION } from '~/features/base/constants/entities';

const entitiesSelector = state => entityStoreSelector(state)[ENTITY_CONTROL_DEVICE_OPERATION];
const pagesSelector = state => indicesStoreSelector(state)[ENTITY_CONTROL_DEVICE_OPERATION];

const entitiesOfDeviceSelector = (state, ownProps) =>
    getOr({}, ownProps.serialNumber, entitiesSelector(state));
const pagesOfDeviceSelector = (state, ownProps) =>
    getOr({}, ownProps.serialNumber, pagesSelector(state));

export const pageNumberOfPagesOfDeviceSelector = (state, ownProps) => {
    return pagesOfDeviceSelector(state, ownProps).number;
};
export const pageCountOfPagesOfDeviceSelector = (state, ownProps) => {
    return pagesOfDeviceSelector(state, ownProps).totalPages;
};

export const totalElementsOfPagesOfDeviceSelector = (state, ownProps) => {
    return pagesOfDeviceSelector(state, ownProps).totalElements;
};

export const controlDeviceReportPageItemsSelector = createSelector(
    [pagesOfDeviceSelector, entitiesOfDeviceSelector,
        pageNumberOfPagesOfDeviceSelector],
    (pages, entities, pageNumber) => {
        const report = map(id => {
            const operation = entities[id];
            const operationReport = operation.report ? map(reportItem => {
                const action = find({ actionId: reportItem.actionId }, operation.actions);
                return {
                    ...reportItem,
                    action,
                };
            }, operation.report) : undefined;
            return {
                ...operation,
                operationReport,
            };
        }, getOr([], pageNumber, pages));
        return {
            operations: reverse(sortBy('operationId', report)),
        };
    }
);
