import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import NumberInput from '@rio-cloud/rio-uikit/lib/es/NumberInput';

/**
 * System parameter definition editor form.
 */
export class SysParamDefinitionNumericValueForm extends PureComponent {

    render() {
        const { label, min, max, value } = this.props;

        return (
            <div className='form-group'>
                <label className='control-label'>
                    <FormattedMessage id={label}/>
                </label>
                <NumberInput
                    min={min}
                    max={max}
                    value={parseInt(value, 10)}
                    onValueChanged={this.props.onValueChanged} />
            </div>
        );
    }
}

export default SysParamDefinitionNumericValueForm;

SysParamDefinitionNumericValueForm.defaultProps = {
    label: '',
    min: 0,
    max: 100,
    value: 0,
    onValueChanged: noop,
};

SysParamDefinitionNumericValueForm.propTypes = {
    // props
    label: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.number,
    // functions
    onValueChanged: PropTypes.func,
};
