import defaults from 'lodash/fp/defaults';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ENTITY_UPDATE_EVENT_SCOPE_PACKAGE } from '~/features/base/constants/entities';

import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import {
    exportFilteredUpdateEvents,
    fetchFilteredUpdateEvents,
} from '~/features/updateEvents/actions/updateEventActions';

import {
    packageUpdateEventsLoadingSelector,
    packageUpdateEventsPageCountSelector,
    packageUpdateEventsPageNumberSelector,
    packageUpdateEventsSelector,
    packageUpdateEventsTotalElementsSelector,
} from '~/features/updateEvents/selectors/updateEventSelectors';
import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';

import { queryToSearchCriteria } from '~/features/updateEvents/utils/updateEventsQuery';
import { parseQuery } from '~/features/base/utils/query';
import uid from '~/features/base/utils/uid';
import { showUpdateEventReportDialog } from '~/features/updateEvents/actions/updateEventReportActions';
import { toSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import DeliverableUpdateEventList from '~/features/deliverables/components/details/DeliverableUpdateEventList';
import { DeliverableType } from '~/features/deliverables/constants/DeliverableType';

const FETCH_UPDATE_EVENTS_AMOUNT_INCREMENT = 50;

class DeliverableUpdateEventsContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.name = uid();
        this.state = {
            page: 1,
            filters: {},
        };
    }

    onShowReport = () => {
        const { packageId, shortPackageVersion, search } = this.props;
        const parsedQuery = parseQuery(search);
        const searchCriteria = this.getSearchCriteria(parsedQuery);
        const targetDeliverableVersion = shortPackageVersion ? toSemanticVersion(shortPackageVersion) : undefined;
        this.props.showUpdateEventReportDialog({
            deliverableId: packageId,
            deliverableVersion: targetDeliverableVersion,
            searchCriteria,
            scope: ENTITY_UPDATE_EVENT_SCOPE_PACKAGE,
        });
    };

    onExportUpdateEvents = (format = 'csv') => {
        const { search } = this.props;
        const parsedQuery = parseQuery(search);
        const searchCriteria = this.getSearchCriteria(parsedQuery);
        const options = {
            format,
            searchCriteria,
        };
        this.props.exportUpdateEvents(options);
    };

    onLoadMore = () => {
        this.setState({ page: this.state.page + 1 });
    };

    onFilterChange = (filters) => {
        this.setState({ ...this.state, filters });
    };

    getSearchCriteria(parsedQuery) {
        const { selectedDeliverableDetails } = this.props;
        const { deliverableId, deliverableVersion } = selectedDeliverableDetails;
        return defaults(queryToSearchCriteria(parsedQuery), {
            deliverableId: deliverableId,
            targetDeliverableVersion: deliverableVersion,
            ...this.state.filters
        });
    }

    render() {
        return (
            <>
                <div className='overflow-y-scroll max-height-75vh padding-bottom-50'>
                    <DeliverableUpdateEventList {...this.props} showDeliverableId={false}
                                                showReportButton={!!this.props.shortPackageVersion}
                                                onShowReport={this.onShowReport}
                                                onExportUpdateEvents={this.onExportUpdateEvents}
                                                onLoadMore={this.onLoadMore}
                                                onCriteriaChange={this.onFilterChange}
                                                filters={this.state.filters}
                    />
                </div>
            </>
        );
    }

    fetchFilteredUpdateEvents() {
        const { page } = this.state;
        const { search } = this.props;
        const parsedQuery = parseQuery(search);
        const searchCriteria = this.getSearchCriteria(parsedQuery);
        const fetchFilteredUpdateEventsOptions = {
            searchCriteria,
            page: 0,
            size: page * FETCH_UPDATE_EVENTS_AMOUNT_INCREMENT,
            scope: ENTITY_UPDATE_EVENT_SCOPE_PACKAGE,
        };
        this.props.fetchUpdateEvents({
            ...fetchFilteredUpdateEventsOptions,
        });
    }

    componentWillMount() {
        this.fetchFilteredUpdateEvents();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.page !== this.state.page || prevState.filters !== this.state.filters) {
            this.fetchFilteredUpdateEvents();
        }
    }

    componentWillUnmount() {
        this.props.unregisterDataInterest(this.name);
        clearInterval(this.interval);
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        pathname: pathnameSelector(state),
        search: searchSelector(state),
        updateEvents: packageUpdateEventsSelector(state, ownProps),
        updateEventsLoading: packageUpdateEventsLoadingSelector(state, ownProps),
        pageNumber: packageUpdateEventsPageNumberSelector(state),
        pageCount: packageUpdateEventsPageCountSelector(state),
        totalElements: packageUpdateEventsTotalElementsSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        triggerDataFetcher: () => {
            dispatch(triggerDataFetcher());
        },
        showUpdateEventReportDialog: (options) => {
            dispatch(showUpdateEventReportDialog(options));
        },
        exportUpdateEvents: (options) => {
            dispatch(exportFilteredUpdateEvents(options));
        },
        fetchUpdateEvents: (options) => {
            dispatch(fetchFilteredUpdateEvents(options));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableUpdateEventsContainer);

DeliverableUpdateEventsContainer.defaultProps = {
    // props
    selectedDeliverableDetails: {},
    updateEvents: [],
    updateEventsLoading: false,
    pageNumber: 0,
    pageCount: 0,
    totalElements: 0,
    pathname: '',
    search: '',
    // functions
    followRoute: noop,
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    triggerDataFetcher: noop,
    exportUpdateEvents: noop,
};

DeliverableUpdateEventsContainer.propTypes = {
    // props
    selectedDeliverableDetails: PropTypes.object,
    updateEvents: PropTypes.array,
    updateEventsLoading: PropTypes.bool,
    pageNumber: PropTypes.number,
    pageCount: PropTypes.number,
    totalElements: PropTypes.number,
    pathname: PropTypes.string,
    search: PropTypes.string,
    // functions
    followRoute: PropTypes.func,
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
    exportUpdateEvents: PropTypes.func,
};
