import { call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';
import { hideModal, showModal } from '~/features/base/actions/ui/modalsActions';

import { getAtlassianConnectorBackend } from '~/features/base/selectors/backendSelectors';

import { doHandleErrorSaga, getHTTP, postHTTP } from '~/features/base/sagas/sagaUtil';
import { failedAction, finishedAction } from '~/features/higherorder/transforms/actionTransforms';
import {
    changeJiraIssueEditor,
    CREATE_JIRA_ISSUE,
    FIND_JIRA_ISSUES,
    resetJiraIssueEditor,
    SHOW_JIRA_ISSUE_EDITOR,
    updateExistingJiraIssues,
} from '~/features/atlassian/actions/atlassianJiraActions';
import { JIRA_ISSUE_EDITOR_MODAL } from '~/features/base/constants/modals';
import { FormattedMessage } from 'react-intl';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import {
    ATLASSIAN_JIRA_ISSUE_LABEL,
    ATLASSIAN_JIRA_ISSUE_URL,
    getSummaryKey,
} from '~/features/atlassian/constants/atlassianConstants';
import { renderDescription } from '~/features/atlassian/utils/descriptionTemplate';

export function* getAtlassianConnectorURL() {
    const serviceURL = yield select(getAtlassianConnectorBackend);
    return `${serviceURL}/v1/jira`;
}

export function* doPrepareForNewJiraIssueSaga(payload) {
    yield put(changeJiraIssueEditor(
        {
            ...payload,
            isNew: true,
        }));
}

export function* doFindJiraIssuesSaga(action) {
    try {
        const url = yield call(getAtlassianConnectorURL);
        const { serialNumber, jiraProjectName } = action.payload;
        const projectNameParameter = jiraProjectName ? `project=${jiraProjectName} and` : '';
        const response = yield call(getHTTP,
            `${url}/search?jql=${projectNameParameter} labels in (${ATLASSIAN_JIRA_ISSUE_LABEL}) and summary~"${getSummaryKey(
                serialNumber)}"`);
        yield put(updateExistingJiraIssues({ existingJiraIssues: response }));
    } catch (error) {
    }
    yield put(finishedAction(action.type));
}

export function* doCreateJiraIssueSaga(action) {
    try {
        const url = yield call(getAtlassianConnectorURL);
        const { controlDevice, summary, description, jiraProjectName } = action.payload;
        const newJiraIssue = {
            'project': jiraProjectName,
            'summary': `${getSummaryKey(controlDevice.serialNumber)} ${summary}`,
            'description': renderDescription(description, controlDevice),
            'type': 'BUG',
            'labels': [
                ATLASSIAN_JIRA_ISSUE_LABEL,
            ],
        };
        yield put(hideModal());
        const response = yield call(postHTTP, `${url}/issue`, JSON.stringify(newJiraIssue));
        Notification.success(
            [<FormattedMessage id='intl-msg:clickToOpenNewJiraIssue'/>,
                <a href={ATLASSIAN_JIRA_ISSUE_URL + '/' + response.issueId} className='padding-5 text-white'
                   target="_blank"><b><u>{response.issueId}</u></b></a>],
            null,
            10000,
            null,
            null);
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doShowJiraIssueEditorSaga(action) {
    yield put(resetJiraIssueEditor());
    yield call(doPrepareForNewJiraIssueSaga, action.payload);
    yield put(showModal({
        modalType: JIRA_ISSUE_EDITOR_MODAL,
        modalProps: {},
    }));
}

export function* findJiraIssuesSaga() {
    yield takeLatest(FIND_JIRA_ISSUES, doFindJiraIssuesSaga);
}

export function* createJiraIssueSaga() {
    yield takeEvery(CREATE_JIRA_ISSUE, doCreateJiraIssueSaga);
}

export function* showJiraIssueEditorSaga() {
    yield takeLatest(SHOW_JIRA_ISSUE_EDITOR, doShowJiraIssueEditorSaga);
}
