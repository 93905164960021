import map from 'lodash/fp/map';

import {
    toShortDistroVersion,
    toShortSemanticVersion,
} from '~/features/base/utils/versionNumberConverter';

export const parseVehicle = vehicle => {
    const shortLastDistroVersionReported = toShortDistroVersion(vehicle.lastDistroVersionReported);
    const shortLastBaseSoftwareVersionReported = toShortSemanticVersion(vehicle.lastBaseSoftwareVersionReported);
    return {
        ...vehicle,
        shortLastDistroVersionReported,
        shortLastBaseSoftwareVersionReported,
    };
};
export const parseVehicles = vehicles => map(parseVehicle, vehicles);
