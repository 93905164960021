import React from 'react';
import Switch from '@rio-cloud/rio-uikit/lib/es/Switch';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import { FormattedMessage } from 'react-intl';

interface DeliverableStandaloneFormItemProps {
    model: any;
    onChange: (value: boolean) => void;
}

const DeliverableStandaloneFormItem: React.FC<DeliverableStandaloneFormItemProps> = ({ model, onChange }) => {
    return (
        <div className={'margin-bottom-10  margin-top-10'}>
            <div>
                <label><FormattedMessage id={'intl-msg:deliverable.standalone'}/></label>
                <OverlayTrigger
                    key={1}
                    placement={'right-start'}
                    overlay={
                        <Tooltip id='tooltip' allowOnTouch>
                            <FormattedMessage id={'intl-msg:deliverable.standaloneTooltip'}/>
                        </Tooltip>
                    }
                >
                    <span className={'rioglyph rioglyph-exclamation-sign margin-left-5 text-color-info '}/>
                </OverlayTrigger>
            </div>
            <Switch onChange={() => onChange(!model.standalone)} checked={model.standalone}/>
        </div>
    );
};

export default DeliverableStandaloneFormItem;
