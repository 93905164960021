// This is the runtime configuration being used for tests

export const testConfig = {
    id: 'env.test',
    region: 'EU',
    backend: {
        DEVICE_MANAGEMENT_SERVICE: `${window.location.origin}/api/mdmservice`,
        DEVICE_LOG_DOWNLOAD_SERVICE: `${window.location.origin}/api/tbm3logdownload/v1/devices`,
        REPORTS_BILLING_CHARTS_DATA_SERVICE: `${window.location.origin}/api/mdm/reporting/v1/traffic/search`,
        REPORTS_BILLING_CHARTS_DATA_VERSIONS_SERVICE: 'https://6182843e84c2020017d89efa.mockapi.io/dataTraffic/dataTrafficTypes',
    },
    homeRoute: `${window.location.origin}`,
    appMenuUri: `${window.location.origin}/#app-menu`,
    userMenuUri: `${window.location.origin}/#user-menu`,
    login: {
        authority: `${window.location.origin}/oauth`,
        mockAuthorization: false,
        preventRedirect: false,
        redirectUri: `${window.location.origin}/redirect.html`,
        silentRedirectUri: `${window.location.origin}/redirect.html`,
    },
    logoutUri: `${window.location.origin}`,
};

