import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent, useState } from 'react';
import { connect } from 'react-redux';
import uid from '~/features/base/utils/uid';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import map from 'lodash/fp/map';
import orderBy from 'lodash/fp/orderBy';
import { FormattedMessage, FormattedTime } from 'react-intl';
import classNames from 'classnames';
import {
    ASSIGNED,
    DISTRO_UPDATE,
    DOWNLOAD_FINISHED,
    DOWNLOAD_IN_PROGRESS,
    FAILED,
    FILE_DOWNLOAD,
    FINISHED,
    INSTALLATION_FINISHED,
    INSTALLATION_IN_PROGRESS,
    PACKAGE_INSTALL,
    PACKAGE_UPDATE,
    rolloutStatus,
    SENT_TO_DEVICE,
    SUCCESS,
    updateEventType,
    updateOutcome,
} from '~/features/devices/constants/deviceRolloutStates';
import ShortBaseSwVersion from '~/features/baseSwVersions/components/ShortBaseSwVersion';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import SteppedProgressBar from '@rio-cloud/rio-uikit/lib/es/SteppedProgressBar';
import {
    getCurrentlySelectedVehicleDevicesSelector,
    getCurrentlySelectedVehicleRolloutStatesSelector,
} from '~/features/oldVehicles/selectors/vehicleSelectors';
import { TBM3, VCM } from '~/features/devices/constants/deviceTypes';
import { DeviceTypeLabel } from '~/features/devices/components/DeviceType';
import find from 'lodash/fp/find';
import { Select } from '@rio-cloud/rio-uikit';
import SortDirection from '@rio-cloud/rio-uikit/lib/es/SortDirection';

/**
 * Container for the vehicle rollout states
 */
const appProgressStates = [
    SENT_TO_DEVICE,
    DOWNLOAD_IN_PROGRESS,
    DOWNLOAD_FINISHED,
    INSTALLATION_IN_PROGRESS,
    INSTALLATION_FINISHED,
    FINISHED,
];

const distroProgressStates = [
    SENT_TO_DEVICE,
    DOWNLOAD_IN_PROGRESS,
    DOWNLOAD_FINISHED,
    INSTALLATION_IN_PROGRESS,
    INSTALLATION_FINISHED,
    FINISHED,
];

const fileProgressStates = [
    SENT_TO_DEVICE,
    DOWNLOAD_IN_PROGRESS,
    DOWNLOAD_FINISHED,
    FINISHED,
];

export class VehicleRolloutStatesContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.name = uid();
        this.state = {
            selectedDeviceId: '',
            selectedDeviceType: '',
        };
    }

    getStepNumber = (rolloutState) => {
        if (rolloutState.isFinished && rolloutState.outcome === SUCCESS) {
            return 5;
        }
        if (rolloutState.isFinished && rolloutState.outcome === FAILED) {
            return -1;
        }
        const lastEvent = rolloutState.eventSteps.pop();
        if (!lastEvent) {
            return 0;
        }
        switch (lastEvent.state) {
            case ASSIGNED:
                return -1;
            case SENT_TO_DEVICE:
                return 0;
            case DOWNLOAD_IN_PROGRESS:
                return 1;
            case DOWNLOAD_FINISHED:
                return 2;
            case INSTALLATION_IN_PROGRESS:
                return 3;
            case INSTALLATION_FINISHED:
                return 4;
            default:
                return 0;
        }
    };

    getStateLabelDefinition = (state, rolloutState) => {
        const currentStep = rolloutState.eventSteps && rolloutState.eventSteps.find(
            eventStep => eventStep.state === state);
        const currentStepInfo = (currentStep && currentStep.info) || '';
        const currentStepDate = (currentStep && currentStep.timestamp);
        return {
            icon: currentStepInfo ? <span>
                    <OverlayTrigger placement='top'
                                    overlay={currentStepInfo && <Tooltip id='tooltip' className='top-right'>
                                        {currentStepInfo}
                                    </Tooltip>}>
                        {rolloutStatus[state].icon}
                        </OverlayTrigger>
                </span> : rolloutStatus[state].icon,
            label: <div>
                <div><FormattedMessage id={rolloutStatus[state].label}/></div>
                {
                    currentStepDate && <div>
                        <FormattedTime value={currentStepDate} hour='numeric' minute='numeric' second='numeric'/>
                    </div>
                }
            </div>,
        };
    };

    compileAppProgressBar = (rolloutState) => {
        const stateLabelDefinitions = [];
        switch (rolloutState.type) {
            case PACKAGE_UPDATE:
                appProgressStates.map(state => {
                    stateLabelDefinitions.push(this.getStateLabelDefinition(state, rolloutState));
                });
                break;
            case DISTRO_UPDATE:
                distroProgressStates.map(state => {
                    stateLabelDefinitions.push(this.getStateLabelDefinition(state, rolloutState));
                });
                break;
            case PACKAGE_INSTALL:
                appProgressStates.map(state => {
                    stateLabelDefinitions.push(this.getStateLabelDefinition(state, rolloutState));
                });
                break;
            case FILE_DOWNLOAD:
                fileProgressStates.map(state => {
                    stateLabelDefinitions.push(this.getStateLabelDefinition(state, rolloutState));
                });
                break;
            default:
        }
        return stateLabelDefinitions;
    };

    renderOutcome = (rolloutState) => {
        if (!rolloutState.outcome) {
            return;
        }
        const labelClass = updateOutcome[rolloutState.outcome].className;
        return (
            <span>
                <OverlayTrigger placement='top' overlay={
                    <Tooltip id='tooltip' className='top-right'>
                        <FormattedMessage id={updateOutcome[rolloutState.outcome].tooltip}/>
                    </Tooltip>}>
                    <span className={classNames('text-size-18', labelClass)}></span>
                </OverlayTrigger>
            </span>
        );
    };

    renderLabel = (rolloutState) => {
        const { devices } = this.props;
        const deviceWithSerial = find({ deviceId: rolloutState.hwSerial }, devices);
        const renderedUpdateOutcome = this.renderOutcome(rolloutState);
        const labelClass = updateEventType[rolloutState.type].className;
        return (
            <div>
                <div className='row'>
                    <span>
                        {renderedUpdateOutcome}
                    </span>
                    <span className='badge margin-left-5'>{rolloutState.hwSerial}</span>
                    <span className='padding-left-5'>
                        <DeviceTypeLabel deviceType={deviceWithSerial.type === TBM3 ? TBM3 : VCM}/>
                    </span>
                    <span className='text-color-dark padding-left-5'>
                        <FormattedTime value={rolloutState.lastUpdated} year='numeric' month='2-digit' day='2-digit'/>
                    </span>
                </div>
                <div className='row padding-top-5 padding-left-25 justify-content-center'>
                    <div className='display-flex'>
                        <div>
                            <span className={classNames('label label-condensed margin-right-5',
                                labelClass)}>
                                <FormattedMessage id={updateEventType[rolloutState.type].label}/>
                            </span>
                        </div>
                        <div>
                            <span className='text-color-dark'>{`${rolloutState.deliverableId} - v.`}</span>
                            <span className='text-color-dark'>
                                <ShortBaseSwVersion baseSwVersion={rolloutState.version}/>
                           </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    getDeviceIds = (devices, selectedDeviceId) => {
        const deviceIds = [];
        map((device) => {
            deviceIds.push({
                id: device.deviceId,
                label: device.deviceId,
            });
        }, devices);
        return deviceIds;
    };

    getDeviceTypes = (devices, selectedDeviceType) => {
        const deviceTypes = [];
        map((device) => {
            deviceTypes.push({
                id: device.type,
                label: device.type,
                icon: <DeviceTypeLabel deviceType={device.type === TBM3 ? TBM3 : VCM}/>,
            });
        }, devices);
        return deviceTypes;
    };

    renderFilter = (deviceIds, deviceTypes, onDeviceIdChange, onDeviceTypeChange, onResetFilter, selectedDeviceId,
        selectedDeviceType) => {
        return (
            <div className='col-sm-12'>
                <div className='col-sm-5 padding-bottom-25'>
                    <label><FormattedMessage id='intl-msg:deviceId'/></label>
                    <Select onChange={item => onDeviceIdChange(item.id)}
                            placeholder='Filter by device id...'
                            value={[selectedDeviceId]}
                            options={deviceIds}
                            tabIndex={1}
                        // showSelectedItemIcon
                        //     useClear
                    />
                </div>
                {/*<div className='col-sm-5 padding-bottom-25'>*/}
                {/*    <label><FormattedMessage id='intl-msg:deviceType'/></label>*/}
                {/*    <Select onChange={(event) => onDeviceTypeChange(event.id)} options={deviceTypes}*/}
                {/*            value={selectedDeviceType}*/}
                {/*            placeholder='Filter by device type...'*/}
                {/*            showSelectedItemIcon/>*/}
                {/*</div>*/}
                <div className='col-sm-1 padding-top-25'>
                    <button className='btn btn-default margin-left-10' onClick={() => onResetFilter()}>
                        Reset
                    </button>
                </div>
            </div>
        );
    };

    onResetFilter = () => {
        this.setState({
            selectedDeviceId: '',
            selectedDeviceType: '',
        });
    };

    onDeviceIdChange = (deviceId) => {
        this.setState({
            ...this.state,
            selectedDeviceId: deviceId,
        });
    };

    onDeviceTypeChange = (deviceType) => {
        this.setState({
            ...this.state,
            selectedDeviceType: deviceType,
        });
    };

    render() {
        const { devices, rolloutStates } = this.props;
        const { selectedDeviceId, selectedDeviceType } = this.state;

        const deviceIds = this.getDeviceIds(devices, selectedDeviceId);
        const deviceTypes = this.getDeviceTypes(devices, selectedDeviceType);
        return [
            <div className=''>{this.renderFilter(deviceIds, deviceTypes, this.onDeviceIdChange, this.onDeviceTypeChange,
                this.onResetFilter, selectedDeviceId, selectedDeviceType)}</div>,
            <div>
                {
                    map(rolloutState => {
                        if (selectedDeviceId !== '' && (rolloutState.hwSerial !== selectedDeviceId)) {
                            return;
                        }
                        // if state is app
                        const labelProgressBar = this.compileAppProgressBar(rolloutState);
                        const selectedStep = this.getStepNumber(rolloutState);
                        const renderedLabel = this.renderLabel(rolloutState);
                        return <div className='padding-10'>
                            {renderedLabel}
                            <SteppedProgressBar
                                className='padding-top-10 padding-bottom-10 text-size-12'
                                selectedStepNumber={selectedStep}
                                onSelectedChanged={this.onSelectedChanged}
                                labels={labelProgressBar}
                            />
                        </div>;
                    }, orderBy(['hwSerial', 'lastUpdated'], ['desc'], rolloutStates))
                }
            </div>,
        ];
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        rolloutStates: getCurrentlySelectedVehicleRolloutStatesSelector(state, ownProps),
        devices: getCurrentlySelectedVehicleDevicesSelector(state, ownProps),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        triggerDataFetcher: () => {
            dispatch(triggerDataFetcher());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleRolloutStatesContainer);

VehicleRolloutStatesContainer.defaultProps = {
    // props
    serialNumber: '',
    autoRefresh: true,
    limit: 0,
    rolloutStates: [],
    devices: [],
    rolloutStatesLoading: false,
    // functions
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    triggerDataFetcher: noop,
};

VehicleRolloutStatesContainer.propTypes = {
    // props
    serialNumber: PropTypes.string,
    autoRefresh: PropTypes.bool,
    limit: PropTypes.number,
    rolloutStates: PropTypes.array,
    devices: PropTypes.array,
    rolloutStatesLoading: PropTypes.bool,
    // functions
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
};
