import { buffers, END, eventChannel } from 'redux-saga';
import { take, takeEvery, put, call } from 'redux-saga/effects';
import { uploadFailure, uploadProgress, uploadSuccess, UPLOAD_REQUEST } from '~/features/base/actions/data/fileUploadActions';
import { accessToken } from '~/features/tokenHandling/accessToken';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';


function createUploadFileChannel(endpoint, file) {
    return eventChannel(emitter => {
        const xhr = new XMLHttpRequest();

        const onProgress = (e) => {
            if (e.lengthComputable) {
                const progress = e.loaded / e.total;
                emitter({ progress });
            }
        };
        const onFailure = (e) => {
            emitter({ err: new Error('Upload failed') });
            emitter(END);
        };
        xhr.upload.addEventListener('progress', onProgress);
        xhr.upload.addEventListener('error', onFailure);
        xhr.upload.addEventListener('abort', onFailure);
        xhr.onreadystatechange = () => {
            const { readyState, status } = xhr;
            if (readyState === 4) {
                if (status === 200) {
                    emitter({ success: true });
                    emitter(END);
                } else {
                    onFailure(null);
                }
            }
        };
        xhr.open('POST', endpoint, true);

        // add bearer token
        const token = accessToken.getAccessToken();
        xhr.setRequestHeader('Authorization', `Bearer ${token}`);

        xhr.setRequestHeader('Content-type', 'application/octet-stream');
        xhr.send(file);
        return () => {
            xhr.upload.removeEventListener('progress', onProgress);
            xhr.upload.removeEventListener('error', onFailure);
            xhr.upload.removeEventListener('abort', onFailure);
            xhr.onreadystatechange = null;
            xhr.abort();
        };
    }, buffers.sliding(2));
}

// Watch for an upload request and then
// defer to another saga to perform the actual upload
export function* uploadRequestWatcherSaga() {
    yield takeEvery(UPLOAD_REQUEST, function* (action) {
        const file = action.payload.file;
        const endpoint = action.payload.url;
        yield call(uploadFileSaga, file, endpoint);
    });
}

// Upload the specified file
export function* uploadFileSaga(file, endpoint) {
    const channel = yield call(createUploadFileChannel, endpoint, file);
    while (true) {
        const { progress = 0, err, success } = yield take(channel);
        if (err) {
            yield put(uploadFailure(file, err));
            return;
        }
        if (success) {
            yield put(uploadSuccess(file));
            yield put(triggerDataFetcher());
            return;
        }
        yield put(uploadProgress(file, progress));
    }
}
