import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { inCustomerFleetOptions, ALL } from '~/features/base/constants/filterOptions';
import { selectionByValue } from '~/features/base/utils/selectUtils';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';

export class ActivatedFormItem extends Component {
    onChange = (item) => {
        this.props.onChange({
            inCustomerFleet: item.id === ALL ? undefined : item.value,
        });
    }

    render() {
        const { label, value } = this.props;
        const optionsWithSelection = selectionByValue(value)(inCustomerFleetOptions);
        return (
            <div className='form-group'>
                <label className='control-label'>
                    <FormattedMessage id={label} />
                </label>
                <Select options={optionsWithSelection}
                    onChange={this.onChange} />
            </div>
        );
    }
}

export default ActivatedFormItem;

ActivatedFormItem.defaultProps = {
    // props
    label: 'intl-msg:inCustomerFleet',
    value: ALL,
    // functions
    onChange: noop,
};

ActivatedFormItem.propTypes = {
    // props
    label: PropTypes.string,
    value: PropTypes.string,
    // functions
    onChange: PropTypes.func,
};
