import omit from 'lodash/fp/omit';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';

export const DeviceReportJSON = ({ operation }) => {
    return (
        <ExpanderPanel title={<FormattedMessage id='intl-msg:showJSON'/>} bsStyle='default' iconLeft>
            <pre>{JSON.stringify(omit(['operationReport', 'resultsSummary'], operation), undefined, 4)}</pre>
        </ExpanderPanel>
    );
};

export default DeviceReportJSON;

DeviceReportJSON.defaultProps = {
    operation: {},
    formatDateTime: noop,
};

DeviceReportJSON.propTypes = {
    // props
    operation: PropTypes.object,
    formatDateTime: PropTypes.func,
};
