import React, { useEffect } from 'react';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import { FormattedMessage } from 'react-intl';
import SemanticVersionInputFormItem from '~/features/base/components/forms/SemanticVersionInputFormItem';

const InstalledDeliverablesFormGroup = ({
    deliverables,
    values,
    onChange,
    noCompatibilitySeparator
}) => {

    const emptyRow = {
        deliverableId: '',
        deliverableType: '',
        versionRange: {
            minValue: {
                major: 0,
                minor: 0,
                patch: 0
            },
            maxValue: {
                major: 0,
                minor: 0,
                patch: 0
            }
        }
    };
    const defaultValues = [emptyRow];

    useEffect(() => {
        if (!values || values?.length === 0) {
            onChange([emptyRow]);
        }
    }, []);

    const optionsWithSelection = deliverables?.map((app) => {
        return {
            label: `${app.id} (${app.type})`,
            type: app.type,
            id: app.id
        };
    });

    const onFieldsChange = (newValue, index) => {
        if (index != undefined) {
            let appList = [...values];
            appList[index] = { ...values[index], ...newValue || '' };
            onChange(appList);
        }
    };

    const onDeliverableChange = (newValue, index) => {
        if (index != undefined) {
            const deliverable = { ['deliverableType']: newValue.type, ['deliverableId']: newValue.id };
            onFieldsChange(deliverable, index);
        }
    };
    const onVersionRangeChange = (field, newValue, index) => {
        if (index != undefined) {
            const range = { ...values[index]?.versionRange, [field]: newValue };
            onFieldsChange({['versionRange']: range}, index);
        }
    };

    const onAddDeliverableFilterFormGroup = () => {
        onChange(values.concat(defaultValues));
    };

    const onDeleteDeliverableFilterFormGroup = (index) => {
        if (values.length === 1) {
            onChange(defaultValues);
            return;
        }
        let list = [...values];
        list.splice(index, 1);
        onChange(list);
    };

    const compatibilitySeparatorStyle = !noCompatibilitySeparator ? 'border border-color-grey border-top-only border-width-1 padding-top-10 margin-top-20' : '';
    return <>
        <div className={compatibilitySeparatorStyle}>
            <label className='control-label margin-bottom-10 text-bold'>
                <FormattedMessage id='intl-msg:reportsBillingDeliverableVersion'/>
            </label>
        </div>

        {values?.map((value, index) => (
            <div className='form-group form-group-semantic-version-range'>
                <div className={'display-flex gap-20 margin-bottom-20display-flex gap-20 margin-bottom-20'}>
                    <div className='flex-basis-40pct'>
                        <label className='control-label'><FormattedMessage id='intl-msg:deliverableId'/></label>
                        <Select
                            useFilter
                            useClear
                            options={optionsWithSelection}
                            value={[value?.deliverableId]}
                            onChange={(value) => {
                                onDeliverableChange(value, index);
                            }}/>
                    </div>

                    <div className='flex-basis-50pct margin-left-15 margin-right-15'>
                        <SemanticVersionInputFormItem label={'intl-msg:minValue'}
                                                      value={value?.versionRange?.minValue}
                                                      isClearable={true}
                                                      onChange={(value) => {
                                                          onVersionRangeChange('minValue', value, index);
                                                      }}
                                                      classNameInputField={'col-xs-3 col-sm-3 col-md-3'}
                                                      classNameClearButton={'col-xs-3 col-sm-3 col-md-2'}
                        />
                        <SemanticVersionInputFormItem label={'intl-msg:maxValue'}
                                                      value={value?.versionRange?.maxValue}
                                                      isClearable={true}
                                                      onChange={(value) => {
                                                          onVersionRangeChange('maxValue', value, index);

                                                      }}
                                                      classNameInputField={'col-xs-3 col-sm-3 col-md-3'}
                                                      classNameClearButton={'col-xs-3 col-sm-3 col-md-2'}
                        />
                    </div>
                    <div className='margin-top-15 justify-content-end align-items-start'>
                        <button type='button' className='margin-top-10 margin-bottom-10 btn btn-danger'
                                onClick={() => {
                                    onDeleteDeliverableFilterFormGroup(index);
                                }}
                                disabled={false}>
                            <span className='text-capitalize'><FormattedMessage id='intl-msg:delete'/></span>
                        </button>
                        <div>
                            {index === values?.length - 1 && <button type='button'
                                                                     className='margin-top-20 padding-left-20 padding-right-20 btn btn-success'
                                                                     onClick={onAddDeliverableFilterFormGroup}>
                                <span className='text-capitalize'><FormattedMessage id='intl-msg:changeOperation.add'/></span>
                            </button>}
                        </div>
                    </div>
            </div>
        </div>))}
        <hr/>
    </>;
}

export default InstalledDeliverablesFormGroup;
