import classNames from 'classnames';

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    deviceStates,
    ACTIVATED,
    TEST,
    BLOCKED,
} from '~/features/devices/constants/deviceStates';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

export const DeviceStateLabel = ({ deviceState }) => {
    const labelClass = deviceStates[deviceState].className;
    return (
        <span>
            <OverlayTrigger placement='top' overlay={
                <Tooltip id='tooltip' className='top-right'>
                    <FormattedMessage id={deviceStates[deviceState].tooltip}/>
                </Tooltip>}>
                <span className={classNames('label label-condensed margin-right-5', labelClass)}>
                    <FormattedMessage id={deviceStates[deviceState].label}/>
                </span>
            </OverlayTrigger>
        </span>
    );
};

export const DeviceState = ({ controlDevice }) => {
    if (!controlDevice) {
        return null;
    }
    const tags = [];
    if (controlDevice.inCustomerFleet) {
        tags.push(<DeviceStateLabel deviceState={ACTIVATED}/>);
    }
    if (!controlDevice.updatesActive) {
        tags.push(<DeviceStateLabel deviceState={BLOCKED}/>);
    }
    if (controlDevice.testReleasesActive) {
        tags.push(<DeviceStateLabel deviceState={TEST}/>);
    }
    return tags;
};

export default DeviceState;

DeviceState.defaultProps = {};

DeviceState.propTypes = {
    // props
    controlDevice: PropTypes.object,
};
