import React from 'react';
import { createCustomPresets } from '~/features/base/utils/customPresets';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

interface CustomOptionsProps {
    setTimeRange: (startValue: moment.Moment, endValue: moment.Moment) => void;
    setIsDateCustom: (isDateCustom: boolean) => void;
}

const CustomOptions: React.FC<CustomOptionsProps> = ({ setTimeRange, setIsDateCustom }: CustomOptionsProps) => {
    return (
        <div
            className={'display-flex flex-column  width-100pct padding-left-10 padding-right-10 justify-content-between'}>
            {
                createCustomPresets(true).map((preset) =>
                    (<div>
                        <button
                            onClick={() => setTimeRange(preset.startValue, preset.endValue)}
                            className={' btn btn-default border-none padding-left-10  margin-right-10 padding-0  margin-top-5 width-100pct  text-align justify-content-start'}>
                                    <span>
                                        {preset.text}
                                    </span>
                        </button>
                    </div>)
                )
            }
            <div
                className={'margin-bottom-10  margin-top-10  border rounded divider-y-1 line-height-1 divider-color-light divider-style-solid'}>
            </div>
            <button
                onClick={() => setIsDateCustom(true)}
                className={' btn btn-default margin-bottom-10  border-none width-100pct  text-align justify-content-start'}>
                        <span>
                            <FormattedMessage id={'intl-msg:dateTimeRange.custom'}/>
                        </span>
            </button>

        </div>
    );
};

export default CustomOptions;
