
/*
 * Action type for fetching system parameters system values
 * @type {string}
 */
export const FETCH_SYS_PARAM_SYSTEM_VALUES = 'FETCH_SYS_PARAM_SYSTEM_VALUES';

/**
 * Action type for creating a system parameter system valuess
 * @type {string}
 */
export const CREATE_SYS_PARAM_SYSTEM_VALUE = 'CREATE_SYS_PARAM_SYSTEM_VALUE';

/**
 * Action type for updating a system parameter system values
 * @type {string}
 */
export const UPDATE_SYS_PARAM_SYSTEM_VALUE = 'UPDATE_SYS_PARAM_SYSTEM_VALUE';

/**
 * Action type for deleting a system parameter system values
 * @type {string}
 */
export const DELETE_SYS_PARAM_SYSTEM_VALUE = 'DELETE_SYS_PARAM_SYSTEM_VALUE';

/**
 * Action creator for fetching of system parameter system values
 * @param {object} options The options for which to fetch the system parameter system values
 * @param {string} options.serialNumber The serial number of the device
 * @param {string} options.shortBaseSwVersion The base software version for which to fetch the systems
 * @returns {{type: string, payload}} The action for fetching of system parameter system values
 */
export function fetchSysParamSystemValues({ serialNumber, shortBaseSwVersion }) {
    return {
        type: FETCH_SYS_PARAM_SYSTEM_VALUES,
        payload: {
            serialNumber,
            shortBaseSwVersion,
        },
    };
}

/**
 * Action creator for creating a system parameter system value
 * @param {object} sysParamSystemValue The system parameter system value to be created
 * @returns {{type: string, payload: object}} The action for creating a system parameter system value
 */
export function createSysParamSystemValue(sysParamSystemValue) {
    return {
        type: CREATE_SYS_PARAM_SYSTEM_VALUE,
        payload: {
            sysParamSystemValue,
        },
    };
}

/**
 * Action creator for updating a system parameter system value
 * @param {object} sysParamSystemValue The system parameter system value to be updated
 * @returns {{type: string, payload: object}} The action for updating a system parameter system value
 */
export function updateSysParamSystemValue(sysParamSystemValue) {
    return {
        type: UPDATE_SYS_PARAM_SYSTEM_VALUE,
        payload: {
            sysParamSystemValue,
        },
    };
}

/**
 * Action creator for deleting a system parameter system value
 * @param {object} sysParamSystemValue The system parameter system value to be deleted
 * @returns {{type: string, payload: object}} The action for deleting a system parameter system value
 */
export function deleteSysParamSystemValue(sysParamSystemValue) {
    return {
        type: DELETE_SYS_PARAM_SYSTEM_VALUE,
        payload: sysParamSystemValue,
    };
}
