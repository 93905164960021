import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import DateRangePicker from '@rio-cloud/rio-uikit/lib/es/DateRangePicker';
import { DAILY, HOURLY, MONTHLY, timeIntervals, WEEKLY } from '~/features/updateEvents/constants/timeIntervals';

export class UpdateEventReportToolbar extends PureComponent {
    render() {
        const { children, onChangeReportTimeRange, timeSpan } = this.props;
        const renderReportTimeInterval = this.renderReportTimeIntervalSelect();
        return (
            <div className='row device-action-list-toolbar'>
                <div className='col-xs-3'>
                    <div className='btn-toolbar'>
                        {children}
                    </div>
                </div>
                <div className='col-xs-6 text-center'>
                    <div className='col-md-2 text-right padding-top-5'>
                        <FormattedMessage id='intl-msg:timeRange'/>
                    </div>
                    <div className='col-md-10'>
                        <DateRangePicker
                            className='width-500'
                            onRangeChange={(startTime, endTime) => {
                                onChangeReportTimeRange(startTime, endTime)
                            }}
                            startValue={timeSpan.from}
                            endValue={timeSpan.to}
                            textDefault='sdf'
                            customPresets={[{
                                text: <FormattedMessage id='intl-msg:dateRangePicker.preset.today'/>,
                                startValue: moment().utc().startOf('day'),
                                endValue: moment().utc().endOf('day'),
                            }, {
                                text: <FormattedMessage id='intl-msg:dateRangePicker.preset.last7Days'/>,
                                startValue: moment().utc().subtract(7, 'days').startOf('day'),
                                endValue: moment().utc().endOf('day'),
                            }, {
                                text: <FormattedMessage id='intl-msg:dateRangePicker.preset.last30Days'/>,
                                startValue: moment().utc().subtract(30, 'days').startOf('day'),
                                endValue: moment().utc().endOf('day'),
                            }, {
                                text: <FormattedMessage id='intl-msg:dateRangePicker.preset.last3Months'/>,
                                startValue: moment().utc().startOf('month').subtract(2, 'months').startOf('day'),
                                endValue: moment().utc().endOf('month'),
                            }]}
                        />
                    </div>
                </div>
                <div className='col-xs-3'>
                    <div className='col-md-6 text-right  padding-top-5'>
                        <FormattedMessage id='intl-msg:timeInterval'/>
                    </div>
                    <div className='col-md-6'>
                        {renderReportTimeInterval}
                    </div>
                </div>
            </div>
        );
    }

    renderReportTimeIntervalSelect() {
        const reportModeOptionsWithSelection = [{
            id: HOURLY,
            label: <FormattedMessage id={timeIntervals[HOURLY].label}/>,
            selected: this.props.timeInterval === HOURLY,
        }, {
            id: DAILY,
            label: <FormattedMessage id={timeIntervals[DAILY].label}/>,
            selected: this.props.timeInterval === DAILY,
        }, {
            id: WEEKLY,
            label: <FormattedMessage id={timeIntervals[WEEKLY].label}/>,
            selected: this.props.timeInterval === WEEKLY,
        }, {
            id: MONTHLY,
            label: <FormattedMessage id={timeIntervals[MONTHLY].label}/>,
            selected: this.props.timeInterval === MONTHLY,
        }];
        return (
            <Select options={reportModeOptionsWithSelection}
                    onChange={this.props.onChangeReportTimeInterval}/>
        );
    }
}

export default UpdateEventReportToolbar;

UpdateEventReportToolbar.defaultProps = {
    // props
    timeInterval: WEEKLY,
    timeSpan: {
        from: moment().utc().startOf('day').add(-7, 'day'),
        // from: moment().utc().startOf('day'),
        to: moment().utc().endOf('day'),
    },
    // functions
    onChangeReportTimeInterval: noop,
    onChangeReportTimeRange: noop,
};

UpdateEventReportToolbar.propTypes = {
    // props
    timeInterval: PropTypes.string,
    timeSpan: PropTypes.object,
    // functions
    onChangeReportTimeInterval: PropTypes.func,
    onChangeReportTimeRange: PropTypes.func,
};
