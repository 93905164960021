import React from 'react';
import DeliverableIdChip from '~/features/deliverables/components/DeliverableIdChip';
import PackageReleaseChip from '~/features/deliverables/components/PackageReleaseChip';
import FieldOrNoData from '~/features/base/components/FieldOrNoData';
import YesOrNo from '~/features/base/components/YesOrNo';

import isString from 'lodash/fp/isString';
import { toSemanticVersion } from '~/features/base/utils/versionNumberConverter';

export const deviceManagedDeliverableColumnDescriptors = [{
    id: 'deliverableId',
    field: 'deliverableId',
    label: 'intl-msg:deliverableId',
    format: (field, item) => {
        return (<DeliverableIdChip deliverableId={field} deliverableType={item.deliverableType}/>);
    },
}, {
    id: 'deliverableType',
    field: 'deliverableType',
    label: 'intl-msg:deliverableType',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'deliverableVersion',
    field: 'deliverableVersion',
    label: 'intl-msg:deliverableVersion',
    format: (field, item) => [
        field !== undefined ? <PackageReleaseChip packageId={item.deliverableId}
                                                  packageVersion={isString(field) ? toSemanticVersion(field) : field}/>
            : <YesOrNo/>,
        item.eligiblePackageVersion ? [
            <span className='margin-right-5'>→</span>,
            <PackageReleaseChip packageId={item.deliverableId} packageVersion={item.eligiblePackageVersion}/>,
        ] : null,
    ],
}];

// export const devicePackageColumnDescriptors = [{
//     id: 'packageId',
//     field: 'packageId',
//     label: 'intl-msg:packageId',
// }, {
//     id: 'packageVersion',
//     field: 'packageVersion',
//     label: 'intl-msg:packageVersion',
// }];
