import { RECOVER_SYSTEM_STATE_COLUMN_SETTINGS, SET_SYSTEM_STATE_COLUMN_SETTINGS } from '~/features/systemState/actions/systemStateActions';
import React from 'react';
import size from 'lodash/fp/size';
import { SYSTEM_STATE_COLUMN_SETTINGS_LOCAL_STORAGE_KEY } from '~/features/systemState/constants/systemStateNotificationColumnDescriptors';

const defaultState = {
    columnSettings: [{
        id: 'timestamp',
        checked: true,
    }, {
        id: 'messageType',
        checked: true,
    }, {
        id: 'uptime',
        checked: false,
    }, {
        id: 'average_cpu',
        checked: false,
    }, {
        id: 'cpu_usage',
        checked: true,
    }, {
        id: 'cpu0_usage',
        checked: false,
    }, {
        id: 'cpu1_usage',
        checked: false,
    }, {
        id: 'topCpu_processName',
        checked: false,
    }, {
        id: 'topCpu_usage',
        checked: false,
    }, {
        id: 'topCpu_avgUsage',
        checked: false,
    }, {
        id: 'loadavg_1',
        checked: false,
    }, {
        id: 'loadavg_5',
        checked: false,
    }, {
        id: 'loadavg_15',
        checked: true,
    }, {
        id: 'mem_usage',
        checked: true,
    }, {
        id: 'mem_free',
        checked: true,
    }, {
        id: 'processLogLevels',
        checked: false,
    }, {
        id: 'restartedProcesses',
        checked: false,
    }, {
        id: 'processesNotRunning',
        checked: true,
    }, {
        id: 'system_state_version',
        checked: true,
    }, {
        id: 'base_sw_version',
        checked: true,
    }, {
        id: 'emmcHealthInfo_user',
        checked: false,
    }, {
        id: 'emmcHealthInfo_system',
        checked: false,
    }, {
        id: 'disc_usage',
        checked: true,
    }, {
        id: 'disc_usage_common',
        checked: false,
    }, {
        id: 'disc_usage_handover',
        checked: false,
    }, {
        id: 'boot_bank_id',
        checked: true,
    }, {
        id: 'boot_bank_switch_reason',
        checked: false,
    }, {
        id: 'partition',
        checked: true,
    },
    ],
};

const getStored = () => {
    try {
        const storedColumnSettings = JSON.parse(
            localStorage.getItem(SYSTEM_STATE_COLUMN_SETTINGS_LOCAL_STORAGE_KEY));
        // reset locally stored column settings if new columns settings were added
        if (storedColumnSettings && (size(storedColumnSettings.columnSettings) !== size(defaultState.columnSettings))) {
            localStorage.setItem(SYSTEM_STATE_COLUMN_SETTINGS_LOCAL_STORAGE_KEY, JSON.stringify(defaultState));
            return defaultState;
        }
        return storedColumnSettings;
    } catch (e) {
        console.error(e);
    }
};

/**
 * Reducer for system state
 * @param {object} state The current state
 * @param {object} action The action to be processed
 * @returns {object} The new state
 */
export default function systemStateReducer(state = defaultState, action = {}) {
    let recoveredColumnSettings;
    switch (action.type) {
        case SET_SYSTEM_STATE_COLUMN_SETTINGS:
            return action.payload;
        case RECOVER_SYSTEM_STATE_COLUMN_SETTINGS:
            // this will need to be replaced by redux persist in future
            recoveredColumnSettings = getStored();
            return recoveredColumnSettings ? recoveredColumnSettings : state;
        default:
            return state;
    }
}
