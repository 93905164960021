import max from 'lodash/fp/max';
import noop from 'lodash/fp/noop';
import size from 'lodash/fp/size';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import UpdateEventsChart from '~/features/base/components/charts/UpdateEventsChart';
import UpdateEventReportToolbar from '~/features/updateEvents/components/UpdateEventReportToolbar';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import DeliverableChip from '~/features/deliverables/components/DeliverableChip';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';

export class UpdateEventReport extends PureComponent {
    render() {
        const {
            deliverableId,
            deliverableVersion,
            onChangeReportTimeInterval,
            onChangeReportTimeRange,
            timeInterval,
            deliverableType,
        } = this.props;
        const content = this.renderContent();
        return [
            <UpdateEventReportToolbar
                timeInterval={timeInterval}
                onChangeReportTimeInterval={onChangeReportTimeInterval}
                onChangeReportTimeRange={onChangeReportTimeRange}>
                <span className='pointer-events-none'>
                    <FormattedMessage id={'intl-msg:deliverable'}/>
                    {': '}
                    <DeliverableChip
                        deliverableId={deliverableId}
                        deliverableVersion={toShortSemanticVersion(deliverableVersion)}
                        deliverableType={deliverableType}
                    />
                </span>
            </UpdateEventReportToolbar>,
            content,
        ];
    }

    renderContent() {
        const { timeInterval, updateEventReport, updateEventReportLoading } = this.props;
        if (updateEventReportLoading) {
            return <div className='height-500'>
                <Spinner/>
            </div>;
        }
        if (!size(updateEventReport.stats)) {
            return <div className='height-500 padding-25'>
                <NotFoundState outerClassName='border-none'
                               headline={<FormattedMessage id='intl-msg:nothingFound'/>}
                />
            </div>;
        }

        return (
            // <div className='flex-row justify-content-center align-items-center'>
            <UpdateEventsChart
                width={max([500, parent.width])}
                timeInterval={timeInterval}
                height={300}
                trendData={updateEventReport.trend}
                statData={updateEventReport.stats}/>
            // </div>
        );
    }
}

export default UpdateEventReport;

UpdateEventReport.defaultProps = {
    // props
    timeInterval: '',
    timeSpan: {},
    updateEventStats: [],
    // functions
    onChangeReportTimeInterval: noop,
    onChangeReportTimeRange: noop,
    onHide: noop,
};

UpdateEventReport.propTypes = {
    // props
    deliverableId: PropTypes.string,
    deliverableVersion: PropTypes.object,
    timeInterval: PropTypes.string,
    timeSpan: PropTypes.object,
    updateEventStats: PropTypes.array,
    // functions
    onChangeReportTimeInterval: PropTypes.func,
    onChangeReportTimeRange: PropTypes.func,
    onHide: PropTypes.func,
};
