import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import AutoSuggestOTA from '../../base/components/forms/AutoSuggestOTA';
import SelectOTA from '../../base/components/forms/SelectOTA';
import {FormattedMessage} from 'react-intl';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import {
    ALL,
    createBaseSwVersionOptions, createBaseSwVersionOptionsWithoutAll,
    createDistroVersionOptions,
    createHwVariantOptions, createHwVariantOptionsWithoutAll,
    createVehicleSparePartNumberOptions, createVehicleSparePartNumberOptionsWithoutAll,
    createVehicleVariantOptions, createVehicleVariantOptionsWithoutAll,
    deviceStatusNameOptions,
    deviceStatusTypeOptions, deviceStatusTypeOptionsWithoutAll,
    deviceTypeOptions
} from '~/features/base/constants/filterOptions';
import MultiSelectOTA from '~/features/base/components/MultiSelectOTA';
import {useFetchHwVariantsQuery, useFetchSparePartNumbersQuery,} from '~/api/controlDevice/ControlDevice.api';
import {
    useFetchAllDistroVersionsQuery,
    useFetchBaseSwVersionsQuery
} from '~/api/deliverables/DeliverablesManagement.api';
import {allVehicleVariants} from '~/features/vehicleVariants/constants/vehicleVariantsConstants';
import {searchSelector} from '~/features/base/selectors/locationSelectors';
import {useSelector} from 'react-redux';
import {parseQuery} from '~/features/base/utils/query';
import VehicleFiltersApplied from "~/features/vehicles/components/VehicleFiltersApplied";
import {ControlDeviceSearchCriteria} from "~/api/models";

interface VehicleListFiltersDialogProps {
    showDialog: boolean;
    setShowDialog: Function;
    onCriteriaChange: (criteria: ControlDeviceSearchCriteria) => void;
}

const VehicleListFiltersDialog: React.FC<VehicleListFiltersDialogProps> = ({
    showDialog,
    setShowDialog,
    onCriteriaChange
}) => {

    const urlParams = useSelector((state) => searchSelector(state));
    const {
        hwVariantPrefix,
        hwVariantList,
        deviceType,
        vehicleCheckNames,
        vehicleStatus,
        distroVersion,
        vehicleVariants,
        softwareVersion,
        manufacturerSparePartNumber
    } = parseQuery(urlParams);

    const {
        handleSubmit,
        formState,
        control,
        watch,
        setValue,
        reset,
    } = useForm(
        {
            defaultValues: {
                //hwVariantPrefix,
                hwVariantList,
                deviceType: deviceType && deviceTypeOptions.find(option => option.value === deviceType),
                vehicleCheckNames: vehicleCheckNames && deviceStatusNameOptions.find(option => option.label === vehicleCheckNames),
                vehicleStatus: vehicleStatus && deviceStatusTypeOptions.find(option => option.label === vehicleStatus),
                distroVersion,
                vehicleVariants,
                softwareVersion,
                manufacturerSparePartNumber,
            },
            mode: 'onChange'
        }
    );

    useEffect(() => {
        const subscription = watch(() => handleSubmit(onSubmit)());
        return () => {
            subscription.unsubscribe();
        };
    }, [handleSubmit, watch, urlParams]);

    const { data: hwVariants } = useFetchHwVariantsQuery({});
    const { data: sparePartNumbers } = useFetchSparePartNumbersQuery({});
    const { data: baseSwVersions } = useFetchBaseSwVersionsQuery({});
    const { data: distroVersions } = useFetchAllDistroVersionsQuery({});

    const { errors } = formState;

    const onSubmit = data => {
        const { deviceType, vehicleStatus, vehicleCheckNames } = data;

        const formatedData = {
            ...data,
            deviceType: deviceType?.value,
            vehicleStatus: vehicleStatus?.value,
            vehicleCheckNames: vehicleCheckNames?.value,

        };
        onCriteriaChange(formatedData);
    };

    const baseSwVersionOptions = createBaseSwVersionOptionsWithoutAll(baseSwVersions);
    const distroVersionOptions = createDistroVersionOptions(distroVersions);
    const hwVariantOptions = createHwVariantOptionsWithoutAll(hwVariants);
    const vehicleSparePartNumberOptions = createVehicleSparePartNumberOptionsWithoutAll(sparePartNumbers);
    const vehicleVariantOptions = createVehicleVariantOptionsWithoutAll(allVehicleVariants);

    const renderSuggestion = (suggestion) => {
        return (
            <span>{suggestion.label}</span>
        );
    };

    const content = (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={''}>
                   {/* <div className={'margin-bottom-10'}>
                        <label className='control-label'>
                            <FormattedMessage id='intl-msg:hwVariantPrefix'/>
                        </label>
                        <Controller
                            control={control}
                            name='hwVariantPrefix'
                            render={({ field: { value, onChange } }) =>
                                <AutoSuggestOTA
                                    hasError={!!errors.hwVariantPrefix}
                                    suggestions={hwVariantOptions}
                                    renderSuggestion={renderSuggestion}
                                    inputProps={{
                                        value,
                                        onChange: (_, changeProps) => (onChange(changeProps.newValue)),
                                        onClear: () => onChange(undefined),
                                        placeholder: 'Enter hardware variant'
                                    }}
                                />
                            }
                        />
                    </div>*/}
                    <div className={'margin-bottom-10'}>
                        <label className='control-label'>
                            <FormattedMessage id='intl-msg:hwVariants'/>
                        </label>
                        <Controller
                            control={control}
                            name='hwVariantList'
                            render={({ field: { onChange, value } }) =>
                                <MultiSelectOTA
                                    hasError={!!errors.hwVariantList}
                                    placeholder={<FormattedMessage
                                        id='intl-msg:hwVariantPrefix.prompt'/>}
                                    options={hwVariantOptions}
                                    value={value}
                                    onChange={onChange}
                                />
                            }
                        />
                    </div>
                    <div className={'margin-bottom-10 display-flex justify-content-between'}>
                        <div className={'width-33pct margin-right-10'}>
                            <label className='control-label'>
                                <FormattedMessage id='intl-msg:deviceType'/>
                            </label>
                            <Controller
                                control={control}
                                name='deviceType'
                                render={({ field: { onChange, value } }) =>
                                    <SelectOTA
                                        useClear
                                        hasError={!!errors.deviceType}
                                        options={deviceTypeOptions}
                                        value={value?.id}
                                        onChange={onChange}
                                        placeholder={<FormattedMessage
                                            id='intl-msg:deviceType.prompt'/>}                                />

                                }
                            />
                        </div>
                        <div className={'width-33pct margin-right-10'}>
                            <label className='control-label'>
                                <FormattedMessage id='intl-msg:vehicleStatus'/>
                            </label>
                            <Controller
                                control={control}
                                name='vehicleCheckNames'
                                render={({ field: { onChange, value } }) =>
                                    <SelectOTA
                                        useClear
                                        hasError={!!errors.vehicleCheckNames}
                                        options={deviceStatusNameOptions.filter((option) => option.id !== ALL)}
                                        value={value?.id}
                                        onChange={onChange}
                                        placeholder={<FormattedMessage
                                            id='intl-msg:vehicleStatus.prompt'/>}
                                    />
                                }
                            />
                        </div>
                        <div className={'width-33pct'}>
                            <label className='control-label'>
                                <FormattedMessage id='intl-msg:vehicleHealth'/>
                            </label>
                            <Controller
                                control={control}
                                name='vehicleStatus'
                                render={({ field: { onChange, value } }) =>
                                    <SelectOTA
                                        useClear
                                        hasError={!!errors.vehicleStatus}
                                        placeholder={<FormattedMessage
                                            id='intl-msg:vehicleHealth.prompt'/>}
                                        options={deviceStatusTypeOptionsWithoutAll}
                                        value={value?.id}
                                        onChange={onChange}
                                    />
                                }
                            />
                        </div>
                    </div>
                    <div className={'margin-bottom-10 display-flex justify-content-between'}>
                        <div className={'width-50pct margin-right-10'}>
                            <label className='control-label'>
                                <FormattedMessage id='intl-msg:distroVersion'/>
                            </label>
                            <Controller
                                control={control}
                                name='distroVersion'
                                render={({ field: { value, onChange }, }) => {
                                    return (<AutoSuggestOTA
                                        hasError={!!errors.distroVersion}
                                        suggestions={distroVersionOptions}
                                        inputProps={{
                                            value,
                                            onChange: (_, changeProps) => (onChange(changeProps.newValue)),
                                            onClear: onChange,
                                            placeholder: 'Select distro version'
                                        }}
                                    />);
                                }
                                }
                            />
                        </div>
                        <div className={'width-50pct'}>
                            <label className='control-label'>
                                <FormattedMessage id='intl-msg:baseSwVersion'/>
                            </label>
                            <Controller
                                control={control}
                                name='softwareVersion'
                                render={({ field: { onChange, value } }) =>
                                    <AutoSuggestOTA
                                        hasError={!!errors.softwareVersion}
                                        suggestions={baseSwVersionOptions}
                                        inputProps={{
                                            value,
                                            onChange: (_, changeProps) => (onChange(changeProps.newValue)),
                                            onClear: onChange,
                                            placeholder: 'Enter software version'
                                        }}
                                    />
                                }
                            />
                        </div>
                    </div>
                    <div className={'margin-bottom-10'}>
                        <label className='control-label'>
                            <FormattedMessage id='intl-msg:vehicleVariants'/>
                        </label>
                        <Controller
                            control={control}
                            name='vehicleVariants'
                            render={({ field: { onChange, value } }) =>
                                <MultiSelectOTA
                                    hasError={!!errors.vehicleVariants}
                                    placeholder={<FormattedMessage
                                        id='intl-msg:vehicleVariants.prompt'/>}
                                    options={vehicleVariantOptions}
                                    value={value}
                                    onChange={onChange}
                                />
                            }
                        />
                    </div>

                    <div className={'margin-bottom-10'}>
                        <label className='control-label'>
                            <FormattedMessage id='intl-msg:manufacturerSparePartNumber'/>
                        </label>
                        <Controller
                            control={control}
                            name='manufacturerSparePartNumber'
                            render={({ field: { value, onChange }, }) => {
                                return (<AutoSuggestOTA
                                    hasError={!!errors.manufacturerSparePartNumber}
                                    suggestions={vehicleSparePartNumberOptions}
                                    inputProps={{
                                        value,
                                        onChange: (_, changeProps) => (onChange(changeProps.newValue)),
                                        onClear: onChange,
                                        placeholder: 'Enter spare part number'
                                    }}
                                />);
                            }
                            }
                        />
                    </div>

                </div>
                <div className={'btn-toolbar justify-content-end margin-top-20'}>
                    <a id='clear-filters-button'
                       className='btn btn-default'
                       onClick={ () => {reset({})} }>
                        <span className='rioglyph rioglyph-trash' aria-hidden='true'></span>&nbsp;
                        <FormattedMessage id='intl-msg:clearFilters'/>
                    </a>
                    <button className='btn btn-default' onClick={ () => {setShowDialog(false)} }>
                        <FormattedMessage id='intl-msg:ok'/>
                    </button>
                </div>
            </form>

        </div>
    );

    // @ts-ignore
    return (
        <div className={''}>
            {<VehicleFiltersApplied onClear={() => reset()} onCriteriaChange={(property, value) => setValue(`${property}`, value)}/>}
                <Dialog
                    show={showDialog}
                    title='Filters'
                    body={content}
                    onHide={() => {
                        setShowDialog(false);
                    }}
                />
        </div>
    );
};

export default VehicleListFiltersDialog;
