import React, { useEffect, useState, ReactNode, } from 'react';
import { FC } from 'react';
import ListTableTS from '~/features/base/components/table/ListTableTS';
import find from 'lodash/fp/find';
import size from 'lodash/fp/size';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { vehicleColumnDescriptors } from '~/features/vehicles/constants/vehicleColumnDescriptors';
import { ColumnDescriptorModel } from '~/features/base/models/ColumnDescriptorModel';
import { VehicleDefaultView } from '~/api/models';
import { TableLoader } from '~/features/base/components/TableLoader';
import { setVehicleSelected } from '~/features/vehicles/vehiclesSlice';

interface VehiclesListTableProps {
    vehicles: VehicleDefaultView[];
    hiddenColumns: any[];
    allowSelection: boolean;
    selectedItems: any[];
    selectedAll: boolean;
    canUpdateControlVehicles: boolean;
    onEditVehicle: Function;
    onSelectItem: Function;
    onSelectAll: Function;
    onShowItem: Function;
    setShowVehicleDetailsSidebar: Function;
    isLoading: boolean;
}

const VehiclesListTable: FC<VehiclesListTableProps> = ({
    vehicles = [],
    hiddenColumns = [],
    allowSelection = false,
    selectedItems = [],
    selectedAll = false,
    canUpdateControlVehicles = false,
    onEditVehicle,
    onSelectItem,
    onSelectAll,
    onShowItem,
    setShowVehicleDetailsSidebar,
    isLoading
}: VehiclesListTableProps) => {
    const dispatch = useDispatch();

    const [actionsColumn, setActionsColumn] = useState({
        id: 'actions',
        field: 'serialNumber',
        className: 'table-action',
        format: field => {
            const items: { value: ReactNode }[] = [];
            if (canUpdateControlVehicles) {
                items.push({
                    value: (
                        <div className='' onClick={onEditButtonClick} data-key={field}>
                            <span className='rioglyph rioglyph-pencil margin-right-10'></span>
                            <span><FormattedMessage id='intl-msg:editControlDevice'/></span>
                        </div>
                    ),
                });
            }
            if (size(items)) {
                return (
                    <ButtonDropdown
                        title={<span className='rioglyph rioglyph-option-vertical'/>}
                        className='pull-right'
                        bsStyle='link'
                        iconOnly
                        items={items}/>
                );
            }
        },
        formatLabel: () => '',
    });

    const [columnOrder, setColumnOrder] = useState<string[]>([]);

    const columns: ColumnDescriptorModel[] = [...vehicleColumnDescriptors, actionsColumn];

    let filteredAndOrderedColumns: ColumnDescriptorModel[] = [];
    columnOrder.forEach(columnId => {
        if (!hiddenColumns.includes(columnId)) {
            // @ts-ignore
            filteredAndOrderedColumns.push(columns.find(entry => entry.id === columnId));
        }
    });

    useEffect(() => {
        let defaultColumnLabels = {};
        vehicleColumnDescriptors.forEach(entry => {
            defaultColumnLabels[entry.id] = { id: entry.label };
        });
        setColumnOrder(Object.keys(defaultColumnLabels));
    }, []);

    const onRowClick = (rowId, isSelected) => {
        setShowVehicleDetailsSidebar(rowId);
        onSelectItem(rowId, isSelected);
        if (isSelected) {
            dispatch(setVehicleSelected(rowId));
        } else {
            dispatch(setVehicleSelected(undefined));
        }
    };

    const onEditButtonClick = (event) => {
        const key = event.currentTarget.getAttribute('data-key');
        const controlDevice = find(item => item.vin === key, vehicles);
        onEditVehicle({
            isNew: false,
            ...controlDevice,
        });
    };

    if (isLoading && vehicles.length === 0) {
        const allLabels = filteredAndOrderedColumns.map(column => column.label);
        return <TableLoader labels={allLabels} nLines={50}/>;
    }

    return (
        <ListTableTS
            className='table-bordered table-condensed'
            items={vehicles}
            itemKey='vin'
            highlightSelectedRow
            columnDescriptors={filteredAndOrderedColumns}
            selectedItems={selectedItems}
            selectedAll={selectedAll}
            onRowClick={onRowClick}/>
    );
};

export default VehiclesListTable;
