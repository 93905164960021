import React, { FC, useEffect, useState } from 'react';
import { useFetchVehiclesQuery } from '~/api/vehicles/VehicleManagement.api';
import LoadMoreButton from '@rio-cloud/rio-uikit/lib/es/LoadMoreButton';
import { useDispatch, useSelector } from 'react-redux';
import VehicleListTable from '~/features/vehicles/components/VehicleListTable';
import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';
import VehicleListToolbar from '~/features/vehicles/components/VehicleListToolbar';
import { getSelectedVehicle, setVehicleSelected } from '~/features/vehicles/vehiclesSlice';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { VEHICLES_PATH } from '~/features/base/constants/routes';
import { parseQuery } from '~/features/base/utils/query';
import { followRoute } from '~/features/base/actions/ui/routeActions';
import { searchSelector } from '~/features/base/selectors/locationSelectors';
import { ControlDeviceSearchCriteria } from '~/api/models';
import {queryToSearchCriteria} from "~/features/vehicles/utils/vehicleQuery";

const PAGE_SIZE = 50;
const VehiclesListContainer: FC = () => {
    const [page, setPage] = useState<number>(1);
    const search = useSelector((state) => searchSelector(state));

    const [criteria, setCriteria] = useState<ControlDeviceSearchCriteria>({});

    const { isLoading, data: vehicleList } = useFetchVehiclesQuery({
        pageNumber: 0,
        pageSize: PAGE_SIZE * (page),
        ...criteria
    });
    const [hiddenColumns, setHiddenColumns] = useState([]);
    const { vehicleVin } = useParams();
    const selectedVehicle = useSelector(getSelectedVehicle);
    const dispatch = useDispatch();

    const history = useHistory();

    const handleLoadMore = () => {
        setPage(page + 1);
    };

    const handleChangeCriteria = (query) => {
        const parsedSearch = parseQuery(search);
        const newQuery = { ...parsedSearch, ...query };
        dispatch(followRoute({ route: VEHICLES_PATH, query: newQuery }));
    };

    const selectVehicleFromRoute = () => {
        if (vehicleVin) {
            dispatch(setVehicleSelected(vehicleVin));
        }
    };
    const updateURlWithSelectedVehicle = () => {
        if (selectedVehicle) {
            history.replace(`/${VEHICLES_PATH}/${selectedVehicle}${search}`);
        } else {
            history.replace(`/${VEHICLES_PATH}${search}`);
        }
    };

    useEffect(() => {
        setPage(1);
    }, [search, criteria]);

    useEffect(() => {
        const parsedQuery = parseQuery(search);
        setCriteria(queryToSearchCriteria(parsedQuery));
    }, [search]);

    useEffect(() => {
        selectVehicleFromRoute();
    }, []);

    useEffect(() => {
        updateURlWithSelectedVehicle();
    }, [selectedVehicle]);
    return (
        <>
            <DefaultWhiteColumn className='padding-top-20'>
                <VehicleListToolbar totalVehicles={vehicleList?.totalElements || 0}
                                    criteria={criteria}
                                    onCriteriaChange={handleChangeCriteria}/>
                <VehicleListTable
                    isLoading={isLoading}
                    vehicles={vehicleList ? [...vehicleList.content] : []}
                    hiddenColumns={hiddenColumns}
                    canUpdateControlVehicles={true}
                    allowSelection={true}
                    selectedItems={[]}
                    selectedAll={false}
                    onEditVehicle={() => {
                    }}
                    onSelectItem={() => {
                    }}
                    onSelectAll={() => {
                    }}
                    onShowItem={() => {
                    }}
                    setShowVehicleDetailsSidebar={() => {
                    }}/>


            </DefaultWhiteColumn>
            <React.Fragment>
                <LoadMoreButton
                    loaded={vehicleList?.content.length || 0}
                    total={vehicleList?.totalElements || 0}
                    onLoadMore={handleLoadMore}
                    loadMoreMessage='Load more'
                    noMoreMessage='Everything loaded'
                />
            </React.Fragment>
        </>

    );
};

export default VehiclesListContainer;
