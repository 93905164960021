import React, {useRef, useEffect} from 'react';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import {
    STATUS_WARN_LEVEL_1,
    STATUS_WARN_LEVEL_2,
    STATUS_CONFIRMED, STATUS_WARN_BUNDLE_RELEASE_STATE,
} from '../../../base/constants/deliverablesWhiteListing';
import {FormattedMessage} from 'react-intl';

interface DeliverableWarningsDialogProps {
    showConfirm: boolean;
    whiteListedDevices: number;
    onReview: (param: boolean, status: string) => void;
    onSaveFileDeliverable: () => void;
    warnings: string[];
}

const DeliverableWarningsDialog: React.FC<DeliverableWarningsDialogProps> = ({
                                                                                 showConfirm,
                                                                                 warnings,
                                                                                 whiteListedDevices,
                                                                                 onReview,
                                                                                 onSaveFileDeliverable,
                                                                             }) => {
    const level1MainButton = useRef<HTMLButtonElement>(null);
    const level2MainButton = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (level1MainButton.current) {
            level1MainButton.current.focus();
        }
        if (level2MainButton.current) {
            level2MainButton.current.focus();
        }
    }, [showConfirm]);

    const onSave = () => {
        onSaveFileDeliverable();
    };

    const review = () => {
        onReview(false, STATUS_CONFIRMED);
    };

    const hasBundleReleaseStateWarning = () => !!warnings.find(warn => warn === STATUS_WARN_BUNDLE_RELEASE_STATE);
    const hasL1Warning = !!warnings.find(warn => warn === STATUS_WARN_LEVEL_1);
    const hasL2Warning = warnings.find(warn => warn === STATUS_WARN_LEVEL_2);
    const getLevelWarningMessage = () => {
        if (hasL1Warning) {
            return 'intl-msg:fileDeliverableEditor.Message.WarnLevel1';
        } else if (hasL2Warning) {
            return 'intl-msg:fileDeliverableEditor.Message.WarnLevel2';
        }
        return undefined;
    };

    const content = (
        <div className='text-center'>
            <div className='margin-50'>
                <div className=' text-size-h4 margin-bottom-10'>
                    <FormattedMessage
                        id='intl-msg:deliverables.bundleDialogWarning.create'
                    />
                </div>
                {(hasL1Warning || hasL2Warning) && <div className='text-size-14 text-color-warning'>
                    <span className='rioglyph rioglyph-info-sign margin-right-10'/>
                    <FormattedMessage
                        id={getLevelWarningMessage()}
                        values={{ whiteListedDevices: whiteListedDevices, br: <br/> }}
                    />
                </div>}
                {hasBundleReleaseStateWarning() && <div className='text-size-14 text-color-warning'>
                    <span className='rioglyph rioglyph-info-sign margin-right-10'/>
                    <FormattedMessage
                        id='intl-msg:deliverables.bundleReleaseStateWarning'/>
                </div>}
            </div>
            <div className='pull-right btn-toolbar'>
                <button type='button' className='btn btn-default'
                        onClick={review}>
                    <FormattedMessage id='intl-msg:reviewRecommended'/>
                </button>
                {!hasL2Warning && <button type='button' className='btn btn-primary'
                                          onClick={onSave}>
                    <FormattedMessage id='intl-msg:save'/>
                </button>}
            </div>
        </div>
    );

    return <Dialog
        show={showConfirm}
        body={content}
        showCloseButton={false}
    />;
};


export default DeliverableWarningsDialog;
