import { FETCH_USER } from '~/features/user/actions/userActions';
import { toSuccess, toFailed } from '~/features/higherorder/transforms/actionTransforms';

const defaultState = {
    userLoaded: true,
};

export const errorsReducer = (state = defaultState, action = {}) => {
    switch (action.type) {
        case toSuccess(FETCH_USER):
            return {
                ...state,
                userLoaded: true,
            };
        case toFailed(FETCH_USER):
            return {
                ...state,
                userLoaded: false,
            };
        default:
            return state;
    }
};

export default errorsReducer;
