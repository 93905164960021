import map from 'lodash/fp/map';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import uid from '~/features/base/utils/uid';

export const VehiclesLegend = ({ data }) => {
    const items = map.convert({ cap: false })((item) => (
        <div key={uid()} className={'width-50pct pull-left padding-left-5 padding-right-5'}>
            <span className={`${item.classes} text-size-h2`} style={{ color: item.color }}></span>
            <span className={'position-absolute margin-left-5 margin-top-5'}>{item.label}</span>
            <span className='badge text-size-smallest margin-top-5 pull-right'
                  style={{ backgroundColor: item.color }}>
                <FormattedNumber value={item.value}/>
            </span>
        </div>
    ), data);
    return (
        <div className='width-100pct clearfix'>
            {items}
        </div>
    );
};

export default VehiclesLegend;

VehiclesLegend.propTypes = {
    // props
    data: PropTypes.array,
    route: PropTypes.string,
    query: PropTypes.object,
};
