import get from 'lodash/fp/get';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import SemanticVersionInputFormItem from './SemanticVersionInputFormItem';

export class SemanticVersionRangeInputFormItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clearing: false,
        };
    }

    onMdmcAppMinVersionChange = (minValue) => {
        const range = {
            minValue,
            maxValue: get('maxValue', this.props.range),
        };
        this.props.onChange(range);
    }

    onMdmcAppMaxVersionChange = (maxValue) => {
        const range = {
            minValue: get('minValue', this.props.range),
            maxValue,
        };
        this.props.onChange(range);
    }

    render() {
        const { label, minLabel, maxLabel, range } = this.props;
        return (
            <div className='form-group form-group-semantic-version-range'>
                <label className='control-label'>
                    <FormattedMessage id={label} />
                </label>
                <div className='margin-left-15 margin-right-15'>
                    <SemanticVersionInputFormItem label={minLabel}
                        value={range.minValue}
                        isClearable={this.props.isClearable}
                        onChange={this.onMdmcAppMinVersionChange}/>
                    <SemanticVersionInputFormItem label={maxLabel}
                        value={range.maxValue}
                        isClearable={this.props.isClearable}
                        onChange={this.onMdmcAppMaxVersionChange}/>
                </div>
            </div>
        );
    }
}

export default SemanticVersionRangeInputFormItem;

SemanticVersionRangeInputFormItem.defaultProps = {
    // props
    label: 'intl-msg:mdmcAppVersion',
    minLabel: 'intl-msg:minValue',
    maxLabel: 'intl-msg:maxValue',
    range: {
    },
    isClearable: false,
    // functions
    onChange: noop,
};

SemanticVersionRangeInputFormItem.propTypes = {
    // props
    label: PropTypes.string,
    minLabel: PropTypes.string,
    maxLabel: PropTypes.string,
    range: PropTypes.object,
    isClearable: PropTypes.bool,
    // functions
    onChange: PropTypes.func,
};
