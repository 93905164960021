import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { OVERVIEW_PATH, LOG_REQUESTS_PATH } from '~/features/base/constants/routes';

export const DeviceLogsAction = ({ deviceAction }) => {
    if (deviceAction) {
        return (
            <div className={'margin-bottom-25'}>
                <p className='bg-info padding-10 margin-0'>
                    <FormattedMessage id='intl-msg:deviceHasLogRequest' values={{
                        link: (
                            <Link className={'text-bold text-color-white'} target={'_blank'}
                                  to={`/${OVERVIEW_PATH}/${LOG_REQUESTS_PATH}/${deviceAction.hwSerial}`}>
                                <FormattedMessage id='intl-msg:goToLogRquest'/>
                            </Link>
                        ),
                    }}/>
                </p>
            </div>
        );
    }
    return null;
};

export default DeviceLogsAction;

DeviceLogsAction.propTypes = {
    // props
    deviceAction: PropTypes.object,
};
