import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { groupRowDescriptors } from '~/features/groups/constants/groupRowDescriptors';

import { groupWithSerialNumbersSelector } from '~/features/groups/selectors/groupSelectors';

import PropertiesTable from '~/features/base/components/table/PropertiesTable';
import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';

export class GroupPropertiesContainer extends PureComponent {

    getErrorMessage() {
        return (
            <div className='error-message'>
                <FormattedMessage id='intl-msg:controlDevices.fetch.error'/>
            </div>
        );
    }

    render() {
        const { groupDesc } = this.props;
        return (
            <DefaultWhiteColumn className='padding-top-20 padding-bottom-20'>
                <PropertiesTable data={groupDesc} rowDescriptors={groupRowDescriptors} />
            </DefaultWhiteColumn>
        );
    }

    componentWillMount() {
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        groupDesc: groupWithSerialNumbersSelector(state, ownProps),
    };
};

export const mapDispatchToProps = (/*dispatch*/) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupPropertiesContainer);

GroupPropertiesContainer.defaultProps = {
    // props
};

GroupPropertiesContainer.propTypes = {
    // props
    groupName: PropTypes.string,
    groupDesc: PropTypes.object,
};
