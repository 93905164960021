import PropTypes from 'prop-types';
import File from '~/prop-types/filePropType';

import React from 'react';
import { Link } from 'react-router-dom';

import { FILES_PATH } from '~/features/base/constants/routes';

import Chip from '~/features/base/components/Chip';

export const FileChip = ({ fileId, bsStyle, className }) => {
    const name = fileId.name || fileId;
    return (
        <Link to={`/${FILES_PATH}/${name}`}>
            <Chip bsStyle={bsStyle} className={className}>{name}</Chip>
        </Link>
    );
};
export default FileChip;

FileChip.propTypes = {
    // props
    fileId: PropTypes.oneOfType([
        PropTypes.instanceOf(File),
        PropTypes.string,
    ]).isRequired,
    bsStyle: PropTypes.string,
    className: PropTypes.string,
};
