import findIndex from 'lodash/fp/findIndex';
import last from 'lodash/fp/last';

import { call, put, takeLatest } from 'redux-saga/effects';

import {
    SYS_PARAM_DEFINITION_EDITOR_MODAL,
    SYS_PARAM_DEFINITION_DELETE_MODAL,
} from '~/features/base/constants/modals';
import {
    STRING,
    NUMERIC,
    ENUMERATION,
    BYTE_ARRAY,
} from '~/features/sysParams/constants/sysParamTypes';
import {
    accessKeyHasDefault,
} from '~/features/sysParams/constants/sysParamGroups';

import { showModal } from '~/features/base/actions/ui/modalsActions';
import {
    resetSysParamDefinitionEditor,
    changeSysParamDefinitionEditor,
    SHOW_SYS_PARAM_DEFINITION_EDITOR,
    SHOW_DELETE_SYS_PARAM_DEFINITION_DIALOG,
} from '~/features/sysParams/actions/sysParamDefinitionEditorActions';

export function* doPrepareForNewSysParamDefinitionSaga(payload) {
    let baseSoftwareVersion;
    const showBaseSwVersionForm = !payload.baseSoftwareVersion;
    if (showBaseSwVersionForm) {
        baseSoftwareVersion = {
            major: 1,
            minor: 0,
            patch: 0,
        };
    } else {
        baseSoftwareVersion = payload.baseSoftwareVersion;
    }
    const sysParams = payload.sysParams;
    const lastSysParamDefinition = last(sysParams);
    const accessKey = lastSysParamDefinition ? lastSysParamDefinition.accessKey + 1 : 0;

    yield put(changeSysParamDefinitionEditor({
        isNew: true,
        accessKey,
        baseSoftwareVersion,
        showBaseSwVersionForm,
    }));
}

export function* doPrepareForExistingSysParamDefinitionSaga(payload) {
    const sysParam = payload.sysParam;
    const sysParamDefaultValue = sysParam.defaultValue;
    const hasDefault = accessKeyHasDefault(sysParam.accessKey);

    const editorModel = {
        isNew: false,
        hasDefault,
        accessKey: sysParam.accessKey,
        baseSoftwareVersion: sysParam.baseSoftwareVersion,
        name: sysParam.name,
        description: sysParam.description,
        unit: sysParam.unit,
    };
    if (sysParam.stringParameterDefinition) {
        editorModel.type = STRING;
        editorModel.stringMaxLength = sysParam.stringParameterDefinition.maxLength;
        if (hasDefault && sysParamDefaultValue) {
            editorModel.stringDefaultValue = sysParamDefaultValue.value;
        }
    } else if (sysParam.numericParameterDefinition) {
        editorModel.type = NUMERIC;
        editorModel.numericType = sysParam.numericParameterDefinition.type;
        editorModel.numericMinValue = sysParam.numericParameterDefinition.minValue;
        editorModel.numericMaxValue = sysParam.numericParameterDefinition.maxValue;
        if (hasDefault && sysParamDefaultValue) {
            editorModel.numericDefaultValue = sysParamDefaultValue.value;
        }
    } else if (sysParam.enumParameterDefinition) {
        editorModel.type = ENUMERATION;
        editorModel.enumValues = sysParam.enumParameterDefinition.enumValues;
        editorModel.enumParameterDefinition = {
            enumValues: sysParam.enumParameterDefinition.enumValues,
            numericType: sysParam.enumParameterDefinition.numericType || STRING,
        };
        if (hasDefault && sysParamDefaultValue) {
            editorModel.enumDefaultValueIndex = findIndex(o => o.value === sysParamDefaultValue.value, editorModel.enumValues);
        }
    } else if (sysParam.byteArrayParameterDefinition) {
        editorModel.type = BYTE_ARRAY;
        editorModel.byteArrayByteLength = sysParam.byteArrayParameterDefinition.byteLength;
    }
    yield put(changeSysParamDefinitionEditor(editorModel));
}

export function* doShowSysParamDefinitionEditorSaga(action) {
    yield put(resetSysParamDefinitionEditor());
    if (action.payload.isNew) {
        yield call(doPrepareForNewSysParamDefinitionSaga, action.payload);
    } else {
        yield call(doPrepareForExistingSysParamDefinitionSaga, action.payload);
    }
    yield put(showModal({
        modalType: SYS_PARAM_DEFINITION_EDITOR_MODAL,
        modalProps: {
        },
    }));
}

export function* doShowSysParamDefinitionDeletionDialogSaga(action) {
    yield put(resetSysParamDefinitionEditor());
    const sysParam = action.payload.sysParam;
    yield put(changeSysParamDefinitionEditor({
        baseSoftwareVersion: sysParam.baseSoftwareVersion,
        name: sysParam.name,
        accessKey: sysParam.accessKey,
    }));
    yield put(showModal({
        modalType: SYS_PARAM_DEFINITION_DELETE_MODAL,
        modalProps: {

        },
    }));
}

export function* showSysParamDefinitionEditorSaga() {
    yield takeLatest(SHOW_SYS_PARAM_DEFINITION_EDITOR, doShowSysParamDefinitionEditorSaga);
}
export function* showSysParamDefinitionDeletionDialogSaga() {
    yield takeLatest(SHOW_DELETE_SYS_PARAM_DEFINITION_DIALOG, doShowSysParamDefinitionDeletionDialogSaga);
}
