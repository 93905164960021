import {
    toShortSemanticVersion,
} from '~/features/base/utils/versionNumberConverter';

export const toPackageIdAndVersion = (packageId, shortAppVersion) => `${packageId}@${shortAppVersion}`;

export const parsePackageDeliverable = ({ deliverableId, deliverableVersion, ...rest }) => {
    const shortPackageVersion = toShortSemanticVersion(deliverableVersion);
    const result = {
        packageIdAndVersion: toPackageIdAndVersion(deliverableId, shortPackageVersion),
        packageId: deliverableId,
        deliverableVersion,
        shortPackageVersion,
        ...rest,
    };
    return result;
};

export const parsePackageDeliverableVersions = ({ packageVersions }) => {
    let allPackageVersions = [];
    packageVersions.forEach(packageVersion => {
        allPackageVersions.push(toShortSemanticVersion(packageVersion));
    });
    return allPackageVersions;
};

