import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { dynamicBaseQuery } from '~/api/common/dynamicBaseQuery';
import { LogLevelRequestDto } from '~/api/models/LogLevelRequestDto';

export const deviceActionServiceApi = createApi({
    reducerPath: 'logsTBM3Api',
    baseQuery: dynamicBaseQuery,
    endpoints: (builder) => ({
        setLogTBM3Level: builder.mutation<{}, LogLevelRequestDto>({
            query: (LogParams: LogLevelRequestDto) => ({
                url: `/v1/admin/device-action/loglevel/${LogParams.serialNumber}`,
                method: 'POST',
                body: LogParams,
            }),
        }),
    })
});

export const {
    useSetLogTBM3LevelMutation,
} = deviceActionServiceApi;
