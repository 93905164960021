export const FETCH_BASE_SW_VERSIONS = 'FETCH_BASE_SW_VERSIONS';
export const FETCH_EDITABLE_BASE_SW_VERSIONS = 'FETCH_EDITABLE_BASE_SW_VERSIONS';

export const fetchBaseSwVersions = () => {
    return {
        type: FETCH_BASE_SW_VERSIONS,
    };
};
export const fetchEditableBaseSwVersions = () => {
    return {
        type: FETCH_EDITABLE_BASE_SW_VERSIONS,
    };
};
