export const PERMISSION_PACKAGE_CREATE = 'entity.package.create';
export const PERMISSION_PACKAGE_READ = 'entity.package.read';
export const PERMISSION_PACKAGE_UPDATE = 'entity.package.update';
export const PERMISSION_PACKAGE_DELETE = 'entity.package.delete';

export const PERMISSION_PACKAGE_DELIVERABLE_CREATE = 'entity.packageDeliverable.create';
export const PERMISSION_PACKAGE_DELIVERABLE_READ = 'entity.packageDeliverable.read';
export const PERMISSION_PACKAGE_DELIVERABLE_UPDATE = 'entity.packageDeliverable.update';
export const PERMISSION_PACKAGE_DELIVERABLE_DELETE = 'entity.packageDeliverable.delete';

export const PERMISSION_FILE_CREATE = 'entity.file.create';
export const PERMISSION_FILE_READ = 'entity.file.read';
export const PERMISSION_FILE_UPDATE = 'entity.file.update';
export const PERMISSION_FILE_DELETE = 'entity.file.delete';

export const PERMISSION_FILE_DELIVERABLE_CREATE = 'entity.fileDeliverable.create';
export const PERMISSION_FILE_DELIVERABLE_READ = 'entity.fileDeliverable.read';
export const PERMISSION_FILE_DELIVERABLE_UPDATE = 'entity.fileDeliverable.update';
export const PERMISSION_FILE_DELIVERABLE_DELETE = 'entity.fileDeliverable.delete';


export const DELIVERABLE_USER_INFO_PERMISSIONS = 'permissions';
export const PERMISSION_DELIVERABLE_FILE_READ = 'file.read';
export const PERMISSION_DELIVERABLE_FILE_WRITE = 'file.write';
export const PERMISSION_DELIVERABLE_FILE_DELETE = 'file.delete';

export const ARTIFACT_USER_INFO_PERMISSIONS = 'permissions';
export const PERMISSION_ARTIFACT_FILE_READ = 'file.read';
export const PERMISSION_ARTIFACT_FILE_WRITE = 'file.write';
export const PERMISSION_ARTIFACT_FILE_DELETE = 'file.delete';

export const PERMISSION_CONTROL_DEVICE_CREATE = 'entity.controlDevice.create';
export const PERMISSION_CONTROL_DEVICE_READ = 'entity.controlDevice.read';
export const PERMISSION_CONTROL_DEVICE_UPDATE = 'entity.controlDevice.update';
export const PERMISSION_CONTROL_DEVICE_DELETE = 'entity.controlDevice.delete';

export const PERMISSION_SYS_PARAM_DEVICE_VALUES_READ = 'entity.systemParameterDeviceManagedValues.read';

export const PERMISSION_DEVICE_ACTION_CREATE = 'entity.deviceActions.create';
export const PERMISSION_DEVICE_ACTION_READ = 'entity.deviceActions.read';
export const PERMISSION_DEVICE_ACTION_UPDATE = 'entity.deviceActions.update';
export const PERMISSION_DEVICE_ACTION_DELETE = 'entity.deviceActions.delete';

export const PERMISSION_OTA_PARAMETER_DEFAULT_CREATE = 'entity.otaParametersDefault.create';
export const PERMISSION_OTA_PARAMETER_DEFAULT_READ = 'entity.otaParametersDefault.read';
export const PERMISSION_OTA_PARAMETER_DEFAULT_UPDATE = 'entity.otaParametersDefault.update';
export const PERMISSION_OTA_PARAMETER_DEFAULT_DELETE = 'entity.otaParametersDefault.delete';
export const PERMISSION_OTA_PARAMETER_SPECIFIC_CREATE = 'entity.otaParametersSpecific.create';
export const PERMISSION_OTA_PARAMETER_SPECIFIC_READ = 'entity.otaParametersSpecific.read';
export const PERMISSION_OTA_PARAMETER_SPECIFIC_UPDATE = 'entity.otaParametersSpecific.update';
export const PERMISSION_OTA_PARAMETER_SPECIFIC_DELETE = 'entity.otaParametersSpecific.delete';

export const PERMISSION_SYS_PARAM_DEFAULT_VALUES_CREATE = 'entity.systemParameterDefaultValues.create';
export const PERMISSION_SYS_PARAM_DEFAULT_VALUES_READ = 'entity.systemParameterDefaultValues.read';
export const PERMISSION_SYS_PARAM_DEFAULT_VALUES_UPDATE = 'entity.systemParameterDefaultValues.update';
export const PERMISSION_SYS_PARAM_DEFAULT_VALUES_DELETE = 'entity.systemParameterDefaultValues.delete';
export const PERMISSION_SYS_PARAM_VALUES_CREATE = 'entity.systemParameterValues.create';
export const PERMISSION_SYS_PARAM_VALUES_READ = 'entity.systemParameterValues.read';
export const PERMISSION_SYS_PARAM_VALUES_UPDATE = 'entity.systemParameterValues.update';
export const PERMISSION_SYS_PARAM_VALUES_DELETE = 'entity.systemParameterValues.delete';
export const PERMISSION_SYS_PARAM_DEFINITIONS_CREATE = 'entity.systemParameterDefinitions.create';
export const PERMISSION_SYS_PARAM_DEFINITIONS_READ = 'entity.systemParameterDefinitions.read';
export const PERMISSION_SYS_PARAM_DEFINITIONS_UPDATE = 'entity.systemParameterDefinitions.update';
export const PERMISSION_SYS_PARAM_DEFINITIONS_DELETE = 'entity.systemParameterDefinitions.delete';

export const PERMISSION_SOFTWARE_INFO_CREATE = 'entity.tbmSoftwareInfo.create';
export const PERMISSION_SOFTWARE_INFO_READ = 'entity.tbmSoftwareInfo.read';
export const PERMISSION_SOFTWARE_INFO_UPDATE = 'entity.tbmSoftwareInfo.update';
export const PERMISSION_SOFTWARE_INFO_DELETE = 'entity.tbmSoftwareInfo.delete';

export const PERMISSION_SOFTWARE_INFO_CHANGE_READ = 'entity.tbmSoftwareInfoChange.read';

export const PERMISSION_UPDATE_HISTORY_READ = 'entity.updateHistory.read';

export const PERMISSION_SYS_PARAM_COPY = 'action.sysparamCopy.execute';

export const readPermissions = [
    PERMISSION_PACKAGE_READ,
    PERMISSION_PACKAGE_DELIVERABLE_READ,
    PERMISSION_FILE_READ,
    PERMISSION_FILE_DELIVERABLE_READ,
    PERMISSION_CONTROL_DEVICE_READ,
    PERMISSION_SYS_PARAM_DEVICE_VALUES_READ,
    PERMISSION_DEVICE_ACTION_READ,
    PERMISSION_OTA_PARAMETER_DEFAULT_READ,
    PERMISSION_OTA_PARAMETER_SPECIFIC_READ,
    PERMISSION_SYS_PARAM_DEFAULT_VALUES_READ,
    PERMISSION_SYS_PARAM_VALUES_READ,
    PERMISSION_SYS_PARAM_DEFINITIONS_READ,
    PERMISSION_SOFTWARE_INFO_READ,
    PERMISSION_SOFTWARE_INFO_CHANGE_READ,
];

// New Roles
export const ROLE_FILE_TRANSFER_MANAGER = `rio.ota.filetransfermanager`;
export const ROLE_UPDATE_MANAGER = `rio.ota.updatemanager`;
export const ROLE_APP_UPDATE_MANAGER = `rio.ota.appupdatemanager`;
export const ROLE_TEST_MANAGER = `rio.ota.testmanager`;
export const ROLE_CAMPAIGN_MANAGER = `rio.ota.campaignmanager`;
export const ROLE_BASIC_USER = `rio.ota.user`;
export const ROLE_DIAG_LOG_FILE_MANAGER = `rio.devicelogs.user`;
export const ROLE_DEVICE_LOGS_USER = `rio.devicelogs.user`;
export const ROLE_DEVICE_MONITOR_USER = 'rio.devicemonitor.user';
