import getOr from 'lodash/fp/getOr';
import toUpper from 'lodash/fp/toUpper';
import padCharsStart from 'lodash/fp/padCharsStart';

import React from 'react';

import { sysParamTypes } from '~/features/sysParams/constants/sysParamTypes';

import SysParamGroup from '~/features/sysParams/components/SysParamGroup';
import CutOffStringLabel from '~/features/base/components/CutOffStringLabel';
import { truncate } from '~/features/apps/utils/appsUtils';

export const distroSysParamColumnDescriptors = [{
    id: '#',
    field: 'accessKey',
    formatLabel: () => '#',
}, {
    id: 'accessKey',
    field: 'accessKey',
    label: 'intl-msg:accessKey',
    format: field => `0x${padCharsStart('0', 4, toUpper(field.toString(16)))}`,
}, {
    id: 'name',
    field: 'name',
    label: 'intl-msg:name',
    format: field => <span title={field}>{field}</span>,
}, {
    id: 'group',
    field: 'accessKey',
    label: 'intl-msg:group',
    format: field => <SysParamGroup accessKey={field}/>,
}, {
    id: 'type',
    field: 'type',
    label: 'intl-msg:type',
    format: field => sysParamTypes[field].label,
}, {
    id: 'default',
    field: 'defaultValue',
    label: 'intl-msg:default',
    format: field => {
        const defaultValueToPrint = getOr('-', 'value', field);
        return <span title={defaultValueToPrint}>{truncate(defaultValueToPrint, 10)}</span>;
    },
}, {
    id: 'unit',
    field: 'unit',
    label: 'intl-msg:unit',
    className: 'hidden-xs',
}];

export const deviceSysParamColumnDescriptors = [{
    id: '#',
    field: 'accessKey',
    className: 'col-md-1',
    formatLabel: () => '#',
}, {
    id: 'accessKey',
    field: 'accessKey',
    label: 'intl-msg:accessKey',
    className: 'col-md-1',
    format: field => `0x${padCharsStart('0', 4, toUpper(field.toString(16)))}`,
}, {
    id: 'name',
    field: 'name',
    label: 'intl-msg:name',
    format: field => <CutOffStringLabel text={field} length={15}/>,
}, {
    id: 'group',
    field: 'accessKey',
    label: 'intl-msg:group',
    format: field => <SysParamGroup accessKey={field}/>,
}, {
    id: 'type',
    field: 'type',
    label: 'intl-msg:type',
    format: field => sysParamTypes[field].label,
}, {
    id: 'default',
    field: 'defaultValue',
    label: 'intl-msg:default',
    format: field => {
        const defaultValueToPrint = getOr('-', 'value', field);
        return <CutOffStringLabel text={defaultValueToPrint} length={15} placement={'top-left'}/>;
    },
}, {
    id: 'lastOnDevice',
    field: 'deviceValue',
    label: 'intl-msg:lastOnDevice',
    format: field => {
        const deviceValueToPrint = getOr('-', 'value', field);
        return <CutOffStringLabel text={deviceValueToPrint} length={15} placement={'top-left'}/>;
    },
}, {
    id: 'plannedValue',
    field: 'systemValue',
    label: 'intl-msg:plannedValue',
    format: (field, { defaultValue }) => {
        let systemValueToPrint;
        const hasSystemValue = !!field;
        const hasDefaultValue = !!defaultValue;
        if (hasSystemValue) {
            const equalsDefault = hasDefaultValue ? defaultValue.value === field.value : false;
            systemValueToPrint = equalsDefault ? `(${defaultValue.value})` : field.value;
        } else {
            systemValueToPrint = hasDefaultValue ? `(${defaultValue.value})` : '-';
        }
        return <CutOffStringLabel text={systemValueToPrint} length={15} placement={'top-left'}/>;
    },
}, {
    id: 'unit',
    field: 'unit',
    label: 'intl-msg:unit',
    className: 'hidden-xs',
}];
