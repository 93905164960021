import PropTypes from 'prop-types';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import truncate from 'lodash/fp/truncate';
import size from 'lodash/fp/size';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

export const CutOffStringLabel = ({ text = '', length = 10, placement = 'top' }) => {
    return (
        (size(text) > length) ?
            <OverlayTrigger placement='top' overlay={
                <Tooltip id='tooltip' className={placement}>
                    <FormattedMessage id={text}/>
                </Tooltip>}>
                <span title={text}>{truncate({ length }, text)}</span>
            </OverlayTrigger>
            : <span title={text}>{truncate({ length }, text)}</span>
    );
};

export default CutOffStringLabel;

CutOffStringLabel.propTypes = {
    // props
    text: PropTypes.string.isRequired,
    length: PropTypes.number,
    placement: PropTypes.string,
};
