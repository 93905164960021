import PropTypes from 'prop-types';

import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

const textStyles = 'text-bold text-size-14 white-space-nowrap clickable';

export const Chip = ({ bsStyle = 'default', toolTipMessage = '', className, children, onClick }) => {
    return (
        toolTipMessage ?
            <OverlayTrigger placement='top' overlay={
                <Tooltip id='tooltip' className='top-right'>
                    <FormattedMessage id={toolTipMessage}/>
                </Tooltip>}>
            <span className={classNames(' tag tag-muted tag-small', className)}>
                <span onClick={onClick} className={classNames(`text-color-${bsStyle}`, textStyles)}>
                    {children}
                </span>
            </span>
            </OverlayTrigger>
            : <span onClick={onClick} className={classNames(' clickable tag tag-muted tag-small', className)}>
                    <span className={classNames(`text-color-${bsStyle}`, textStyles)}>
                        {children}
                    </span>
                </span>
    );
};

export default Chip;

Chip.propTypes = {
    // props
    bsStyle: PropTypes.string,
    className: PropTypes.string,
};
