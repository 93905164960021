export const FETCH_FILTERED_GROUPS = 'FETCH_FILTERED_GROUPS';
export const FETCH_GROUP = 'FETCH_GROUP';
export const FETCH_SERIAL_NUMBERS_OF_GROUP = 'FETCH_SERIAL_NUMBERS_OF_GROUP';
export const FETCH_REFERENCES_OF_GROUP = 'FETCH_REFERENCES_OF_GROUP';

export const CREATE_GROUP = 'CREATE_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';

export const ADD_DEVICE_TO_GROUP = 'ADD_DEVICE_TO_GROUP';
export const REMOVE_DEVICE_FROM_GROUP = 'REMOVE_DEVICE_FROM_GROUP';

export const DELETE_GROUP = 'DELETE_GROUP';
export const FETCH_DEVICE_GROUPS = 'FETCH_DEVICE_GROUPS';
export const UPDATE_DEVICE_GROUPS = 'UPDATE_DEVICE_GROUPS';

export const fetchFilteredGroups = () => {
    return {
        type: FETCH_FILTERED_GROUPS,
    };
};

export const fetchGroup = (groupName) => {
    return {
        type: FETCH_GROUP,
        groupName,
    };
};

export const fetchSerialNumbersOfGroup = (groupName) => {
    return {
        type: FETCH_SERIAL_NUMBERS_OF_GROUP,
        groupName,
    };
};

export const fetchReferencesOfGroup = (groupName) => {
    return {
        type: FETCH_REFERENCES_OF_GROUP,
        groupName,
    };
};

export const createGroup = (group) => {
    return {
        type: CREATE_GROUP,
        group,
    };
};

export const updateGroup = (group, originalGroup) => {
    return {
        type: UPDATE_GROUP,
        group,
        originalGroup,
    };
};

export const addDeviceToGroup = (group, serialNumber) => {
    return {
        type: ADD_DEVICE_TO_GROUP,
        group,
        serialNumber,
    };
};

export const removeDeviceFromGroup = (group, serialNumber) => {
    return {
        type: REMOVE_DEVICE_FROM_GROUP,
        group,
        serialNumber,
    };
};

export const deleteGroup = (group) => {
    return {
        type: DELETE_GROUP,
        group,
    };
};

export const fetchDeviceGroups = ({ serialNumber }) => {
    return {
        type: FETCH_DEVICE_GROUPS,
        serialNumber,
    };
};

export const updateDeviceGroups = ({ serialNumber, originalDeviceGroups, deviceGroups }) => {
    return {
        type: UPDATE_DEVICE_GROUPS,
        serialNumber,
        originalDeviceGroups,
        deviceGroups,
    };
};
