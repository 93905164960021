import PropTypes from 'prop-types';
import React from 'react';
import { DELIVERABLES_PATH, FILES_PATH } from '~/features/base/constants/routes';

import Chip from '~/features/base/components/Chip';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import { openInNewTab } from '~/features/base/utils/openInNewTab';

export const FileReleaseChip = ({
    fileId,
    fileVersion,
    showFileId,
    bsStyle,
    className,
    isNotShortVersion,
    toolTipMessage,
}) => {
    const shortFileVersion = isNotShortVersion ? toShortSemanticVersion(fileVersion) : fileVersion;
    const label = showFileId ? `${fileId}@${shortFileVersion}` : `${shortFileVersion}`;
    const handleOnClick = () => openInNewTab(`/#${DELIVERABLES_PATH}/${FILES_PATH}?deliverableIdPrefix=${fileId}&deliverableVersion=${shortFileVersion}`);
    return (
        <Chip onClick={handleOnClick} bsStyle={bsStyle} className={className}
              toolTipMessage={toolTipMessage}>{label}</Chip>
    );
};

export default FileReleaseChip;

FileReleaseChip.defaultProps = {
    // props
    showFileId: false,
};

FileReleaseChip.propTypes = {
    // props
    fileId: PropTypes.string,
    fileVersion: PropTypes.object,
    showFileId: PropTypes.bool,
    bsStyle: PropTypes.string,
    className: PropTypes.string,
};
