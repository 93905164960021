import { connect } from 'react-redux';
import { followRoute } from '~/features/base/actions/ui/routeActions';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';
import DeliverableList from '~/features/deliverables/components/DeliverableList';
import {
    canDeleteDeliverablesSelector,
    canUpdateDeliverablesSelector,
    deliverableIdsItemSelector, deliverableIdsTotalCountSelector,
    deliverablesLoadingSelector,
} from '~/features/deliverables/selectors/deliverableSelectors';
import {
    showDeliverableDeletionDialog,
    showDeliverableEditorDialog,
    showDeliverableVersionEditorDialog,
} from '~/features/deliverables/actions/deliverableActions';
import noop from 'lodash/fp/noop';
import PropTypes from 'prop-types';
import {
    canCreateDistrosSelector,
    canDeleteDistrosSelector,
    canUpdateDistrosSelector,
} from '~/features/deliverables/features/distros/selectors/distroSelectors';
import DeliverableListContainer from '~/features/artifacts/components/DeliverableListContainer';

export const mapStateToProps = (state, ownProps) => {
    return {
        pathname: pathnameSelector(state),
        search: searchSelector(state),
        deliverablesLoading: deliverablesLoadingSelector(state),
        deliverableIdsTotalCount: deliverableIdsTotalCountSelector(state),
        showLoadMore: deliverableIdsItemSelector(state) < deliverableIdsTotalCountSelector(state),
        canCreateDistros: canCreateDistrosSelector(state),
        canUpdateDistros: canUpdateDistrosSelector(state),
        canDeleteDistros: canDeleteDistrosSelector(state),
        canUpdateDeliverablesSelector: canUpdateDeliverablesSelector(state),
        canDeleteDeliverablesSelector: canDeleteDeliverablesSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        onCreateDeliverable: (options) => {
            dispatch(showDeliverableEditorDialog(options));
        },
        onCreateDeliverableVersion: (options) => {
            dispatch(showDeliverableVersionEditorDialog(options));
        },
        onEditDeliverable: (options) => {
            dispatch(showDeliverableEditorDialog(options));
        },
        onDeleteDeliverable: (options) => {
            dispatch(showDeliverableDeletionDialog(options));
        },
        followRoute: (options) => {
            dispatch(followRoute(options));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableList);

DeliverableListContainer.defaultProps = {
    // props
    pathname: '',
    search: '',
    deliverablePermissions: [],
    canUpdateDeliverables: false,
    canDeleteDeliverables: false,
    canCreateDistros: false,
    canUpdateDistros: false,
    canDeleteDistros: false,
    deliverablesLoading: false,
    deliverableIdsTotalCount: 0,
    showLoadMore: false,
    // functions
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    onCreateDeliverable: noop,
    onCreateDeliverableVersion: noop,
    onEditDeliverable: noop,
    onDeleteDeliverable: noop,
    followRoute: noop,
};

DeliverableListContainer.propTypes = {
    // props
    pathname: PropTypes.string,
    search: PropTypes.string,
    deliverablePermissions: PropTypes.array,
    canUpdateDeliverables: PropTypes.bool,
    canDeleteDeliverables: PropTypes.bool,
    canCreateDistros: PropTypes.bool,
    canUpdateDistros: PropTypes.bool,
    canDeleteDistros: PropTypes.bool,
    deliverablesLoading: PropTypes.bool,
    deliverableIdsTotalCount: PropTypes.number,
    showLoadMore: PropTypes.bool,
    // functions
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
    onCreateDeliverable: PropTypes.func,
    onCreateDeliverableVersion: PropTypes.func,
    onEditDeliverable: PropTypes.func,
    onDeleteDeliverable: PropTypes.func,
    followRoute: PropTypes.func,
};
