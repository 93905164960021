export const UPLOAD_ARTIFACT_FILE = 'UPLOAD_ARTIFACT_FILE';
export const FETCH_ARTIFACT_FILES = 'FETCH_ARTIFACT_FILES';
export const DELETE_ARTIFACT_FILE = 'FETCH_ARTIFACT_FILE';
export const DOWNLOAD_ARTIFACT_FILE = 'DOWNLOAD_ARTIFACT_FILE';
export const SHOW_ARTIFACT_FILE_DELETION_DIALOG = 'SHOW_ARTIFACT_FILE_DELETION_DIALOG';

export function showArtifactFileDeletionDialog(payload) {
    return {
        type: SHOW_ARTIFACT_FILE_DELETION_DIALOG,
        payload,
    };
}

export const uploadArtifactFile = (artifactId, file) => {
    return {
        type: UPLOAD_ARTIFACT_FILE,
        payload: {
            artifactId,
            file,
        },
    };
};

export const fetchArtifactFiles = ({ artifactId, page }) => {
    return {
        type: FETCH_ARTIFACT_FILES,
        payload: {
            artifactId,
            page,
        },
    };
};

export const deleteArtifactFile = (artifactId, fileName) => {
    return {
        type: DELETE_ARTIFACT_FILE,
        payload: {
            artifactId,
            fileName,
        },
    };
};

export const downloadArtifactFile = (artifactId, fileName) => {
    return {
        type: DOWNLOAD_ARTIFACT_FILE,
        payload: {
            artifactId,
            fileName,
        },
    };
};
