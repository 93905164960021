import { createSelector } from 'reselect';

export const filterSelector = state => state.ui.filters;
export const showFilterViewSelector = createSelector(
    [filterSelector],
    filters => filters.showFilters,
);

export const filterSettingsSelector = createSelector(
    [filterSelector],
    (filters) => filters.filterSettings,
);

export const filterDeliverableVersionsSelector = createSelector(
    [filterSelector],
    (filters) => filters.filterSettings.deliverableVersions,
);
