import PropTypes from 'prop-types';

export const Group = PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    description: PropTypes.string,
    serialNumbers: PropTypes.arrayOf(PropTypes.string),
});

export default Group;
