import includes from 'lodash/fp/includes';
import { MERGE_ENTITY, RESET_ENTITY } from '~/features/higherorder/actions/entityActions';

const acceptedActions = [MERGE_ENTITY, RESET_ENTITY];

const defaultState = null;

const mergeEntity = (action) => {
    const newState = action.entity;
    return newState;
};

export const entityReducer = (state = defaultState, action = {}) => {
    switch (action.type) {
        case MERGE_ENTITY:
            return mergeEntity(action);
        case RESET_ENTITY:
            return null;
        default:
            return state;
    }
};

export const createEntityReducer = (entityName) => {
    return (state = defaultState, action = {}) => {
        if (includes(action.type, acceptedActions)) {
            if (action.mergeProps.entityName === entityName) {
                return entityReducer(state, action);
            }
        }
        return state;
    };
};

export default createEntityReducer;
