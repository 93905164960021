import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/fp/noop';
import { FormattedMessage } from 'react-intl';
import PageHeader from '~/features/base/components/PageHeader';
import { REPORTS_BILLING_PATH } from '~/features/base/constants/routes';
import ReportsBillingTabs from '~/features/reportsbilling/components/ReportsBillingTabs';
import ReportsBillingChartsContainer from '~/features/reportsbilling/components/ReportsBillingChartsContainer';
import { connect } from 'react-redux';
import { followRoute } from '~/features/base/actions/ui/routeActions';
import { parseQuery } from '~/features/base/utils/query';
import {
    fetchReportsBillingChartsInitialData,
} from '~/features/reportsbilling/actions/reportsBillingActions';
import uid from '~/features/base/utils/uid';
import { getEndDate, getStartDate, BYTE_TO_MB } from '~/features/reportsbilling/utils/processRawChartData';

const defaultRequestParams = {
    startDate: getStartDate(),
    endDate: getEndDate(),
    totalsOnly: true,
    requestTypes: ["TRANSFER_DISTRO", "TRANSFER_APP", "TRANSFER_FILE", "MDM_LOGS", "MDM_C4U", "MDM_SYSPARAMS"],
};

export class ReportsBillingDetails extends PureComponent {
    constructor(props) {
        super(props);
        this.name = uid();
    }

    render() {
        const content = this.renderContent();
        return (
            <div className='file-details row'>
                <PageHeader pageTitle={<FormattedMessage id='Reports & Billing'/>}/>
                {content}
            </div>
        );
    }

    renderContent() {
        const tabs = this.renderTabs();
        const charts = this.renderCharts();
        return [
            <div className='col-md-12'>{tabs}</div>,
            <div>{charts}</div>,
        ];
    }

    renderTabs() {
        return (
            <ReportsBillingTabs basePath={`/${REPORTS_BILLING_PATH}`} view={REPORTS_BILLING_PATH}/>
        );
    }

    renderCharts() {
        return (
            <ReportsBillingChartsContainer requestParams={defaultRequestParams}/>
        );
    }

    componentWillMount() {
        this.props.fetchReportsBillingChartsInitialData(
            defaultRequestParams.startDate,
            defaultRequestParams.endDate,
            defaultRequestParams.totalsOnly,
            defaultRequestParams.requestTypes
        );
    }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
    fetchReportsBillingChartsInitialData: (startDate, endDate, totalsOnly, requestTypes) => {
        dispatch(fetchReportsBillingChartsInitialData(
            {
                searchCriteria: {
                    startDate,
                    endDate,
                    totalsOnly,
                    requestTypes
                },
            },
        ));
    },
    followRoute: (options) => {
        dispatch(followRoute(options));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsBillingDetails);

ReportsBillingDetails.defaultProps = {
    // props
    size: 50,
    page: 0,
    chartsData: noop,
    versionsData: noop,
    // functions
    fetchReportsBillingChartsInitialData: noop,
    followRoute: noop,
};

ReportsBillingDetails.propTypes = {
    // props
    size: PropTypes.number,
    page: PropTypes.number,
    chartsData: PropTypes.array,
    versionsData: PropTypes.array,
    // functions
    fetchReportsBillingChartsInitialData: PropTypes.func,
    followRoute: noop,
};
