import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ENTITY_UPDATE_EVENT_SCOPE_ALL } from '~/features/base/constants/entities';

import { followRoute } from '~/features/base/actions/ui/routeActions';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import { showUpdateEventReportDialog } from '~/features/updateEvents/actions/updateEventReportActions';
import {
    exportFilteredUpdateEvents,
    fetchFilteredUpdateEvents,
} from '~/features/updateEvents/actions/updateEventActions';

import {
    allUpdateEventsLoadingSelector,
    allUpdateEventsPageCountSelector,
    allUpdateEventsPageNumberSelector,
    allUpdateEventsSelector,
    allUpdateEventsTotalElementsSelector,
} from '~/features/updateEvents/selectors/updateEventSelectors';
import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';

import UpdateEventList from '~/features/updateEvents/components/UpdateEventList';

import { queryToSearchCriteria } from '~/features/updateEvents/utils/updateEventsQuery';
import { parseQuery } from '~/features/base/utils/query';
import uid from '~/features/base/utils/uid';

const FETCH_UPDATE_EVENTS_AMOUNT_INCREMENT = 50;

// TODO Merge all UpdateEventsContainers
// TODO Separate between user defined search criteria and implicit search criteria
// TODO Revise code for getting search and implicit search criteria
export class AllUpdateEventsContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.name = uid();

        this.onShowReport = this.onShowReport.bind(this);
        this.onExportUpdateEvents = this.onExportUpdateEvents.bind(this);
        this.onLoadMore = this.onLoadMore.bind(this);
    }

    onShowReport() {
        const { search } = this.props;
        const parsedQuery = parseQuery(search);
        const searchCriteria = this.getSearchCriteria(parsedQuery);
        this.props.showUpdateEventReportDialog({
            searchCriteria,
            scope: ENTITY_UPDATE_EVENT_SCOPE_ALL,
        });
    }

    onExportUpdateEvents(format = 'csv') {
        const { search } = this.props;
        const parsedQuery = parseQuery(search);
        const searchCriteria = this.getSearchCriteria(parsedQuery);
        const options = {
            format,
            searchCriteria,
        };
        this.props.exportUpdateEvents(options);
    }

    onLoadMore() {
        const { pathname, search } = this.props;
        const query = parseQuery(search);
        const queryAmount = Number(query.limit ? query.limit : FETCH_UPDATE_EVENTS_AMOUNT_INCREMENT)
            + FETCH_UPDATE_EVENTS_AMOUNT_INCREMENT;
        this.props.followRoute({
            route: pathname, query: {
                ...query,
                limit: queryAmount,
            },
        });
    }

    getSearchCriteria(parsedQuery) {
        return queryToSearchCriteria(parsedQuery);
    }

    render() {
        return (
            <UpdateEventList {...this.props}
                             onShowReport={this.onShowReport}
                             onExportUpdateEvents={this.onExportUpdateEvents}
                             onLoadMore={this.onLoadMore}/>
        );
    }

    registerDataInterest(parsedQuery) {
        const { page, limit } = parsedQuery;
        const searchCriteria = this.getSearchCriteria(parsedQuery);
        const fetchFilteredUpdateEventsOptions = {
            page: (page && page > 0) ? (page - 1) : 0,
            size: limit ? limit : FETCH_UPDATE_EVENTS_AMOUNT_INCREMENT,
            searchCriteria,
            scope: ENTITY_UPDATE_EVENT_SCOPE_ALL,
        };
        this.props.registerDataInterest(this.name, [
            fetchFilteredUpdateEvents(fetchFilteredUpdateEventsOptions),
        ]);
    }

    componentWillMount() {
        const { search } = this.props;
        const parsedQuery = parseQuery(search);
        this.registerDataInterest(parsedQuery);

        this.props.triggerDataFetcher();
        this.interval = setInterval(() => {
            this.props.triggerDataFetcher();
        }, 60000);
    }

    // FIXME REACT16 Should probably be componentDidUpdate(prevProps, prevState, snapshot)
    componentWillReceiveProps(nextProps) {
        const { search } = nextProps;
        if (search !== this.props.search) {
            const parsedQuery = parseQuery(search);
            this.registerDataInterest(parsedQuery);
            nextProps.triggerDataFetcher();
        }
    }

    componentWillUnmount() {
        this.props.unregisterDataInterest(this.name);
        clearInterval(this.interval);
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        pathname: pathnameSelector(state),
        search: searchSelector(state),
        updateEvents: allUpdateEventsSelector(state, ownProps),
        updateEventsLoading: allUpdateEventsLoadingSelector(state, ownProps),
        pageNumber: allUpdateEventsPageNumberSelector(state),
        pageCount: allUpdateEventsPageCountSelector(state),
        totalElements: allUpdateEventsTotalElementsSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        followRoute: (options) => {
            dispatch(followRoute(options));
        },
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        triggerDataFetcher: () => {
            dispatch(triggerDataFetcher());
        },
        showUpdateEventReportDialog: (options) => {
            dispatch(showUpdateEventReportDialog(options));
        },
        exportUpdateEvents: (options) => {
            dispatch(exportFilteredUpdateEvents(options));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllUpdateEventsContainer);

AllUpdateEventsContainer.defaultProps = {
    // props
    updateEvents: [],
    updateEventsLoading: false,
    pageNumber: 0,
    pageCount: 0,
    totalElements: 0,
    pathname: '',
    search: '',
    // functions
    followRoute: noop,
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    triggerDataFetcher: noop,
    exportUpdateEvents: noop,
};

AllUpdateEventsContainer.propTypes = {
    // props
    updateEvents: PropTypes.array,
    updateEventsLoading: PropTypes.bool,
    pageNumber: PropTypes.number,
    pageCount: PropTypes.number,
    totalElements: PropTypes.number,
    pathname: PropTypes.string,
    search: PropTypes.string,
    // functions
    followRoute: PropTypes.func,
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
    exportUpdateEvents: PropTypes.func,
};
