import { combineReducers } from '@reduxjs/toolkit';
import identity from 'lodash/fp/identity';

import {
    ENTITY_BASE_SW,
    ENTITY_CONTROL_DEVICE,
    ENTITY_CONTROL_DEVICE_OPERATION,
    ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS,
    ENTITY_DELIVERABLE, ENTITY_DELIVERABLE_ID,
    ENTITY_DEVICE_ACTION,
    ENTITY_DISTRO,
    ENTITY_FILE,
    ENTITY_FILE_DELIVERABLE,
    ENTITY_GROUP,
    ENTITY_PACKAGE,
    ENTITY_PACKAGE_DELIVERABLE,
    ENTITY_SERIAL_NUMBER,
    ENTITY_SYS_PARAM_DEFINITION,
    ENTITY_UPDATE_EVENT, ENTITY_VEHICLES,
    IDENTIFIER_BASE_SW,
    IDENTIFIER_CONTROL_DEVICE,
    IDENTIFIER_CONTROL_DEVICE_OPERATION,
    IDENTIFIER_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATION, IDENTIFIER_DELIVERABLE,
    IDENTIFIER_DEVICE_ACTION,
    IDENTIFIER_DISTRO,
    IDENTIFIER_FILE,
    IDENTIFIER_FILE_DELIVERABLE,
    IDENTIFIER_GROUP,
    IDENTIFIER_PACKAGE,
    IDENTIFIER_PACKAGE_DELIVERABLE,
    IDENTIFIER_SYS_PARAM,
    IDENTIFIER_UPDATE_EVENT, IDENTIFIER_VEHICLE,
} from '~/features/base/constants/entities';
// higher order
import {
    createMappedPaginationReducer,
    createPaginationReducer,
} from '~/features/higherorder/reducers/paginationReducer';
import {
    createMappedSortedIdsReducer,
    createSortedIdsReducer,
} from '~/features/higherorder/reducers/sortedIdsReducer';

export const indicesReducer = combineReducers({
    [ENTITY_SERIAL_NUMBER]: createMappedSortedIdsReducer(ENTITY_SERIAL_NUMBER, identity, 'groupName'),
    [ENTITY_CONTROL_DEVICE]: createMappedPaginationReducer(ENTITY_CONTROL_DEVICE, IDENTIFIER_CONTROL_DEVICE, 'scope'),
    [ENTITY_VEHICLES]: createMappedPaginationReducer(ENTITY_VEHICLES, IDENTIFIER_VEHICLE, 'scope'),
    [ENTITY_DELIVERABLE]: createMappedPaginationReducer(ENTITY_DELIVERABLE, IDENTIFIER_DELIVERABLE, 'scope'),
    [ENTITY_DELIVERABLE_ID]: createMappedPaginationReducer(ENTITY_DELIVERABLE_ID, IDENTIFIER_DELIVERABLE, 'scope'),
    [ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS]: createMappedPaginationReducer(
        ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS,
        IDENTIFIER_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATION, 'scope'),
    [ENTITY_CONTROL_DEVICE_OPERATION]: createMappedPaginationReducer(ENTITY_CONTROL_DEVICE_OPERATION,
        IDENTIFIER_CONTROL_DEVICE_OPERATION, 'serialNumber'),
    [ENTITY_GROUP]: createSortedIdsReducer(ENTITY_GROUP, IDENTIFIER_GROUP),
    [ENTITY_DISTRO]: createPaginationReducer(ENTITY_DISTRO, IDENTIFIER_DISTRO),
    [ENTITY_BASE_SW]: createMappedSortedIdsReducer(ENTITY_BASE_SW, IDENTIFIER_BASE_SW, 'scope'),
    [ENTITY_SYS_PARAM_DEFINITION]: createMappedSortedIdsReducer(ENTITY_SYS_PARAM_DEFINITION,
        IDENTIFIER_SYS_PARAM, IDENTIFIER_BASE_SW),
    [ENTITY_PACKAGE]: createSortedIdsReducer(ENTITY_PACKAGE, IDENTIFIER_PACKAGE),
    [ENTITY_PACKAGE_DELIVERABLE]: createPaginationReducer(ENTITY_PACKAGE_DELIVERABLE,
        IDENTIFIER_PACKAGE_DELIVERABLE),
    [ENTITY_FILE]: createSortedIdsReducer(ENTITY_FILE, IDENTIFIER_FILE),
    [ENTITY_FILE_DELIVERABLE]: createPaginationReducer(ENTITY_FILE_DELIVERABLE,
        IDENTIFIER_FILE_DELIVERABLE),
    [ENTITY_DEVICE_ACTION]: createSortedIdsReducer(ENTITY_DEVICE_ACTION, IDENTIFIER_DEVICE_ACTION),
    [ENTITY_UPDATE_EVENT]: createMappedPaginationReducer(ENTITY_UPDATE_EVENT, IDENTIFIER_UPDATE_EVENT, 'scope'),
});
export default indicesReducer;
