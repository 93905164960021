import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import PackageRelease from '~/prop-types/packageReleasePropType';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { DEVICES_PATH } from '~/features/base/constants/routes';
import {
    ENTITY_CONTROL_DEVICE,
    ENTITY_CONTROL_DEVICE_SCOPE_PACKAGE_DELIVERABLE_WHITELIST,
} from '~/features/base/constants/entities';

import { followRoute } from '~/features/base/actions/ui/routeActions';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import { fetchDeliverableWhitelisting } from '~/features/devices/actions/controlDeviceActions';
import { exportDevices } from '~/features/devices/actions/exportActions';
import { clearPage } from '~/features/higherorder/actions/paginationActions';

import {
    canReadControlDevicesSelector,
    packageDeliverableWhitelistLoadingSelector,
} from '~/features/devices/selectors/controlDeviceSelectors';
import {
    whitelistPageCountSelector,
    whitelistPageItemsSelector,
    whitelistPageNumberSelector,
    whitelistTotalElementsSelector,
} from '~/features/deliverables/features/packages/selectors/packageDeliverableWhitelistSelectors';
import {
    packageDeliverableSelector,
} from '~/features/deliverables/features/packages/selectors/packageDeliverableSelectors';
import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';

import { parseQuery } from '~/features/base/utils/query';
import uid from '~/features/base/utils/uid';
import DeliverableWhitelist from '~/features/deliverables/components/details/DeliverableWhitelist';
import { groupsSelector } from '~/features/groups/selectors/groupSelectors';

const FETCH_DEVICES_AMOUNT_INCREMENT = 50;

/**
 * Container for the Deliverable whitelist
 */
class DeliverableWhitelistContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.name = uid();
        const parsed = parseQuery(this.props.search);
        this.registerDataInterest(parsed);
        this.props.clearPage();
        this.props.triggerDataFetcher();
    }

    onReload = () => {
        this.props.triggerDataFetcher();
    };

    onShowItem = ({ serialNumber }) => {
        const route = `/${DEVICES_PATH}/${serialNumber}`;
        this.props.followRoute({ route });
    };

    onShowPage = ({ page }) => {
        const { pathname, search } = this.props;
        const query = parseQuery(search);
        this.props.followRoute({
            route: pathname, query: {
                ...query,
                page,
            },
        });
    };

    onExportDevices = (format = 'csv') => {
        // get criteria from props
        const { packageDeliverable } = this.props;
        const options = {
            format,
            page: 0,
            size: 1000000,
            searchCriteria: {
                serialNumbers: packageDeliverable.whitelisting.deviceWhitelist,
            },
        };
        this.props.exportDevices(options);
    };

    onLoadMore = () => {
        const { pathname, search } = this.props;
        const query = parseQuery(search);
        const queryAmount = Number(query.limit ? query.limit : FETCH_DEVICES_AMOUNT_INCREMENT) +
            FETCH_DEVICES_AMOUNT_INCREMENT;
        this.props.followRoute({
            route: pathname, query: {
                ...query,
                limit: queryAmount,
            },
        });
    };

    render() {
        return (
            <div className='overflow-y-scroll max-height-75vh padding-bottom-50'>
                <DeliverableWhitelist {...this.props}
                                      onReload={this.onReload}
                                      onShowPage={this.onShowPage}
                                      onShowItem={this.onShowItem}
                                      onExportDevices={this.onExportDevices}
                                      onLoadMore={this.onLoadMore}/>
            </div>
        );
    }

    registerDataInterest(parsedQuery) {
        const { selectedDeliverableDetails } = this.props;
        const { page, limit } = parsedQuery;
        this.props.registerDataInterest(this.name, [
            fetchDeliverableWhitelisting({
                whitelisting: selectedDeliverableDetails.whitelisting,
                page: (page && page > 0) ? (page - 1) : 0,
                size: limit ? limit : FETCH_DEVICES_AMOUNT_INCREMENT,
            }),
        ]);
    }

    componentWillUnmount() {
        this.props.unregisterDataInterest(this.name);
        clearInterval(this.interval);
    }

    componentWillReceiveProps(nextProps) {
        const { search } = nextProps;
        if (search !== this.props.search) {
            const parsed = parseQuery(search);
            this.registerDataInterest(parsed);
            nextProps.triggerDataFetcher();
        }
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        pathname: pathnameSelector(state),
        search: searchSelector(state),
        canReadControlDevices: canReadControlDevicesSelector(state),
        packageDeliverable: packageDeliverableSelector(state, ownProps),
        pageNumber: whitelistPageNumberSelector(state),
        pageCount: whitelistPageCountSelector(state),
        totalElements: whitelistTotalElementsSelector(state),
        packageDeliverableWhitelist: whitelistPageItemsSelector(state),
        devicesLoading: packageDeliverableWhitelistLoadingSelector(state),
        groups: groupsSelector(state),

    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        followRoute: (options) => {
            dispatch(followRoute(options));
        },
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        triggerDataFetcher: () => {
            dispatch(triggerDataFetcher());
        },
        exportDevices: (options) => {
            dispatch(exportDevices(options));
        },
        clearPage: () => {
            dispatch(clearPage({
                entityName: ENTITY_CONTROL_DEVICE,
                scope: ENTITY_CONTROL_DEVICE_SCOPE_PACKAGE_DELIVERABLE_WHITELIST,
            }));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableWhitelistContainer);

DeliverableWhitelistContainer.defaultProps = {
    // props
    selectedDeliverableDetails: {},
    pathname: '',
    search: '',
    canReadControlDevices: false,
    packageId: '',
    shortPackageVersion: '',
    packageDeliverable: {},
    pageNumber: 0,
    pageCount: 0,
    totalElements: 0,
    packageDeliverableWhitelist: [],
    devicesLoading: false,
    // functions
    followRoute: noop,
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    triggerDataFetcher: noop,
    exportDevices: noop,
    clearPage: noop,
};

DeliverableWhitelistContainer.propTypes = {
    // props
    selectedDeliverableDetails: PropTypes.object,
    pathname: PropTypes.string,
    search: PropTypes.string,
    canReadControlDevices: PropTypes.bool,
    packageId: PropTypes.string,
    shortPackageVersion: PropTypes.string,
    packageDeliverable: PropTypes.instanceOf(PackageRelease),
    pageNumber: PropTypes.number,
    pageCount: PropTypes.number,
    totalCount: PropTypes.number,
    packageDeliverableWhitelist: PropTypes.array,
    devicesLoading: PropTypes.bool,
    // functions
    followRoute: PropTypes.func,
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
    exportDevices: PropTypes.func,
    clearPage: PropTypes.func,
};
