import React, { Component } from 'react';
import { connect } from 'react-redux';
import map from 'lodash/fp/map';
import onClickOutside from 'react-onclickoutside';
import RenderingError from '~/features/base/components/RenderingError';
import noop from 'lodash/fp/noop';
import PropTypes from 'prop-types';
import Sidebar from '@rio-cloud/rio-uikit/lib/es/Sidebar';
import { showVehicleDetailsSidebarSelector } from '~/features/oldVehicles/selectors/vehicleSelectors';
import {
    hideVehicleDetailsSidebar,
    toggleVehicleDetailsSidebar,
} from '~/features/oldVehicles/actions/ui/vehicleDetailsActions';

export class VehicleDetailsSidebarContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: undefined,
        };
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error,
        };
    }

    handleClickOutside = evt => {
        const selectedRow = evt.target.closest('.tableRow');
        const selectedDialog = evt.target.closest('.device-editor-dialog');
        const isRowClick = selectedRow !== null;
        const isDialogClick = selectedDialog !== null;
        if (!isRowClick && !isDialogClick) {
            this.props.hideVehicleDetailsSidebar();
            const selectedRows = document.getElementsByClassName('tableRow active');
            map(element => element.classList.remove('active'), selectedRows);
        }
    };

    onDisableFilterView = () => {
        this.props.toggleVehicleDetailsSidebar();
    };

    render() {
        const { showVehicleDetailsSidebar, children } = this.props;
        const content = this.state.hasError ? (
            <RenderingError error={this.state.error}/>
        ) : children;

        if (content) {
            return (
                <Sidebar
                    fly
                    width={800}
                    closed={!showVehicleDetailsSidebar}
                    onClose={this.onDisableFilterView}>
                    {content}
                </Sidebar>
            );
        }
    }
}

export const mapStateToProps = (state) => {
    return {
        showVehicleDetailsSidebar: showVehicleDetailsSidebarSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        toggleVehicleDetailsSidebar: () => {
            dispatch(toggleVehicleDetailsSidebar());
        },
        hideVehicleDetailsSidebar: () => {
            dispatch(hideVehicleDetailsSidebar());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDetailsSidebarContainer);

VehicleDetailsSidebarContainer.defaultProps = {
    // props
    vin: '',
    // functions
    toggleVehicleDetailsSidebar: noop,
    hideVehicleDetailsSidebar: noop,
};

VehicleDetailsSidebarContainer.propTypes = {
    // props
    vin: PropTypes.string,
    // functions
    toggleVehicleDetailsSidebar: PropTypes.func,
    hideVehicleDetailsSidebar: PropTypes.func,
};
