import getOr from 'lodash/fp/getOr';
import flow from 'lodash/fp/flow';
import toPairs from 'lodash/fp/toPairs';
import map from 'lodash/fp/map';

import {
    toShortDistroVersion,
    toShortSemanticVersion,
} from '~/features/base/utils/versionNumberConverter';

import { colors, colorsChart } from '~/features/base/constants/chartColors';

export const parseWhitelistingInfo = (whitelistingInfo) => {
    return {
        ...whitelistingInfo,
        whitelistedForAll: getOr(false, 'whitelistedForAll', whitelistingInfo),
        vehicleGroupWhitelist: getOr([], 'vehicleGroupWhitelist', whitelistingInfo),
        deviceWhitelist: getOr([], 'deviceWhitelist', whitelistingInfo),
        vehicleGroupBlacklist: getOr([], 'vehicleGroupBlacklist', whitelistingInfo),
        deviceBlacklist: getOr([], 'deviceBlacklist', whitelistingInfo),
    };
};

export const parseDistro = distro => {
    const { deliverableVersion, distroInfo: { baseSoftwareVersion } } = distro;
    const shortDistroVersion = toShortSemanticVersion(deliverableVersion);
    const shortBaseSwVersion = toShortSemanticVersion(baseSoftwareVersion);
    const result = {
        ...distro,
        shortDistroVersion,
        shortBaseSwVersion,
        softwareDate: distro.softwareTimestamp ? new Date(distro.softwareTimestamp) : undefined,
        releaseStartDate: distro.releaseStart ? new Date(distro.releaseStart) : undefined,
        whitelistingInfo: parseWhitelistingInfo(distro.whitelistingInfo),
        mandatoryWhitelistingInfo: parseWhitelistingInfo(distro.mandatoryWhitelistingInfo),
    };
    return result;
};

export const parseDistros = distros => map(parseDistro, distros);

export const generatePerReleaseState = counts => {
    return flow(
        toPairs,
        toPairs,
        map(([index, [key, value]]) => {
            return {
                label: key,
                value: value,
                filters: { releaseState: key },
                color: colors[index],
                colorChart: colorsChart[index],
            };
        }),
    )(counts);
};
