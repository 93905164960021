import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { updatesActiveOptions, ALL } from '~/features/base/constants/filterOptions';
import { selectionByValue } from '~/features/base/utils/selectUtils';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';

export class BlockedFormItem extends Component {

    onChange = (item) => {
        const updatesActive = item.id === ALL ? undefined : item.value;
        this.props.onChange({
            updatesActive,
        });
    }

    render() {
        const { label, value } = this.props;
        const optionsWithSelection = selectionByValue(value)(updatesActiveOptions);
        return (
            <div className='form-group'>
                <label className='control-label'>
                    <FormattedMessage id={label} />
                </label>
                <Select options={optionsWithSelection}
                    onChange={this.onChange} />
            </div>
        );
    }
}

export default BlockedFormItem;

BlockedFormItem.defaultProps = {
    // props
    label: 'intl-msg:blocked',
    value: ALL,
    // functions
    onChange: noop,
};

BlockedFormItem.propTypes = {
    // props
    label: PropTypes.string,
    value: PropTypes.string,
    // functions
    onChange: PropTypes.func,
};
