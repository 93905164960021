import React from 'react';
import { getVehicleIcon } from '~/features/base/utils/getVehicleIcon';

interface VehicleVariantProps {
    vehicleVariant: string;
}

const VehicleVariant: React.FC<VehicleVariantProps> = ({ vehicleVariant }) => {

    return (
        <>
            <span className={`text-size-h3 ${getVehicleIcon(vehicleVariant)}`}/>
            <span className={'padding-left-5'}>
                {vehicleVariant}
            </span>
        </>

    );
};

export default VehicleVariant;
