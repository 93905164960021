import values from 'lodash/fp/values';
import { FormattedMessage } from 'react-intl';

export const DRAFT = 'DRAFT';
export const TEST = 'TEST';
export const LIVE = 'LIVE';

export const releaseStates = {
    [DRAFT]: {
        id: DRAFT,
        value: DRAFT,
        label: <FormattedMessage id='intl-msg:releaseState.draft'/>,
        tooltip: 'intl-msg:releaseState.draft.tooltip',
        className: 'label-info',
    },
    [TEST]: {
        id: TEST,
        value: TEST,
        label: <FormattedMessage id='intl-msg:releaseState.test'/>,
        tooltip: 'intl-msg:releaseState.test.tooltip',
        className: 'label-warning',
    },
    [LIVE]: {
        id: LIVE,
        value: LIVE,
        label: <FormattedMessage id='intl-msg:releaseState.live'/>,
        tooltip: 'intl-msg:releaseState.live.tooltip',
        className: 'label-success',
    },
};

export const createReleaseStateOptions = () => values(releaseStates);
