import {
    ARTIFACTS_PATH,
    EVENTS_PATH,
    OVERVIEW_PATH,
    PACKAGES_PATH,
} from '~/features/base/constants/routes';

import React, { PureComponent } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import SidebarContainer from '~/features/base/components/SidebarContainer';
import UpdateEventsFiltersContainer from '~/features/updateEvents/components/UpdateEventFiltersContainer';
import DeliverableFiltersContainer from '~/features/artifacts/components/DeliverableFiltersContainer';

export const SidebarContainerRoute = () => {
    return (
        <SidebarContainer/>
    );
};
export const DeliverableFiltersContainerRoute = () => {
    return (
        <SidebarContainer>
            <DeliverableFiltersContainer/>
        </SidebarContainer>
    );
};
export const UpdateEventsFiltersRoute = () => {
    return (
        <SidebarContainer>
            <UpdateEventsFiltersContainer/>
        </SidebarContainer>
    );
};

export const PackageUpdateEventsRoute = ({ match }) => {
    const packageId = match.params.packageId;
    return (
        <SidebarContainer>
            <UpdateEventsFiltersContainer deliverableId={packageId}
                                          deliverableIdInputVisible={false}/>
        </SidebarContainer>
    );
};
export const PackageDeliverableUpdateEventsRoute = ({ match }) => {
    const packageId = match.params.packageId;
    const packageVersion = match.params.packageVersion;
    return (
        <SidebarContainer>
            <UpdateEventsFiltersContainer deliverableId={packageId}
                                          targetDeliverableVersion={packageVersion}
                                          deliverableIdInputVisible={false}
                                          targetDeliverableVersionInputVisible={false}/>
        </SidebarContainer>
    );
};

export class SidebarRoutes extends PureComponent {
    render() {
        const { match } = this.props;
        return (
            <div className='display-flex height-100pct'>
                <Switch>
                    <Route path={`${match.path}${OVERVIEW_PATH}/${EVENTS_PATH}`}
                           component={UpdateEventsFiltersRoute}/>
                    <Route path={`${match.path}${PACKAGES_PATH}/:packageId/${EVENTS_PATH}`}
                           component={PackageUpdateEventsRoute}/>
                    <Route
                        path={`${match.path}${PACKAGES_PATH}/${ARTIFACTS_PATH}/:packageId/:packageVersion/${EVENTS_PATH}`}
                        component={PackageDeliverableUpdateEventsRoute}/>
                    <Route path={`${match.path}${ARTIFACTS_PATH}`} component={DeliverableFiltersContainerRoute}/>
                    <Route path={match.path} component={SidebarContainerRoute}/>
                </Switch>
            </div>
        );
    }
}

export default withRouter(SidebarRoutes);
