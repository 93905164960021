import map from 'lodash/fp/map';

import {
    toShortSemanticVersion,
} from '~/features/base/utils/versionNumberConverter';

export const parseSysParamDeviceValue = sysParamDeviceValue => {
    const shortBaseSwVersion = toShortSemanticVersion(sysParamDeviceValue.baseSoftwareVersion);
    return {
        ...sysParamDeviceValue,
        shortBaseSwVersion,
    };
};

export const parseSysParamDeviceValues = sysParamDeviceValues => map(parseSysParamDeviceValue, sysParamDeviceValues);
