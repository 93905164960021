import React from 'react';
import ShortBaseSwVersion from '~/features/baseSwVersions/components/ShortBaseSwVersion';
import FieldOrNoData from '~/features/base/components/FieldOrNoData';
import { useFetchEligibleBundleQuery } from '~/api/controlDevice/ControlDevice.api';

interface LastReportedBundleProps {
    currentVersion: object,
    deliverableId: string,
    serialNumber: string,

}

const LastReportedBundle: React.FC<LastReportedBundleProps> = ({
    currentVersion,
    deliverableId,
    serialNumber
}: LastReportedBundleProps) => {

    const { data } = useFetchEligibleBundleQuery({ serialNumber }, { refetchOnMountOrArgChange: true });
    const firstEligibleDeliverableVersion = data && Object.keys(data)[0];

    if (currentVersion || firstEligibleDeliverableVersion) {
        return (
            <>
                {currentVersion ? <div>{deliverableId}@<ShortBaseSwVersion baseSwVersion={currentVersion}/></div> :
                    <span>...</span>}
                {data && firstEligibleDeliverableVersion &&
                    <>
                        <span className='margin-right-5'>→</span>
                        {firstEligibleDeliverableVersion}@ <ShortBaseSwVersion
                        baseSwVersion={data[firstEligibleDeliverableVersion]?.semanticVersion}/>
                    </>}
            </>);

    } else {
        return <FieldOrNoData/>;
    }

};

export default LastReportedBundle;


