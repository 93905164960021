import map from 'lodash/fp/map';

import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';

export const parseSysParamDefaultValue = sysParamDefaultValue => {
    const shortBaseSwVersion = toShortSemanticVersion(sysParamDefaultValue.baseSoftwareVersion);
    return {
        ...sysParamDefaultValue,
        shortBaseSwVersion,
    };
};

export const parseSysParamDefaultValues = sysParamDefaultValues =>
    map(parseSysParamDefaultValue, sysParamDefaultValues);
