import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { updateEventColumnDescriptors } from '~/features/updateEvents/constants/updateEventColumnDescriptors';

import ListTable from '~/features/base/components/table/ListTable';
import noop from 'lodash/fp/noop';
import { setShowDeviceDetailsSidebar } from '~/features/devices/actions/ui/controlDeviceDetailsActions';
import { connect } from 'react-redux';
import { CM4 } from '~/features/devices/constants/deviceTypes';

export class UpdateEventsTable extends PureComponent {

    onRowClick = (rowId, isSelected) => {
        const { showSerialNumber } = this.props;
        if (showSerialNumber) {
            const rowIds = rowId.split('_');
            const deviceId = rowIds[0] === CM4.toLowerCase() ? rowIds[0] + '_' + rowIds[1] : rowIds[0];
            this.props.setShowDeviceDetailsSidebar(deviceId);
        }
    };

    render() {
        const { showDeliverableId, showSerialNumber, updateEvents } = this.props;

        let filterUpdateEventColumnDescriptors;
        if (!showDeliverableId) {
            filterUpdateEventColumnDescriptors = updateEventColumnDescriptors.filter(
                descriptor => descriptor.id !== 'deliverableId');
        } else {
            filterUpdateEventColumnDescriptors = showSerialNumber ? updateEventColumnDescriptors.filter(
                    descriptor => descriptor.id !== 'fileId')
                : updateEventColumnDescriptors.filter(
                    descriptor => (descriptor.id !== 'fileId' || descriptor.id !== 'deliverableId'));
        }
        return [
            <ListTable items={updateEvents}
                       key={'listTable'}
                       itemKey='id'
                       onRowClick={this.onRowClick}
                       onSelectItem={this.props.onSelectItem}
                       columnDescriptors={filterUpdateEventColumnDescriptors}/>,
        ];
    }
}

export const mapStateToProps = (state) => {
    return {};
};

export const mapDispatchToProps = (dispatch) => {
    return {
        setShowDeviceDetailsSidebar: (rowId) => {
            dispatch(setShowDeviceDetailsSidebar(rowId));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEventsTable);

UpdateEventsTable.defaultProps = {
    // props
    showSerialNumber: true,
    showDeliverableId: true,
    updateEvents: [],
    setShowDeviceDetailsSidebar: noop,
};

UpdateEventsTable.propTypes = {
    // props
    showSerialNumber: PropTypes.bool,
    showDeliverableId: PropTypes.bool,
    updateEvents: PropTypes.array,
    setShowDeviceDetailsSidebar: PropTypes.func,
};
