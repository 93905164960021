import map from 'lodash/fp/map';

import {
    STRING,
    NUMERIC,
    ENUMERATION,
    BYTE_ARRAY,
} from '~/features/sysParams/constants/sysParamTypes';

import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';

const sysParamDefinitionToType = (definition) => {
    if (definition.numericParameterDefinition) {
        return NUMERIC;
    } else if (definition.enumParameterDefinition) {
        return ENUMERATION;
    } else if (definition.byteArrayParameterDefinition) {
        return BYTE_ARRAY;
    }
    return STRING;
};

export const parseSysParamDefinition = sysParamDefinition => {
    const shortBaseSwVersion = toShortSemanticVersion(sysParamDefinition.baseSoftwareVersion);
    return {
        ...sysParamDefinition,
        type: sysParamDefinitionToType(sysParamDefinition),
        shortBaseSwVersion,
    };
};

export const parseSysParamDefinitions = sysParamDefinitions => map(parseSysParamDefinition, sysParamDefinitions);
