import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// Routes

// Vehicles
import { sendGoogleAnalyticsEvent } from '~/index';
import VehiclesListContainer from './VehiclesListContainer';
import { useDataInterestCleaner } from '~/features/base/hooks/useDataInterestCleaner';

export const OldVehiclesRoute = ({ match }) => {
    useDataInterestCleaner();
    // send Google Analytics Event
    sendGoogleAnalyticsEvent('Vehicles Page', 'View Vehicles');
    return (
        <Switch>
            <Route path={`${match.path}`} component={VehiclesListContainer}/>
            <Redirect to={`${match.url}`}/>
        </Switch>
    );
};

export default OldVehiclesRoute;
