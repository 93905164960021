import map from 'lodash/fp/map';

import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';

export const parseVehicleInfo = vehicleInfo => {
    return {
        ...vehicleInfo,
        lastPackageVersionsReported: map(lastAppVersionReported => ({
            ...lastAppVersionReported,
            shortPackageVersion: toShortSemanticVersion(lastAppVersionReported.packageVersion),
        }), vehicleInfo.lastPackageVersionsReported),
    };
};
