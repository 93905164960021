import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * System parameter definition string editor form.
 */
export class SysParamDefinitionStringValueForm extends PureComponent {
    constructor(props) {
        super(props);
        this.onValueChanged = this.onValueChanged.bind(this);
    }
    onValueChanged(event) {
        this.props.onValueChanged(event.target.value);
    }
    render() {
        const { label, placeholder, value, maxLength } = this.props;
        return (
            <div className='form-group form-group-string-default-value'>
                <label className='control-label'>
                    <FormattedMessage id={label} />
                </label>
                <FormattedMessage id={placeholder}>
                    {placeHolder =>
                        <input type='text' className='form-control'
                            value={value}
                            maxLength={maxLength}
                            placeholder={placeHolder}
                            onChange={this.onValueChanged} />
                    }
                </FormattedMessage>
            </div>
        );
    }
}

export default SysParamDefinitionStringValueForm;

SysParamDefinitionStringValueForm.defaultProps = {
    // props
    label: 'intl-msg:defaultValue',
    placeholder: 'intl-msg:defaultValue.prompt',
    value: '',
    maxLength: 128,
    // functions
    onValueChanged: noop,
};

SysParamDefinitionStringValueForm.propTypes = {
    // props
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    maxLength: PropTypes.number,
    // functions
    onValueChanged: PropTypes.func,
};
