import { call, put, select, fork, takeLatest, takeEvery } from 'redux-saga/effects';

import { ENTITY_DEVICE_ACTION } from '~/features/base/constants/entities';

import {
    FETCH_DEVICE_ACTION_COUNT,
    FETCH_DEVICE_ACTIONS,
    FETCH_DEVICE_ACTION,
    CREATE_DEVICE_ACTION,
    UPDATE_DEVICE_ACTION,
    DELETE_DEVICE_ACTION,
} from '~/features/deviceLogs/actions/deviceActionActions';
import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';
import { hideModal } from '~/features/base/actions/ui/modalsActions';
import { mergeAggregation } from '~/features/higherorder/actions/aggregationActions';
import { mergeEntities, mergeEntity, deleteEntity } from '~/features/higherorder/actions/entityActions';
import { startLoading, endLoading } from '~/features/higherorder/actions/loadingActions';

import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';

import { doHandleErrorSaga, getHTTP, postHTTP, putHTTP, deleteHTTP } from '~/features/base/sagas/sagaUtil';

import { parseDeviceActions, parseDeviceAction } from '~/features/deviceLogs/transforms/parseDeviceActions';
import { finishedAction, failedAction } from '~/features/higherorder/transforms/actionTransforms';
import { LOG_REQUESTS_PATH, OVERVIEW_PATH } from '~/features/base/constants/routes';
import { followRoute } from '~/features/base/actions/ui/routeActions';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { FormattedMessage } from 'react-intl';

export function* getDeviceActionsURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/device-action`;
}

export function* doFetchDeviceActionCountSaga(action) {
    try {
        const deviceActionsURL = yield call(getDeviceActionsURL);
        const response = yield call(getHTTP, `${deviceActionsURL}/count`);
        yield put(mergeAggregation({
            entityName: ENTITY_DEVICE_ACTION,
            scope: 'totalCount',
        }, response.count));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchDeviceActionsSaga(action) {
    yield put(startLoading(ENTITY_DEVICE_ACTION));
    try {
        const deviceActionsURL = yield call(getDeviceActionsURL);
        const response = yield call(getHTTP, `${deviceActionsURL}`);
        const entities = parseDeviceActions(response.content);
        yield put(mergeEntities(entities, { entityName: ENTITY_DEVICE_ACTION }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_DEVICE_ACTION));
    yield put(finishedAction(action.type));
}

export function* doFetchDeviceActionSaga(action) {
    try {
        const deviceActionsURL = yield call(getDeviceActionsURL);
        let response;
        try {
            response = yield call(getHTTP, `${deviceActionsURL}/${action.serialNumber}`);
        } catch (err) {
            //TODO In case of no result, the endpoint return an 404 error object
            if (err.status === 404) {
                yield put(deleteEntity({ hwSerial: action.serialNumber }, { entityName: ENTITY_DEVICE_ACTION }));
                yield put(finishedAction(action.type));
                return;
            } else {
                throw err;
            }
        }
        if (response && response.hwSerial) {
            const entity = parseDeviceAction(response);
            yield put(mergeEntity(entity, { entityName: ENTITY_DEVICE_ACTION }));
        } else {
            yield put(deleteEntity({ hwSerial: action.serialNumber }, { entityName: ENTITY_DEVICE_ACTION }));
        }
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        if (!action.silent) {
            yield put(showErrorMessage(action.type, error));
        }
    }
    yield put(finishedAction(action.type));
}

export function* doCreateDeviceActionSaga(action) {
    try {
        const deviceActionsServiceURL = yield call(getDeviceActionsURL);
        yield call(postHTTP, `${deviceActionsServiceURL}`, JSON.stringify(action.payload));
        yield put(hideModal());
        yield put(triggerDataFetcher());
        Notification.success(<FormattedMessage id='intl-msg:logs:logRequestWithSuccess'/>);
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doUpdateDeviceActionSaga(action) {
    try {
        const deviceActionsServiceURL = yield call(getDeviceActionsURL);
        const response = yield call(putHTTP, `${deviceActionsServiceURL}`, JSON.stringify(action.payload));
        const parsedResponse = parseDeviceAction(response);
        yield put(mergeEntity(parsedResponse, { entityName: ENTITY_DEVICE_ACTION }));
        yield put(hideModal());
        yield put(triggerDataFetcher());
        Notification.success('Log request edited');
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doDeleteDeviceActionSaga(action) {
    try {
        const deviceActionsServiceURL = yield call(getDeviceActionsURL);
        const encodedSerialNumber = encodeURIComponent(action.payload);
        const response = yield call(deleteHTTP, `${deviceActionsServiceURL}/${encodedSerialNumber}`);
        const parsedResponse = parseDeviceAction(response);
        yield put(deleteEntity(parsedResponse, { entityName: ENTITY_DEVICE_ACTION }));
        yield put(hideModal());
        yield put(followRoute({ route: `/${OVERVIEW_PATH}/${LOG_REQUESTS_PATH}` }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* fetchDeviceActionCountSaga() {
    yield takeLatest(FETCH_DEVICE_ACTION_COUNT, doFetchDeviceActionCountSaga);
}

export function* fetchDeviceActionsSaga() {
    yield takeLatest(FETCH_DEVICE_ACTIONS, doFetchDeviceActionsSaga);
}

export function* fetchDeviceActionSaga() {
    yield takeLatest(FETCH_DEVICE_ACTION, doFetchDeviceActionSaga);
}

export function* createDeviceActionSaga() {
    yield takeEvery(CREATE_DEVICE_ACTION, doCreateDeviceActionSaga);
}

export function* updateDeviceActionSaga() {
    yield takeEvery(UPDATE_DEVICE_ACTION, doUpdateDeviceActionSaga);
}

export function* deleteDeviceActionSaga() {
    yield takeEvery(DELETE_DEVICE_ACTION, doDeleteDeviceActionSaga);
}
