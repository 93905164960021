import getOr from 'lodash/fp/getOr';

import { createSelector } from 'reselect';

import { entityStoreSelector } from '~/features/higherorder/selectors/dataSelectors';
import { ENTITY_DEVICE_LOGS } from '~/features/base/constants/entities';

const deviceLogsDataSelector = state => entityStoreSelector(state)[ENTITY_DEVICE_LOGS];

const deviceSelector = (state, ownProps) =>
    getOr({}, ownProps.serialNumber, deviceLogsDataSelector(state));

export const deviceLogsSelector = createSelector(
    [deviceSelector],
    device => device.logs
);

