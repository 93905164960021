const groupNameRegEx = /^[a-zA-Z].{3,}/;

/**
 * Validates whether the given group name starts with a letter and has minimum length 4
 * @param {string} groupName The hwSerial to be validated
 * @returns {bool} The error code
 */
export function validateGroupName(groupName) {
    if (groupName && typeof groupName === 'string' &&
        groupNameRegEx.test(groupName)) {
        return true;
    }
    return false;
}
