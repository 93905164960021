import { createSelector } from 'reselect';

import { ENTITY_HW_VARIANTS_ALL } from '~/features/base/constants/entities';
import { entityStoreSelector } from '~/features/higherorder/selectors/dataSelectors';

const hwVariantsEntitiesSelector = state => entityStoreSelector(state)[ENTITY_HW_VARIANTS_ALL];

export const hwVariantsSelector = createSelector(
    [hwVariantsEntitiesSelector],
    (hwVariants) => {
        return hwVariants;
    },
);
