import noop from 'lodash/fp/noop';
import find from 'lodash/fp/find';
import isEmpty from 'lodash/fp/isEmpty';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import uid from '~/features/base/utils/uid';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import map from 'lodash/map';
import { FormattedMessage } from 'react-intl';
import {
    getCurrentlySelectedVehicleDevicesSelector, getCurrentlySelectedVehicleInfoSelector,
} from '~/features/oldVehicles/selectors/vehicleSelectors';
import { Select } from '@rio-cloud/rio-uikit';
import VehicleDetailsTabs from '~/features/oldVehicles/components/VehicleDetailsTabs';
import { getSelectedTabIdSelector } from '~/features/devices/selectors/controlDeviceSelectors';
import DevicePropertiesContainer from '~/features/oldVehicles/components/devices/DevicePropertiesContainer';
import {
    DELIVERABLES,
    DETAILS,
} from '~/features/oldVehicles/components/devices/constants/deviceDetailsTabs';
import DeviceDeliverablesContainer from '~/features/oldVehicles/components/devices/DeviceDeliverablesContainer';
import { TBM3, VCM } from '~/features/devices/constants/deviceTypes';
import DeviceTypeLabel from '~/features/devices/components/DeviceType';

/**
 * Container for the vehicle devices
 */
export class VehicleDevicesContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.name = uid();
        this.state = {
            selectedDeviceId: '',
        };
    }

    getDeviceIds = (devices) => {
        const deviceIds = [];
        map(devices, (device) => {
            deviceIds.push({
                id: device.deviceId,
                label: `${device.deviceId} (${device.type})`,
            });
        });
        return deviceIds;
    };

    renderFilter = (deviceIds, onDeviceIdChange, selectedDeviceId) => {
        return (
            <div className='col-sm-12'>
                <div className='col-sm-5 padding-bottom-25'>
                    <label><FormattedMessage id='intl-msg:deviceId'/></label>
                    <Select onChange={item => onDeviceIdChange(item.id)}
                            value={[selectedDeviceId]}
                            options={deviceIds}
                            tabIndex={1}
                    />
                </div>
            </div>
        );
    };

    onDeviceIdChange = (deviceId) => {
        this.setState({
            ...this.state,
            selectedDeviceId: deviceId,
        });
    };

    getSelectedTabContainer = (devices, selectedDeviceId) => {
        if (isEmpty(devices)) {
            return;
        }
        const { selectedTabId } = this.props;
        let selectedDeviceInfo;
        if (!selectedDeviceId) {
            selectedDeviceInfo = devices[0];
            this.onDeviceIdChange(selectedDeviceInfo.deviceId);
        } else {
            selectedDeviceInfo = find({ deviceId: selectedDeviceId }, devices);
            if (!selectedDeviceInfo) {
                selectedDeviceInfo = devices[0];
                this.onDeviceIdChange(selectedDeviceInfo.deviceId);
            }
        }

        if (isEmpty(selectedDeviceInfo)) {
            return;
        }
        switch (selectedTabId) {
            case DETAILS:
                return <DevicePropertiesContainer controlDeviceInfo={selectedDeviceInfo}
                                                  serialNumber={selectedDeviceId}/>;
            case DELIVERABLES:
                return <DeviceDeliverablesContainer controlDeviceInfo={selectedDeviceInfo}
                                                    serialNumber={selectedDeviceId}/>;
            // case FILES:
            //     return <DeviceFileVersionsContainer controlDeviceInfo={filter({ deviceId: selectedDeviceId }, devices)}
            //                                         serialNumber={selectedDeviceId}/>;
            // case REPORT:
            //     return <DeviceReportContainer serialNumber={serialNumber}/>;
            // case LOGS:
            //     return <DeviceLogsContainer serialNumber={serialNumber}/>;
            // case LOGSNEO:
            //     return <DeviceLogsContainerNeo serialNumber={serialNumber}/>;
            default:
                return <DevicePropertiesContainer controlDeviceInfo={selectedDeviceInfo}
                                                  serialNumber={selectedDeviceId}/>;
        }
    };

    render() {
        const { devices } = this.props;
        const { selectedDeviceId } = this.state;

        const deviceIds = this.getDeviceIds(devices, selectedDeviceId);
        return [
            <div className=''>{this.renderFilter(deviceIds, this.onDeviceIdChange, selectedDeviceId)}</div>,
            <VehicleDetailsTabs/>,
            this.getSelectedTabContainer(devices, selectedDeviceId),
        ];
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        selectedTabId: getSelectedTabIdSelector(state),
        vehicleInfo: getCurrentlySelectedVehicleInfoSelector(state, ownProps),
        devices: getCurrentlySelectedVehicleDevicesSelector(state, ownProps),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        triggerDataFetcher: () => {
            dispatch(triggerDataFetcher());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDevicesContainer);

VehicleDevicesContainer.defaultProps = {
    // props
    serialNumber: '',
    selectedTabId: '',
    autoRefresh: true,
    limit: 0,
    vehicleInfo: {},
    rolloutStates: [],
    devices: [],
    rolloutStatesLoading: false,
    // functions
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    triggerDataFetcher: noop,
};

VehicleDevicesContainer.propTypes = {
    // props
    serialNumber: PropTypes.string,
    selectedTabId: PropTypes.string,
    autoRefresh: PropTypes.bool,
    limit: PropTypes.number,
    vehicleInfo: PropTypes.object,
    rolloutStates: PropTypes.array,
    devices: PropTypes.array,
    rolloutStatesLoading: PropTypes.bool,
    // functions
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
};
