import React from 'react'
import SelectOTA from "~/features/base/components/forms/SelectOTA";
import {
    deviceTypeByDeliverableOptions,
    deviceTypeOptions
} from "~/features/base/constants/filterOptions";
import {FormattedMessage} from 'react-intl';
import {selectionByValue} from "~/features/base/utils/selectUtils";

interface DeviceTypeSelectProps {
    deliverableType?: string;
    hasError?: boolean
    value: string;
    onChange: Function;
}

const DeviceTypeSelect: React.FC<DeviceTypeSelectProps> = ({
                                                               hasError,
                                                               deliverableType,
                                                               value,
                                                               onChange
                                                           }) => {


    const deviceTypes = deliverableType ? deviceTypeByDeliverableOptions[deliverableType] : deviceTypeOptions;
    const options = selectionByValue(value)(deviceTypes);

    return (
        <div className={'margin-bottom-15'}>
            <label className='control-label'>
                <FormattedMessage id='intl-msg:deviceType'/>
            </label>
            <SelectOTA
                hasError={hasError}
                options={options}
                onChange={onChange}
                placeholder={<FormattedMessage
                    id='intl-msg:deviceType.prompt'/>}/>
        </div>
    )
}

export default DeviceTypeSelect;
