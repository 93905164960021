import PropTypes from 'prop-types';

import React from 'react';
import { Link } from 'react-router-dom';

export const PageHeaderLink = ({ children, to }) => {
    return (
        <Link to={to}>
            <span className='rioglyph rioglyph-chevron-left' aria-hidden='true'></span>
            {' '}
            {children}
        </Link>
    );
};

export default PageHeaderLink;

PageHeaderLink.propTypes = {
    // props
    to: PropTypes.string,
};
