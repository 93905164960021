import trim from 'lodash/fp/trim';
import noop from 'lodash/fp/noop';

import classNames from 'classnames';

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { validatePartialHwSerial } from '~/features/base/utils/hwSerialValidator';

export class SerialNumberPrefixFormItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isValid: validatePartialHwSerial(props.value),
        };
        this.onChange = this.onChange.bind(this);

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.focused) {
            return {
                isValid: validatePartialHwSerial(nextProps.value),
            };
        }
        return prevState;
    }

    onChange(event) {
        const hwSerial = trim(event.target.value);
        const isValid = validatePartialHwSerial(hwSerial);
        this.setState({
            isValid,
        });
        this.props.onChange({
            serialNumberPrefix: hwSerial || undefined,
        });
    }

    render() {
        const { label, value } = this.props;
        const { isValid } = this.state;

        const helpBlockMessage = !isValid ?
            <FormattedMessage id='intl-msg:invalidSerialNumber'/> : undefined;

        return (
            <div className={classNames('form-group', { 'has-error': !isValid })}>
                <label className='control-label'>
                    <FormattedMessage id={label}/>
                </label>
                <span className='input-group width-100pct'>
                    <span className='input-group-addon'>
                        <span className='rioglyph rioglyph-search' aria-hidden='true'></span>
                    </span>
                    <FormattedMessage id='intl-msg:serialNumber.prompt'>
                        { placeHolder =>
                            <input className='form-control'
                                type='text'
                                value={value}
                                placeholder={placeHolder}
                                onChange={this.onChange}/>
                        }
                    </FormattedMessage>
                </span>
                <span className='help-block'>{helpBlockMessage}</span>
            </div>

        );
    }
}

export default SerialNumberPrefixFormItem;

// TODO Obviously it doesn't work without knowledge about whitelistingInfo
SerialNumberPrefixFormItem.defaultProps = {
    // props
    value: '',
    label: 'intl-msg:serialNumberPrefix',
    // functions
    onChange: noop,
};

SerialNumberPrefixFormItem.propTypes = {
    // props
    value: PropTypes.string,
    label: PropTypes.string,
    // functions
    onChange: PropTypes.func,
};
