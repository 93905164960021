import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import {
    devicesLoadingSelector,
} from '~/features/devices/selectors/controlDeviceSelectors';
import { showControlDeviceEditor } from '~/features/devices/actions/controlDeviceEditorActions';
import { deviceRowDescriptors } from '~/features/oldVehicles/components/devices/constants/deviceRowDescriptors';
import uid from '~/features/base/utils/uid';
import { defaultNetworkCodeSelector } from '~/features/base/selectors/defaultNetworkCodeSelector';
import DevicePropertiesTable from '~/features/oldVehicles/components/devices/DevicePropertiesTable';

/**
 * Container for the device properties
 */
export class DevicePropertiesContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.name = uid();
    }

    getErrorMessage = () => {
        return (
            <div className='error-message'>
                <FormattedMessage id='intl-msg:controlDevices.fetch.error'/>
            </div>
        );
    };

    onEditDevice = () => {
        const { serialNumber } = this.props.controlDeviceInfo;
        this.props.showControlDeviceEditor({
            isNew: false,
            serialNumber,
        });
    };

    render() {
        const { controlDeviceInfo, defaultNetworkCodeList } = this.props;
        const controlDeviceData = { ...controlDeviceInfo, networkCodeList: defaultNetworkCodeList };
        return [
            <DevicePropertiesTable
                controlDeviceData={controlDeviceData}
                rowDescriptors={deviceRowDescriptors}/>,
        ];
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        deviceDetailsLoading: devicesLoadingSelector(state),
        defaultNetworkCodeList: defaultNetworkCodeSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        showControlDeviceEditor: payload => {
            dispatch(showControlDeviceEditor(payload));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DevicePropertiesContainer);

DevicePropertiesContainer.defaultProps = {
    // props
    devices: {},
    serialNumber: '',
    controlDeviceData: undefined,
    controlDeviceInfo: undefined,
    defaultNetworkCodeList: undefined,
    deviceDetailsLoading: false,
    // functions
    showControlDeviceEditor: noop,
};

DevicePropertiesContainer.propTypes = {
    // props
    devices: PropTypes.object,
    serialNumber: PropTypes.string,
    controlDeviceData: PropTypes.object,
    controlDeviceInfo: PropTypes.object,
    defaultNetworkCodeList: PropTypes.array,
    deviceDetailsLoading: PropTypes.bool,
    // functions
    showControlDeviceEditor: PropTypes.func,
};
