import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import size from 'lodash/fp/size';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import ListTableTS from '~/features/base/components/table/ListTableTS';
import ReloadButton from '~/features/base/components/ReloadButton';
import {TableLoader} from '~/features/base/components/TableLoader';
import {adaptOrdersColumnDescriptors} from "~/features/adaptOrders/constants/AdaptOrdersColumnDescriptors";
import TableReloadAndItems from "~/features/base/components/TableReloadAndItems";
import {ManualAdaptOrder} from "~/api/models/manualAdaptOrder";
import AdaptOrderCancelDialog from "~/features/adaptOrders/components/AdaptOrderCancelDialog";
import {hasAdaptOrderPermissionsSelector} from "~/features/user/selectors/permissionSelectors";
import { useSelector } from 'react-redux';
import {
    AdaptOrderFinalStatuses,
    AdaptOrderStatuses,
    AdaptOrderTypes
} from "~/features/adaptOrders/constants/AdaptOrderOptions";
import NoData from "~/features/base/components/NoData";
import map from "lodash/fp/map";
import {renderTableData} from "~/features/base/components/table/Utils";
import LogFilesTable from "~/features/deviceLogs/components/logsTable/LogFilesTable";
import LogFilesDownloadZipButton from "~/features/deviceLogs/components/logsTable/LogFilesDownloadZipButton";
import AdaptOrderDetails from "~/features/adaptOrders/components/AdaptOrderDetails";
import {cm4LogRequestColumnDescriptors} from "~/features/deviceLogs/components/logsTable/cm4ColumnDescriptors";
import ButtonDropdown from "@rio-cloud/rio-uikit/lib/es/ButtonDropdown";

interface AdaptOrdersListProps {
    vin: string;
    setPage: Function;
    page: number;
    isLoading: boolean,
    orders: any,
    onReload: () => void;
    onCreate: () => void;
    onEdit: (order: ManualAdaptOrder) => void;
}

enum ActionType {
    EDIT = 'EDIT',
    CANCEL = 'CANCEL',
}

const AdaptOrdersList: React.FC<AdaptOrdersListProps> = ({vin, setPage, page, isLoading, orders, onReload, onCreate, onEdit}) => {

    //permissions to create + edit + cancel and adapt order
    const hasAdaptOrderPermissions = useSelector((state) => hasAdaptOrderPermissionsSelector(state));

    const [showCancel, setShowCancel] = useState(false);
    const [adaptOrderCancel, setAdaptOrderCancel] = useState();
    const [openExpanders, setOpenExpander] = useState<{ [key: string]: boolean }>({});

    const handleReloadClick = () => {
        if (page === 0) onReload();
        else setPage(0);
    };
    const handleLoadMore = () => {
        setPage(page + 1);
    };

    const renderShowFilesButton = (id) => {
        return (
            <>
                <button type='button' className='margin-left-5' onClick={
                    (event) => {

                        if (openExpanders[id] !== undefined) {
                            setOpenExpander({
                                [id]: !openExpanders[id]
                            });
                        } else {
                            setOpenExpander({
                                [id]: true
                            });
                        }
                    }}>
                    {
                        openExpanders[id] ?
                            <span className='rioglyph rioglyph-chevron-up text-size-10' aria-hidden='true'></span>
                            : <span className='rioglyph rioglyph-chevron-down text-size-10' aria-hidden='true'></span>
                    }
                </button>
            </>
        );
    };

    const renderCustomRow = (item) => {
        const button = renderShowFilesButton(item.id);
        const tableData = map(columnDescriptor => renderTableData(item, columnDescriptor), adaptOrdersColumnDescriptors);
        if (size(tableData)) {
            const colOrder = size(tableData)-1;
            tableData[colOrder] = <td className=''>
                <div className='padding-right-25 display-flex justify-content-end'>
                    <div className='display-inline-block'>
                        {tableData[colOrder]?.props?.children}

                    </div>
                    <div className='display-inline-block'>
                        {button}
                    </div>
                </div>
            </td>;
        }
        const key = item['id'];
        const tableRows = [
            <tr className='tableRow' key={key} data-key={key}>
                {tableData}
                {hasAdaptOrderPermissions && <td className='width-5px'>
                    <ButtonDropdown
                        title={<span className='rioglyph rioglyph-option-vertical'/>}
                        className='pull-right position-relative'
                        dropdownClassName='position-fixed z-index-10. width-150'
                        bsStyle='link'
                        iconOnly
                        items={tableActionItems(item)}
                    />
                </td>}
            </tr>,
        ];

        openExpanders[item.id] ?
            tableRows.push(
                <tr key={key + '_'}>
                    <td style={{ borderTop: 0 }} colSpan={adaptOrdersColumnDescriptors.length}>
                        <h6 className={'margin-left-20 margin-bottom-0 text-bold'}>DETAILS</h6>
                        <AdaptOrderDetails order={item}/>
                    </td>
                </tr>
            ) : null;
        return tableRows;
    };

    const renderTable = () => {
        if (isLoading) {
            const allLabels = adaptOrdersColumnDescriptors.map(column => column.label);
            return (<TableLoader labels={allLabels} nLines={4}/>);
        }

        if (!isLoading && !size(orders?.content)) {
            return (
                <NotFoundState fullWidth
                               headline={<FormattedMessage id='intl-msg:nothingFound'/>}
                               message={<div className={'display-flex'} style={{ flexDirection: 'column' }}>
                                   <div className={'margin-bottom-25'}><FormattedMessage
                                       id='intl-msg:noAdaptOrdersFound'/></div>
                                   <ReloadButton onReloadClick={handleReloadClick}/>
                               </div>}
                />
            );
        }

        return (<div>
            <ListTableTS
                className='table-bordered'
                items={orders?.content || []}
                itemKey='id'
                columnDescriptors={adaptOrdersColumnDescriptors}
                actionItems={hasAdaptOrderPermissions ? (item) => tableActionItems(item) : undefined}
                renderCustomTableRow={renderCustomRow}
            />
            <div className={'display-flex justify-content-center'}>
                <button id='load-more-button'
                        disabled={!hasMoreLogs()}
                        className='btn btn-default'
                        onClick={handleLoadMore}>
                    <FormattedMessage id='intl-msg:loadMore'/>
                </button>
            </div>
        </div>)
    }

    const hasMoreLogs = () => {
        if (orders?.totalElements && orders?.content.length) {
            return orders?.totalElements > orders?.content.length;
        }
        return false;
    };

    const tableActionItems = (item) => [
        {
            value: <FormattedMessage id='intl-msg:edit' />,
            disabled: item.type === AdaptOrderTypes.AUTOMATIC || item?.status !== AdaptOrderStatuses.GENERATED,
            type: ActionType.EDIT,
            onSelect: () => {
                onEdit(item);
            }
        },
        {
            value: <FormattedMessage id='intl-msg:cancel' />,
            disabled: AdaptOrderFinalStatuses.includes(item?.status),
            type: ActionType.CANCEL,
            onSelect: () => {
                setShowCancel(true);
                setAdaptOrderCancel(item);
            }
        }
    ];

    const x =  (
        <td className='width-5px'>
            <ButtonDropdown
                title={<span className='rioglyph rioglyph-option-vertical'/>}
                className='pull-right position-relative'
                dropdownClassName='position-fixed z-index-10. width-150'
                bsStyle='link'
                iconOnly
                items={(item)=>tableActionItems(item)}
            />
        </td>
    );

    return (
                <div>
                    <div className={'margin-bottom-15'}>
                        <span className={'h5 margin-bottom-20'}>Adapt Orders</span>
                    </div>
                    <div className={'display-flex justify-content-between margin-bottom-15'}>
                        <TableReloadAndItems itemsCount={orders?.totalElements || 0} onReload={onReload}/>
                        <div className={'display-flex'}>
                            {hasAdaptOrderPermissions && <div className={""}>
                                <a id='create-control-device-button'
                                   className='btn btn-primary'
                                   onClick={onCreate}>
                                    <FormattedMessage id='intl-msg:createNew'/>
                                </a>
                            </div>}
                        </div>
                    </div>
                    <div className={'display-flex flex-column'}>
                        {renderTable()}
                    </div>
                    <AdaptOrderCancelDialog onHide={() => setShowCancel(false)} show={showCancel} vin={vin} adaptOrder={adaptOrderCancel}/>
                </div>
    );
};

export default AdaptOrdersList;
