import { ATLASSIAN_JIRA_ISSUE_URL } from '~/features/atlassian/constants/atlassianConstants';
import FieldOrNoData from '~/features/base/components/FieldOrNoData';
import { FormattedTime } from 'react-intl';
import React from 'react';
import Tag from '@rio-cloud/rio-uikit/lib/es/Tag';

export const existingIssueColumnDescriptors = [{
    id: 'issueId',
    field: 'issueId',
    label: 'intl-msg:jiraIssueId',
    className: 'width-150',
    format: field => <Tag clickable className='margin-right-5'><a
        href={ATLASSIAN_JIRA_ISSUE_URL + '/' + field}
        target="_blank">{field}</a></Tag>,
}, {
    id: 'summary',
    field: 'summary',
    label: 'intl-msg:jiraIssueSummary',
    format: field => <FieldOrNoData field={field.split(':')[1]}/>,
}, {
    id: 'status',
    field: 'status',
    label: 'intl-msg:jiraIssueStatus',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'created',
    field: 'created',
    label: 'intl-msg:createdTimestamp',
    format: field => <FormattedTime value={field} year='numeric'
                                    month='2-digit' day='2-digit'/>,
}];
