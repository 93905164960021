import {call, fork, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import {
    ENTITY_CONTROL_DEVICE_ELIGIBLE_DELIVERABLE_VERSIONS,
    ENTITY_CONTROL_DEVICE_INFO,
} from '~/features/base/constants/entities';
import {
    FETCH_CONTROL_DEVICE_ELIGIBLE_DELIVERABLES_VERSIONS,
    FETCH_CONTROL_DEVICE_INFORMATION,
} from '~/features/devices/actions/controlDeviceInfoActions';
import {showErrorMessage} from '~/features/base/actions/ui/notificationsActions';
import {mergeEntity, resetEntity} from '~/features/higherorder/actions/entityActions';
import {getDeviceManagementBackend} from '~/features/base/selectors/backendSelectors';
import {doHandleErrorSaga, getHTTP} from '~/features/base/sagas/sagaUtil';
import {parseControlDeviceInfo} from '~/features/devices/transforms/parseControlDeviceInfo';
import {finishedAction} from '~/features/higherorder/transforms/actionTransforms';
import {SET_SHOW_DEVICE_DETAILS_SIDEBAR} from '~/features/devices/actions/ui/controlDeviceDetailsActions';

export function* getControlDeviceURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/control-device`;
}

export function* doFetchControlDeviceInfoSaga(action) {
    try {
        const controlDeviceServiceURL = yield call(getControlDeviceURL);
        const { serialNumber } = action.payload;
        const response = yield call(getHTTP, `${controlDeviceServiceURL}/${serialNumber}/information`);
        const parsedResponse = parseControlDeviceInfo(response);
        yield put(mergeEntity(parsedResponse, { entityName: ENTITY_CONTROL_DEVICE_INFO }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFeShowControlDeviceDetailsInSidebar(action) {
    try {
        yield put(resetEntity({}, { entityName: ENTITY_CONTROL_DEVICE_INFO }));
        const controlDeviceServiceURL = yield call(getControlDeviceURL);
        const { serialNumber } = action;
        const response = yield call(getHTTP, `${controlDeviceServiceURL}/${serialNumber}/information`);
        const parsedResponse = parseControlDeviceInfo(response);
        yield put(mergeEntity(parsedResponse, { entityName: ENTITY_CONTROL_DEVICE_INFO }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchControlDeviceEligibleDeliverablesSaga(action) {
    try {
        yield put(resetEntity({}, { entityName: ENTITY_CONTROL_DEVICE_ELIGIBLE_DELIVERABLE_VERSIONS }));
        const controlDeviceServiceURL = yield call(getControlDeviceURL);
        const { serialNumber } = action.payload;
        const response = yield call(getHTTP, `${controlDeviceServiceURL}/${serialNumber}/eligible/deliverables`);
        yield put(mergeEntity(response, { entityName: ENTITY_CONTROL_DEVICE_ELIGIBLE_DELIVERABLE_VERSIONS }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* fetchControlDeviceInfoSaga() {
    yield takeLatest(FETCH_CONTROL_DEVICE_INFORMATION, doFetchControlDeviceInfoSaga);
}

export function* showControlDeviceDetailsInSidebar() {
    yield takeLatest(SET_SHOW_DEVICE_DETAILS_SIDEBAR, doFeShowControlDeviceDetailsInSidebar);
}

export function* fetchControlDeviceEligibleDeliverablesVersionsSaga() {
    yield takeEvery(FETCH_CONTROL_DEVICE_ELIGIBLE_DELIVERABLES_VERSIONS, doFetchControlDeviceEligibleDeliverablesSaga);
}

