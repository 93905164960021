export const MERGE_AGGREGATION = '@@data/MERGE_AGGREGATION';
export const RESET_AGGREGATION = '@@data/RESET_AGGREGATION';

export const mergeAggregation = (mergeProps, aggregation) => {
    return {
        type: MERGE_AGGREGATION,
        mergeProps,
        aggregation,
    };
};

export const resetAggregation = (mergeProps) => {
    return {
        type: RESET_AGGREGATION,
        mergeProps,
    };
};
