import PropTypes from 'prop-types';
import React from 'react';

import ListTable from '~/features/base/components/table/ListTable';
import {
    deviceManagedDeliverableColumnDescriptors,
} from '~/features/oldVehicles/components/devices/constants/deliverableColumnDescriptors';

export const DeviceManagedDeliverablesTable = ({ deliverables }) => {
    if (deliverables) {
        return [
            <ListTable showHeader={false}
                       items={deliverables}
                       itemKey='deliverableId'
                       columnDescriptors={deviceManagedDeliverableColumnDescriptors}/>,
        ];
    }
};

export default DeviceManagedDeliverablesTable;

DeviceManagedDeliverablesTable.defaultProps = {
    deliverables: [],
};

DeviceManagedDeliverablesTable.propTypes = {
    // props
    deliverables: PropTypes.array,
};
