import {
    RESET_REPORTS_BILLING_CHARTS_DATA,
    SET_REPORTS_BILLING_CHARTS_DATA,
} from '~/features/reportsbilling/actions/reportsBillingActions';

const defaultState = {
    chartsData: [],
    versions: [],
};

const resetReportsBillingChartsData = () => {
    return defaultState;
}

const setReportsBillingChartsData = (state, action) => {
    const payload = action.payload;
    state.chartsData = payload.chartsData;
    state.versions = payload.versionsData;
    return state;
};

export default function reportsBillingReducer(state = defaultState, action = {}) {
    switch (action.type) {
        case SET_REPORTS_BILLING_CHARTS_DATA:
            return setReportsBillingChartsData(state, action);
        case RESET_REPORTS_BILLING_CHARTS_DATA:
            return resetReportsBillingChartsData();
        default:
            return state;
    }
}
