import {UdfData, UdfDataParameter} from "~/api/models/udfData";
import {UdfDataParameterCodes} from "~/features/adaptOrders/constants/UdfDataParameterCodes";

export const udfDataToObj = (udfData: UdfDataParameter[]) => {
    if (!udfData || udfData.length === 0) {
        return undefined;
    }

    const vehicleManufacturerEOLProgInfoCode = udfData.find(
        elem => elem?.did === UdfDataParameterCodes.vehicleManufacturerEOLProgInfoCode
    )?.parameterLine;

    const integrityValidationDataExpectedValueIVDMD = udfData.find(
        elem => elem?.did === UdfDataParameterCodes.integrityValidationDataExpectedValueIVDMD
    )?.parameterLine;

    if (!vehicleManufacturerEOLProgInfoCode && !integrityValidationDataExpectedValueIVDMD) {
        return undefined;
    }

    return {
        vehicleManufacturerEOLProgInfoCode,
        integrityValidationDataExpectedValueIVDMD,
    };
};

export const udfDataToArr = ({
                                 vehicleManufacturerEOLProgInfoCode,
                                 integrityValidationDataExpectedValueIVDMD,
                             }: Partial<UdfData> = {}): UdfDataParameter[] => {
    return [
        vehicleManufacturerEOLProgInfoCode && {
            did: UdfDataParameterCodes.vehicleManufacturerEOLProgInfoCode,
            parameterLine: vehicleManufacturerEOLProgInfoCode,
        },
        integrityValidationDataExpectedValueIVDMD && {
            did: UdfDataParameterCodes.integrityValidationDataExpectedValueIVDMD,
            parameterLine: integrityValidationDataExpectedValueIVDMD,
        },
    ].filter(Boolean) as UdfDataParameter[]; // filter out any undefined entries
};

