import React, { useState } from 'react';

import DataTabs from '@rio-cloud/rio-uikit/DataTabs';
import DataTab from '@rio-cloud/rio-uikit/DataTab';
import { VehicleDetailsView } from '~/api/models';
import PropertiesTableTs from '~/features/base/components/table/PropertiesTableTs';
import {
    VehicleDetailsRowDescriptors
} from '~/features/vehicles/constants/VehicleDetailsRowDescriptors';
import { FormattedMessage } from 'react-intl';

interface VehicleDetailsTabsProps {
    vehicleDetails: VehicleDetailsView;
}

const tableClassName = 'table table-condensed table-hover';
const VehicleDetailsTabs: React.FC<VehicleDetailsTabsProps> = (props: VehicleDetailsTabsProps) => {
    const { vehicleDetails } = props;
    const [tabKey, setTabKey] = useState();

    return (
        <DataTabs activeKey={tabKey} onSelectTab={tabKey => setTabKey(tabKey)}>
            <DataTab tabKey='tab1' title={<Title text='' value={<FormattedMessage
                id={'intl-msg:vehicleDetails'}/>}/>}>
                <PropertiesTableTs rowDescriptors={VehicleDetailsRowDescriptors} showHeader={false}
                                   data={vehicleDetails} className={tableClassName}/>
            </DataTab>
            <DataTab tabKey='tab2' title={<Title text='' value={<FormattedMessage
                id={'intl-msg:vehicleInstalledDeliverables'}/>}/>}>
            </DataTab>
            <DataTab tabKey='tab3' title={<Title text='' value={<FormattedMessage
                id={'intl-msg:rolloutProgress'}/>}/>}>
            </DataTab>
        </DataTabs>

    );
};

export const Title = ({ text, value }) => (
    <div>
        <div>{text}</div>
        <div className='text-bold text-size-18'>{value}</div>
    </div>
);

export default VehicleDetailsTabs;
