import { call, put, select, fork, takeEvery } from 'redux-saga/effects';

import download from 'downloadjs';

import { EXPORT_DEVICES } from '~/features/devices/actions/exportActions';
import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';
import { requestText, doHandleErrorSaga } from '~/features/base/sagas/sagaUtil';
import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';

export function* getControlDeviceURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/control-device`;
}
export function* receiveSerials(url, searchCriteria) {
    const content = yield call(requestText, `${url}/export/serial-numbers`, {
        method: 'POST',
        headers: {
            Accept: 'text/plain',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(searchCriteria),
    });
    return content;
}

export function* receiveCSV(url, searchCriteria) {
    const content = yield call(requestText, `${url}/export/csv`, {
        method: 'POST',
        headers: {
            Accept: 'text/csv',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(searchCriteria),
    });
    return content;
}

export function* doExportDevicesSaga(action) {
    const url = yield call(getControlDeviceURL);
    try {
        if (action.format === 'serials') {
            const result = yield call(receiveSerials, url, action.searchCriteria);
            download(result, 'serials.txt', 'text/plain');
        } else {
            const result = yield call(receiveCSV, url, action.searchCriteria);
            download(result, 'devices.csv', 'text/csv');
        }
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
}

export function* exportDevicesSaga() {
    yield takeEvery(EXPORT_DEVICES, doExportDevicesSaga);
}
