import trim from 'lodash/fp/trim';
import noop from 'lodash/fp/noop';
import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import ContextSuggestFormItem from '~/features/base/components/forms/ContextSuggestFormItem';
import { createContextOptions } from '~/features/base/constants/filterOptions';
import FilePicker from '@rio-cloud/rio-uikit/lib/es/FilePicker';

export class DeliverableEditorForm extends PureComponent {

    onDeliverableNameChange = (event) => {
        this.props.changeDeliverableEditor({
            deliverableName: event.target.value,
        });
    };

    onContextNameChange = (event) => {
        this.props.changeDeliverableEditor({
            context: event.context,
        });
    };

    onFileSelect = (file) => {
        this.props.changeDeliverableEditor({
            file: file,
        });
    };

    render() {
        const { model, contexts } = this.props;
        const deliverableNameInput = this.renderDeliverableNameInput(model);
        const deliverableContextInput = this.renderDeliverableContextInput(model, contexts);
        const deliverableSelectedFileInput = this.renderDeliverableSelectedFileInput(model);

        return (
            <form>
                {deliverableNameInput}
                {deliverableContextInput}
                {deliverableSelectedFileInput}
            </form>
        );
    }

    renderDeliverableNameInput(model) {
        return (
            <div className='form-group form-group-file-id'>
                <label className='control-label'>
                    <FormattedMessage id='intl-msg:deliverableName'/>
                </label>
                <FormattedMessage id='intl-msg:deliverableName.prompt'>
                    {placeHolder =>
                        <input type='text' className='form-control'
                               value={model.deliverableName}
                               placeholder={placeHolder}
                               onChange={this.onDeliverableNameChange}/>
                    }
                </FormattedMessage>
            </div>
        );
    }

    renderDeliverableContextInput(model, contexts) {
        const contextOptions = createContextOptions(contexts, false);
        return (
            <div className='form-group form-group-file-id'>
                <ContextSuggestFormItem value={model.context}
                                        options={contextOptions}
                                        onChange={this.onContextNameChange}/>
            </div>
        );
    }

    renderDeliverableSelectedFileInput(model) {
        const fileName = model.file ? model.file[0].name : '';
        return (
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-1'>
                        <FilePicker label={<FormattedMessage id='intl-msg:addFile'/>}
                                    onPick={(acceptedFiles) => this.onFileSelect(acceptedFiles)}/>
                    </div>
                    <div className='col-sm-2'>
                        <label>
                            {fileName}
                        </label>
                    </div>
                </div>
            </div>
        );
    }

}

export default DeliverableEditorForm;

// TODO Obviously it doesn't work without knowledge about model
DeliverableEditorForm.defaultProps = {
    // props
    model: {
        deliverableName: '',
        context: '',
        file: undefined,
    },
    contexts: [],
    // functions
    changeDeliverableEditor: noop,
};

DeliverableEditorForm.propTypes = {
    // props
    model: {
        deliverableName: PropTypes.string,
        context: PropTypes.string,
        file: PropTypes.object,
    },
    contexts: PropTypes.array,
    // functions
    changeDeliverableEditor: PropTypes.func,
};
