import {
    RESET_SYS_PARAM_DEFINITION_EDITOR,
    CHANGE_SYS_PARAM_DEFINITION_EDITOR,
} from '~/features/sysParams/actions/sysParamDefinitionEditorActions';

import {
    CREATE_SYS_PARAM_DEFINITION,
    UPDATE_SYS_PARAM_DEFINITION,
} from '~/features/sysParams/actions/sysParamDefinitionActions';

import { STRING } from '~/features/sysParams/constants/sysParamTypes';
import { READ_ONLY } from '~/features/sysParams/constants/sysParamGroups';
import { UINT_8 } from '~/features/sysParams/constants/numericTypes';
import { toFinished } from '~/features/higherorder/transforms/actionTransforms';

const defaultState = {
    accessKey: 0,
    sysParamGroup: READ_ONLY,
    hasDefault: false,
    baseSoftwareVersion: {
        major: 1,
        minor: 0,
        patch: 0,
    },
    showBaseSwVersionForm: false,
    name: '',
    description: '',
    type: STRING,
    stringParameterDefinition: {
        maxLength: 0,
    },
    byteArrayParameterDefinition: {
        byteLength: 0,
    },
    enumParameterDefinition: {
        enumValues: [
        ],
        numericType: UINT_8,
    },
    numericParameterDefinition: {
        maxValue: 0,
        minValue: 0,
        type: UINT_8,
    },
    stringMaxLength: 128,
    stringDefaultValue: '',
    numericType: UINT_8,
    numericMinValue: 0,
    numericMaxValue: 1,
    numericDefaultValue: 0,
    enumValues: [{
        description: '',
        value: 0,
    }, {
        description: '',
        value: 1,
    }],
    enumDefaultValueIndex: 0,
    byteArrayByteLength: 128,
    unit: '',
    isNew: true,
    isInProgress: false,
    valid: false,
};

/**
 * Reducer for system parameter definition editor
 * @param {object} state The current state
 * @param {object} action The action to be processed
 * @returns {object} The new state
 */
export default function sysParamDefinitionEditorReducer(state = defaultState, action = {}) {
    let newState;
    switch (action.type) {
        case RESET_SYS_PARAM_DEFINITION_EDITOR:
            newState = {
                ...defaultState,
            };
            return newState;
        case CHANGE_SYS_PARAM_DEFINITION_EDITOR:
            newState = {
                ...state,
                ...action.payload,
            };
            return newState;
        case CREATE_SYS_PARAM_DEFINITION:
        case UPDATE_SYS_PARAM_DEFINITION:
            newState = {
                ...state,
                isInProgress: true,
            };
            return newState;
        case toFinished(CREATE_SYS_PARAM_DEFINITION):
        case toFinished(UPDATE_SYS_PARAM_DEFINITION):
            newState = {
                ...state,
                isInProgress: false,
            };
            return newState;
        default:
            return state;
    }
}
