export const queryToSearchCriteria = ({
    serialNumber,
    types,
    partitions,
    from,
    to,
}) => {
    const result = {
        serialNumber,
        types,
        partitions,
        from,
        to,
    };
    return result;
};
