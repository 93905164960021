import React from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { VehicleDetailsView } from '~/api/models';
import { getVehicleIcon } from '~/features/base/utils/getVehicleIcon';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import { copyToClipboard } from '~/features/apps/utils/appsUtils';

interface VehicleSummaryProps {
    vehicleDetails: VehicleDetailsView;
}

const VehicleSummary: React.FC<VehicleSummaryProps> = (vehicleDetailsProps: VehicleSummaryProps) => {
    const { vehicleVariant, vin, lastCheckForUpdateTimestamp } = vehicleDetailsProps.vehicleDetails;
    return (
        <div className='display-flex flex-row'>
            <div className={'flex-col margin-bottom-20 text-size-16'}>
                <span
                    className={`rioglyph ${getVehicleIcon(vehicleVariant)}  text-size-300pct margin-top-20 margin-bottom-10 text-color-darker `}/>
            </div>
            <div className={'flex-col margin-bottom-20 text-size-16'}>
                <div className='flex-row text-color-darker'>
                    <span className='text-bold text-size-20'>{vin}</span>
                    <OverlayTrigger placement='top' overlay={
                        <Tooltip id='tooltip' className='top-right'>
                            <FormattedMessage id='intl-msg:copyVin'/>
                        </Tooltip>}>
                        <span onClick={() => copyToClipboard(vin, true)}
                              className='rioglyph rioglyph-duplicate text-color-dark text-size-20 padding-left-5'></span>
                    </OverlayTrigger>
                </div>
                <div className='flex-row '>
                                    <span className='text-color-darker text-size-12'>
                                        <FormattedMessage id={'intl-msg:lastCheckForUpdateTimestamp'}/>:
                                        <span className='padding-left-5'>
                                            <FormattedTime value={lastCheckForUpdateTimestamp}
                                                           year='numeric'
                                                           month='2-digit' day='2-digit'/>
                                        </span>
                                    </span>

                </div>
            </div>
        </div>
    );
};

export default VehicleSummary;
