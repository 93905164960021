import compact from 'lodash/fp/compact';
import difference from 'lodash/fp/difference';
import find from 'lodash/fp/find';
import map from 'lodash/fp/map';
import size from 'lodash/fp/size';

import { all, call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { GROUPS_PATH } from '~/features/base/constants/routes';
import {
    ENTITY_DEVICE_GROUP,
    ENTITY_GROUP,
    ENTITY_GROUP_REFERENCES,
    ENTITY_SERIAL_NUMBER,
} from '~/features/base/constants/entities';

import {
    ADD_DEVICE_TO_GROUP,
    addDeviceToGroup,
    CREATE_GROUP,
    DELETE_GROUP,
    FETCH_DEVICE_GROUPS,
    FETCH_FILTERED_GROUPS,
    FETCH_GROUP,
    FETCH_REFERENCES_OF_GROUP,
    fetchGroup,
    fetchSerialNumbersOfGroup,
    REMOVE_DEVICE_FROM_GROUP,
    removeDeviceFromGroup,
    UPDATE_DEVICE_GROUPS,
    UPDATE_GROUP,
} from '~/features/groups/actions/groupActions';
import { fetchControlDeviceInformation } from '~/features/devices/actions/controlDeviceInfoActions';

import { deleteEntity, mergeEntities, mergeEntity } from '~/features/higherorder/actions/entityActions';
import { endLoading, startLoading } from '~/features/higherorder/actions/loadingActions';

import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';
import { hideModal } from '~/features/base/actions/ui/modalsActions';
import { followRoute } from '~/features/base/actions/ui/routeActions';

import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';
import { groupsSelector } from '~/features/groups/selectors/groupSelectors';

import { deleteHTTP, doHandleErrorSaga, getHTTP, patchHTTP, postHTTP, putHTTP } from '~/features/base/sagas/sagaUtil';
import { doFetchControlDeviceInfoSaga } from '~/features/devices/sagas/controlDeviceInfoSaga';

import {
    parseDeviceGroups,
    parseGroup,
    parseGroupReferences,
    parseGroups,
} from '~/features/groups/transforms/parseGroups';
import { failedAction, finishedAction } from '~/features/higherorder/transforms/actionTransforms';
import { AUTOMATIC, USER } from '~/features/groups/constants/groupTypes';
import { filterEmptyDependencies } from '~/features/deliverables/utils/checkEmptyDeliverableDependecies';

export function* getGroupsURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/vehicle-group`;
}

export function* doFetchFilteredGroupsSaga(action) {
    yield put(startLoading(ENTITY_GROUP));
    try {
        const url = yield call(getGroupsURL);
        const response = yield call(getHTTP, url);
        const entities = parseGroups(response.content);
        yield put(mergeEntities(entities, { entityName: ENTITY_GROUP }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_GROUP));
    yield put(finishedAction(action.type));
}

export function* doFetchDeviceGroupsSaga(action) {
    try {
        const url = yield call(getGroupsURL);
        const searchCriteria = {
            serialNumber: action.serialNumber,
        };
        const response = yield call(postHTTP, `${url}/search`, JSON.stringify(searchCriteria));
        const parsedResponse = {
            serialNumber: action.serialNumber,
            groups: parseDeviceGroups(response),
        };
        yield put(mergeEntity(parsedResponse, { entityName: ENTITY_DEVICE_GROUP }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchGroupSaga(action) {
    try {
        const url = yield call(getGroupsURL);
        const encodedName = encodeURIComponent(action.groupName);
        const response = yield call(getHTTP, `${url}/${encodedName}`);
        const parsedResponse = parseGroup(response);
        yield put(mergeEntity(parsedResponse, { entityName: ENTITY_GROUP }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchReferencesOfGroupSaga(action) {
    try {
        const url = yield call(getGroupsURL);
        const encodedName = encodeURIComponent(action.groupName);
        const response = yield call(getHTTP, `${url}/${encodedName}/references?limit=100`);
        const parsedResponse = parseGroupReferences(response);
        yield put(mergeEntity(parsedResponse, { entityName: ENTITY_GROUP_REFERENCES }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doCreateGroupSaga(action) {
    try {
        const url = yield call(getGroupsURL);
        if (action.group.type === AUTOMATIC) {
            filterEmptyDependencies(action.group?.autoGroupCriteria?.installedDeliverables);
        }
        const response = yield call(postHTTP, url, JSON.stringify(action.group));
        const parsedResponse = parseGroup(response);
        yield put(mergeEntity(parsedResponse, { entityName: ENTITY_GROUP }));
        yield put(hideModal());
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doUpdateGroupSaga(action) {
    try {
        const url = yield call(getGroupsURL);
        if (action.originalGroup.type === AUTOMATIC) {
            // prepare put request
            filterEmptyDependencies(action.group.autoGroupCriteria.installedDeliverables);
            const response = yield call(putHTTP, url, JSON.stringify(action.group));
            const parsedResponse = parseGroup(response);
            yield put(mergeEntity(parsedResponse, { entityName: ENTITY_GROUP }));
            yield put(mergeEntities(parsedResponse.serialNumbers, {
                entityName: ENTITY_SERIAL_NUMBER,
                groupName: action.group.name,
            }));
        } else if (action.originalGroup.type === USER) {
            // prepare patch request
            const groupName = action.group.name;
            const operations = [];
            const originalSerials = action.originalGroup.serialNumbers;
            const updatedSerials = action.group.serialNumbers;
            const toBeAddedSerials = difference(updatedSerials, originalSerials);
            toBeAddedSerials.map(serial => {
                operations.push({
                    op: 'add',
                    path: 'serialNumbers',
                    value: serial,
                });
            });
            const toBeRemovedSerials = difference(originalSerials, updatedSerials);
            toBeRemovedSerials.map(serial => {
                operations.push({
                    op: 'remove',
                    path: 'serialNumbers',
                    value: serial,
                });
            });

            if (action.originalGroup.description !== action.group.description) {
                operations.push({
                    op: 'add',
                    path: 'description',
                    value: action.group.description,
                });
            }
            if (size(operations)) {
                yield call(patchHTTP, `${url}/${groupName}`, JSON.stringify(operations));
                yield put(fetchGroup(groupName));
                yield put(fetchSerialNumbersOfGroup(groupName));
            }
        }
        yield put(hideModal());
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doAddDeviceToGroupSaga(action) {
    const { group, serialNumber } = action;
    try {
        const url = yield call(getGroupsURL);
        const encodedName = encodeURIComponent(group.name);
        const response = yield call(patchHTTP, `${url}/${encodedName}`, JSON.stringify([{
            op: 'add',
            path: 'serialNumbers',
            value: serialNumber,
        }]));
        if (!response) {
            throw Error('Could not add device to group');
        }
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doRemoveDeviceFromGroupSaga(action) {
    const { group, serialNumber } = action;
    try {
        const url = yield call(getGroupsURL);
        const encodedName = encodeURIComponent(group.name);
        const response = yield call(patchHTTP, `${url}/${encodedName}`, JSON.stringify([{
            op: 'remove',
            path: 'serialNumbers',
            value: serialNumber,
        }]));
        if (!response) {
            throw Error('Could not remove device from group');
        }
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doDeleteGroupSaga(action) {
    try {
        const url = yield call(getGroupsURL);
        const encodedName = encodeURIComponent(action.group.name);
        const response = yield call(deleteHTTP, `${url}/${encodedName}`);
        const parsedResponse = parseGroup(response);
        yield put(deleteEntity(parsedResponse, { entityName: ENTITY_GROUP }));
        yield put(hideModal());
        yield put(followRoute({ route: `/${GROUPS_PATH}` }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doUpdateDeviceGroupsSaga(action) {
    try {
        const { serialNumber, originalDeviceGroups, deviceGroups } = action;
        // in case we have group changes, update the here
        const toBeRemoved = difference(originalDeviceGroups, deviceGroups);
        const toBeAdded = difference(deviceGroups, originalDeviceGroups);
        const groups = yield select(groupsSelector);

        const removeCalls = compact(map(groupName => {
            // TODO Better filter instead of check
            const group = find(o => o.name === groupName, groups);
            if (group) {
                return call(doRemoveDeviceFromGroupSaga, removeDeviceFromGroup(group, serialNumber));
            }
        }, toBeRemoved));
        const addCalls = compact(map(groupName => {
            // TODO better filter instead of check
            const group = find(o => o.name === groupName, groups);
            if (group) {
                return call(doAddDeviceToGroupSaga, addDeviceToGroup(group, serialNumber));
            }
        }, toBeAdded));
        yield all(removeCalls);
        yield all(addCalls);
        yield call(doFetchControlDeviceInfoSaga, fetchControlDeviceInformation({ serialNumber }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* fetchFilteredGroupsSaga() {
    yield takeLatest(FETCH_FILTERED_GROUPS, doFetchFilteredGroupsSaga);
}

export function* fetchDeviceGroupsSaga() {
    yield takeLatest(FETCH_DEVICE_GROUPS, doFetchDeviceGroupsSaga);
}

export function* fetchGroupSaga() {
    yield takeLatest(FETCH_GROUP, doFetchGroupSaga);
}

export function* fetchReferencesOfGroupSaga() {
    yield takeLatest(FETCH_REFERENCES_OF_GROUP, doFetchReferencesOfGroupSaga);
}

export function* createGroupSaga() {
    yield takeEvery(CREATE_GROUP, doCreateGroupSaga);
}

export function* updateGroupSaga() {
    yield takeEvery(UPDATE_GROUP, doUpdateGroupSaga);
}

export function* addDeviceToGroupSaga() {
    yield takeEvery(ADD_DEVICE_TO_GROUP, doAddDeviceToGroupSaga);
}

export function* removeDeviceFromGroupSaga() {
    yield takeEvery(REMOVE_DEVICE_FROM_GROUP, doRemoveDeviceFromGroupSaga);
}

export function* deleteGroupSaga() {
    yield takeEvery(DELETE_GROUP, doDeleteGroupSaga);
}

export function* updateDeviceGroupsSaga() {
    yield takeEvery(UPDATE_DEVICE_GROUPS, doUpdateDeviceGroupsSaga);
}
