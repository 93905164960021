import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';

import {
    OVERVIEW_PATH,
    LOG_REQUESTS_PATH,
} from '~/features/base/constants/routes';

import LogRequestDetailsToolbar from '~/features/deviceLogs/components/LogRequestDetailsToolbar';
import LogRequestPropertiesTable from '~/features/deviceLogs/components/LogRequestPropertiesTable';
import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';
import PageHeader from '~/features/base/components/PageHeader';
import PageHeaderLink from '~/features/base/components/PageHeaderLink';

/**
 * Log requests details component
 */
export class LogRequestDetails extends PureComponent {
    render() {
        const { serialNumber, deviceAction } = this.props;
        if (!deviceAction) {
            return <div className='col-md-12'><Spinner/></div>;
        }
        const content = this.renderContent(deviceAction);
        return (
            <div className='device-action-details row'>
                <PageHeader pageTitle={
                    <FormattedMessage id='intl-msg:logRequestFor' values={{ serialNumber }}/>
                } pageLink={
                    <PageHeaderLink to={`/${OVERVIEW_PATH}/${LOG_REQUESTS_PATH}`}>
                        <FormattedMessage id='intl-msg:backToList'/>
                    </PageHeaderLink>
                }/>
                {content}
            </div>
        );
    }

    renderContent(deviceAction) {
        const { canUpdateDeviceActions, canDeleteDeviceActions, rowDescriptors } = this.props;
        return [
            <div className='col-md-12'>
                <LogRequestDetailsToolbar
                    canUpdateDeviceActions={canUpdateDeviceActions}
                    canDeleteDeviceActions={canDeleteDeviceActions}
                    deviceAction={deviceAction}
                    onEditDeviceAction={this.props.showDeviceActionEditor}
                    onDeleteDeviceAction={this.props.showLogRequestDeletionDialog}/>
            </div>,
            <DefaultWhiteColumn className='padding-top-20 padding-bottom-20'>
                <LogRequestPropertiesTable
                    logRequest={deviceAction}
                    rowDescriptors={rowDescriptors}/>
            </DefaultWhiteColumn>,
        ];
    }
}

export default LogRequestDetails;

LogRequestDetails.defaultProps = {
    canUpdateDeviceActions: false,
    canDeleteDeviceActions: false,
    serialNumber: '',
    deviceAction: undefined,
    rowDescriptors: [],
    showDeviceActionEditor: noop,
    showLogRequestDeletionDialog: noop,
};

LogRequestDetails.propTypes = {
    // props
    canUpdateDeviceActions: PropTypes.bool,
    canDeleteDeviceActions: PropTypes.bool,
    serialNumber: PropTypes.string,
    deviceAction: PropTypes.object,
    rowDescriptors: PropTypes.array,
    // functions
    showDeviceActionEditor: PropTypes.func,
    showLogRequestDeletionDialog: PropTypes.func,
};
