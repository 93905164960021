import React, { PureComponent } from 'react';
import { ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS_SCOPE_DEVICE } from '~/features/base/constants/entities';
import { fetchSystemStateNotificationsOfDevice } from '~/features/systemState/actions/systemStateActions';
import uid from '~/features/base/utils/uid';
import { parseQuery } from '~/features/base/utils/query';
import defaults from 'lodash/fp/defaults';
import { queryToSearchCriteria } from '~/features/updateEvents/utils/updateEventsQuery';
import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';
import {
    deviceSystemStateNotificationsForSerialNumberSelector,
    deviceSystemStateNotificationsLoadingSelector,
    deviceSystemStateNotificationsPageCountSelector,
    deviceSystemStateNotificationsPageNumberSelector,
    deviceSystemStateNotificationsTotalElementsSelector,
} from '~/features/systemState/selectors/systemStateSelectors';
import { followRoute } from '~/features/base/actions/ui/routeActions';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import { connect } from 'react-redux';
import noop from 'lodash/fp/noop';
import PropTypes from 'prop-types';
import DeviceSystemStateList from '~/features/devices/components/DeviceSystemStateList';

/**
 * Component for the device systemstates
 */

const FETCH_SYSTEM_STATE_NOTIFICATIONS_AMOUNT_INCREMENT = 5;
const SYSTEM_STATE_NOTIFICATION_MESSAGE_TYPES = ['CPU_LIMIT', 'MEM_LIMIT', 'APP_CRASH', 'PROC_NOT_RUNNING',
    'AUTO_BANK_SWITCH', 'BOOT_BANK_SWITCHED'];

export class DeviceSystemStateContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.name = uid();
    }

    onLoadMore = () => {
        const { pathname, search } = this.props;
        const query = parseQuery(search);
        const queryAmount = Number(query.limit ? query.limit : FETCH_SYSTEM_STATE_NOTIFICATIONS_AMOUNT_INCREMENT)
            + FETCH_SYSTEM_STATE_NOTIFICATIONS_AMOUNT_INCREMENT;
        this.props.followRoute({
            route: pathname, query: {
                ...query,
                limit: queryAmount,
            },
        });
    };

    getSearchCriteria(parsedQuery) {
        const { serialNumber } = this.props;
        return defaults(queryToSearchCriteria(parsedQuery), {
            serialNumber,
        });
    }

    render() {
        return (
            <DeviceSystemStateList {...this.props}
                                   onLoadMore={this.onLoadMore}/>
        );
    }

    componentWillMount() {
        const { search } = this.props;
        const parsedQuery = parseQuery(search);
        const { page, limit } = parsedQuery;
        let searchCriteria = this.getSearchCriteria(parsedQuery);
        searchCriteria.types = SYSTEM_STATE_NOTIFICATION_MESSAGE_TYPES.join(',');
        const fetchFilteredSystemStateNotificationOptions = {
            page: (page && page > 0) ? (page - 1) : 0,
            size: limit ? limit : FETCH_SYSTEM_STATE_NOTIFICATIONS_AMOUNT_INCREMENT,
            searchCriteria,
            scope: ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS_SCOPE_DEVICE,
        };
        this.props.fetchSystemStateNotificationsOfDevice(fetchFilteredSystemStateNotificationOptions);
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        pathname: pathnameSelector(state),
        search: searchSelector(state),
        systemStateNotifications: deviceSystemStateNotificationsForSerialNumberSelector(state, ownProps),
        systemStateNotificationsLoading: deviceSystemStateNotificationsLoadingSelector(state, ownProps),
        pageNumber: deviceSystemStateNotificationsPageNumberSelector(state),
        pageCount: deviceSystemStateNotificationsPageCountSelector(state),
        totalElements: deviceSystemStateNotificationsTotalElementsSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        followRoute: (options) => {
            dispatch(followRoute(options));
        },
        fetchSystemStateNotificationsOfDevice: (fetchFilteredSystemStateNotificationOptions) => {
            dispatch(fetchSystemStateNotificationsOfDevice(fetchFilteredSystemStateNotificationOptions));
        },
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        triggerDataFetcher: () => {
            dispatch(triggerDataFetcher());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSystemStateContainer);

DeviceSystemStateContainer.defaultProps = {
    // props
    serialNumber: '',
    systemStates: [],
    systemStatesLoading: false,
    pageNumber: 0,
    pageCount: 0,
    totalElements: 0,
    pathname: '',
    search: '',
    // functions
    followRoute: noop,
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    triggerDataFetcher: noop,
    fetchSystemStateNotificationsOfDevice: noop,
};

DeviceSystemStateContainer.propTypes = {
    // props
    serialNumber: PropTypes.string,
    systemStateNotifications: PropTypes.array,
    systemStateNotificationsLoading: PropTypes.bool,
    pageNumber: PropTypes.number,
    pageCount: PropTypes.number,
    totalElements: PropTypes.number,
    pathname: PropTypes.string,
    search: PropTypes.string,
    // functions
    followRoute: PropTypes.func,
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
    fetchSystemStateNotificationsOfDevice: PropTypes.func,
};
