/*
 * Action type for fetching system parameters default values
 * @type {string}
 */
export const FETCH_SYS_PARAM_DEFAULT_VALUES = 'FETCH_SYS_PARAM_DEFAULT_VALUES';

/**
 * Action type for creating a system parameter default valuess
 * @type {string}
 */
export const CREATE_SYS_PARAM_DEFAULT_VALUE = 'CREATE_SYS_PARAM_DEFAULT_VALUE';

/**
 * Action type for updating a system parameter default values
 * @type {string}
 */
export const UPDATE_SYS_PARAM_DEFAULT_VALUE = 'UPDATE_SYS_PARAM_DEFAULT_VALUE';

/**
 * Action type for deleting a system parameter default values
 * @type {string}
 */
export const DELETE_SYS_PARAM_DEFAULT_VALUE = 'DELETE_SYS_PARAM_DEFAULT_VALUE';

/**
 * Action creator for fetching of system parameter default values
 * @param {object} options The options for which to fetch the defaults
 * @param {object} options.shortBaseSwVersion The base software version for which to fetch the defaults
 * @returns {{type: string, payload}} The action for fetching of system parameter default values
 */
export function fetchSysParamDefaultValues({ shortBaseSwVersion }) {
    return {
        type: FETCH_SYS_PARAM_DEFAULT_VALUES,
        payload: {
            shortBaseSwVersion,
        },
    };
}

/**
 * Action creator for creating a system parameter default value
 * @param {object} sysParamDefaultValue The system parameter default value to be created
 * @returns {{type: string, payload: object}} The action for creating a system parameter default value
 */
export function createSysParamDefaultValue(sysParamDefaultValue) {
    return {
        type: CREATE_SYS_PARAM_DEFAULT_VALUE,
        payload: {
            sysParamDefaultValue,
        },
    };
}

/**
 * Action creator for updating a system parameter default value
 * @param {object} sysParamDefaultValue The system parameter default value to be updated
 * @returns {{type: string, payload: object}} The action for updating a system parameter default value
 */
export function updateSysParamDefaultValue(sysParamDefaultValue) {
    return {
        type: UPDATE_SYS_PARAM_DEFAULT_VALUE,
        payload: {
            sysParamDefaultValue,
        },
    };
}

/**
 * Action creator for deleting a system parameter default value
 * @param {object} sysParamDefaultValue The system parameter default value to be deleted
 * @returns {{type: string, payload: object}} The action for deleting a system parameter default value
 */
export function deleteSysParamDefaultValue(sysParamDefaultValue) {
    return {
        type: DELETE_SYS_PARAM_DEFAULT_VALUE,
        payload: sysParamDefaultValue,
    };
}
