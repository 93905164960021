import React from 'react';
import { FormattedTime } from 'react-intl';
import Tag from '@rio-cloud/rio-uikit/lib/es/Tag';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';

export const cm4LogRequestColumnDescriptors = [{
    id: 'systems',
    field: 'requestedSystems',
    label: 'intl-msg:logs:requestedSystems',
    format: field => field?.map(system => <Tag round={false} size='small' className={'margin-5'}>
        {system}
    </Tag>),
}, {
    id: 'createdDate',
    field: 'createdDate',
    label: 'intl-msg:logs:requestAt',
    format: field => [
        <FormattedTime value={field} year='numeric' month='2-digit' day='2-digit'/>,
    ],
}, {
    id: 'intl-msg:logs:status',
    field: 'status',
    label: 'intl-msg:logs:status',
    format: field => field,
}, {
    id: 'period',
    field: 'requestedPeriod',
    label: 'intl-msg:logs:requestedPeriod',
    format: (field) => (field && [
        <FormattedTime value={field.minValue} year='numeric' month='2-digit' day='2-digit'/>,
        field.maxValue ? (
            <span>
                <span> - </span>
                <FormattedTime value={field.maxValue} year='numeric' month='2-digit' day='2-digit'/>
            </span>
        ) : null,
    ])
}, {
    id: 'requestedBy',
    field: 'requestedBy',
    label: 'intl-msg:logs:requestedBy',
    format: field => {
        const valueSplitted = field?.split('(',);
        const email = valueSplitted?.length === 2 ? valueSplitted[1].replace(')', '') : '';

        return (<OverlayTrigger placement='top' overlay={
            <Tooltip id='tooltip' className='top-left'>
                {email
                }
            </Tooltip>}>
            <span className={'display-block ellipsis-2'}> {field}</span>
        </OverlayTrigger>);
    }
}, {
    id: 'logs',
    field: 'log',
    label: 'intl-msg:files',
    format: field => <></>
}
];

