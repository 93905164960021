import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import VehicleDetailsSidebarContainer from '~/features/oldVehicles/components/VehicleDetailsSidebarContainer';
import {
    getCurrentlySelectedVehicleInfoSelector,
    getSelectedTabIdSelector,
} from '~/features/oldVehicles/selectors/vehicleSelectors';
import { toggleVehicleDetailsSidebar } from '~/features/oldVehicles/actions/ui/vehicleDetailsActions';
import VehicleSummary from '~/features/oldVehicles/components/VehicleSummary';
import { ExpanderPanel } from '@rio-cloud/rio-uikit';
import { FormattedMessage } from 'react-intl';
import VehicleRolloutStatesContainer from '~/features/oldVehicles/components/VehicleRolloutStatesContainer';
import VehicleDevicesContainer from '~/features/oldVehicles/components/VehicleDevicesContainer';

export class VehicleDetailsSidebarRoutes extends PureComponent {

    getSelectedTabContainer = () => {
        const { vehicleInfo, selectedTabId } = this.props;
        const vin = (vehicleInfo && vehicleInfo.vin) || '---';
        // switch (selectedTabId) {
        // case DETAILS:
        //     return <VehiclePropertiesContainer vin={vin}/>;
        // case PACKAGES:
        //     return <DevicePackagesContainer serialNumber={serialNumber}/>;
        // case FILES:
        //     return <DeviceFileVersionsContainer serialNumber={serialNumber}/>;
        // case REPORT:
        //     return <DeviceReportContainer serialNumber={serialNumber}/>;
        // case LOGS:
        //     return <DeviceLogsContainer serialNumber={serialNumber}/>;
        // case LOGSNEO:
        //     return <DeviceLogsContainerNeo serialNumber={serialNumber}/>;
        // default:
        //     return <div>default</div>//<VehiclePropertiesContainer vin={vin}/>;
        // }
    };

    render() {
        const { vehicleInfo } = this.props;
        const vin = (vehicleInfo && vehicleInfo.vin) || '---';
        return (
            <div className='display-flex height-100pct'>
                <VehicleDetailsSidebarContainer vin={vin}>
                    {vehicleInfo ?
                        <DefaultWhiteColumn>
                            <div className='position-sticky top-0 bg-white padding-bottom-5 z-index-5 non-printable'>
                                <div className='row'>
                                    <VehicleSummary {...this.props}
                                                    vin={vin}
                                                    vehicleInfo={vehicleInfo}/>
                                </div>
                                <div className='row padding-15'>
                                    <ExpanderPanel bsStyle='primary' className='margin-0' open={true}
                                                   title={<FormattedMessage id='intl-msg:controlDevices'/>}>
                                        <VehicleDevicesContainer/>
                                    </ExpanderPanel>
                                </div>
                                <div className='row padding-15'>
                                    <ExpanderPanel bsStyle='primary' className='margin-0' open={false}
                                                   title={<FormattedMessage id='intl-msg:rolloutProgress'/>}>
                                        <VehicleRolloutStatesContainer serialNumber={vehicleInfo.serialNumber}/>
                                    </ExpanderPanel>
                                </div>
                            </div>
                            {
                                this.getSelectedTabContainer()
                            }
                        </DefaultWhiteColumn>
                        :
                        <div
                            className={'display-flex justify-content-center align-items-center margin-25 padding-25'}>
                            <Spinner text={'Loading'}/>
                        </div>
                    }
                </VehicleDetailsSidebarContainer>
            </div>
        );
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        selectedTabId: getSelectedTabIdSelector(state),
        vehicleInfo: getCurrentlySelectedVehicleInfoSelector(state, ownProps),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        toggleVehicleDetailsSidebar: () => {
            dispatch(toggleVehicleDetailsSidebar());
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VehicleDetailsSidebarRoutes));
