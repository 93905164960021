import reduce from 'lodash/fp/reduce';

const vinRegEx = /^[a-hj-npr-zA-HJ-NPR-Z0-9]{17}$/;

/**
 * Validates whether the given vin has 17 characters
 * @param {string} vin The vin to be validated
 * @returns {bool} The error code
 */
export function validateVIN(vin) {
    if (vin && typeof vin === 'string' &&
        vinRegEx.test(vin)) {
        return true;
    }
    return false;
}

/**
 * Validates whether the given list of vin has 17 characters
 * @param {array} vin The hwSerial to be validated
 * @returns {bool} The error code
 */
export function validateVINs(vins) {
    const allValid = reduce((valid, vin) => {
        return valid && validateVIN(vin);
    }, true, vins);
    return allValid;
}
