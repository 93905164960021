import map from 'lodash/fp/map';

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

export const FlagsLegend = ({ data }) => {
    const items = map.convert({ cap: false })((item, index) => (
        <div key={`flag-${index}`} className={'width-50pct pull-left padding-left-5 padding-right-5'}>
            <span className={`${item.classes}`} style={{
                'width': '28px',
                'height': '21px',
                'boxShadow': 'rgb(0 0 0 / 50%) 2px 2px 6px 0px',
            }}></span>
            <span className={'position-absolute padding-top-5'}>
                {index === 7
                    ? <span className={'margin-left-20'}><FormattedMessage
                        id={'intl-msg:other'}/></span>
                    : ''}
            </span>
            <span className='badge text-size-smallest margin-top-5 pull-right'
                  style={{ backgroundColor: item.color }}>
                <FormattedNumber value={item.value}/>
            </span>
        </div>
    ), data);
    return (
        <div className='width-100pct clearfix'>
            {items}
        </div>
    );
};

export default FlagsLegend;

FlagsLegend.propTypes = {
    // props
    data: PropTypes.array,
    route: PropTypes.string,
    query: PropTypes.string,
};
