import map from 'lodash/fp/map';
import size from 'lodash/fp/size';
import GroupChip from '~/features/groups/components/GroupChip';
import CommaSeparatedList from '~/features/base/components/CommaSeparatedList';
import NoData from '~/features/base/components/NoData';
import uid from '~/features/base/utils/uid';
import React from 'react';

interface GroupChipListProps {
    groups: string[];
    bsStyle?: string;
    className?: string;
    generalToolTip?: string;
}

export const GroupChipList = ({ groups, bsStyle, className, generalToolTip }: GroupChipListProps) => {
    if (!size(groups)) {
        return <NoData/>;
    }

    let groupChips = new Array<JSX.Element>();
    map(group => {
        const adjustedGroup = generalToolTip ? ({ name: group, description: generalToolTip }) : group;
        groupChips.push(<GroupChip key={uid()} group={adjustedGroup} bsStyle={bsStyle} className={className}/>);
    }, groups);
    return (
        <CommaSeparatedList>{groupChips}</CommaSeparatedList>
    );
};

export default GroupChipList;
