import { toFinished } from '~/features/higherorder/transforms/actionTransforms';
import {
    CHANGE_ARTIFACT_EDITOR,
    CREATE_ARTIFACT,
    RESET_ARTIFACT_EDITOR,
    UPDATE_ARTIFACT,
} from '~/features/deliverables/features/artifacts/actions/artifactActions';

const defaultState = {
    isNew: true,
    isInProgress: false,
    artifactName: '',
    deliverableId: '',
    deliverableVersion: '',
    owningContext: '',
};

export const resetArtifactEditor = (state = defaultState) => {
    return state;
};
export const changeArtifactEditor = (state, { payload }) => {
    const newState = {
        ...state,
        ...payload,
    };
    return newState;
};
export const setInProgress = (state) => {
    return {
        ...state,
        isInProgress: true,
    };
};
export const setNotInProgress = (state) => {
    return {
        ...state,
        isInProgress: false,
    };
};

export default function artifactEditorReducer(state = defaultState, action = {}) {
    switch (action.type) {
        case RESET_ARTIFACT_EDITOR:
            return resetArtifactEditor();
        case CHANGE_ARTIFACT_EDITOR:
            return changeArtifactEditor(state, action);
        case CREATE_ARTIFACT:
        case UPDATE_ARTIFACT:
            return setInProgress(state);
        case toFinished(CREATE_ARTIFACT):
        case toFinished(UPDATE_ARTIFACT):
            return setNotInProgress(state);
        default:
            return state;
    }
}
