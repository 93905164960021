import React from 'react';
import { FormattedMessage } from 'react-intl';
import AutoSuggest from "@rio-cloud/rio-uikit/AutoSuggest";
import { AutoSuggestProps } from '@rio-cloud/rio-uikit/types';

interface AutoSuggestOTAProps {
    hasError: boolean;
}

const AutoSuggestOTA: React.FC<AutoSuggestOTAProps & Partial<AutoSuggestProps>> = (props) => {

    const highlightError = props.hasError ? 'form-group has-feedback has-error' : '';
    return (
        <>
            <div className={highlightError}>
                <AutoSuggest
                    {...props}
                /></div>
            {props.hasError &&
                <div className={'form-group has-feedback has-error'}>
                        <span className='help-block'>
                        <span><FormattedMessage id='intl-msg:form:requiredField'/></span>
                        </span>
                </div>
            }</>
    );
};

export default AutoSuggestOTA;
