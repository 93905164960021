import React, { PureComponent } from 'react';
import uid from '~/features/base/utils/uid';
import PieChart from '@rio-cloud/rio-uikit/lib/es/PieChart';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import {
    reportsBillingDetailsInitialDataSelector,
} from '~/features/reportsbilling/selectors/reportsBillingSelectors';
import noop from 'lodash/fp/noop';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import size from 'lodash/fp/size';
import map from 'lodash/fp/map';
import {
    createDetailedPieChartData,
    createIntermediateObject,
    createTotalChartsData,
    sourceDataDetails,
} from '~/features/reportsbilling/utils/processRawChartData';
import ReportsBillingInfoBoxContentLoader
    from '~/features/reportsbilling/components/ReportsBillingInfoBoxContentLoader';
import { ReportsBillingLegendTable } from '~/features/reportsbilling/components/ReportsBillingLegendTable';
import { ReportsBillingLegendTableCosts } from '~/features/reportsbilling/components/ReportsBillingLegendTableCosts';

const chartsData = {};

export class ReportsBillingChartsDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.name = uid();
        this.state = {
            searchValue: '',
        };
        this.onSearchValueChange = this.onSearchValueChange.bind(this);
    }

    onCloseDialog = () => {
        chartsData.detailedPieChartData = noop;
        this.props.dialogParams.close();
    };

    onSearchValueChange(searchValue) {
        this.setState({
            searchValue,
        });
    }

    dialogContent(domain) {
        const { searchValue } = this.state;
        const filteredChartData = !searchValue ? chartsData.detailedPieChartData
            : chartsData.detailedPieChartData.filter(row => (
                row.name.toLowerCase().startsWith(searchValue.toLowerCase())
            ));
        const tableFilteredChartData = JSON.parse(JSON.stringify(filteredChartData));
        const options = {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        };
        let subLineTotal = '';
        let subLineCosts = '';
        let result;
        let pieChartAccessor = 'value';
        if (domain === 'costs') {
            pieChartAccessor = 'costs';
            const costs = chartsData.detailedPieChartData.reduce((t, o) => {
                return t + parseFloat(o.costs);
            }, 0);
            const bytes = chartsData.detailedPieChartData.reduce((t, o) => {
                return t + parseFloat(o.bytes);
            }, 0);
            const requests = chartsData.detailedPieChartData.reduce((t, o) => {
                return t + parseFloat(o.requests);
            }, 0);
            subLineTotal = `COSTS: ${costs.toLocaleString(undefined, options)} €`;
            const subLineBytes = `DATA VOLUME: ${bytes.toLocaleString(undefined, options)} GB`;
            const subLineRequests = `REQUESTS: # ${requests.toLocaleString()}`;
            result =
                <p className='bg-primary padding-10 rounded'>
                    <span className='margin-right-25'>{subLineTotal}</span>
                    <span className='margin-right-25'>{subLineBytes}</span>
                    <span>{subLineRequests}</span>
                </p>;
        } else {
            const sum = chartsData.detailedPieChartData.reduce((t, o) => {
                return t + parseFloat(o.value);
            }, 0);
            const total = chartsData.detailedPieChartData.reduce((t, o) => {
                return t + parseFloat(o.costs);
            }, 0);
            if (domain === 'bytes') {
                subLineTotal = `DATA VOLUME: ${sum.toLocaleString(undefined, options)} GB`;
                subLineCosts = `COSTS: ${total.toLocaleString(undefined, options)} €`;
                result =
                    <p className='bg-primary padding-10 rounded'>
                        <span className='margin-right-25'>{subLineTotal}</span>
                        <span>{subLineCosts}</span>
                    </p>;
            }
            if (domain === 'requests') {
                subLineTotal = `REQUESTS: # ${sum.toLocaleString()}`;
                subLineCosts = `COSTS: ${total.toLocaleString(undefined, options)} €`;
                result =
                    <p className='bg-primary padding-10 rounded'>
                        <span className='margin-right-25'>{subLineTotal}</span>
                        <span>{subLineCosts}</span>
                    </p>;
            }
        }
        return (
            <div className='col-md-12 bg-white'>
                {size(chartsData.detailedPieChartData) ?
                    <div className='text-size-20'>
                        {result}
                    </div>
                    :
                    null
                }
                <div className='col-md-12 bg-white'>
                    <div className={'col-md-6 bg-white'}>
                        {size(chartsData.detailedPieChartData) ?
                                <PieChart
                                    data={filteredChartData}
                                    dataKey={entry => entry[pieChartAccessor]}
                                    filled={true}
                                    outerRadius={150}
                                    height={500}
                                    width={'100%'}
                                    paddingAngle={0}
                                    legend={false}
                                    tooltip={false}
                                    pieOptions={{
                                        animationDuration: 300,
                                        label: entry => entry.name,
                                    }}
                                />
                            :
                            <ReportsBillingInfoBoxContentLoader/>
                        }
                    </div>
                    <div className={'col-md-6 bg-white'}>
                        {size(chartsData.detailedPieChartData) ?
                            domain === 'costs'
                                ? <ReportsBillingLegendTableCosts
                                    data={tableFilteredChartData}
                                    onSearchValueChange={this.onSearchValueChange}
                                    searchValue={this.state.searchValue}
                                    dialogParams={this.props.dialogParams}
                                />
                                : <ReportsBillingLegendTable
                                    data={tableFilteredChartData}
                                    onSearchValueChange={this.onSearchValueChange}
                                    searchValue={this.state.searchValue}
                                    dialogParams={this.props.dialogParams}
                                />
                            :
                            <ReportsBillingInfoBoxContentLoader/>
                        }
                    </div>
                </div>
            </div>);
    }

    dialogFooter() {
        return (
            <div>
                <Button onClick={this.onCloseDialog}>
                    <span>{'Close'}</span>
                </Button>
            </div>
        );
    }

    render() {
        const { rawChartsData, dialogParams } = this.props;
        const retArr = [];
        map((item) => {
            retArr.push(createIntermediateObject(item));
        }, rawChartsData);
        chartsData['intermediateObj'] = retArr;
        chartsData['detailedPieChartData'] = [];
        const retVal = createTotalChartsData(chartsData.intermediateObj);
        Object.assign(chartsData, retVal);
        if (size(rawChartsData) > 0) {
            const details = sourceDataDetails(chartsData.intermediateObj, dialogParams.month,
                dialogParams.deliverableType);

            chartsData.detailedPieChartData = createDetailedPieChartData(details, dialogParams.domain);
        }
        const title = `${dialogParams.chartType} Details - ${dialogParams.deliverableType.toUpperCase()} - ${this.props.dialogParams.month}`;
        const content = this.dialogContent(dialogParams.domain);
        const footer = this.dialogFooter();
        return (
            <div>
                <Dialog
                    show={dialogParams.show}
                    title={title}
                    footer={footer}
                    body={content}
                    bsSize={Dialog.SIZE_XL}
                    disableEsc={true}
                    showCloseButton={false}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    rawChartsData: reportsBillingDetailsInitialDataSelector(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsBillingChartsDialog);

ReportsBillingChartsDialog.defaultProps = {
    // props
    rawChartsData: noop,
    // functions
};

ReportsBillingChartsDialog.propTypes = {
    // props
    rawChartsData: PropTypes.array,
    // functions
};
