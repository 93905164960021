import { createSelector } from 'reselect';

import {
    ENTITY_DELIVERABLE_HISTORY,
    ENTITY_DELIVERABLES_CONTEXT_INFO,
    ENTITY_DELIVERABLES_USER_INFO,
} from '~/features/base/constants/entities';
import { entityStoreSelector, loadingStoreSelector } from '~/features/higherorder/selectors/dataSelectors';

import {
    DELIVERABLE_TYPE_APP,
    DELIVERABLE_TYPE_DISTRO,
    DELIVERABLE_TYPE_FILE,
} from '~/features/deliverables/constants/deliverablesParameters';

import {
    ACCESSIBLE_CONTEXTS,
    CONTEXT_OWNERS,
} from '~/features/artifacts/constants/deliverablesParameters';
import { pathnameSelector } from '~/features/base/selectors/locationSelectors';
import {
    DELIVERABLES_APPS_PATH,
    DELIVERABLES_DISTROS_PATH,
    DELIVERABLES_FILES_PATH,
    ARTIFACTS_PATH,
} from '~/features/base/constants/routes';

export const deliverableUserInfoEntitiesSelector = state => entityStoreSelector(state)[ENTITY_DELIVERABLES_USER_INFO];
export const deliverableContextInfoEntitiesSelector = state => entityStoreSelector(
    state)[ENTITY_DELIVERABLES_CONTEXT_INFO];
export const deliverablesLoadingSelector = state => loadingStoreSelector(state)['deliverables'];

export const deliverableEditorSelector = state => state.ui.deliverableEditor;
export const deliverablePermissionSelector = state => state.ui.deliverablePermissions;
export const allDeliverablesSelector = state => state.ui.deliverables;

export const deliverableTypeSelector = createSelector(
    [pathnameSelector],
    (pathname) => {
        switch (pathname) {
            case `/${ARTIFACTS_PATH}/${DELIVERABLES_FILES_PATH}`:
                return DELIVERABLE_TYPE_FILE;
            case `/${ARTIFACTS_PATH}/${DELIVERABLES_APPS_PATH}`:
                return DELIVERABLE_TYPE_APP;
            case `/${ARTIFACTS_PATH}/${DELIVERABLES_DISTROS_PATH}`:
                return DELIVERABLE_TYPE_DISTRO;
            default:
                return DELIVERABLE_TYPE_FILE;
        }
    },
);

export const deliverablesContextsSelector = createSelector(
    [deliverableUserInfoEntitiesSelector],
    (entities) => {
        return entities ? entities[ACCESSIBLE_CONTEXTS] : [];
    },
);

export const selectedContextOwnersSelector = createSelector(
    [deliverableContextInfoEntitiesSelector],
    (entities) => {
        return entities ? entities[CONTEXT_OWNERS] : [];
    },
);

export const deliverablesSelector = createSelector(
    [deliverableTypeSelector, allDeliverablesSelector],
    (deliverableType, entities) => {
        return entities[deliverableType];
    },
);

const deliverableHistoryEntitiesSelector = state => entityStoreSelector(state)[ENTITY_DELIVERABLE_HISTORY];
export const deliverableHistorySelector = (state) => {
    return deliverableHistoryEntitiesSelector(state) || {};
};
