export const FETCH_REPORTS_BILLING_INITIAL_DATA = 'FETCH_REPORTS_BILLING_INITIAL_DATA';
export const REMOVE_REPORTS_BILLING_INITIAL_DATA = 'REMOVE_REPORTS_BILLING_INITIAL_DATA';
export const FETCH_REPORTS_BILLING_DATA = 'FETCH_REPORTS_BILLING_DATA';
export const FETCH_REPORTS_BILLING_DELIVERABLE_VERSIONS_BY_ID = 'FETCH_REPORTS_BILLING_DELIVERABLE_VERSIONS_BY_ID';
export const SET_REPORTS_BILLING_CHARTS_DATA = 'SET_REPORTS_BILLING_CHARTS_DATA';
export const RESET_REPORTS_BILLING_CHARTS_DATA = 'RESET_REPORTS_BILLING_CHARTS_DATA';

export const fetchReportsBillingChartsInitialData = ({
    searchCriteria,
}) => {
    return {
        type: FETCH_REPORTS_BILLING_INITIAL_DATA,
        payload: {
            searchCriteria,
        },
    };
};
export const removeReportsBillingChartsInitialData = () => {
    return {
        type: REMOVE_REPORTS_BILLING_INITIAL_DATA,
    };
};

// export const fetchReportsBillingChartsData = ({
//     page,
//     qSize,
//     searchCriteria,
// }) => {
//     return {
//         type: FETCH_REPORTS_BILLING_DATA,
//         payload: {
//             page,
//             qSize,
//             searchCriteria,
//         },
//     };
// };

// export const fetchReportsBillingDeliverableVersionsById = ({
//     page,
//     qSize,
//     searchCriteria,
// }) => {
//     return {
//         type: FETCH_REPORTS_BILLING_DELIVERABLE_VERSIONS_BY_ID,
//         payload: {
//             page,
//             qSize,
//             searchCriteria,
//         },
//     };
// };
