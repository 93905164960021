import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { DEVICES_PATH } from '~/features/base/constants/routes';
import Chip from '~/features/base/components/Chip';

export const DeviceChip = ({ serialNumber, bsStyle, className }) => {
    return (
        <Link to={`/${DEVICES_PATH}?serial-number-prefix=${serialNumber}`} target="_blank">
            <Chip bsStyle={bsStyle} className={className}>{serialNumber}</Chip>
        </Link>
    );
};

export default DeviceChip;

DeviceChip.propTypes = {
    // props
    serialNumber: PropTypes.string.isRequired,
    bsStyle: PropTypes.string,
    className: PropTypes.string,
};
