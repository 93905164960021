export const CONTROL_DEVICE_EDITOR_MODAL = 'CONTROL_DEVICE_EDITOR_MODAL';
export const CONTROL_DEVICE_QUICK_WHITELISTING_MODAL = 'CONTROL_DEVICE_QUICK_WHITELISTING_MODAL';
export const CONTROL_DEVICE_ASSOCIATED_DELIVERABLES_MODAL = 'CONTROL_DEVICE_ASSOCIATED_DELIVERABLES_MODAL';

export const MISSING_USER_ROLE_WARNING_MODAL = 'MISSING_USER_ROLE_WARNING_MODAL';

export const DEVICE_ACTION_EDITOR_MODAL = 'DEVICE_ACTION_EDITOR_MODAL';
export const DEVICE_ACTION_EDITOR_NEO_MODAL = 'DEVICE_ACTION_EDITOR_NEO_MODAL';
export const DEVICE_ACTION_DELETE_MODAL = 'DEVICE_ACTION_DELETE_MODAL';

export const DISTRO_EDITOR_MODAL = 'DISTRO_EDITOR_MODAL';
export const DISTRO_DELETE_MODAL = 'DISTRO_DELETE_MODAL';

export const COPY_SYS_PARAM_DEFINITIONS_MODAL = 'COPY_SYS_PARAM_DEFINITIONS_MODAL';
export const IMPORT_SYS_PARAM_DEFINITIONS_MODAL = 'IMPORT_SYS_PARAM_DEFINITIONS_MODAL';

export const SYS_PARAM_DEFINITION_EDITOR_MODAL = 'SYS_PARAM_DEFINITION_EDITOR_MODAL';
export const SYS_PARAM_DEFINITION_DELETE_MODAL = 'SYS_PARAM_DEFINITION_DELETE_MODAL';

export const SYS_PARAM_SYSTEM_VALUE_EDITOR_MODAL = 'SYS_PARAM_SYSTEM_VALUE_EDITOR_MODAL';
export const SYS_PARAM_SYSTEM_VALUE_DELETE_MODAL = 'SYS_PARAM_SYSTEM_VALUE_DELETE_MODAL';

export const SERVICE_LOG_LEVEL_MODAL = 'SERVICE_LOG_LEVEL_MODAL';
export const SERVICE_LOG_LEVEL_NEO_MODAL = 'SERVICE_LOG_LEVEL_NEO_MODAL';

export const PACKAGE_EDITOR_MODAL = 'PACKAGE_EDITOR_MODAL';
export const PACKAGE_DELETE_MODAL = 'PACKAGE_MODAL';
export const PACKAGE_DELIVERABLE_EDITOR_MODAL = 'PACKAGE_DELIVERABLE_EDITOR_MODAL';
export const PACKAGE_DELIVERABLE_DELETE_MODAL = 'PACKAGE_DELIVERABLE_DELETE_MODAL';

export const FILE_EDITOR_MODAL = 'FILE_EDITOR_MODAL';
export const FILE_DELETE_MODAL = 'FILE_MODAL';
export const FILE_DELIVERABLE_EDITOR_MODAL = 'FILE_DELIVERABLE_EDITOR_MODAL';
export const FILE_DELIVERABLE_DELETE_MODAL = 'FILE_DELIVERABLE_DELETE_MODAL';

export const ARTIFACT_EDITOR_MODAL = 'ARTIFACT_EDITOR_MODAL';
export const ARTIFACT_DELETE_MODAL = 'ARTIFACT_DELETE_MODAL';
export const ARTIFACT_FILE_DELETE_MODAL = 'ARTIFACT_FILE_DELETE_MODAL';

export const DELIVERABLE_EDITOR_MODAL = 'DELIVERABLE_EDITOR_MODAL';
export const DELIVERABLE_DELETE_MODAL = 'DELIVERABLE_DELETE_MODAL';
export const DELIVERABLE_FILE_DELETE_MODAL = 'DELIVERABLE_FILE_DELETE_MODAL';
export const DELIVERABLE_PERMISSIONS_MODAL = 'DELIVERABLE_PERMISSIONS_MODAL';

export const DELIVERABLE_EDITOR_MODAL_NEO = 'DELIVERABLE_EDITOR_MODAL_NEO';
export const DELIVERABLE_VERSION_EDITOR_MODAL = 'DELIVERABLE_VERSION_EDITOR_MODAL';
export const DELIVERABLE_DELETE_MODAL_NEO = 'DELIVERABLE_DELETE_MODAL_NEO';
export const DELIVERABLE_VERSION_DELETE_MODAL = 'DELIVERABLE_VERSION_DELETE_MODAL';
export const DELIVERABLE_FILE_DELETE_MODAL_NEO = 'DELIVERABLE_FILE_DELETE_MODAL_NEO';
export const DELIVERABLE_PERMISSIONS_MODAL_NEO = 'DELIVERABLE_PERMISSIONS_MODAL_NEO';

export const GROUP_EDITOR_MODAL = 'GROUP_EDITOR_MODAL';
export const GROUP_DELETE_MODAL = 'GROUP_DELETE_MODAL';

export const UPDATE_EVENT_REPORT_MODAL = 'UPDATE_EVENT_REPORT_MODAL';

export const JIRA_ISSUE_EDITOR_MODAL = 'JIRA_ISSUE_EDITOR_MODAL';
