import {
    SHOW_DELIVERABLE_FILE_DELETION_DIALOG,
} from '~/features/deliverables/actions/deliverableActions';

export const UPLOAD_DELIVERABLE_FILE = 'UPLOAD_DELIVERABLE_FILE_NEO';
export const FETCH_DELIVERABLE_FILES = 'FETCH_DELIVERABLE_FILES_NEO';
export const DELETE_DELIVERABLE_FILE = 'FETCH_DELIVERABLE_FILE_NEO';
export const DOWNLOAD_DELIVERABLE_FILE = 'DOWNLOAD_DELIVERABLE_FILE_NEO';

export function showDeliverableFileDeletionDialog(payload) {
    return {
        type: SHOW_DELIVERABLE_FILE_DELETION_DIALOG,
        payload,
    };
}

export const uploadDeliverableFile = (context, deliverableId, deliverableVersion, deliverableType, file) => {
    return {
        type: UPLOAD_DELIVERABLE_FILE,
        payload: {
            context,
            deliverableId,
            deliverableVersion,
            deliverableType,
            file,
        },
    };
};

export const fetchDeliverableFiles = ({ context, deliverableId, deliverableType, page }) => {
    return {
        type: FETCH_DELIVERABLE_FILES,
        payload: {
            context,
            deliverableId,
            deliverableType,
            page,
        },
    };
};

export const deleteDeliverableFile = (deliverableId, fileName) => {
    return {
        type: DELETE_DELIVERABLE_FILE,
        payload: {
            deliverableId,
            fileName,
        },
    };
};

export const downloadDeliverableFile = (deliverableId, fileName) => {
    return {
        type: DOWNLOAD_DELIVERABLE_FILE,
        payload: {
            deliverableId,
            fileName,
        },
    };
};
