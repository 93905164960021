export const TBM3 = 'TBM3';
export const VCM = 'VCM';
export const CM4 = 'CM4';

export const deviceTypes = {
    [TBM3]: {
        id: TBM3,
        label: 'intl-msg:deviceType.TBM3.label',
        tooltip: 'intl-msg:deviceType.TBM3.tooltip',
        className: 'label-info',
    },
    [VCM]: {
        id: VCM,
        label: 'intl-msg:deviceType.VCM.label',
        tooltip: 'intl-msg:deviceType.VCM.tooltip',
        className: 'label-info',
    },
    [CM4]: {
        id: CM4,
        label: 'intl-msg:deviceType.CM4.label',
        tooltip: 'intl-msg:deviceType.CM4.tooltip',
        className: 'label-info',
    },
};
