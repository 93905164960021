import React from 'react';

import FileChip from '~/features/deliverables/components/FileChip';
import FileReleaseChip from '~/features/deliverables/components/FileReleaseChip';
import ReleaseState from '~/features/base/components/ReleaseState';
import FieldOrNoData from '~/features/base/components/FieldOrNoData';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';

export const fileColumnDescriptors = [{
    id: 'fileId',
    field: 'fileId',
    label: 'intl-msg:fileId',
    format: field => <FileChip fileId={field}/>,
}, {
    id: 'description',
    field: 'description',
    label: 'intl-msg:description',
    className: 'hidden-xs hidden-ls',
    format: field => <FieldOrNoData field={field}/>,
}];

export const fileDeliverableColumnDescriptors = [{
    id: 'fileId',
    field: 'fileId',
    label: 'intl-msg:fileId',
    format: field => <FileChip fileId={field}/>,
}, {
    id: 'fileVersion',
    field: 'fileVersion',
    label: 'intl-msg:fileVersion',
    format: (field, item) => (
        <span className='white-space-nowrap'>
            <FileReleaseChip fileId={item.fileId}
                             fileVersion={field} className='margin-right-15'/>
            <ReleaseState releaseState={item.releaseState}/>
        </span>
    ),
}, {
    id: 'description',
    field: 'description',
    label: 'intl-msg:description',
    className: 'hidden-xs hidden-ls',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'downloadUrl',
    field: 'downloadUrl',
    label: 'intl-msg:downloadUrl',
    format: field => <FieldOrNoData field={field}/>,
}];

export const fileVersionsColumnDescriptors = [{
    id: 'fileId',
    field: 'fileId',
    label: 'intl-msg:fileId',
    format: field => <FileChip fileId={field}/>,
}, {
    id: 'fileVersion',
    field: 'fileVersion',
    label: 'intl-msg:fileVersion',
    format: (field, item) => [
        <FileReleaseChip fileId={item.fileId} fileVersion={toShortSemanticVersion(field)}/>,
        item.eligibleFileVersion ? [
            <span className='margin-right-5'>→</span>,
            <FileReleaseChip fileId={item.fileId} fileVersion={toShortSemanticVersion(item.eligibleFileVersion)}/>,
        ] : null,
    ],
}];
