import omit from 'lodash/fp/omit';
import size from 'lodash/fp/size';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { Component } from 'react';

import DistroVersionInputFormItem from '~/features/base/components/forms/DistroVersionInputFormItem';
import SemanticVersionInputFormItem from '~/features/base/components/forms/SemanticVersionInputFormItem';
import SemanticVersionRangeInputFormItem from '~/features/base/components/forms/SemanticVersionRangeInputFormItem';
import HwVariantPrefixFormItem from '~/features/base/components/forms/HwVariantPrefixFormItem';
import ActivatedFormItem from '~/features/base/components/forms/ActivatedFormItem';
import VehicleVariantsFormItem from '~/features/base/components/forms/VehicleVariantsFormItem';
import { toSemanticVersion, toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import { allVehicleVariants } from '~/features/vehicleVariants/constants/vehicleVariantsConstants';
import HardwareVariantsFormItem from '~/features/base/components/forms/HardwareVariantsFormItem';
import { connect } from 'react-redux';
import { hwVariantsSelector } from '~/features/hwVariants/selectors/hwVariantsSelectors';
import { fetchHwVariants } from '~/features/hwVariants/actions/hwVariantsActions';
import VINListFormItem from '~/features/base/components/forms/VINListFormItem';
import InstalledDeliverablesFormGroup from '~/features/groups/components/InstalledDeliverablesFormGroup';
import {
    appsDependenciesSelector,
    deliverableIdEntitiesSelector, groupsDependenciesSelector,
} from '~/features/deliverables/selectors/deliverableSelectors';

export class GroupCriteriaEditorForm extends Component {
    onDistroVersionChange = (distroVersion) => {
        const { autoGroupCriteria } = this.props;
        if (distroVersion) {
            this.props.onChange({
                ...autoGroupCriteria,
                distroVersion,
            });
        } else {
            this.props.onChange(omit(['distroVersion'], autoGroupCriteria));
        }
    };
    onBaseSwVersionChange = (baseSoftwareVersion) => {
        const { autoGroupCriteria } = this.props;
        if (baseSoftwareVersion) {
            this.props.onChange({
                ...autoGroupCriteria,
                baseSoftwareVersion,
            });
        } else {
            this.props.onChange(omit(['baseSoftwareVersion'], autoGroupCriteria));
        }
    };
    onBaseSwVersionChange2 = ({ value, isValid }) => {
        const { autoGroupCriteria } = this.props;
        this.props.onChange({
            ...autoGroupCriteria,
            baseSoftwareVersion: value ? toSemanticVersion(value) : undefined,
            baseSoftwareVersionIsValid: isValid,
        });
    };
    onMdmProtocolVersionChange = (mdmProtocolVersion) => {
        const { autoGroupCriteria } = this.props;
        if (mdmProtocolVersion) {
            this.props.onChange({
                ...autoGroupCriteria,
                mdmProtocolVersion,
            });
        } else {
            this.props.onChange(omit(['mdmProtocolVersion'], autoGroupCriteria));
        }
    };
    onMdmcAppVersionRangeChange = (mdmcAppVersionRange) => {
        const { autoGroupCriteria } = this.props;
        if (mdmcAppVersionRange.minValue || mdmcAppVersionRange.maxValue) {
            this.props.onChange({
                ...autoGroupCriteria,
                mdmcAppVersionRange,
            });
        } else {
            this.props.onChange(omit(['mdmcAppVersionRange'], autoGroupCriteria));
        }
    };

    onInstalledDeliverablesChange = (installedDeliverables) => {
        const { autoGroupCriteria } = this.props;
        this.props.onChange({
            ...autoGroupCriteria,
            installedDeliverables,
        });
    };

    onHwVariantPrefixChange = ({ hwVariantPrefix }) => {
        const { autoGroupCriteria } = this.props;
        if (!size(hwVariantPrefix)) {
            this.props.onChange(omit(['hwVariantPrefix'], autoGroupCriteria));
        } else {
            this.props.onChange({
                ...autoGroupCriteria,
                hwVariantPrefix,
            });
        }
    };
    onHwVariantListChange = ({ hwVariantList }) => {
        const { autoGroupCriteria } = this.props;
        if (!size(hwVariantList)) {
            this.props.onChange(omit(['hwVariantList'], autoGroupCriteria));
        } else {
            this.props.onChange({
                ...autoGroupCriteria,
                hwVariantList,
            });
        }
    };
    onVehicleVariantFilterChange = (vehicleVariants) => {
        const { autoGroupCriteria } = this.props;
        if (!size(vehicleVariants.vehicleVariantsWhitelist)) {
            const a = omit(['vehicleVariants'], autoGroupCriteria);
            this.props.onChange(a);
        } else {
            this.props.onChange({
                ...autoGroupCriteria,
                vehicleVariants: vehicleVariants.vehicleVariantsWhitelist,
            });
        }
    };
    onInCustomerFleetChange = ({ inCustomerFleet }) => {
        const { autoGroupCriteria } = this.props;
        if (inCustomerFleet === undefined) {
            this.props.onChange(omit(['inCustomerFleet'], autoGroupCriteria));
        } else {
            this.props.onChange({
                ...autoGroupCriteria,
                inCustomerFleet,
            });
        }
    };
    onSerialNumbersChange = ({ serialNumbers, isValid }) => {
        const { autoGroupCriteria } = this.props;
        if (!size(serialNumbers)) {
            this.props.onChange(omit(['serialNumbers'], autoGroupCriteria));
        } else {
            this.props.onChange({
                ...autoGroupCriteria,
                serialNumbers,
                isValidSerialNumbers: isValid,
            });
        }
    };

    onVinListChange = ({vinList}) => {
        const {autoGroupCriteria} = this.props;
        if (!size(vinList)) {
            this.props.onChange(omit(['vinList'], autoGroupCriteria));
        } else {
            this.props.onChange({
                ...autoGroupCriteria,
                vinList
            });
        }
    };

    render() {
        const { autoGroupCriteria, hwVariants, deliverables } = this.props;

        return [
            <DistroVersionInputFormItem value={autoGroupCriteria.distroVersion}
                                        isClearable onChange={this.onDistroVersionChange}/>,
            <SemanticVersionInputFormItem value={autoGroupCriteria.baseSoftwareVersion}
                                          isClearable onChange={this.onBaseSwVersionChange}/>,
            <SemanticVersionRangeInputFormItem range={autoGroupCriteria.mdmcAppVersionRange}
                                               isClearable onChange={this.onMdmcAppVersionRangeChange}/>,
            <InstalledDeliverablesFormGroup values={autoGroupCriteria.installedDeliverables} deliverables={deliverables}
                                            onChange={this.onInstalledDeliverablesChange}/>,
            <HwVariantPrefixFormItem value={autoGroupCriteria.hwVariantPrefix}
                                     onChange={this.onHwVariantPrefixChange}/>,
            <HardwareVariantsFormItem
                whiteListedHardwareVariants={autoGroupCriteria.hwVariantList}
                hardwareVariants={hwVariants}
                onChange={this.onHwVariantListChange}/>,

            <VehicleVariantsFormItem
                whiteListedVehicleVariants={autoGroupCriteria.vehicleVariants || []}
                vehicleVariants={allVehicleVariants}
                onChange={this.onVehicleVariantFilterChange}/>,

            <VINListFormItem label='intl-msg:vinListAssigned'
                             vinList={autoGroupCriteria.vinList}
                             onChange={this.onVinListChange}/>,

            <ActivatedFormItem value={autoGroupCriteria.inCustomerFleet}
                               onChange={this.onInCustomerFleetChange}/>,
        ];
    }

    componentWillMount() {
        this.props.fetchHwVariants();
    }
}

const mapStateToProps = (state) => ({
    hwVariants: hwVariantsSelector(state),
    deliverables: groupsDependenciesSelector(state),
});

const mapDispatchToProps = dispatch => ({
    fetchHwVariants: () => {
        dispatch(fetchHwVariants());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupCriteriaEditorForm);

GroupCriteriaEditorForm.defaultProps = {
    // props
    apps:[],
    // functions
    onChange: noop,
};

GroupCriteriaEditorForm.propTypes = {
    // props
    autoGroupCriteria: PropTypes.object,
    apps: PropTypes.array,
    // functions
    onChange: PropTypes.func,
};
