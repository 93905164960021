import { connect } from 'react-redux';
import { followRoute } from '~/features/base/actions/ui/routeActions';

import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import {
    showFileDeletionDialog,
    showFileEditorDialog,
} from '~/features/deliverables/features/files/actions/fileEditorActions';

import DeliverableDetails from '~/features/deliverables/components/DeliverableDetails';
import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';

export const mapStateToProps = (state) => {
    return {
        pathname: pathnameSelector(state),
        search: searchSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        triggerDataFetcher: () => {
            dispatch(triggerDataFetcher());
        },
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        onShowFileEditorDialog: (options) => {
            dispatch(showFileEditorDialog(options));
        },
        onEditFile: (options) => {
            dispatch(showFileEditorDialog(options));
        },
        onDeleteFile: (options) => {
            dispatch(showFileDeletionDialog(options));
        },
        followRoute: (options) => {
            dispatch(followRoute(options));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableDetails);
