import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';


import { fetchUser } from '~/features/user/actions/userActions';

import { permissionsFetchedSelector } from '~/features/user/selectors/permissionSelectors';
import { userLoadedSelector } from '~/features/base/selectors/errorsSelector';
import PreviewBanner from '~/features/base/components/PreviewBanner';
import RenderingError from '~/features/base/components/RenderingError';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';

/**
 * Container for a device management submodule
 */
export class SubModuleContainer extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: undefined,
        };
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error,
        };
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <RenderingError error={this.state.error}/>
            );
        }

        const content = this.renderContent();
        return (
            <div className='device-management-submodule'>
                {content}
                <PreviewBanner/>
            </div>
        );
    }

    renderContent() {
        const { userLoaded, permissionsFetched, children } = this.props;
        if (!userLoaded) {
            return (
                <div className='bs-callout bs-callout-danger'>
                    <h4><FormattedMessage id='intl-msg:error.serviceNotReachable'/></h4>
                    <p><FormattedMessage id='intl-msg:error.serviceNotReachableDetails'/></p>
                </div>
            );
        }
        if (!permissionsFetched) {
            return <div className='col-md-12'><Spinner/></div>;
        }
        return children;
    }

    componentWillMount() {
        this.fetchData();
    }

    fetchData() {
        if (!this.props.permissionsFetched) {
            //console.log('SubModuleContainer', 'fetchUser');
            this.props.fetchUser();
        }
    }
}

export const mapStateToProps = (state) => {
    return {
        userLoaded: userLoadedSelector(state),
        permissionsFetched: permissionsFetchedSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        fetchUser: () => {
            dispatch(fetchUser());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubModuleContainer);

SubModuleContainer.defaultProps = {
    // props
    userLoaded: true,
    permissionsFetched: false,
    // functions
    fetchUser: noop,
};

SubModuleContainer.propTypes = {
    // props
    userLoaded: PropTypes.bool,
    permissionsFetched: PropTypes.bool,
    // functions
    fetchUser: PropTypes.func,
};
