import { call, put, select, takeLatest } from 'redux-saga/effects';
import { GROUP_DELETE_MODAL, GROUP_EDITOR_MODAL } from '~/features/base/constants/modals';

import { showModal } from '~/features/base/actions/ui/modalsActions';
import {
    changeGroupEditor,
    FETCH_SERIAL_NUMBERS_AND_OPEN_EDITOR_OF_GROUP,
    resetGroupEditor,
    SHOW_GROUP_DELETION_DIALOG,
    SHOW_GROUP_EDITOR_DIALOG, showGroupEditorDialog,
} from '~/features/groups/actions/groupEditorActions';
import { groupWithSerialNumbersSelector } from '~/features/groups/selectors/groupSelectors';
import { doFetchGroupSaga } from '~/features/groups/sagas/groupSaga';
import { doFetchSerialNumbersOfGroupSaga } from '~/features/groups/sagas/groupDevicesAssignedSaga';

export function* doPrepareForNewGroupSaga(/*payload*/) {
    yield put(changeGroupEditor({
        isNew: true,
    }));
}

export function* doPrepareForExistingGroupSaga(payload) {
    const group = payload;
    yield put(changeGroupEditor({
        isNew: false,
        ...group,
        name: group.name || '',
        description: group.description || '',
        isValidSerialNumbers: true,
    }));
}

export function* doShowGroupEditorDialogSaga(action) {
    yield put(resetGroupEditor());
    if (action.payload.isNew) {
        yield call(doPrepareForNewGroupSaga, action.payload);
    } else {
        yield call(doPrepareForExistingGroupSaga, action.payload);
    }
    yield put(showModal({
        modalType: GROUP_EDITOR_MODAL,
        modalProps: {},
    }));
}

export function* doShowGroupDeletionDialogSaga(action) {
    yield put(resetGroupEditor());
    const group = action.payload;
    yield put(changeGroupEditor({
        ...group,
    }));
    yield put(showModal({
        modalType: GROUP_DELETE_MODAL,
        modalProps: {},
    }));
}

export function* doFetchSerialNumbersAndOpenEditorOfGroupSaga(action) {
    const { groupName } = action;
    yield call(doFetchGroupSaga, action);
    yield call(doFetchSerialNumbersOfGroupSaga, action);
    const group = yield select(groupWithSerialNumbersSelector, { groupName });
    yield put(showGroupEditorDialog(
        {
            isNew: false,
            ...group,
        }
    ));
}

export function* showGroupEditorDialogSaga() {
    yield takeLatest(SHOW_GROUP_EDITOR_DIALOG, doShowGroupEditorDialogSaga);
}

export function* showGroupDeletionDialogSaga() {
    yield takeLatest(SHOW_GROUP_DELETION_DIALOG, doShowGroupDeletionDialogSaga);
}

export function* fetchSerialNumbersAndOpenEditorOfGroupSaga() {
    yield takeLatest(FETCH_SERIAL_NUMBERS_AND_OPEN_EDITOR_OF_GROUP, doFetchSerialNumbersAndOpenEditorOfGroupSaga);
}
