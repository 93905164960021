import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import {
    ENTITY_CONTROL_DEVICE_OPERATION,
    ENTITY_CONTROL_DEVICE_ROLLOUT_STATES,
} from '~/features/base/constants/entities';

import { FETCH_CONTROL_DEVICE_OPERATIONS } from '~/features/devices/actions/controlDeviceOperationsActions';

import { mergeEntities, resetEntity } from '~/features/higherorder/actions/entityActions';
import { mergePage } from '~/features/higherorder/actions/paginationActions';

import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';

import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';

import { doHandleErrorSaga, getHTTP, postHTTP } from '~/features/base/sagas/sagaUtil';

import { stringifyToQuery } from '~/features/base/utils/query';

import { parseControlDeviceOperations } from '~/features/devices/transforms/parseControlDeviceOperations';
import { finishedAction } from '~/features/higherorder/transforms/actionTransforms';
import { FETCH_CONTROL_DEVICE_ROLLOUT_STATES } from '~/features/devices/actions/ui/controlDeviceDetailsActions';
import { startLoading } from '~/features/higherorder/actions/loadingActions';

export function* getControlDeviceURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/control-device`;
}

export function* doFetchControlDeviceOperationsSaga(action) {
    try {
        const url = yield call(getControlDeviceURL);
        const { searchCriteria } = action.payload;
        const { serialNumber, page, size } = searchCriteria;
        const query = stringifyToQuery({
            page,
            size,
        });
        const { content, ...pagination } = yield call(postHTTP, `${url}/${serialNumber}/device-operations?${query}`,
            JSON.stringify(searchCriteria));
        const parsedResponse = { content: parseControlDeviceOperations(content), ...pagination };
        yield put(mergeEntities(parsedResponse.content, {
            entityName: ENTITY_CONTROL_DEVICE_OPERATION,
            serialNumber: serialNumber,
        }));
        yield put(mergePage({
            entityName: ENTITY_CONTROL_DEVICE_OPERATION,
            serialNumber: serialNumber,
        }, parsedResponse));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchControlDeviceRolloutStatesSaga(action) {
    yield put(startLoading(ENTITY_CONTROL_DEVICE_ROLLOUT_STATES));
    try {
        const url = yield call(getControlDeviceURL);
        const { serialNumber } = action.payload;
        const query = stringifyToQuery(action.payload);
        let response = yield call(getHTTP, `${url}/${serialNumber}/rollout-progress?${query}`);
        response = response.map(rs => ({ ...rs, id: `${rs.deliverableId}${rs.lastUpdated}` }));
        yield put(resetEntity(response, { entityName: ENTITY_CONTROL_DEVICE_ROLLOUT_STATES, serialNumber }));
        yield put(mergeEntities(response, { entityName: ENTITY_CONTROL_DEVICE_ROLLOUT_STATES, serialNumber }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* fetchControlDeviceOperationsSaga() {
    yield takeLatest(FETCH_CONTROL_DEVICE_OPERATIONS, doFetchControlDeviceOperationsSaga);
}

export function* fetchControlDeviceRolloutStatesSaga() {
    yield takeLatest(FETCH_CONTROL_DEVICE_ROLLOUT_STATES, doFetchControlDeviceRolloutStatesSaga);
}
