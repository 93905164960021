export const FETCH_PACKAGE_COUNT = 'FETCH_PACKAGE_COUNT';
export const FETCH_PACKAGES = 'FETCH_PACKAGES';


export const fetchPackageCount = () => {
    return {
        type: FETCH_PACKAGE_COUNT,
    };
};

export const fetchPackages = () => {
    return {
        type: FETCH_PACKAGES,
    };
};
