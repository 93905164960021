import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';
import merge from 'lodash/fp/merge';

import { detectFeatureToggles } from './setup/featureToggles';

import { localAuthConfig } from './env/env.localauth';
import { localConfig } from './env/env.local';
import { localProdConfig } from './env/env.localprod';
import { localProdLATAMConfig } from './env/env.localprod.latam';
import { productionEUConfig } from './env/env.production.eu';
import { productionLATAMConfig } from './env/env.production.latam';
import { productionTESTConfig } from './env/env.production.test';
import { testConfig } from './env/env.test';
import { localTestConfig } from '~/env/env.localTest';

export const getConfig = get('config');

export const getLoginClientId = get('config.login.clientId');

export const getLoginRedirectUri = get('config.login.redirectUri');

export const getProdEnvironment = host => {
    if (includes('device-ota-updates-test', host)) {
        return productionTESTConfig;
    }
    if (includes('admin-latam', host)) {
        return productionLATAMConfig;
    }
    return productionEUConfig;
};
export const isProdEnvironment = host => {
    return includes('admin', host);
};

export const getRuntimeConfig = (window, env = {}) => {
    const host = window.location.host;
    const defaultConfig = {
        backend: {},
        homeRoute: `${window.location.origin}`,
        id: 'env.stub',
        login: {
            oauthScope: [
                'openid',
                'profile',
                'email',
            ],
            mockLocale: 'en-GB',
            preventRedirect: false,
        },
        sentryToken: 'https://6e5484a7f1454a408493d98937cf4051@sentry.io/295340',
    };

    let config = localConfig;

    if (env.isRunningOnProd) {
        config = getProdEnvironment(host);
    }
    if (env.featureToggles.enforcedEnv === 'localauth') {
        config = localAuthConfig;
    }
    if (env.featureToggles.enforcedEnv === 'localprod') {
        config = localProdConfig;
    }
    if (env.featureToggles.enforcedEnv === 'localprodlatam') {
        config = localProdLATAMConfig;
    }
    if (env.featureToggles.enforcedEnv === 'production') {
        config = getProdEnvironment(host);
    }
    if (env.featureToggles.enforcedEnv === 'localtest') {
        config = localTestConfig;
    }
    if (env.isTestEnv) {
        config = testConfig;
    }

    return merge(merge({}, defaultConfig), config);
};

export const configureEnv = (window, process) => {
    const { location: { host } } = window;
    const { env: { NODE_ENV } } = process;

    // TODO: Change the URLs to the ones your service is using
    const isProdEnv = isProdEnvironment(host);
    const isStagingEnv = isProdEnvironment(host);
    const isTestEnv = NODE_ENV === 'test';
    const isLocalEnv = !isProdEnv && !isStagingEnv && !isTestEnv;

    const shouldSendMetrics = isProdEnv || isStagingEnv;

    const isProdBuild = import.meta.env.PROD;
    const isRunningOnProd = isProdEnvironment(host);
    const isRunningOnStaging = isProdEnvironment(host);
    const runsInSandbox = isProdBuild && !isRunningOnStaging && !isRunningOnProd;

    const shouldRestrictLog = import.meta.env.PROD;
    const shouldUseConsoleLogger = false; //NODE_ENV !== 'test' && !shouldRestrictLog;

    const featureToggles = detectFeatureToggles(window);

    const env = {
        featureToggles,
        isLocalEnv,
        isProdBuild,
        isRunningOnProd,
        isRunningOnStaging,
        isTestEnv,
        runsInSandbox,
        runtimeConfig: {},
        shouldRestrictLog,
        shouldUseConsoleLogger,
        shouldSendMetrics,
    };

    env.runtimeConfig = getRuntimeConfig(window, env);

    return env;
};

export const env = configureEnv(window, process);

if (env.featureToggles.tracing) {
    // eslint-disable-next-line no-console
    console.log(env);
}
