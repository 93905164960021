import clone from 'lodash/fp/clone';
import get from 'lodash/fp/get';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import min from 'lodash/fp/min';
import noop from 'lodash/fp/noop';
import size from 'lodash/fp/size';
import toPairs from 'lodash/fp/toPairs';
import without from 'lodash/fp/without';

import classNames from 'classnames';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';


import { STRING } from '~/features/sysParams/constants/sysParamTypes';
import { createSysParamEnumerationTypesOptions } from '~/features/sysParams/constants/numericTypes';

import SysParamDefinitionEnumerationValueForm
    from '~/features/sysParams/components/SysParamDefinitionEnumerationValueForm';
import { selectionByValue } from '~/features/base/utils/selectUtils';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import NumberInput from '@rio-cloud/rio-uikit/lib/es/NumberInput';

const canDelete = model => size(model.enumValues) === 2;

/**
 * System parameter definition enumeration editor form
 */
export class SysParamDefinitionEnumerationEditorForm extends PureComponent {
    constructor(props) {
        super(props);
    }

    onAddRow = () => {
        this.props.changeSysParamDefinitionEditor({
            enumValues: this.props.model.enumValues.concat([{
                description: '',
                value: '',
            }]),
        });
    }

    onRemoveRow = (key) => {
        const model = this.props.model;
        if (!canDelete) {
            return;
        }
        const newEnumValues = without([model.enumValues[key]], model.enumValues);
        const newEnumDefaultValueIndex = min([model.enumDefaultValueIndex, size(newEnumValues) - 1]);
        // Check whether model.enumDefaultValueIndex is now out of bounds
        this.props.changeSysParamDefinitionEditor({
            enumValues: newEnumValues,
            enumDefaultValueIndex: newEnumDefaultValueIndex,
        });
    }

    onNumericTypeSelect = (numericType) => {
        const key = numericType.value;
        let enumValues;
        if (key === STRING) {
            enumValues = map(enumValue => ({
                ...enumValue,
                value: enumValue.value.toString(),
            }), this.props.model.enumValues);
        } else {
            enumValues = map(enumValue => ({
                ...enumValue,
                value: parseInt(enumValue.value, 10),
            }), this.props.model.enumValues);
        }

        this.props.changeSysParamDefinitionEditor({
            enumParameterDefinition: {
                ...this.props.model.enumParameterDefinition,
                numericType: key,
            },
            enumValues,
        });
    }

    onEnumerationValueChanged = (value, key) => {
        const model = this.props.model;
        const enumValues = clone(model.enumValues);
        enumValues[key] = {
            ...model.enumValues[key],
            value: value,
        };
        this.props.changeSysParamDefinitionEditor({
            enumValues: enumValues,
        });
    }

    onEnumerationDescriptionChange = (event, key) => {
        const model = this.props.model;
        const enumValues = clone(model.enumValues);
        enumValues[key] = {
            ...model.enumValues[key],
            description: event.target.value,
        };
        this.props.changeSysParamDefinitionEditor({
            enumValues: enumValues,
        });
    }

    onDefaultValueSelect = (key) => {
        this.props.changeSysParamDefinitionEditor({
            enumDefaultValueIndex: key,
        });
    }

    render() {
        const model = this.props.model;
        const numericType = get('enumParameterDefinition.numericType', model);
        const optionsWithSelection = selectionByValue(numericType)(createSysParamEnumerationTypesOptions());
        return (
            <div>
                <div className='row'>
                    <div className='form-group-type form-group col-xs-2 col-sm-2 col-md-2'>
                        <label className='control-label'>
                            <FormattedMessage id='intl-msg:dataType' />
                        </label>
                        <Select options={optionsWithSelection}
                            onChange={this.onNumericTypeSelect} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xs-4 col-sm-4 col-md-4'>
                        <label className='control-label'>
                            <FormattedMessage id='intl-msg:value' />
                        </label>
                    </div>
                    <div className='col-xs-4 col-sm-4 col-md-4'>
                        <label className='control-label'>
                            <FormattedMessage id='intl-msg:description' />
                        </label>
                    </div>
                    <div className='col-xs-2 col-sm-2 col-md-2'>
                    </div>
                </div>
                {this.renderEnumValues(model)}
                <div className='row'>
                    <div className='col-md-12'>
                        <a className='btn btn-primary'
                            onClick={this.onAddRow}>
                            <span className='rioglyph rioglyph-plus' aria-hidden='true'></span>
                        </a>
                    </div>
                </div>
                {this.renderDefaultValue(model)}
            </div>
        );
    }

    renderInputValue = (enumValue, key, numericType) => {
        if (numericType === STRING) {
            return (
                <FormattedMessage id='intl-msg:value.prompt'>
                    {placeHolder =>
                        <input type='text' className='form-control'
                            value={enumValue.value}
                            placeholder={placeHolder}
                            onChange={event => this.onEnumerationValueChanged(event.target.value, key)} />
                    }
                </FormattedMessage>
            );
        }
        return (
            <NumberInput min={0} value={parseInt(enumValue.value, 10)}
                onValueChanged={value => this.onEnumerationValueChanged(value, key)}/>
        );
    }

    renderEnumValues = (model) => {
        const numericType = get('enumParameterDefinition.numericType', model);

        return flow(
            toPairs,
            map(([key, enumValue]) => {
                return (
                    <div className='row enum-value' key={key}>
                        <div className='col-xs-4 col-sm-4 col-md-4'>
                            {this.renderInputValue(enumValue, key, numericType)}
                        </div>
                        <div className='col-xs-4 col-sm-4 col-md-4'>
                            <FormattedMessage id='intl-msg:descriptionOptional'>
                                {placeHolder =>
                                    <input type='text' className='form-control'
                                        value={enumValue.description}
                                        placeholder={placeHolder}
                                        onChange={event => this.onEnumerationDescriptionChange(event, key)} />
                                }
                            </FormattedMessage>
                        </div>
                        <div className='col-xs-2 col-sm-2 col-md-2'>
                            <button className={classNames('btn btn-default pull-right', { disabled: canDelete(model) })}
                                onClick={() => this.onRemoveRow(key)}>
                                <span className='rioglyph rioglyph-minus' aria-hidden='true'></span>
                            </button>
                        </div>
                    </div>
                );
            }))(model.enumValues);
    }

    renderDefaultValue = (model) => {
        if (model.hasDefault) {
            const value = model.enumDefaultValueIndex >= 0 ?
                model.enumValues[model.enumDefaultValueIndex].value.toString() :
                undefined;
            return (
                <SysParamDefinitionEnumerationValueForm
                    label={'intl-msg:defaultValue'}
                    value={value}
                    onValueSelect={this.onDefaultValueSelect}
                    model={model}/>
            );
        }
    }
}

export default SysParamDefinitionEnumerationEditorForm;

SysParamDefinitionEnumerationEditorForm.defaultProps = {
    // props
    model: {},
    // functions
    changeSysParamDefinitionEditor: noop,
};

SysParamDefinitionEnumerationEditorForm.propTypes = {
    // props
    model: PropTypes.object,
    // functions
    changeSysParamDefinitionEditor: PropTypes.func,
};
