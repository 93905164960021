import noop from 'lodash/fp/noop';

import moment from 'moment';

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';


import { createCustomPresets } from '~/features/base/utils/customPresets';
import DateRangePicker from '@rio-cloud/rio-uikit/lib/es/DateRangePicker';

export class FromToFormItem extends Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onClear = this.onClear.bind(this);
    }

    onChange(startValue, endValue) {
        this.props.onChange({
            from: startValue ? startValue.valueOf() : undefined,
            to: endValue ? endValue.valueOf() : undefined,
        });
    }

    onClear() {
        this.props.onChange({
            from: undefined,
            to: undefined,
        });
    }

    render() {
        const { label, from, to, inputClassName } = this.props;

        const customPresets = createCustomPresets();
        const startValue = from ? moment(parseInt(from, 10)) : null;
        const endValue = to ? moment(parseInt(to, 10)) : null;
        return (
            <div className='form-group'>
                <label className='control-label'>
                    <FormattedMessage id={label} />
                </label>
                <span className='width-100pct'>
                    <DateRangePicker
                        className={inputClassName}
                        hasTimePicker={true}
                        customPresets={customPresets}
                        startValue={startValue}
                        endValue={endValue}
                        onRangeChange={this.onChange}
                        onClear={this.onClear}
                        clearable/>
                </span>
            </div>
        );
    }
}

export default FromToFormItem;

FromToFormItem.defaultProps = {
    // props
    label: 'intl-msg:range',
    // functions
    onChange: noop,
};

FromToFormItem.propTypes = {
    // props
    label: PropTypes.string,
    from: PropTypes.number,
    to: PropTypes.number,
    inputClassName: PropTypes.string,
    // functions
    onChange: PropTypes.func,
};
