import trim from 'lodash/fp/trim';
import noop from 'lodash/fp/noop';

import classNames from 'classnames';

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { ClearableInput } from '@rio-cloud/rio-uikit';

export class DeliverableIdPrefixFormItem extends Component {

    onChange = (text) => {
        const deliverableIdPrefix = trim(text);
        this.props.onChange({
            deliverableIdPrefix: deliverableIdPrefix || undefined,
        });
    };

    render() {
        const { label, value, showHeader } = this.props;
        const cNames = showHeader ? 'form-group' : ['margin-top-5', 'margin-right-0', 'margin-bottom-0',
            'margin-left-0'];
        return (
            <div className={classNames(cNames)}>
                {
                    showHeader && <label className='control-label'>
                        <FormattedMessage id={label}/>
                    </label>
                }
                <span className='input-group width-100pct'>
                    <span className='input-group-addon'>
                        <span className='rioglyph rioglyph-search' aria-hidden='true'></span>
                    </span>
                    <FormattedMessage id='intl-msg:deliverableIdPrefix.prompt'>
                        {placeHolder =>
                            <ClearableInput
                                value={value}
                                placeholder={placeHolder}
                                onChange={this.onChange}
                            />
                        }
                    </FormattedMessage>
                </span>
            </div>

        );
    }
}

export default DeliverableIdPrefixFormItem;

DeliverableIdPrefixFormItem.defaultProps = {
    // props
    value: '',
    label: 'intl-msg:deliverableIdPrefix',
    showHeader: true,
    // functions
    onChange: noop,
};

DeliverableIdPrefixFormItem.propTypes = {
    // props
    value: PropTypes.string,
    label: PropTypes.string,
    showHeader: PropTypes.bool,
    // functions
    onChange: PropTypes.func,
};
