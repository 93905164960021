import { ENTITY_CONTROL_DEVICE_SCOPE_DEVICES } from '~/features/base/constants/entities';

export const FETCH_CONTROL_DEVICE_COUNT = 'FETCH_CONTROL_DEVICE_COUNT';

export const FETCH_TEST_DEVICE_COUNT = 'FETCH_TEST_DEVICE_COUNT';

export const FETCH_BLOCKED_DEVICE_COUNT = 'FETCH_BLOCKED_DEVICE_COUNT';

export const FETCH_WARNING_DEVICE_COUNT = 'FETCH_WARNING_DEVICE_COUNT';

export const FETCH_ERROR_DEVICE_COUNT = 'FETCH_ERROR_DEVICE_COUNT';

export const FETCH_IN_CUSTOMER_FLEET_COUNT = 'FETCH_IN_CUSTOMER_FLEET_COUNT';

export const FETCH_CONTROL_DEVICE_COUNTS_BY_BASE_SW = 'FETCH_CONTROL_DEVICE_COUNTS_BY_BASE_SW';

export const FETCH_CONTROL_DEVICE_COUNTS_BY_DISTRO = 'FETCH_CONTROL_DEVICE_COUNTS_BY_DISTRO';

export const FETCH_CONTROL_DEVICE_COUNTS_BY_HW_VARIANT = 'FETCH_CONTROL_DEVICE_COUNTS_BY_HW_VARIANT';

export const FETCH_CONTROL_DEVICE_COUNTS_BY_BASE_SW_IN_CUSTOMER_FLEET =
    'FETCH_CONTROL_DEVICE_COUNTS_BY_BASE_SW_IN_CUSTOMER_FLEET';

export const FETCH_CONTROL_DEVICE_COUNTS_BY_DISTRO_IN_CUSTOMER_FLEET =
    'FETCH_CONTROL_DEVICE_COUNTS_BY_DISTRO_IN_CUSTOMER_FLEET';

export const FETCH_CONTROL_DEVICE_COUNTS_BY_HW_VARIANT_IN_CUSTOMER_FLEET =
    'FETCH_CONTROL_DEVICE_COUNTS_BY_HW_VARIANT_IN_CUSTOMER_FLEET';

export const FETCH_FILTERED_CONTROL_DEVICES = 'FETCH_FILTERED_CONTROL_DEVICES';

export const FETCH_DELIVERABLE_WHITELISTING = 'FETCH_DELIVERABLE_WHITELISTING';

export const FETCH_CONTROL_DEVICE = 'FETCH_CONTROL_DEVICE';

export const FETCH_CONTROL_DEVICE_WITH_SYS_PARAMS = 'FETCH_CONTROL_DEVICE_WITH_SYS_PARAMS';

export const CREATE_CONTROL_DEVICE = 'CREATE_CONTROL_DEVICE';

export const UPDATE_CONTROL_DEVICE = 'UPDATE_CONTROL_DEVICE';

export const TRIGGER_CHECK_FOR_UPDATE_ON_CONTROL_DEVICE = 'TRIGGER_CHECK_FOR_UPDATE_ON_CONTROL_DEVICE';

export const fetchControlDeviceCount = ({ scope = 'totalCount', searchCriteria }) => {
    return {
        type: FETCH_CONTROL_DEVICE_COUNT,
        scope,
        searchCriteria,
    };
};

export const fetchTestDeviceCount = ({ scope = 'testCount' }) => {
    return {
        type: FETCH_TEST_DEVICE_COUNT,
        scope,
    };
};

export const fetchBlockedDeviceCount = ({ scope = 'blockedCount' }) => {
    return {
        type: FETCH_BLOCKED_DEVICE_COUNT,
        scope,
    };
};

export const fetchWarningDeviceCount = ({ scope = 'warningDeviceCount' }) => {
    return {
        type: FETCH_WARNING_DEVICE_COUNT,
        scope,
    };
};

export const fetchErrorDeviceCount = ({ scope = 'errorDeviceCount' }) => {
    return {
        type: FETCH_ERROR_DEVICE_COUNT,
        scope,
    };
};

export const fetchInCustomerFleetCount = ({ scope = 'inCustomerFleetCount', searchCriteria }) => {
    return {
        type: FETCH_IN_CUSTOMER_FLEET_COUNT,
        scope,
        searchCriteria,
    };
};

export const fetchControlDeviceCountsByBaseSw = ({ scope = 'countsByBaseSw', searchCriteria }) => {
    return {
        type: FETCH_CONTROL_DEVICE_COUNTS_BY_BASE_SW,
        scope,
        searchCriteria,
    };
};

export const fetchControlDeviceCountsByDistro = ({ scope = 'countsByDistro', searchCriteria }) => {
    return {
        type: FETCH_CONTROL_DEVICE_COUNTS_BY_DISTRO,
        scope,
        searchCriteria,
    };
};

export const fetchControlDeviceCountsByHwVariant = ({ scope = 'countsByHwVariant', searchCriteria }) => {
    return {
        type: FETCH_CONTROL_DEVICE_COUNTS_BY_HW_VARIANT,
        scope,
        searchCriteria,
    };
};

export const fetchControlDeviceCountsByBaseSwInCustomerFleet = ({
    scope = 'countsByBaseSwInCustomerFleet',
    searchCriteria,
}) => {
    return {
        type: FETCH_CONTROL_DEVICE_COUNTS_BY_BASE_SW_IN_CUSTOMER_FLEET,
        scope,
        searchCriteria,
    };
};

export const fetchControlDeviceCountsByDistroInCustomerFleet = ({
    scope = 'countsByDistroInCustomerFleet',
    searchCriteria,
}) => {
    return {
        type: FETCH_CONTROL_DEVICE_COUNTS_BY_DISTRO_IN_CUSTOMER_FLEET,
        scope,
        searchCriteria,
    };
};

export const fetchControlDeviceCountsByHwVariantInCustomerFleet = ({
    scope = 'countsByHwVariantInCustomerFleet',
    searchCriteria,
}) => {
    return {
        type: FETCH_CONTROL_DEVICE_COUNTS_BY_HW_VARIANT_IN_CUSTOMER_FLEET,
        scope,
        searchCriteria,
    };
};

export const fetchFilteredControlDevices = ({
    scope = ENTITY_CONTROL_DEVICE_SCOPE_DEVICES,
    page,
    size,
    searchCriteria,
}) => {
    return {
        type: FETCH_FILTERED_CONTROL_DEVICES,
        scope,
        page,
        size,
        searchCriteria,
    };
};

export const fetchDeliverableWhitelisting = ({ whitelisting, page, size }) => {
    return {
        type: FETCH_DELIVERABLE_WHITELISTING,
        payload: {
            whitelisting,
            page,
            size,
        },
    };
};

export const fetchControlDevice = ({ serialNumber }) => {
    return {
        type: FETCH_CONTROL_DEVICE,
        payload: {
            serialNumber,
        },
    };
};

export const fetchControlDeviceWithSysParams = ({ serialNumber }) => {
    return {
        type: FETCH_CONTROL_DEVICE_WITH_SYS_PARAMS,
        payload: {
            serialNumber,
        },
    };
};

export const createControlDevice = (controlDevice) => {
    return {
        type: CREATE_CONTROL_DEVICE,
        payload: controlDevice,
    };
};

export const updateControlDevice = (controlDevice) => {
    return {
        type: UPDATE_CONTROL_DEVICE,
        payload: controlDevice,
    };
};

export const triggerCheckForUpdateOnControlDevice = ({ serialNumber }) => {
    return {
        type: TRIGGER_CHECK_FOR_UPDATE_ON_CONTROL_DEVICE,
        payload: {
            serialNumber,
        },
    };
};
