import noop from 'lodash/fp/noop';

import moment from 'moment';

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import DatePicker from '@rio-cloud/rio-uikit/lib/es/DatePicker';

export class FromFormItem extends Component {
    onChange = (value) => {
        this.props.onChange({
            value: value ? value.valueOf() : undefined,
        });
    };

    render() {
        const { label, value } = this.props;
        return (
            <div className='form-group'>
                <label className='control-label'>
                    <FormattedMessage id={label}/>
                </label>
                <span className='width-100pct'>
                    <DatePicker
                        dropup={true}
                        closeOnSelect={false}
                        value={value ? moment(parseInt(value, 10)) : null}
                        onChange={this.onChange}/>
                </span>
            </div>
        );
    }
}

export default FromFormItem;

FromFormItem.defaultProps = {
    // props
    label: 'intl-msg:datetime',
    // functions
    onChange: noop,
};

FromFormItem.propTypes = {
    // props
    label: PropTypes.string,
    value: PropTypes.number,
    // functions
    onChange: PropTypes.func,
};
