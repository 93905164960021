import React, { Component } from 'react';
import { connect } from 'react-redux';
import map from 'lodash/fp/map';
import onClickOutside from 'react-onclickoutside';
import {
    hideDeviceDetailsSidebar,
    toggleDeviceDetailsSidebar,
} from '~/features/devices/actions/ui/controlDeviceDetailsActions';
import RenderingError from '~/features/base/components/RenderingError';
import noop from 'lodash/fp/noop';
import PropTypes from 'prop-types';
import { showDeviceDetailsSidebarSelector } from '~/features/devices/selectors/controlDeviceSelectors';
import Sidebar from '@rio-cloud/rio-uikit/lib/es/Sidebar';

export class DeviceDetailsSidebarContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: undefined,
        };
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error,
        };
    }

    handleClickOutside = evt => {
        const selectedRow = evt.target.closest('.tableRow');
        const selectedDialog = evt.target.closest('.device-editor-dialog') || evt.target.closest(
            '.device-action-editor-dialog') || evt.target.closest('.device-adapt-order-dialog');
        const isRowClick = selectedRow !== null;
        const isDialogClick = selectedDialog !== null;
        if (!isRowClick && !isDialogClick) {
            this.props.hideDeviceDetailsSidebar();
            const selectedRows = document.getElementsByClassName('tableRow active');
            map(element => element.classList.remove('active'), selectedRows);
        }
    };

    onDisableFilterView = () => {
        this.props.toggleDeviceDetailsSidebar();
    };

    render() {
        const { showDeviceDetailsSidebar, children } = this.props;
        const content = this.state.hasError ? (
            <RenderingError error={this.state.error}/>
        ) : children;

        if (content) {
            return (
                <Sidebar
                    fly
                    width={1000}
                    closed={!showDeviceDetailsSidebar}
                    onClose={this.onDisableFilterView}>
                    {content}
                </Sidebar>
            );
        }
    }
}

export const mapStateToProps = (state) => {
    return {
        showDeviceDetailsSidebar: showDeviceDetailsSidebarSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        toggleDeviceDetailsSidebar: () => {
            dispatch(toggleDeviceDetailsSidebar());
        },
        hideDeviceDetailsSidebar: () => {
            dispatch(hideDeviceDetailsSidebar());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(onClickOutside(DeviceDetailsSidebarContainer));

DeviceDetailsSidebarContainer.defaultProps = {
    // props
    serialNumber: '',
    // functions
    toggleDeviceDetailsSidebar: noop,
};

DeviceDetailsSidebarContainer.propTypes = {
    // props
    serialNumber: PropTypes.string,
    // functions
    toggleDeviceDetailsSidebar: PropTypes.func,
};
