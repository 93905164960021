import { toSemanticVersion } from '~/features/base/utils/versionNumberConverter';

// TODO Think about combining this with filterOptions
export const queryToSearchCriteria = ({
    serialNumber: hwSerial,
    deliverableId,
    showOnlyLastEvent,
    targetDeliverableVersion,
    result: outcome,
    types,
    from,
    to,
}) => {
    const result = {
        hwSerial,
        deliverableId,
        showOnlyLastEvent,
        targetDeliverableVersion: targetDeliverableVersion ? toSemanticVersion(targetDeliverableVersion) : undefined,
        outcome,
        types,
        from,
        to,
    };
    return result;
};
