import getOr from 'lodash/fp/getOr';

import { createSelector } from 'reselect';

import { entityStoreSelector } from '~/features/higherorder/selectors/dataSelectors';
import { ENTITY_CONTROL_DEVICE_ROLLOUT_STATES } from '~/features/base/constants/entities';

const entitiesSelector = state => entityStoreSelector(state)[ENTITY_CONTROL_DEVICE_ROLLOUT_STATES];

export const entitiesOfDeviceSelector = (state, ownProps) =>
    getOr({}, ownProps.serialNumber, entitiesSelector(state));

export const controlDeviceRolloutStatesSelector = createSelector(
    [entitiesOfDeviceSelector],
    (entities) => {
        return getOr({}, entities);
    },
);
