import map from 'lodash/fp/map';
import mergeAll from 'lodash/fp/mergeAll';
import isEmpty from 'lodash/fp/isEmpty';
import omitBy from 'lodash/fp/omitBy';
import split from 'lodash/fp/split';
import replace from 'lodash/fp/replace';
import join from 'lodash/fp/join';
import head from 'lodash/fp/head';
import tail from 'lodash/fp/tail';
import size from 'lodash/fp/size';

import moment from 'moment';

const parseTimeString = timeString => {
    if (!timeString) {
        return;
    }
    if (size(timeString) === 14) {
        return moment.utc(timeString, 'YYYYMMDDHHmmss').toDate();
    }
    if (size(timeString) === 8) {
        return moment(timeString, 'YYYYMMDD').toDate();
    }
};

const mapDateRange = range => (
    (from, to) => ({ from, to })
)(...split('-', range));

const mapActionAndRange = (val1, val2) =>
    isEmpty(val2) ?
        mapDateRange(val1) :
        mergeAll([{ actionId: val1 }, mapDateRange(val2)]);

const actionAndDateRangeMap = arr =>
    isEmpty(arr) ? {} : mapActionAndRange(...arr);

const deOp = opId => replace(/op-/, '', opId || '');

const mapKey = (serviceId, opId, ...rest) => omitBy(isEmpty,
    mergeAll([{ serviceId, operationId: deOp(opId) }, actionAndDateRangeMap(rest)])
);

const splitKey = key => split('/', replace(/\s+/g, '', key || ''));

export const mapLogKey = key => mapKey(...splitKey(key));

export const parseDeviceLog = deviceLogFile => {
    const nameParts = split('.', deviceLogFile.name);
    const name = head(nameParts);
    const props = mapLogKey(name);
    const parsedFrom = parseTimeString(props.from);
    const parsedTo = parseTimeString(props.to);
    return {
        ...deviceLogFile,
        ...props,
        name,
        extension: join('.', tail(nameParts)) || 'txt',
        parsedFrom,
        parsedTo,
    };
};

export const parseDeviceLogs = deviceLogs => map(parseDeviceLog, deviceLogs);
