import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { DEVICES_PATH } from '~/features/base/constants/routes';

import { followRoute } from '~/features/base/actions/ui/routeActions';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import { fetchDevicesWithLogs } from '~/features/deviceLogs/actions/deviceLogsActions';

import {
    devicesWithLogsPageNumberSelector,
    devicesWithLogsPageCountSelector,
    devicesWithLogsTotalElementsSelector,
    devicesWithLogsPageItemsSelector,
} from '~/features/deviceLogs/selectors/devicesWithLogsSelector';

import { parseQuery } from '~/features/base/utils/query';
import uid from '~/features/base/utils/uid';

import DevicesWithLogsList from '~/features/deviceLogs/components/DevicesWithLogsList';
import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';

const FETCH_DEVICES_AMOUNT_INCREMENT = 50;

/**
 * Container for devices with logs
 */
export class DevicesWithLogsListContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.name = uid();
        this.onShowItem = this.onShowItem.bind(this);
        this.onLoadMore = this.onLoadMore.bind(this);
    }

    onShowItem({ serialNumber }) {
        const route = `/${DEVICES_PATH}/${serialNumber}`;
        this.props.followRoute({ route });
    }

    onLoadMore() {
        const { pathname, search } = this.props;
        const query = parseQuery(search);
        const queryAmount = Number(query.limit?query.limit:FETCH_DEVICES_AMOUNT_INCREMENT) + FETCH_DEVICES_AMOUNT_INCREMENT;
        this.props.followRoute({ route: pathname, query: {
                ...query,
                limit: queryAmount,
            } });
    }

    render() {
        return (
            <DevicesWithLogsList {...this.props}
                onShowItem={this.onShowItem}>
                onLoadMore={this.onLoadMore}
                {this.props.children}
            </DevicesWithLogsList>
        );
    }

    registerDataInterest(parsedQuery) {
        const { page, limit } = parsedQuery;
        const fetchDevicesWithLogsOptions = {
            page: (page && page > 0) ? (page - 1) : 0,
            size: limit ? limit : FETCH_DEVICES_AMOUNT_INCREMENT,
        };
        this.props.registerDataInterest(this.name, [
            fetchDevicesWithLogs(fetchDevicesWithLogsOptions),
        ]);
    }

    componentWillMount() {
        const { search } = this.props;
        const parsedQuery = parseQuery(search);
        this.registerDataInterest(parsedQuery);

        this.props.triggerDataFetcher();
        this.interval = setInterval(() => {
            this.props.triggerDataFetcher();
        }, 60000);
    }
    componentWillUnmount() {
        this.props.unregisterDataInterest(this.name);
        clearInterval(this.interval);
    }

    // FIXME REACT16 Should probably be componentDidUpdate(prevProps, prevState, snapshot)
    componentWillReceiveProps(nextProps) {
        const { search } = nextProps;
        if (search !== this.props.search) {
            const parsed = parseQuery(search);
            this.registerDataInterest(parsed);
            nextProps.triggerDataFetcher();
        }
    }
}

export const mapStateToProps = (state) => {
    return {
        pathname: pathnameSelector(state),
        search: searchSelector(state),
        pageNumber: devicesWithLogsPageNumberSelector(state),
        pageCount: devicesWithLogsPageCountSelector(state),
        totalElements: devicesWithLogsTotalElementsSelector(state),
        controlDevices: devicesWithLogsPageItemsSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        followRoute: (options) => {
            dispatch(followRoute(options));
        },
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        triggerDataFetcher: () => {
            dispatch(triggerDataFetcher());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DevicesWithLogsListContainer);

DevicesWithLogsListContainer.defaultProps = {
    // props
    pageNumber: 0,
    pageCount: 0,
    totalElements: 0,
    controlDevices: [],
    pathname: '',
    search: '',
    // functions
    followRoute: noop,
    triggerDataFetcher: noop,
    registerDataInterest: noop,
    unregisterDataInterest: noop,
};

DevicesWithLogsListContainer.propTypes = {
    // props
    pageNumber: PropTypes.number,
    pageCount: PropTypes.number,
    totalElements: PropTypes.number,
    controlDevices: PropTypes.array,
    pathname: PropTypes.string,
    search: PropTypes.string,
    // functions
    followRoute: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
};
