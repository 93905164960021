import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { deleteArtifact } from '~/features/deliverables/features/artifacts/actions/artifactActions';
import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';

/**
 * Artifact deletion dialog
 */
export class ArtifactDeletionDialog extends PureComponent {

    onDeleteArtifact = () => {
        const { artifactId, deleteArtifact } = this.props;
        deleteArtifact(artifactId);
    };

    render() {
        const { artifactName } = this.props;
        return (
            <ConfirmationDialog className='file-deliverable-deletion-dialog'
                                show={true}
                                title={<FormattedMessage id='intl-msg:deleteArtifact'/>}
                                content={<FormattedMessage id='intl-msg:deleteArtifact.prompt' values={{
                                    artifactName
                                }}/>}
                                cancelButtonText={<FormattedMessage id='intl-msg:cancel'/>}
                                confirmButtonText={<FormattedMessage id='intl-msg:ok'/>}
                                onClickCancel={this.props.hideModal}
                                onClickConfirm={this.onDeleteArtifact}/>
        );
    }
}

export const mapStateToProps = (state) => {
    return {};
};

export const mapDispatchToProps = (dispatch) => {
    return {
        deleteArtifact: (artifactId) => {
            dispatch(deleteArtifact(artifactId));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArtifactDeletionDialog);

ArtifactDeletionDialog.defaultProps = {
    // functions
    deleteArtifact: noop,
};

ArtifactDeletionDialog.propTypes = {
    // functions
    deleteArtifact: PropTypes.func,
};
