import size from 'lodash/fp/size';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';

export class SysParamListToolbar extends PureComponent {

    render() {
        const createButton = this.renderCreateButton();
        const moreActionsDropdown = this.renderMoreActionsDropdown();
        const exportsDropdown = this.renderExportDropdown();
        return (
            <div className='row sys-param-list-toolbar'>
                <div className='col-xs-6'>
                    <div className='btn-toolbar'>
                    </div>
                </div>
                <div className='col-xs-6'>
                    <div className='btn-toolbar pull-right'>
                        {createButton}
                        {moreActionsDropdown}
                        {exportsDropdown}
                    </div>
                </div>
            </div>
        );
    }

    renderCreateButton() {
        const {
            isBaseSwEditable,
            canCreateSysParamDefinitions,
        } = this.props;
        if (isBaseSwEditable && canCreateSysParamDefinitions) {
            return (
                <a id='create-sys-param-button'
                   className='btn btn-default'
                   onClick={this.props.onShowSysParamDefinitionEditor}>
                    <span className='rioglyph rioglyph-plus' aria-hidden='true'></span>&nbsp;
                    <FormattedMessage id='intl-msg:createSysParamDefinition'/>
                </a>
            );
        }
    }

    renderMoreActionsDropdown() {
        const {
            isBaseSwEditable,
            canCopySysParams,
            canCreateSysParamDefinitions,
            onShowImportSysParamDefinitionsDialog,
            onShowCopySysParamDefinitionsDialog,
        } = this.props;

        const items = [];
        if (isBaseSwEditable && canCreateSysParamDefinitions) {
            items.push(
                {
                    value: [
                        <FormattedMessage id='intl-msg:importSysParamDefinitions'/>
                    ],
                    onSelect: () => onShowImportSysParamDefinitionsDialog(),
                }
            );
        }
        if (isBaseSwEditable && canCopySysParams) {
            items.push(
                {
                    value: [
                        <FormattedMessage id='intl-msg:copySysParamDefinitions'/>
                    ],
                    onSelect: () => onShowCopySysParamDefinitionsDialog(),
                }
            );
        }
        return (
            size(items) ? (
                <ButtonDropdown
                    id='sys-param-list-dropdown'
                    title={
                        <span>
                            <FormattedMessage id='intl-msg:moreActions'/>
                        </span>
                    }
                    items={items}
                />
            ) : null
         );
    }

    renderExportDropdown() {
        const { showExportDropDown } = this.props;
        const items = [];
        items.push(
            {
                value: [
                    <span className='rioglyph rioglyph-cards-grid padding-right-5' aria-hidden='true'></span>,
                    <FormattedMessage id='intl-msg:exportSysParams'/>
                ],
                onSelect: (value) => this.props.onExportSysParams(),
            }
        );
        return (
            showExportDropDown && size(items) ? (
                <ButtonDropdown
                    id='sys-param-list-exports'
                    title={
                        <span>
                            <span className='rioglyph rioglyph-download' aria-hidden='true'/>&nbsp;&nbsp;
                            <FormattedMessage id='intl-msg:export'/>
                        </span>
                    }
                    items={items}
                />
            ) : null
        );
    }
}

export default SysParamListToolbar;

SysParamListToolbar.defaultProps = {
    // props
    canCopySysParams: false,
    canCreateSysParamDefinitions: false,
    isBaseSwEditable: false,
    showExportDropDown: true,
    // functions
    onShowSysParamDefinitionEditor: noop,
    onShowCopySysParamDefinitionsDialog: noop,
    onShowImportSysParamDefinitionsDialog: noop,
    onExportSysParams: noop,
};

SysParamListToolbar.propTypes = {
    // props
    canCopySysParams: PropTypes.bool,
    canCreateSysParamDefinitions: PropTypes.bool,
    isBaseSwEditable: PropTypes.bool,
    showExportDropDown: PropTypes.bool,
    // functions
    onShowSysParamDefinitionEditor: PropTypes.func,
    onShowCopySysParamDefinitionsDialog: PropTypes.func,
    onShowImportSysParamDefinitionsDialog: PropTypes.func,
    onExportSysParams: PropTypes.func,
};
