import { put, takeLatest } from 'redux-saga/effects';

import {
    COPY_SYS_PARAM_DEFINITIONS_MODAL,
    IMPORT_SYS_PARAM_DEFINITIONS_MODAL,
} from '~/features/base/constants/modals';

import { showModal } from '~/features/base/actions/ui/modalsActions';
import {
    changeDistroEditor,
    resetDistroEditor,

    SHOW_COPY_SYS_PARAM_DEFINITIONS_DIALOG,
    SHOW_IMPORT_SYS_PARAM_DEFINITIONS_DIALOG,
} from '~/features/deliverables/features/distros/actions/distroEditorActions';

export function* doShowCopySysParamDefinitionsDialogSaga(action) {
    yield put(resetDistroEditor());
    const distro = action.payload.distro;
    yield put(changeDistroEditor({
        ...distro,
    }));
    yield put(showModal({
        modalType: COPY_SYS_PARAM_DEFINITIONS_MODAL,
        modalProps: {},
    }));
}

export function* doShowImportSysParamDefinitionsDialogSaga(action) {
    yield put(resetDistroEditor());
    const distro = action.payload.distro;
    yield put(changeDistroEditor({
        ...distro,
    }));
    yield put(showModal({
        modalType: IMPORT_SYS_PARAM_DEFINITIONS_MODAL,
        modalProps: {},
    }));
}

export function* showCopySysParamDefinitionsDialogSaga() {
    yield takeLatest(SHOW_COPY_SYS_PARAM_DEFINITIONS_DIALOG, doShowCopySysParamDefinitionsDialogSaga);
}

export function* showImportSysParamDefinitionsDialogSaga() {
    yield takeLatest(SHOW_IMPORT_SYS_PARAM_DEFINITIONS_DIALOG, doShowImportSysParamDefinitionsDialogSaga);
}
