import React from 'react';
import { FormattedTime } from 'react-intl';
import DeviceOffline from '~/features/devices/components/DeviceOffline';
import FieldOrNoData from '~/features/base/components/FieldOrNoData';
import DeviceHealthState from '~/features/devices/components/DeviceHealthState';
import { copyToClipboard } from '~/features/apps/utils/appsUtils';
import VehicleVariant from '~/features/vehicles/components/VehicleVariant';

export const vehicleColumnDescriptors = [{
    id: 'vin',
    field: 'vin',
    label: 'intl-msg:VIN',
    className: 'hidden-xs hidden-ls hidden-sm',
    format: field => [<FieldOrNoData field={field}/>,
        field && <span className='rioglyph rioglyph-duplicate text-color-dark text-size-15 padding-left-5'
                       onClick={(event) => {
                           event.stopPropagation();
                           copyToClipboard(field, true);
                       }}></span>,
    ],
}, {
    id: 'vehicleVariant',
    field: 'vehicleVariant',
    label: 'intl-msg:vehicleVariant',
    format: (field, item) => (
        <span id={`vehicleVariant-${item.vin}`} className='white-space-nowrap'>
            <span className='margin-right-5'>
                <VehicleVariant vehicleVariant={item.vehicleVariant}/>
            </span>
        </span>
    ),
}, {
    id: 'deviceHealthState',
    field: 'status',
    label: 'intl-msg:deviceHealthState',
    format: (field, item) => [
        <DeviceHealthState controlDevice={item}/>,
    ],
// }, {
//     id: 'hwVariant',
//     field: 'hwVariant',
//     label: 'intl-msg:hwVariant',
//     className: 'hidden-xs hidden-ls hidden-sm',
//     format: (field, item) => [
//         <div>
//             <FieldOrNoData field={field}/>
//         </div>,
//         <div>
//             <FieldOrNoData field={'(' + item.vehicleManufacturerSparePartNumber + ')'}/>
//         </div>,
//     ],
// }, {
//     id: 'lastDistroVersionReported',
//     field: 'lastDistroVersionReported',
//     label: 'intl-msg:distroVersion',
//     format: field => [
//         <DistroChip distroVersion={field} className='clickable'/>,
//     ],
// }, {
//     id: 'lastBaseSoftwareVersionReported',
//     field: 'lastBaseSoftwareVersionReported',
//     label: 'intl-msg:baseSoftwareVersion',
//     format: field => [
//         <ShortBaseSwVersion baseSwVersion={field}/>,
//     ],
}, {
    id: 'lastCheckForUpdateTimestamp',
    field: 'lastCheckForUpdateTimestamp',
    label: 'intl-msg:lastCheckForUpdateTimestamp',
    format: field => {
        return (
            <span className='white-space-nowrap'>
                <FieldOrNoData field={field ? <FormattedTime value={field} year='numeric'
                                                             month='2-digit' day='2-digit'/> : null}/>
                <DeviceOffline timestamp={field}/>
            </span>
        );
    },
    sortable: true,
}, {
    id: 'registrationTimestamp',
    field: 'registrationTimestamp',
    label: 'intl-msg:registrationTimestamp',
    className: 'hidden-xs hidden-ls hidden-sm hidden-md',
    format: field => {
        return (
            <FieldOrNoData field={field ? <FormattedTime value={field} year='numeric'
                                                         month='2-digit' day='2-digit'/> : null}/>
        );
    },
    hidden: true,
}];
