import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface initialState {
    variantSelected?: string | undefined;
}

const initialState: initialState = {
    variantSelected: 'all',
};

const overviewSlice = createSlice({
    name: 'overview',
    initialState,
    reducers: {
        setSelectedVariant: (state, action:  PayloadAction<string | undefined>) => {
            state.variantSelected = action.payload;
        },
    },
});

export const getSelectedVehicleVariant = (state) => state.overview.variantSelected;

export const { setSelectedVariant } = overviewSlice.actions;

export default overviewSlice.reducer;
