
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import UpdateEvents from '~/features/updateEvents/components/UpdateEvents';
import { sendGoogleAnalyticsEvent } from '~/index';

export class UpdateEventsContainer extends PureComponent {
    render() {
        // send Google Analytics Event
        sendGoogleAnalyticsEvent('Overview Page', 'View Update Events');
        return (
            <UpdateEvents {...this.props}/>
        );
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEventsContainer);

UpdateEventsContainer.defaultProps = {
    // props
    // functions
};

UpdateEventsContainer.propTypes = {
    // props
    // functions
};
