/**
 * Action types for file upload.
 */
export const UPLOAD_REQUEST = 'UPLOAD_REQUEST';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_FAILURE = 'UPLOAD_FAILURE';

export function uploadRequest(file, url) {
    return {
        type: UPLOAD_REQUEST,
        payload: {
            url,
            file,
        },
    };
};

export function uploadProgress(file, progress) {
    return {
        type: UPLOAD_PROGRESS,
        payload: progress,
        meta: { file },
    };
};

export function uploadSuccess(file) {
    return {
        type: UPLOAD_SUCCESS,
        meta: { file },
    };
};

export function uploadFailure(file, err) {
    return {
        type: UPLOAD_FAILURE,
        payload: err,
        error: true,
        meta: { file },
    };
};
