import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchDefaultNetworkCodeList } from '~/features/base/actions/ui/networkCodeFetcherActions';

class NetworkCodeList extends Component {
    render() {
        return null
    }
    componentDidMount() {
        this.props.defaultNetworkCodeList();
        this.interval = setInterval(
            this.props.defaultNetworkCodeList,
            1500000
        );
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
}

export const mapStateToProps = (state) => {
    return {
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        defaultNetworkCodeList: () => {
            dispatch(fetchDefaultNetworkCodeList());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NetworkCodeList);

NetworkCodeList.defaultProps = {
    // props
};

NetworkCodeList.propTypes = {
    // props
};
