export const SET_SHOW_GROUP_TABLE_VIEW = 'SET_SHOW_GROUP_TABLE_VIEW';
export const UPDATE_GROUP_SEARCH_VALUE = 'UPDATE_GROUP_SEARCH_VALUE';

// TODO Find more generic solution
export function setShowGroupTableView(showTableView) {
    return {
        type: SET_SHOW_GROUP_TABLE_VIEW,
        showTableView,
    };
}

export function updateGroupSearchValue(searchValue) {
    return {
        type: UPDATE_GROUP_SEARCH_VALUE,
        searchValue,
    };
}
