import classNames from 'classnames';

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { deviceHealthStates, ERROR, OK, WARN } from '~/features/devices/constants/deviceHealthStates';
import FieldOrNoData from '~/features/base/components/FieldOrNoData';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import uid from '~/features/base/utils/uid';

export const DeviceHealthStateLabel = ({ status, statusInfos }) => {
    const labelClass = deviceHealthStates[status].className;
    return (
        statusInfos ?
            <span>
                <OverlayTrigger placement='top' overlay={
                    <Tooltip id='tooltip' className='top-right'>
                        <div className='flex-row'>
                            {
                                statusInfos.map(statusInfo => {
                                    return <div key={uid()} className='padding-5 text-left'>{statusInfo.status + ': '
                                        + statusInfo.description}</div>;
                                })
                            }
                        </div>
                    </Tooltip>}>
                    <span className={classNames('label label-condensed margin-right-5 margin-top-4 margin-bottom-5',
                        labelClass)}>
                        <FormattedMessage id={deviceHealthStates[status].label}/>
                    </span>
                </OverlayTrigger>
            </span>
            :
            <span
                className={classNames('label label-condensed margin-right-5 margin-top-4 margin-bottom-5', labelClass)}>
                    <FormattedMessage id={deviceHealthStates[status].label}/>
            </span>
    );
};

export const DeviceHealthState = ({ controlDevice }) => {
    if (!controlDevice.status) {
        return <FieldOrNoData/>;
    }
    const { status, statusInfos } = controlDevice;
    switch (status) {
        case 'OK':
            return <DeviceHealthStateLabel status={OK} statusInfos={statusInfos}/>;
        case 'ERROR':
            return <DeviceHealthStateLabel status={ERROR} statusInfos={statusInfos}/>;
        case 'WARN':
            return <DeviceHealthStateLabel status={WARN} statusInfos={statusInfos}/>;
        default:
            return <FieldOrNoData/>;
    }
};

export default DeviceHealthState;

DeviceHealthState.defaultProps = {};

DeviceHealthState.propTypes = {
    // props
    controlDevice: PropTypes.object,
};
