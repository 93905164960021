import React from 'react';
import ContentLoader from '@rio-cloud/rio-uikit/lib/es/ContentLoader';
import { FormattedMessage } from 'react-intl';

export const TableLoader = ({ nLines, labels }) => {
    const tableClassNames = 'table table-layout-fixed table-column-overflow-hidden table-bordered table-sticky table-head-filled';

    return (
        <>
            <table className={tableClassNames}>
                <thead className='position-sticky' style={{ top: '-1px' }}>
                <tr>
                    {labels.map((label) => <th><FormattedMessage id={label}/></th>)}
                </tr>
                </thead>
                <tbody>
                {[...Array(nLines)].map((i) => (
                    <tr>
                        {labels.map(() => (
                            <td>
                                <ContentLoader/>
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    );
};
