import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import map from 'lodash/fp/map';

import { createSelector } from 'reselect';
import {
    ENTITY_CONTROL_DEVICE,
    ENTITY_CONTROL_DEVICE_SCOPE_PACKAGE_DELIVERABLE_WHITELIST,
} from '~/features/base/constants/entities';
import { controlDeviceEntitiesSelector } from '~/features/devices/selectors/controlDeviceSelectors';
import { indicesStoreSelector } from '~/features/higherorder/selectors/dataSelectors';

const whitelistPagesSelector = state => getOr({},
    ENTITY_CONTROL_DEVICE_SCOPE_PACKAGE_DELIVERABLE_WHITELIST, indicesStoreSelector(state)[ENTITY_CONTROL_DEVICE]);

export const whitelistPageNumberSelector = state => get('number', whitelistPagesSelector(state));
export const whitelistPageCountSelector = state => get('totalPages', whitelistPagesSelector(state));
export const whitelistTotalElementsSelector = state => get('totalElements', whitelistPagesSelector(state));

export const whitelistPageItemsSelector = createSelector(
    [whitelistPagesSelector, controlDeviceEntitiesSelector, whitelistPageNumberSelector],
    (pages, entities, pageNumber) => {
        return map(id => entities[id], getOr([], pageNumber, pages));
    }
);
