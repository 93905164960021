import { call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';

import { getDeliverableManagementBackend } from '~/features/base/selectors/backendSelectors';

import { deleteHTTP, doHandleErrorSaga, getHTTP } from '~/features/base/sagas/sagaUtil';

import { ENTITY_ARTIFACT, ENTITY_ARTIFACT_FILES } from '~/features/base/constants/entities';
import { failedAction, finishedAction } from '~/features/higherorder/transforms/actionTransforms';
import { endLoading, startLoading } from '~/features/higherorder/actions/loadingActions';
import { uploadRequest } from '~/features/base/actions/data/fileUploadActions';
import { hideModal } from '~/features/base/actions/ui/modalsActions';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import download from 'downloadjs';
import {
    DELETE_ARTIFACT_FILE,
    DOWNLOAD_ARTIFACT_FILE,
    FETCH_ARTIFACT_FILES,
    UPLOAD_ARTIFACT_FILE,
} from '~/features/deliverables/features/artifacts/actions/artifactFileActions';
import { mergeEntities } from '~/features/higherorder/actions/entityActions';

export function* getArtifactsURL() {
    const serviceURL = yield select(getDeliverableManagementBackend);
    return `${serviceURL}/deliverables/v1`;
}

export function* doUploadArtifactFileSaga(action) {
    yield put(startLoading(ENTITY_ARTIFACT));
    try {
        const url = yield call(getArtifactsURL);
        const file = action.payload.file[0];
        const artifactId = encodeURIComponent(action.payload.artifactId);
        const fileName = encodeURIComponent(file.name);
        yield put(uploadRequest(file, `${url}/${artifactId}/files/${fileName}/upload?compressed=false`));
        yield put(hideModal());
        // yield put(triggerDataFetcher());
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_ARTIFACT));
    yield put(finishedAction(action.type));
}

export function* doFetchArtifactFilesSaga(action) {
    yield put(startLoading(ENTITY_ARTIFACT_FILES));
    try {
        const url = yield call(getArtifactsURL);
        const artifactId = action.payload.artifactId;
        const response = yield call(getHTTP, `${url}/${artifactId}/files`);
        // if (response.length > 0) {
        const a = {};
        a.set('artifactId', artifactId);
        a.set('files', response);
        const b = {
            artifactId,
            files: response,
        };
        yield put(mergeEntities([b],
            { entityName: ENTITY_ARTIFACT_FILES }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_ARTIFACT_FILES));
    yield put(finishedAction(action.type));
}

export function* doDeleteArtifactFileSaga(action) {
    try {
        const url = yield call(getArtifactsURL);
        const { artifactId, fileName } = action.payload;
        yield call(deleteHTTP, `${url}/${artifactId}/files/${fileName}`);
        yield put(hideModal());
        yield put(triggerDataFetcher());
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doDownloadArtifactFileSaga(action) {
    try {
        const url = yield call(getArtifactsURL);
        const { artifactId, fileName } = action.payload;
        // TODO: Context hardcoded
        const context = encodeURIComponent(`::BASYS-FOTA::`);
        const response = yield call(getHTTP, `${url}/file${context}${artifactId}/files/${fileName}/download`);
        fetch(response.url,
            {
                method: 'GET',
            },
        ).then((data) => {
            data.blob().then(blob => download(blob, fileName, 'application/octet-stream'));
        });
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
    }
}

export function* uploadArtifactFileSaga() {
    yield takeLatest(UPLOAD_ARTIFACT_FILE, doUploadArtifactFileSaga);
    yield put(triggerDataFetcher());
}

export function* fetchArtifactFilesSaga() {
    yield takeLatest(FETCH_ARTIFACT_FILES, doFetchArtifactFilesSaga);
}

export function* deleteArtifactFileSaga() {
    yield takeEvery(DELETE_ARTIFACT_FILE, doDeleteArtifactFileSaga);
    yield put(triggerDataFetcher());
}

export function* downloadArtifactFileSaga() {
    yield takeEvery(DOWNLOAD_ARTIFACT_FILE, doDownloadArtifactFileSaga);
}
