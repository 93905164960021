import React from 'react';
import FieldOrNoData from '~/features/base/components/FieldOrNoData';
import { FormattedTime } from 'react-intl';

export const fileColumnDescriptors = [{
    id: 'fileName',
    field: 'fileName',
    label: 'intl-msg:fileName',
    format: field => <FieldOrNoData field={field}/>,
},{
    id: 'sizeInBytes',
    field: 'sizeInBytes',
    label: 'intl-msg:size',
    format: field => <FieldOrNoData field={field}/>,
},{
    id: 'uploadedTime',
    field: 'uploadedTime',
    label: 'intl-msg:creationTime',
    format: field => <FormattedTime value={field} year='numeric' month='2-digit' day='2-digit'/>,
}];
