import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { deleteSysParam } from '~/features/sysParams/actions/sysParamsActions';

import { sysParamDefinitionEditorSelector } from '~/features/sysParams/selectors/sysParamDefinitionSelectors';
import { sysParamDefaultValueSelector } from '~/features/sysParams/selectors/sysParamDefaultValueSelectors';

import {
    toShortSemanticVersion,
} from '~/features/base/utils/versionNumberConverter';
import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';

/**
 * System parameter definition deletion dialog
 */
export class SysParamDefinitionDeletionDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.onDeleteSysParam = this.onDeleteSysParam.bind(this);
    }
    onDeleteSysParam() {
        const defaultValue = this.props.defaultValue;
        const definition = this.props.model;

        this.props.deleteSysParam(definition, defaultValue);
    }
    render() {
        const { model } = this.props;
        const shortBaseSwVersion = toShortSemanticVersion(model.baseSoftwareVersion);
        return (
            <ConfirmationDialog className='sys-param-definitions-deletion-dialog'
                show={true}
                title={<FormattedMessage id='intl-msg:deleteSysParamDefinition'/>}
                content={<FormattedMessage id='intl-msg:deleteSysParamDefinition.prompt' values={{
                    name: model.name,
                    shortBaseSwVersion: shortBaseSwVersion,
                }}/>}
                cancelButtonText={<FormattedMessage id='intl-msg:cancel'/>}
                confirmButtonText={<FormattedMessage id='intl-msg:ok'/>}
                onClickCancel={this.props.hideModal}
                onClickConfirm={this.onDeleteSysParam}/>
        );
    }
}

export const mapStateToProps = (state) => {
    // TODO Can we move more of this to selector?
    const model = sysParamDefinitionEditorSelector(state);
    const shortBaseSwVersion = toShortSemanticVersion(model.baseSoftwareVersion);
    const accessKey = model.accessKey;
    return {
        model,
        defaultValue: sysParamDefaultValueSelector(state, { shortBaseSwVersion, accessKey }),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        deleteSysParam: (sysParamDefinition, sysParamDefaultValue) => {
            dispatch(deleteSysParam(sysParamDefinition, sysParamDefaultValue));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SysParamDefinitionDeletionDialog);

SysParamDefinitionDeletionDialog.defaultProps = {
    model: {},
    defaultValue: undefined,
    deleteSysParam: noop,
};

SysParamDefinitionDeletionDialog.propTypes = {
    model: PropTypes.object,
    defaultValue: PropTypes.object,
    deleteSysParam: PropTypes.func,
};
