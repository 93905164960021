import noop from 'lodash/fp/noop';
import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import ClearableInput from '@rio-cloud/rio-uikit/lib/es/ClearableInput';
import Tag from '@rio-cloud/rio-uikit/lib/es/Tag';

export class DeliverablePermissionForm extends PureComponent {
    render() {
        const {
            canFullyManageOwnerContext,
            contexts,
            owners,
            onSelectContextName,
            onRemoveOwner,
            selectedContextName,
        } = this.props;
        return (
            <form>
                <div className="display-flex justify-content-center">
                    <div className="column margin-10 list-group">
                        <div className="form-group form-group-file-id">
                            <label className="control-label">
                                <FormattedMessage id="intl-msg:permissionDeliverables.contexts"/>
                            </label>
                            <div
                                className="overflow-y-auto height-400 width-300 padding-5 border-style-solid border-width-1 border-color-lighter">
                                {
                                    contexts.map(contextName => {
                                        return <button type="button" onClick={onSelectContextName}
                                                       data-key={contextName}
                                                       className={`list-group-item list-group-item-action width-100pct text-left ${contextName
                                                       === selectedContextName ? 'active' : ''}`}
                                        >{contextName}</button>;
                                    })
                                }
                            </div>
                            {canFullyManageOwnerContext &&
                                <div className="display-flex width-300 padding-top-5">
                                    <ClearableInput className="width-200" onChange={this.props.onUpdateNewContextName}/>
                                    <button className="btn btn-default margin-left-5" onClick={this.props.onAddNewContext}>
                                        <FormattedMessage id="intl-msg:permissionDeliverables.newButton"/>
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="column margin-10">
                        <div className="form-group form-group-file-id">
                            <label className="control-label">
                                <FormattedMessage id="intl-msg:permissionDeliverables.addUserName"/>
                            </label>
                            <div>
                                <div className="display-flex">
                                    <ClearableInput className="width-300" onChange={this.props.onUpdateNewOwnerName}/>
                                    <button className="btn btn-default margin-left-5" onClick={this.props.onAddOwner}>
                                        <FormattedMessage id="intl-msg:permissionDeliverables.addButton"/>
                                    </button>
                                </div>
                                <div>
                                    <ul className="list-inline margin-top-10">
                                        {
                                            owners.map(owner => {
                                                return <div className="padding-5">
                                                    <Tag onClick={onRemoveOwner} data-key={owner}
                                                         deletable>{owner}</Tag>
                                                </div>;
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default DeliverablePermissionForm;

DeliverablePermissionForm.defaultProps = {
    // props
    contexts: [],
    owners: [],
    selectedContextName: '',
    canFullyManageOwnerContext: false,
    // functions
    onUpdateNewOwnerName: noop,
    onUpdateNewContextName: noop,
    onSelectContextName: noop,
    onAddNewContext: noop,
    onAddOwner: noop,
    onRemoveOwner: noop,
};

DeliverablePermissionForm.propTypes = {
    // props
    contexts: PropTypes.array,
    owners: PropTypes.array,
    selectedContextName: PropTypes.string,
    canFullyManageOwnerContext: PropTypes.bool,
    // functions
    onUpdateNewOwnerName: PropTypes.func,
    onUpdateNewContextName: PropTypes.func,
    onSelectContextName: PropTypes.func,
    onAddNewContext: PropTypes.func,
    onAddOwner: PropTypes.func,
    onRemoveOwner: PropTypes.func,
};
