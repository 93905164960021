import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';

import DeviceListTableToolbar from '~/features/devices/components/DeviceListTableToolbar';
import DeviceListTable from '~/features/devices/components/DeviceListTable';
import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';
import isEmpty from 'lodash/fp/isEmpty';

const columns = ['serialNumber', 'vin', 'deviceHealthState', 'hwVariant', 'softwareVersion', 'lastCheckForUpdateTimestamp'];

export class GroupDevicesAssigned extends PureComponent {

    render() {
        const {
            canReadControlDevices,
        } = this.props;

        if (!canReadControlDevices) {
            return <FormattedMessage id='intl-msg:accessNotAllowed'/>;
        }
        const {
            groupDevicesAssigned, devicesLoading, totalElements, onShowPage, onLoadMore,
            onShowItem, onExportDevices,
        } = this.props;
        const showLoadMore = totalElements > groupDevicesAssigned.length;
        const whitelistSerialNumbersAvailable = totalElements > 0 && !isEmpty(groupDevicesAssigned);
        return [
            <DefaultWhiteColumn className='padding-top-20 clearfix'>
                <DeviceListTableToolbar
                    whitelistSerialNumbersAvailable={whitelistSerialNumbersAvailable}
                    onExportDevices={onExportDevices}>
                    <span className='padding-top-5'>
                        <FormattedMessage id='intl-msg:totalControlDevices.phrase' values={{
                            totalElements,
                            selectedCount: 0,
                        }}/>
                    </span>
                    <div className='display-inline-block padding-left-5'>
                        {devicesLoading ? <Spinner/> : null}
                    </div>
                </DeviceListTableToolbar>
            </DefaultWhiteColumn>,
            <DefaultWhiteColumn className='padding-top-20 padding-bottom-5'>
                <DeviceListTable
                    controlDevices={groupDevicesAssigned}
                    hiddenColumns={[]}
                    columnOrder={columns}
                    canUpdateControlDevices={false}
                    allowSelection={true}
                    onShowItem={onShowItem}/>
            </DefaultWhiteColumn>,
            showLoadMore &&
                <DefaultWhiteColumn className='text-center padding-bottom-20'>
                    <a id='load-more-button'
                       className='btn btn-default'
                       onClick={onLoadMore}>
                        <FormattedMessage id='intl-msg:loadMore'/>
                    </a>
                </DefaultWhiteColumn>,
        ];
    }
}

export default GroupDevicesAssigned;

GroupDevicesAssigned.defaultProps = {
    // props
    canReadControlDevices: false,
    totalElements: 0,
    groupDevicesAssigned: [],
    devicesLoading: false,
    // functions
    onShowPage: noop,
    onShowItem: noop,
    onExportDevices: noop,
    onLoadMore: noop,
};

GroupDevicesAssigned.propTypes = {
    // props
    canReadControlDevices: PropTypes.bool,
    totalElements: PropTypes.number,
    groupDevicesAssigned: PropTypes.array,
    devicesLoading: PropTypes.bool,
    // functions
    onShowPage: PropTypes.func,
    onShowItem: PropTypes.func,
    onExportDevices: PropTypes.func,
    onLoadMore: PropTypes.func,
};
