import PropTypes, { object } from 'prop-types';

import React from 'react';
import { Link } from 'react-router-dom';

import { GROUPS_PATH } from '~/features/base/constants/routes';

import Chip from '~/features/base/components/Chip';
import uid from '~/features/base/utils/uid';

export const GroupChip = ({ group, bsStyle, className }) => {
    const name = group.name || group;
    const description = group && group.description;
    return (
        <Link key={uid()} to={`/${GROUPS_PATH}/${name}`}>
            <Chip toolTipMessage={description} bsStyle={bsStyle} className={className}>{name}</Chip>
        </Link>
    );
};

export default GroupChip;

GroupChip.propTypes = {
    // props
    group: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]).isRequired,
    bsStyle: PropTypes.string,
    className: PropTypes.string,
};
