export const START_LOADING = '@@data/START_LOADING';
export const END_LOADING = '@@data/END_LOADING';

export const startLoading = (entityName, scope) => {
    return {
        type: START_LOADING,
        mergeProps: {
            entityName,
            scope,
        },
    };
};
export const endLoading = (entityName, scope) => {
    return {
        type: END_LOADING,
        mergeProps: {
            entityName,
            scope,
        },
    };
};
