import map from 'lodash/fp/map';
import flow from 'lodash/fp/flow';
import values from 'lodash/fp/values';

import React from 'react';

export const ALL = 'ALL';

export const BACKEND_PROXY_SERVICE = 'backendproxy';
export const BLUETOOTH_DRIVER_ID = 'bt-driverid';
export const BUSYBOX_KLOGD_SERVICE = 'busybox-klogd';
export const BUSYBOX_SYSLOG_SERVICE = 'busybox-syslog';
export const CERT_SERVICE = 'cert_app';
export const DBUS_SERVICE = 'dbus';
export const DNS_MASQ_SERVICE = 'dnsmasq';
export const DRIVER_INFO_SERVICE = 'driver-info';
export const DTC_MONITOR_SERVICE = 'dtc-monitor';
export const ECUVARD_SERVICE = 'vard';
export const ELECTRIC_SERVICE = 'electric-app';
export const EVENT_TRIGGER_SERVICE = 'event-trigger';
export const GETTY_SERVICE = 'getty@tty1';
export const GNSS_MGR_SERVICE = 'gnssmgr';
export const IPCD_SERVICE = 'ipcd';
export const IP_TABLES_SERVICE = 'iptables';
export const KMOD_STATIC_NODES_SERVICE = 'kmod-static-nodes';
export const LD_CONFIG_SERVICE = 'ldconfig';
export const LOCATION_SERVICE = 'location';
export const MAINTENANCE_SERVICE = 'maintenance-app';
export const MATCHING_SERVICE = 'matching-app';
export const MDMC_MONITOR = 'mdmc-monitor';
export const MDMC_SERVICE = 'mdmc-app';
export const MMTD_SERVICE = 'mmtd';
export const MODEM_CONFIG_SERVICE = 'modemconfig';
export const MODEM_MANAGER_SERVICE = 'ModemManager';
export const MODEMD_SERVICE = 'modemd';
export const MOSQUITTO_SERVICE = 'mosquitto';
export const NEO_MMTD_SERVICE = 'neo-mmtd';
export const PERFORMANCE_SERVICE = 'performance-app';
export const POLKIT_SERVICE = 'polkit';
export const RTLOGD_SERVICE = 'rtlogd';
export const REMOTE_TESTER_SERVICE = 'remotetester';
export const SERIAL_GETTY_SERVICE = 'serial-getty@ttyFP0';
export const SPIDER_SERVICE = 'spider';

export const SYSTEMD_HWDB_UPDATE_PACKAGE = 'systemd-hwdb-update';
export const SYSTEMD_JOURNAL_CATALOG_UPDATE_PACKAGE = 'systemd-journal-catalog-update';
export const SYSTEMD_JOURNAL_FLUSH_SERVICE = 'systemd-journal-flush';
export const SYSTEMD_JOURNALD_SERVICE = 'systemd-journald';
export const SYSTEMD_LOGIND_SERVICE = 'systemd-logind';
export const SYSTEMD_MODULES_LOAD_SERVICE = 'systemd-modules-load';
export const SYSTEMD_RANDOM_SEED_SERVICE = 'systemd-random-seed';
export const SYSTEMD_REMOUNT_FS_SERVICE = 'systemd-remount-fs';
export const SYSTEMD_RESOLVED_SERVICE = 'systemd-resolved';
export const SYSTEMD_SYSCTL_SERVICE = 'systemd-sysctl';
export const SYSTEMD_SYSUSERS_SERVICE = 'systemd-sysusers';
export const SYSTEMD_TMPFILES_SETUP_DEV_SERVICE = 'systemd-tmpfiles-setup-dev';
export const SYSTEMD_TMPFILES_SETUP_SERVICE = 'systemd-tmpfiles-setup';
export const SYSTEMD_UDEV_TRIGGER_SERVICE = 'systemd-udev-trigger';
export const SYSTEMD_UDEV_SERVICE = 'systemd-udevd';
export const SYSTEMD_UPDATE_DONE_SERVICE = 'systemd-update-done';
export const SYSTEMD_UPDATE_UTMP_SERVICE = 'systemd-update-utmp';
export const SYSTEMD_VCONSOLE_SETUP_SERVICE = 'systemd-vconsole-setup';

export const SYSTEM_STATE_SERVICE = 'system-state';

export const SYS_TIME_MGR_SERVICE = 'systimemgr';
export const TACHO_REMOTE_DOWNLOAD_SERVICE = 'tacho-remote-download';
export const TACHO_REMOTE_DOWNLOAD_ENGINE_SERVICE = 'TrdEngine';
export const TEMP_MAN_CERT_APP = 'temp-man-cert-app';
export const TCF_AGENT_SERVICE = 'tcf-agent';
export const UDSENGINED_SERVICE = 'udsengined';
export const UDSIFD_SERVICE = 'udsifd';
export const VEHICLE_INIT_SERVICE = 'vehicle-init';
export const VEHICLE_STATE_SERVICE = 'vehicle-state';

export const JOURNAL = 'journal';
export const TPA_JOURNAL = 'tpa-journal';

export const serviceEnum = [
    BACKEND_PROXY_SERVICE,
    BLUETOOTH_DRIVER_ID,
    BUSYBOX_KLOGD_SERVICE,
    BUSYBOX_SYSLOG_SERVICE,
    CERT_SERVICE,
    DBUS_SERVICE,
    DNS_MASQ_SERVICE,
    DRIVER_INFO_SERVICE,
    DTC_MONITOR_SERVICE,
    ECUVARD_SERVICE,
    ELECTRIC_SERVICE,
    EVENT_TRIGGER_SERVICE,
    GETTY_SERVICE,
    GNSS_MGR_SERVICE,
    IPCD_SERVICE,
    IP_TABLES_SERVICE,
    KMOD_STATIC_NODES_SERVICE,
    LD_CONFIG_SERVICE,
    LOCATION_SERVICE,
    MAINTENANCE_SERVICE,
    MATCHING_SERVICE,
    MDMC_MONITOR,
    MDMC_SERVICE,
    MMTD_SERVICE,
    MODEM_CONFIG_SERVICE,
    MODEM_MANAGER_SERVICE,
    MODEMD_SERVICE,
    MOSQUITTO_SERVICE,
    NEO_MMTD_SERVICE,
    PERFORMANCE_SERVICE,
    POLKIT_SERVICE,
    RTLOGD_SERVICE,
    REMOTE_TESTER_SERVICE,
    SERIAL_GETTY_SERVICE,
    SPIDER_SERVICE,
    SYSTEMD_HWDB_UPDATE_PACKAGE,
    SYSTEMD_JOURNAL_CATALOG_UPDATE_PACKAGE,
    SYSTEMD_JOURNAL_FLUSH_SERVICE,
    SYSTEMD_JOURNALD_SERVICE,
    SYSTEMD_LOGIND_SERVICE,
    SYSTEMD_MODULES_LOAD_SERVICE,
    SYSTEMD_RANDOM_SEED_SERVICE,
    SYSTEMD_REMOUNT_FS_SERVICE,
    SYSTEMD_RESOLVED_SERVICE,
    SYSTEMD_SYSCTL_SERVICE,
    SYSTEMD_SYSUSERS_SERVICE,
    SYSTEMD_TMPFILES_SETUP_DEV_SERVICE,
    SYSTEMD_TMPFILES_SETUP_SERVICE,
    SYSTEMD_UDEV_TRIGGER_SERVICE,
    SYSTEMD_UDEV_SERVICE,
    SYSTEMD_UPDATE_DONE_SERVICE,
    SYSTEMD_UPDATE_UTMP_SERVICE,
    SYSTEMD_VCONSOLE_SETUP_SERVICE,
    SYSTEM_STATE_SERVICE,
    SYS_TIME_MGR_SERVICE,
    TACHO_REMOTE_DOWNLOAD_SERVICE,
    TACHO_REMOTE_DOWNLOAD_ENGINE_SERVICE,
    TEMP_MAN_CERT_APP,
    TCF_AGENT_SERVICE,
    UDSENGINED_SERVICE,
    UDSIFD_SERVICE,
    VEHICLE_INIT_SERVICE,
    VEHICLE_STATE_SERVICE,
];

export const serviceOptions = {
    [ALL]: {
        id: ALL,
        name: 'All',
        description: 'All',
    },
    [BACKEND_PROXY_SERVICE]: {
        id: BACKEND_PROXY_SERVICE,
        name: 'Backend Proxy',
        description: 'The Alexandra Backend Proxy',
    },
    [BLUETOOTH_DRIVER_ID]: {
        id: BLUETOOTH_DRIVER_ID,
        name: 'Bluetooth Driver Id',
        description: 'Bluetooth Driver Id',
    },
    [BUSYBOX_KLOGD_SERVICE]: {
        id: BUSYBOX_KLOGD_SERVICE,
        name: 'Kernel Logging',
        description: 'Kernel Logging Service',
    },
    [BUSYBOX_SYSLOG_SERVICE]: {
        id: BUSYBOX_SYSLOG_SERVICE,
        name: 'System Logging',
        description: 'System Logging Service',
    },
    [CERT_SERVICE]: {
        id: CERT_SERVICE,
        name: 'Cert Signing',
        description: 'Alexandra Certificate Signing Application',
    },
    [DBUS_SERVICE]: {
        id: DBUS_SERVICE,
        name: 'D-Bus',
        description: 'D-Bus System Message Bus',
    },
    [DNS_MASQ_SERVICE]: {
        id: DNS_MASQ_SERVICE,
        name: 'DNS MASQ',
        description: 'DNS forwarder and DHCP server',
    },
    [DRIVER_INFO_SERVICE]: {
        id: DRIVER_INFO_SERVICE,
        name: 'DriverInfo',
        description: '',
    },
    [DTC_MONITOR_SERVICE]: {
        id: DTC_MONITOR_SERVICE,
        name: 'DTC Monitor',
        description: '',
    },
    [ECUVARD_SERVICE]: {
        id: ECUVARD_SERVICE,
        name: 'ECU Var Deamon',
        description: 'The Alexandra ECU Variable daemon',
    },
    [ELECTRIC_SERVICE]: {
        id: ELECTRIC_SERVICE,
        name: 'Electric App',
        description: 'The Electric App',
    },
    [EVENT_TRIGGER_SERVICE]: {
        id: EVENT_TRIGGER_SERVICE,
        name: 'Event Trigger',
        description: 'Event Trigger',
    },
    [GETTY_SERVICE]: {
        id: GETTY_SERVICE,
        name: 'Getty',
        description: 'Getty on tty1',
    },
    [GNSS_MGR_SERVICE]: {
        id: GNSS_MGR_SERVICE,
        name: 'gnssmgr',
        description: 'Alexandra gnssmgr',
    },
    [IPCD_SERVICE]: {
        id: IPCD_SERVICE,
        name: 'IPCD',
        description: 'The Alexandra IPCd',
    },
    [IP_TABLES_SERVICE]: {
        id: IP_TABLES_SERVICE,
        name: 'IP Tables',
        description: 'Packet Filtering Framework',
    },
    [KMOD_STATIC_NODES_SERVICE]: {
        id: KMOD_STATIC_NODES_SERVICE,
        name: 'KMOD Static Nodes',
        description: 'Create list of required static device nodes for the current kernel',
    },
    [LD_CONFIG_SERVICE]: {
        id: LD_CONFIG_SERVICE,
        name: 'LD  Config',
        description: 'Rebuild Dynamic Linker Cache',
    },
    [LOCATION_SERVICE]: {
        id: LOCATION_SERVICE,
        name: 'Location',
        description: 'The MAN location app',
    },
    [MAINTENANCE_SERVICE]: {
        id: MAINTENANCE_SERVICE,
        name: 'Maintenance',
        description: 'The MAN maintenance app',
    },
    [MATCHING_SERVICE]: {
        id: MATCHING_SERVICE,
        name: 'Matching',
        description: 'The matching app',
    },
    [MDMC_MONITOR]: {
        id: MDMC_MONITOR,
        name: 'MDMC Monitor',
        description: 'The mdmc monitor app',
    },
    [MDMC_SERVICE]: {
        id: MDMC_SERVICE,
        name: 'MDMC',
        description: 'The mdmc app',
    },
    [MMTD_SERVICE]: {
        id: MMTD_SERVICE,
        name: 'MMTD',
        description: 'The mmtd app',
    },
    [MODEM_CONFIG_SERVICE]: {
        id: MODEM_CONFIG_SERVICE,
        name: 'Modem Config',
        description: 'Alexandra Modem Configurator',
    },
    [MODEM_MANAGER_SERVICE]: {
        id: MODEM_MANAGER_SERVICE,
        name: 'Modem Manager',
        description: 'Modem Manager',
    },
    [MODEMD_SERVICE]: {
        id: MODEMD_SERVICE,
        name: 'ModemD',
        description: 'ModemD',
    },
    [MOSQUITTO_SERVICE]: {
        id: MOSQUITTO_SERVICE,
        name: 'Mosquitto',
        description: 'Mosquitto - lightweight server implementation of the MQTT and MQTT-SN protocols',
    },
    [NEO_MMTD_SERVICE]: {
        id: NEO_MMTD_SERVICE,
        name: 'Neo MMTD',
        description: 'The new mmtd app',
    },
    [PERFORMANCE_SERVICE]: {
        id: PERFORMANCE_SERVICE,
        name: 'Performance',
        description: 'The MAN performance app',
    },
    [POLKIT_SERVICE]: {
        id: POLKIT_SERVICE,
        name: 'Polkit Authorization Manager',
        description: 'Authorization Manager',
    },
    [RTLOGD_SERVICE]: {
        id: RTLOGD_SERVICE,
        name: 'rtlogd',
        description: 'Alexandra rtlogd',
    },
    [REMOTE_TESTER_SERVICE]: {
        id: REMOTE_TESTER_SERVICE,
        name: 'Remote Tester',
        description: 'The remote tester app',
    },
    [SERIAL_GETTY_SERVICE]: {
        id: SERIAL_GETTY_SERVICE,
        name: 'Serial Getty',
        description: 'Serial Getty on ttyFP0',
    },
    [SPIDER_SERVICE]: {
        id: SPIDER_SERVICE,
        name: 'Spider',
        description: 'Alexandra spider',
    },
    [SYSTEMD_HWDB_UPDATE_PACKAGE]: {
        id: SYSTEMD_HWDB_UPDATE_PACKAGE,
        name: 'Systemd Hardware Database',
        description: 'Rebuild Hardware Database',
    },
    [SYSTEMD_JOURNAL_CATALOG_UPDATE_PACKAGE]: {
        id: SYSTEMD_JOURNAL_CATALOG_UPDATE_PACKAGE,
        name: 'Systemd Journal Catalog',
        description: 'Rebuild Journal Catalog',
    },
    [SYSTEMD_JOURNAL_FLUSH_SERVICE]: {
        id: SYSTEMD_JOURNAL_FLUSH_SERVICE,
        name: 'Systemd Flush Journal',
        description: 'Flush Journal to Persistent Storage',
    },
    [SYSTEMD_JOURNALD_SERVICE]: {
        id: SYSTEMD_JOURNALD_SERVICE,
        name: 'Systemd Journal Service',
        description: 'Journal Service',
    },
    [SYSTEMD_LOGIND_SERVICE]: {
        id: SYSTEMD_LOGIND_SERVICE,
        name: 'Systemd Login Service',
        description: 'Login Service',
    },
    [SYSTEMD_MODULES_LOAD_SERVICE]: {
        id: SYSTEMD_MODULES_LOAD_SERVICE,
        name: 'Systemd Load Kernel Modules',
        description: 'Load Kernel Modules',
    },
    [SYSTEMD_RANDOM_SEED_SERVICE]: {
        id: SYSTEMD_RANDOM_SEED_SERVICE,
        name: 'Systemd Random Seed',
        description: 'Load/Save Random Seed',
    },
    [SYSTEMD_REMOUNT_FS_SERVICE]: {
        id: SYSTEMD_REMOUNT_FS_SERVICE,
        name: 'Systemd Remount File Systems',
        description: 'Remount Root and Kernel File Systems',
    },
    [SYSTEMD_RESOLVED_SERVICE]: {
        id: SYSTEMD_RESOLVED_SERVICE,
        name: 'Systemd Network Name Resolution',
        description: 'Network Name Resolution',
    },
    [SYSTEMD_SYSCTL_SERVICE]: {
        id: SYSTEMD_SYSCTL_SERVICE,
        name: 'Systemd Apply Kernel Variables',
        description: 'Apply Kernel Variables',
    },
    [SYSTEMD_SYSUSERS_SERVICE]: {
        id: SYSTEMD_SYSUSERS_SERVICE,
        name: 'Systemd Create System Users',
        description: 'Create System Users',
    },
    [SYSTEMD_TMPFILES_SETUP_DEV_SERVICE]: {
        id: SYSTEMD_TMPFILES_SETUP_DEV_SERVICE,
        name: 'Systemd Tmp Files Setup Dev',
        description: 'Create Static Device Nodes in /dev',
    },
    [SYSTEMD_TMPFILES_SETUP_SERVICE]: {
        id: SYSTEMD_TMPFILES_SETUP_SERVICE,
        name: 'Systemd Tmp Files Setup',
        description: 'Create Volatile Files and Directories',
    },
    [SYSTEMD_UDEV_TRIGGER_SERVICE]: {
        id: SYSTEMD_UDEV_TRIGGER_SERVICE,
        name: 'Systemd UDEV Trigger',
        description: 'Coldplug all Devices',
    },
    [SYSTEMD_UDEV_SERVICE]: {
        id: SYSTEMD_UDEV_SERVICE,
        name: 'Systemd UDEV',
        description: 'Kernel Device Manager',
    },
    [SYSTEMD_UPDATE_DONE_SERVICE]: {
        id: SYSTEMD_UPDATE_DONE_SERVICE,
        name: 'Systemd Update Done',
        description: 'Update is Completed',
    },
    [SYSTEMD_UPDATE_UTMP_SERVICE]: {
        id: SYSTEMD_UPDATE_UTMP_SERVICE,
        name: 'Systemd Update UTMP',
        description: 'Update UTMP about System Boot/Shutdown',
    },
    [SYSTEMD_VCONSOLE_SETUP_SERVICE]: {
        id: SYSTEMD_VCONSOLE_SETUP_SERVICE,
        name: 'Systemd Setup Virtual Console',
        description: 'Setup Virtual Console',
    },
    [SYSTEM_STATE_SERVICE]: {
        id: SYSTEM_STATE_SERVICE,
        name: 'System State',
        description: 'System State',
    },
    [SYS_TIME_MGR_SERVICE]: {
        id: SYS_TIME_MGR_SERVICE,
        name: 'System Time Manager',
        description: 'Alexandra System Time Manager',
    },
    [TACHO_REMOTE_DOWNLOAD_SERVICE]: {
        id: TACHO_REMOTE_DOWNLOAD_SERVICE,
        name: 'Tacho Remote Download',
        description: 'This is a Service to facilitate fetching diagnostic data from UDS and upload to backend',
    },
    [TACHO_REMOTE_DOWNLOAD_ENGINE_SERVICE]: {
        id: TACHO_REMOTE_DOWNLOAD_ENGINE_SERVICE,
        name: 'TRD Engine',
        description: 'This is a Service to facilitate fetching diagnostic data from UDS and upload to backend',
    },
    [TEMP_MAN_CERT_APP]: {
        id: TEMP_MAN_CERT_APP,
        name: 'Temp MAN Cert App',
        description: 'Temp MAN Cert App',
    },
    [TCF_AGENT_SERVICE]: {
        id: TCF_AGENT_SERVICE,
        name: 'TCF Agent',
        description: 'Target Communication Framework agent',
    },
    [UDSENGINED_SERVICE]: {
        id: UDSENGINED_SERVICE,
        name: 'UDS Engine Daemon',
        description: 'The Alexandra UDS request engine daemon (DriverId)',
    },
    [UDSIFD_SERVICE]: {
        id: UDSIFD_SERVICE,
        name: 'UDS Interface Daemon',
        description: 'The Alexandra UDS interface daemon',
    },
    [VEHICLE_INIT_SERVICE]: {
        id: VEHICLE_INIT_SERVICE,
        name: 'Vehicle Init',
        description: 'The MAN vehicle-initialization app',
    },
    [VEHICLE_STATE_SERVICE]: {
        id: VEHICLE_STATE_SERVICE,
        name: 'Vehicle State',
        description: 'The MAN vehicle-state app',
    },
};

export const serviceOptionsWithAll = {
    ...serviceOptions,
};

export const serviceOptionsWithAllDefault = {
    [ALL]: {
        id: ALL,
        name: 'All',
        description: 'All',
    }
}

export const createServiceFilterOptions = () => {
    return flow(
        values,
        map(option => ({
            ...option,
            label: [
                option.id,
                ' (',
                option.name,
                ')',
            ],
        }))
    )(serviceOptionsWithAll);
};

export const createServiceFilterOptionsAll = () => {
    return flow(
        values,
        map(option => ({
            ...option,
            label: [
                option.id,
                ' (',
                option.name,
                ')',
            ],
        }))
    )(serviceOptionsWithAllDefault);
};

export const pluginOptions = {
    [JOURNAL]: {
        id: JOURNAL,
        name: 'Journal',
        description: 'The Journal',
    },
    [TPA_JOURNAL]: {
        id: TPA_JOURNAL,
        name: 'TPA Journal',
        description: 'The TPA Journal',
    },
};

export const pluginOptionsOnly = {
    ...pluginOptions,
};

export const createPluginFilterOptions = () => {
    return flow(
        values,
        map(option => ({
            ...option,
            label: [
                option.id,
                ' (',
                option.name,
                ')',
            ],
        }))
    )(pluginOptionsOnly);
};
