export const HOURLY = 'HOUR';
export const DAILY = 'DAY';
export const WEEKLY = 'WEEK';
export const MONTHLY = 'MONTH';

export const timeIntervals = {
    [HOURLY]: {
        id: HOURLY,
        label: 'intl-msg:hourly',
    },
    [DAILY]: {
        id: DAILY,
        label: 'intl-msg:daily',
    },
    [WEEKLY]: {
        id: WEEKLY,
        label: 'intl-msg:weekly',
    },
    [MONTHLY]: {
        id: MONTHLY,
        label: 'intl-msg:monthly',
    },
};
