import get from 'lodash/fp/get';
import find from 'lodash/fp/find';
import map from 'lodash/fp/map';
import isEqual from 'lodash/fp/isEqual';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import Group from '~/prop-types/groupPropType';

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { validateHwSerial } from '~/features/base/utils/hwSerialValidator';
import DeviceTypeFormItem from '~/features/base/components/forms/DeviceTypeFormItem';
import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import Multiselect from '@rio-cloud/rio-uikit/lib/es/Multiselect';

/**
 * Control device editor form
 */
export class DeviceEditorForm extends PureComponent {
    constructor() {
        super();
        this.serialNumberInput = React.createRef();
        this.descriptionInput = React.createRef();
    }

    onSerialNumberChange = (event) => {
        const serialNumber = event.target.value;
        const isValidSerialNumber = validateHwSerial(serialNumber);
        this.props.changeControlDeviceEditor({
            serialNumber,
            isValidSerialNumber,
        });
    };

    onDeviceTypeFilterChange = (event) => {
        const type = event.deviceType;
        this.props.changeControlDeviceEditor({
            type,
        });
    };

    onInCustomerFleetChange = (event) => {
        this.props.changeControlDeviceEditor({
            inCustomerFleet: event.target.checked,
        });
    };

    onUpdatesActiveChange = (event) => {
        this.props.changeControlDeviceEditor({
            updatesActive: !event.target.checked,
        });
    };

    onTestReleasesActiveChange = (event) => {
        this.props.changeControlDeviceEditor({
            testReleasesActive: event.target.checked,
        });
    };

    onDeviceGroupsChange = (groupNames) => {
        const groups = this.props.groups;
        const deviceGroups = map(groupName => get('name', find(group => group.name === groupName, groups)), groupNames);
        this.props.changeControlDeviceEditor({
            deviceGroups,
        });
    };

    onDescriptionChange = (event) => {
        this.props.changeControlDeviceEditor({
            description: event.target.value,
        });
    };

    render() {
        const { model, groups } = this.props;
        const serialNumberInput = this.renderSerialNumberInput(model);
        const renderDeviceTypeInput = this.renderDeviceTypeInput(model);
        const inCustomerFleetInput = this.renderInCustomerFleetInput(model);
        const updatesActicveInput = this.renderUpdatesActiveInput(model);
        const testReleasesActiveInput = this.renderTestReleaseActiveInput(model);
        const groupsInput = this.renderGroupsInput(model, groups);
        const descriptionInput = this.renderDescriptionInput(model);
        return (
            <form>
                {serialNumberInput}
                {renderDeviceTypeInput}
                {groupsInput}
                {inCustomerFleetInput}
                {updatesActicveInput}
                {testReleasesActiveInput}
                {descriptionInput}
            </form>
        );
    }

    renderSerialNumberInput(model) {
        if (!model.isNew) {
            return;
        }
        const helpBlock = !model.isValidSerialNumber ? <FormattedMessage id='intl-msg:invalidSerialNumber'/>
            : undefined;
        return (
            <div className='form-group form-group-serial-number'
                 validationState={model.isValidSerialNumber ? undefined : 'error'}>
                <label className='control-label'>
                    <FormattedMessage id='intl-msg:serialNumber'/>
                </label>
                <div>
                    <FormattedMessage id='intl-msg:serialNumber.prompt'>
                        {placeHolder =>
                            <input type='text'
                                   ref={this.serialNumberInput}
                                   className='form-control'
                                   value={model.serialNumber}
                                   placeholder={placeHolder}
                                   onChange={this.onSerialNumberChange}/>}
                    </FormattedMessage>
                </div>
                <span className='help-block'>
                    {helpBlock}
                </span>
            </div>
        );
    }

    renderInCustomerFleetInput(model) {
        return (
            <div className='form-group form-group-in-customer-fleet'>
                <div>
                    <Checkbox onClick={this.onInCustomerFleetChange} checked={model.inCustomerFleet}>
                        <FormattedMessage id='intl-msg:inCustomerFleet'/>
                    </Checkbox>
                </div>
            </div>
        );
    }

    renderDeviceTypeInput(model) {
        return (
            <div className='form-group form-group-in-customer-fleet'>
                <div>
                    <DeviceTypeFormItem value={model.type}
                                        onChange={this.onDeviceTypeFilterChange}/>
                </div>
            </div>
        );
    }

    renderUpdatesActiveInput(model) {
        return (
            <div className='form-group form-group-updates-active'>
                <div>
                    <Checkbox onClick={this.onUpdatesActiveChange} checked={!model.updatesActive}>
                        <FormattedMessage id='intl-msg:blocked'/>
                    </Checkbox>
                </div>
            </div>
        );
    }

    renderTestReleaseActiveInput(model) {
        return (
            <div className='form-group form-group-test-release-active'>
                <div>
                    <Checkbox onClick={this.onTestReleasesActiveChange} checked={model.testReleasesActive}>
                        <FormattedMessage id='intl-msg:testReleasesActive'/>
                    </Checkbox>
                </div>
            </div>
        );
    }

    renderGroupsInput(model, groups) {
        const options = map(group => ({
            id: group.name,
            label: group.name,
            selected: !!find(groupName => {
                return isEqual(groupName, group.name);
            }, model.deviceGroups),
        }), groups);
        return (
            <div className='form-group form-group-groups'>
                <label className='control-label'>
                    <FormattedMessage id='intl-msg:groups'/>
                </label>
                <div className='form-group'>
                    <Multiselect placeholder={<FormattedMessage id='intl-msg:multiSelect.prompt'/>}
                                 options={options}
                                 onChange={this.onDeviceGroupsChange}/>
                </div>
            </div>
        );
    }

    renderDescriptionInput(model) {
        return (
            <div className='form-group form-group-description'>
                <label className='control-label'>
                    <FormattedMessage id='intl-msg:description'/>
                </label>
                <FormattedMessage id='intl-msg:description.prompt'>
                    {placeHolder =>
                        <input type='text'
                               ref={this.descriptionInput}
                               className='form-control'
                               value={model.description}
                               placeholder={placeHolder}
                               onChange={this.onDescriptionChange}/>
                    }
                </FormattedMessage>
            </div>
        );
    }

    componentDidMount() {
        const { model } = this.props;
        if(model.isNew) {
            this.serialNumberInput.current.focus();
        } else {
            this.descriptionInput.current.focus();
        }
    }
}

export default DeviceEditorForm;

DeviceEditorForm.defaultProps = {
    // props
    model: {},
    groups: [],
    // functions
    changeControlDeviceEditor: noop,
};

DeviceEditorForm.propTypes = {
    // props
    model: PropTypes.object,
    groups: PropTypes.arrayOf(Group),
    // functions
    changeControlDeviceEditor: PropTypes.func,
};
