import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import noop from 'lodash/fp/noop';

import ListTable from '~/features/base/components/table/ListTable';
import { fileColumnDescriptors } from '~/features/artifacts/constants/deliverableColumnDescriptors';
import { FormattedMessage } from 'react-intl';
import size from 'lodash/fp/size';
import {
    deleteDeliverableFile,
    downloadDeliverableFile,
    showDeliverableFileDeletionDialog,
} from '~/features/artifacts/actions/deliverableFileActions';
import { connect } from 'react-redux';
import { getDeliverableManagementBackend } from '~/features/base/selectors/backendSelectors';
import {
    canDeleteDeliverableFilesSelector,
    canReadDeliverableFilesSelector,
} from '~/features/artifacts/selectors/deliverableFileSelectors';
import { canManageDeliverablesSelector } from '~/features/user/selectors/permissionSelectors';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';

export class DeliverableFilesTable extends PureComponent {

    constructor(props) {
        super(props);

        this.actionsColumn = {
            id: 'actions',
            field: 'fileName',
            className: 'table-action',
            format: field => {
                const items = [];
                const { canDeleteDeliverableFiles, canReadDeliverableFiles, canManageDeliverables } = this.props;
                if (canDeleteDeliverableFiles && canManageDeliverables) {
                    items.push({
                        value: (
                            <div onClick={this.onDeleteButtonClick} data-key={field}>
                                <span className='rioglyph rioglyph-trash margin-right-10'></span>
                                <span><FormattedMessage id='intl-msg:deleteFile'/></span>
                            </div>
                        ),
                    });
                }
                if (canReadDeliverableFiles) {
                    items.push({
                        value: (
                            <div onClick={this.onDownloadButtonClick} data-key={field}>
                                {/*<div data-key={field}>*/}
                                {/*    <a download href={this.createDownloadLink(field)}>*/}
                                <span className='rioglyph rioglyph-download margin-right-10'></span>
                                <span><FormattedMessage id='intl-msg:download'/></span>
                                {/*</a>*/}
                            </div>
                        ),
                    });
                }
                if (size(items)) {
                    return (
                        <ButtonDropdown
                            title={<span className='rioglyph rioglyph-option-vertical'/>}
                            className='pull-right'
                            bsStyle='link'
                            iconOnly
                            items={items}/>
                    );
                }
            },
            formatLabel: () => '',
        };
    }

    // createDownloadLink = (fileName) => {
    //     const url = `${this.props.serviceURL}/artifacts/v1`;
    //     return `${url}/${this.props.deliverableId}/files/${fileName}/download`;
    // };

    onDeleteButtonClick = (event) => {
        const fileName = event.currentTarget.getAttribute('data-key');
        this.props.showDeliverableFileDeletionDialog({
            deliverableId: this.props.deliverableId,
            fileName,
        });
    };

    onDownloadButtonClick = (event) => {
        const fileName = event.currentTarget.getAttribute('data-key');
        this.props.downloadDeliverableFile(this.props.deliverableId, fileName);
    };

    render() {
        const { files } = this.props;
        if (files) {
            const columns = [...fileColumnDescriptors, this.actionsColumn];
            return (
                <ListTable items={files}
                           itemKey='fileName'
                           columnDescriptors={columns}/>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    serviceURL: getDeliverableManagementBackend(state),
    canReadDeliverableFiles: canReadDeliverableFilesSelector(state),
    canDeleteDeliverableFiles: canDeleteDeliverableFilesSelector(state),
    canManageDeliverables: canManageDeliverablesSelector(state),
});

export const mapDispatchToProps = (dispatch) => {
    return {
        showDeliverableFileDeletionDialog: payload => {
            dispatch(showDeliverableFileDeletionDialog(payload));
        },
        deleteDeliverableFile: (deliverableId, fileName) => {
            dispatch(deleteDeliverableFile(deliverableId, fileName));
        },
        downloadDeliverableFile: (deliverableId, fileName) => {
            dispatch(downloadDeliverableFile(deliverableId, fileName));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableFilesTable);

DeliverableFilesTable.defaultProps = {
    // props
    deliverableId: '',
    files: [],
    canReadDeliverableFiles: false,
    canDeleteDeliverableFiles: false,
    // functions
    deleteDeliverableFile: noop,
    downloadDeliverableFile: noop,
    canManageDeliverables: noop,
};

DeliverableFilesTable.propTypes = {
    // props
    deliverableId: PropTypes.string.isRequired,
    files: PropTypes.array,
    canReadDeliverableFiles: PropTypes.bool,
    canDeleteDeliverableFiles: PropTypes.bool,
    // functions
    deleteDeliverableFile: PropTypes.func,
    downloadDeliverableFile: PropTypes.func,
    canManageDeliverables: PropTypes.func,
};
