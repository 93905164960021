import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { permissionsSelector, rolesSelector } from '~/features/user/selectors/permissionSelectors';

import Chip from '~/features/base/components/Chip';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';

export class PermissionsOverviewDialog extends PureComponent {
    renderTitle() {
        return <FormattedMessage id='intl-msg:permissionsOverview'/>;
    }

    renderBody() {
        const { roles, permissions } = this.props;
        return (
            <div>
                <div>
                    <h5>
                        <FormattedMessage id='intl-msg:permissionsOverviewDialogIntro'/>
                        <a href='https://collaboration.msi.audi.com/confluence/pages/viewpage.action?pageId=133310497' target="_blank">User, Roles & Rights</a>
                    </h5>
                    <h2>
                        <FormattedMessage id='intl-msg:roles'/>
                    </h2>
                    {
                        roles.map(role => {
                            return <Chip className='margin-5'>{role}</Chip>;
                        })
                    }
                </div>
                <div className='padding-top-20'>
                    <h2>
                        <FormattedMessage id='intl-msg:permissions'/>
                    </h2>
                    {
                        permissions.map(permission => {
                            return <div className='text-color-dark'>{permission}</div>;
                        })
                    }
                </div>
            </div>
        );
    }

    renderFooter() {
        return (
            <div>
                <button className='btn btn-default' onClick={this.props.togglePermissionsOverDialog}>
                    <FormattedMessage id='intl-msg:close'/>
                </button>
            </div>
        );
    }

    render() {
        const { togglePermissionsOverDialog } = this.props;
        const title = this.renderTitle();
        const body = this.renderBody();
        const footer = this.renderFooter();
        return (
            <Dialog className='device-editor-dialog'
                    show={true}
                    onHide={togglePermissionsOverDialog}
                    title={title}
                    body={body}
                    footer={footer}
                    useOverflow
                    bsSize={Dialog.SIZE_FULL_SCREEN}
            />
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        roles: rolesSelector(state),
        permissions: permissionsSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsOverviewDialog);

PermissionsOverviewDialog.defaultProps = {
    // props
    // functions
    togglePermissionsOverDialog: noop,
};

PermissionsOverviewDialog.propTypes = {
    // props
    // functions
    togglePermissionsOverDialog: PropTypes.func,
};
