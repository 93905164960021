export const FETCH_DEVICES_WITH_LOGS_COUNT = 'FETCH_DEVICES_WITH_LOGS_COUNT';
export const FETCH_DEVICES_WITH_LOGS = 'FETCH_DEVICES_WITH_LOGS';
export const FETCH_DEVICE_LOGS = 'FETCH_DEVICE_LOGS';
export const DOWNLOAD_DEVICE_LOG_FILE = 'DOWNLOAD_DEVICE_LOG_FILE';

export const fetchDevicesWithLogsCount = () => ({
    type: FETCH_DEVICES_WITH_LOGS_COUNT,
});

export const fetchDevicesWithLogs = ({ page, size }) => ({
    type: FETCH_DEVICES_WITH_LOGS,
    page,
    size,
});

export const fetchDeviceLogs = ({ serialNumber }) => ({
    type: FETCH_DEVICE_LOGS,
    payload: {
        serialNumber,
    },
});

export const downloadDeviceLogFile = (serialNumber, logFile) => ({
    type: DOWNLOAD_DEVICE_LOG_FILE,
    payload: {
        serialNumber,
        logFile,
    },
});
