import * as React from 'react';
import { useEffect, useMemo, useReducer } from 'react';

import { FormattedMessage } from 'react-intl';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import {
    useFetchDeliverableIdsQuery,
    useLazyFetchDeliverableVersionsQuery
} from '~/api/deliverables/DeliverablesManagement.api';
import { DeliverableSearchCriteriaDeliverableTypeEnum } from '~/api/models/deliverable-search-criteria';
import { SemanticVersion } from '~/api/models';
import { Bundle } from '~/api/models/Bundle';

interface EssentialImageFormItemProps {
    value: Bundle;
    onChange: (lastEssentialImageReported: Bundle) => void;
}

interface DeliverableVersionModel {
    [p: string]: { id: string, label: string, value: SemanticVersion }[] | undefined;
}

const EssentialImageFormItem = ({ value, onChange }: EssentialImageFormItemProps) => {

    const [deliverableVersions, setDeliverableVersions] = useReducer((prev, next) => {
        return { ...prev, ...next };
    }, {} as DeliverableVersionModel);

    const { data: bundleList } = useFetchDeliverableIdsQuery({
        deliverableType: DeliverableSearchCriteriaDeliverableTypeEnum.BUNDLE,
    });
    const [getDeliverableVersions] = useLazyFetchDeliverableVersionsQuery();

    useEffect(() => {
        if (value?.id) {
            getVersions(value.id, DeliverableSearchCriteriaDeliverableTypeEnum.BUNDLE);
        }
    }, []);

    const bundleIdsOptions = useMemo(() => {
        return bundleList?.content?.map(deliverableId => ({
            id: deliverableId,
            label: deliverableId
        })) || [];
    }, [bundleList]);

    const getVersions = (deliverableId = '', deliverableType = '') => {
        getDeliverableVersions({ deliverableId, deliverableType }, true).then(result => {
            const versionsList = result?.data?.content.map(version => {
                return {
                    label: toShortSemanticVersion(version),
                    value: version,
                    id: toShortSemanticVersion(version)
                };
            });
            setDeliverableVersions({ ...deliverableVersions, [deliverableId]: versionsList });
        });
    };

    const onDeliverableVersionChange = (value: string | undefined) => {
        if (value && !deliverableVersions[value]) {
            getVersions(value, DeliverableSearchCriteriaDeliverableTypeEnum.BUNDLE);
        }
        onChange({ id: value });
    };
    return (
        <div className='form-group form-group-semantic-version-range display-flex flex-row  gap-20'>
            <div className='flex-basis-60pct'>
                <label className='control-label'><FormattedMessage id='intl-msg:lastReportedEssentialImage.id'/></label>
                <Select
                    useFilter
                    useClear
                    placeholder={<FormattedMessage id='intl-msg:lastReportedEssentialImage.id'/>}
                    options={bundleIdsOptions}
                    value={[value?.id || '']}
                    onChange={(value) => {
                        onDeliverableVersionChange(value?.id);
                    }}/>
            </div>
            <div className='flex-basis-40pct'>
                <label className='control-label'><FormattedMessage
                    id='intl-msg:lastReportedEssentialImage.version'/></label>
                <Select
                    useFilter
                    useClear
                    placeholder={<FormattedMessage id='intl-msg:lastReportedEssentialImage.version'/>}
                    disabled={!value?.id}
                    options={value?.id ? deliverableVersions[value?.id] : []}
                    value={[toShortSemanticVersion(value?.version)]}
                    onChange={(v) => onChange({ ...value, version: v.id })}/>
            </div>
        </div>
    );
};

export default EssentialImageFormItem;
