import noop from 'lodash/fp/noop';

import moment from 'moment';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { ENTITY_UPDATE_EVENT_SCOPE_ALL } from '~/features/base/constants/entities';

import { fetchUpdateEventReport } from '~/features/updateEvents/actions/updateEventActions';

import {
    updateEventReportSelector,
    updateEventReportsLoadingSelector,
} from '~/features/updateEvents/selectors/updateEventSelectors';

import UpdateEventReport from '~/features/updateEvents/components/UpdateEventReport';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import defaults from 'lodash/fp/defaults';
import { DAILY, HOURLY, MONTHLY, WEEKLY } from '~/features/updateEvents/constants/timeIntervals';

export class UpdateEventReportDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            timeInterval: DAILY,
            from: moment().utc().subtract(7, 'days').startOf('day').valueOf(),
            to: moment().utc().endOf('day').valueOf(),
        };
    }

    onChangeReportTimeInterval = (timeInterval) => {
        this.setState({
                timeInterval: timeInterval.id,
            }, () => this.fetchReport(),
        );
    };

    onChangeReportTimeRange = (startTime, endTime) => {
        const timeDifferenceInDays = endTime.diff(startTime, 'days');
        let timeInterval = this.state.timeInterval;
        if (timeDifferenceInDays === 0) {
            timeInterval = HOURLY;
        } else if (timeDifferenceInDays === 7) {
            timeInterval = DAILY;
        } else if (timeDifferenceInDays === 28) {
            timeInterval = WEEKLY;
        } else if (timeDifferenceInDays > 28) {
            timeInterval = WEEKLY;
        } else if (timeDifferenceInDays > 31) {
            timeInterval = MONTHLY;
        }
        this.setState({
                from: startTime.valueOf(),
                to: endTime.valueOf(),
                timeInterval,
            }, () => this.fetchReport(),
        );
    };

    render() {
        const body = this.renderBody();
        const footer = this.renderFooter();
        return (
            <Dialog className='update-event-report-dialog'
                    show={true}
                    showCloseButton={true}
                    bsSize={Dialog.SIZE_LG}
                    title={<FormattedMessage id='intl-msg:updateEventReport'/>}
                    body={body}
                    footer={footer}
                    onHide={this.props.hideModal}
                    useOverflow
            />
        );
    }

    renderBody() {
        const { timeInterval, from, to } = this.state;
        const {
            deliverableId, deliverableVersion, updateEventCount, updateEventStats, updateEventReport,
            updateEventReportLoading, deliverableType,
        } = this.props;
        return (
            <UpdateEventReport deliverableId={deliverableId}
                               deliverableVersion={deliverableVersion}
                               deliverableType={deliverableType}
                               updateEventCount={updateEventCount}
                               updateEventStats={updateEventStats}
                               updateEventReport={updateEventReport}
                               updateEventReportLoading={updateEventReportLoading}
                               timeSpan={{ from, to }}
                               timeInterval={timeInterval}
                               onChangeReportMode={this.onChangeReportMode}
                               onChangeReportTimeInterval={this.onChangeReportTimeInterval}
                               onChangeReportTimeRange={this.onChangeReportTimeRange}
                               onHide={this.props.hideModal}/>
        );
    }

    renderFooter() {
        return (
            <div>
                <button className='btn btn-default' onClick={this.props.hideModal}>
                    <FormattedMessage id='intl-msg:close'/>
                </button>
            </div>
        );
    }

    fetchReport() {
        const { searchCriteria } = this.props;
        const { from, to, timeInterval } = this.state;
        const trendSearchCriteria = defaults(searchCriteria, {
            from,
            to,
        });
        this.props.fetchUpdateEventReport({
            searchCriteria: trendSearchCriteria,
            timeInterval,
            scope: this.props.scope,
        });
    }

    componentWillMount() {
        this.fetchReport();
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        updateEventReport: updateEventReportSelector(state, { scope: ownProps.scope }),
        updateEventReportLoading: updateEventReportsLoadingSelector(state, { scope: ownProps.scope }),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        fetchUpdateEventReport: (options) => {
            dispatch(fetchUpdateEventReport(options));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEventReportDialog);

UpdateEventReportDialog.defaultProps = {
    // props
    scope: ENTITY_UPDATE_EVENT_SCOPE_ALL,
    searchCriteria: {},
    updateEventReport: {},
    updateEventReportLoading: PropTypes.bool,
    // functions
    fetchUpdateEventReport: noop,
};

UpdateEventReportDialog.propTypes = {
    // props
    scope: PropTypes.string,
    deliverableId: PropTypes.string,
    deliverableVersion: PropTypes.object,
    // TODO Rethink whether search criteria are needed with deliverableId and deliverableVersion as props
    searchCriteria: PropTypes.object,
    updateEventReport: PropTypes.object,
    updateEventReportLoading: PropTypes.bool,
    // functions
    fetchUpdateEventReport: PropTypes.func,
};
