import findKey from 'lodash/fp/findKey';
import values from 'lodash/fp/values';

import React from 'react';
import { FormattedMessage } from 'react-intl';

export const READ_ONLY = 'READ_ONLY';
export const FINGERPRINT = 'FINGERPRINT';
export const AUTO_DETECTION = 'AUTO_DETECTION';
export const READ_AND_WRITE = 'READ_AND_WRITE';

export const sysParamGroups = {
    [READ_ONLY]: {
        id: READ_ONLY,
        value: READ_ONLY,
        label: <FormattedMessage id='intl-msg:readOnly'/>,
        className: 'label-danger',
        min: 0x0000,
        max: 0x1FFF,
        hasPlannedValue: false,
        hasDefault: false,
    },
    [FINGERPRINT]: {
        id: FINGERPRINT,
        value: FINGERPRINT,
        label: <FormattedMessage id='intl-msg:fingerprint'/>,
        className: 'label-info',
        min: 0x2000,
        max: 0x3FFF,
        hasPlannedValue: false,
        hasDefault: false,
    },
    [AUTO_DETECTION]: {
        id: AUTO_DETECTION,
        value: AUTO_DETECTION,
        label: <FormattedMessage id='intl-msg:autoDetection'/>,
        className: 'label-warning',
        min: 0x8000,
        max: 0x9FFF,
        hasPlannedValue: true,
        hasDefault: false,
    },
    [READ_AND_WRITE]: {
        id: READ_AND_WRITE,
        value: READ_AND_WRITE,
        label: <FormattedMessage id='intl-msg:readAndWrite'/>,
        className: 'label-success',
        min: 0xA000,
        max: 0xFFFF,
        hasPlannedValue: true,
        hasDefault: true,
    },
};
export const accessKeyToGroup = (accessKey) => {
    return findKey(sysParamGroup => accessKey >= sysParamGroup.min && accessKey <= sysParamGroup.max, sysParamGroups);
};
export const accessKeyInGroup = (accessKey, group) => {
    return accessKeyToGroup(accessKey) === group;
};
export const accessKeyHasDefault = (accessKey) => {
    return sysParamGroups[accessKeyToGroup(accessKey)].hasDefault;
};
export const accessKeyHasPlannedValue = (accessKey) => {
    return sysParamGroups[accessKeyToGroup(accessKey)].hasPlannedValue;
};

export const createSysParamGroupOptions = () => values(sysParamGroups);
