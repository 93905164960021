/**
 * Action type for showing the control device quick whitelisting dialog
 * @type {string}
 */
export const SHOW_CONTROL_DEVICE_QUICK_WHITELISTING = 'SHOW_CONTROL_DEVICE_QUICK_WHITELISTING';

/**
 * Action type for resetting the control device quick whitelisting model
 * @type {string}
 */
export const RESET_CONTROL_DEVICE_QUICK_WHITELISTING = 'RESET_CONTROL_DEVICE_QUICK_WHITELISTING';

/**
 * Action type for changing the control device quick whitelisting model
 * @type {string}
 */
export const CHANGE_CONTROL_DEVICE_QUICK_WHITELISTING = 'CHANGE_CONTROL_DEVICE_QUICK_WHITELISTING';

/**
 * Action type for adding device to deliverable whitelist
 * @type {string}
 */
export const ADD_CONTROL_DEVICE_TO_DELIVERABLE_WHITELIST = 'ADD_CONTROL_DEVICE_TO_DELIVERABLE_WHITELIST';

/**
 * Action type for removing device to deliverable whitelist
 * @type {string}
 */
export const REMOVE_CONTROL_DEVICE_TO_DELIVERABLE_WHITELIST = 'REMOVE_CONTROL_DEVICE_TO_DELIVERABLE_WHITELIST';

/**
 * Action type for revoking uninstall package deliverable
 * @type {string}
 */
export const REVOKE_UNINSTALL_PACKAGE_DELIVERABLE = 'REVOKE_UNINSTALL_PACKAGE_DELIVERABLE';

/**
 * Action creator for showing control device quick whitelisting dialog
 * @param serialNumber of device
 * @returns {{type: string}} The action for showing control device quick whitelisting dialog
 */
export function showControlDeviceQuickWhitelisting(serialNumber, mode, deviceType) {
    return {
        type: SHOW_CONTROL_DEVICE_QUICK_WHITELISTING,
        serialNumber,
        mode,
        deviceType,
    };
}

/**
 * Action creator for resetting control device editor
 * @returns {{type: string}} The action for resetting control device editor
 */
export function resetControlDeviceQuickWhitelisting() {
    return {
        type: RESET_CONTROL_DEVICE_QUICK_WHITELISTING,
    };
}

/**
 * Action creator for changing control device quick whitelisting
 * @param {object} payload The payload
 * @returns {{type: string}} The action for changing control device quick whitelisting
 */
export function changeControlDeviceQuickWhitelisting(payload) {
    return {
        type: CHANGE_CONTROL_DEVICE_QUICK_WHITELISTING,
        payload,
    };
}

/**
 * Action creator for changing control device quick whitelisting
 * @param {object} payload The payload
 * @returns {{type: string}} The action for changing control device quick whitelisting
 */
export function addControlDeviceToDeliverableWhitelist(payload) {
    return {
        type: ADD_CONTROL_DEVICE_TO_DELIVERABLE_WHITELIST,
        payload,
    };
}

/**
 * Action creator for changing control device quick whitelisting
 * @param {object} payload The payload
 * @returns {{type: string}} The action for changing control device quick whitelisting
 */
export function removeControlDeviceFromDeliverableWhitelist(payload) {
    return {
        type: REMOVE_CONTROL_DEVICE_TO_DELIVERABLE_WHITELIST,
        payload,
    };
}

/**
 * Action creator for revoking uninstall package deliverable
 * @param {object} payload The payload
 * @returns {{type: string}} The action for revoking uninstall package deliverable
 */
export function revokeUninstallPackageDeliverable(payload) {
    return {
        type: REVOKE_UNINSTALL_PACKAGE_DELIVERABLE,
        payload,
    };
}
