import React from 'react';
import ListTable from '../../base/components/table/ListTable';
import { LiveRolloutsColumnDescriptors } from '../constants/LiveRolloutsColumnDescriptors';
import { DISTROS_ROLLOUT } from '../../deliverables/constants/deliverablesRollout';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import size from 'lodash/fp/size';
import { FormattedMessage } from 'react-intl';
import SortDirection from '@rio-cloud/rio-uikit/lib/es/SortDirection';

interface LiveRolloutsTileProps {
    data: LiveRolloutModel[];
    label: string;
    deliverableType: string;
}

const LiveRolloutsTile = ({ data = [], label, deliverableType }: LiveRolloutsTileProps) => {
    const classes = `display-flex flex-column max-height-350 `;
    const labelWrapperClasses = 'position-absolute bottom-25 padding-left-20 padding-right-20 bg-darkest';
    const labelClasses = 'text-size-20 text-bold text-uppercase text-color-white';
    const items = data ? data.map(item => {
        return {
            deliverableVersion: {
                description: item.description,
                version: item.deliverableVersion,
                type: item.deliverableType,
                deliverableId: item.deliverableId,

            },
            deliverableType: item.deliverableType,
            version: item.deliverableVersion, //repeated because of sorting
            deliverableId: item.deliverableId,
            baseSoftwareVersion: item.baseSoftwareVersion,
            progress: {
                successfulRollouts: item.successfulRollouts,
                estimatedTargets: item.estimatedTargets,
                successfulRolloutsPercentage: item.successfulRolloutsPercentage
            }
        };

    }) : [];
    const columns = deliverableType !== DISTROS_ROLLOUT ?
        LiveRolloutsColumnDescriptors.filter((col) => col.id !== 'shortBaseSwVersion') :
        LiveRolloutsColumnDescriptors.filter((col) => col.id !== 'id');

    const emptyStateMsg = <FormattedMessage id='intl-msg:overview.rollout.emptyStateMessage'
                                            values={{
                                                deliverableType: deliverableType,
                                            }}/>;

    return (
        <>
            <div className='bg-white bg-tile height-500'>
                <div className={classes}>
                    <div className={'bg-white padding-25'}>
                        <div className={`height-400 overflow-y-scroll`}>
                            {size(items) ? <ListTable items={items}
                                                      itemKey='version'
                                                      columnDescriptors={columns}
                                                      allowSorting={true}
                                                      sortBy={(deliverableType !== DISTROS_ROLLOUT) ? 'id' : 'version'}
                                                      sortDir={deliverableType === DISTROS_ROLLOUT ? SortDirection.ASCENDING : SortDirection.DESCENDING}
                                /> :
                                <NotFoundState className='height-100pct align-items-center display-flex'
                                               outerClassName='border-none' headline='Nothing found'
                                               message={emptyStateMsg}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='position-relative'>
                <div className={labelWrapperClasses}>
                    <div>
                        <span className={labelClasses}>{label}</span>
                    </div>
                </div>
            </div>
        </>

    );
};

export default LiveRolloutsTile;
