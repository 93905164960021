export const ACTIVATED = 'ACTIVATED';
export const TEST = 'TEST';
export const BLOCKED = 'BLOCKED';

export const deviceStates = {
    [ACTIVATED]: {
        id: ACTIVATED,
        label: 'intl-msg:deviceState.activated',
        tooltip: 'intl-msg:deviceState.activated.tooltip',
        className: 'label-success',
    },
    [TEST]: {
        id: TEST,
        label: 'intl-msg:deviceState.test',
        tooltip: 'intl-msg:deviceState.test.tooltip',
        className: 'label-warning',
    },
    [BLOCKED]: {
        id: BLOCKED,
        label: 'intl-msg:deviceState.blocked',
        tooltip: 'intl-msg:deviceState.blocked.tooltip',
        className: 'label-default',
    },
};
