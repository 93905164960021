import { prepareHeaders } from '~/api/common/prepareHeaders';
import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import {
    getDeviceManagementBackend,
    getLogsBackend
} from '~/features/base/selectors/backendSelectors';

export const dynamicBaseQuery: BaseQueryFn<string | FetchArgs,
    unknown,
    FetchBaseQueryError> = async(args, api, extraOptions) => {
    const baseUrl = getDeviceManagementBackend(api.getState());
    return fetchBaseQuery(
        {
            baseUrl,
            prepareHeaders
        })(args, api, extraOptions);
};

export const dynamicLogBaseQuery: BaseQueryFn<string | FetchArgs,
    unknown,
    FetchBaseQueryError> = async(args, api, extraOptions) => {
    const baseUrl = getLogsBackend(api.getState());
    return fetchBaseQuery(
        {
            baseUrl,
            prepareHeaders
        })(args, api, extraOptions);
};
