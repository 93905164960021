import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// Routes
import {
     NEW_OVERVIEW_PATH,
} from '~/features/base/constants/routes';

// Overview
import { sendGoogleAnalyticsEvent } from '~/index';
import { useDataInterestCleaner } from '~/features/base/hooks/useDataInterestCleaner';
import OverviewContainer from "~/features/overviewNew/components/OverviewContainer";


export const OverviewRoutes = ({ match }) => {

    // send Google Analytics Event
    sendGoogleAnalyticsEvent('Overview Page', 'View Overview');
    useDataInterestCleaner();

    return (
        <Switch>
            <Route path={`${match.path}/${NEW_OVERVIEW_PATH}`} component={(props) => <OverviewContainer {...props}/>}/>
            <Redirect to={`${match.url}/${NEW_OVERVIEW_PATH}`}/>
        </Switch>
    );
};

export default OverviewRoutes;
