import { call, put, select, all, takeLatest } from 'redux-saga/effects';

import {
    FETCH_CONTROL_DEVICE_WITH_SYS_PARAMS, fetchControlDevice,
} from '~/features/devices/actions/controlDeviceActions';
import { fetchSysParamDefinitions } from '~/features/sysParams/actions/sysParamDefinitionActions';
import { fetchSysParamDefaultValues } from '~/features/sysParams/actions/sysParamDefaultValueActions';
import { fetchSysParamDeviceValues } from '~/features/sysParams/actions/sysParamDeviceValueActions';
import { fetchSysParamSystemValues } from '~/features/sysParams/actions/sysParamSystemValueActions';

import { doFetchControlDeviceSaga } from '~/features/devices/sagas/controlDeviceSaga';
import { doFetchSysParamDefinitionsSaga } from '~/features/sysParams/sagas/sysParamDefinitionsSaga';
import { doFetchSysParamDefaultValuesSaga } from '~/features/sysParams/sagas/sysParamDefaultValuesSaga';
import { doFetchSysParamDeviceValuesSaga } from '~/features/sysParams/sagas/sysParamDeviceValuesSaga';
import { doFetchSysParamSystemValuesSaga } from '~/features/sysParams/sagas/sysParamSystemValuesSaga';

import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';
import { shortBaseSwVersionOfDeviceSelector } from '~/features/baseSwVersions/selectors/baseSwVersionSelectors';

import { finishedAction, failedAction } from '~/features/higherorder/transforms/actionTransforms';

export function* getControlDeviceURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/control-device`;
}
export function* doFetchControlDeviceWithSysParamsSaga(action) {
    try {
        const { serialNumber } = action.payload;
        yield call(doFetchControlDeviceSaga, fetchControlDevice({ serialNumber }));
        const shortBaseSwVersion = yield select(shortBaseSwVersionOfDeviceSelector, { serialNumber });
        yield all([
            call(doFetchSysParamDefinitionsSaga, fetchSysParamDefinitions({ shortBaseSwVersion })),
            call(doFetchSysParamDefaultValuesSaga, fetchSysParamDefaultValues({ shortBaseSwVersion })),
            call(doFetchSysParamDeviceValuesSaga,
                fetchSysParamDeviceValues({ serialNumber, shortBaseSwVersion })),
            call(doFetchSysParamSystemValuesSaga,
                fetchSysParamSystemValues({ serialNumber, shortBaseSwVersion })),
        ]);
    } catch (error) {
        yield put(failedAction(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* fetchControlDeviceWithSysParamsSaga() {
    yield takeLatest(FETCH_CONTROL_DEVICE_WITH_SYS_PARAMS, doFetchControlDeviceWithSysParamsSaga);
}
