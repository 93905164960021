import React from 'react';
import DeliverableIdChip from '~/features/deliverables/components/DeliverableIdChip';
import PackageReleaseChip from '~/features/deliverables/components/PackageReleaseChip';
import YesOrNo from '~/features/base/components/YesOrNo';
import isString from 'lodash/fp/isString';
import { toSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import { DELIVERABLE_TYPE_APP } from '~/features/deliverables/constants/deliverablesParameters';

export const deviceManagedPackageColumnDescriptors = [{
    id: 'packageId',
    field: 'packageId',
    label: 'intl-msg:packageId',
    format: field => <DeliverableIdChip deliverableId={field} deliverableType={DELIVERABLE_TYPE_APP}/>,
}, {
    id: 'packageVersion',
    field: 'packageVersion',
    label: 'intl-msg:packageVersion',
    format: (field, item) => [
        field !== undefined ? <PackageReleaseChip packageId={item.packageId}
                                                  packageVersion={isString(field) ? toSemanticVersion(field) : field}/>
            : <YesOrNo/>,
        item.eligiblePackageVersion ? [
            <span className='margin-right-5'>→</span>,
            <PackageReleaseChip packageId={item.packageId} packageVersion={item.eligiblePackageVersion}/>,
        ] : null,
    ],
}];

export const devicePackageColumnDescriptors = [{
    id: 'packageId',
    field: 'packageId',
    label: 'intl-msg:packageId',
}, {
    id: 'packageVersion',
    field: 'packageVersion',
    label: 'intl-msg:packageVersion',
}];
