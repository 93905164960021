import compact from 'lodash/fp/compact';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import trim from 'lodash/fp/trim';
import join from 'lodash/fp/join';
import split from 'lodash/fp/split';
import size from 'lodash/fp/size';
import noop from 'lodash/fp/noop';

import classNames from 'classnames';

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { validateVINs } from '~/features/base/utils/vinValidator';
import uid from '~/features/base/utils/uid';

export class VINListFormItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value:  join(', ', props.vinList),
            isValid: validateVINs(props.vinList),
            focused: false,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.focused) {
            return {
                value: join(', ', nextProps.vinList),
                isValid: validateVINs(nextProps.vinList),
                focused: false,
            };
        }
        return prevState;
    }

    onFocus = () => {
        this.setState({
            focused: true,
        });
    };
    onBlur = () => {
        this.setState({
            focused: false,
        });
    };
    onChange = (event) => {
        const value = event.target.value;
        const vinList = flow(
            split(','),
            map(vinNumber => trim(vinNumber)),
            compact,
        )(value);
        const isValid = validateVINs(vinList);
        this.setState({
            value,
            isValid,
        });
        this.props.onChange({
            vinList,
            isValid,
        });
    };

    render() {
        const { label, vinList, disabled } = this.props;
        const { value, isValid/*, focused*/ } = this.state;

        const helpBlockMessage = !isValid ?
            <FormattedMessage id='intl-msg:listContainsInvalidVIN'/> : undefined;
        return (
            <div className={classNames('form-group', { 'has-error': !isValid })}>
                <label className='control-label'>
                    <FormattedMessage id={label}/>
                    <span className='margin-left-10'/>
                    <span className='badge bg-info'>{size(vinList)}</span>
                    {

                        /*focused ?
                            <span className='badge bg-danger'>{'Focused'}</span> : null*/
                    }
                </label>
                <FormattedMessage id='intl-msg:vinList.prompt'>
                    {placeHolder =>
                        <textarea className={classNames('form-control', { disabled })}
                                  value={value}
                                  placeholder={placeHolder}
                                  style={{ height: '100px' }}
                                  onFocus={this.onFocus}
                                  onBlur={this.onBlur}
                                  onChange={this.onChange}/>
                    }
                </FormattedMessage>
                <span className='help-block'>{helpBlockMessage}</span>
            </div>
        );
    }
}

export default VINListFormItem;

// TODO Obviously it doesn't work without knowledge about whitelistingInfo
VINListFormItem.defaultProps = {
    // props
    vinList: [],
    disabled: false,
    label: 'intl-msg:deviceWhitelistByVINs',
    // functions
    onChange: noop,
};

VINListFormItem.propTypes = {
    // props
    vinList: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool,
    label: PropTypes.string,
    // functions
    onChange: PropTypes.func,
};
