import {
    HIDE_VEHICLE_DETAILS_SIDEBAR,
    SET_SHOW_VEHICLE_DETAILS_SIDEBAR, TOGGLE_VEHICLE_DETAILS_SIDEBAR,
} from '~/features/oldVehicles/actions/ui/vehicleDetailsActions';
import { DETAILS } from '~/features/oldVehicles/components/devices/constants/deviceDetailsTabs';
import {
    HIDE_DEVICE_DETAILS_SIDEBAR,
    TOGGLE_DEVICE_DETAILS_SIDEBAR,
} from '~/features/devices/actions/ui/controlDeviceDetailsActions';

const defaultState = {
    showVehicleDetailsSidebar: false,
    currentVin: '',
    tabId: DETAILS,
};

export const showVehicleDetailsSidebar = (state, { vin }) => {
    const newState = {
        ...state,
        showVehicleDetailsSidebar: state.currentVin === vin ? false : true,
        currentVin: state.currentVin === vin ? '' : vin,
        tabId: DETAILS,
    };
    return newState;
};

export const setSelectedTabVehicleDetailsSidebar = (state, { tabId }) => {
    const newState = {
        ...state,
        tabId,
    };
    return newState;
};

export const toggleVehicleDetailsSidebar = (state) => {
    const newState = {
        ...state,
        showVehicleDetailsSidebar: !state.showVehicleDetailsSidebar,
    };
    return newState;
};

export const hideVehicleDetailsSidebar = (state) => {
    if (state.showVehicleDetailsSidebar) {
        const newState = {
            ...state,
            showVehicleDetailsSidebar: false,
        };
        return newState;
    }
    return state;
};

export const vehicleDetailsReducer = (state = defaultState, action = {}) => {
    switch (action.type) {
        case SET_SHOW_VEHICLE_DETAILS_SIDEBAR:
            return showVehicleDetailsSidebar(state, action);
        // case SET_SELECTED_TAB_DEVICE_DETAILS_SIDEBAR:
        //     return setSelectedTabVehicleDetailsSidebar(state, action);
        case TOGGLE_VEHICLE_DETAILS_SIDEBAR:
            return toggleVehicleDetailsSidebar(state, action);
        case HIDE_VEHICLE_DETAILS_SIDEBAR:
            return hideVehicleDetailsSidebar(state, action);
        // case HIDE_DEVICE_DETAILS_SIDEBAR:
        //     return hideVehicleDetailsSidebar(state, action);
        default:
            return state;
    }
};

export default vehicleDetailsReducer;
