import map from 'lodash/fp/map';

import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';

export const parseUpdateEvent = updateEvent => ({
    id: `${updateEvent.hwSerial}_${updateEvent.deliverableId}_${updateEvent.timestamp}`,
    shortPreviousVersion: toShortSemanticVersion(updateEvent.previousVersion),
    shortTargetVersion: toShortSemanticVersion(updateEvent.targetVersion),
    shortActualVersion: toShortSemanticVersion(updateEvent.actualVersion),
    ...updateEvent,
});

export const parseUpdateEvents = packages => map(parseUpdateEvent, packages);
