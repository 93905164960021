import React from 'react';
import map from 'lodash/map';

import PackageType from '~/features/deliverables/components/PackageType';
import DeliverableIdChip from '~/features/deliverables/components/DeliverableIdChip';
import PackageReleaseChip from '~/features/deliverables/components/PackageReleaseChip';
import { toDistroVersion, toSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import Chip from '~/features/base/components/Chip';
import FileType from '~/features/deliverables/components/FileType';
import DistroType from '~/features/deliverables/components/DistroType';
import {
    DIRECT_BLACKLISTED,
    DIRECT_WHITELISTED,
    GROUP_BLACKLISTED,
    GROUP_WHITELISTED,
} from '~/features/devices/constants/deliverablesReferenceTypes';
import GroupChipList from '~/features/groups/components/GroupChipList';
import DeliverableReferenceType from '~/features/devices/components/DeliverableReferenceType';
import FileReleaseChip from '~/features/deliverables/components/FileReleaseChip';
import DistroChip from '~/features/deliverables/components/DistroChip';
import Cm4gType from '~/features/deliverables/components/Cm4gType';
import BundleType from '~/features/deliverables/components/BundleType';
import DeliverableChip from '~/features/deliverables/components/DeliverableChip';
import { BUNDLE_PATH, CM4G_PATH } from '~/features/base/constants/routes';

export const deliverablesColumnDescriptors = [{
    id: 'deliverableId',
    field: 'deliverableId',
    label: 'intl-msg:deliverableId',
    className: 'col-md-2',
    format: (field, item) => {
        return <DeliverableIdChip deliverableId={field} deliverableType={item.deliverableType.toLowerCase()}/>;

    },
}, {
    id: 'deliverableType',
    field: 'deliverableType',
    label: 'intl-msg:deliverableType',
    format: (field) => {
        switch (field) {
            case 'APP':
                return <PackageType packageType={field}/>;
            case 'DISTRO':
                return <DistroType/>;
            case 'FILE':
                return <FileType/>;
            case 'CM4G':
                return <Cm4gType/>;
            case 'BUNDLE':
                return <BundleType/>;
            default:
                return <Chip children={'Missing type'}/>;
        }
    },
}, {
    id: 'deliverableVersion',
    field: 'deliverableVersion',
    label: 'intl-msg:deliverableVersion',
    format: (field, item) => {
        switch (item.deliverableType) {
            case 'APP':
                return <PackageReleaseChip packageId={item.deliverableId} packageVersion={toSemanticVersion(field)}/>;
            case 'DISTRO':
                return <DistroChip distroVersion={toDistroVersion(field)}/>;
            case 'FILE':
                return <FileReleaseChip fileId={item.deliverableId} fileVersion={field}/>;
            case 'CM4G':
                return <DeliverableChip deliverableType={CM4G_PATH} deliverableId={item.deliverableId}
                                        deliverableVersion={field}/>;
            case 'BUNDLE':
                return <DeliverableChip deliverableType={BUNDLE_PATH} deliverableId={item.deliverableId}
                                        deliverableVersion={field}/>;
            default:
                return <Chip children={'Missing type'}/>;
        }
    },
}, {
    id: 'referenceTypesAndGroups',
    field: 'referenceTypesAndGroups',
    // label: 'intl-msg:deliverableVersion',
    format: (field) => {
        let elements = [];
        map(field, (item, referenceType) => {
            switch (referenceType) {
                case DIRECT_WHITELISTED:
                    elements.push(<DeliverableReferenceType
                        className={'margin-5 bg-white text-color-darkest border-color-darkest'}
                        label={'intl-msg:deliverableDirectlyWhitelisted'}
                        toolTipMessage={'intl-msg:deliverableDirectlyWhitelisted.tooltip'}/>);
                    return;
                case DIRECT_BLACKLISTED:
                    elements.push(<DeliverableReferenceType
                        className={'margin-5 bg-darkest text-color-white'}
                        label={'intl-msg:deliverableDirectlyBlacklisted'}
                        toolTipMessage={'intl-msg:deliverableDirectlyBlacklisted.tooltip'}/>);
                    return;
                case GROUP_WHITELISTED:
                    elements.push(<GroupChipList groups={item.groups}
                                                 generalToolTip={'intl-msg:deliverableGroupWhitelisted.tooltip'}
                                                 className={'bg-white border-color-darkest'}
                                                 bsStyle={'grey'}/>);
                    return;
                case GROUP_BLACKLISTED:
                    elements.push(<GroupChipList groups={item.groups}
                                                 generalToolTip={'intl-msg:deliverableGroupBlacklisted.tooltip'}
                                                 className={'bg-darkest'}
                                                 bsStyle={'white'}/>);
                // eslint-disable-next-line no-fallthrough
                default:

            }
        });
        return elements;
    },
}];
