import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { mergeEntity } from '~/features/higherorder/actions/entityActions';

import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';
import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';

import { doHandleErrorSaga } from '~/features/base/sagas/sagaUtil';

import { ENTITY_VEHICLE_VARIANTS_ALL } from '~/features/base/constants/entities';
import { finishedAction } from '~/features/higherorder/transforms/actionTransforms';
import { FETCH_VEHICLE_VARIANTS } from '~/features/vehicleVariants/actions/vehicleVariantsActions';
import { allVehicleVariants } from '~/features/vehicleVariants/constants/vehicleVariantsConstants';

export function* getControlDeviceURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/control-device`;
}

export function* doFetchVehicleVariantsSaga(action) {
    try {
        const vehicleVariantsServiceURL = yield call(getControlDeviceURL);
        // const response = yield call(getHTTP, `${vehicleVariantsServiceURL}/variants`);
        // yield put(mergeEntity(response.content, { entityName: ENTITY_VEHICLE_VARIANTS_ALL }));
        yield put(mergeEntity(allVehicleVariants, { entityName: ENTITY_VEHICLE_VARIANTS_ALL }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* fetchVehicleVariantsSaga() {
    yield takeLatest(FETCH_VEHICLE_VARIANTS, doFetchVehicleVariantsSaga);
}
