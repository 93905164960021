import values from 'lodash/fp/values';
import { FormattedMessage } from 'react-intl';
import React from 'react';

export const HIGH = 'HIGH';
export const NORMAL = 'NORMAL';
export const LOW = 'LOW';

export const downloadPriorities = {
    [HIGH]: {
        id: HIGH,
        value: HIGH,
        label: <FormattedMessage id='intl-msg:priority.high'/>,
        tooltip: 'intl-msg:priority.tooltip',
        className: 'label-info',
    },
    [NORMAL]: {
        id: NORMAL,
        value: NORMAL,
        label: <FormattedMessage id='intl-msg:priority.normal'/>,
        tooltip: 'intl-msg:priority.tooltip',
        className: 'label-info',
    },
    [LOW]: {
        id: LOW,
        value: LOW,
        label: <FormattedMessage id='intl-msg:priority.low'/>,
        tooltip: 'intl-msg:priority.tooltip',
        className: 'label-info',
    },
};

export const createDownloadPrioritiesOptions = () => values(downloadPriorities);
