import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import includes from 'lodash/fp/includes';

import {
    PERMISSION_SYS_PARAM_DEFINITIONS_READ,
    PERMISSION_SYS_PARAM_DEFINITIONS_CREATE,
    PERMISSION_SYS_PARAM_DEFINITIONS_UPDATE,
    PERMISSION_SYS_PARAM_DEFINITIONS_DELETE,
} from '~/features/base/constants/permissions';

import { permissionsSelector } from '~/features/user/selectors/permissionSelectors';
import { entityStoreSelector } from '~/features/higherorder/selectors/dataSelectors';
import { ENTITY_SYS_PARAM_DEFINITION } from '~/features/base/constants/entities';
import { createSelector } from 'reselect';
import {
    shortBaseSwVersionOfDistroSelector,
    shortBaseSwVersionOfDeviceSelector,
} from '~/features/baseSwVersions/selectors/baseSwVersionSelectors';

const sysParamDefinitionEntitiesSelector = state => entityStoreSelector(state)[ENTITY_SYS_PARAM_DEFINITION];

export const canReadSysParamDefinitionsSelector = state =>
    includes(PERMISSION_SYS_PARAM_DEFINITIONS_READ, permissionsSelector(state));

export const canCreateSysParamDefinitionsSelector = state =>
    includes(PERMISSION_SYS_PARAM_DEFINITIONS_CREATE, permissionsSelector(state));

export const canUpdateSysParamDefinitionsSelector = state =>
    includes(PERMISSION_SYS_PARAM_DEFINITIONS_UPDATE, permissionsSelector(state));

export const canDeleteSysParamDefinitionsSelector = state =>
    includes(PERMISSION_SYS_PARAM_DEFINITIONS_DELETE, permissionsSelector(state));

export const sysParamDefinitionSelector = (state, ownProps) => {
    const definition = get([ownProps.shortBaseSwVersion, ownProps.accessKey],
        sysParamDefinitionEntitiesSelector(state));
    return definition;
};

export const sysParamDefinitionEntitiesOfDistroSelector = createSelector(
    [sysParamDefinitionEntitiesSelector, shortBaseSwVersionOfDistroSelector],
    (entities, shortBaseSwVersion) => {
        return getOr({}, shortBaseSwVersion, entities);
    }
);
export const sysParamDefinitionEntitiesOfDeviceSelector = createSelector(
    [sysParamDefinitionEntitiesSelector, shortBaseSwVersionOfDeviceSelector],
    (entities, shortBaseSwVersion) => {
        return getOr({}, shortBaseSwVersion, entities);
    }
);

export const sysParamDefinitionEditorSelector = state => state.ui.sysParamDefinitionEditor;
