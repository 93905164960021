import {
    DELIVERABLE_TYPE_APP, DELIVERABLE_TYPE_BUNDLE, DELIVERABLE_TYPE_CM4G,
    DELIVERABLE_TYPE_FILE,
} from '~/features/deliverables/constants/deliverablesParameters';
import { DELIVERABLE_TYPE_DISTRO } from '~/features/deliverables/constants/deliverablesParameters';
import { env } from '~/env';
import {CM4, TBM3} from "~/features/devices/constants/deviceTypes";

const defaultDevice = {
    [DELIVERABLE_TYPE_DISTRO]: TBM3,
    [DELIVERABLE_TYPE_CM4G]: CM4,
    [DELIVERABLE_TYPE_BUNDLE]: CM4,
}

export const getDefaultDeviceType = (deliverableType) => {
    return deliverableType ? defaultDevice[deliverableType] : '';
}
