import compact from 'lodash/fp/compact';
import size from 'lodash/fp/size';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';


import { OVERVIEW_PATH, DEVICES_WITH_LOGS_PATH } from '~/features/base/constants/routes';

import PageHeader from '~/features/base/components/PageHeader';
import PageHeaderLink from '~/features/base/components/PageHeaderLink';

import LogRequestsListToolbar from '~/features/deviceLogs/components/LogRequestsListToolbar';
import LogRequestsTable from '~/features/deviceLogs/components/LogRequestsTable';
import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';

/**
 * List of log requests
 */
export class LogRequestsList extends PureComponent {

    render() {
        const content = this.renderContent();
        return (
            <div className='log-requests-list row'>
                <PageHeader pageTitle={<FormattedMessage id='intl-msg:openLogRequests'/>}
                    pageLink={
                        <PageHeaderLink to={`/${OVERVIEW_PATH}`}>
                            <FormattedMessage id='intl-msg:backToOverview'/>
                        </PageHeaderLink>
                    }
                    pageTitleLinkAddon={
                        <Link to={`/${OVERVIEW_PATH}/${DEVICES_WITH_LOGS_PATH}`}>
                            <FormattedMessage id='intl-msg:devicesWithLogs'/>
                            {' '}
                            <span className='rioglyph rioglyph-chevron-right' aria-hidden='true'></span>
                        </Link>
                    }/>
                {content}
            </div>
        );
    }

    renderContent() {
        const { canCreateDeviceActions, deviceActionsLoading, deviceActions, onShowDeviceActionEditor } = this.props;

        if (!deviceActionsLoading && !size(deviceActions)) {
            return [
                <div className='col-md-12'>
                    <NotFoundState fullWidth
                        headline={<FormattedMessage id='intl-msg:nothingFound'/>}
                        message={<FormattedMessage id='intl-msg:devices.notFound'/>}
                        buttons={compact([canCreateDeviceActions ? {
                            text: <FormattedMessage id= 'intl-msg:createLogRequest'/>,
                            onClick: onShowDeviceActionEditor,
                        } : null])}/>
                </div>,
            ];
        }
        return [
            <DefaultWhiteColumn className='padding-top-20 clearfix'>
                <LogRequestsListToolbar
                    canCreateDeviceActions={canCreateDeviceActions}
                    onShowDeviceActionEditor={onShowDeviceActionEditor}>
                    <span className='padding-top-5'>
                        <FormattedMessage id='intl-msg:totalLogRequests.phrase'
                            values={{
                                totalCount: this.props.deviceActions.length,
                            }}/>
                    </span>
                    <div className='display-inline-block padding-left-5'>
                        {this.props.deviceActionsLoading ? <Spinner/> : null}
                    </div>
                </LogRequestsListToolbar>
            </DefaultWhiteColumn>,
            <DefaultWhiteColumn className='padding-top-20 padding-bottom-5'>
                <LogRequestsTable
                    canUpdateDeviceActions={this.props.canUpdateDeviceActions}
                    canDeleteDeviceActions={this.props.canDeleteDeviceActions}
                    deviceActions={this.props.deviceActions}
                    onShowItem={this.props.onShowItem}
                    onEditDeviceAction={this.props.onEditDeviceAction}
                    onDeleteDeviceAction={this.props.onDeleteDeviceAction}/>
            </DefaultWhiteColumn>,
        ];
    }
}

export default LogRequestsList;

LogRequestsList.defaultProps = {
    canCreateDeviceActions: false,
    canUpdateDeviceActions: false,
    canDeleteDeviceActions: false,
    deviceActions: [],
    deviceActionsLoading: false,
    onShowDeviceActionEditor: noop,
    onShowItem: noop,
    onEditDeviceAction: noop,
    onDeleteDeviceAction: noop,
};

LogRequestsList.propTypes = {
    // props
    canCreateDeviceActions: PropTypes.bool,
    canUpdateDeviceActions: PropTypes.bool,
    canDeleteDeviceActions: PropTypes.bool,
    deviceActions: PropTypes.array,
    deviceActionsLoading: PropTypes.bool,
    // functions
    onShowDeviceActionEditor: PropTypes.func,
    onShowItem: PropTypes.func,
    onEditDeviceAction: PropTypes.func,
    onDeleteDeviceAction: PropTypes.func,
};
