import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { deleteDeviceAction } from '~/features/deviceLogs/actions/deviceActionActions';

import { deviceActionEditorSelector } from '~/features/deviceLogs/selectors/deviceActionSelectors';
import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';

/**
 * Log requests deletion dialog
 */
export class LogRequestDeletionDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.onDeleteDeviceAction = this.onDeleteDeviceAction.bind(this);
    }
    onDeleteDeviceAction() {
        this.props.deleteDeviceAction(this.props.model.hwSerial);
    }
    render() {
        const { model } = this.props;
        const { hwSerial } = model;
        return (
            <ConfirmationDialog className='device-action-deletion-dialog'
                show={true}
                title={<FormattedMessage id='intl-msg:deleteLogRequest' />}
                content={<FormattedMessage id='intl-msg:deleteLogRequest.prompt' values={{
                    serialNumber: hwSerial,
                }}/>}
                cancelButtonText={<FormattedMessage id='intl-msg:cancel'/>}
                confirmButtonText={<FormattedMessage id='intl-msg:ok'/>}
                onClickCancel={this.props.hideModal}
                onClickConfirm={this.onDeleteDeviceAction}/>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        model: deviceActionEditorSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        deleteDeviceAction: (deviceAction) => {
            dispatch(deleteDeviceAction(deviceAction));
        },
    };
};

LogRequestDeletionDialog.defaultProps = {
    model: undefined,
    deleteDeviceAction: noop,
};

LogRequestDeletionDialog.propTypes = {
    model: PropTypes.object,
    deleteDeviceAction: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogRequestDeletionDialog);
