import PropTypes from 'prop-types';

import classNames from 'classnames';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import SortArrows from '@rio-cloud/rio-uikit/lib/es/SortArrows';
import SortDirection from '@rio-cloud/rio-uikit/lib/es/SortDirection';

class TableHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchValue: '',
            showTableSettingsDialog: false,
            // columnOrder: defaultColumnOrder,
            hiddenColumns: [],
            // columnsDetails: demoColumnsDetails,
        };
    }

    renderSortArrow = (columnDescriptor, sortBy = 'something', sortDir = SortDirection.ASCENDING) => {
        const { id, sortable } = columnDescriptor;
        if (sortable) {
            if (sortBy === id) {
                return <SortArrows direction={sortDir}/>;
            }
            return <SortArrows/>;
        }
    };

    toLabel = (columnDescriptor) => {
        const { formatLabel, label, id } = columnDescriptor;
        if (formatLabel) {
            return formatLabel(id);
        }
        if (columnDescriptor.label) {
            return <FormattedMessage id={label}/>;
        }
        return <FormattedMessage id={`intl-msg:field.unknown.${id}`}/>;
    };

    render() {
        const { columnDescriptor, sortBy, sortDir, handleSortChange } = this.props;
        if (columnDescriptor.hidden) {
            return null;
        }
        const { id, className } = columnDescriptor;
        const label = this.toLabel(columnDescriptor);
        const sorting = this.renderSortArrow(columnDescriptor, sortBy, sortDir);
        const headerClassNames = classNames('user-select-none', { 'sort-column': !!sorting }, className);
        return (
            <th key={id}
                className={headerClassNames}
                data-field={id}
                onClick={(event) => {
                    handleSortChange(event);
                }}
                data-sortby={id}>
            <span>
                {sorting}
                {label}
            </span>
            </th>
        );
    }
}

export default TableHeader;

TableHeader.propTypes = {
    // props
    columnDescriptor: PropTypes.object,
    sortBy: PropTypes.string,
    sortDir: PropTypes.string,
    // functions
    handleSortChange: PropTypes.func,
};
