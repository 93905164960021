import React from 'react';
import {FormattedMessage} from 'react-intl';

interface InputProps {
    hasError?: boolean;
}

const InputOTA: React.FC<InputProps & Partial<any>> = (props) => {

    const { placeholder } = props || '';
    const highlightError = props.hasError ? 'form-group has-feedback has-error' : '';
    return (
        <>
            {props?.title && <label><FormattedMessage id={props.title}/></label>}
            <div className={highlightError}>
                <FormattedMessage id={placeholder}>
                {placeholder => <input
                    {...props}
                    {...placeholder}
                />}
                </FormattedMessage>
            </div>
            {props.hasError &&
                <div className={'form-group has-feedback has-error  padding-bottom-15'}>
                        <span className='help-block'>
                        <span><FormattedMessage id='intl-msg:form:requiredField'/></span>
                        </span>
                </div>
            }</>
    );
};

export default InputOTA;
