import take from 'lodash/fp/take';
import map from 'lodash/fp/map';
import size from 'lodash/fp/size';
import drop from 'lodash/fp/drop';

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { DEVICES_PATH } from '~/features/base/constants/routes';

import Chip from '~/features/base/components/Chip';
import CommaSeparatedList from '~/features/base/components/CommaSeparatedList';
import uid from '~/features/base/utils/uid';

const renderFirstLinks = (serialNumbers, amount, bsStyle) => {
    const items = take(amount, serialNumbers);
    return map(serialNumber => {
        return (
            <Link key={uid()} to={`/${DEVICES_PATH}?serial-number-prefix=${serialNumber}`} target="_blank">
                <Chip bsStyle={bsStyle}>{serialNumber}</Chip>
            </Link>
        );
    }, items);
};

const renderMoreText = (serialNumbers, amount) => {
    const rest = drop(amount, serialNumbers);
    const num = size(rest);
    if (num) {
        return (
            <span>
                <FormattedMessage id='intl-msg:andNumMore' values={{ num }}/>
            </span>
        );
    }
};

export const DeviceChipList = ({ serialNumbers = [], amount = 3, bsStyle = 'primary' }) => {
    const firstLinks = renderFirstLinks(serialNumbers, amount, bsStyle);
    const moreText = renderMoreText(serialNumbers, amount);
    return (
        <div>
            <CommaSeparatedList>{firstLinks}</CommaSeparatedList>
            {moreText}
        </div>
    );
};

export default DeviceChipList;

DeviceChipList.propTypes = {
    // props
    serialNumbers: PropTypes.array,
    amount: PropTypes.number,
    bsStyle: PropTypes.string,
};
