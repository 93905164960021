export const RESET_JIRA_ISSUE_EDITOR = 'RESET_JIRA_ISSUE_EDITOR';
export const SHOW_JIRA_ISSUE_EDITOR = 'SHOW_JIRA_ISSUE_EDITOR';
export const CHANGE_JIRA_ISSUE_EDITOR = 'CHANGE_JIRA_ISSUE_EDITOR';
export const CREATE_JIRA_ISSUE = 'CREATE_JIRA_ISSUE';
export const FIND_JIRA_ISSUES = 'FIND_JIRA_ISSUES';
export const UPDATE_EXISTING_JIRA_ISSUES = 'UPDATE_EXISTING_JIRA_ISSUES';

export function resetJiraIssueEditor() {
    return {
        type: RESET_JIRA_ISSUE_EDITOR,
    };
}

export function showJiraIssueEditor(payload) {
    return {
        type: SHOW_JIRA_ISSUE_EDITOR,
        payload,
    };
}

export function changeJiraIssueEditor(payload) {
    return {
        type: CHANGE_JIRA_ISSUE_EDITOR,
        payload,
    };
}

export const createJiraIssue = (payload) => {
    return {
        type: CREATE_JIRA_ISSUE,
        payload: payload,
    };
};

export const findJiraIssues = (payload) => {
    return {
        type: FIND_JIRA_ISSUES,
        payload: payload,
    };
};

export const updateExistingJiraIssues = (payload) => {
    return {
        type: UPDATE_EXISTING_JIRA_ISSUES,
        payload: payload,
    };
};
