/**
 * Action type for showing the control device editor
 * @type {string}
 */
export const SHOW_CONTROL_DEVICE_EDITOR = 'SHOW_CONTROL_DEVICE_EDITOR';

/**
 * Action type for resetting the control device editor model
 * @type {string}
 */
export const RESET_CONTROL_DEVICE_EDITOR = 'RESET_CONTROL_DEVICE_EDITOR';

/**
 * Action type for changing the control device editor model
 * @type {string}
 */
export const CHANGE_CONTROL_DEVICE_EDITOR = 'CHANGE_CONTROL_DEVICE_EDITOR';

/**
 * Action creator for showing control device editor
 * @param {object} payload The payload
 * @returns {{type: string}} The action for showing control device editor
 */
export function showControlDeviceEditor(payload) {
    return {
        type: SHOW_CONTROL_DEVICE_EDITOR,
        payload,
    };
}

/**
 * Action creator for resetting control device editor
 * @returns {{type: string}} The action for resetting control device editor
 */
export function resetControlDeviceEditor() {
    return {
        type: RESET_CONTROL_DEVICE_EDITOR,
    };
}

/**
 * Action creator for changing control device editor
 * @param {object} payload The payload
 * @returns {{type: string}} The action for changing control device editor
 */
export function changeControlDeviceEditor(payload) {
    return {
        type: CHANGE_CONTROL_DEVICE_EDITOR,
        payload,
    };
}
