import compact from 'lodash/fp/compact';

import React from 'react';
import { FormattedMessage, FormattedNumber, FormattedTime } from 'react-intl';
import GroupChipList from '~/features/groups/components/GroupChipList';
import FieldOrNoData from '~/features/base/components/FieldOrNoData';
import { env } from '~/env';
import classNames from 'classnames';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

const { networkCodes, networkCodesWhiteList } = env.runtimeConfig;

export const vehicleRowDescriptors = compact([{

    field: 'vin',
    label: 'intl-msg:VIN',
    format: field => <FieldOrNoData field={field}/>,
}, {
    field: 'hwVariant',
    label: 'intl-msg:hwVariant',
    format: field => <FieldOrNoData field={field}/>,
}, {
    field: 'vehicleVariant',
    label: 'intl-msg:vehicleVariant',
    format: field => <FieldOrNoData field={field}/>,
}, {
    field: 'vehicleGroupNames',
    label: 'intl-msg:groups',
    format: field => <GroupChipList groups={field}/>,
}, {
    field: 'lastNetworkCodeReported',
    label: 'intl-msg:deviceNetworkLocation',
    format: field => {
        let lastNetworkReportedCountry;
        let countryCode;
        if (field) {
            countryCode = field.split(' ')[0];
            lastNetworkReportedCountry = networkCodes[countryCode] || '';
        }
        return (
            <span>
                {
                    lastNetworkReportedCountry ? lastNetworkReportedCountry
                        : <FormattedMessage id='intl-msg:networkCountryCode' values={{ countryCode: countryCode }}/>
                }
                <OverlayTrigger placement='top' overlay={
                    <Tooltip id='tooltip' className='top-right'>
                        {
                            countryCode && networkCodesWhiteList.includes(parseInt(countryCode, 0)) ?
                                <FormattedMessage id='intl-msg:deviceNetworkCanUseServices'/>
                                :
                                <FormattedMessage id='intl-msg:report.reason.disallowedNetwork'/>
                        }
                    </Tooltip>}>
                            <span className={classNames('label label-condensed margin-right-5')}>
                            {
                                countryCode && networkCodesWhiteList.includes(parseInt(countryCode, 0)) ?
                                    <span
                                        className='text-success rioglyph rioglyph-ok-sign margin-right-10 text-size-16'/>
                                    :
                                    <span
                                        className='text-danger rioglyph rioglyph-error-sign margin-right-10 text-size-16'/>
                            }
                            </span>
                        </OverlayTrigger>
            </span>
        );
    },
}, {
    field: 'lastMileageReported',
    label: 'intl-msg:mileageWithUnit',
    format: field => {
        return field ? <FieldOrNoData field={<FormattedNumber value={field}/>}/> : <FieldOrNoData field={field}/>;
    },
}, {
    field: 'lastFleetEvent',
    label: 'intl-msg:lastFleetEvent',
    format: field => <FieldOrNoData field={field ? <FormattedTime value={field} year='numeric'
                                                                  month='2-digit' day='2-digit'/> : null}/>,
}]);
