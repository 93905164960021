import includes from 'lodash/fp/includes';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { GROUPS_PATH } from '~/features/base/constants/routes';
import { ENTITY_GROUP } from '~/features/base/constants/entities';

import { followRoute } from '~/features/base/actions/ui/routeActions';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import { fetchFilteredGroups } from '~/features/groups/actions/groupActions';
import { setShowGroupTableView } from '~/features/groups/actions/groupViewActions';
import {
    fetchSerialNumbersAndOpenEditorOfGroup,
    showGroupDeletionDialog,
    showGroupEditorDialog,
} from '~/features/groups/actions/groupEditorActions';
import { mergeSelect, mergeSelectAll } from '~/features/higherorder/actions/selectionActions';

import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';
import {
    canCreateGroupsSelector,
    canDeleteGroupsSelector,
    canReadGroupsSelector,
    canUpdateGroupsSelector,
    groupsLoadingSelector,
    groupsSelector,
    groupTotalElementsSelector,
    selectedAllGroupsSelector,
    selectedGroupCountSelector,
    selectedGroupsSelector,
    showGroupTableViewSelector,
} from '~/features/groups/selectors/groupSelectors';

import { parseQuery } from '~/features/base/utils/query';
import { queryToSearchCriteria } from '~/features/groups/utils/groupQuery';

import GroupList from '~/features/groups/components/GroupList';
import uid from '~/features/base/utils/uid';
import { fetchVehicleVariants } from '~/features/vehicleVariants/actions/vehicleVariantsActions';

export class GroupListContainer extends PureComponent {

    constructor(props) {
        super(props);
        this.name = uid();
    }

    onSelectItem(serialNumber) {
        const selected = includes(serialNumber, this.props.selectedItems);
        this.props.mergeSelect(ENTITY_GROUP, !selected, serialNumber);
    }

    onSelectAll() {
        this.props.mergeSelectAll(ENTITY_GROUP, !this.props.selectedAll);
    }

    onShowItem(item) {
        const route = `/${GROUPS_PATH}/${item}`;
        this.props.followRoute({ route });
    }

    onCreateGroup() {
        this.props.showGroupEditorDialog({
            isNew: true,
        });
    }

    onEditGroup(group) {
        if (group.type === 'USER') {
            this.props.fetchSerialNumbersAndOpenEditorOfGroup(group.name);
        } else {
            this.props.showGroupEditorDialog({
                isNew: false,
                ...group,
            });
        }
    }

    onDeleteGroup(group) {
        this.props.showGroupDeletionDialog({
            ...group,
        });
    }

    onShowTableView(showTableView) {
        this.props.setShowGroupTableView(showTableView);
    }

    render() {
        const parsedQuery = parseQuery(this.props.search);
        return (
            <GroupList {...this.props} {...parsedQuery}
                       onSelectItem={item => this.onSelectItem(item)}
                       onSelectAll={() => this.onSelectAll()}
                       onShowItem={item => this.onShowItem(item)}
                       onCreateGroup={() => this.onCreateGroup()}
                       onShowTableView={(showTableView) => this.onShowTableView(showTableView)}
                       onEditGroup={item => this.onEditGroup(item)}
                       onDeleteGroup={item => this.onDeleteGroup(item)}/>
        );
    }

    registerDataInterest(parsedQuery) {
        const searchCriteria = queryToSearchCriteria(parsedQuery);

        const fetchFilteredGroupsOptions = {
            searchCriteria,
        };

        this.props.registerDataInterest(this.name, [
            fetchFilteredGroups(fetchFilteredGroupsOptions),
            fetchVehicleVariants(),
        ]);
    }

    componentWillMount() {
        const { search } = this.props;
        const parsedQuery = parseQuery(search);
        this.registerDataInterest(parsedQuery);

        this.props.triggerDataFetcher();
        this.interval = setInterval(() => {
            this.props.triggerDataFetcher();
        }, 60000);
    }

    componentWillUnmount() {
        this.props.unregisterDataInterest(this.name);
        clearInterval(this.interval);
    }

    // FIXME REACT16 Should probably be componentDidUpdate(prevProps, prevState, snapshot)
    componentWillReceiveProps(nextProps) {
        const { search } = nextProps;
        if (search !== this.props.search) {
            const parsed = parseQuery(search);
            this.registerDataInterest(parsed);
            nextProps.triggerDataFetcher();
        }
    }
}

export const mapStateToProps = (state) => {
    return {
        pathname: pathnameSelector(state),
        search: searchSelector(state),
        canReadGroups: canReadGroupsSelector(state),
        canCreateGroups: canCreateGroupsSelector(state),
        canUpdateGroups: canUpdateGroupsSelector(state),
        canDeleteGroups: canDeleteGroupsSelector(state),
        totalElements: groupTotalElementsSelector(state),
        groups: groupsSelector(state),
        groupsLoading: groupsLoadingSelector(state),
        selectedItems: selectedGroupsSelector(state),
        selectedAll: selectedAllGroupsSelector(state),
        selectedCount: selectedGroupCountSelector(state),
        showTableView: showGroupTableViewSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        followRoute: (options) => {
            dispatch(followRoute(options));
        },
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        triggerDataFetcher: () => {
            dispatch(triggerDataFetcher());
        },
        mergeSelect: (entity, select, id) => {
            dispatch(mergeSelect(entity, select, id));
        },
        mergeSelectAll: (entity, selectAll) => {
            dispatch(mergeSelectAll(entity, selectAll));
        },
        showGroupEditorDialog: (options) => {
            dispatch(showGroupEditorDialog(options));
        },
        showGroupDeletionDialog: (options) => {
            dispatch(showGroupDeletionDialog(options));
        },
        setShowGroupTableView: (showTableView) => {
            dispatch(setShowGroupTableView(showTableView));
        },
        fetchSerialNumbersAndOpenEditorOfGroup: groupName => {
            dispatch(fetchSerialNumbersAndOpenEditorOfGroup(groupName));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupListContainer);

GroupListContainer.defaultProps = {
    // props
    pathname: '',
    search: '',
    canReadGroups: false,
    canCreateGroups: false,
    canUpdateGroups: false,
    canDeleteGroups: false,
    groups: [],
    groupsLoading: false,
    selectedItems: [],
    selectedAll: false,
    selectedCount: 0,
    totalElements: 0,
    showTableView: false,
    // functions
    fetchSerialNumbersAndOpenEditorOfGroup: noop,
    followRoute: noop,
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    triggerDataFetcher: noop,
    mergeSelect: noop,
    mergeSelectAll: noop,
    showGroupEditorDialog: noop,
    showGroupDeletionDialog: noop,
    setShowGroupTableView: noop,
};

GroupListContainer.propTypes = {
    // props
    pathname: PropTypes.string,
    search: PropTypes.string,
    canReadGroups: PropTypes.bool,
    canCreateGroups: PropTypes.bool,
    canUpdateGroups: PropTypes.bool,
    canDeleteGroups: PropTypes.bool,
    groups: PropTypes.array,
    groupsLoading: PropTypes.bool,
    selectedItems: PropTypes.array,
    selectedAll: PropTypes.bool,
    selectedCount: PropTypes.number,
    totalElements: PropTypes.number,
    showTableView: PropTypes.bool,
    // functions
    fetchSerialNumbersAndOpenEditorOfGroup: PropTypes.func,
    followRoute: PropTypes.func,
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
    mergeSelect: PropTypes.func,
    mergeSelectAll: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
    showGroupEditorDialog: PropTypes.func,
    showGroupDeletionDialog: PropTypes.func,
    setShowGroupTableView: PropTypes.func,
};
