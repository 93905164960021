import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    showDeviceActionEditor,
    showLogRequestDeletionDialog,
} from '~/features/deviceLogs/actions/deviceActionEditorActions';
import { fetchDeviceAction } from '~/features/deviceLogs/actions/deviceActionActions';

import {
    deviceActionSelector,
    canUpdateDeviceActionsSelector,
    canDeleteDeviceActionsSelector,
} from '~/features/deviceLogs/selectors/deviceActionSelectors';

import { deviceActionRowDescriptors } from '~/features/deviceLogs/constants/deviceLogRowDescriptors';
import LogRequestDetails from '~/features/deviceLogs/components/LogRequestDetails';

/**
 * Container for log requests details
 */
export class LogRequestDetailsContainer extends PureComponent {
    render() {
        return (
            <LogRequestDetails {...this.props} rowDescriptors={deviceActionRowDescriptors}/>
        );
    }
    componentWillMount() {
        this.fetchData();
        this.interval = setInterval(() => {
            this.fetchData();
        }, 60000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    fetchData() {
        const { serialNumber } = this.props;
        this.props.fetchDeviceAction({ serialNumber });
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        canUpdateDeviceActions: canUpdateDeviceActionsSelector(state),
        canDeleteDeviceActions: canDeleteDeviceActionsSelector(state),
        deviceAction: deviceActionSelector(state, ownProps),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        fetchDeviceAction: payload => {
            dispatch(fetchDeviceAction(payload));
        },
        showDeviceActionEditor: payload => {
            dispatch(showDeviceActionEditor(payload));
        },
        showLogRequestDeletionDialog: payload => {
            dispatch(showLogRequestDeletionDialog(payload));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogRequestDetailsContainer);

LogRequestDetailsContainer.defaultProps = {
    // props
    canUpdateDeviceActions: false,
    canDeleteDeviceActions: false,
    serialNumber: '',
    deviceAction: undefined,
    // functions
    fetchDeviceAction: noop,
    showDeviceActionEditor: noop,
    showLogRequestDeletionDialog: noop,
};

LogRequestDetailsContainer.propTypes = {
    // props
    canUpdateDeviceActions: PropTypes.bool,
    canDeleteDeviceActions: PropTypes.bool,
    serialNumber: PropTypes.string,
    deviceAction: PropTypes.object,
    // functions
    fetchDeviceAction: PropTypes.func,
    showDeviceActionEditor: PropTypes.func,
    showLogRequestDeletionDialog: PropTypes.func,
};
