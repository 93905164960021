import { connect } from 'react-redux';
import { followRoute } from '~/features/base/actions/ui/routeActions';
import ReportsBillingDetails from '~/features/reportsbilling/components/ReportsBillingDetails';
import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';

const mapStateToProps = (state) => {
    return {
        pathname: pathnameSelector(state),
        search: searchSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        followRoute: (options) => {
            dispatch(followRoute(options));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsBillingDetails);
