import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { CONTROL_DEVICE_QUICK_WHITELISTING_MODAL } from '~/features/base/constants/modals';

import { hideModal, showModal } from '~/features/base/actions/ui/modalsActions';
import {
    ADD_CONTROL_DEVICE_TO_DELIVERABLE_WHITELIST,
    REMOVE_CONTROL_DEVICE_TO_DELIVERABLE_WHITELIST,
    resetControlDeviceQuickWhitelisting, REVOKE_UNINSTALL_PACKAGE_DELIVERABLE,
    SHOW_CONTROL_DEVICE_QUICK_WHITELISTING,
} from '~/features/devices/actions/controlDeviceQuickWhitelistingActions';
import { deleteHTTP, doHandleErrorSaga, postHTTP, putHTTP } from '~/features/base/sagas/sagaUtil';
import { failedAction, finishedAction } from '~/features/higherorder/transforms/actionTransforms';
import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';
import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';
import { DeliverableType } from '~/features/deliverables/constants/DeliverableType';
import { toSemanticVersion } from '~/features/base/utils/versionNumberConverter';

export function* getDeliverablesURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/deliverable`;
}

export function* getPackageDeliverablesURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v2/client/devices`;
}

export function* doShowControlDeviceQuickWhitelistingSaga(action) {
    yield put(resetControlDeviceQuickWhitelisting());
    yield put(showModal({
        modalType: CONTROL_DEVICE_QUICK_WHITELISTING_MODAL,
        modalProps: { mode: action.mode, serialNumber: action.serialNumber, deviceType: action.deviceType },
    }));
}

export function* doAddDeviceToDeliverableWhitelistSaga(action) {
    try {
        const url = yield call(getDeliverablesURL);
        const {
            selectedDeliverableType,
            selectedDeliverableId,
            selectedDeliverableVersion,
            serialNumber,
        } = action.payload;
        yield call(putHTTP,
            `${url}/${selectedDeliverableType.toUpperCase()}/${selectedDeliverableId}/${selectedDeliverableVersion}/whitelist?serial2add=${serialNumber}`,
        );
        yield put(hideModal());
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doRemoveDeviceFromDeliverableWhitelistSaga(action) {
    try {
        const url = yield call(getPackageDeliverablesURL);
        const {
            selectedDeliverableId,
            serialNumber,
        } = action.payload;
        yield call(deleteHTTP,
            `${url}/${serialNumber}/deliverables/${selectedDeliverableId}/${DeliverableType.APP}`,
        );
        yield put(hideModal());
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doRevokeUninstallPackageDeliverableSaga(action) {
    try {
        const url = yield call(getPackageDeliverablesURL);
        const {
            selectedDeliverableId,
            selectedDeliverableVersion,
            serialNumber,
        } = action.payload;
        yield call(postHTTP,
            `${url}/${serialNumber}/deliverables/revoke-uninstall`,
            JSON.stringify({
                deliverableId: selectedDeliverableId,
                deliverableVersion: toSemanticVersion(selectedDeliverableVersion),
                deliverableType: DeliverableType.APP,
            }),
        );
        yield put(hideModal());
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* showControlDeviceQuickWhitelistingSaga() {
    yield takeLatest(SHOW_CONTROL_DEVICE_QUICK_WHITELISTING, doShowControlDeviceQuickWhitelistingSaga);
}

export function* addDeviceToDeliverableWhitelistSaga() {
    yield takeLatest(ADD_CONTROL_DEVICE_TO_DELIVERABLE_WHITELIST, doAddDeviceToDeliverableWhitelistSaga);
}

export function* removeDeviceFromDeliverableWhitelistSaga() {
    yield takeLatest(REMOVE_CONTROL_DEVICE_TO_DELIVERABLE_WHITELIST, doRemoveDeviceFromDeliverableWhitelistSaga);
}

export function* revokeUninstallPackageDeliverableSaga() {
    yield takeLatest(REVOKE_UNINSTALL_PACKAGE_DELIVERABLE, doRevokeUninstallPackageDeliverableSaga);
}
