import { createSelector } from 'reselect';

import { ENTITY_VEHICLE_VARIANTS_ALL } from '~/features/base/constants/entities';
import { entityStoreSelector } from '~/features/higherorder/selectors/dataSelectors';

const vehicleVariantsEntitiesSelector = state => entityStoreSelector(state)[ENTITY_VEHICLE_VARIANTS_ALL];

export const vehicleVariantsSelector = createSelector(
    [vehicleVariantsEntitiesSelector],
    (vehicleVariants) => {
        return vehicleVariants;
    },
);
