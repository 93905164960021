import PropTypes from 'prop-types';
import { WhitelistingInfo } from '~/prop-types/whitelistingInfoPropType';

import React from 'react';
import { FormattedMessage } from 'react-intl';

import DeviceChipList from '~/features/devices/components/DeviceChipList';
import GroupChipList from '~/features/groups/components/GroupChipList';

const renderWhitelist = (whitelistingInfo) => {
    if (!whitelistingInfo) {
        return;
    }
    if (whitelistingInfo.whitelistedForAll) {
        return (
            <span className='label label-condensed label-primary'>
                <FormattedMessage id='intl-msg:allDevices'/>
            </span>
        );
    }
    return (
        <div>
            <GroupChipList groups={whitelistingInfo.vehicleGroupWhitelist} bsStyle='success'/>
            <DeviceChipList serialNumbers={whitelistingInfo.deviceWhitelist} bsStyle='success'/>
        </div>
    );
};

const renderBlacklist = (whitelistingInfo) => {
    if (!whitelistingInfo) {
        return;
    }
    return (
        <div>
            <GroupChipList groups={whitelistingInfo.vehicleGroupBlacklist} bsStyle='danger'/>
            <DeviceChipList serialNumbers={whitelistingInfo.deviceBlacklist} bsStyle='danger'/>
        </div>
    );
};

export const WhitelistingDetails = ({ whitelistingInfo }) => {
    const whitelist = renderWhitelist(whitelistingInfo);
    const blacklist = renderBlacklist(whitelistingInfo);
    return (
        <div>
            {whitelist}
            {blacklist}
        </div>
    );
};

export default WhitelistingDetails;

WhitelistingDetails.propTypes = {
    // props
    whitelistingInfo: PropTypes.object,
};
