import getOr from 'lodash/fp/getOr';
import map from 'lodash/fp/map';
import size from 'lodash/fp/size';

import { createSelector } from 'reselect';

import { ENTITY_GROUP, ENTITY_DEVICE_GROUP, ENTITY_SERIAL_NUMBER } from '~/features/base/constants/entities';

/*import {
    PERMISSION_GROUP_READ,
    PERMISSION_GROUP_CREATE,
    PERMISSION_GROUP_UPDATE,
    PERMISSION_GROUP_DELETE,
} from '~/features/base/constants/permissions';*/

import {
    entityStoreSelector,
    selectionStoreSelector,
    indicesStoreSelector,
    loadingStoreSelector,
} from '~/features/higherorder/selectors/dataSelectors';

const groupEntitiesSelector = state => entityStoreSelector(state)[ENTITY_GROUP];
const groupSortedIdsSelector = state => indicesStoreSelector(state)[ENTITY_GROUP];
const groupSelectionSelector = state => selectionStoreSelector(state)[ENTITY_GROUP];
export const groupsLoadingSelector = state => loadingStoreSelector(state)['groups'];

const deviceGroupEntitiesSelector = state => entityStoreSelector(state)[ENTITY_DEVICE_GROUP];
const groupViewSelector = state => state.ui.groupView;

export const canReadGroupsSelector = () => true;
export const canCreateGroupsSelector = () => true;
export const canUpdateGroupsSelector = () => true;
export const canDeleteGroupsSelector = () => true;

export const groupSelector = (state, ownProps) =>
    groupEntitiesSelector(state)[ownProps.groupName];

export const deviceGroupsSelector = (state, ownProps) =>
    getOr([], [ownProps.serialNumber, 'groups'], deviceGroupEntitiesSelector(state));

export const groupTotalElementsSelector = state =>
    size(groupSortedIdsSelector(state));

export const groupsSelector = createSelector(
    [groupSortedIdsSelector, groupEntitiesSelector],
    (sortedIds, entities) => {
        return map(id => entities[id], sortedIds);
    }
);

export const selectedAllGroupsSelector = state => groupSelectionSelector(state).selectedAll;
export const selectedGroupsSelector = state => groupSelectionSelector(state).selectedIds;

export const selectedGroupCountSelector = createSelector(
    [selectedAllGroupsSelector, selectedGroupsSelector, groupTotalElementsSelector],
    (selectedAll, selected, totalElements) => {
        if (selectedAll) {
            return totalElements;
        }
        return size(selected);
    }
);

const serialNumberSortedIdsSelector = state => indicesStoreSelector(state)[ENTITY_SERIAL_NUMBER];
export const groupSerialNumbersSelector = (state, ownProps) =>
    getOr([], ownProps.groupName, serialNumberSortedIdsSelector(state));

export const groupWithSerialNumbersSelector = createSelector(
    [groupSelector, groupSerialNumbersSelector],
    (group, serialNumbers) => {
        if (group) {
            return {
                ...group,
                serialNumbers,
            };
        }
    }
);

export const showGroupTableViewSelector = state => groupViewSelector(state).showTableView;

export const groupSearchValue = state => groupViewSelector(state).searchValue;

export const groupEditorSelector = state => state.ui.groupEditor;
