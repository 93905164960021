import includes from 'lodash/fp/includes';
import find from 'lodash/fp/find';
import size from 'lodash/fp/size';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { connect } from 'react-redux';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import uid from '~/features/base/utils/uid';
import { vehicleColumnDescriptors } from '../constants/vehicleColumnDescriptors';
import { VehicleListTableDetails } from './VehicleListTableDetails';
import { setShowVehicleDetailsSidebar } from '~/features/oldVehicles/actions/ui/vehicleDetailsActions';

const KEY_UP = 38;
const KEY_DOWN = 40;

export class VehicleListTable extends PureComponent {
    constructor(props) {
        super(props);
        this.actionsColumn = {
            id: 'actions',
            field: 'serialNumber',
            className: 'table-action',
            format: field => {
                const items = [];
                const { canUpdateControlDevices } = this.props;
                if (canUpdateControlDevices) {
                    items.push({
                        value: (
                            <div className='' onClick={this.onEditButtonClick} data-key={field}>
                                <span className='rioglyph rioglyph-pencil margin-right-10'></span>
                                <span><FormattedMessage id='intl-msg:editControlDevice'/></span>
                            </div>
                        ),
                    });
                }
                if (size(items)) {
                    return (
                        <ButtonDropdown
                            title={<span className='rioglyph rioglyph-option-vertical'/>}
                            className='pull-right'
                            bsStyle='link'
                            iconOnly
                            items={items}/>
                    );
                }
            },
            formatLabel: () => '',
        };
        this.name = uid();
    }

    checkRecursively(node, nodeType, className) {
        if (node.localName === nodeType) {
            return includes(className, node.classList);
        } else if (node.localName === 'td') {
            return false;
        }
        return this.checkRecursively(node.parentNode, nodeType, className);
    }

    findNextListEntry() {
        return '190418-0130';
    }

    handleKeyUpDown = (event) => {
        switch (event.keyCode) {
            case KEY_UP:
                console.log('up');
                break;
            case KEY_DOWN:
                this.onRowClick(this.findNextListEntry(), true);
                console.log('down');
                break;
            default:
                break;
        }
    };

    onRowClick = (rowId, isSelected) => {
        this.props.setShowVehicleDetailsSidebar(rowId);
        this.props.onSelectItem(rowId, isSelected);
    };

    onEditButtonClick = (event) => {
        const key = event.currentTarget.getAttribute('data-key');
        const controlDevice = find(item => item.vin === key, this.props.vehicles);
        this.props.onEditDevice({
            isNew: false,
            ...controlDevice,
        });
    };

    onSelectAllClick = () => {
        this.props.onSelectAll();
    };

    render() {
        const { vehicles, hiddenColumns, columnOrder, allowSelection, selectedItems, selectedAll } = this.props;
        const columns = [...vehicleColumnDescriptors, this.actionsColumn];
        let filteredAndOrderedColumns = [];
        columnOrder.forEach(columnId => {
            if (!hiddenColumns.includes(columnId)) {
                filteredAndOrderedColumns.push(columns.find(entry => entry.id === columnId));
            }
        });

        return [
            <VehicleListTableDetails className='table-bordered table-condensed'
                                     items={vehicles}
                                     itemKey='vin'
                                     highlightSelectedRow
                                     columnDescriptors={filteredAndOrderedColumns}
                                     selectedItems={selectedItems}
                                     selectedAll={selectedAll}
                                     onRowClick={this.onRowClick}
                                     onSelectItem={this.props.onSelectItem}
                                     onSelectAll={this.props.onSelectAll}
                                     onShowItem={this.props.onShowItem}/>,
        ];
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyUpDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyUpDown);
    }
}

export const mapStateToProps = (state) => {
    return {};
};

export const mapDispatchToProps = (dispatch) => {
    return {
        setShowVehicleDetailsSidebar: (rowId) => {
            dispatch(setShowVehicleDetailsSidebar(rowId));
        },
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        triggerDataFetcher: () => {
            dispatch(triggerDataFetcher());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleListTable);

VehicleListTable.defaultProps = {
    // props
    vehicles: [],
    hiddenColumns: [],
    columnOrder: [],
    canDeleteControlDevices: false,
    allowSelection: false,
    selectedItems: [],
    selectedAll: false,
    // functions
    onEditDevice: noop,
    onSelectItem: noop,
    onSelectAll: noop,
    onShowItem: noop,
    setShowVehicleDetailsSidebar: noop,
};

VehicleListTable.propTypes = {
    // props
    vehicles: PropTypes.array,
    hiddenColumns: PropTypes.array,
    columnOrder: PropTypes.array,
    canDeleteControlDevices: PropTypes.bool,
    allowSelection: PropTypes.bool,
    selectedItems: PropTypes.array,
    selectedAll: PropTypes.bool,
    // functions
    onEditDevice: PropTypes.func,
    onSelectItem: PropTypes.func,
    onSelectAll: PropTypes.func,
    onShowItem: PropTypes.func,
    setShowVehicleDetailsSidebar: PropTypes.func,
};
