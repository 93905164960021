// This is the runtime configuration being used for development
// against a local instance of an `authorization-server`. Note
// that the redirect only works if you run the `webpack-dev-server`
// on http://localhost:8081.

export const localAuthConfig = {
    id: 'env.localauth',
    region: 'EU',
    backend: {
        DEVICE_SYSTEM_STATE_SERVICE: 'http://localhost:443/api/tbm3systemstatenotification',
        DEVICE_LOG_DOWNLOAD_SERVICE: 'http://localhost:443/api/tbm3logdownload/v1/devices',
        ATLASSIAN_CONNECTOR: 'http://localhost:443/api/mdmotaac',
        REPORTS_BILLING_CHARTS_DATA_SERVICE: 'http://localhost:443/api/mdm/reporting/v1/traffic/search',
        REPORTS_BILLING_CHARTS_DATA_VERSIONS_SERVICE: 'https://6182843e84c2020017d89efa.mockapi.io/dataTraffic/dataTrafficTypes',
        DEVICE_MANAGEMENT_SERVICE: 'http://localhost:4431/api/mdmservice',
        LOG_SERVICE: 'http://localhost:4432/api/mdm/logging',
    },
    homeRoute: `${window.location.origin}`,
    appMenuUri: 'http://localhost:8040/#app-menu',
    login: {
        authority: 'http://localhost:8443',
        clientId: 'test-client',
        mockAuthorization: true,
        preventRedirect: false,
        redirectUri: `${window.location.origin}/redirect.html`,
        silentRedirectUri: `${window.location.origin}/redirect.html`,
    },
    logoutUri: `http://localhost:8443/logout?redirect_uri=${window.location.origin}`,
    packageVersionDownloadBaseUrl: 'https://transfer.devgateway.eu/release/packages/',
    packageVersionInstallerBaseUrl: 'https://transfer.devgateway.eu/release/packages/app-installer_2020.09.04',
    downloadCM4GUrl: 'https://transfer.cm4.devgateway.eu/release/cm4g/MDM/',
    showAdaptOrder: true,
    networkCodes: {},
    networkCodesWhiteList: [],
};
