/**
 * Action type for showing error messages.
 * @type {string}
 */
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';
export const SHOW_SUCCESS_MESSAGE = 'SHOW_SUCCESS_MESSAGE';

/**
 * Action creator to show an error message.
 * @param {string} actionType The type of the original action
 * @param {string} error The error returned from the server
 * @returns {object} a show error message action object
 */
export function showErrorMessage(actionType, error) {
    return {
        type: SHOW_ERROR_MESSAGE,
        actionType,
        error,
    };
}

export function showSuccessMessage(actionType, message) {
    return {
        type: SHOW_SUCCESS_MESSAGE,
        actionType,
        message
    };
}
