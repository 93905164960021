import React from 'react';
import DatePicker from '@rio-cloud/rio-uikit/lib/es/DatePicker';
import moment from 'moment/moment';
import { FormattedMessage } from 'react-intl';

interface DateRangeProps {
    setStartValue: (starValue: moment.Moment) => void;
    setEndValue: (endValue: moment.Moment) => void;
    startValue: moment.Moment;
    endValue: moment.Moment;
    setTimeRange: (startValue: moment.Moment, endValue: moment.Moment) => void;
    setDropdownOpen: (isOpen: boolean) => void;
}

const DateRange: React.FC<DateRangeProps> = ({
    setStartValue,
    setEndValue,
    startValue,
    endValue,
    setTimeRange,
    setDropdownOpen
}: DateRangeProps) => {
    return (
        <div className='display-flex flex-column '>
            <div
                className='display-flex  width-100pct height-350 margin-bottom-10 margin-10 justify-content-around'>
                <div className={'width-250'}>
                    <label><FormattedMessage id={'intl-msg:dateTimeRange.from'}/></label>
                    <DatePicker open initialValue={moment()} inputProps={{ placeholder: 'Select Date' }}
                                onChange={setStartValue}
                                clearableInput/>
                </div>
                <div className={'width-250'}>
                    <label><FormattedMessage id={'intl-msg:dateTimeRange.to'}/></label>
                    <DatePicker open initialValue={moment()} inputProps={{ placeholder: 'Select Date' }}
                                onChange={setEndValue}
                                clearableInput alignRight/>
                </div>

            </div>
            <div className={'align-self-end'}>
                <button onClick={() => setDropdownOpen(false)}
                        className={'btn btn-default margin-top-10 margin-bottom-10  margin-right-10 align-self-end'}>
                    <FormattedMessage id={'intl-msg:cancel'}/>
                </button>
                <button disabled={startValue >= endValue} onClick={() => setTimeRange(startValue, endValue)}
                        className={'btn btn-default margin-top-10 margin-bottom-10 width-100 align-self-end'}>
                    <FormattedMessage id={'intl-msg:apply'}/>
                </button>
            </div>
        </div>
    );
};

export default DateRange;
