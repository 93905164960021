export const UNKNOWN = 'UNKNOWN';
export const STATISTICAL = 'STATISTICAL';
export const CPU_LIMIT = 'CPU_LIMIT';
export const MEM_LIMIT = 'MEM_LIMIT';
export const APP_CRASH = 'APP_CRASH';
export const REQUESTED = 'REQUESTED';
export const LOG_MSG = 'LOG_MSG';
export const PROC_NOT_RUNNING = 'PROC_NOT_RUNNING';
export const BOOT_BANK_SWITCHED = 'BOOT_BANK_SWITCHED';
export const AUTO_BANK_SWITCH = 'AUTO_BANK_SWITCH';

export const systemStateNotificationTypes = {
    [UNKNOWN]: {
        id: UNKNOWN,
        label: 'intl-msg:controlDevice.systemstate.messageType.unknown',
        tooltip: 'intl-msg:controlDevice.systemstate.messageType.unknown',
        className: 'label-default',
    },
    [STATISTICAL]: {
        id: STATISTICAL,
        label: 'intl-msg:controlDevice.systemstate.messageType.statistical',
        tooltip: 'intl-msg:controlDevice.systemstate.messageType.statistical',
        className: 'label-info',
    },
    [CPU_LIMIT]: {
        id: CPU_LIMIT,
        label: 'intl-msg:controlDevice.systemstate.messageType.cpuLimit',
        tooltip: 'intl-msg:controlDevice.systemstate.messageType.cpuLimit',
        className: 'label-danger',
    },
    [MEM_LIMIT]: {
        id: MEM_LIMIT,
        label: 'intl-msg:controlDevice.systemstate.messageType.memLimit',
        tooltip: 'intl-msg:controlDevice.systemstate.messageType.memLimit',
        className: 'label-danger',
    },
    [APP_CRASH]: {
        id: APP_CRASH,
        label: 'intl-msg:controlDevice.systemstate.messageType.appCrash',
        tooltip: 'intl-msg:controlDevice.systemstate.messageType.appCrash',
        className: 'label-danger',
    },
    [REQUESTED]: {
        id: REQUESTED,
        label: 'intl-msg:controlDevice.systemstate.messageType.requested',
        tooltip: 'intl-msg:controlDevice.systemstate.messageType.requested',
        className: 'label-warning',
    },
    [LOG_MSG]: {
        id: LOG_MSG,
        label: 'intl-msg:controlDevice.systemstate.messageType.logMsg',
        tooltip: 'intl-msg:controlDevice.systemstate.messageType.logMsg',
        className: 'label-warning',
    },
    [PROC_NOT_RUNNING]: {
        id: PROC_NOT_RUNNING,
        label: 'intl-msg:controlDevice.systemstate.messageType.procNotRunning',
        tooltip: 'intl-msg:controlDevice.systemstate.messageType.procNotRunning',
        className: 'label-danger',
    },
    [BOOT_BANK_SWITCHED]: {
        id: BOOT_BANK_SWITCHED,
        label: 'intl-msg:controlDevice.systemstate.messageType.bootBankSwitch',
        tooltip: 'intl-msg:controlDevice.systemstate.messageType.bootBankSwitch',
        className: 'label-warning',
    },
    [AUTO_BANK_SWITCH]: {
        id: AUTO_BANK_SWITCH,
        label: 'intl-msg:controlDevice.systemstate.messageType.autoBankSwitch',
        tooltip: 'intl-msg:controlDevice.systemstate.messageType.autoBankSwitch',
        className: 'label-info',
    }
};
