import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import map from 'lodash/fp/map';
import size from 'lodash/fp/size';

import {createSelector} from 'reselect';

import {
    PERMISSION_CONTROL_DEVICE_CREATE,
    PERMISSION_CONTROL_DEVICE_READ,
    PERMISSION_CONTROL_DEVICE_UPDATE,
} from '~/features/base/constants/permissions';

import {isTestManager, permissionsSelector} from '~/features/user/selectors/permissionSelectors';

import {
    aggregationStoreSelector,
    entityStoreSelector,
    indicesStoreSelector,
    loadingStoreSelector,
    selectionStoreSelector,
} from '~/features/higherorder/selectors/dataSelectors';
import {
    ENTITY_CONTROL_DEVICE,
    ENTITY_CONTROL_DEVICE_ELIGIBLE_DELIVERABLE_VERSIONS,
    ENTITY_CONTROL_DEVICE_INFO,
    ENTITY_CONTROL_DEVICE_SCOPE_DEVICES,
    ENTITY_DEVICE_STATUS_NAMES_ALL,
    ENTITY_DEVICE_STATUS_TYPES_ALL,
} from '~/features/base/constants/entities';
import {filterSettingsSelector} from '~/features/base/selectors/filterSelectors';
import {getEligibleSize} from "~/features/devices/utils/eligibleDeliverables";

export const deviceDetailsUiSelector = state => state.ui.deviceDetails;

const deviceStatusNamesEntitySelector = state => entityStoreSelector(state)[ENTITY_DEVICE_STATUS_NAMES_ALL];
const deviceStatusTypesEntitySelector = state => entityStoreSelector(state)[ENTITY_DEVICE_STATUS_TYPES_ALL];
export const controlDeviceInfoDataSelector = state => entityStoreSelector(state)[ENTITY_CONTROL_DEVICE_INFO];

const controlDeviceEligibleDeliverableVersionsDataSelector = state => entityStoreSelector(
    state)[ENTITY_CONTROL_DEVICE_ELIGIBLE_DELIVERABLE_VERSIONS];

export const controlDeviceAggregationsSelector = state => aggregationStoreSelector(state)[ENTITY_CONTROL_DEVICE];
export const controlDeviceEntitiesSelector = state => entityStoreSelector(state)[ENTITY_CONTROL_DEVICE];
const controlDeviceSelectionSelector = state => selectionStoreSelector(state)[ENTITY_CONTROL_DEVICE];
const loadingSelector = state => loadingStoreSelector(state)['controlDevices'];
export const devicesLoadingSelector = createSelector(
    [loadingSelector],
    loading => loading.devices,
);
export const devicesAssignedLoadingSelector = createSelector(
    [loadingSelector],
    loading => loading.devicesAssigned,
);
export const distroWhitelistLoadingSelector = createSelector(
    [loadingSelector],
    loading => loading.distroWhitelist,
);
export const packageDeliverableWhitelistLoadingSelector = createSelector(
    [loadingSelector],
    loading => loading.packageDeliverableWhitelist,
);
export const fileDeliverableWhitelistLoadingSelector = createSelector(
    [loadingSelector],
    loading => loading.fileDeliverableWhitelist,
);

const controlDevicePagesSelector = state => getOr({}, ENTITY_CONTROL_DEVICE_SCOPE_DEVICES,
    indicesStoreSelector(state)[ENTITY_CONTROL_DEVICE]);

export const controlDeviceTBM3CountSelector = state => controlDeviceAggregationsSelector(state).totalTBM3Count;
export const controlDeviceVCMCountSelector = state => controlDeviceAggregationsSelector(state).totalVCMCount;
export const controlDeviceCM4CountSelector = state => controlDeviceAggregationsSelector(state).totalCM4Count;
export const testDeviceCountSelector = state => controlDeviceAggregationsSelector(state).testCount;
export const blockedDeviceCountSelector = state => controlDeviceAggregationsSelector(state).blockedCount;
export const warningDeviceCountSelector = state => controlDeviceAggregationsSelector(state).warningDeviceCount;
export const errorDeviceCountSelector = state => controlDeviceAggregationsSelector(state).errorDeviceCount;
export const inCustomerFleetCountSelectorTBM3 = state => controlDeviceAggregationsSelector(state).inCustomerFleetCountTBM3;
export const inCustomerFleetCountSelectorCM4 = state => controlDeviceAggregationsSelector(state).inCustomerFleetCountCM4;

export const controlDevicePageNumberSelector = state => get('number', controlDevicePagesSelector(state));
export const controlDevicePageCountSelector = state => get('totalPages', controlDevicePagesSelector(state));
export const controlDeviceTotalElementsSelector = state => get('totalElements', controlDevicePagesSelector(state));

export const controlDevicePageItemsSelector = createSelector(
    [controlDevicePagesSelector, controlDeviceEntitiesSelector, controlDevicePageNumberSelector],
    (pages, entities, pageNumber) => {
        return map(id => entities[id], getOr([], pageNumber, pages));
    },
);

export const controlDeviceSelector = (state, ownProps) => controlDeviceEntitiesSelector(state)[ownProps.serialNumber];
export const controlDeviceInfoSelector = (state, ownProps) =>
    controlDeviceInfoDataSelector(state)[ownProps.serialNumber];

export const controlDeviceEligibleDeliverableVersionsSelector = createSelector(
    [controlDeviceEligibleDeliverableVersionsDataSelector],
    (entities) => {
        return entities ? entities : [];
    },
);

export const controlDeviceEligibleDeliverablesSizeSelector = createSelector(
    [controlDeviceEligibleDeliverableVersionsDataSelector],
    (entities) => {
        return getEligibleSize(entities);
    },
);
export const controlDeviceEligiblePackageVersionsSelector = createSelector(
    [controlDeviceEligibleDeliverableVersionsDataSelector],
    (entities) => {
        return entities?.APP || {};
    },
);
export const controlDeviceEligibleCM4VersionsSelector = createSelector(
    [controlDeviceEligibleDeliverableVersionsDataSelector],
    (entities) => {
        return entities?.CM4G || [];
    },
);
export const controlDeviceEligibleFileVersionsSelector = createSelector(
    [controlDeviceEligibleDeliverableVersionsDataSelector],
    (entities) => {
        return entities?.FILE || [];
    },
);

export const controlDeviceCountsByBaseSwSelector = state => controlDeviceAggregationsSelector(state).countsByBaseSw;
export const controlDeviceCountsByDistroSelector = state => controlDeviceAggregationsSelector(state).countsByDistro;
export const controlDeviceCountsByHwVariantSelector = state =>
    controlDeviceAggregationsSelector(state).countsByHwVariant;

export const controlDeviceCountsByBaseSwInCustomerFleetSelector = state =>
    controlDeviceAggregationsSelector(state).countsByBaseSwInCustomerFleet;

export const controlDeviceCountsByDistroInCustomerFleetSelector = state =>
    controlDeviceAggregationsSelector(state).countsByDistroInCustomerFleet;

export const controlDeviceCountsByHwVariantInCustomerFleetSelector = state =>
    controlDeviceAggregationsSelector(state).countsByHwVariantInCustomerFleet;

export const canReadControlDevicesSelector = state =>
    permissionsSelector(state).includes(PERMISSION_CONTROL_DEVICE_READ);

export const canCreateControlDevicesSelector = state =>
    permissionsSelector(state).includes(PERMISSION_CONTROL_DEVICE_CREATE);

export const canUpdateControlDevicesSelector = (state, ownProps) => {
    const controlDevice = controlDeviceSelector(state, ownProps);
    return permissionsSelector(state).includes(PERMISSION_CONTROL_DEVICE_UPDATE) || (isTestManager(state)
        && controlDevice && controlDevice.testReleasesActive);
};

export const canQuickWhitelistControlDevicesSelector = (state, ownProps) => {
    const controlDevice = controlDeviceSelector(state, ownProps);
    return (isTestManager(state) && controlDevice && controlDevice.testReleasesActive);
};

export const selectedAllControlDevicesSelector = state => controlDeviceSelectionSelector(state).selectedAll;
export const selectedControlDevicesSelector = state => controlDeviceSelectionSelector(state).selectedIds;

export const selectedControlDeviceCountSelector = createSelector(
    [selectedAllControlDevicesSelector, selectedControlDevicesSelector, controlDeviceTotalElementsSelector],
    (selectedAll, selected, totalElements) => {
        if (selectedAll) {
            return totalElements;
        }
        return size(selected);
    },
);

export const filterSettingsControlDevicesSelector = createSelector(
    [filterSettingsSelector],
    (filterSettings) => filterSettings[ENTITY_CONTROL_DEVICE],
);

export const controlDeviceEditorSelector = state => state.ui.controlDeviceEditor;

export const showDeviceDetailsSidebarSelector = createSelector(
    [deviceDetailsUiSelector],
    deviceDetailsUi => deviceDetailsUi.showDeviceDetailsSidebar,
);

export const getSelectedTabIdSelector = createSelector(
    [deviceDetailsUiSelector],
    deviceDetailsUi => deviceDetailsUi.tabId,
);

export const getCurrentlySelectedDeviceSerialNumberSelector = createSelector(
    [deviceDetailsUiSelector],
    deviceDetailsUi => deviceDetailsUi.currentSerialNumber,
);

export const getCurrentlySelectedDeviceInfoSelector = createSelector(
    [deviceDetailsUiSelector, controlDeviceInfoDataSelector],
    (deviceDetailsUi, controlDeviceInfo) => {
        return controlDeviceInfo && controlDeviceInfo[deviceDetailsUi.currentSerialNumber];
    },
);

export const deviceStatusNamesSelector = createSelector(
    [deviceStatusNamesEntitySelector],
    (deviceStatusNames) => {
        return deviceStatusNames;
    },
);

export const deviceStatusTypesSelector = createSelector(
    [deviceStatusTypesEntitySelector],
    (deviceStatusTypes) => {
        return deviceStatusTypes;
    },
);
