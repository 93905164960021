import PropTypes from 'prop-types';
import React from 'react';

import Tile from '~/features/overview/components/Tile';
import Legend from '~/features/overview/components/Legend';
import DonutOld from '~/features/overview/components/DonutOld';

const labelWrapperClasses = 'position-absolute bottom-25 padding-left-20 padding-right-20 bg-darkest';
const labelClasses = 'text-size-20 text-bold text-uppercase text-color-white';

export const DonutTile = ({ label, data, tileDescriptor, onClick }) => {
    return (
        <Tile>
            <div className='bg-white bg-tile height-500'>
                <div className='position-relative'>
                    <div
                        className='position-absolute padding-top-15 padding-left-10 padding-right-10 width-100pct z-index-1'>
                        <Legend route={tileDescriptor.link} query={tileDescriptor.query} data={data}/>
                    </div>
                </div>
                <div className='display-flex justify-content-center align-items-end height-100pct'>
                    <DonutOld tileDescriptor={tileDescriptor} data={data} onClick={onClick}/>
                </div>
                <div className='position-relative'>
                    <div className={labelWrapperClasses}>
                        <span className={labelClasses}>{label}</span>
                    </div>
                </div>
            </div>
        </Tile>
    );
};

export default DonutTile;

DonutTile.propTypes = {
    // props
    label: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
    ]),
    data: PropTypes.number,
    tileDescriptor: PropTypes.object,
    // functions
    onClick: PropTypes.func,
};
