import map from 'lodash/fp/map';
//import reverse from 'lodash/fp/reverse';

import moment from 'moment';

const toBeginInterval = (stat, timeInterval) => {
    const beginMoment = moment.utc(stat.beginTimestamp);
    const startMoment = beginMoment.startOf(timeInterval === 'MONTH' ? 'month' : 'week');
    const startValue = startMoment.valueOf();
    return startValue;
};
const toEndInterval = (stat, timeInterval) => {
    const beginMoment = moment.utc(stat.beginTimestamp);
    const endMoment = beginMoment.endOf(timeInterval === 'MONTH' ? 'month' : 'week');
    const endValue = endMoment.valueOf();
    return endValue;
};

export const parseUpdateEventStats = (stats, timeInterval) => {
    return map(stat => {
        const beginInterval = toBeginInterval(stat, timeInterval);
        const endInterval = toEndInterval(stat, timeInterval);
        const midInterval = (beginInterval + endInterval) / 2;
        return {
            ...stat,
            date: new Date(stat.beginTimestamp),
            endDate: new Date(stat.endTimestamp),
            beginInterval: new Date(beginInterval),
            endInterval: new Date(endInterval),
            midInterval: new Date(midInterval),
            midDate: new Date((stat.beginTimestamp + stat.endTimestamp) / 2),
        };
    }, stats);
};
