import map from 'lodash/fp/map';
import { createSelector } from 'reselect';

import {
    PERMISSION_DEVICE_ACTION_READ,
    PERMISSION_DEVICE_ACTION_CREATE,
    PERMISSION_DEVICE_ACTION_UPDATE,
    PERMISSION_DEVICE_ACTION_DELETE,
} from '~/features/base/constants/permissions';
import { ENTITY_DEVICE_ACTION } from '~/features/base/constants/entities';

import { permissionsSelector } from '~/features/user/selectors/permissionSelectors';
import {
    aggregationStoreSelector,
    entityStoreSelector,
    loadingStoreSelector,
    indicesStoreSelector,
} from '~/features/higherorder/selectors/dataSelectors';

const deviceActionAggregationsSelector = state => aggregationStoreSelector(state)[ENTITY_DEVICE_ACTION];
const deviceActionEntitiesSelector = state => entityStoreSelector(state)[ENTITY_DEVICE_ACTION];
const deviceActionSortedIdsSelector = state => indicesStoreSelector(state)[ENTITY_DEVICE_ACTION];
export const deviceActionsLoadingSelector = state => loadingStoreSelector(state)['deviceActions'];

export const logRequestsCountSelector = state => deviceActionAggregationsSelector(state).totalCount;

export const deviceActionsSelector = createSelector(
    [deviceActionSortedIdsSelector, deviceActionEntitiesSelector],
    (sortedIds, entities) => {
        return map(id => entities[id], sortedIds);
    }
);

export const deviceActionSelector = (state, ownProps) =>
    deviceActionEntitiesSelector(state)[ownProps.serialNumber];

export const canReadDeviceActionsSelector = state =>
    permissionsSelector(state).includes(PERMISSION_DEVICE_ACTION_READ);

export const canCreateDeviceActionsSelector = state =>
    permissionsSelector(state).includes(PERMISSION_DEVICE_ACTION_CREATE);

export const canUpdateDeviceActionsSelector = state =>
    permissionsSelector(state).includes(PERMISSION_DEVICE_ACTION_UPDATE);

export const canDeleteDeviceActionsSelector = state =>
    permissionsSelector(state).includes(PERMISSION_DEVICE_ACTION_DELETE);

export const deviceActionEditorSelector = state =>
    state.ui.deviceActionEditor;
