import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { doHandleErrorSaga, postHTTP } from '~/features/base/sagas/sagaUtil';
import { mergeEntity } from '~/features/higherorder/actions/entityActions';
import {
    ENTITY_DEVICE_COUNTS_BY_COUNTRIES,
    ENTITY_DEVICE_COUNTS_BY_VEHICLES,
} from '~/features/base/constants/entities';
import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';
import { finishedAction } from '~/features/higherorder/transforms/actionTransforms';
import {
    FETCH_DEVICE_COUNTS_BY_COUNTRIES,
    FETCH_DEVICE_COUNTS_BY_VEHICLES,
} from '~/features/devices/actions/ui/deviceDistributionsActions';
import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';

export function* getDeviceDistributionsURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/control-device`;
}
export function* doFetchDeviceCountsByCountries(action) {
    try {
        const url = yield call(getDeviceDistributionsURL);
        action.payload = {};
        const response = yield call(postHTTP, `${url}/counts-by/country-network-code`, JSON.stringify(action.payload));
        yield put(mergeEntity(response, { entityName: ENTITY_DEVICE_COUNTS_BY_COUNTRIES }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchDeviceCountsByVehicles(action) {
    try {
        const url = yield call(getDeviceDistributionsURL);
        const searchCriteria = action.payload.searchCriteria;
        const response = yield call(postHTTP, `${url}/counts-by/vehicle-variant`, JSON.stringify(searchCriteria));
        yield put(mergeEntity(response, { entityName: ENTITY_DEVICE_COUNTS_BY_VEHICLES }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* fetchDeviceCountsByCountriesSaga() {
    yield takeLatest(FETCH_DEVICE_COUNTS_BY_COUNTRIES, doFetchDeviceCountsByCountries);
}

export function* fetchDeviceCountsByVehiclesSaga() {
    yield takeLatest(FETCH_DEVICE_COUNTS_BY_VEHICLES, doFetchDeviceCountsByVehicles);
}

