import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

interface ReloadButtonProps {
    onReloadClick: () => void;
    className?: string;
}

const ReloadButton: React.FC<ReloadButtonProps> = ({ className, onReloadClick }) => {

    return (
        <div className={classNames(className)}>
            <a id='create-control-device-button'
               className='btn btn-default'
               onClick={onReloadClick}>
                <span className='rioglyph rioglyph-refresh' aria-hidden='true'></span>
                <FormattedMessage id='intl-msg:reload'/>
            </a>
        </div>
    );
};

export default ReloadButton;
