import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { dynamicBaseQuery } from '~/api/common/dynamicBaseQuery';
import {DeviceDeliverableRequest} from "~/api/models";

export const deviceDeliverableManagementApi = createApi({
    reducerPath: 'deviceDeliverableManagementApi',
    baseQuery: dynamicBaseQuery,
    endpoints: (builder) => ({
        deviceDeliverableUninstall: builder.mutation<{}, DeviceDeliverableRequest>({
            query: (body: DeviceDeliverableRequest) => ({
                url: `/v2/client/devices/deliverables/uninstall`,
                method: 'DELETE',
                body,
            }),
        }),
        deviceDeliverableRevokeUninstall: builder.mutation<{}, DeviceDeliverableRequest>({
            query: (body: DeviceDeliverableRequest) => ({
                url: `/v2/client/devices/deliverables/revoke-uninstall`,
                method: 'POST',
                body,
            }),
        }),
    })
});

export const {
    useDeviceDeliverableUninstallMutation,
    useDeviceDeliverableRevokeUninstallMutation
} = deviceDeliverableManagementApi;
