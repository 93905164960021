import {
    createApi,
} from '@reduxjs/toolkit/query/react';
import { ControlDeviceSearchCriteria, PageVehicleDefaultView, VehicleDetailsView } from '~/api/models';
import { dynamicBaseQuery } from '~/api/common/dynamicBaseQuery';
import { PageQuery } from '~/api/models/PageQuery';

const baseUrl: string = '/v2/client/vehicle';
export const vehicleManagementApi = createApi({
    reducerPath: 'vehiclesApi',
    baseQuery: dynamicBaseQuery,
    endpoints: (builder) => ({
        fetchVehicles: builder.query<PageVehicleDefaultView, ControlDeviceSearchCriteria & PageQuery>({
            query: (criteria: ControlDeviceSearchCriteria & PageQuery) => ({
                url: `${baseUrl}/search/default-view?page=${criteria.pageNumber}&size=${criteria.pageSize}`,
                method: 'POST',
                body: criteria,
            }),
            serializeQueryArgs: ({ queryArgs: { pageNumber, pageSize, ...rest } }) => {
                return JSON.stringify({ ...rest });
            },
            merge: (currentCache, newItems) => {
                currentCache.content = [...currentCache.content, ...newItems.content];
            },

        }),
        fetchVehicleDetails: builder.query<VehicleDetailsView, string>({
            query: (vinNumber: string) => ({
                url: `${baseUrl}/${vinNumber}/information`,
                method: 'GET',
            }),
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
        }),
        fetchVehicleGroups: builder.query<VehicleDetailsView, string>({
            query: (vinNumber: string) => ({
                url: `${baseUrl}/${vinNumber}/information`,
                method: 'GET',
            }),
        })
    }),
});

export const { useFetchVehiclesQuery, useFetchVehicleDetailsQuery } = vehicleManagementApi;
