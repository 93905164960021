import { call, put, select, takeLatest } from 'redux-saga/effects';

import { showModal } from '~/features/base/actions/ui/modalsActions';
import { fetchFilteredGroups } from '~/features/groups/actions/groupActions';

import { doFetchFilteredGroupsSaga } from '~/features/groups/sagas/groupSaga';
import {
    changeDeliverableEditor,
    changeDeliverableVersionEditor,
    resetDeliverableEditor,
    SHOW_DELIVERABLE_DELETION_DIALOG,
    SHOW_DELIVERABLE_EDITOR_DIALOG,
    SHOW_DELIVERABLE_FILE_DELETION_DIALOG,
    SHOW_DELIVERABLE_VERSION_DELETION_DIALOG,
} from '~/features/deliverables/actions/deliverableActions';
import {
    DELIVERABLE_DELETE_MODAL_NEO,
    DELIVERABLE_EDITOR_MODAL_NEO,
    DELIVERABLE_FILE_DELETE_MODAL_NEO,
    DELIVERABLE_VERSION_DELETE_MODAL,
} from '~/features/base/constants/modals';
import { deliverableEntitiesSelector } from '~/features/deliverables/selectors/deliverableSelectors';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';

export function* doPrepareForNewDeliverableSaga(payload) {
    yield put(changeDeliverableEditor({
        isNew: true,
        deliverableId: payload.deliverableId,
        isDeliverableVersion: payload.isDeliverableVersion,
    }));
}

export function* doPrepareForExistingDeliverableVersionSaga(payload) {
    const deliverables = yield select(deliverableEntitiesSelector, {});
    const selectedDeliverable = deliverables.filter(deliverable => {
        return deliverable.deliverableId === payload.deliverableId &&
            toShortSemanticVersion(deliverable.deliverableVersion) === payload.deliverableVersion;
    });

    if (selectedDeliverable.length === 1) {
        yield put(changeDeliverableEditor({
            isNew: false,
            ...selectedDeliverable[0],
        }));
    }
}

export function* doPrepareForNewDeliverableVersionSaga(fileDeliverable) {
    yield put(changeDeliverableVersionEditor({
        isNew: true,
        ...fileDeliverable,
    }));
}

export function* doShowDeliverableEditorDialogSaga(action) {
    yield put(resetDeliverableEditor());
    let selectedDeliverable;
    if (action.payload.isNew) {
        yield call(doPrepareForNewDeliverableSaga, action.payload);
    } else {
        yield call(doPrepareForExistingDeliverableVersionSaga, action.payload);
    }

    yield call(doFetchFilteredGroupsSaga, fetchFilteredGroups());

    yield put(showModal({
        modalType: DELIVERABLE_EDITOR_MODAL_NEO,
        modalProps: {
            model: selectedDeliverable,
        },
    }));
}

export function* doShowDeliverableDeletionDialogSaga(action) {
    const { deliverableId, deliverableType, deliverableVersions } = action.payload;
    yield put(showModal({
        modalType: DELIVERABLE_DELETE_MODAL_NEO,
        modalProps: {
            deliverableId,
            deliverableType,
            deliverableVersions,
        },
    }));
}

export function* doShowDeliverableVersionDeletionDialogSaga(action) {
    const { deliverableId, deliverableType, deliverableVersion } = action.payload;
    yield put(showModal({
        modalType: DELIVERABLE_VERSION_DELETE_MODAL,
        modalProps: {
            deliverableId,
            deliverableType,
            deliverableVersion,
        },
    }));
}

export function* doShowDeliverableFileDeletionDialogSaga(action) {
    yield put(resetDeliverableEditor());
    const { deliverableId, fileName } = action.payload;
    yield put(showModal({
        modalType: DELIVERABLE_FILE_DELETE_MODAL_NEO,
        modalProps: {
            deliverableId,
            fileName,
        },
    }));
}

export function* showDeliverableEditorDialogSagaNeo() {
    yield takeLatest(SHOW_DELIVERABLE_EDITOR_DIALOG, doShowDeliverableEditorDialogSaga);
}

export function* showDeliverableDeletionDialogSagaNeo() {
    yield takeLatest(SHOW_DELIVERABLE_DELETION_DIALOG, doShowDeliverableDeletionDialogSaga);
}

export function* showDeliverableVersionDeletionDialogSagaNeo() {
    yield takeLatest(SHOW_DELIVERABLE_VERSION_DELETION_DIALOG, doShowDeliverableVersionDeletionDialogSaga);
}

export function* showDeliverableFileDeletionDialogSagaNeo() {
    yield takeLatest(SHOW_DELIVERABLE_FILE_DELETION_DIALOG, doShowDeliverableFileDeletionDialogSaga);
}
