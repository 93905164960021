import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import {
    DELIVERABLES_APPS_PATH,
    DELIVERABLES_DISTROS_PATH,
    DELIVERABLES_FILES_PATH,
    ARTIFACTS_PATH,
} from '~/features/base/constants/routes';

export class DeliverableTypeTabs extends PureComponent {

    render() {
        const navigation = this.renderNavigation();
        return (
            <div className='file-details-tabs row'>
                <div className='col-md-12'>
                    {navigation}
                </div>
            </div>
        );
    }

    renderNavigationItem(basePath, view, label, currentView) {
        const className = {
            active: currentView === view,
        };
        return (
            <li key={view} className={classNames(className)}>
                <Link to={`${basePath}/${view}`}><FormattedMessage id={label}/></Link>
            </li>
        );
    }

    renderNavigation() {
        const { basePath, view } = this.props;
        return (
            <ul className='nav nav-tabs user-select-none'>
                {this.renderNavigationItem(basePath, DELIVERABLES_FILES_PATH, 'intl-msg:files', view)}
                {this.renderNavigationItem(basePath, DELIVERABLES_APPS_PATH, 'intl-msg:apps', view)}
                {this.renderNavigationItem(basePath, DELIVERABLES_DISTROS_PATH, 'intl-msg:distros', view)}
            </ul>
        );
    }
}

export default DeliverableTypeTabs;

DeliverableTypeTabs.defaultProps = {
    // props
    basePath: '',
    view: ARTIFACTS_PATH,
};

DeliverableTypeTabs.propTypes = {
    // props
    basePath: PropTypes.string,
    view: PropTypes.string,
};
