import React from 'react';
import GroupChipList from "~/features/groups/components/GroupChipList";
import DeviceChipList from "~/features/devices/components/DeviceChipList";

interface UninstallWhitelistedProps {
    groups: string[];
    devices: string[];
}

const UninstallWhitelisted: React.FC<UninstallWhitelistedProps> = ({ groups, devices }) => {
    return (
        <div>
            <GroupChipList groups={groups} bsStyle='warning' />
            <DeviceChipList serialNumbers={devices} bsStyle='warning' />
        </div>
    );
};

export default UninstallWhitelisted;
