export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';

export const changeOperations = {
    [CREATE]: {
        id: CREATE,
        label: 'intl-msg:changeOperation.create',
    },
    [UPDATE]: {
        id: UPDATE,
        label: 'intl-msg:changeOperation.update',
    },
    [DELETE]: {
        id: DELETE,
        label: 'intl-msg:changeOperation.delete',
    },
};
