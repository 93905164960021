import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLazyDownloadLogQuery, } from '~/api/logs/LoggingService.api';
import download from 'downloadjs';

interface LogFilesDownloadButtonProps {
    contentId: string;
    fileKey: string;
}

const LogFilesDownloadButton: React.FC<LogFilesDownloadButtonProps> = ({ contentId, fileKey }) => {
    const [downloadLog] = useLazyDownloadLogQuery<any>();
    const getFileFullName = () => {
        try {
            return fileKey.split('/')[2];
        } catch (e) {
            return `log_${contentId}`;
        }
    };
    const handleOnClick = async() => {
        await downloadLog(contentId).unwrap().then(async(result) => {
            const response = await fetch(result, {
                method: 'GET',
                headers: {
                    Accept: 'application/octet-stream',
                    'Content-Type': 'application/octet-stream',
                },
            });
            response.blob().then(data => download(data, getFileFullName(), 'application/octet-stream'));

        });
    };

    return (<div className='btn-toolbar pull-right'>
        <div className='btn-group'>
            <FormattedMessage id='intl-msg:download'>
                {title =>
                    <button className='btn btn-link'
                            title={title}
                            data-key={contentId}
                            onClick={handleOnClick}>
                        <span className='rioglyph rioglyph-download' aria-hidden='true'></span>
                    </button>
                }
            </FormattedMessage>
        </div>
    </div>);

};

export default LogFilesDownloadButton;
