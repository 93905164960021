import { call, put, select, fork, takeEvery, takeLatest } from 'redux-saga/effects';

import {
    FETCH_SYS_PARAM_SYSTEM_VALUES,
    CREATE_SYS_PARAM_SYSTEM_VALUE,
    UPDATE_SYS_PARAM_SYSTEM_VALUE,
    DELETE_SYS_PARAM_SYSTEM_VALUE,
} from '~/features/sysParams/actions/sysParamSystemValueActions';
import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';
import { hideModal } from '~/features/base/actions/ui/modalsActions';
import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';
import { stringifyToQuery } from '~/features/base/utils/query';

import { doHandleErrorSaga, getHTTP, postHTTP, putHTTP, deleteHTTP } from '~/features/base/sagas/sagaUtil';
import {
    toShortSemanticVersion,
} from '~/features/base/utils/versionNumberConverter';
import {
    parseSysParamSystemValues,
    parseSysParamSystemValue,
} from '~/features/sysParams/transforms/parseSysParamSystemValues';
import { ENTITY_SYS_PARAM_SYSTEM_VALUE } from '~/features/base/constants/entities';
import { mergeEntities, mergeEntity, deleteEntity } from '~/features/higherorder/actions/entityActions';
import { finishedAction, failedAction } from '~/features/higherorder/transforms/actionTransforms';

const entityName = ENTITY_SYS_PARAM_SYSTEM_VALUE;

export function* getSysParamSystemValuesURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/system-params/device-specific-params`;
}

export function* doFetchSysParamSystemValuesSaga(action) {
    const serialNumber = action.payload.serialNumber;
    const shortBaseSwVersion = action.payload.shortBaseSwVersion;
    try {
        const sysParamSystemValuesServiceURL = yield call(getSysParamSystemValuesURL);
        const query = stringifyToQuery({
            hwSerial: serialNumber,
            swVersion: shortBaseSwVersion,
        });
        const response = yield call(getHTTP, `${sysParamSystemValuesServiceURL}?${query}`);
        const entities = parseSysParamSystemValues(response.content);
        yield put(mergeEntities(entities, { entityName, serialNumber, shortBaseSwVersion }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doCreateSysParamSystemValueSaga(action) {
    try {
        const sysParamSystemValuesServiceURL = yield call(getSysParamSystemValuesURL);
        const systemValue = action.payload.sysParamSystemValue;
        const serialNumber = systemValue.hwSerial;
        const shortBaseSwVersion = systemValue.shortBaseSwVersion;

        const response = yield call(postHTTP, `${sysParamSystemValuesServiceURL}`,
            JSON.stringify(action.payload.sysParamSystemValue));
        const parsedResponse = parseSysParamSystemValue(response);
        yield put(mergeEntity(parsedResponse, { entityName, serialNumber, shortBaseSwVersion }));
        yield put(hideModal());
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
        yield put(failedAction(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doUpdateSysParamSystemValueSaga(action) {
    try {
        const sysParamSystemValuesServiceURL = yield call(getSysParamSystemValuesURL);
        const systemValue = action.payload.sysParamSystemValue;
        const serialNumber = systemValue.hwSerial;
        const shortBaseSwVersion = systemValue.shortBaseSwVersion;

        const response = yield call(putHTTP, `${sysParamSystemValuesServiceURL}`,
            JSON.stringify(systemValue));
        const parsedResponse = parseSysParamSystemValue(response);
        yield put(mergeEntity(parsedResponse, { entityName, serialNumber, shortBaseSwVersion }));
        yield put(hideModal());
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
        yield put(failedAction(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doDeleteSysParamSystemValueSaga(action) {
    try {
        const serviceURL = yield call(getSysParamSystemValuesURL);
        const systemValue = action.payload;
        const serialNumber = systemValue.hwSerial;
        const shortBaseSwVersion = toShortSemanticVersion(systemValue.baseSoftwareVersion);

        const encodedShortBaseSwVersion = encodeURIComponent(shortBaseSwVersion);
        const encodedAccessKey = encodeURIComponent(`${systemValue.accessKey}`);
        const encodedSerialNumber = encodeURIComponent(`${systemValue.hwSerial}`);
        const url = `${serviceURL}/${encodedShortBaseSwVersion}/${encodedAccessKey}/${encodedSerialNumber}`;
        const response = yield call(deleteHTTP, url);
        const parsedResponse = parseSysParamSystemValue(response);
        yield put(deleteEntity(parsedResponse, { entityName, serialNumber, shortBaseSwVersion }));
        yield put(hideModal());
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* fetchSysParamSystemValuesSaga() {
    yield takeLatest(FETCH_SYS_PARAM_SYSTEM_VALUES, doFetchSysParamSystemValuesSaga);
}

export function* createSysParamSystemValueSaga() {
    yield takeEvery(CREATE_SYS_PARAM_SYSTEM_VALUE, doCreateSysParamSystemValueSaga);
}

export function* updateSysParamSystemValueSaga() {
    yield takeEvery(UPDATE_SYS_PARAM_SYSTEM_VALUE, doUpdateSysParamSystemValueSaga);
}

export function* deleteSysParamSystemValueSaga() {
    yield takeEvery(DELETE_SYS_PARAM_SYSTEM_VALUE, doDeleteSysParamSystemValueSaga);
}
