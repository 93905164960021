import { ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS_SCOPE_DEVICE } from '~/features/base/constants/entities';

export const FETCH_STATE_NOTIFICATIONS_OF_DEVICE = 'FETCH_STATE_NOTIFICATIONS_OF_DEVICE';
export const REQUEST_NEW_STATE_NOTIFICATIONS = 'REQUEST_NEW_STATE_NOTIFICATIONS';
export const RECOVER_SYSTEM_STATE_COLUMN_SETTINGS = 'RECOVER_SYSTEM_STATE_COLUMN_SETTINGS';
export const SET_SYSTEM_STATE_COLUMN_SETTINGS = 'SET_SYSTEM_STATE_COLUMN_SETTINGS';

export const fetchSystemStateNotificationsOfDevice = ({
    scope = ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS_SCOPE_DEVICE,
    page,
    size,
    searchCriteria,
}) => ({
    type: FETCH_STATE_NOTIFICATIONS_OF_DEVICE,
    scope,
    payload: {
        page,
        size,
        searchCriteria,
    },
});

export const requestNewSystemStateNotifications = (
    serialNumber,
    partition,
) => ({
    type: REQUEST_NEW_STATE_NOTIFICATIONS,
    payload: {
        serialNumber,
        partition,
    },
});

export const setSystemStateColumnSettings = (
    columnSettings,
) => ({
    type: SET_SYSTEM_STATE_COLUMN_SETTINGS,
    payload: {
        columnSettings,
    },
});

export const recoverSystemStateColumnSettings = (
) => ({
    type: RECOVER_SYSTEM_STATE_COLUMN_SETTINGS,
});

