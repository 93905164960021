import {DeliverableModel} from "~/features/devices/models/DeliverableModel";

export function getEligibleSize(input) {
    let size = 0;
    for (let deliverableType in input) {
        if (input.hasOwnProperty(deliverableType)) {
            for (let deliverableID in input[deliverableType]) {
                if (input[deliverableType].hasOwnProperty(deliverableID)) {
                    size++;
                }
            }
        }
    }
    return size;
}

export function transformEligibleDeliverables(eligibleDeliverableVersions): DeliverableModel[] {
    let result: any[] = [];
    // Iterate through the keys of the input object
    for (let deliverableType in eligibleDeliverableVersions) {
        if (eligibleDeliverableVersions.hasOwnProperty(deliverableType)) {
            // Iterate through the keys of each deliverable type
            for (let deliverableID in eligibleDeliverableVersions[deliverableType]) {
                if (eligibleDeliverableVersions[deliverableType].hasOwnProperty(deliverableID)) {
                    // Create the object for the component view
                    let deliverableVersion = eligibleDeliverableVersions[deliverableType][deliverableID];
                    result.push({
                        deliverableId: deliverableID,
                        eligibleDeliverableVersion: deliverableVersion,
                        deliverableType: deliverableType
                    });
                }
            }
        }
    }

    return result;
}
