import { call, put, takeLatest } from 'redux-saga/effects';

import {
    FILE_EDITOR_MODAL,
    FILE_DELETE_MODAL,
} from '~/features/base/constants/modals';

import { showModal } from '~/features/base/actions/ui/modalsActions';
import {
    resetFileEditor,
    changeFileEditor,
    SHOW_FILE_EDITOR_DIALOG,
    SHOW_FILE_DELETION_DIALOG,
} from '~/features/deliverables/features/files/actions/fileEditorActions';

export function* doPrepareForNewSaga(fileDesc) {
    yield put(changeFileEditor({
        isNew: true,
        ...fileDesc,
    }));
    // Should suggest next patch  version
}

export function* doPrepareForExistingSaga(fileDesc) {
    yield put(changeFileEditor({
        isNew: false,
        ...fileDesc,
    }));
}

export function* doShowFileEditorDialogSaga(action) {
    yield put(resetFileEditor());
    if (action.payload.isNew) {
        yield call(doPrepareForNewSaga, action.payload);
    } else {
        yield call(doPrepareForExistingSaga, action.payload);
    }
    yield put(showModal({
        modalType: FILE_EDITOR_MODAL,
        modalProps: {},
    }));
}

export function* doShowFileDeletionDialogSaga(action) {
    yield put(resetFileEditor());
    const { fileId, fileVersion } = action.payload;
    yield put(changeFileEditor({
        fileId,
        fileVersion,
    }));
    yield put(showModal({
        modalType: FILE_DELETE_MODAL,
        modalProps: {},
    }));
}

export function* showFileEditorDialogSaga() {
    yield takeLatest(SHOW_FILE_EDITOR_DIALOG, doShowFileEditorDialogSaga);
}

export function* showFileDeletionDialogSaga() {
    yield takeLatest(SHOW_FILE_DELETION_DIALOG, doShowFileDeletionDialogSaga);
}
