import getOr from 'lodash/fp/getOr';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import {
    STRING,
    NUMERIC,
    ENUMERATION,
} from '~/features/sysParams/constants/sysParamTypes';

import SysParamDefinitionStringValueForm from '~/features/sysParams/components/SysParamDefinitionStringValueForm';
import SysParamDefinitionNumericValueForm from '~/features/sysParams/components/SysParamDefinitionNumericValueForm';
import SysParamDefinitionEnumerationValueForm
    from '~/features/sysParams/components/SysParamDefinitionEnumerationValueForm';

/**
 * System parameter system value editor form.
 */
export class SysParamSystemValueEditorForm extends PureComponent {
    constructor(props) {
        super(props);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onValueSelect = this.onValueSelect.bind(this);
    }

    onValueChanged(value) {
        this.props.changeSysParamSystemValueEditor({
            value,
        });
    }
    onValueSelect(index) {
        const model = this.props.model;
        const enumParameterDefinition = model.sysParamDefinition.enumParameterDefinition;
        const value = enumParameterDefinition.enumValues[index].value;
        this.props.changeSysParamSystemValueEditor({
            enumValueIndex: index,
            value,
        });
    }

    render() {
        const model = this.props.model;
        const valueForm = this.renderValueForm(model);
        // TODO Do we really wanna render a div?
        return (
            <div>{valueForm}</div>
        );
    }

    renderValueForm(model) {
        const type = getOr(STRING, 'sysParamDefinition.type', model);
        switch (type) {
            case NUMERIC:
                return this.renderNumericForm(model);
            case ENUMERATION:
                return this.renderEnumerationForm(model);
            case STRING:
            default:
                return this.renderStringForm(model);
        }
    }

    renderStringForm(model) {
        const stringParameterDefinition = getOr({}, 'sysParamDefinition.stringParameterDefinition', model);
        return (
            <SysParamDefinitionStringValueForm
                label={'intl-msg:plannedValue'}
                placeholder={'intl-msg:value.prompt'}
                value={model.value}
                maxLength={stringParameterDefinition.maxLength}
                onValueChanged={this.onValueChanged} />
        );
    }

    renderNumericForm(model) {
        const numericParameterDefinition = getOr({}, 'sysParamDefinition.numericParameterDefinition', model);
        return (
            <SysParamDefinitionNumericValueForm
                label={'intl-msg:plannedValue'}
                min={numericParameterDefinition.minValue}
                max={numericParameterDefinition.maxValue}
                value={model.value}
                onValueChanged={this.onValueChanged}/>
        );
    }

    renderEnumerationForm(model) {
        const enumParameterDefinition = getOr({}, 'sysParamDefinition.enumParameterDefinition', model);
        return (
            <SysParamDefinitionEnumerationValueForm
                label={'intl-msg:plannedValue'}
                value={model.enumValueIndex >= 0 ?
                    enumParameterDefinition.enumValues[model.enumValueIndex].value.toString()
                    : undefined}
                onValueSelect={this.onValueSelect}
                model={enumParameterDefinition}/>
        );
    }
}

export default SysParamSystemValueEditorForm;

SysParamSystemValueEditorForm.defaultProps = {
    // props
    model: {},
    // functions
    changeSysParamDefinitionEditor: noop,
};

SysParamSystemValueEditorForm.propTypes = {
    // props
    model: PropTypes.object,
    // functions
    changeSysParamSystemValueEditor: PropTypes.func,
};
