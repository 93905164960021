import trim from 'lodash/fp/trim';
import noop from 'lodash/fp/noop';

import classNames from 'classnames';

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

export class VINFormItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isValid: true//props.value ? validateVIN(props.value) : true,
        };

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.focused) {
            return {
                isValid: true//nextProps.value ? validateVIN(nextProps.value) : true,
            };
        }
        return prevState;
    }

    onChange = (event) => {
        const vin = trim(event.target.value).toUpperCase();
        const isValid = true//vin ? validateVIN(vin) : true;
        this.setState({
            isValid,
        });
        this.props.onChange({
            vinPrefix: vin || undefined,
        });
    }

    render() {
        const { label, value } = this.props;
        const { isValid } = this.state;

        const helpBlockMessage = !isValid ?
            <FormattedMessage id='intl-msg:invalidVIN'/> : undefined;

        return (
            <div className={classNames('form-group', { 'has-error': !isValid })}>
                <label className='control-label'>
                    <FormattedMessage id={label}/>
                </label>
                <span className='input-group width-100pct'>
                    <span className='input-group-addon'>
                        <span className='rioglyph rioglyph-search' aria-hidden='true'></span>
                    </span>
                    <FormattedMessage id='intl-msg:vin.prompt'>
                        { placeHolder =>
                            <input className='form-control'
                                type='text'
                                value={value}
                                placeholder={placeHolder}
                                onChange={this.onChange}/>
                        }
                    </FormattedMessage>
                </span>
                <span className='help-block'>{helpBlockMessage}</span>
            </div>

        );
    }
}

export default VINFormItem;

VINFormItem.defaultProps = {
    // props
    value: '',
    label: 'intl-msg:vinPrefix',
    // functions
    onChange: noop,
};

VINFormItem.propTypes = {
    // props
    value: PropTypes.string,
    label: PropTypes.string,
    // functions
    onChange: PropTypes.func,
};
