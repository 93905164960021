import React, { useMemo, useReducer, useEffect } from 'react';
import { SelectProps } from '@rio-cloud/rio-uikit/types';
import { FormattedMessage } from 'react-intl';
import SelectOTA from '~/features/base/components/forms/SelectOTA';
import {
    useFetchDeliverableIdsQuery,
    useLazyFetchDeliverableVersionsQuery
} from '~/api/deliverables/DeliverablesManagement.api';
import { DeliverableSearchCriteriaDeliverableTypeEnum } from '~/api/models/deliverable-search-criteria';
import { DELIVERABLE_TYPE_BUNDLE } from '~/features/deliverables/constants/deliverablesParameters';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';

interface DeliverableIdVersionSelectProps extends Partial<SelectProps<any>> {
    deliverableId: any;
    deliverableVersion: any;
    onDeliverableIdChange: (value: any) => void;
    onDeliverableVersionChange: (value: any) => void;
    hasError?: boolean;
    deliverableIdError?: boolean,
    deliverableVersionError?: boolean;
    readOnly?: boolean;
}

const DeliverableIdVersionSelect: React.FC<DeliverableIdVersionSelectProps> = ({
                                                                                   deliverableId,
                                                                                   deliverableVersion,
                                                                                   onDeliverableIdChange,
                                                                                   onDeliverableVersionChange,
                                                                                   hasError,
                                                                                   deliverableIdError,
                                                                                   deliverableVersionError,
                                                                                    readOnly= false,

                                                                               }) => {

    const { data: bundleIdsList } = useFetchDeliverableIdsQuery({
        deliverableType: DeliverableSearchCriteriaDeliverableTypeEnum.BUNDLE,
    });

    const [getDeliverableVersions] = useLazyFetchDeliverableVersionsQuery();

    const [deliverableVersions, setDeliverableVersions] = useReducer((prev, next) => {
        return { ...prev, ...next };
    }, {});

    useEffect(() => {
        if (deliverableId && !deliverableVersions[deliverableId]) {
            getVersions(deliverableId);
        }
    }, [deliverableId]);

    const getVersions = (deliverableId) => {
        if (!deliverableVersions[deliverableId]) {
            getDeliverableVersions({ deliverableId, deliverableType: DELIVERABLE_TYPE_BUNDLE.toUpperCase() })
                .then(result => {
                    const versionsList = result?.data?.content.map(version => ({
                        label: toShortSemanticVersion(version),
                        value: version,
                        id: toShortSemanticVersion(version)
                    }));
                    setDeliverableVersions({ ...deliverableVersions, [deliverableId]: versionsList });
                });
        }
    };

    const deliverableOptions = useMemo(() => {
        return bundleIdsList ? bundleIdsList?.content?.map(deliverableId => ({
            id: deliverableId,
            type: DELIVERABLE_TYPE_BUNDLE.toUpperCase(),
            label: deliverableId,
            value: deliverableId
        })) : [];
    }, [bundleIdsList]);

    const highlightError = (hasError: boolean) => hasError ? 'form-group has-feedback has-error' : '';

    return (
        <div className={`display-flex gap-10 ${highlightError(!!hasError && !!deliverableIdError)}`}>
            <div className={'width-50pct'}>
                <SelectOTA
                    title={'intl-msg:reportsBillingDeliverableId'}
                    placeholder={<FormattedMessage id={'intl-msg:reportsBillingDeliverableId'} />}
                    useFilter
                    useClear
                    onClear={()=>onDeliverableIdChange('')}
                    options={deliverableOptions}
                    value={[deliverableId]}
                    onChange={(v) => onDeliverableIdChange(v.value)}
                    hasError={deliverableIdError}
                    disabled={readOnly}
                />
            </div>
            <div className={`width-50pct ${highlightError(!!hasError && !!deliverableIdError)}\``}>
                <SelectOTA
                    title={'intl-msg:reportsBillingDeliverableVersion'}
                    placeholder={<FormattedMessage id={'intl-msg:reportsBillingDeliverableVersion'} />}
                    useFilter
                    useClear
                    disabled={!deliverableId || readOnly}
                    options={deliverableId? deliverableVersions[deliverableId] : []}
                    value={[toShortSemanticVersion(deliverableVersion)] || []}
                    onChange={(v) => onDeliverableVersionChange(v.value)}
                    hasError={deliverableVersionError}
                />
            </div>
        </div>
    );
};

export default DeliverableIdVersionSelect;
