import noop from 'lodash/fp/noop';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { fetchControlDeviceOperations } from '~/features/devices/actions/controlDeviceOperationsActions';
import {
    controlDeviceReportPageItemsSelector,
    pageCountOfPagesOfDeviceSelector,
    pageNumberOfPagesOfDeviceSelector,
    totalElementsOfPagesOfDeviceSelector,
} from '~/features/devices/selectors/controlDeviceOperationSelectors';
import DeviceReport from '~/features/deviceReports/components/DeviceReport';
import uid from '~/features/base/utils/uid';
import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';
import { parseQuery } from '~/features/base/utils/query';
import { copyToClipboard } from '~/features/apps/utils/appsUtils';
import { followRoute } from '~/features/base/actions/ui/routeActions';
import {
    FETCH_REPORT_AMOUNT_INCREMENT,
    FETCH_REPORT_FROM_OPERATION_ID, FETCH_REPORT_UNTIL_OPERATION_ID,
} from '~/features/devices/constants/deviceReportValues';

/**
 * Container for the device report
 */
export class DeviceReportContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.name = uid();
        this.state = {
            page: 1,
            searchCriteria: {
                size: FETCH_REPORT_AMOUNT_INCREMENT,
                serialNumber: this.props.serialNumber,
                operationIdRange: {
                    minValue: FETCH_REPORT_FROM_OPERATION_ID,
                    maxValue: FETCH_REPORT_UNTIL_OPERATION_ID,
                },
                dateRange: {
                    minValue: 0,
                    maxValue: Date.now(),
                },
            },
        };
    }

    onLoadMore = () => {
        this.setState({ page: this.state.page + 1 });
    };

    onDateRangeChange = (startDate, endDate) => {
        this.setState({
            ...this.state,
            page: 0,
            searchCriteria: {
                ...this.state.searchCriteria,
                dateRange: {
                    minValue: startDate.unix() * 1000,
                    maxValue: endDate.unix() * 1000,
                },
            },
        });
    };

    onResetFilter = () => {
        const searchCriteria = {
            size: FETCH_REPORT_AMOUNT_INCREMENT,
            serialNumber: this.props.serialNumber,
            operationIdRange: {
                minValue: FETCH_REPORT_FROM_OPERATION_ID,
                maxValue: FETCH_REPORT_UNTIL_OPERATION_ID,
            },
            dateRange: {
                minValue: 0,
                maxValue: Date.now(),
            },
        }
        this.setState({
            ...this.state,
            page: 1,
            searchCriteria: {
                ...searchCriteria,
            },
        });
    };

    onOperationIdMinimumChange = (value) => {
        this.setState({
            ...this.state,
            page: 1,
            searchCriteria: {
                ...this.state.searchCriteria,
                operationIdRange: {
                    ...this.state.searchCriteria.operationIdRange,
                    minValue: value,
                },
            },
        })
    };

    onOperationIdMaximumChange = (value) => {
        this.setState({
            ...this.state,
            page: 1,
            searchCriteria: {
                ...this.state.searchCriteria,
                operationIdRange: {
                    ...this.state.searchCriteria.operationIdRange,
                    maxValue: value,
                },
            },
        });
    };

    onCopyDeviceReportLink = (operationId = null) => {
        const url = this.createLink(operationId);
        copyToClipboard(url, false);
    };

    createLink = (operationId) => {
        const { serialNumber } = this.props;
        const { dateRange, operationIdRange } = this.state.searchCriteria;
        const operationIdParams = operationId
            ? `&operationIdStart=${operationId}&operationIdEnd=${operationId}`
            : `&operationIdStart=${operationIdRange.minValue}&operationIdEnd=${operationIdRange.maxValue}`;
        return `${window.location.origin}/#devices?serial-number-prefix=${serialNumber}&deviceSelected=true&showReport=true&startDate=${dateRange.minValue}&endDate=${dateRange.maxValue}${operationIdParams}`;
    };

    render() {
        const { report } = this.props;
        if (!report) {
            return null;
        }
        return (
            <DeviceReport
                {...this.props}
                onDateRangeChange={this.onDateRangeChange}
                onOperationIdMinimumChange={this.onOperationIdMinimumChange}
                onOperationIdMaximumChange={this.onOperationIdMaximumChange}
                onCopyDeviceReportLink={this.onCopyDeviceReportLink}
                onResetFilter={this.onResetFilter}
                startDate={this.state.searchCriteria.dateRange.minValue}
                endDate={this.state.searchCriteria.dateRange.maxValue}
                operationIdStart={this.state.searchCriteria.operationIdRange.minValue}
                operationIdEnd={this.state.searchCriteria.operationIdRange.maxValue}
                onLoadMore={this.onLoadMore}
            />
        );
    }

    componentWillMount() {
        const { search } = this.props;
        const parsedQuery = parseQuery(search);
        const { showReport, startDate, endDate, operationIdStart, operationIdEnd } = parsedQuery;
        if (showReport) {
            this.setState({
                ...this.state,
                searchCriteria: {
                    ...this.state.searchCriteria,
                    operationIdRange: {
                        minValue: Number(operationIdStart),
                        maxValue: Number(operationIdEnd),
                    },
                    dateRange: {
                        minValue: Number(startDate),
                        maxValue: Number(endDate),
                    },
                },
            }, () => {
                this.props.fetchControlDeviceOperations({ ...this.state.searchCriteria, page: 0 });
            });
        } else {
            this.props.fetchControlDeviceOperations({ ...this.state.searchCriteria, page: 0 });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { page, searchCriteria } = this.state;
        if (prevState.page !== this.state.page || prevState.searchCriteria !== this.state.searchCriteria) {
            this.props.fetchControlDeviceOperations({
                ...searchCriteria,
                serialNumber: this.props.serialNumber,
                page: 0,
                size: page * FETCH_REPORT_AMOUNT_INCREMENT,
            });
        }
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        pageCount: pageCountOfPagesOfDeviceSelector(state, ownProps),
        pageNumber: pageNumberOfPagesOfDeviceSelector(state, ownProps),
        totalElements: totalElementsOfPagesOfDeviceSelector(state, ownProps),
        report: controlDeviceReportPageItemsSelector(state, ownProps),
        search: searchSelector(state),
        pathname: pathnameSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        fetchControlDeviceOperations: (options) => {
            dispatch(fetchControlDeviceOperations(options));
        },
        followRoute: (options) => {
            dispatch(followRoute(options));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceReportContainer);

DeviceReportContainer.defaultProps = {
    // props
    search: '',
    serialNumber: undefined,
    report: undefined,
    // functions
    fetchControlDeviceOperations: noop,
    followRoute: noop,
};

DeviceReportContainer.propTypes = {
    // props
    search: PropTypes.string,
    serialNumber: PropTypes.string,
    report: PropTypes.object,
    // functions
    fetchControlDeviceOperations: PropTypes.func,
    followRoute: PropTypes.func,
};
