import getOr from 'lodash/fp/getOr';

const defaultState = {};

export const mapReducer = (state, action, scopeProp, reducer) => {
    const key = action.mergeProps[scopeProp];
    const subState = getOr({}, key, state);
    const newSubState = reducer(subState, action);
    if (newSubState === subState) {
        return state;
    }
    return {
        ...state,
        [key]: newSubState,
    };
};
export const createMapReducer = (entityName = 'entity', scopeProp = 'scope', reducer) => {
    return (state = defaultState, action = {}) => {
        if (action.mergeProps && action.mergeProps.entityName === entityName) {
            return mapReducer(state, action, scopeProp, reducer);
        }
        return state;
    };
};

export default createMapReducer;
