import React from 'react';
import { FormattedMessage } from 'react-intl';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';

import { resultOptions, ALL } from '~/features/base/constants/filterOptions';
import { selectionByValue } from '~/features/base/utils/selectUtils';

interface ResultFormItemProps {
    value?: string;
    onChange?: (result: any) => void;
    showLabel?: boolean;
}

const ResultFormItem: React.FC<ResultFormItemProps> = ({
                                                           value = ALL,
                                                           onChange = () => {},
                                                           showLabel = true,
                                                       }) => {
    const handleChange = (item) => {
        onChange({result: item?.id === ALL ? undefined : item?.value});
    };

    const optionsWithSelection = selectionByValue(value)(resultOptions);

    return (
        <div className='width-150'>
            {showLabel && <label className='control-label'>
                <FormattedMessage id={'intl-msg:result'}/>
            </label>}
            <Select className={'width-100pct'} options={optionsWithSelection} onChange={handleChange} />
        </div>
    );
};

export default ResultFormItem;
