import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import NumberInput from '@rio-cloud/rio-uikit/lib/es/NumberInput';

/**
 * System parameter definition bytearray editor form
 */
export class SysParamDefinitionByteArrayEditorForm extends PureComponent {

    onByteArrayByteLengthChange(value) {
        this.props.changeSysParamDefinitionEditor({
            byteArrayByteLength: value,
        });
    }

    render() {
        return (
            <div className='form-group'>
                <label className='control-label'>
                    <FormattedMessage id='intl-msg:byteLength' />
                </label>
                <NumberInput min={0} value={this.props.model.byteArrayByteLength}
                    onValueChanged={value => this.onByteArrayByteLengthChange(value)}/>
            </div>
        );
    }
}

export default SysParamDefinitionByteArrayEditorForm;

SysParamDefinitionByteArrayEditorForm.defaultProps = {
    model: {},
    changeSysParamDefinitionEditor: noop,
};

SysParamDefinitionByteArrayEditorForm.propTypes = {
    // props
    model: PropTypes.object,
    // functions
    changeSysParamDefinitionEditor: PropTypes.func,
};
