/**
 * Action type for fetching system parameters definition
 * @type {string}
 */
export const FETCH_SYS_PARAM_DEFINITIONS = 'FETCH_SYS_PARAM_DEFINITIONS';

/**
 * Action type for creating a system parameter definitions
 * @type {string}
 */
export const CREATE_SYS_PARAM_DEFINITION = 'CREATE_SYS_PARAM_DEFINITION';

/**
 * Action type for updating a system parameter definition
 * @type {string}
 */
export const UPDATE_SYS_PARAM_DEFINITION = 'UPDATE_SYS_PARAM_DEFINITION';

/**
 * Action type for deleting a system parameter definition
 * @type {string}
 */
export const DELETE_SYS_PARAM_DEFINITION = 'DELETE_SYS_PARAM_DEFINITION';

/**
 * Action type for copying the system parameter definitions
 * @type {string}
 */
export const COPY_SYS_PARAM_DEFINITIONS = 'COPY_SYS_PARAM_DEFINITIONS';

/**
 * Action creator for fetching of system parameter definitions
 * @param {object} options The options for which to fetch the system parameter definitions
 * @param {object} options.shortBaseSwVersion The base software version for which to fetch the system parameter
 * definitions
 * @returns {{type: string, payload: object}} The action for fetching of system parameter definitions
 */
export function fetchSysParamDefinitions({ shortBaseSwVersion }) {
    return {
        type: FETCH_SYS_PARAM_DEFINITIONS,
        payload: {
            shortBaseSwVersion,
        },
    };
}

/**
 * Action creator for creating a system parameter definition
 * @param {object} sysParamDefinition The system parameter definition to be created
 * @param {object} sysParamDefaultValue The system parameter default value to be created
 * @returns {{type: string, payload: object}} The action for creating a system parameter definition
 */
export function createSysParamDefinition(sysParamDefinition, sysParamDefaultValue) {
    return {
        type: CREATE_SYS_PARAM_DEFINITION,
        payload: {
            sysParamDefinition,
            sysParamDefaultValue,
        },
    };
}

/**
 * Action creator for updating a system parameter definition
 * @param {object} sysParamDefinition The system parameter definition to be updated
 * @param {object} sysParamDefaultValue The system parameter default value to be updated
 * @returns {{type: string, payload: object}} The action for updating a system parameter definition
 */
export function updateSysParamDefinition(sysParamDefinition, sysParamDefaultValue) {
    return {
        type: UPDATE_SYS_PARAM_DEFINITION,
        payload: {
            sysParamDefinition,
            sysParamDefaultValue,
        },
    };
}

/**
 * Action creator for deleting a system parameter definition
 * @param {object} sysParamDefinition The system parameter definition to be deleted
 * @returns {{type: string, payload: object}} The action for deleting a system parameter definition
 */
export function deleteSysParamDefinition(sysParamDefinition) {
    return {
        type: DELETE_SYS_PARAM_DEFINITION,
        payload: sysParamDefinition,
    };
}

/**
 * Action creator for copying system parameter definitions
 * @param {string} source The base software version to be copied from
 * @param {string} target The base software version to be copied to
 * @returns {{type: string, payload: object}} The action for copying system parameter definitions
 */
export function copySysParamDefinitions(source, target) {
    return {
        type: COPY_SYS_PARAM_DEFINITIONS,
        payload: {
            source,
            target,
        },
    };
}
