import PropTypes from 'prop-types';
import React from 'react';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

const MenuItemWithTooltip = ({ value,disabled, onSelect, index, tooltipValue, closeDropdown  }) => {

    const classes = disabled && 'disabled pointer-events-auto';
    const onSelectItem = event => {
        if (!disabled) {
            onSelect(index, event);
            closeDropdown();
        }
    };

     const content = <li
         className={classes}
         style={{
             pointerEvents:'auto'}}
         role={'presentation'}
         onClick={onSelectItem}
         data-item-index={index}
     >
         <a role={'menuitem'}>{value}</a>
     </li>

    return (<>
            {tooltipValue ?  <OverlayTrigger placement="left" overlay={
                <Tooltip  id="tooltip" textAlignment={'left'} >
                    {tooltipValue}
                </Tooltip>}>
                    {content}
            </OverlayTrigger> : content}
        </>
    )
};

export default MenuItemWithTooltip;

MenuItemWithTooltip.propTypes = {
    // props
    value: PropTypes.string || PropTypes.node,
    tooltipValue:  PropTypes.string,
    onSelect: PropTypes.func,
    closeDropdown: PropTypes.func,
    disabled: PropTypes.bool
};
