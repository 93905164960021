import { combineReducers } from '@reduxjs/toolkit';

import {
    ENTITY_CONTROL_DEVICE,
    ENTITY_GROUP,
} from '~/features/base/constants/entities';

// higher order
import createSelectionReducer from '~/features/higherorder/reducers/selectionReducer';

export const selectionsReducer = combineReducers({
    [ENTITY_CONTROL_DEVICE]: createSelectionReducer(ENTITY_CONTROL_DEVICE),
    [ENTITY_GROUP]: createSelectionReducer(ENTITY_GROUP),
});
export default selectionsReducer;
