import React, { useEffect, useMemo, useReducer } from 'react';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import { FormattedMessage } from 'react-intl';
import {
    useFetchDeliverableIdsQuery,
    useLazyFetchDeliverableVersionsQuery,
    useLazyValidateDeliverableQuery
} from '~/api/deliverables/DeliverablesManagement.api';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import { SemanticVersion } from '~/api/models';
import { DELIVERABLE_TYPE_CM4G, DELIVERABLE_TYPE_FILE } from '~/features/deliverables/constants/deliverablesParameters';
import { DeliverableSearchCriteriaDeliverableTypeEnum } from '~/api/models/deliverable-search-criteria';
import classNames from 'classnames';
import { prepareDeliverablePayload } from '~/features/deliverables/sagas/deliverableSaga';

const emptyRow = {
    deliverableId: '',
    deliverableType: '',
    deliverableVersion: {
        major: '',
        minor: '',
        patch: '',
    },
    sequence: '',
};

interface DeliverableVersionModel {
    [p: string]: { id: string, label: string, value: SemanticVersion }[] | undefined;
}

const BundleDeliverables = ({deliverables, values = [emptyRow], onChange, hasWarning, model}) => {
    const { data: standaloneCM4GList } = useFetchDeliverableIdsQuery({
        deliverableType: DeliverableSearchCriteriaDeliverableTypeEnum.CM4G,
        isStandalone: false
    });
    const { data: standaloneFilesList } = useFetchDeliverableIdsQuery({
        deliverableType: DeliverableSearchCriteriaDeliverableTypeEnum.FILE,
        isStandalone: false
    });

    const deliverableHasWarning = (value) => {
        return hasWarning ? !!hasWarning?.find((warn) => warn.deliverableId === value.deliverableId && toShortSemanticVersion(warn.deliverableVersion) === toShortSemanticVersion(value.deliverableVersion)) : false;
    };
    const getReleaseState = (value) => {
        return hasWarning?.find((warn) => warn.deliverableId === value.deliverableId && toShortSemanticVersion(warn.deliverableVersion) === toShortSemanticVersion(value.deliverableVersion))?.releaseState;
    };
    const [validateDeliverable] = useLazyValidateDeliverableQuery();

    useEffect(() => {
        if (model.deliverableType) {
            const filteredModel = prepareDeliverablePayload({ ...model });
            validateDeliverable({
                deliverable: filteredModel,
                isEdit: true
            });

        }
    }, [model]);

    const deliverableOptions = useMemo(() => {
        const cmg4WithType = standaloneCM4GList?.content?.map(deliverableId => ({
            id: deliverableId,
            type: DELIVERABLE_TYPE_CM4G.toUpperCase(),
            label: `${deliverableId} (${DELIVERABLE_TYPE_CM4G.toUpperCase()})`
        })) || [];
        const fileWithType = standaloneFilesList?.content?.map(deliverableId => ({
            id: deliverableId,
            type: DELIVERABLE_TYPE_FILE.toUpperCase(),
            label: `${deliverableId} (${DELIVERABLE_TYPE_FILE.toUpperCase()})`
        })) || [];
        return [...cmg4WithType, ...fileWithType];
    }, [standaloneCM4GList, standaloneFilesList]);

    const [getDeliverableVersions] = useLazyFetchDeliverableVersionsQuery();

    const [deliverableVersions, setDeliverableVersions] = useReducer((prev, next) => {
        return {...prev, ...next}
    }, {} as DeliverableVersionModel);


    useEffect(() => {
        if (!values || values?.length === 0) {
            onChange([emptyRow]);
        } else {
            values.forEach(deliverable => getVersions(deliverable.deliverableId, deliverable.deliverableType));
        }
    }, []);

    useEffect(() => {
        //   console.log(result);
    }, [values, model]);

    const onFieldsChange = (newValue, index) => {
        if (index != undefined) {
            let deliverables = [...values];
            deliverables[index] = {...values[index], ...newValue || ''};
            onChange(deliverables);
        }
    };

    const onAddDeliverableFilterFormGroup = () => {
        onChange(values.concat([{...emptyRow}]));
    }

    const getVersions = (deliverableId = '', deliverableType = '') => {
        getDeliverableVersions({deliverableId, deliverableType}).then(result => {
            const versionsList = result?.data?.content.map(version => {
                return {
                    label: toShortSemanticVersion(version),
                    value: version,
                    id: toShortSemanticVersion(version)
                }
            })
            setDeliverableVersions({...deliverableVersions, [deliverableId]: versionsList});
        })
    }

    const onDeliverableVersionChange = (value, index) => {
        let deliverable = {};
        if (index != undefined) {
            getVersions(value?.id, value?.type);
            deliverable = {
                ['deliverableType']: value?.type,
                ['deliverableId']: value?.id,
                };
        }
        onFieldsChange({...deliverable, ['deliverableVersion']: {
                major:'',
                minor:'',
                patch:'',
            }}, index);

    }

    const onDeleteDeliverableFilterFormGroup = (index) => {
        let list = [...values];
        list.splice(index, 1);
        onChange(list);
    }

    return <>
        { values?.map((value, index) => (
            <div className='form-group form-group-semantic-version-range'>
                <div
                    className={classNames('form-group display-flex gap-20 margin-bottom-20', {'has-has-feedback has-warning': deliverableHasWarning(value)})}>
                    <div className='flex-basis-60pct'>
                        <label className='control-label'><FormattedMessage id='intl-msg:deliverableId'/></label>
                        <Select
                            className={'warning'}
                            useFilter
                            useClear
                            options={deliverableOptions}
                            value={[value?.deliverableId]}
                            onChange={(value) => {
                                onDeliverableVersionChange(value, index);
                            }}/>
                        {deliverableHasWarning(value) &&
                            <div className={'margin-top-5'}>
                                <span
                                    className='rioglyph rioglyph-info-sign text-color-warning text-size-15 margin-top-20 margin-right-5'/>
                                <span className={'text-color-warning text-size-12 margin-top-10'}><FormattedMessage
                                    id={'intl-msg:deliverables.bundleDeliverableReleaseStateWarningForm'}
                                    values={{releaseState: getReleaseState(value)}}/>
                                 </span>
                            </div>}
                    </div>
                    <div className='flex-basis-25pct'>
                        <label className='control-label'><FormattedMessage
                            id='intl-msg:reportsBillingDeliverableVersion'/></label>
                        <Select
                            useFilter
                            useClear
                            disabled={!value.deliverableId}
                            options={deliverableVersions[value.deliverableId] || []}
                            value={[toShortSemanticVersion(value.deliverableVersion)]}
                            onChange={(value) => onFieldsChange({['deliverableVersion']: value?.value}, index)}/>
                    </div>
                    <div className='margin-top-15 justify-content-end align-items-start'>
                        <button type="button" className="margin-top-10 margin-bottom-10 btn btn-danger"
                                 onClick={() => {onDeleteDeliverableFilterFormGroup(index)}}
                                 disabled={values.length === 1}>
                            <span className="text-capitalize"><FormattedMessage id='intl-msg:delete'/></span>
                        </button>
                        <div>
                            {index === values.length-1 && <button type="button" className="margin-top-20 padding-left-20 padding-right-20 btn btn-success"
                                     onClick={onAddDeliverableFilterFormGroup}>
                                <span className="text-capitalize"><FormattedMessage id='intl-msg:changeOperation.add'/></span>
                            </button>}
                        </div>
                    </div>
            </div>
        </div>))}
    </>;
}

export default BundleDeliverables;
