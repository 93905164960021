import forEach from 'lodash/fp/forEach';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import AutoSuggest from '@rio-cloud/rio-uikit/lib/es/AutoSuggest';
import TagList from '@rio-cloud/rio-uikit/lib/es/TagList';
import Tag from '@rio-cloud/rio-uikit/lib/es/Tag';
import uid from '~/features/base/utils/uid';

export class HardwareVariantsFormItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hardwareVariantsInputText: '',
        };
    }

    onHardwareVariantsListChange = (hardwareVariantNames) => {
        this.props.onChange({
            hwVariantList: hardwareVariantNames,
        });
    };

    onHardwareVariantListInputTextChange = (value) => {
        this.setState({
            hardwareVariantsInputText: value,
        });
    };

    render() {
        const { hardwareVariants, whiteListedHardwareVariants } = this.props;
        const { hardwareVariantsInputText } = this.state;
        const hardwareVariantsInput = this.renderHardwareVariantsInput('hardwareVariants', this.props.hardwareVariantsLabel,
            whiteListedHardwareVariants, hardwareVariants, this.onHardwareVariantsListChange, hardwareVariantsInputText,
            this.onHardwareVariantListInputTextChange);
        return hardwareVariantsInput;

    }

    renderHardwareVariantsInput(name, label, hardwareVariantNames, hardwareVariants, onChange, inputText, onChangeInputText) {
        const optionsWithSelection = [];
        if (!hardwareVariantNames) {
            hardwareVariantNames = [];
        }
        forEach(hardwareVariantName => {
            if (!hardwareVariantNames.includes(hardwareVariantName) && hardwareVariantName.toUpperCase().startsWith(inputText.toUpperCase())) {
                optionsWithSelection.push({
                    label: hardwareVariantName,
                });
            }
        }, hardwareVariants);

        const handleAddSelectedItem = (_, { suggestion }) => {
            hardwareVariantNames.push(suggestion.label);
            onChange(hardwareVariantNames);
            onChangeInputText('');
        };

        const handleRemoveSelectedItem = tagName => {
            onChange(hardwareVariantNames.filter(e => e !== tagName));
        };

        const handleSuggestionsChange = ({ value }) => {
            onChangeInputText(value);
        };

        const inputProps = {
            placeholder: 'Start typing ...',
            value: inputText,
        };

        return (
            <div className='form-group'>
                <label className='control-label'>
                    <FormattedMessage id={label}/>
                </label>
                <div className='form-group'>
                    <AutoSuggest
                        inputProps={inputProps}
                        suggestions={optionsWithSelection}
                        noItemMessage={<FormattedMessage id='intl-msg:multiSelect.prompt'/>}
                        onSuggestionSelected={handleAddSelectedItem}
                        onSuggestionsFetchRequested={handleSuggestionsChange}
                        openOnFocus
                    />
                    <TagList className={'margin-top-15'}>
                        {hardwareVariantNames.map(item => (
                            <Tag key={item} deletable onClick={() => handleRemoveSelectedItem(item)}>
                                {item}
                            </Tag>
                        ))}
                    </TagList>
                </div>
            </div>
        );
    }
}

export default HardwareVariantsFormItem;

HardwareVariantsFormItem.defaultProps = {
    // props
    hardwareVariants: [],
    whiteListedHardwareVariants: [],
    hardwareVariantsLabel: 'intl-msg:hwVariants',
    // functions
    onChange: noop,
};

HardwareVariantsFormItem.propTypes = {
    // props
    hardwareVariants: PropTypes.array,
    whiteListedHardwareVariants: PropTypes.array,
    hardwareVariantsLabel: PropTypes.string,
    // functions
    onChange: PropTypes.func,
};
