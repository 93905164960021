import React from 'react';

interface TableReloadAndItemsProps {
    itemsCount: number;
    onReload: any;
}

const TableReloadAndItems = ({ itemsCount, onReload }: TableReloadAndItemsProps) => {

    return (
        <div>
            <a id='create-control-device-button'
               className='btn btn-action btn-sm'
               onClick={onReload}>
                <span className='rioglyph rioglyph-refresh' aria-hidden='true'></span>
            </a>
            <span>{`${itemsCount} items`}</span>
        </div>


    );
};

export default TableReloadAndItems;
