import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import {
    DETAILS_PATH,
    EVENTS_PATH, HISTORY_PATH, PARAMETERS_PATH, WHITELIST_PATH,
} from '~/features/base/constants/routes';
import {
    DELIVERABLE_TYPE_DISTRO,
    DELIVERABLE_TYPE_APP,
    DELIVERABLE_TYPE_FILE,
    DELIVERABLE_TYPE_CM4G,
    DELIVERABLE_TYPE_BUNDLE,
} from '~/features/deliverables/constants/deliverablesParameters';
import noop from 'lodash/fp/noop';

export class DeliverableDetailsTabs extends PureComponent {

    render() {
        const navigation = this.renderNavigation();
        return (
            <div className='package-details-tabs row'>
                <div className='col-md-12'>
                    {navigation}
                </div>
            </div>
        );
    }

    renderNavigationItem(basePath, view, label, currentView) {
        const { onSelectTab } = this.props;
        const className = {
            active: currentView === view,
        };
        return (
            <li key={view} className={classNames(className)}>
                <div onClick={() => onSelectTab(view)}><FormattedMessage id={label}/></div>
            </li>
        );
    }

    renderNavigation() {
        const { basePath, selectedDeliverableDetails, view } = this.props;
        const deliverableType = selectedDeliverableDetails?.deliverableType?.toLowerCase() || '';
        switch (deliverableType) {
            case DELIVERABLE_TYPE_DISTRO:
                return (
                    <ul className='nav nav-tabs user-select-none'>
                        {this.renderNavigationItem(basePath, DETAILS_PATH, 'intl-msg:details', view)}
                        {this.renderNavigationItem(basePath, WHITELIST_PATH, 'intl-msg:whitelist', view)}
                        {this.renderNavigationItem(basePath, EVENTS_PATH, 'intl-msg:updateEvents', view)}
                        {this.renderNavigationItem(basePath, PARAMETERS_PATH, 'intl-msg:sysParams', view)}
                        {this.renderNavigationItem(basePath, HISTORY_PATH, 'intl-msg:history', view)}
                    </ul>
                );
            case DELIVERABLE_TYPE_APP:
                return (
                    <ul className='nav nav-tabs user-select-none'>
                        {this.renderNavigationItem(basePath, DETAILS_PATH, 'intl-msg:details', view)}
                        {this.renderNavigationItem(basePath, WHITELIST_PATH, 'intl-msg:whitelist', view)}
                        {this.renderNavigationItem(basePath, EVENTS_PATH, 'intl-msg:updateEvents', view)}
                        {this.renderNavigationItem(basePath, HISTORY_PATH, 'intl-msg:history', view)}
                    </ul>
                );
            case DELIVERABLE_TYPE_FILE:
                return (
                    <ul className='nav nav-tabs user-select-none'>
                        {this.renderNavigationItem(basePath, DETAILS_PATH, 'intl-msg:details', view)}
                        {this.renderNavigationItem(basePath, WHITELIST_PATH, 'intl-msg:whitelist', view)}
                        {this.renderNavigationItem(basePath, EVENTS_PATH, 'intl-msg:updateEvents', view)}
                        {this.renderNavigationItem(basePath, HISTORY_PATH, 'intl-msg:history', view)}
                    </ul>
                );
            case DELIVERABLE_TYPE_CM4G:
                return (
                    <ul className="nav nav-tabs user-select-none">
                        {this.renderNavigationItem(basePath, DETAILS_PATH, 'intl-msg:details', view)}
                        {this.renderNavigationItem(basePath, WHITELIST_PATH, 'intl-msg:whitelist', view)}
                        {this.renderNavigationItem(basePath, EVENTS_PATH, 'intl-msg:updateEvents', view)}
                        {this.renderNavigationItem(basePath, HISTORY_PATH, 'intl-msg:history', view)}
                    </ul>
                );
            case DELIVERABLE_TYPE_BUNDLE:
                return (
                    <ul className="nav nav-tabs user-select-none">
                        {this.renderNavigationItem(basePath, DETAILS_PATH, 'intl-msg:details', view)}
                        {this.renderNavigationItem(basePath, WHITELIST_PATH, 'intl-msg:whitelist', view)}
                        {this.renderNavigationItem(basePath, EVENTS_PATH, 'intl-msg:updateEvents', view)}
                        {this.renderNavigationItem(basePath, HISTORY_PATH, 'intl-msg:history', view)}
                    </ul>
                );
            default:
                return (
                    <ul className="nav nav-tabs user-select-none">
                        {this.renderNavigationItem(basePath, DETAILS_PATH, 'intl-msg:details', view)}
                    </ul>
                );
        }
    }
}

export default DeliverableDetailsTabs;

DeliverableDetailsTabs.defaultProps = {
    // props
    basePath: '',
    selectedDeliverableDetails: {},
    view: DETAILS_PATH,
    // functions
    onSelectTab: noop,
};

DeliverableDetailsTabs.propTypes = {
    // props
    basePath: PropTypes.string,
    selectedDeliverableDetails: PropTypes.object,
    view: PropTypes.string,
    // functions
    onSelectTab: PropTypes.func,
};
