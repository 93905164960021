import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { deleteDeliverable } from '~/features/deliverables/actions/deliverableActions';
import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';

/**
 * Deliverable deletion dialog
 */
export class DeliverableDeletionDialog extends PureComponent {

    onDeleteDeliverable = (deliverableId, deliverableType, deliverableVersions) => {
        this.props.deleteDeliverable(deliverableId, deliverableType, deliverableVersions);
    };

    render() {
        const { deliverableId, deliverableType, deliverableVersions } = this.props;
        return (
            <ConfirmationDialog className='file-deliverable-deletion-dialog'
                                show={true}
                                title={<FormattedMessage id='intl-msg:deleteDeliverable'/>}
                                content={<FormattedMessage id='intl-msg:deleteDeliverable.prompt' values={{
                                    deliverableId,
                                }}/>}
                                cancelButtonText={<FormattedMessage id='intl-msg:cancel'/>}
                                confirmButtonText={<FormattedMessage id='intl-msg:ok'/>}
                                onClickCancel={this.props.hideModal}
                                onClickConfirm={() => this.onDeleteDeliverable(deliverableId, deliverableType,
                                    deliverableVersions)}/>
        );
    }
}

export const mapStateToProps = (state) => {
    return {};
};

export const mapDispatchToProps = (dispatch) => {
    return {
        deleteDeliverable: (deliverableId, deliverableType, deliverableVersions) => {
            dispatch(deleteDeliverable(deliverableId, deliverableType, deliverableVersions));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableDeletionDialog);

DeliverableDeletionDialog.defaultProps = {
    // functions
    deleteDeliverable: noop,
};

DeliverableDeletionDialog.propTypes = {
    // functions
    deleteDeliverable: PropTypes.func,
};
