import PropTypes from 'prop-types';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

export const SystemStateNotificationTopCpu = ({ field, topProcesses }) => {
    if (!(field || topProcesses)) {
        return null;
    }
    return (
        <OverlayTrigger placement='top' overlay={
            <Tooltip id='tooltip' width='400' className='top-right'>
                <div>
                    <div className='row margin-bottom-5'>
                        <div className='col-xs-5'>
                            <u><FormattedMessage id='intl-msg:name'/></u>
                        </div>
                        <div className='col-xs-3'>
                            <u><FormattedMessage id='intl-msg:controlDevice.systemstate.cpu_usage'/></u>
                        </div>
                        <div className='col-xs-4'>
                            <u><FormattedMessage id='intl-msg:controlDevice.systemstate.average_cpu'/></u>
                        </div>
                    </div>
                    { topProcesses &&
                        topProcesses.map(process => {
                            return (
                                <div className='row'>
                                    <div className='col-xs-5'>
                                        {process.name}
                                    </div>
                                    <div className='col-xs-3'>
                                        {process.cpu_usage}
                                    </div>
                                    <div className='col-xs-4'>
                                        {process.average_cpu}
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </Tooltip>}>
            <span>
                <u>{field}</u>
            </span>
        </OverlayTrigger>
    );
};

export default SystemStateNotificationTopCpu;

SystemStateNotificationTopCpu.propTypes = {
    // props
    field: PropTypes.string,
    value: PropTypes.string,
};
