import noop from 'lodash/fp/noop';
import size from 'lodash/fp/size';
import isString from 'lodash/fp/isString';
import isEmpty from 'lodash/fp/isEmpty';

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { ALL } from '~/features/base/constants/filterOptions';
import AutoSuggest from '@rio-cloud/rio-uikit/lib/es/AutoSuggest';

export class SuggestFormItem extends Component {
    constructor(props) {
        super(props);
        const { intl, value } = this.props;
        const incomingValue = value ? value : intl.formatMessage({ id: 'intl-msg:all' });
        this.state = {
            value: incomingValue,
            options: [],
            suggestions: [],
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.value !== prevState.value) {
            prevState.value = nextProps.value;
        }
        if (size(nextProps.options) > size(prevState.options)) {
            return {
                ...prevState,
                options: nextProps.options,
                suggestions: nextProps.options,
            };
        }
    }

    checkForDataKeyAttribute = (item) => {
        try {
            return item.target.children[0].children[0].getAttribute('data-key');
        } catch (e) {
        }
    };

    onChange = (event) => {
        let suggestionId = '';
        // check if item has the data-key attribute and retrieve it as id directly
        const dataKey = this.checkForDataKeyAttribute(event);
        if (dataKey) {
            suggestionId = dataKey;
        } else {
            // extract id from options
            const { options } = this.props;
            const inputValue = event.target.textContent;
            const selectedOption = options.find(option => option.label === inputValue);
            if (selectedOption) {
                // case found mapped literal option id
                suggestionId = selectedOption.id;
            } else if (inputValue === '') {
                // case direct input
                suggestionId = event.target.value;
                suggestionId = isEmpty(suggestionId) ? '' : suggestionId;
                suggestionId = suggestionId.toLocaleLowerCase() === ALL.toLocaleLowerCase() ? undefined : suggestionId;
                this.props.onChange({
                    suggestionId,
                });
                return;
            }
        }
        if (size(suggestionId) > 0) {
            suggestionId = suggestionId.toLocaleLowerCase() === ALL ? undefined : suggestionId;
            this.props.onChange({
                suggestionId,
            });
        }
    };

    onSuggestionSelected = (event) => {
    };

    // Autosuggest will call this function every time you need to update suggestions.
    onSuggestionsFetchRequested = (arg) => {
        const { value } = arg;
        let selectedValue;
        if (isString(value)) {
            selectedValue = value;
        } else {
            const { intl } = this.props;
            const labelKey = value.props.children.props.id;
            selectedValue = intl.formatMessage({ id: labelKey });
        }
        const { options } = this.state;
        this.setState({
            value: selectedValue,
            isVersionFilterSet: true,
            suggestions: options.filter(suggestion => suggestion.value.includes(value)),
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            value: '',
            isVersionFilterSet: false,
            suggestions: [],
        });
    };

    onBlur = () => {
        if (this.state.value === '') {
            const { intl } = this.props;
            this.setState({
                value: intl.formatMessage({ id: 'intl-msg:all' }),
                isVersionFilterSet: false,
                suggestions: this.props.options,
            });
        }
    };

    onClear = (event) => {
        this.setState({
            value: '',
            isVersionFilterSet: false,
            suggestions: this.props.options,
        });
        this.props.onChange({
            distroVersion: undefined,
        });
    };

    renderSuggestion = (suggestion) => {
        return (
            <span>{suggestion.label}</span>
        );
    };

    render() {
        const { label } = this.props;
        const { value, suggestions } = this.state;
        return (
            <div className='form-group'>
                <label className='control-label'>
                    <FormattedMessage id={label}/>
                </label>
                <FormattedMessage id={label}>
                    {placeholder =>
                        <AutoSuggest
                            inputProps={{
                                value,
                                placeholder,
                                onChange: this.onChange,
                                onClear: this.onClear,
                                onBlur: this.onBlur,
                            }}
                            suggestions={suggestions}
                            renderSuggestion={this.renderSuggestion}
                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                            onSuggestionSelected={this.onSuggestionSelected}
                            openOnFocus={false}
                        />
                    }
                </FormattedMessage>
            </div>
        );
    }
}

export default injectIntl(SuggestFormItem);

SuggestFormItem.defaultProps = {
    // props
    label: 'default',
    options: [],
    // functions
    onChange: noop,
};

SuggestFormItem.propTypes = {
    // props
    label: PropTypes.string,
    options: PropTypes.array,
    // functions
    onChange: PropTypes.func,
};
