import React, { PureComponent } from 'react';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import { FormattedMessage } from 'react-intl';

export class VehicleLabel extends PureComponent {
    render() {
        const { classes, color, label, value, name, midAngle } = this.props;
        let { x, y } = this.props;
        const vehicleHeight = 38;
        const vehicleWidth = 48;

        if (midAngle >= 0 && midAngle < 45) {
            y -= vehicleHeight / 2;
        }
        if (midAngle >= 45 && midAngle < 135) {
            y -= vehicleHeight;
            if (midAngle >= 90) {
                x -= vehicleWidth / 2;
            }
        }
        if (midAngle >= 135 && midAngle < 225) {
            x -= vehicleWidth;
            y -= vehicleHeight / 2;
        }
        if (midAngle >= 225 && midAngle < 315) {
            x -= vehicleWidth / 2;
        }
        if (midAngle >= 315 && midAngle <= 360) {
            y -= vehicleHeight / 2;
        }
        const vehicleClasses = `${classes} text-size-h1`;
        return <foreignObject x={x} y={y} height={38} width={50}>
            <OverlayTrigger placement='top' overlay={
                <Tooltip id='flag-label-tooltip' className='top-right'>
                    <span>{`${label}: ${value.toLocaleString()}`}</span>
                </Tooltip>}>
                <div className='width-100pct height-100pct text-center'><span className={vehicleClasses}
                                                                              style={{ color: color }}></span></div>
            </OverlayTrigger>
        </foreignObject>;
    }
}
