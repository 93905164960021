import filter from 'lodash/fp/filter';
import values from 'lodash/fp/values';

import React from 'react';
import { FormattedMessage } from 'react-intl';

export const SYSTEM = 'SYSTEM';
export const AUTOMATIC = 'AUTOMATIC';
export const USER = 'USER';

export const groupTypes = {
    [SYSTEM]: {
        id: SYSTEM,
        label: <FormattedMessage id='intl-msg:groupType.system'/>,
        tooltip: <FormattedMessage id='intl-msg:groupType.system.tooltip'/>,
        className: 'label-success',
    },
    [AUTOMATIC]: {
        id: AUTOMATIC,
        label: <FormattedMessage id='intl-msg:groupType.automatic'/>,
        tooltip: <FormattedMessage id='intl-msg:groupType.automatic.tooltip'/>,
        className: 'label-warning',
    },
    [USER]: {
        id: USER,
        label: <FormattedMessage id='intl-msg:groupType.user'/>,
        tooltip: <FormattedMessage id='intl-msg:groupType.user.tooltip'/>,
        className: 'label-primary',
    },
};

export const createGroupTypeOptions = () => {
    // SYSTEM groups cannot be created, so filtering them out
    return filter(groupType => groupType.id !== SYSTEM, values(groupTypes));
};
