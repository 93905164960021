import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import {
    FETCH_STATE_NOTIFICATIONS_OF_DEVICE,
    REQUEST_NEW_STATE_NOTIFICATIONS,
    SET_SYSTEM_STATE_COLUMN_SETTINGS,
} from '~/features/systemState/actions/systemStateActions';
import { doHandleErrorSaga, getHTTP, postHTTP } from '~/features/base/sagas/sagaUtil';
import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';
import { getDeviceSystemStateBackend } from '~/features/base/selectors/backendSelectors';
import { mergeEntities, resetEntity } from '~/features/higherorder/actions/entityActions';
import { ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS } from '~/features/base/constants/entities';
import { endLoading, startLoading } from '~/features/higherorder/actions/loadingActions';
import { failedAction, finishedAction } from '~/features/higherorder/transforms/actionTransforms';
import getOr from 'lodash/fp/getOr';
import { clearPage, mergePage } from '~/features/higherorder/actions/paginationActions';
import { parseSystemStateNotifications } from '~/features/systemState/transforms/parseSystemStateNotifications';
import { getTimestampSixMonthsAgo } from '~/features/base/constants/filterOptions';
import moment from 'moment';
import {
    SYSTEM_STATE_COLUMN_SETTINGS_LOCAL_STORAGE_KEY,
} from '~/features/systemState/constants/systemStateNotificationColumnDescriptors';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { FormattedMessage } from 'react-intl';

export function* getDeviceSystemStateURL() {
    const serviceURL = yield select(getDeviceSystemStateBackend);
    return `${serviceURL}`;
}

export function* doFetchStateNotificationsOfDevice(action) {
    yield put(startLoading(ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS, action.scope));
    try {
        const url = yield call(getDeviceSystemStateURL);
        const searchCriteria = getOr({}, 'payload.searchCriteria', action);
        let params = `starttimespan=${getTimestampSixMonthsAgo()}&endtimespan=${moment.now()}`;
        if (searchCriteria.from && searchCriteria.to) {
            params = `starttimespan=${searchCriteria.from}&endtimespan=${searchCriteria.to}`;
        }
        if (searchCriteria.types) {
            params = `${params}&filter=${searchCriteria.types}`;
        }
        if (searchCriteria.partitions) {
            params = `${params}&partitions=${searchCriteria.partitions}`;
        }
        params = `${params}&page=${action.payload.page}&size=${action.payload.size}`;

        yield put(clearPage({ entityName: ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS, scope: action.scope },
            {}));
        yield put(resetEntity({}, {
            entityName: ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS,
            serialNumber: searchCriteria.serialNumber,
        }));

        const { content, ...pagination } = yield call(getHTTP,
            `${url}/devices/${searchCriteria.serialNumber}/events?${params}`);

        const parsedResponse = { content: parseSystemStateNotifications(content), ...pagination };
        yield put(
            mergeEntities(parsedResponse.content, { entityName: ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS }));
        yield put(mergePage({ entityName: ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS, scope: action.scope },
            parsedResponse));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS, action.scope));
    yield put(finishedAction(action.type));
}

export function* doRequestNewStateNotification(action) {
    try {
        const messageSenderUrl = yield call(getDeviceSystemStateURL);
        const {serialNumber, partition} = action.payload;
        const params = `&partition=${partition}`;
        yield call(postHTTP, `${messageSenderUrl}/devices/${serialNumber}/systemstates?${params}`);
        Notification.success(<FormattedMessage id='intl-msg:controlDevice.systemstate.notificationRequested'/>);
    } catch (error) {
        yield fork(doHandleErrorSaga, error);
        yield put(showErrorMessage(error));
    }
}

export function* doSetSystemStateColumnSettings(action) {
    try {
        localStorage.setItem(SYSTEM_STATE_COLUMN_SETTINGS_LOCAL_STORAGE_KEY, JSON.stringify(action.payload));
    } catch (error) {
        yield fork(doHandleErrorSaga, error);
        yield put(showErrorMessage(error));
    }
}

export function* fetchStateNotificationsOfDeviceSaga() {
    yield takeLatest(FETCH_STATE_NOTIFICATIONS_OF_DEVICE, doFetchStateNotificationsOfDevice);
}

export function* requestNewStateNotificationSaga() {
    yield takeLatest(REQUEST_NEW_STATE_NOTIFICATIONS, doRequestNewStateNotification);
}

export function* setSystemStateColumnSettingsSaga() {
    yield takeLatest(SET_SYSTEM_STATE_COLUMN_SETTINGS, doSetSystemStateColumnSettings);
}
