import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import semver from 'semver';
import { connect } from 'react-redux';
import { triggerCheckForUpdateOnControlDevice } from '~/features/devices/actions/controlDeviceActions';
import { showControlDeviceEditor } from '~/features/devices/actions/controlDeviceEditorActions';
import { showJiraIssueEditor } from '~/features/atlassian/actions/atlassianJiraActions';
import { showControlDeviceQuickWhitelisting } from '~/features/devices/actions/controlDeviceQuickWhitelistingActions';
import { canQuickWhitelistControlDevicesSelector } from '~/features/devices/selectors/controlDeviceSelectors';
import {
    DEVICE_QUICK_WHITELISTING_MODE_INSTALL,
    DEVICE_QUICK_WHITELISTING_MODE_UNINSTALL,
} from '~/features/devices/components/dialogs/quickWhitelisting/DeviceQuickWhitelistingDialog';
import { showControlDeviceAssociatedDeliverables } from '~/features/devices/actions/controlDeviceAssociatedDeliverablesActions';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';

/**
 * Control Vehicle details toolbar component
 */
const MIN_MDMC_VERSION = '8.6.0';

export class VehicleDetailsToolbar extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showCheckForUpdateTriggered: false,
        };
    }

    onEditDevice = () => {
        const { serialNumber } = this.props;
        this.props.showControlDeviceEditor({
            isNew: false,
            serialNumber,
        });
    };

    assembleDataDogURL = (controlDeviceInfo) => {
        let query = controlDeviceInfo.serialNumber;
        query += ` OR ${controlDeviceInfo.serialNumber.replace('-', '')}`;
        if (controlDeviceInfo.assetId) {
            query += ` OR ${controlDeviceInfo.assetId}`;
        }
        if (controlDeviceInfo.vin) {
            query += ` OR ${controlDeviceInfo.vin}`;
        }
        return `https://app.datadoghq.eu/logs?cols=core_host%2Ccore_service&index=&live=true&messageDisplay=inline&query=${query}&stream_sort=desc`;
    };

    render() {
        const { canQuickWhitelistControlDevices, controlDeviceInfo, packageMdmcAppVersion, serialNumber } = this.props;
        const uninstallAllowed = !!packageMdmcAppVersion && semver.gte(packageMdmcAppVersion, MIN_MDMC_VERSION)
        const dropDownItems = [];
        dropDownItems.push(
            {
                value: <div>
                    <span className="rioglyph rioglyph-pencil padding-right-20 text-size-20" aria-hidden="true"></span>
                    <FormattedMessage id="intl-msg:editControlDevice"/>...
                </div>,
                onSelect: value => this.onEditDevice(),
            },
        );
        dropDownItems.push(
            {
                value: <div>
                    <span className="rioglyph rioglyph-group padding-right-20 text-size-20" aria-hidden="true"></span>
                    <FormattedMessage id="intl-msg:showAssociatedDeliverables"/>...
                </div>,
                onSelect: value => this.props.showControlDeviceAssociatedDeliverables(serialNumber),
            },
        );
        canQuickWhitelistControlDevices && dropDownItems.push(
            {
                value: <div>
                    <span className="rioglyph rioglyph-area-in padding-right-20 text-size-20" aria-hidden="true"></span>
                    <FormattedMessage id="intl-msg:whitelistControlDevice"/>
                </div>,
                onSelect: value => this.props.showControlDeviceQuickWhitelisting(serialNumber,
                    DEVICE_QUICK_WHITELISTING_MODE_INSTALL),
            },
        );
        uninstallAllowed && canQuickWhitelistControlDevices && dropDownItems.push(
            {
                value: <div>
                    <span className="rioglyph rioglyph-area-out padding-right-20 text-size-20"
                          aria-hidden="true"></span>
                    <FormattedMessage id="intl-msg:blacklistControlDevice"/>
                </div>,
                onSelect: value => this.props.showControlDeviceQuickWhitelisting(serialNumber,
                    DEVICE_QUICK_WHITELISTING_MODE_UNINSTALL),
            },
        );
        dropDownItems.push(
            {
                value: <div>
                    <span className="rioglyph rioglyph-support padding-right-20 text-size-20" aria-hidden="true"></span>
                    <FormattedMessage id="intl-msg:createJiraEntry"/>...
                </div>,
                onSelect: value => this.props.showJiraIssueEditor(controlDeviceInfo),
            },
        );
        dropDownItems.push(
            {
                value: <div>
                    <span className="rioglyph rioglyph-am padding-right-20 text-size-20" aria-hidden="true"></span>
                    <FormattedMessage id="intl-msg:openDataDog"/>
                </div>,
                onSelect: value => window.open(this.assembleDataDogURL(controlDeviceInfo), '_blank'),
            },
        );

        const editButton = this.renderEditButton();
        return (
            <div className="device-details-toolbar margin-bottom-25 padding-top-10">
                <div className="pull-right">
                    <ButtonDropdown
                        title={<FormattedMessage id="intl-msg:moreActions"/>}
                        items={dropDownItems}
                        dropdownClassName={'width-250'}
                    />
                </div>
            </div>
        );
    }

    renderEditButton() {
        if (this.props.canUpdateControlDevices) {
            return (
                <button id="edit-control-device-button" type="button"
                        className="btn btn-primary" onClick={this.onEditDevice}>
                    <span className="rioglyph rioglyph-pencil" aria-hidden="true"></span>
                    <FormattedMessage id="intl-msg:editControlDevice"/>
                </button>
            );
        } else {
            return (
                <OverlayTrigger placement="top" overlay={
                    <Tooltip id="tooltip">
                        <FormattedMessage id="intl-msg:missingPermissions"/>
                    </Tooltip>}>
                    <span className="cursor-not-allowed">
                        <button type="button" className="btn btn-primary disabled">
                            <span className="rioglyph rioglyph-pencil" aria-hidden="true"></span>
                            <FormattedMessage id="intl-msg:editControlDevice"/>
                        </button>
                    </span>
                </OverlayTrigger>
            );
        }
    }

    onTriggerCheckForUpdate() {
        this.props.triggerCheckForUpdateOnControlDevice(this.props.serialNumber);
        this.setState({ showCheckForUpdateTriggered: !this.state.showCheckForUpdateTriggered });
        setTimeout(() => this.setState({ showCheckForUpdateTriggered: false }), 3000);
    }

    isMdmcEligibleForCheckForUpdateTrigger(packageMdmcAppVersion) {
        if (packageMdmcAppVersion === '') {
            return false;
        }
        const version = Number(packageMdmcAppVersion.replace(/\./g, ''));
        return version >= 605 ? true : false;
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        canQuickWhitelistControlDevices: canQuickWhitelistControlDevicesSelector(state, ownProps),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        triggerCheckForUpdateOnControlDevice: (serialNumber) => {
            dispatch(triggerCheckForUpdateOnControlDevice({ serialNumber }));
        },
        showControlDeviceEditor: payload => {
            dispatch(showControlDeviceEditor(payload));
        },
        showJiraIssueEditor: (controlDevice) => {
            dispatch(showJiraIssueEditor({ controlDevice }));
        },
        showControlDeviceQuickWhitelisting: (serialNumber, mode) => {
            dispatch(showControlDeviceQuickWhitelisting(serialNumber, mode));
        },
        showControlDeviceAssociatedDeliverables: (serialNumber) => {
            dispatch(showControlDeviceAssociatedDeliverables(serialNumber));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDetailsToolbar);

VehicleDetailsToolbar.defaultProps = {
    // props
    controlDeviceInfo: null,
    serialNumber: '',
    packageMdmcAppVersion: '',
    showCheckForUpdateTriggered: false,
    canEditServiceLogLevels: false,
    canUpdateControlDevices: false,
    // functions
    showControlDeviceEditor: noop,
    triggerCheckForUpdateOnControlDevice: noop,
    showJiraIssueEditor: noop,
    showControlDeviceQuickWhitelisting: noop,
    showControlDeviceAssociatedDeliverables: noop,
};

VehicleDetailsToolbar.propTypes = {
    // props
    controlDeviceInfo: PropTypes.object,
    serialNumber: PropTypes.string,
    packageMdmcAppVersion: PropTypes.string,
    showCheckForUpdateTriggered: PropTypes.bool,
    canEditServiceLogLevels: PropTypes.bool,
    canUpdateControlDevices: PropTypes.bool,
    // functions
    showControlDeviceEditor: PropTypes.func,
    triggerCheckForUpdateOnControlDevice: PropTypes.func,
    showJiraIssueEditor: PropTypes.func,
    showControlDeviceQuickWhitelisting: PropTypes.func,
    showControlDeviceAssociatedDeliverables: PropTypes.func,
};
