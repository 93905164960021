import moment from 'moment';
import {
    RESET_DEVICE_ACTION_EDITOR,
    CHANGE_DEVICE_ACTION_EDITOR,
} from '~/features/deviceLogs/actions/deviceActionEditorActions';

import {
    CREATE_DEVICE_ACTION,
    UPDATE_DEVICE_ACTION,
} from '~/features/deviceLogs/actions/deviceActionActions';

import { ALL } from '~/features/base/constants/serviceOptions';
import { NOTICE } from '~/features/deviceLogs/constants/logLevelOptions';
import { toFinished } from '~/features/higherorder/transforms/actionTransforms';

import uid from '~/features/base/utils/uid';

const defaultState = {
    hwSerial: undefined,
    hwSerialEditable: false,
    isValidHwSerial: false,
    logRangeChangeOverwrite: false,
    logs: [{
        serviceId: ALL,
        logLevel: NOTICE,
        from: moment().startOf('day').valueOf(),
        to: moment().valueOf(),
        id: uid(),
    }],
    isNew: true,
    isInProgress: false,
};

/**
 * Reducer for device action editor
 * @param {object} state The current state
 * @param {object} action The action to be processed
 * @returns {object} The new state
 */
export default function deviceActionEditorReducer(state = defaultState, action = {}) {
    let newState;
    switch (action.type) {
        case RESET_DEVICE_ACTION_EDITOR:
            newState = {
                ...defaultState,
            };
            return newState;
        case CHANGE_DEVICE_ACTION_EDITOR:
            newState = {
                ...state,
                ...action.payload,
            };
            return newState;
        case CREATE_DEVICE_ACTION:
        case UPDATE_DEVICE_ACTION:
            newState = {
                ...state,
                isInProgress: true,
            };
            return newState;
        case toFinished(CREATE_DEVICE_ACTION):
        case toFinished(UPDATE_DEVICE_ACTION):
            newState = {
                ...state,
                isInProgress: false,
            };
            return newState;
        default:
            return state;
    }
}
