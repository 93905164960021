/* tslint:disable */
/* eslint-disable */

/**
 * Mdm Logging Service
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: man-team-mdm@man.eu
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { RangeInstant } from '~/api/models/range-instant';
import { SystemLog } from '~/api/models/system-log';

/**
 *
 * @export
 * @interface LogsRequest
 */
export interface LogsRequest {
    /**
     *
     * @type {number}
     * @memberof LogsRequest
     */
    id?: number;
    /**
     *
     * @type {number}
     * @memberof LogsRequest
     */
    operationId?: number;
    /**
     *
     * @type {string}
     * @memberof LogsRequest
     */
    serialNumber: string;
    /**
     *
     * @type {string}
     * @memberof LogsRequest
     */
    deviceType: LogsRequestDeviceTypeEnum;
    /**
     *
     * @type {Array<string>}
     * @memberof LogsRequest
     */
    systems: Array<LogsRequestRequestedSystemsEnum>;
    /**
     *
     * @type {RangeInstant}
     * @memberof LogsRequest
     */
    period?: RangeInstant;
    /**
     *
     * @type {string}
     * @memberof LogsRequest
     */
    status?: LogsRequestStatusEnum;
    /**
     *
     * @type {Array<SystemLog>}
     * @memberof LogsRequest
     */
    logs?: Array<SystemLog>;
    /**
     *
     * @type {Date}
     * @memberof LogsRequest
     */
    createdDate?: Date;
    /**
     *
     * @type {string}
     * @memberof LogsRequest
     */
    requestedBy?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum LogsRequestDeviceTypeEnum {
    CM4 = 'CM4',
    TBM3 = 'TBM3',
    VCM = 'VCM'
}

/**
 * @export
 * @enum {string}
 */
export enum LogsRequestRequestedSystemsEnum {
    CHIPPS = 'CHIPPS',
    VUC = 'VUC',
    NAD = 'NAD',
    TELE = 'TELE',
    TPA = 'TPA'
}

/**
 * @export
 * @enum {string}
 */
export enum LogsRequestStatusEnum {
    REQUESTED = 'REQUESTED',
    ERROR = 'ERROR',
    UPLOADED = 'UPLOADED',
    PROVIDED = 'PROVIDED'
}

