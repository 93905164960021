import getOr from 'lodash/fp/getOr';

import { prettySize } from 'pretty-size';

import React from 'react';
import { FormattedTime } from 'react-intl';

import DeviceChip from '~/features/devices/components/DeviceChip';

export const logRequestColumnDescriptors = [{
    id: 'serialNumber',
    field: 'hwSerial',
    label: 'intl-msg:hwSerial',
    format: field => <DeviceChip serialNumber={field} className='clickable'/>,
}, {
    id: 'timestamp',
    field: 'created',
    label: 'intl-msg:timestamp',
    format: field => [
        <FormattedTime value={field} year='numeric' month='2-digit' day='2-digit'/>,
    ],
}, {
    id: 'count',
    field: 'logs',
    label: 'intl-msg:count',
    format: field => getOr(0, ['length'], field),
}];

export const deviceLogColumnDescriptors = [{
    id: 'serviceId',
    field: 'serviceId',
    label: 'intl-msg:serviceId',
    format: field => field || 'N/A',
}, {
    id: 'operationId',
    field: 'operationId',
    label: 'intl-msg:operationId',
    format: field => field || 'N/A',
}, {
    id: 'actionId',
    field: 'actionId',
    label: 'intl-msg:actionId',
    format: field => field || 'N/A',
}, {
    id: 'range',
    field: 'parsedFrom',
    label: 'intl-msg:range',
    format: (field, item) => [
        <FormattedTime value={field} year='numeric' month='2-digit' day='2-digit'/>,
        item.parsedTo ? (
            <span>
                <span> - </span>
                <FormattedTime value={item.parsedTo} year='numeric' month='2-digit' day='2-digit'/>
            </span>
        ) : null,
    ],
}, {
    id: 'fileType',
    field: 'extension',
    label: 'intl-msg:fileType',
}, {
    id: 'size',
    field: 'sizeBytes',
    label: 'intl-msg:size',
    format: field => prettySize(field),
}];
