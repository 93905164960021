import omitBy from 'lodash/fp/omitBy';
import isUndefined from 'lodash/fp/isUndefined';
import isEmpty from 'lodash/fp/isEmpty';

import { toDistroVersion, toSemanticVersion } from '~/features/base/utils/versionNumberConverter';

const getSwVersion = ({ softwareVersion }) => {
    const hasBaseSwVersion = softwareVersion &&
        (softwareVersion !== 'unknown') && (softwareVersion !== 'other');
    return hasBaseSwVersion ? toSemanticVersion(softwareVersion) : undefined;
};
const getDistroVersion = ({ distroVersion }) => {
    const hasDistroVersion = distroVersion &&
        (distroVersion !== 'unknown') && (distroVersion !== 'other');
    return hasDistroVersion ? toDistroVersion(distroVersion) : undefined;
};

const getVehicleVariantsWhitelistInfo = ({ vehicleVariants }) => {
    if (Array.isArray(vehicleVariants)) {
        return vehicleVariants;
    } else if (!isEmpty(vehicleVariants)) {
        return [vehicleVariants];
    } else {
        return undefined;
    }
};

const getHardwareVariantList = ({ hwVariantList }) => {
    if (Array.isArray(hwVariantList)) {
        return hwVariantList;
    } else if (!isEmpty(hwVariantList)) {
        return [hwVariantList];
    } else {
        return undefined;
    }
};

const getDeviceStatusNames = ({ vehicleCheckNames }) => {
    if (Array.isArray(vehicleCheckNames)) {
        return vehicleCheckNames;
    } else if (!isEmpty(vehicleCheckNames)) {
        return [vehicleCheckNames];
    } else {
        return undefined;
    }
};

export const queryToSearchCriteria = (query) => {
    const {
        vinPrefix,
        hwVariantPrefix,
        manufacturerSparePartNumber,
        deviceType,
        vehicleStatus,
    } = query;
    return omitBy(isUndefined, {
        vinPrefix,
        hwVariantPrefix,
        hwVariantList: getHardwareVariantList(query),
        manufacturerSparePartNumber,
        deviceType,
        vehicleStatus,
        vehicleCheckNames: getDeviceStatusNames(query),
        softwareVersion: getSwVersion(query),
        distroVersion: getDistroVersion(query),
        vehicleVariants: getVehicleVariantsWhitelistInfo(query),
    });
};

