import React from 'react';
import { FormattedMessage } from 'react-intl';

const TechnicalInfoDetails = ({ technicalInformation }) => {

    return (
        <table>
            <tr>
                <td className='text-color-dark width-200'>
                    <FormattedMessage id='intl-msg:id'/>
                </td>
                <td>
                    {technicalInformation.tiId}
                </td>
            </tr>
            <tr>
                <td className='text-color-dark width-200'>
                    <FormattedMessage id='intl-msg:status'/>
                </td>
                <td>
                    {technicalInformation.status}
                </td>
            </tr>
            <tr>
                <td className='text-color-dark width-200'>
                    <FormattedMessage id='intl-msg:number'/>
                </td>
                <td>
                    {technicalInformation.number}
                </td>
            </tr>
            <tr>
                <td className='text-color-dark width-200'>
                    <FormattedMessage id='intl-msg:version'/>
                </td>
                <td>
                    {technicalInformation.version}
                </td>
            </tr>
            <tr>
                <td className='text-color-dark width-200'>
                    <FormattedMessage id='intl-msg:variant'/>
                </td>
                <td>
                    {technicalInformation.variant}
                </td>
            </tr>
        </table>

    );
};

export default TechnicalInfoDetails;
