export const SCHEDULED = 'ACTSCHEDULEDIVE';
export const DOWNLOADING = 'DOWNLOADING';
export const DOWNLOADED = 'DOWNLOADED';
export const INSTALLING = 'INSTALLING';
export const FINISHED = 'FINISHED';

export const rolloutStages = {
    [SCHEDULED]: {
        id: SCHEDULED,
        label: 'Scheduled',
    },
    [DOWNLOADING]: {
        id: DOWNLOADING,
        label: 'Downloading',
    },
    [DOWNLOADED]: {
        id: DOWNLOADED,
        label: 'Downloaded',
    },
    [INSTALLING]: {
        id: INSTALLING,
        label: 'Installing',
    },
    [FINISHED]: {
        id: FINISHED,
        label: 'Finished',
    },
};

export default rolloutStages;
