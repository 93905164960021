import { useEffect } from 'react';
import {  store } from '~/setup/store';
import { resetAllIntervals } from '~/features/apps/utils/appsUtils';
import { UNREGISTER_ALL_DATA_INTEREST } from '~/features/base/actions/ui/dataInterestActions';




export const useDataInterestCleaner= () =>{

    useEffect(()=>{
        return () => {
            resetAllIntervals();
            store.dispatch({ type: UNREGISTER_ALL_DATA_INTEREST });
        };
    },[] )


}
