import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import flow from 'lodash/fp/flow';
import toPairs from 'lodash/fp/toPairs';
import map from 'lodash/fp/map';
import compact from 'lodash/fp/compact';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';

/**
 * System parameter definition enumeration default value form
 */
export class SysParamDefinitionEnumerationValueForm extends PureComponent {

    render() {
        const { label, value, model, onValueSelect } = this.props;
        const menuItems = flow(
            toPairs,
            map(([key, enumValue]) => {
                return (
                    {
                        value: [
                            <span>{enumValue.value.toString()}</span>
                        ],
                        onSelect: () => onValueSelect(key),
                    }
                );
            }),
            compact
        )(model.enumValues);

        return (
            <div className='form-group form-group-enum-default-value'>
                <label className='control-label'>
                    <FormattedMessage id={label}/>
                </label>
                <div>
                    <FormattedMessage id='intl-msg:notDefined'>
                        {notDefinedTitle =>
                            <ButtonDropdown
                                id='enumDefaultValueInput'
                                title={
                                    <span>
                                        {value ? value : notDefinedTitle}
                                    </span>
                                }
                                items={menuItems}
                            />
                        }
                    </FormattedMessage>
                </div>
            </div>
        );
    }
}

export default SysParamDefinitionEnumerationValueForm;

SysParamDefinitionEnumerationValueForm.defaultProps = {
    label: '',
    value: undefined,
    model: [],
    onValueSelect: noop,
};

SysParamDefinitionEnumerationValueForm.propTypes = {
    // props
    label: PropTypes.string,
    value: PropTypes.string,
    model: PropTypes.array,
    // functions
    onValueSelect: PropTypes.func,
};
