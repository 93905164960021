import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import { groupTypes } from '~/features/groups/constants/groupTypes';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

export const GroupType = ({ groupType }) => {
    const labelClass = groupTypes[groupType].className;
    return (
        <OverlayTrigger placement='top' overlay={
            <Tooltip id='tooltip' className='top-right'>
                <FormattedMessage id={groupTypes[groupType].tooltip}/>
            </Tooltip>}>
            <span className={classNames('label label-condensed margin-right-5', labelClass)}>
                <FormattedMessage id={groupTypes[groupType].label} />
            </span>
        </OverlayTrigger>
    );
};

export default GroupType;

GroupType.defaultProps = {
};

GroupType.propTypes = {
    // props
    groupType: PropTypes.string,
};
