import React from 'react';
import TabVisibility from 'react-tab-visibility/src/tabVisibility';
import { connect } from 'react-redux';
import { onTabVisibilityChanged } from '~/features/app/actions';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';

export class TabVisibilitySensor extends TabVisibility {
    onTabVisibilityChange(state) {
        this.props.onTabVisibilityChanged(state.tabIsVisible);
        if (state.tabIsVisible) {
            this.props.triggerDataFetcher();
        }
    }

    render() {
        return null;
    }
}

export const mapDispatchToProps = (dispatch) => ({
    onTabVisibilityChanged: (isVisible) => dispatch(onTabVisibilityChanged(isVisible)),
    triggerDataFetcher: () => {
        dispatch(triggerDataFetcher());
    },

});

export const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TabVisibilitySensor);
