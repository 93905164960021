import React, {useState} from 'react';
import NumberControl from "@rio-cloud/rio-uikit/lib/es/NumberControl";
import InputOTA from "~/features/base/components/forms/InputOTA";
import {FormattedMessage} from 'react-intl';

interface MileageFormItemProps {
    onMinMileageChange: (mileage: number) => void;
    onMaxMileageChange: (mileage: number) => void;
    minMileage: number;
    maxMileage: number;
}

const MileageFormItem: React.FC<MileageFormItemProps> = ({minMileage, maxMileage, onMinMileageChange, onMaxMileageChange}) => {

    return (
        <div className={"display-flex flex-row gap-20"}>
            <div className='form-group width-50pct '>
                <InputOTA
                    title={<FormattedMessage id='Min Mileage (km)'/>}
                    type='number'
                    minValue={0}
                    className='form-control'
                    value={minMileage}
                    placeholder={'Enter min mileage'}
                    onChange={(e)=> {
                        onMinMileageChange(e.target.value)
                    }}
                />
            </div>
            <div className='form-group width-50pct '>
            <InputOTA
                title={<FormattedMessage id='Max Mileage (km)'/>}
                type='number'
                className='form-control'
                value={maxMileage}
                placeholder={'Enter max mileage'}
                onChange={(e)=> {
                    onMaxMileageChange(e.target.value)
                }}
            />
            </div>
        </div>
    );
};

export default MileageFormItem;
