import React from "react";
import Dialog from "@rio-cloud/rio-uikit/lib/es/Dialog";
import {FormattedMessage} from 'react-intl';
import Notification from "@rio-cloud/rio-uikit/Notification";
import {useCancelAdaptOrderMutation} from "~/api/adaptOrder/AdaptOrder.api";
import {ManualAdaptOrder} from "~/api/models/manualAdaptOrder";

interface AdaptOrderFromDialogProps {
    onHide: () => void;
    show: boolean;
    vin: string;
    adaptOrder: ManualAdaptOrder | undefined;
}

const AdaptOrderFromDialog: React.FC<AdaptOrderFromDialogProps> = ({ onHide, show, vin, adaptOrder}) => {

    const [cancelAdaptOrder] = useCancelAdaptOrderMutation();


    const onCancel = async(requestParams: { adaptOrder }) => {
        await cancelAdaptOrder(requestParams)
            .unwrap()
            .then(() => {
                onHide();
                Notification.success(<FormattedMessage
                    id={`intl-msg:adaptOrder.cancel.successMsg`}/>);
            })
            .catch((error) => {
                Notification.error(<FormattedMessage id='intl-msg:adaptOrder.cancel.errorMsg' values={
                    { error: error?.data?.message }
                }/>);
            });

    };

    const content = (
        <div className='text-center'>
            <div className='margin-50'>
                <div className=' text-size-h4 margin-bottom-10'>
                    <FormattedMessage
                        id='intl-msg:adaptOrder.cancel.dialogTitle'
                    />
                </div>

                <div className='text-size-14 text-color-warning'>
                    <span className='rioglyph rioglyph-info-sign margin-right-10'/>
                    <FormattedMessage
                        id='intl-msg:adaptOrder.cancel.dialogPrompt'/>
                </div>
            </div>
            <div className='pull-right btn-toolbar'>
                <button type='button' className='btn btn-default'
                        onClick={() => onHide()}>
                    <FormattedMessage id='intl-msg:close'/>
                </button>
                 <button type='button' className='btn btn-primary'
                                          onClick={() => onCancel({adaptOrder})}>
                    <FormattedMessage id='intl-msg:yes'/>
                </button>
            </div>
        </div>
    );


    return (<Dialog className='device-adapt-order-dialog'
                                show={show}
                                cancelButtonText={<FormattedMessage id='intl-msg:close'/>}
                                confirmButtonText={<FormattedMessage id='intl-msg:ok'/>}
                                onClickCancel={onHide}
                                onClickConfirm={onCancel} useOverflow
                                body={content}/>)
}

export default AdaptOrderFromDialog;
