import compact from 'lodash/fp/compact';
import size from 'lodash/fp/size';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { parseQuery } from '~/features/base/utils/query';

import DeliverableListToolbar from '~/features/artifacts/components/DeliverableListToolbar';
import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';

import uid from '~/features/base/utils/uid';
import DeliverablesTable from '~/features/artifacts/components/DeliverablesTable';
import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';
import { deliverablesSelector } from '~/features/artifacts/selectors/deliverableSelectors';
import { connect } from 'react-redux';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';

export class DeliverableList extends PureComponent {

    constructor(props) {
        super(props);
        this.name = uid();
    }

    onShowPage = (page) => {
        const { pathname, search } = this.props;
        const query = parseQuery(search);
        this.props.followRoute({
            route: pathname, query: {
                ...query,
                page,
            },
        });
    };

    onCreateDeliverable = () => {
        this.props.onCreateDeliverable({
            isNew: true,
        });
    };

    render() {
        const { deliverables, deliverablesLoading, onLoadMore } = this.props;
        return [
            <DefaultWhiteColumn className='padding-top-20 clearfix'>
                <DeliverableListToolbar onCreateDeliverable={this.onCreateDeliverable}>
                    <div className='display-inline-block padding-left-5'>
                        {deliverablesLoading ? <Spinner/> : null}
                    </div>
                </DeliverableListToolbar>
            </DefaultWhiteColumn>,
            <DefaultWhiteColumn className='padding-top-20 padding-bottom-5'>
                {size(deliverables) ?
                    <DeliverablesTable deliverables={deliverables}
                                       followRoute={this.props.followRoute}
                                       onEditDeliverable={this.props.onEditDeliverable}
                                       onDeleteDeliverable={this.props.onDeleteDeliverable}/>
                    :
                    <NotFoundState fullWidth
                                   headline={<FormattedMessage id='intl-msg:nothingFound'/>}
                                   message={<FormattedMessage id='intl-msg:fileDeliverables.notFound'/>}
                    />
                }
            </DefaultWhiteColumn>,
            <DefaultWhiteColumn className='text-center padding-bottom-20'>
                {size(deliverables) ?
                    <a id='load-more-button'
                       className='btn btn-default'
                       onClick={onLoadMore}
                    >
                        <FormattedMessage id='intl-msg:loadMore'/>
                    </a>
                    : null
                }
            </DefaultWhiteColumn>,
        ];
    }
}

export const mapStateToProps = (state) => {
    return {
        pathname: pathnameSelector(state),
        search: searchSelector(state),
        deliverables: deliverablesSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableList);
DeliverableList.defaultProps = {
    // props
    deliverableType: '',
    deliverableId: '',
    deliverables: [],
    deliverablesLoading: false,
    // functions
    followRoute: noop,
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    triggerDataFetcher: noop,
    onShowPage: noop,
    onLoadMore: noop,
    onCreateDeliverable: noop,
    onEditDeliverable: noop,
    onDeleteDeliverable: noop,
};

DeliverableList.propTypes = {
    // props
    deliverableType: PropTypes.string,
    deliverableId: PropTypes.string,
    deliverables: PropTypes.array,
    deliverablesLoading: PropTypes.bool,
    // functions
    followRoute: PropTypes.func,
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
    onShowPage: PropTypes.func,
    onLoadMore: PropTypes.func,
    onCreateDeliverable: PropTypes.func,
    onEditDeliverable: PropTypes.func,
    onDeleteDeliverable: PropTypes.func,
};
