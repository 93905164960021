import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import DeviceParametersContainer from '~/features/devices/components/DeviceParametersContainer';
import { hotkeys } from 'react-keyboard-shortcuts';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';

export class DeviceSystemParametersDialog extends PureComponent {
    // eslint-disable-next-line camelcase
    hot_keys = {
        'esc': {
            priority: 1,
            handler: () => {
                this.props.toggleSystemParametersDialog();
            },
        },
    };

    renderTitle() {
        return <FormattedMessage id='intl-msg:sysParams'/>;
    }

    renderBody(serialNumber) {
        return (
            <DeviceParametersContainer serialNumber={serialNumber}/>
        );
    }

    renderFooter() {
        return (
            <div>
                <button className='btn btn-default' onClick={this.props.toggleSystemParametersDialog}>
                    <FormattedMessage id='intl-msg:close'/>
                </button>
            </div>
        );
    }

    render() {
        const { toggleSystemParametersDialog, serialNumber } = this.props;
        const title = this.renderTitle();
        const body = this.renderBody(serialNumber);
        const footer = this.renderFooter();
        return (
            <Dialog className='device-editor-dialog'
                    show={true}
                    onHide={toggleSystemParametersDialog}
                    title={title}
                    body={body}
                    footer={footer}
                    useOverflow
                    bsSize={Dialog.SIZE_FULL_SCREEN}
            />
        );
    }
}

export default hotkeys(DeviceSystemParametersDialog);

DeviceSystemParametersDialog.defaultProps = {
    // props
    serialNumber: '',
    // functions
    toggleSystemParametersDialog: noop,
};

DeviceSystemParametersDialog.propTypes = {
    // props
    serialNumber: PropTypes.string,
    // functions
    toggleSystemParametersDialog: PropTypes.func,
};
