import { HIDE_SESSION_EXPIRED_DIALOG, TAB_VISIBILITY_CHANGED } from './actions';

const initialState = {
    sessionExpiredAcknowledged: false,
    isTabVisible: true,
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case HIDE_SESSION_EXPIRED_DIALOG:
            return {
                ...state,
                sessionExpiredAcknowledged: true,
            };
        case TAB_VISIBILITY_CHANGED:
            return {
                ...state,
                isTabVisible: action.isVisible,
            };
        default:
            return state;
    }
};

export default reducer;
