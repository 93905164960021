import getOr from 'lodash/fp/getOr';

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const RenderingError = ({ error }) => {
    const message = getOr('', 'message', error);
    const stack = getOr('', 'stack', error);
    return (
        <div>
            <h1>
                <FormattedMessage id='intl-msg:error.somethingWentWrong'/>
            </h1>
            <div className='padding-top-10 padding-bottom-20'>
                <span className='text-color-danger text-size-18'>
                    {message}
                </span>
            </div>
            <pre>
                {stack}
            </pre>
        </div>
    );
};

export default RenderingError;

RenderingError.propTypes = {
    // props
    error: PropTypes.object,
};
