import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import noop from 'lodash/fp/noop';

import ListTable from '~/features/base/components/table/ListTable';
import { fileColumnDescriptors } from '~/features/artifacts/constants/deliverableColumnDescriptors';
import { FormattedMessage } from 'react-intl';
import size from 'lodash/fp/size';
import { connect } from 'react-redux';
import { getDeliverableManagementBackend } from '~/features/base/selectors/backendSelectors';
import {
    canDeleteDeliverableFilesSelector,
    canReadDeliverableFilesSelector,
} from '~/features/artifacts/selectors/deliverableFileSelectors';
import { canManageDeliverablesSelector } from '~/features/user/selectors/permissionSelectors';
import {
    deleteArtifactFile,
    downloadArtifactFile,
    showArtifactFileDeletionDialog,
} from '~/features/deliverables/features/artifacts/actions/artifactFileActions';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';

export class ArtifactFilesTable extends PureComponent {

    constructor(props) {
        super(props);

        this.actionsColumn = {
            id: 'actions',
            field: 'fileName',
            className: 'table-action',
            format: field => {
                const items = [];
                const { canDeleteArtifactFiles, canReadArtifactFiles, canManageArtifacts } = this.props;
                if (canDeleteArtifactFiles && canManageArtifacts) {
                    items.push({
                        value: (
                            <div onClick={this.onDeleteButtonClick} data-key={field}>
                                <span className='rioglyph rioglyph-trash margin-right-10'></span>
                                <span><FormattedMessage id='intl-msg:deleteFile'/></span>
                            </div>
                        ),
                    });
                }
                if (canReadArtifactFiles) {
                    items.push({
                        value: (
                            <div onClick={this.onDownloadButtonClick} data-key={field}>
                                {/*<div data-key={field}>*/}
                                {/*    <a download href={this.createDownloadLink(field)}>*/}
                                <span className='rioglyph rioglyph-download margin-right-10'></span>
                                <span><FormattedMessage id='intl-msg:download'/></span>
                                {/*</a>*/}
                            </div>
                        ),
                    });
                }
                if (size(items)) {
                    return (
                        <ButtonDropdown
                            title={<span className='rioglyph rioglyph-option-vertical'/>}
                            className='pull-right'
                            bsStyle='link'
                            iconOnly
                            items={items}/>
                    );
                }
            },
            formatLabel: () => '',
        };
    }

    // createDownloadLink = (fileName) => {
    //     const url = `${this.props.serviceURL}/artifacts/v1`;
    //     return `${url}/${this.props.deliverableId}/files/${fileName}/download`;
    // };

    onDeleteButtonClick = (event) => {
        const fileName = event.currentTarget.getAttribute('data-key');
        this.props.showArtifactFileDeletionDialog({
            artifactId: this.props.artifactId,
            fileName,
        });
    };

    onDownloadButtonClick = (event) => {
        const fileName = event.currentTarget.getAttribute('data-key');
        this.props.downloadArtifactFile(this.props.artifactId, fileName);
    };

    render() {
        const { files } = this.props;
        if (files) {
            const columns = [...fileColumnDescriptors, this.actionsColumn];
            return (
                <ListTable items={files}
                           itemKey='fileName'
                           columnDescriptors={columns}/>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    serviceURL: getDeliverableManagementBackend(state),
    canReadArtifactFiles: canReadDeliverableFilesSelector(state),
    canDeleteArtifactFiles: canDeleteDeliverableFilesSelector(state),
    canManageArtifacts: canManageDeliverablesSelector(state),
});

export const mapDispatchToProps = (dispatch) => {
    return {
        showArtifactFileDeletionDialog: payload => {
            dispatch(showArtifactFileDeletionDialog(payload));
        },
        deleteArtifactFile: (artifactId, fileName) => {
            dispatch(deleteArtifactFile(artifactId, fileName));
        },
        downloadArtifactFile: (artifactId, fileName) => {
            dispatch(downloadArtifactFile(artifactId, fileName));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArtifactFilesTable);

ArtifactFilesTable.defaultProps = {
    // props
    artifactId: '',
    files: [],
    canReadArtifactFiles: false,
    canDeleteArtifactFiles: false,
    // functions
    deleteArtifactFile: noop,
    downloadArtifactFile: noop,
    canManageArtifacts: noop,
};

ArtifactFilesTable.propTypes = {
    // props
    artifactId: PropTypes.string.isRequired,
    files: PropTypes.array,
    canReadArtifactFiles: PropTypes.bool,
    canDeleteArtifactFiles: PropTypes.bool,
    // functions
    deleteArtifactFile: PropTypes.func,
    downloadArtifactFile: PropTypes.func,
    canManageArtifacts: PropTypes.func,
};
