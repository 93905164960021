import React from 'react';
import ListTable from '~/features/base/components/table/ListTable';
import { connect } from 'react-redux';
import { removeControlDeviceFromDeliverableWhitelist } from '~/features/devices/actions/controlDeviceQuickWhitelistingActions';
import PropTypes from 'prop-types';

class DeviceAssociatedDeliverablesListTable extends ListTable {
    onRemoveControlDeviceFromDeliverableWhitelist = (selectedDeliverableId, selectedDeliverableVersion) => {
        const { serialNumber } = this.props;
        this.props.removeControlDeviceFromDeliverableWhitelist({
            selectedDeliverableId,
            selectedDeliverableVersion,
            serialNumber,
        });
    };

}

export const mapStateToProps = (state) => {
};

export const mapDispatchToProps = (dispatch) => {
    return {
        removeControlDeviceFromDeliverableWhitelist: payload => {
            dispatch(removeControlDeviceFromDeliverableWhitelist(payload));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceAssociatedDeliverablesListTable);

DeviceAssociatedDeliverablesListTable.defaultProps = {
    // props
    serialNumber: '',
    selectedDeliverableType: '',
};

DeviceAssociatedDeliverablesListTable.propTypes = {
    // props
    serialNumber: PropTypes.string,
    selectedDeliverableType: PropTypes.string,
};
