import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { CONTROL_DEVICE_ASSOCIATED_DELIVERABLES_MODAL } from '~/features/base/constants/modals';

import { showModal } from '~/features/base/actions/ui/modalsActions';
import { doHandleErrorSaga, getHTTP } from '~/features/base/sagas/sagaUtil';
import { finishedAction } from '~/features/higherorder/transforms/actionTransforms';
import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';
import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';
import {
    FETCH_CONTROL_DEVICE_REFERENCED_DELIVERABLES,
    SHOW_CONTROL_DEVICE_REFERENCED_DELIVERABLES,
} from '~/features/devices/actions/controlDeviceAssociatedDeliverablesActions';
import { mergeEntity } from '~/features/higherorder/actions/entityActions';
import { ENTITY_CONTROL_DEVICE_REFERENCED_DELIVERABLES } from '~/features/base/constants/entities';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import {
    DIRECT_WHITELISTED,
    DIRECT_BLACKLISTED,
    GROUP_WHITELISTED,
    GROUP_BLACKLISTED,
} from '~/features/devices/constants/deliverablesReferenceTypes';

export function* getDeliverablesURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/deliverable`;
}

export function* doFetchControlDeviceReferencedDeliverablesSaga(action) {
    try {
        const deliverablesServiceURL = yield call(getDeliverablesURL);
        const { serialNumber } = action.payload;
        const response = yield call(getHTTP,
            `${deliverablesServiceURL}/${serialNumber}/referencedDeliverables?page=0&size=50`);
        const structuredResponse = structureResponse(response);
        yield put(
            mergeEntity(structuredResponse,
                { entityName: ENTITY_CONTROL_DEVICE_REFERENCED_DELIVERABLES }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

function structureResponse(response) {
    let referencedDeliverables = {};

    // add direct referenced artifacts
    [DIRECT_WHITELISTED, DIRECT_BLACKLISTED].map(listingType => {
        response[listingType].map(deliverable => {
            const deliverableVersion = deliverable.deliverableVersion ? toShortSemanticVersion(
                    deliverable.deliverableVersion)
                : '0.0.0';
            referencedDeliverables = {
                ...referencedDeliverables,
                [deliverable.deliverableType]: {
                    ...referencedDeliverables[deliverable.deliverableType],
                    [deliverable.deliverableId]: {
                        ...referencedDeliverables[deliverable.deliverableType]
                        && referencedDeliverables[deliverable.deliverableType][deliverable.deliverableId],
                        [deliverableVersion]: {
                            ...(referencedDeliverables[deliverable.deliverableType]
                                && referencedDeliverables[deliverable.deliverableType][deliverable.deliverableId])
                            && referencedDeliverables[deliverable.deliverableType][deliverable.deliverableId][deliverableVersion],
                            [listingType]: {
                                groups: [],
                            },
                        },
                    },
                },
            };
        });
    });

    // add group referenced artifacts
    [GROUP_WHITELISTED, GROUP_BLACKLISTED].map(listingType => {
        Object.keys(response[listingType]).map(deliverableKeyPublic => {
            const splittedDeliverableKeyPublic = deliverableKeyPublic.replace('DeliverableKeyPublic(', '').slice(0,
                -1).split(', ');
            const deliverableType = splittedDeliverableKeyPublic[0].split('=')[1];
            const deliverableId = splittedDeliverableKeyPublic[1].split('=')[1];
            const deliverableVersion = splittedDeliverableKeyPublic[2].split('=')[1];
            const groups = response[listingType][deliverableKeyPublic];

            if (referencedDeliverables[deliverableType]
                && referencedDeliverables[deliverableType][deliverableId]
                && referencedDeliverables[deliverableType][deliverableId][deliverableVersion]
                && referencedDeliverables[deliverableType][deliverableId][deliverableVersion][listingType]
            ) {
                referencedDeliverables[deliverableType][deliverableId][deliverableVersion][listingType].pushAll(groups);
            } else {
                referencedDeliverables = {
                    ...referencedDeliverables,
                    [deliverableType]: {
                        ...referencedDeliverables[deliverableType],
                        [deliverableId]: {
                            ...referencedDeliverables[deliverableType]
                            && referencedDeliverables[deliverableType][deliverableId],
                            [deliverableVersion]: {
                                ...(referencedDeliverables[deliverableType]
                                    && referencedDeliverables[deliverableType][deliverableId])
                                && referencedDeliverables[deliverableType][deliverableId][deliverableVersion],
                                [listingType]: {
                                    groups,
                                },
                            },
                        },
                    },
                };
            }
        });
    });

    return referencedDeliverables;
}

export function* doShowControlDeviceAssociatedDeliverablesSaga(action) {
    yield put(showModal({
        modalType: CONTROL_DEVICE_ASSOCIATED_DELIVERABLES_MODAL,
        modalProps: { serialNumber: action.serialNumber },
    }));
}

export function* showControlDeviceAssociatedDeliverablesSaga() {
    yield takeLatest(SHOW_CONTROL_DEVICE_REFERENCED_DELIVERABLES, doShowControlDeviceAssociatedDeliverablesSaga);
}

export function* fetchControlDeviceReferencedDeliverablesSaga() {
    yield takeLatest(FETCH_CONTROL_DEVICE_REFERENCED_DELIVERABLES, doFetchControlDeviceReferencedDeliverablesSaga);
}
