import PropTypes from 'prop-types';

import React from 'react';

const renderAddon = ({ pageTitleAddon }) => {
    if (pageTitleAddon) {
        return (
            <div className='display-inline-block padding-left-10 align-middle'>
                <span className='position-relative top-25pct'>{pageTitleAddon}</span>
            </div>
        );
    }
};
const renderLinkAddon = ({ pageTitleLinkAddon }) => {
    if (pageTitleLinkAddon) {
        return (
            <div className='position-absolute right-10 top-25 z-index-1'>
                {pageTitleLinkAddon}
            </div>
        );
    }
};

export const PageHeader = ({ pageTitle, pageLink, ...rest }) => {
    return (
        <>
            <div className='col-md-12'>
                <p>{pageLink}</p>
            </div>
            <div className='col-md-12 padding-bottom-25 equal-height'>
                <div className='display-inline-block'>
                    <h1>{pageTitle}</h1>
                </div>
                {renderAddon(rest)}
                {renderLinkAddon(rest)}
            </div>
        </>
    );
};

export default PageHeader;

PageHeader.propTypes = {
    // props
    pageTitle: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    pageLink: PropTypes.node,
    pageTitleAddon: PropTypes.node,
    pageTitleLinkAddon: PropTypes.node,
};
