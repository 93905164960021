// TODO Maybe change to set and unset

export const REGISTER_DATA_INTEREST = 'REGISTER_DATA_INTEREST';
export const UNREGISTER_DATA_INTEREST = 'UNREGISTER_DATA_INTEREST';
export const UNREGISTER_ALL_DATA_INTEREST = 'UNREGISTER_ALL_DATA_INTEREST';

export const registerDataInterest = (view, interest) => ({
    type: REGISTER_DATA_INTEREST,
    view,
    interest,
});

export const unRegisterAllDataInterest = () => ({
    type: UNREGISTER_ALL_DATA_INTEREST,

});

export const unregisterDataInterest = (view) => ({
    type: UNREGISTER_DATA_INTEREST,
    view,
});
