import React from 'react';
import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import { FormattedMessage } from 'react-intl';
import { TechnicalInformation } from '~/api/models/technicalInformation';

interface TechnicalInformationFormModel {
    onChange: (tiInfo: TechnicalInformation) => void;
    model: any;
}

const TechnicalInformationForm = ({ onChange, model }: TechnicalInformationFormModel) => {

    const onchangeTechnicalInformation = (id, value) => {
        let currentTiInfo;
        if (model?.bundleInfo?.tiIds?.length > 0) {
            currentTiInfo = model.bundleInfo.tiIds[0];
        }
        const tiInfo = {
            ...model.bundleInfo,
            tiIds: [{ ...currentTiInfo, [id]: value }]
        };
        onChange(tiInfo);
    };

    return <ExpanderPanel className='additional-rollout-settings-expander'
                          open={false}
                          title={<FormattedMessage
                              id={'intl-msg:technicalInformation'}/>} bsStyle='default'>
        <div className={'display-flex flex-row gap-20 width-100pct'}>
            <div className='form-group  width-100pct '>
                <label className='control-label'>
                    <FormattedMessage id={'intl-msg:id'}/>
                </label>
                <input type='text' className='form-control width-227'
                       value={model.bundleInfo.tiIds && model.bundleInfo.tiIds[0]?.tiId}
                       placeholder={'Enter id'}
                       onChange={(event) => onchangeTechnicalInformation('tiId', event.target.value)}/>
            </div>
            <div className='form-group  width-100pct'>
                <label className='control-label'>
                    <FormattedMessage id={'intl-msg:status'}/>
                </label>
                <input type='text' className='form-control'
                       value={model.bundleInfo.tiIds && model.bundleInfo.tiIds[0]?.status}
                       placeholder={'Enter status'}
                       onChange={(event) => onchangeTechnicalInformation('status', event.target.value)}/>
            </div>
            <div className='form-group  width-100pct'>
                <label className='control-label'>
                    <FormattedMessage id={'intl-msg:number'}/>
                </label>
                <input type='text' className='form-control'
                       value={model.bundleInfo.tiIds && model.bundleInfo.tiIds[0]?.number}
                       placeholder={'Enter number'}
                       onChange={(event) => onchangeTechnicalInformation('number', event.target.value)}/>
            </div>
        </div>
        <div className={'display-flex flex-row gap-20 '}>
            <div className='form-group  width-100pct'>
                <label className='control-label'>
                    <FormattedMessage id={'intl-msg:version'}/>
                </label>
                <input type='text' className='form-control'
                       value={model.bundleInfo.tiIds && model.bundleInfo.tiIds[0]?.version}
                       placeholder={'Enter version'}
                       onChange={(event) => onchangeTechnicalInformation('version', event.target.value)}/>
            </div>
            <div className='form-group width-100pct'>
                <label className='control-label'>
                    <FormattedMessage id={'intl-msg:variant'}/>
                </label>
                <input type='text' className='form-control'
                       value={model.bundleInfo.tiIds && model.bundleInfo.tiIds[0]?.variant}
                       placeholder={'Enter variant'}
                       onChange={(event) => onchangeTechnicalInformation('variant', event.target.value)}/>
            </div>
        </div>
    </ExpanderPanel>;
};

export default TechnicalInformationForm;
