import flow from 'lodash/fp/flow';
import camelCase from 'lodash/fp/camelCase';
import kebabCase from 'lodash/fp/kebabCase';
import mapKeys from 'lodash/fp/mapKeys';

import queryString from 'query-string';

export const parseQuery = query => {
    const parsedQuery = queryString.parse(query, {parseBooleans: true});
    return flow(
        mapKeys(camelCase)
    )(parsedQuery);
}

export const stringifyToQuery = props =>
    flow(
        mapKeys(kebabCase),
        queryString.stringify
    )(props);

export default {
    parse: parseQuery,
    stringify: stringifyToQuery,
};
