import React from 'react';
import { FormattedTime } from 'react-intl';
import DeliverableIdChip from '~/features/deliverables/components/DeliverableIdChip';
import UpdateEventOutcome from '~/features/updateEvents/components/UpdateEventOutcome';
import UpdateEventType from '~/features/updateEvents/components/UpdateEventType';
import FieldOrNoData from '~/features/base/components/FieldOrNoData';
import ShortBaseSwVersion from '~/features/baseSwVersions/components/ShortBaseSwVersion';
import Chip from '~/features/base/components/Chip';
import DeliverableChip from '~/features/deliverables/components/DeliverableChip';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import { DeliverableType } from '~/features/deliverables/constants/DeliverableType';

const getDeliverableType = (updateEventType) => {
    const updateTypeSplit = updateEventType.split('_');
    let deliverableType = '';
    if (updateTypeSplit.length > 0) {
        deliverableType = updateTypeSplit[0];
        deliverableType = deliverableType === 'PACKAGE' ? 'app' : deliverableType;
    }
    return deliverableType.toLowerCase();
};
export const updateEventColumnDescriptors = [{
    id: 'hwSerial',
    field: 'hwSerial',
    label: 'intl-msg:hwSerial',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'type',
    field: 'type',
    label: 'intl-msg:type',
    format: field => <UpdateEventType value={field}/>,
}, {
    id: 'deliverableId',
    field: 'deliverableId',
    label: 'intl-msg:packageId',
    format: (field, item) => {

        return (<DeliverableIdChip deliverableId={field} deliverableType={getDeliverableType(item.type)}/>);
    },
}, {
    id: 'previousVersion',
    field: 'previousVersion',
    label: 'intl-msg:previousVersion',
    format: (field, item) => [
        <DeliverableChip isShortVersion={false} deliverableId={item.deliverableId}
                         deliverableType={getDeliverableType(item.type)}
                         deliverableVersion={toShortSemanticVersion(field)}/>,
        item.previousBaseSoftwareVersion ? [
            <span>/</span>,
            <Chip toolTipMessage={'intl-msg:baseSwVersion'}>
                <ShortBaseSwVersion baseSwVersion={item.previousBaseSoftwareVersion}/>
            </Chip>,
        ] : null,
    ],
}, {
    id: 'targetVersion',
    field: 'targetVersion',
    label: 'intl-msg:targetVersion',
    format: (field, item) => [
        <DeliverableChip isShortVersion={false} deliverableId={item.deliverableId}
                         deliverableType={getDeliverableType(item.type)}
                         deliverableVersion={toShortSemanticVersion(field)}/>,
        item.targetBaseSoftwareVersion ? [
            <span>/</span>,
            <Chip toolTipMessage={'intl-msg:baseSwVersion'}>
                <ShortBaseSwVersion baseSwVersion={item.targetBaseSoftwareVersion}/>
            </Chip>,
        ] : null,
    ],
}, {
    id: 'actualVersion',
    field: 'actualVersion',
    label: 'intl-msg:actualVersion',
    format: (field, item) => [
        <DeliverableChip deliverableId={item.deliverableId}
                         deliverableType={getDeliverableType(item.type)}
                         deliverableVersion={toShortSemanticVersion(field)}/>,
        item.actualBaseSoftwareVersion ? [
            <span>/</span>,
            <Chip toolTipMessage={'intl-msg:baseSwVersion'}>
                <ShortBaseSwVersion baseSwVersion={item.actualBaseSoftwareVersion}/>
            </Chip>,
        ] : null,
    ],
}, {
    id: 'bundle',
    field: 'bundle',
    label: 'intl-msg:updateEventBundle',
    format: field => {
        if (field) {
            return <DeliverableChip deliverableType={DeliverableType.BUNDLE}
                                    bsStyle={'highlight'}
                                    deliverableId={field.id}
                                    deliverableVersion={toShortSemanticVersion(field.version)}
                                    displayValue={`${field.id}@${toShortSemanticVersion(field.version)}`}/>;
        } else {
            return <FieldOrNoData field={field}/>;
        }
    },
}, {
    id: 'outcome',
    field: 'outcome',
    label: 'intl-msg:result',
    format: field => <UpdateEventOutcome value={field}/>,
}, {
    id: 'info',
    field: 'info',
    label: 'intl-msg:info',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'timestamp',
    field: 'timestamp',
    label: 'intl-msg:timestamp',
    format: field => <FormattedTime value={field} year='numeric' month='2-digit' day='2-digit'/>,
}];
