import * as React from 'react';


import {FormattedMessage} from 'react-intl';
import {selectionByValue} from '~/features/base/utils/selectUtils';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import {createReasonForChangeOptions} from "~/features/base/constants/reasonForChange";
import {SelectOption} from "@rio-cloud/rio-uikit/lib/es/types";
import {toShortSemanticVersion} from "~/features/base/utils/versionNumberConverter";
import FieldOrNoData from "~/features/base/components/FieldOrNoData";

interface ReasonForChangeSelectFormItemProps {
    value: string;
    onChange(selectOption: SelectOption): void;
}

const ReasonForChangeSelectFormItem = ({value, onChange}: ReasonForChangeSelectFormItemProps) => {
    const reasonForChangePreset = value || null;
    const optionsWithSelection = selectionByValue(reasonForChangePreset)(createReasonForChangeOptions());
    return (
        <div className='form-group form-group-release-state'>
            <label className='control-label'>
                <FormattedMessage id='intl-msg:reasonForChange'/>
            </label>
            <div>
                <Select placeholder={'Please select...'} options={optionsWithSelection} onChange={onChange} useClear/>
            </div>
        </div>
    );
};

export default ReasonForChangeSelectFormItem;
