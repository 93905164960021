import find from 'lodash/fp/find';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { deviceLogColumnDescriptors } from '~/features/deviceLogs/constants/deviceLogColumnDescriptors';

import ListTable from '~/features/base/components/table/ListTable';
import size from 'lodash/fp/size';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';

/**
 * Component for listing device logs
 */
export class DeviceLogsTable extends PureComponent {
    constructor(props) {
        super(props);
        this.onDownloadButtonClick = this.onDownloadButtonClick.bind(this);

        this.actionsColumn = {
            id: 'actions',
            field: 'id',
            className: 'table-action',
            format: field => {
                return [
                    <div className='btn-toolbar pull-right'>
                        <div className='btn-group'>
                            <FormattedMessage id='intl-msg:download'>
                                {title =>
                                    <button className='btn btn-link'
                                            title={title}
                                            data-key={field}
                                            onClick={this.onDownloadButtonClick}>
                                        <span className='rioglyph rioglyph-download' aria-hidden='true'></span>
                                    </button>
                                }
                            </FormattedMessage>
                        </div>
                    </div>,
                ];
            },
            formatLabel: () => '',
        };
    }

    onDownloadButtonClick(event) {
        const key = event.currentTarget.getAttribute('data-key');
        const logFile = find(item => item.id === key, this.props.deviceLog);
        this.props.onDownloadLogFile(logFile);
    }

    render() {
        const { deviceLog } = this.props;
        const logFiles = deviceLog;
        const columns = [...deviceLogColumnDescriptors, this.actionsColumn];
        if (!size(deviceLog)) {
            return (
                <NotFoundState fullWidth
                               headline={<FormattedMessage id='intl-msg:nothingFound'/>}
                               message={<FormattedMessage id='intl-msg:noDeviceLogsAvailable'/>}
                />
            );
        }

        if (logFiles) {
            return [
                <ListTable items={logFiles}
                           itemKey='id'
                           columnDescriptors={columns}
                           onRowClick={this.onRowClick}/>,
            ];
        }
    }
}

export default DeviceLogsTable;

DeviceLogsTable.defaultProps = {
    // props
    serialNumber: '',
    deviceLog: [],
    // functions
    onDownloadLogFile: noop,
};

DeviceLogsTable.propTypes = {
    // props
    serialNumber: PropTypes.string,
    deviceLog: PropTypes.array,
    // functions
    onDownloadLogFile: PropTypes.func,
};
