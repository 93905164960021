import find from 'lodash/fp/find';
import has from 'lodash/fp/has';
import includes from 'lodash/fp/includes';
import isEmpty from 'lodash/fp/isEmpty';
import noop from 'lodash/fp/noop';
import size from 'lodash/fp/size';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import uid from '~/features/base/utils/uid';
import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';
import {
    deliverableIdsPageItemsSelector,
    deliverablePageItemsSelector,
} from '~/features/deliverables/selectors/deliverableSelectors';
import { connect } from 'react-redux';

import classNames from 'classnames';
import map from 'lodash/fp/map';
import { withRouter } from 'react-router-dom';
import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';
import { FormattedMessage } from 'react-intl';
import {
    fetchFilteredDeliverableIds,
    fetchFilteredDeliverables,
} from '~/features/deliverables/actions/deliverableActions';
import {
    DELIVERABLE_TYPE_APP,
    DELIVERABLE_TYPE_DISTRO,
    DELIVERABLE_TYPE_FILE,
} from '~/features/deliverables/constants/deliverablesParameters';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import { deliverablesContextsSelector } from '~/features/artifacts/selectors/deliverableSelectors';
import { TEST } from '~/features/base/constants/releaseStates';
import {
    controlDeviceEligiblePackageVersionsSelector,
    getCurrentlySelectedDeviceInfoSelector,
} from '~/features/devices/selectors/controlDeviceSelectors';
import {
    addControlDeviceToDeliverableWhitelist,
    removeControlDeviceFromDeliverableWhitelist,
    revokeUninstallPackageDeliverable,
} from '~/features/devices/actions/controlDeviceQuickWhitelistingActions';
import {
    DEVICE_QUICK_WHITELISTING_MODE_INSTALL,
} from '~/features/devices/components/dialogs/quickWhitelisting/DeviceQuickWhitelistingDialog';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import ReleaseState from '~/features/base/components/ReleaseState';
import { FINISHED } from '~/features/devices/constants/deviceRolloutStates';
import { resetArtifactEditor } from '~/features/deliverables/features/artifacts/reducers/artifactEditorReducer';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';

export class FotaL3DeliverableList extends PureComponent {
    constructor(props) {
        super(props);
        this.name = uid();
    }

    onDeliverableSelect = (deliverableType, selectedDeliverableId, owningContext) => {
        this.props.fetchFilteredDeliverables(
            owningContext,
            deliverableType.toLowerCase(),
            selectedDeliverableId,
            // TEST,
        );
        this.props.onDeliverableIdChange(selectedDeliverableId);
    };

    onVersionSelect = (selectedDeliverableVersion, isAlreadyWhitelisted) => {
        this.props.onDeliverableVersionChange(selectedDeliverableVersion, isAlreadyWhitelisted);
    };

    getDeliverableLabelName = (deliverableType) => {
        switch (deliverableType) {
            case DELIVERABLE_TYPE_FILE:
                return <FormattedMessage id='intl-msg:files'/>;
            case DELIVERABLE_TYPE_APP:
                return <FormattedMessage id='intl-msg:apps'/>;
            case DELIVERABLE_TYPE_DISTRO:
                return <FormattedMessage id='intl-msg:distros'/>;
            default:
                return <FormattedMessage id='intl-msg:files'/>;
        }
    };

    onInstallDeliverable = (selectedDeliverableType, selectedDeliverableId, selectedDeliverableVersion) => {
        const { serialNumber } = this.props;
        this.props.addControlDeviceToDeliverableWhitelist({
            selectedDeliverableType,
            selectedDeliverableId,
            selectedDeliverableVersion,
            serialNumber,
        });
    };

    onUninstallDeliverable = (selectedDeliverableType, selectedDeliverableId, selectedDeliverableVersion) => {
        const { serialNumber } = this.props;
        this.props.removeControlDeviceFromDeliverableWhitelist({
            selectedDeliverableType,
            selectedDeliverableId,
            selectedDeliverableVersion,
            serialNumber,
        });
    };

    onRevokeUninstallDeliverable = (selectedDeliverableId, selectedDeliverableVersion) => {
        const { serialNumber } = this.props;
        this.props.revokeUninstallPackageDeliverable({
            selectedDeliverableId,
            selectedDeliverableVersion,
            serialNumber,
        });
    };

    isDeviceAlreadyWhitelisted = (serialNumber, deliverable) => {
        if (deliverable.whitelisting && deliverable.whitelisting.deviceWhitelist) {
            return includes(serialNumber, deliverable.whitelisting.deviceWhitelist);
        }
    };

    isDeviceAlreadyBlacklisted = (serialNumber, deliverable) => {
        if (deliverable.blacklisting && deliverable.blacklisting.deviceBlacklist) {
            return includes(serialNumber, deliverable.blacklisting.deviceBlacklist);
        }
    };

    isDeviceCurrentlyInstalled = (deliverable) => {
        const { currentControlDevice, deliverableType } = this.props;
        switch (deliverableType) {
            case DELIVERABLE_TYPE_APP:
                return !isEmpty(find({
                    'packageId': deliverable.deliverableId,
                    'packageVersion': deliverable.deliverableVersion,
                }, currentControlDevice.lastPackageVersionsReported));
            case DELIVERABLE_TYPE_FILE:
                return resetArtifactEditor();
            case DELIVERABLE_TYPE_DISTRO:
                return resetArtifactEditor();
            default:
                return false;
        }
    };

    isInstallationPending = (deliverable) => {
        const { controlDeviceEligiblePackageVersions } = this.props;
        if (has(deliverable.deliverableId, controlDeviceEligiblePackageVersions)) {
            return toShortSemanticVersion(controlDeviceEligiblePackageVersions[deliverable.deliverableId])
                === toShortSemanticVersion(deliverable.deliverableVersion);
        }
        return false;
    };

    render() {
        const {
            deliverableIds,
            deliverableType,
            deliverablesLoading,
            onLoadMore,
            selectedDeliverableId,
            selectedDeliverableVersion,
        } = this.props;

        const tableClassNames = classNames(
            'table',
            'table-hover',
            'table-layout-fixed',
            'table-bordered',
            'table-sticky',
        );

        return (
            <DefaultWhiteColumn className='padding-top-25 clearfix'>
                <div className='col-md-12 bg-white padding-bottom-25'>
                    {this.renderDeliverablesColumn(deliverableIds, deliverableType, selectedDeliverableId,
                        tableClassNames)}
                    {this.renderDeliverableVersionsColumn(deliverableIds, selectedDeliverableVersion, tableClassNames)}
                </div>
            </DefaultWhiteColumn>
        );
    }

    renderDeliverablesColumn(deliverableIds, deliverableType, selectedDeliverableId, tableClassNames) {
        return (
            <div className='col-md-7'>
                <div className={classNames('bg-white')}>
                    <div className='panel panel-primary height-200'>
                        <div className='panel-heading height-40'>
                            <div className='col-md-6 padding-top-5'>
                                {this.props.deliverableHeadline}
                            </div>
                        </div>
                        <div>
                            {size(deliverableIds) > 0 ?
                                <div className='overflow-y-auto padding-bottom-50 max-height-150'>
                                    <table className={tableClassNames}>
                                        <tbody>
                                        {this.renderDeliverableRows(deliverableIds, selectedDeliverableId)}
                                        </tbody>
                                    </table>
                                </div>
                                : <div></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderDeliverableVersionsColumn(deliverableIds, selectedDeliverableVersion, tableClassNames) {
        return (

            <div className='col-md-5'>
                <div className={classNames('bg-white clearfix')}>
                    <div className='panel panel-primary height-200'>
                        <div className='panel-heading height-40'>
                            <div className='col-md-6 padding-top-5'>
                                <FormattedMessage id='intl-msg:versions'/>
                            </div>
                        </div>
                        <div>
                            {size(deliverableIds) > 0 ?
                                <div>
                                    <div className='overflow-y-auto padding-bottom-25-lg height-150'>
                                        <table className={tableClassNames}>
                                            <tbody>
                                            {
                                                (this.props.mode === DEVICE_QUICK_WHITELISTING_MODE_INSTALL)
                                                    ? this.renderInstallableDeliverableVersionRows()
                                                    : this.renderUninstallableDeliverableVersionRows()
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                : <div></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderDeliverableRows(deliverableIds, selectedDeliverableId) {
        const { deliverableType } = this.props;
        return map(deliverableId => {
            const items = [];
            return (
                <tr className={(selectedDeliverableId === deliverableId) && 'active'}
                    onClick={() => this.onDeliverableSelect(deliverableType, deliverableId)}>
                    <td className='width-100pct'>
                        <div>
                            <h6>
                                {deliverableId}
                            </h6>
                        </div>
                    </td>
                    <td>
                        {
                            size(items) > 0 &&
                            <ButtonDropdown
                                title={<span className='rioglyph rioglyph-option-vertical'/>}
                                className='pull-right'
                                bsStyle='link'
                                iconOnly items={items}/>
                        }
                    </td>
                </tr>
            );
        }, deliverableIds);
    }

    renderInstallableDeliverableVersionRows() {
        const { deliverables, deliverableType, serialNumber } = this.props;
        let versionAlreadyInstalled = false;
        return map(deliverable => {
            const isAlreadyWhitelisted = this.isDeviceAlreadyWhitelisted(serialNumber, deliverable);
            const isCurrentlyInstalled = this.isDeviceCurrentlyInstalled(deliverable);
            const isInstallationPending = this.isInstallationPending(deliverable);
            isCurrentlyInstalled ? versionAlreadyInstalled = true : null;
            const versionString = toShortSemanticVersion(deliverable.deliverableVersion);
            return (
                <tr>
                    <td>
                        <div className='row'>
                            <div className='col-md-3'>
                                {versionString}
                            </div>
                            <div className='col-md-3 text-center'>
                                <ReleaseState releaseState={deliverable.releaseState}></ReleaseState>
                            </div>
                            {isCurrentlyInstalled ?
                                <div className='col-md-6 text-center'>
                                    <span className='badge'><FormattedMessage id='intl-msg:installed'/></span>
                                </div>
                                :
                                <div className='col-md-6 text-center'>
                                    {
                                        (deliverable.releaseState === 'TEST' && !versionAlreadyInstalled
                                            && !isInstallationPending) ?
                                            <button
                                                className='btn btn-default btn-xs'
                                                onClick={() => this.onInstallDeliverable(
                                                    deliverableType,
                                                    deliverable.deliverableId,
                                                    versionString)}>
                                                <FormattedMessage id='intl-msg:install'/>
                                            </button>
                                            :
                                            (deliverable.releaseState === 'TEST') &&
                                            <div className='col-md-6 text-center'>
                                                <span className='badge'><FormattedMessage id='intl-msg:pending'/></span>
                                            </div>
                                    }
                                </div>
                            }
                        </div>
                    </td>
                </tr>
            );
        }, deliverables);
    }

    renderUninstallableDeliverableVersionRows() {
        const {
            controlDeviceEligiblePackageVersions,
            deliverables,
            deliverableType,
            selectedDeliverableId,
            currentControlDevice,
        } = this.props;
        const preparedDeliverables = [];
        // add pending artifacts
        // eslint-disable-next-line guard-for-in
        for (const key in controlDeviceEligiblePackageVersions) {
            (key === selectedDeliverableId) && preparedDeliverables.push(
                {
                    deliverableType,
                    deliverableId: key,
                    deliverableVersion: controlDeviceEligiblePackageVersions[key],
                },
            );
        }
        // add installed artifacts
        map(item => {
            (item.packageId === selectedDeliverableId) && preparedDeliverables.push({
                status: FINISHED,
                deliverableType,
                deliverableId: item.packageId,
                deliverableVersion: item.packageVersion,
            });
        }, currentControlDevice.lastPackageVersionsReported);
        return map(deliverable => {
            const versionString = toShortSemanticVersion(deliverable.deliverableVersion);
            return (
                <tr>
                    <td>
                        <div className='col-md-2'>
                            <h6>
                                {versionString}
                            </h6>
                        </div>
                        <div className='col-md-6 text-center padding-top-5'>
                            {
                                deliverable.status === FINISHED ? <span className='badge'><FormattedMessage
                                        id='intl-msg:installed'/></span>
                                    : <span className='badge'><FormattedMessage id='intl-msg:pending'/></span>
                            }
                        </div>
                        <div className='col-md-4 text-center'>
                            <button className='btn btn-default'
                                    onClick={() => this.onUninstallDeliverable(deliverableType,
                                        deliverable.deliverableId,
                                        versionString)}>
                                <FormattedMessage id='intl-msg:uninstall'/>
                            </button>
                            <button className='btn btn-default'
                                    onClick={() => this.onRevokeUninstallDeliverable(deliverable.deliverableId,
                                        versionString)}>
                                <FormattedMessage id='intl-msg:revokeUninstall'/>
                            </button>
                        </div>
                    </td>
                </tr>
            );
        }, preparedDeliverables);
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        currentControlDevice: getCurrentlySelectedDeviceInfoSelector(state),
        controlDeviceEligiblePackageVersions: controlDeviceEligiblePackageVersionsSelector(state, ownProps),
        pathname: pathnameSelector(state),
        search: searchSelector(state),
        deliverables: deliverablePageItemsSelector(state),
        deliverableIds: deliverableIdsPageItemsSelector(state),
        deliverablesContexts: deliverablesContextsSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        fetchFilteredDeliverables: (context, deliverableType, deliverableId, releaseState) => {
            const releaseStateValue = (releaseState && releaseState !== 'all') ? [releaseState] : undefined;
            dispatch(fetchFilteredDeliverables(
                {
                    page: 0,
                    searchCriteria: {
                        owningContext: context,
                        deliverableType,
                        deliverableId,
                        releaseStates: releaseStateValue,
                    },
                },
            ));
        },
        fetchFilteredDeliverableIds: (page, type, context, releaseState) => {
            const releaseStateValue = (releaseState && releaseState !== 'all') ? [releaseState] : undefined;
            dispatch(fetchFilteredDeliverableIds(
                {
                    page: (page && page > 0) ? (page - 1) : 0,
                    searchCriteria: {
                        deliverableType: type,
                        owningContext: context,
                        releaseStates: releaseStateValue,
                    },
                },
            ));
        },
        addControlDeviceToDeliverableWhitelist: payload => {
            dispatch(addControlDeviceToDeliverableWhitelist(payload));
        },
        removeControlDeviceFromDeliverableWhitelist: payload => {
            dispatch(removeControlDeviceFromDeliverableWhitelist(payload));
        },
        revokeUninstallPackageDeliverable: payload => {
            dispatch(revokeUninstallPackageDeliverable(payload));
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FotaL3DeliverableList));

FotaL3DeliverableList.defaultProps = {
    // props
    mode: '',
    serialNumber: '',
    deliverableType: '',
    deliverableHeadline: '',
    selectedDeliverableId: '',
    selectedDeliverableVersion: '',
    deliverables: [],
    deliverableIds: [],
    deliverablesLoading: false,
    controlDeviceEligiblePackageVersions: undefined,
    currentControlDevice: {},
    // functions
    followRoute: noop,
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    triggerDataFetcher: noop,
    onDeliverableVersionChange: noop,
    onDeliverableIdChange: noop,
    onLoadMore: noop,
    fetchFilteredDeliverables: noop,
    fetchFilteredDeliverableIds: noop,
    addControlDeviceToDeliverableWhitelist: noop,
    removeControlDeviceFromDeliverableWhitelist: noop,
    revokeUninstallPackageDeliverable: noop,
};

FotaL3DeliverableList.propTypes = {
    // props
    mode: PropTypes.string,
    serialNumber: PropTypes.string,
    expanderOpen: PropTypes.bool,
    deliverableType: PropTypes.string,
    deliverableHeadline: PropTypes.string,
    selectedDeliverableId: PropTypes.string,
    selectedDeliverableVersion: PropTypes.string,
    deliverables: PropTypes.array,
    deliverableIds: PropTypes.array,
    deliverablesLoading: PropTypes.bool,
    controlDeviceEligiblePackageVersions: PropTypes.object,
    currentControlDevice: PropTypes.object,
    // functions
    followRoute: PropTypes.func,
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
    onDeliverableVersionChange: PropTypes.func,
    onDeliverableIdChange: PropTypes.func,
    onLoadMore: PropTypes.func,
    fetchFilteredDeliverables: PropTypes.func,
    fetchFilteredDeliverableIds: PropTypes.func,
    addControlDeviceToDeliverableWhitelist: PropTypes.func,
    removeControlDeviceFromDeliverableWhitelist: PropTypes.func,
    revokeUninstallPackageDeliverable: PropTypes.func,
};
