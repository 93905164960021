import map from 'lodash/fp/map';
import find from 'lodash/fp/find';
import includes from 'lodash/fp/includes';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import Group from '~/prop-types/groupPropType';

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';


import classNames from 'classnames';

import GroupChip from '~/features/groups/components/GroupChip';
import GroupType from '~/features/groups/components/GroupType';
import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';

export class GroupCards extends PureComponent {
    constructor(props) {
        super(props);
        this.onCardClick = this.onCardClick.bind(this);
        this.onEditButtonClick = this.onEditButtonClick.bind(this);
        this.onDeleteButtonClick = this.onDeleteButtonClick.bind(this);
    }

    checkRecursively(node, nodeType, className) {
        if (node.localName === nodeType) {
            return includes(className, node.classList);
        } else if (node.localName === 'td') {
            return false;
        }
        return this.checkRecursively(node.parentNode, nodeType, className);

    }

    onCardClick(event) {
        const node = event.target;
        if (node.localName === 'span' && includes('checkbox-text', node.classList)) {
            return;
        }
        const name = event.currentTarget.getAttribute('data-name');
        const isSelection = this.checkRecursively(node, 'div', 'selection');
        if (name) {
            if (isSelection) {
                this.props.onSelectItem(name);
            } else {
                this.props.onShowItem(name);
            }
        }
    }

    onEditButtonClick(event) {
        const key = event.currentTarget.getAttribute('data-key');
        const group = find(item => item.name === key, this.props.groups);

        event.stopPropagation();
        this.props.onEditGroup({
            isNew: false,
            ...group,
        });
    }
    onDeleteButtonClick(event) {
        const key = event.currentTarget.getAttribute('data-key');
        const group = find(item => item.name === key, this.props.groups);

        event.stopPropagation();
        this.props.onDeleteGroup(group);
    }

    onSelectAllClick() {
        this.props.onSelectAll();
    }

    render() {
        const { groups, selectedItems, selectedAll } = this.props;

        const cards = this.renderCards(groups, selectedItems, selectedAll);

        return (
            <div key={'cards'} className='row equal-height'>
                {cards}
            </div>
        );
    }

    renderCards(groups, selectedItems, selectedAll) {
        return map(group =>
            this.renderCard(group, selectedAll || includes(group.name, selectedItems)), groups);
    }

    renderCard(group, selected) {
        const { name, groupSize } = group;
        const toolbar = this.renderToolbar(group);
        // Checkbox inline:  inline
        return (
            <div key={name} className={'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 margin-top-20'}>
                <div className={classNames('panel panel-default display-flex flex-column cursor-pointer height-100pct', { active: selected })}
                    data-name={name}
                    onClick={this.onCardClick}>
                    <div className='panel-heading clearfix'>
                        <div className='selection display-inline-block'>
                            <Checkbox checked={selected}/>
                        </div>
                        <GroupChip group={group}/>
                        <GroupType groupType={group.type}/>
                        {toolbar}
                    </div>
                    <div className='panel-body display-flex flex-1-0 justify-content-center align-items-center'>
                        <div className='text-size-20 text-center'>
                            {groupSize}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderToolbar(group) {
        const { canUpdateGroups, canDeleteGroups } = this.props;
        const editButton = canUpdateGroups ? (
            <FormattedMessage id='intl-msg:editGroup'>
                {title =>
                    <button className='btn btn-xs btn-link padding-left-0 padding-right-0'
                        title={title}
                        data-key={group.name}
                        onClick={this.onEditButtonClick}>
                        <span className='rioglyph rioglyph-pencil' aria-hidden='true'></span>
                    </button>
                }
            </FormattedMessage>
        ) : undefined;
        const deleteButton = canDeleteGroups ? (
            <FormattedMessage id='intl-msg:deleteGroup'>
                {title =>
                    <button className='btn btn-xs btn-link padding-left-0 padding-right-0'
                        title={title}
                        data-key={group.name}
                        onClick={this.onDeleteButtonClick}>
                        <span className='rioglyph rioglyph-trash' aria-hidden='true'></span>
                    </button>
                }
            </FormattedMessage>
        ) : undefined;
        return (
            <div className='btn-toolbar display-inline-block pull-right'>
                {editButton}
                {deleteButton}
            </div>
        );
    }
}

export default GroupCards;

GroupCards.defaultProps = {
    // props
    canUpdateGroups: false,
    canDeleteGroups: false,
    groups: [],
    selectedItems: [],
    selectedAll: false,
    // functions
    onSelectItem: noop,
    onSelectAll: noop,
    onShowItem: noop,
    onEditGroup: noop,
    onDeleteGroup: noop,
};

GroupCards.propTypes = {
    // props
    canUpdateGroups: PropTypes.bool,
    canDeleteGroups: PropTypes.bool,
    groups: PropTypes.arrayOf(Group),
    selectedItems: PropTypes.array,
    selectedAll: PropTypes.bool,
    // functions
    onSelectItem: PropTypes.func,
    onSelectAll: PropTypes.func,
    onShowItem: PropTypes.func,
    onEditGroup: PropTypes.func,
    onDeleteGroup: PropTypes.func,
};
