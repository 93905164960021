import React from 'react';
import { FormattedTime } from 'react-intl';
import LogFilesDownloadButton from '~/features/deviceLogs/components/logsTable/LogFilesDownloadButton';

export const logFilesColumnDescriptors = [{
    id: 'system',
    field: 'system',
    label: 'intl-msg:logs.system',
    format: field => field
}, {
    id: 'period',
    field: 'period',
    label: 'intl-msg:logs.period',
    format: (field) => (field && [
        <FormattedTime value={field.minValue} year='numeric' month='2-digit' day='2-digit'/>,
        field.maxValue ? (
            <span>
                    <span> - </span>
                <FormattedTime value={field.maxValue} year='numeric' month='2-digit' day='2-digit'/>
            </span>
        ) : null,
    ])
},
    {
        id: 'actions',
        field: 'contentId',
        className: 'table-action',
        format: (field, data) => <LogFilesDownloadButton contentId={field} fileKey={data.key}/>,
        formatLabel: () => '',
    }
];

