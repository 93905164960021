import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { REPORTS_BILLING_PATH } from '~/features/base/constants/routes';

export class ReportsBillingTabs extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const navigation = this.renderNavigation();
        return (
            <div className='file-details-tabs row'>
                <div className='col-md-12'>
                    {navigation}
                </div>
            </div>
        );
    }

    renderNavigationItem(basePath, view, label, currentView) {
        const className = {
            active: currentView === view,
        };
        return (
            <li key={view} className={classNames(className)}>
                <FormattedMessage id={label}/>
            </li>
        );
    }

    renderNavigation() {
        const { basePath, view } = this.props;
        return (
            <ul className='nav nav-tabs user-select-none'>
                {this.renderNavigationItem(basePath, REPORTS_BILLING_PATH, 'Data Volume & Costs', view)}
            </ul>
        );
    }
}

export default ReportsBillingTabs;

ReportsBillingTabs.defaultProps = {
    // props
    basePath: '',
    view: 'reports',
};

ReportsBillingTabs.propTypes = {
    // props
    basePath: PropTypes.string,
    view: PropTypes.string,
};
