import React, {useState} from 'react';
import size from "lodash/fp/size";
import ButtonDropdown from "@rio-cloud/rio-uikit/lib/es/ButtonDropdown";
import { FormattedMessage } from 'react-intl';
import WhitelistFormDialog from "~/features/deliverables/components/moreActions/WhitelistFormDialog";
import {DeliverableModel} from "~/features/devices/models/DeliverableModel";
import UninstallFormDialog from "~/features/deliverables/components/moreActions/UninstallFormDialog";
import { useSelector } from 'react-redux';
import {isTestOrUpdateManager, isUpdateManager} from "~/features/user/selectors/permissionSelectors";
import map from "lodash/fp/map";
import MenuItemWithTooltip from "~/features/base/components/MenuItemWithTooltip";
import {DELIVERABLE_TYPE_APP} from "~/features/deliverables/constants/deliverablesParameters";

interface DeliverableMoreActionsProps {
    deliverable: DeliverableModel;
}

const DeliverableMoreActions: React.FC<DeliverableMoreActionsProps> = ({ deliverable }) => {
    const items: any = [];
    const [showWhitelistDialog, setShowWhitelistDialog] = useState(false);
    const [showUninstallDialog, setShowUninstallDialog] = useState(false);
    const [dropDownOpen, setDropDownOpen]  = useState(false);
    const hasWhitelistPermissions = useSelector(isTestOrUpdateManager);
    const hasUninstallPermissions = useSelector(isUpdateManager);
    const deliverableType = deliverable.deliverableType?.toLowerCase();
    const seeUninstall = deliverableType === DELIVERABLE_TYPE_APP;

    const tooltipWhitelistingPermissions = <>
        <FormattedMessage id='intl-msg:roleDisclaimerTooltip.testOrUpdateManager'/>
    </>;
    const tooltipUninstallPermissions = <>
        <FormattedMessage id='intl-msg:roleDisclaimerTooltip.testOrUpdateManager'/>
    </>;

    items.push(
            {
                value: [
                    <FormattedMessage id='intl-msg:editWhitelist'/>,
                ],
                onSelect: () => setShowWhitelistDialog(true),
                disabled: !hasWhitelistPermissions,
                tooltipValue: !hasWhitelistPermissions && tooltipWhitelistingPermissions,

            },
    );
    if(seeUninstall) {
        items.push(
            {
                value: [
                    <FormattedMessage id='intl-msg:editUninstall'/>,
                ],
                onSelect: () => setShowUninstallDialog(true),
                disabled: !hasUninstallPermissions,
                tooltipValue: !hasUninstallPermissions && tooltipUninstallPermissions,
            },
        );
    }

    const customDropdown = <>
        {map((item, index) => {
            return <MenuItemWithTooltip {...item} index={index} closeDropdown={() => setDropDownOpen(false)}/>;
        }, items)}
    </>;

    return (
        size(items) ? (
            <>
                <div className='table-toolbar-column'>
                    <ButtonDropdown
                        id='moreActions'
                        title={<FormattedMessage id='intl-msg:moreActions'/>}
                        customDropdown={customDropdown}
                        open={dropDownOpen}
                        onOpen={() => setDropDownOpen(true)}
                        onClose={() => setDropDownOpen(false)}
                    />
                </div>
                {showWhitelistDialog && <WhitelistFormDialog onHide={() => setShowWhitelistDialog(false)} show={showWhitelistDialog}
                                      deliverable={deliverable}/>}
                {showUninstallDialog && <UninstallFormDialog onHide={() => setShowUninstallDialog(false)} show={showUninstallDialog}
                                        deliverable={deliverable}/>}
            </>
        ) : null
    );
};

export default DeliverableMoreActions;
