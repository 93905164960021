import assign from 'lodash/fp/assign';
import concat from 'lodash/fp/concat';
import toPairs from 'lodash/fp/toPairs';
import reduce from 'lodash/fp/reduce';
import flow from 'lodash/fp/flow';
import last from 'lodash/fp/last';
import map from 'lodash/fp/map';
import reverse from 'lodash/fp/reverse';
import sortBy from 'lodash/fp/sortBy';
import keys from 'lodash/fp/keys';
import take from 'lodash/fp/take';
import sum from 'lodash/fp/sum';
import values from 'lodash/fp/values';
import pick from 'lodash/fp/pick';
import omit from 'lodash/fp/omit';
import mapKeys from 'lodash/fp/mapKeys';

export const sumValues = reduce((acc, item) => sum([acc, item.value]), 0);

const technicalKeys = ['date', 'total'];
const omitTechnicalKeys = omit(technicalKeys);

export const getVitalKeys = amount => flow(
    omitTechnicalKeys,
    toPairs,
    sortBy('1'),
    reverse,
    take(amount),
    reduce((result, [key]) => {
        return assign(result, {
            [key || 'unknown']: 0,
        });
    }, {})
);
export const getVital7Keys = getVitalKeys(7);

export const getSnapshotTotal = flow(
    omitTechnicalKeys,
    values,
    reduce((acc, item) => sum([acc, item]), 0)
);

export const updateSnapshot = (baselineSnapshot, rawSnapshot) => {
    const snapshot = mapKeys(key => key || 'unknown', rawSnapshot);
    const vitalValues = pick(keys(baselineSnapshot), snapshot);
    const total = getSnapshotTotal(snapshot);
    const other = total - getSnapshotTotal(vitalValues);
    return {
        date: snapshot.date,
        ...baselineSnapshot,
        ...vitalValues,
        total,
        other,
    };
};

export const updateSnapshots = snapshots => {
    const baselineSnapshot = getVital7Keys(last(snapshots));
    return map(snapshot => updateSnapshot(baselineSnapshot, snapshot), snapshots);
};

export const buildSnapshots = updateSnapshots;

// Used in charts to extract a series
export const toSeries = (key, data) => {
    const result = map(item => ({ date: item.date, value: item[key] }))(data);
    return result;
};

// Used in charts to extract data points
export const toDataPoints = data => reduce((acc, series) => {
    return concat(acc, map(([key, value]) => ({ key, value, date: series.date }), toPairs(omitTechnicalKeys(series))));
}, [], data);

// Used in charts to extract data points
export const toDataKeys = data => keys(omitTechnicalKeys(last(data)));
