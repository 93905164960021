import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { dynamicBaseQuery } from '~/api/common/dynamicBaseQuery';
import {AdaptOrderSearchCriteria} from "~/api/models/adaptOrderSearchCriteria";
import {PageQuery} from "~/api/models/PageQuery";
import {ManualAdaptOrder} from "~/api/models/manualAdaptOrder";

export const adaptOrderServiceApi = createApi({
    reducerPath: 'adaptOrderApi',
    baseQuery: dynamicBaseQuery,
    endpoints: (builder) => ({
        fetchAdaptOrders: builder.query<{}, AdaptOrderSearchCriteria & PageQuery>({
            query: (criteria: AdaptOrderSearchCriteria & PageQuery) => ({
                url: `/v1/admin/adapt-order?page=${criteria.pageNumber}&size=${criteria.pageSize}`,
                method: 'POST',
                body: criteria,
            }),
        }),
        fetchAdaptOrdersStatus: builder.query<{}, any>({
            query: () => ({
                url: `/v1/admin/adapt-order/status`,
                method: 'POST',
            }),
        }),
        createManualAdaptOrder: builder.mutation<{}, {adaptOrder: ManualAdaptOrder, vin: string}>({
            query: ({adaptOrder, vin}: {adaptOrder: ManualAdaptOrder, vin: string}) => ({
                url: `/v1/admin/adapt-order/${vin}`,
                method: 'POST',
                body: adaptOrder
            }),
        }),
        updateAdaptOrder: builder.mutation<{}, {adaptOrder: ManualAdaptOrder, vin: string}>({
            query: ({adaptOrder, vin}: {adaptOrder: ManualAdaptOrder, vin: string}) => ({
                url: `/v1/admin/adapt-order/${vin}`,
                method: 'PUT',
                body: adaptOrder
            }),
        }),
        cancelAdaptOrder: builder.mutation<{}, {adaptOrder: ManualAdaptOrder}>({
            query: ({adaptOrder}: {adaptOrder: ManualAdaptOrder}) => ({
                url: `/v1/admin/adapt-order/cancel`,
                method: 'POST',
                body: adaptOrder
            }),
        }),

    })
});

export const {
    useFetchAdaptOrdersQuery,
    useCreateManualAdaptOrderMutation,
    useUpdateAdaptOrderMutation,
    useCancelAdaptOrderMutation
} = adaptOrderServiceApi;
