import {
    RESET_SYS_PARAM_SYSTEM_VALUE_EDITOR,
    CHANGE_SYS_PARAM_SYSTEM_VALUE_EDITOR,
} from '~/features/sysParams/actions/sysParamSystemValueEditorActions';

import {
    CREATE_SYS_PARAM_SYSTEM_VALUE,
    UPDATE_SYS_PARAM_SYSTEM_VALUE,
} from '~/features/sysParams/actions/sysParamSystemValueActions';

import { STRING } from '~/features/sysParams/constants/sysParamTypes';
import { toFinished } from '~/features/higherorder/transforms/actionTransforms';

const defaultState = {
    accessKey: 0,
    baseSoftwareVersion: {
        major: 1,
        minor: 0,
        patch: 0,
    },
    hwSerial: undefined,
    name: '',
    value: undefined,
    isNew: true,
    isInProgress: false,
    valid: false,
    enumValueIndex: 0,
    sysParamDefinition: {
        type: STRING,
        stringParameterDefinition: {
            maxLength: 128,
        },
    },
    sysParamDefaultValue: undefined,
};

/**
 * Reducer for system parameter system value editor
 * @param {object} state The current state
 * @param {object} action The action to be processed
 * @returns {object} The new state
 */
export default function sysParamSystemValueEditorReducer(state = defaultState, action = {}) {
    let newState;
    switch (action.type) {
        case RESET_SYS_PARAM_SYSTEM_VALUE_EDITOR:
            newState = {
                ...defaultState,
            };
            return newState;
        case CHANGE_SYS_PARAM_SYSTEM_VALUE_EDITOR:
            newState = {
                ...state,
                ...action.payload,
            };
            return newState;
        case CREATE_SYS_PARAM_SYSTEM_VALUE:
        case UPDATE_SYS_PARAM_SYSTEM_VALUE:
            newState = {
                ...state,
                isInProgress: true,
            };
            return newState;
        case toFinished(CREATE_SYS_PARAM_SYSTEM_VALUE):
        case toFinished(UPDATE_SYS_PARAM_SYSTEM_VALUE):
            newState = {
                ...state,
                isInProgress: false,
            };
            return newState;
        default:
            return state;
    }
}
