import { connect } from 'react-redux';
import { reportsBillingInitialDataSelector } from '~/features/reportsbilling/selectors/reportsBillingSelectors';
import { followRoute } from '~/features/base/actions/ui/routeActions';
import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import ReportsBillingCharts from '~/features/reportsbilling/components/ReportsBillingCharts';

const mapStateToProps = (state, ownProps) => {
    return {
        pathname: pathnameSelector(state),
        search: searchSelector(state),
        chartsInitialData: reportsBillingInitialDataSelector(state),
        requestParams: ownProps.requestParams,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        followRoute: (options) => {
            dispatch(followRoute(options));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsBillingCharts);

