import { combineReducers } from '@reduxjs/toolkit';
// data
import aggregationsReducer from '~/features/base/reducers/aggregationsReducer';
import entitiesReducer from '~/features/base/reducers/entitiesReducer';
import indicesReducer from '~/features/base/reducers/indicesReducer';
import selectionsReducer from '~/features/base/reducers/selectionsReducer';
import loadingReducer from '~/features/base/reducers/loadingReducer';
// ui
import dataInterestReducer from '~/features/base/reducers/ui/dataInterestReducer';
import filtersReducer from '~/features/base/reducers/ui/filtersReducer';
import controlDeviceDetailsReducer from '~/features/devices/reducers/ui/controlDeviceDetailsReducer';
import modalsReducer from '~/features/base/reducers/ui/modalsReducer';
import errorsReducer from '~/features/base/reducers/ui/errorsReducer';
import controlDeviceEditorReducer from '~/features/devices/reducers/controlDeviceEditorReducer';
import deviceActionEditorReducer from '~/features/deviceLogs/reducers/deviceActionEditorReducer';
import fileEditorReducer from '~/features/deliverables/features/files/reducers/fileEditorReducer';
import groupViewReducer from '~/features/groups/reducers/groupViewReducer';
import groupEditorReducer from '~/features/groups/reducers/groupEditorReducer';
import sysParamDefinitionEditorReducer from '~/features/sysParams/reducers/sysParamDefinitionEditorReducer';
import sysParamSystemValueEditorReducer from '~/features/sysParams/reducers/sysParamSystemValueEditorReducer';
import deliverableEditorReducer from '~/features/artifacts/reducers/deliverableEditorReducer';
import deliverableReducer from '~/features/artifacts/reducers/deliverableReducer';
import deliverableFilesReducer from '~/features/artifacts/reducers/deliverableFilesReducer';
import systemStateReducer from '~/features/systemState/reducers/systemStateReducer';
import deliverablePermissionsReducer from '~/features/artifacts/reducers/deliverablePermissionsReducer';
import atlassianJiraIssueEditorReducer from '~/features/atlassian/reducers/atlassianJiraEditorReducer';
import deliverableReducerNeo from '~/features/deliverables/reducers/deliverableReducer';
import deliverableEditorReducerNeo from '~/features/deliverables/reducers/deliverableEditorReducer';
import artifactEditorReducer from '~/features/deliverables/features/artifacts/reducers/artifactEditorReducer';
import controlDeviceQuickWhitelistingReducer from '~/features/devices/reducers/controlDeviceQuickWhitelisteningReducer';
import reportsBillingReducer from '~/features/reportsbilling/reducers/reportsBillingReducer';
import vehicleDetailsReducer from '~/features/oldVehicles/reducers/ui/vehicleDetailsReducer';

export const combinedReducers = {
    // new
    aggregations: aggregationsReducer,
    entities: entitiesReducer,
    indices: indicesReducer,
    selections: selectionsReducer,
    loading: loadingReducer,
    // ui
    ui: combineReducers({
        dataInterest: dataInterestReducer,
        filters: filtersReducer,
        deviceDetails: controlDeviceDetailsReducer,
        vehicleDetails: vehicleDetailsReducer,
        modals: modalsReducer,
        errors: errorsReducer,
        controlDeviceEditor: controlDeviceEditorReducer,
        controlDeviceQuickWhitelisting: controlDeviceQuickWhitelistingReducer,
        systemState: systemStateReducer,
        deviceActionEditor: deviceActionEditorReducer,
        fileEditor: fileEditorReducer,
        deliverables: deliverableReducer,
        deliverablesNeo: deliverableReducerNeo,
        deliverableFiles: deliverableFilesReducer,
        deliverableEditor: deliverableEditorReducer,
        deliverableEditorNeo: deliverableEditorReducerNeo,
        artifactEditor: artifactEditorReducer,
        deliverablePermissions: deliverablePermissionsReducer,
        groupView: groupViewReducer,
        groupEditor: groupEditorReducer,
        sysParamDefinitionEditor: sysParamDefinitionEditorReducer,
        sysParamSystemValueEditor: sysParamSystemValueEditorReducer,
        atlassianJiraIssueEditor: atlassianJiraIssueEditorReducer,
        reportsBilling: reportsBillingReducer,

    }),
};

export default combinedReducers;
