import get from 'lodash/fp/get';
import {
    DELIVERABLE_TYPE_APP,
    DELIVERABLE_TYPE_BUNDLE,
    DELIVERABLE_TYPE_CM4G,
    DELIVERABLE_TYPE_DISTRO,
    DELIVERABLE_TYPE_FILE,
} from '~/features/deliverables/constants/deliverablesParameters';
import {
    toShortSemanticVersion,
} from '~/features/base/utils/versionNumberConverter';
import { parseWhitelistingInfo } from '~/features/deliverables/features/distros/transforms/parseDistros';

const parseDistro = distro => {
    const { deliverableVersion, distroInfo } = distro;
    const shortDistroVersion = toShortSemanticVersion(deliverableVersion);
    const shortBaseSwVersion = toShortSemanticVersion(distroInfo.baseSoftwareVersion);
    const result = {
        ...distro,
        shortDistroVersion,
        shortBaseSwVersion,
        softwareDate: distro.softwareTimestamp ? new Date(distro.softwareTimestamp) : undefined,
        releaseStartDate: distro.releaseStart ? new Date(distro.releaseStart) : undefined,
        whitelistingInfo: parseWhitelistingInfo(distro.whitelistingInfo),
        mandatoryWhitelistingInfo: parseWhitelistingInfo(distro.mandatoryWhitelistingInfo),
    };
    return result;
};

const parseFile = file => {
    const { fileInfo } = file;
    const result = {
        ...file,
        recipient: fileInfo?.recipient
    };
    return result;
};

const parsePackage = app => {
    const { appInfo } = app;
    const {installerUrl, mmtInfo} = appInfo;
    const result = {
        ...app,
        installerUrl,
        mmtInfo,

    };
    return result;
};

export const parseDeliverable = document => {

    switch (document.deliverableType.toLowerCase()) {
        case DELIVERABLE_TYPE_DISTRO:
            return parseDistro(document);
        case DELIVERABLE_TYPE_APP:
            return parsePackage(document);
        case DELIVERABLE_TYPE_CM4G:
            return document;
        case DELIVERABLE_TYPE_BUNDLE:
            return document;
        case DELIVERABLE_TYPE_FILE:
            return parseFile(document);
        default:
            return {};
    }

};

export const parseHistoricDeliverable = content => {
    const newDocument = get('newDeliverable', content[0]);
    const oldDocument = get('oldDeliverable', content[0]);
    const deliverableVersions = [];
    if (newDocument) {
        deliverableVersions.push(parseDeliverable(newDocument));
    }
    if (oldDocument) {
        deliverableVersions.push(parseDeliverable(oldDocument));
    }
    return deliverableVersions;
};

export const parseDeliverableHistoryResponse = response => {
    const content = response.content;
    const deliverableVersions = parseHistoricDeliverable(content);
    return {
        deliverableVersions,
        shortDistroVersion: deliverableVersions[0]?.shortDistroVersion || '',
        changeOperation: content[0]?.changeOperation,
        changeTimestamp: content[0]?.changeTimestamp,
        changedBy: content[0]?.changedBy,
        historyId: (response.number + 1),
        page: (response.number + 1),
        pageCount: response.totalPages,
    };
};



