import PropTypes from 'prop-types';
import React from 'react';

import PropertiesTable from '~/features/base/components/table/PropertiesTable';

export const LogRequestPropertiesTable = ({ logRequest, rowDescriptors }) =>
    <PropertiesTable data={logRequest} rowDescriptors={rowDescriptors}/>;

export default LogRequestPropertiesTable;

LogRequestPropertiesTable.defaultProps = {
    logRequest: {},
    rowDescriptors: [],
};

LogRequestPropertiesTable.propTypes = {
    // props
    logRequest: PropTypes.object,
    rowDescriptors: PropTypes.array,
};
