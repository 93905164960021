import { call, put, select, fork, takeEvery, takeLatest } from 'redux-saga/effects';

import {
    FETCH_SYS_PARAM_DEFAULT_VALUES,
    CREATE_SYS_PARAM_DEFAULT_VALUE,
    UPDATE_SYS_PARAM_DEFAULT_VALUE,
    DELETE_SYS_PARAM_DEFAULT_VALUE,
} from '~/features/sysParams/actions/sysParamDefaultValueActions';
import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';
import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';
import { stringifyToQuery } from '~/features/base/utils/query';

import { doHandleErrorSaga, getHTTP, postHTTP, putHTTP, deleteHTTP } from '~/features/base/sagas/sagaUtil';
import {
    toShortSemanticVersion,
} from '~/features/base/utils/versionNumberConverter';

import {
    parseSysParamDefaultValues,
    parseSysParamDefaultValue,
} from '~/features/sysParams/transforms/parseSysParamDefaultValues';
import { mergeEntities, mergeEntity, deleteEntity } from '~/features/higherorder/actions/entityActions';
import { ENTITY_SYS_PARAM_DEFAULT_VALUE } from '~/features/base/constants/entities';
import { finishedAction } from '~/features/higherorder/transforms/actionTransforms';

const entityName = ENTITY_SYS_PARAM_DEFAULT_VALUE;

export function* getSysParamDefaultValuesURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/system-params/default-params`;
}

export function* doFetchSysParamDefaultValuesSaga(action) {
    const { shortBaseSwVersion } = action.payload;
    try {
        const sysParamDefaultValuesServiceURL = yield call(getSysParamDefaultValuesURL);
        const query = stringifyToQuery({
            swVersion: shortBaseSwVersion,
        });
        const response = yield call(getHTTP, `${sysParamDefaultValuesServiceURL}?${query}`);
        const entities = parseSysParamDefaultValues(response.content);
        yield put(mergeEntities(entities, { entityName, shortBaseSwVersion }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doCreateSysParamDefaultValueSaga(action) {
    try {
        const sysParamDefaultValuesServiceURL = yield call(getSysParamDefaultValuesURL);
        const defaultValue = action.payload.sysParamDefaultValue;
        const shortBaseSwVersion = toShortSemanticVersion(defaultValue.baseSoftwareVersion);
        const response = yield call(postHTTP, `${sysParamDefaultValuesServiceURL}`,
            JSON.stringify(action.payload.sysParamDefaultValue));
        const parsedResponse = parseSysParamDefaultValue(response);
        yield put(mergeEntity(parsedResponse, { entityName, shortBaseSwVersion }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doUpdateSysParamDefaultValueSaga(action) {
    try {
        const sysParamDefaultValuesServiceURL = yield call(getSysParamDefaultValuesURL);
        const defaultValue = action.payload.sysParamDefaultValue;
        const shortBaseSwVersion = toShortSemanticVersion(defaultValue.baseSoftwareVersion);
        // TODO That's weird:
        const response = yield call(putHTTP, `${sysParamDefaultValuesServiceURL}`,
            JSON.stringify(defaultValue));
        const parsedResponse = parseSysParamDefaultValue(response);
        yield put(mergeEntity(parsedResponse, { entityName, shortBaseSwVersion }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doDeleteSysParamDefaultValueSaga(action) {
    try {
        const sysParamDefaultValuesServiceURL = yield call(getSysParamDefaultValuesURL);
        const shortBaseSwVersion = toShortSemanticVersion(action.payload.baseSoftwareVersion);
        const encodedShortBaseSwVersion = encodeURIComponent(shortBaseSwVersion);
        const encodedAccessKey = encodeURIComponent(`${action.payload.accessKey}`);
        const url = `${sysParamDefaultValuesServiceURL}/${encodedShortBaseSwVersion}/${encodedAccessKey}`;
        const response = yield call(deleteHTTP, url);
        const parsedResponse = parseSysParamDefaultValue(response);
        yield put(deleteEntity(parsedResponse, { entityName, shortBaseSwVersion }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* fetchSysParamDefaultValuesSaga() {
    yield takeLatest(FETCH_SYS_PARAM_DEFAULT_VALUES, doFetchSysParamDefaultValuesSaga);
}

export function* createSysParamDefaultValueSaga() {
    yield takeEvery(CREATE_SYS_PARAM_DEFAULT_VALUE, doCreateSysParamDefaultValueSaga);
}

export function* updateSysParamDefaultValueSaga() {
    yield takeEvery(UPDATE_SYS_PARAM_DEFAULT_VALUE, doUpdateSysParamDefaultValueSaga);
}

export function* deleteSysParamDefaultValueSaga() {
    yield takeEvery(DELETE_SYS_PARAM_DEFAULT_VALUE, doDeleteSysParamDefaultValueSaga);
}
