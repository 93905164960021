import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import DeviceProductionMode from '~/features/devices/components/DeviceProductionMode';
import fp from 'lodash/fp';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import { connect } from 'react-redux';
import { toUpdateEventsScope } from '~/features/devices/components/DeviceDetailsContainer';
import { pathnameSelector } from '~/features/base/selectors/locationSelectors';
import {
    canReadControlDevicesSelector,
    canUpdateControlDevicesSelector,
    controlDeviceSelector, deviceDetailsUiSelector, getCurrentlySelectedDeviceSerialNumberSelector,
} from '~/features/devices/selectors/controlDeviceSelectors';
import {
    canCreateDeviceActionsSelector,
    deviceActionSelector,
} from '~/features/deviceLogs/selectors/deviceActionSelectors';
import { canReadSysParamDefinitionsSelector } from '~/features/sysParams/selectors/sysParamDefinitionSelectors';
import {
    canTriggerCheck4UpdateSelector,
    isDeviceMonitorUserSelector,
} from '~/features/user/selectors/permissionSelectors';
import { updateEventsSuccessAndFailedSelector } from '~/features/updateEvents/selectors/updateEventSelectors';
import noop from 'lodash/fp/noop';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { triggerCheckForUpdateOnControlDevice } from '~/features/devices/actions/controlDeviceActions';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import { copyToClipboard } from '~/features/apps/utils/appsUtils';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import uid from '~/features/base/utils/uid';
import VehicleDetailsToolbar from '~/features/oldVehicles/components/VehicleDetailsToolbar';
import VehiclePropertiesContainer from '~/features/oldVehicles/components/VehiclePropertiesContainer';
import { ExpanderPanel } from '@rio-cloud/rio-uikit';

export class VehicleSummary extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showCheckForUpdateTriggered: false,
            showCheckForUpdateProceeding: false,
        };
        this.name = uid();
        this.key = null;
    }

    isMdmcEligibleForCheckForUpdateTrigger = (packageMdmcAppVersion) => {
        if (packageMdmcAppVersion === '') {
            return false;
        }
        const version = Number(packageMdmcAppVersion.replace(/\./g, ''));
        return version >= 605 ? true : false;
    };

    onTriggerCheckForUpdate = () => {
        this.props.triggerCheckForUpdateOnControlDevice(this.props.serialNumber);
        this.setState({
            showCheckForUpdateTriggered: false,
            showCheckForUpdateProceeding: true,
        });
        setTimeout(() => this.setState({ showCheckForUpdateTriggered: false }), 2000);
        setTimeout(() => this.setState({ showCheckForUpdateProceeding: false }), 120000);
    };

    getPackageMdmcAppVersion = (controlDevice) => {
        let packageMdmcAppVersion = '6.0.5';
        if (controlDevice && controlDevice.lastPackageVersionsReported) {
            const packageMdmcApp = fp.find((currentPackage) => {
                return currentPackage.packageId === 'mdmc-app';
            }, controlDevice.lastPackageVersionsReported);
            if (packageMdmcApp && packageMdmcApp.packageVersion) {
                packageMdmcAppVersion = toShortSemanticVersion(packageMdmcApp.packageVersion);
            }
        }
        return packageMdmcAppVersion;
    };

    renderToolbar() {
        const {
            canUpdateControlDevices,
            vehicleInfo,
            serialNumber,
        } = this.props;

        let packageMdmcAppVersion = '';
        if (vehicleInfo && vehicleInfo.lastPackageVersionsReported) {
            const packageMdmcApp = fp.find((currentPackage) => {
                return currentPackage.packageId === 'mdmc-app';
            }, vehicleInfo.lastPackageVersionsReported);
            if (packageMdmcApp && packageMdmcApp.packageVersion) {
                packageMdmcAppVersion = toShortSemanticVersion(packageMdmcApp.packageVersion);
            }
        }

        if (!vehicleInfo) {
            return;
        }
        return (
            <VehicleDetailsToolbar
                vehicleInfo={vehicleInfo}
                serialNumber={serialNumber}
                packageMdmcAppVersion={packageMdmcAppVersion}
                canUpdateControlDevices={canUpdateControlDevices}
            />
        );
    }

    getVehicleIcon(vehicleInfo) {
        let vehicleIcon = 'rioglyph-truck';
        if (vehicleInfo.vehicleModel) {
            switch (vehicleInfo.vehicleModel) {
                case 'tge':
                    vehicleIcon = 'rioglyph-van';
                    break;
                case 'etge':
                    vehicleIcon = 'rioglyph-van';
                    break;
                default:
                    vehicleIcon = 'rioglyph-truck';
            }
        } else if (vehicleInfo.vin) {
            if (vehicleInfo.vin.startsWith('WMA') && vehicleInfo.vehicleVariant === 'fms4') {
                vehicleIcon = 'rioglyph-van';
            }
        }
        return vehicleIcon;
    }

    render() {
        const { vehicleInfo, canTriggerCheck4Update } = this.props;
        const packageMdmcAppVersion = this.getPackageMdmcAppVersion(vehicleInfo);
        const { showCheckForUpdateTriggered, showCheckForUpdateProceeding } = this.state;

        let vehicleIcon = this.getVehicleIcon(vehicleInfo);

        if (vehicleInfo) {
            const type = vehicleInfo.type;
            const toolbar = this.renderToolbar();
            return (
                <div>
                    <div className='col-md-12'>
                        <div className='row'>
                            <div className='col-md-1 text-size-16'>
                                <span
                                    className={`rioglyph ${vehicleIcon} text-size-300pct margin-top-20 margin-bottom-10 text-color-darker`}
                                    aria-hidden='true'></span>
                            </div>
                            <div className='col-md-11'>
                                <div className='row text-color-darker'>
                                    <span className='text-bold text-size-20'>{vehicleInfo.serialNumber}</span>
                                    <OverlayTrigger placement='top' overlay={
                                        <Tooltip id='tooltip' className='top-right'>
                                            <FormattedMessage id='intl-msg:copyVin'/>
                                        </Tooltip>}>
                                        <span onClick={() => copyToClipboard(vehicleInfo.vin, true)}
                                              className='rioglyph rioglyph-duplicate text-color-dark text-size-20 padding-left-5'></span>
                                    </OverlayTrigger>
                                </div>
                                <div className='row'>
                                    <DeviceProductionMode controlDevice={vehicleInfo}/>
                                </div>
                                <div className='row'>
                                    <span className='text-color-darker text-size-12'>
                                        <FormattedMessage id={'intl-msg:lastCheckForUpdateTimestamp'}/>:
                                        <span className='padding-left-5'>
                                            <FormattedTime value={vehicleInfo.lastCheckForUpdateTimestamp}
                                                           year='numeric'
                                                           month='2-digit' day='2-digit'/>
                                        </span>
                                        {/*{*/}
                                        {/*    showCheckForUpdateTriggered ?*/}
                                        {/*        <span>*/}
                                        {/*            <OverlayTrigger placement='right' overlay={*/}
                                        {/*                <Tooltip id='tooltip' className='top-right'>*/}
                                        {/*                    <div className='flex-row'>*/}
                                        {/*                                <span><FormattedMessage*/}
                                        {/*                                    id='intl-msg:newTriggerCheckForUpdate'/></span>*/}
                                        {/*                    </div>*/}
                                        {/*                </Tooltip>}>*/}
                                        {/*                <span className='padding-left-5'>*/}
                                        {/*                    <span*/}
                                        {/*                        className='rioglyph rioglyph-refresh spinning text-size-150pct'></span>*/}
                                        {/*                </span>*/}
                                        {/*            </OverlayTrigger>*/}
                                        {/*        </span>*/}
                                        {/*        : <span>*/}
                                        {/*            <OverlayTrigger placement='right' overlay={*/}
                                        {/*                <Tooltip id='tooltip' className='top-right'>*/}
                                        {/*                    <div className='flex-row'>*/}
                                        {/*                        {*/}
                                        {/*                            this.isMdmcEligibleForCheckForUpdateTrigger(*/}
                                        {/*                                packageMdmcAppVersion) ?*/}
                                        {/*                                canTriggerCheck4Update ?*/}
                                        {/*                                    showCheckForUpdateProceeding ?*/}
                                        {/*                                        <span><FormattedMessage*/}
                                        {/*                                            id='intl-msg:checkForUpdateProceeding'/></span>*/}
                                        {/*                                        : <span><FormattedMessage*/}
                                        {/*                                            id='intl-msg:triggerCheckForUpdate'/></span>*/}
                                        {/*                                    : <span><FormattedMessage*/}
                                        {/*                                        id='intl-msg:missingPermissionsCheckForUpdate'/></span>*/}
                                        {/*                                : <span><FormattedMessage*/}
                                        {/*                                    id='intl-msg:triggerCheckForUpdateDoesNotHaveMinimumVersion'/></span>*/}
                                        {/*                        }*/}
                                        {/*                    </div>*/}
                                        {/*                </Tooltip>}>*/}
                                        {/*                <span className='padding-left-5 position-relative bottom-1'>*/}
                                        {/*                    {*/}
                                        {/*                        (canTriggerCheck4Update*/}
                                        {/*                            && this.isMdmcEligibleForCheckForUpdateTrigger(*/}
                                        {/*                                packageMdmcAppVersion)) && <button*/}
                                        {/*                            disabled={showCheckForUpdateProceeding}*/}
                                        {/*                            onClick={this.onTriggerCheckForUpdate}*/}
                                        {/*                            className='btn btn-default btn-icon-only width-25 height-20'>*/}
                                        {/*                                <span*/}
                                        {/*                                    className='rioglyph rioglyph-compare'></span>*/}
                                        {/*                        </button>*/}
                                        {/*                    }*/}
                                        {/*                </span>*/}
                                        {/*            </OverlayTrigger>*/}
                                        {/*        </span>*/}
                                        {/*}*/}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='row padding-25'>
                                <ExpanderPanel bsStyle='primary' className='margin-0' open={true}
                                               title={<FormattedMessage id='intl-msg:vehicleDetails'/>}>
                                    <VehiclePropertiesContainer vin={vehicleInfo.vin}/>
                                </ExpanderPanel>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return <div></div>;
    }

    componentDidMount() {
        // const serialNumber = this.props.serialNumber;
        // if (!serialNumber || serialNumber === '---') {
        //     return;
        // }
        // this.registerDataInterest(serialNumber);
        // this.props.triggerDataFetcher();
        // this.interval = setInterval(() => {
        //     this.props.triggerDataFetcher();
        // }, 20000);
    }

    registerDataInterest(serialNumber) {
        // this.props.registerDataInterest(this.name, [
        //     // fetchControlDeviceInformation({ serialNumber }),
        //     fetchControlDeviceEligiblePackageVersions({ serialNumber }),
        //     fetchControlDeviceEligibleFileVersions({ serialNumber }),
        // ]);
        // this.props.triggerDataFetcher();
    }

    componentWillUnmount() {
        // clearInterval(this.interval);
        // this.props.unregisterDataInterest(this.name);
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        pathname: pathnameSelector(state),
        canTriggerCheck4Update: canTriggerCheck4UpdateSelector(state),
        canReadControlDevices: canReadControlDevicesSelector(state),
        canUpdateControlDevices: canUpdateControlDevicesSelector(state, ownProps),
        canCreateDeviceActions: canCreateDeviceActionsSelector(state),
        canReadSysParamDefinitions: canReadSysParamDefinitionsSelector(state),
        controlDevice: controlDeviceSelector(state, ownProps),
        deviceAction: deviceActionSelector(state, ownProps),
        updateEventsSuccessAndFailed: updateEventsSuccessAndFailedSelector(state,
            { scope: toUpdateEventsScope(ownProps.serialNumber) }),
        selectedSerialNumber: getCurrentlySelectedDeviceSerialNumberSelector(state),
        deviceDetails: deviceDetailsUiSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        triggerCheckForUpdateOnControlDevice: (serialNumber) => {
            dispatch(triggerCheckForUpdateOnControlDevice({ serialNumber }));
        },
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        triggerDataFetcher: () => {
            dispatch(triggerDataFetcher());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleSummary);

VehicleSummary.defaultProps = {
    // props
    vin: '',
    packageMdmcAppVersion: '',
    vehicleInfo: undefined,
    canUpdateControlDevices: false,
    showCheckForUpdateTriggered: false,
    // functions
    triggerCheckForUpdateOnControlDevice: noop,
};

VehicleSummary.propTypes = {
    // props
    vin: PropTypes.string,
    packageMdmcAppVersion: PropTypes.string,
    vehicleInfo: PropTypes.object,
    canReadControlDevices: PropTypes.bool,
    showCheckForUpdateTriggered: PropTypes.bool,
    // functions
    triggerCheckForUpdateOnControlDevice: PropTypes.func,
};
