import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getFromLocalStorage, saveToLocalStorage } from '~/setup/localStorage';
import {
    STORAGE_CURRENT_DISCLAIMER_VERSION,
    STORAGE_DISCLAIMER_VERSION_KEY,
} from '~/features/disclaimer/constants/disclaimer';
import { injectIntl } from 'react-intl';
import InfoDialog from '@rio-cloud/rio-uikit/lib/es/InfoDialog';

class DisclaimerDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showDialog: STORAGE_CURRENT_DISCLAIMER_VERSION > Number(this.props.disclaimerVersion),
        };
    }

    onCloseDisclaimer = () => {
        saveToLocalStorage(STORAGE_DISCLAIMER_VERSION_KEY, STORAGE_CURRENT_DISCLAIMER_VERSION);
        this.setState({ showDialog: false });
    };

    render() {
        const { intl } = this.props;
        const disclaimerMessage = intl.formatMessage({ id: 'intl-msg:disclaimerMessage' });

        const content = (
            <div className='text-center'>
                <div className='margin-bottom-25'>
                    <h3>{'Disclaimer'}</h3>
                    <p>
                        {disclaimerMessage}
                    </p>
                </div>
            </div>
        );

        const footer = (
            <button className={'btn btn-primary'} onClick={this.onCloseDisclaimer} type={'button'}>
                {'Confirm and Close'}
            </button>
        );

        return (
            <div>
                <InfoDialog
                    show={this.state.showDialog}
                    content={content}
                    footer={footer}
                    onClose={() => this.setState({ showDialog: false })}
                    disableEsc={true}
                />
            </div>
        );
    }
}

DisclaimerDialog.defaultProps = {
    // props
    disclaimerVersion: getFromLocalStorage(STORAGE_DISCLAIMER_VERSION_KEY) || 0,
};

DisclaimerDialog.propTypes = {
    // props
    disclaimerVersion: PropTypes.string,

};

export default injectIntl(DisclaimerDialog);
