import React from 'react';
import { FormattedMessage } from 'react-intl';
import TagList from "@rio-cloud/rio-uikit/lib/es/TagList";
import Tag from "@rio-cloud/rio-uikit/lib/es/Tag";
import {searchSelector} from "~/features/base/selectors/locationSelectors";
import { useSelector } from 'react-redux';
import {parseQuery} from "~/features/base/utils/query";
import {devicePropertiesModel} from "~/api/models";
import Button from "@rio-cloud/rio-uikit/lib/es/Button";


interface VehicleFiltersAppliedProps {
    onCriteriaChange: (property: devicePropertiesModel, value: any) => void;
    onClear: () => void;
}

const VehicleFiltersApplied: React.FC<VehicleFiltersAppliedProps> = ({ onCriteriaChange, onClear }) => {

    const urlParams = useSelector((state) => searchSelector(state));
    const { vinPrefix, ...criteria} = parseQuery(urlParams);

    const criteriaList = Object.values(criteria);
    const hasCriteria = criteriaList?.length > 0;

    const onDelete = (index: number) => {
        const property = Object.keys(criteria)[index];
        onCriteriaChange(property as devicePropertiesModel, undefined);
    }

    return (
        <div className={'margin-bottom-20 flex-row display-flex justify-content-between '}>
            {hasCriteria && <div className='display-flex align-items-center row padding-left-10'>
                <div className={'text-capitalize'}>
                    <FormattedMessage id='intl-msg:filteredBy'/>
                </div>
                <div>
                    <TagList className='padding-left-5'>
                        {
                            criteriaList.map((item, index) =>
                                (item &&
                                    <Tag size='small' deletable
                                         onClick={() => onDelete(index)}>
                                        {`${item}`}
                                    </Tag>
                                )
                            )
                        }
                        <button type="button" className="btn btn-sm btn-primary btn-link" onClick={onClear}>
                            <span className="text-capitalize text-decoration-underline">Clear all</span>
                        </button>
                    </TagList>
                </div>
            </div>}
        </div>
    );
};

export default VehicleFiltersApplied;
