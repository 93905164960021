import concat from 'lodash/fp/concat';
import flow from 'lodash/fp/flow';
import getOr from 'lodash/fp/getOr';
import last from 'lodash/fp/last';
import pick from 'lodash/fp/pick';
import reduceRight from 'lodash/fp/reduceRight';
import reverse from 'lodash/fp/reverse';
import size from 'lodash/fp/size';

import { call, put, select, fork, takeEvery, takeLatest } from 'redux-saga/effects';

import download from 'downloadjs';

import { SUCCESS, FAILED } from '~/features/updateEvents/constants/outcomes';
import {
    ENTITY_UPDATE_EVENT,
    ENTITY_UPDATE_EVENT_REPORT,
} from '~/features/base/constants/entities';

import {
    FETCH_UPDATE_EVENT_COUNT,
    FETCH_UPDATE_EVENT_COUNTS_BY_TIME_INTERVAL,
    FETCH_FILTERED_UPDATE_EVENTS,
    EXPORT_FILTERED_UPDATE_EVENTS,
    FETCH_UPDATE_EVENT_SUCCESS_AND_FAILED,
    FETCH_UPDATE_EVENT_REPORT,
} from '~/features/updateEvents/actions/updateEventActions';

import { mergeEntities } from '~/features/higherorder/actions/entityActions';
import { mergePage } from '~/features/higherorder/actions/paginationActions';
import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';
import { startLoading, endLoading } from '~/features/higherorder/actions/loadingActions';
import { mergeAggregation, resetAggregation } from '~/features/higherorder/actions/aggregationActions';

import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';

import { doHandleErrorSaga, postHTTP, requestText } from '~/features/base/sagas/sagaUtil';

import { stringifyToQuery } from '~/features/base/utils/query';

import { parseUpdateEventStats } from '~/features/updateEvents/transforms/parseUpdateEventStats';
import { parseUpdateEvents } from '~/features/updateEvents/transforms/parseUpdateEvents';
import { finishedAction, failedAction } from '~/features/higherorder/transforms/actionTransforms';

export function* getUpdateHistoryURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/update-history`;
}

export function* doFetchUpdateEventCountSaga(action) {
    try {
        const url = yield call(getUpdateHistoryURL);
        const response = yield call(postHTTP, `${url}/count`,
            JSON.stringify(action.searchCriteria));
        yield put(mergeAggregation({
            entityName: ENTITY_UPDATE_EVENT,
            scope: action.scope,
        }, response.count));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchUpdateEventSuccessAndFailedSaga(action) {
    try {
        const url = yield call(getUpdateHistoryURL);
        const successResponse = yield call(postHTTP, `${url}/count`,
            JSON.stringify({
                ...action.searchCriteria,
                outcome: SUCCESS,
            }));
        const failedResponse = yield call(postHTTP, `${url}/count`,
            JSON.stringify({
                ...action.searchCriteria,
                outcome: FAILED,
            }));
        yield put(mergeAggregation({
            entityName: ENTITY_UPDATE_EVENT,
            scope: action.scope,
        }, {
            success: successResponse.count,
            failed: failedResponse.count,
        }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchUpdateEventCountsByTimeIntervalSaga(action) {
    yield put(startLoading(ENTITY_UPDATE_EVENT_REPORT, action.scope));
    try {
        const url = yield call(getUpdateHistoryURL);
        const response = yield call(postHTTP, `${url}/counts-by/time-interval=${action.timeInterval}`,
            JSON.stringify(action.searchCriteria));
        const stats = parseUpdateEventStats(response.content, action.timeInterval);
        yield put(mergeAggregation({
            entityName: ENTITY_UPDATE_EVENT,
            scope: action.scope,
        }, stats));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_UPDATE_EVENT_REPORT, action.scope));
    yield put(finishedAction(action.type));
}

export function* doFetchUpdateEventReportSaga(action) {
    yield put(startLoading(ENTITY_UPDATE_EVENT_REPORT, action.scope));
    try {
        const url = yield call(getUpdateHistoryURL);
        const countResponse = yield call(postHTTP, `${url}/count`,
            JSON.stringify({
                ...action.searchCriteria,
            }));
        const count = countResponse.count;
        const statsResponse = yield call(postHTTP, `${url}/counts-by/time-interval=${action.timeInterval}`,
            JSON.stringify({
                ...action.searchCriteria,
            }));
        const updateEventStats = parseUpdateEventStats(statsResponse.content, action.timeInterval);

        if (!size(updateEventStats)) {
            yield put(resetAggregation( { entityName: ENTITY_UPDATE_EVENT_REPORT }));
            yield put(endLoading(ENTITY_UPDATE_EVENT_REPORT, action.scope));
            return;
        }

        const lastStat = last(updateEventStats);
        let value = count;
        const updateEventsTrend = reverse(reduceRight((stat, agg) => {
            value -= stat.value;
            const newTrend = {
                ...stat,
                value,
                date: stat.date,
            };
            return concat(agg, newTrend);
        }, [{
            ...lastStat,
            value,
            date: lastStat.endDate,
        }], updateEventStats));

        yield put(mergeAggregation({
            entityName: ENTITY_UPDATE_EVENT_REPORT,
            scope: action.scope,
        }, {
            success: count,
            stats: updateEventStats,
            trend: updateEventsTrend,
        }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_UPDATE_EVENT_REPORT, action.scope));
    yield put(finishedAction(action.type));
}

export function* doFetchFilteredUpdateEventsSaga(action) {
    yield put(startLoading(ENTITY_UPDATE_EVENT, action.scope));
    try {
        const url = yield call(getUpdateHistoryURL);
        const query = flow(pick(['page', 'size']), stringifyToQuery)(action.payload);
        const searchCriteria = getOr({}, 'payload.searchCriteria', action);

        const { content, ...pagination } = yield call(postHTTP, `${url}/search?${query}`,
            JSON.stringify(searchCriteria));

        const parsedResponse = { content: parseUpdateEvents(content), ...pagination };
        yield put(mergeEntities(parsedResponse.content, { entityName: ENTITY_UPDATE_EVENT }));
        yield put(mergePage({ entityName: ENTITY_UPDATE_EVENT, scope: action.scope }, parsedResponse));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_UPDATE_EVENT, action.scope));
    yield put(finishedAction(action.type));
}

export function* receiveFilteredUpdatesDeviceSaga(action){
    yield put(startLoading(ENTITY_UPDATE_EVENT, action.scope));
    try {
        const url = yield call(getUpdateHistoryURL);
        const query = flow(pick(['page', 'size']), stringifyToQuery)(action.payload);
        const searchCriteria = getOr({}, 'payload.searchCriteria', action);

        const { content, ...pagination } = yield call(postHTTP, `${url}/search?${query}`,
            JSON.stringify(searchCriteria));

        const parsedResponse = { content: parseUpdateEvents(content), ...pagination };
        return parsedResponse;
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_UPDATE_EVENT, action.scope));
    yield put(finishedAction(action.type));

}

export function* receiveSerials(url, searchCriteria) {
    const content = yield call(requestText, `${url}/export/serial-numbers`, {
        method: 'POST',
        headers: {
            Accept: 'text/plain',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(searchCriteria),
    });
    return content;
}

export function* receiveCSV(url, searchCriteria) {
    const content = yield call(requestText, `${url}/export/csv`, {
        method: 'POST',
        headers: {
            Accept: 'text/csv',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(searchCriteria),
    });
    return content;
}

export function* doExportFilteredUpdateEventsSaga(action) {
    try {
        const url = yield call(getUpdateHistoryURL);
        if (action.format === 'serials') {
            const result = yield call(receiveSerials, url, action.searchCriteria);
            download(result, 'update_event_serials.txt', 'text/plain');
        } else {
            const result = yield call(receiveCSV, url, action.searchCriteria);
            download(result, 'update_events.csv', 'text/csv');
        }
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
}

export function* fetchUpdateEventCountSaga() {
    yield takeEvery(FETCH_UPDATE_EVENT_COUNT, doFetchUpdateEventCountSaga);
}
export function* fetchUpdateEventSuccessAndFailedSaga() {
    yield takeEvery(FETCH_UPDATE_EVENT_SUCCESS_AND_FAILED, doFetchUpdateEventSuccessAndFailedSaga);
}

export function* fetchUpdateEventCountsByTimeIntervalSaga() {
    yield takeEvery(FETCH_UPDATE_EVENT_COUNTS_BY_TIME_INTERVAL, doFetchUpdateEventCountsByTimeIntervalSaga);
}

export function* fetchUpdateEventReportSaga() {
    yield takeEvery(FETCH_UPDATE_EVENT_REPORT, doFetchUpdateEventReportSaga);
}

export function* fetchFilteredUpdateEventsSaga() {
    yield takeLatest(FETCH_FILTERED_UPDATE_EVENTS, doFetchFilteredUpdateEventsSaga);
}

export function* exportFilteredUpdateEventsSaga() {
    yield takeLatest(EXPORT_FILTERED_UPDATE_EVENTS, doExportFilteredUpdateEventsSaga);
}
