import values from 'lodash/fp/values';
import React from 'react';
import {FormattedMessage} from 'react-intl';

export const RECALL = 'RECALL';
export const SAFETY = 'SAFETY';
export const SECURITY = 'SECURITY';
export const PERFORMANCE = 'PERFORMANCE';
export const NEW_FEATURE = 'NEW_FEATURE';
export const FEATURE_UNINSTALLATION = 'FEATURE_UNINSTALLATION';

export const reasonsForChange = {
    [RECALL]: {
        id: RECALL,
        value: RECALL,
        label: <FormattedMessage id='intl-msg:reasonForChange.recall'/>,
        tooltip: 'intl-msg:releaseState.draft.tooltip',
        className: 'label-info'
    },
    [SAFETY]: {
        id: SAFETY,
        value: SAFETY,
        label: <FormattedMessage id='intl-msg:reasonForChange.safety'/>,
        tooltip: 'intl-msg:reasonForChange.safety.tooltip',
        className: 'label-info'
    },
    [SECURITY]: {
        id: SECURITY,
        value: SECURITY,
        label: <FormattedMessage id='intl-msg:reasonForChange.security'/>,
        tooltip: 'intl-msg:reasonForChange.security.tooltip',
        className: 'label-info'
    },
    [PERFORMANCE]: {
        id: PERFORMANCE,
        value: PERFORMANCE,
        label: <FormattedMessage id='intl-msg:reasonForChange.performance'/>,
        tooltip: 'intl-msg:reasonForChange.performance.tooltip',
        className: 'label-info'
    },
    [NEW_FEATURE]: {
        id: NEW_FEATURE,
        value: NEW_FEATURE,
        label: <FormattedMessage id='intl-msg:reasonForChange.newFeature'/>,
        tooltip: 'intl-msg:reasonForChange.newFeature.tooltip',
        className: 'label-info'
    },
    [FEATURE_UNINSTALLATION]: {
        id: FEATURE_UNINSTALLATION,
        value: FEATURE_UNINSTALLATION,
        label: <FormattedMessage id='intl-msg:reasonForChange.featureUninstallation'/>,
        tooltip: 'intl-msg:reasonForChange.featureUninstallation.tooltip',
        className: 'label-info'
    }
};

export const createReasonForChangeOptions = () => values(reasonsForChange);
