import React, {useMemo, useState} from "react";
import AdaptOrdersList from "~/features/adaptOrders/components/AdaptOrdersList";
import {removeEmpty} from "~/features/base/utils/removeEmpty";
import {useFetchAdaptOrdersQuery} from "~/api/adaptOrder/AdaptOrder.api";
import AdaptOrdersFilters from "~/features/adaptOrders/components/AdaptOrdersFilters";
import ExpanderPanel from "@rio-cloud/rio-uikit/ExpanderPanel";
import {FormattedMessage} from 'react-intl';
import AdaptOrderFormDialog from "~/features/adaptOrders/components/AdaptOrderFormDialog";
import {ManualAdaptOrder} from "~/api/models/manualAdaptOrder";

interface AdaptOrdersContainerProps {
    vin: string;
}

const PAGE_SIZE = 50;

const AdaptOrdersContainer: React.FC<AdaptOrdersContainerProps> = ({ vin }) => {

    const defaultFilters = { vin }

    const [page, setPage] = useState<number>(0);
    const [filters, setFilters] = useState<AdaptOrderFiltersModel>(defaultFilters);
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [isNew, setIsNew] = useState<boolean>(true);
    const [currentAdaptOrder, setCurrentAdaptOrder] = useState<ManualAdaptOrder>();

    const parsedFilters = useMemo(() => {
        return removeEmpty({...filters})
    }, [filters]);

    const {data: orders, isLoading, refetch} = useFetchAdaptOrdersQuery({
        ...parsedFilters,
        pageNumber: page,
        pageSize: PAGE_SIZE
    }, { refetchOnMountOrArgChange: true });

    const handleFilterChange = (newFilters) => {
        setFilters({...filters, ...newFilters} );
    }

    const clearFilters = () => {
        setFilters(defaultFilters);
    }

    const onHide = () => {
        setShowDialog(false);
        setCurrentAdaptOrder(undefined);
    }

    const onCreate = () => {
        setShowDialog(true);
        setIsNew(true);
    }

    const onEdit = (adaptOrder: ManualAdaptOrder) => {
        setShowDialog(true);
        setIsNew(false);
        setCurrentAdaptOrder(adaptOrder);
    }

    return (
        <div style={{ backgroundColor: '#F5F5F5' }} className={'padding-bottom-10'}>
            <div className={'display-flex flex-column'}>
                <ExpanderPanel className={'rounded-large bg-white margin-top-10 margin-left-10 margin-right-10 margin-bottom-0'} title={
                    <div className={"display-flex align-items-center text-size-18"}>
                        <span className={"rioglyph rioglyph-filter"}></span>
                        <span className={"margin-left-5 h5 margin-top-0 margin-bottom-0"}><FormattedMessage id={'intl-msg:filters'}/></span>
                    </div>
                } open>
                    <AdaptOrdersFilters filters={filters} onFilterChange={handleFilterChange} clearFilters={clearFilters}/>
                </ExpanderPanel>
                <div className={'rounded-large bg-white margin-top-10  margin-left-10 margin-right-10 padding-20'}>
                    <AdaptOrdersList
                        vin={vin}
                        isLoading={isLoading}
                        orders={orders}
                        page={page}
                        setPage={setPage}
                        onReload={refetch}
                        onCreate={onCreate}
                        onEdit= {onEdit}
                        />
                </div>
            </div>
            {showDialog && <AdaptOrderFormDialog isNew={isNew}
                                                 adaptOrder={currentAdaptOrder}
                                                 vin={vin}
                                                 onHide={onHide}
                                                 show={showDialog}/>}
        </div>)
}

export default AdaptOrdersContainer;
