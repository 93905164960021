import concat from 'lodash/fp/concat';
import map from 'lodash/fp/map';
import reduce from 'lodash/fp/reduce';

import { select, all, put, take, call, takeLatest } from 'redux-saga/effects';

import {
    TRIGGER_DATA_FETCHER,
} from '~/features/base/actions/ui/dataFetcherActions';

import { dataInterestSelector } from '~/features/base/selectors/dataInterestSelectors';
import { finishedAction, toFinished } from '~/features/higherorder/transforms/actionTransforms';
import { store } from '~/setup/store';

export function* runAction(action) {
    yield put(action);
    yield take(toFinished(action.type));
}

export function* runActions(actions) {
    return yield all(map(action => call(runAction, action), actions));
}

export function* doTriggerDataFetcherSaga(action) {
    const reduxState = store.getState();
    if(!reduxState.app.isTabVisible) {
        console.info('skipped doTriggerDataFetcherSaga due to inactive tab');
        return;
    }
    const dataInterest = yield select(dataInterestSelector);
    const actions = reduce((result, value) => {
        return concat(result, value);
    }, [], dataInterest);

    yield call(runActions, actions);
    yield put(finishedAction(action.type));
}

export function* triggerDataFetcherSaga() {
    yield takeLatest(TRIGGER_DATA_FETCHER, doTriggerDataFetcherSaga);
}
