import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import size from 'lodash/fp/size';
import noop from 'lodash/fp/noop';

import classNames from 'classnames';

import { connect } from 'react-redux';
import { isDiagLogFileManagerSelector } from '~/features/user/selectors/permissionSelectors';
import {
    canReadControlDevicesSelector,
    controlDeviceEligibleFileVersionsSelector,
    controlDeviceEligiblePackageVersionsSelector,
    getSelectedTabIdSelector,
} from '~/features/devices/selectors/controlDeviceSelectors';
import { canCreateDeviceActionsSelector } from '~/features/deviceLogs/selectors/deviceActionSelectors';
import { canReadSysParamDefinitionsSelector } from '~/features/sysParams/selectors/sysParamDefinitionSelectors';
import { setSelectedTabDeviceDetailsSidebar } from '~/features/devices/actions/ui/controlDeviceDetailsActions';
import { getCurrentlySelectedVehicleDevicesSelector } from '~/features/oldVehicles/selectors/vehicleSelectors';
import { DETAILS } from '~/features/devices/constants/deviceDetailsTabs';
import { FormattedMessage } from 'react-intl';
import { DELIVERABLES } from '~/features/oldVehicles/components/devices/constants/deviceDetailsTabs';

export class VehicleDetailsTabs extends PureComponent {
    constructor() {
        super();
        this.state = {
            showSystemParametersDialog: false,
            showSystemStatesDialog: false,
            showUpdateEventsDialog: false,
        };
    }

    renderNavigationItem = (tabId, currentTabId, label, badgeValue) => {
        const className = {
            active: currentTabId === tabId,
        };
        return (
            <li key={tabId} className={classNames(className)}>
                <a class='nav-link' onClick={() => this.props.setSelectedTabDeviceDetailsSidebar(tabId)}>
                    <FormattedMessage id={label}/>
                    {
                        badgeValue ? <span className='badge margin-left-5'>{badgeValue}</span> : null
                    }
                </a>
            </li>
        );
    };

    renderNavigation = () => {
        const {
            canReadSysParamDefinitions,
            isDeviceLogsUser,
            controlDeviceEligiblePackageVersions,
            controlDeviceEligibleFileVersions,
            serialNumber,
            selectedTabId,
        } = this.props;
        const { showSystemParametersDialog, showSystemStatesDialog, showUpdateEventsDialog } = this.state;
        const amountEligiblePackageVersions = size(controlDeviceEligiblePackageVersions);
        const amountEligibleFileVersions = size(controlDeviceEligibleFileVersions);
        return (
            <ul className='nav nav-pills'>
                {this.renderNavigationItem(DETAILS, selectedTabId, 'intl-msg:controlDeviceDetails')}
                {this.renderNavigationItem(DELIVERABLES, selectedTabId, 'intl-msg:installedSoftware',
                    amountEligiblePackageVersions)}
                {/*{this.renderNavigationItem(FILES, selectedTabId, 'intl-msg:files',*/}
                {/*    amountEligibleFileVersions)}*/}
                {/*<li key={EVENTS_PATH} onClick={this.toggleUpdateEventsDialog}>*/}
                {/*    <FormattedMessage id={'intl-msg:updateEvents'}/>*/}
                {/*</li>*/}
                {/*{showUpdateEventsDialog && <DeviceUpdateEventsDialog serialNumber={serialNumber}*/}
                {/*                                                     toggleUpdateEventsDialog={this.toggleUpdateEventsDialog}/>}*/}
                {/*{this.renderNavigationItem(REPORT, selectedTabId, 'intl-msg:report')}*/}
                {/*{this.renderNavigationItem(LOGS, selectedTabId, 'intl-msg:logs')}*/}
                {/*{newLogService && this.renderNavigationItem(LOGSNEO, selectedTabId, 'intl-msg:logs.neo')}*/}

                {/*{(isDeviceLogsUser) &&*/}
                {/*this.renderNavigationItem(basePath, LOGS_PATH, 'intl-msg:logs', view)}*/}
                {/*{canReadSysParamDefinitions &&*/}
                {/*    <li key={PARAMETERS_PATH} onClick={this.toggleSystemParametersDialog}>*/}
                {/*        <FormattedMessage id={'intl-msg:sysParams'}/>*/}
                {/*    </li>*/}
                {/*}*/}
                {/*{showSystemParametersDialog && <DeviceSystemParametersDialog serialNumber={serialNumber}*/}
                {/*                                                             toggleSystemParametersDialog={this.toggleSystemParametersDialog}/>}*/}

                {/*{canReadSysParamDefinitions &&*/}
                {/*<li key={SYSTEM_STATE_NOTIFICATIONS_PATH} onClick={this.toggleSystemStatesDialog}>*/}
                {/*    <FormattedMessage id={'intl-msg:apps.deviceState.title'}/>*/}
                {/*</li>*/}
                {/*}*/}
                {/*{showSystemStatesDialog && <DeviceSystemStatesDialog serialNumber={serialNumber}*/}
                {/*                                                     toggleSystemStatesDialog={this.toggleSystemStatesDialog}/>}*/}
            </ul>
        );
    };

    toggleSystemParametersDialog = () => {
        this.setState({ showSystemParametersDialog: !this.state.showSystemParametersDialog });
    };

    toggleSystemStatesDialog = () => {
        this.setState({ showSystemStatesDialog: !this.state.showSystemStatesDialog });
    };

    toggleUpdateEventsDialog = () => {
        this.setState({ showUpdateEventsDialog: !this.state.showUpdateEventsDialog });
    };

    onShowSystemState = () => {
    };

    render() {
        const navigation = this.renderNavigation();
        return (
            <div className='device-details-tabs row margin-bottom-20'>
                <div className='col-md-12'>
                    {navigation}
                </div>
            </div>
        );
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        isDeviceLogsUser: isDiagLogFileManagerSelector(state),
        controlDeviceEligiblePackageVersions: controlDeviceEligiblePackageVersionsSelector(state, ownProps),
        controlDeviceEligibleFileVersions: controlDeviceEligibleFileVersionsSelector(state, ownProps),
        canReadControlDevices: canReadControlDevicesSelector(state),
        canCreateDeviceActions: canCreateDeviceActionsSelector(state),
        canReadSysParamDefinitions: canReadSysParamDefinitionsSelector(state),
        selectedTabId: getSelectedTabIdSelector(state),
        vehicleDevices: getCurrentlySelectedVehicleDevicesSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        setSelectedTabDeviceDetailsSidebar: (tabId) => {
            dispatch(setSelectedTabDeviceDetailsSidebar(tabId));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDetailsTabs);

VehicleDetailsTabs.defaultProps = {
    // props
    vin: '',
    selectedTabId: '',
    isDeviceLogsUser: false,
    canReadSysParamDefinitions: false,
    controlDeviceEligiblePackageVersions: {},
    vehicleDevices: {},
    controlDeviceEligibleFileVersions: {},
    setSelectedTabDeviceDetailsSidebar: noop,
};

VehicleDetailsTabs.propTypes = {
    // props
    vin: PropTypes.string,
    selectedTabId: PropTypes.string,
    isDeviceLogsUser: PropTypes.bool,
    canReadSysParamDefinitions: PropTypes.bool,
    controlDeviceEligiblePackageVersions: PropTypes.object,
    vehicleDevices: PropTypes.object,
    controlDeviceEligibleFileVersions: PropTypes.object,
    setSelectedTabDeviceDetailsSidebar: PropTypes.func,
};
