import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { connect } from 'react-redux';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';

export class MissingUserRoleWarningDialog extends PureComponent {

    renderTitle() {
        return <FormattedMessage id='intl-msg:notSufficientRoles.title'/>;
    }

    renderBody() {
        return ([
                <FormattedMessage id='intl-msg:notSufficientRoles.body.instructions' values={
                    {
                        br: <br/>,
                        officeLink: <a href='https://office.com'>Office 365 Account</a>,
                        azureGroupsLink: <a href='https://account.activedirectory.windowsazure.com/r#/groups'>Azure
                            Groups</a>,
                    }
                }/>,
                <ExpanderPanel className='margin-10'
                               title={<FormattedMessage id='intl-msg:notSufficientRoles.body.rolesTitle'/>}
                               bsStyle='primary'>
                    <div className='margin-5'>
                        {this.renderGroupsTable()}
                    </div>
                    <div className='margin-20'>
                        <FormattedMessage id='intl-msg:notSufficientRoles.body.rolesOverview' values={
                            {
                                br: <br/>,
                            }
                        }/>
                    </div>
                </ExpanderPanel>,
                <FormattedMessage id='intl-msg:notSufficientRoles.body.supportEmail' values={
                    {
                        emailSupport: <a
                            href='mailto:CST_MDM@man.eu?subject=Requesting access to OTA FE&body=Dear MDM Support, I need help to access the OTA FE.'>CST
                            MAN</a>,
                    }
                }/>]
        );
    }

    renderGroupsTable() {
        return (
            <table className='table'>
                <thead>
                <tr>
                    <td>
                        <b><FormattedMessage id='intl-msg:notSufficientRoles.body.group'/></b>
                    </td>
                    <td>
                        <b><FormattedMessage id='intl-msg:notSufficientRoles.body.role'/></b>
                    </td>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        priv_team_mdm
                    </td>
                    <td>
                        *ota_updatemanager
                    </td>
                </tr>
                <tr>
                    <td>
                        priv_team_2nd_level_support
                    </td>
                    <td>
                        *ota_user
                    </td>
                </tr>
                <tr>
                    <td>
                        priv_team_hawkeye
                    </td>
                    <td>
                        *ota_user
                    </td>
                </tr>
                <tr>
                    <td>
                        priv_team_performance
                    </td>
                    <td>
                        *ota_appupdatemanager
                    </td>
                </tr>
                <tr>
                    <td>
                        priv_team_tacho
                    </td>
                    <td>
                        *ota_appupdatemanager
                    </td>
                </tr>
                <tr>
                    <td>
                        priv_team_fota
                    </td>
                    <td>
                        *ota_filetransfermanager
                    </td>
                </tr>
                </tbody>
            </table>
        );
    }

    renderFooter() {
        return (
            <div>
                <a href='/'>
                    <button className='btn btn-default' onClick={this.props.hideModal}>
                        <FormattedMessage id='intl-msg:reload'/>
                    </button>
                </a>
            </div>
        );
    }

    render() {
        const title = this.renderTitle();
        const body = this.renderBody();
        const footer = this.renderFooter();
        return (
            <Dialog className='device-editor-dialog'
                    show={true}
                    showCloseButton={false}
                    onHide={this.props.hideModal}
                    title={title}
                    body={body}
                    footer={footer}
                    useOverflow
            />
        );
    }
}

export default MissingUserRoleWarningDialog;

MissingUserRoleWarningDialog.defaultProps = {
    // props
    // functions
};

MissingUserRoleWarningDialog.propTypes = {
    // props
    // functions
};
