/**
 * Action type for exporting system parameters
 * @type {string}
 */
export const EXPORT_SYS_PARAMS = 'EXPORT_SYS_PARAMS';

/**
 * Action type for importing system parameters
 * @type {string}
 */
export const IMPORT_SYS_PARAMS = 'IMPORT_SYS_PARAMS';

/**
 * Action type for deleting system parameter
 * @type {string}
 */
export const DELETE_SYS_PARAM = 'DELETE_SYS_PARAM';

/**
 * Action creator for exporting system parameters
 * @param {object} sysParams The system parametes to be exported
 * @returns {{type: string, payload: object}} The action for exporting system parameters
 */
export function exportSysParams(sysParams) {
    return {
        type: EXPORT_SYS_PARAMS,
        payload: {
            sysParams,
        },
    };
}

/**
 * Action creator for importing system parameters
 * @param {object} sysParamDefinitions The system parameter definitions to be imported
 * @param {object} sysParamDefaultValues The system parameter default values to be imported
 * @param {object} baseSoftwareVersion The base software version
 * @returns {{type: string, payload: object}} The action for importing system parameters
 */
export function importSysParams(sysParamDefinitions, sysParamDefaultValues, baseSoftwareVersion) {
    return {
        type: IMPORT_SYS_PARAMS,
        payload: {
            sysParamDefinitions,
            sysParamDefaultValues,
            baseSoftwareVersion,
        },
    };
}

/**
 * Action creator for deleting system parameter
 * @param {object} sysParamDefinition The system parameter definition to be deleted
 * @param {object} sysParamDefaultValue The system parameter default value to be deleted
 * @returns {{type: string, payload: object}} The action for deleting system parameter
 */
export function deleteSysParam(sysParamDefinition, sysParamDefaultValue) {
    return {
        type: DELETE_SYS_PARAM,
        payload: {
            sysParamDefinition,
            sysParamDefaultValue,
        },
    };
}
