import flow from 'lodash/fp/flow';
import pick from 'lodash/fp/pick';
import { call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';

import { getDeliverableManagementBackend } from '~/features/base/selectors/backendSelectors';
import { stringifyToQuery } from '~/features/base/utils/query';

import { deleteHTTP, doHandleErrorSaga, getHTTP, postHTTP } from '~/features/base/sagas/sagaUtil';

import {
    ENTITY_DELIVERABLE,
    ENTITY_DELIVERABLES_CONTEXT_INFO,
    ENTITY_DELIVERABLES_USER_INFO,
} from '~/features/base/constants/entities';
import { failedAction, finishedAction } from '~/features/higherorder/transforms/actionTransforms';
import {
    ADD_NEW_CONTEXT,
    CREATE_DELIVERABLE,
    DELETE_DELIVERABLE,
    FETCH_DELIVERABLES_USER_INFO,
    FETCH_FILTERED_DELIVERABLES,
    fetchDeliverablesUserInfo,
    setDeliverables,
} from '~/features/artifacts/actions/deliverableActions';
import { mergeEntity } from '~/features/higherorder/actions/entityActions';
import { endLoading, startLoading } from '~/features/higherorder/actions/loadingActions';
import { hideModal } from '~/features/base/actions/ui/modalsActions';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import { uploadDeliverableFile } from '~/features/artifacts/actions/deliverableFileActions';
import { profileSelector } from '~/features/login/selectors';

export function* getDeliverablesURL() {
    const serviceURL = yield select(getDeliverableManagementBackend);
    return `${serviceURL}/deliverables/v1`;
}

export function* getDeliverablesContextURL() {
    const serviceURL = yield select(getDeliverableManagementBackend);
    return `${serviceURL}/context/v1`;
}

export function* getDeliverablesUserInfoURL() {
    const serviceURL = yield select(getDeliverableManagementBackend);
    return `${serviceURL}/user/v1`;
}

export function* doFetchFilteredDeliverablesSaga(action) {
    yield put(startLoading(ENTITY_DELIVERABLE));
    try {
        const url = yield call(getDeliverablesURL);
        const payload = action.payload;
        const searchCriteria = {
            ...payload.searchCriteria,
            type: payload.searchCriteria.type.toUpperCase(),
        };
        const query = flow(pick(['page']), stringifyToQuery)(payload);
        const response = yield call(postHTTP, `${url}/search?${query}`,
            JSON.stringify(searchCriteria));
        yield put(setDeliverables(action.payload.searchCriteria.type, response));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_DELIVERABLE));
    yield put(finishedAction(action.type));
}

export function* doFetchDeliverablesUserInfoSaga(action) {
    yield put(startLoading(ENTITY_DELIVERABLES_USER_INFO));
    try {
        const url = yield call(getDeliverablesUserInfoURL);
        const response = yield call(getHTTP, `${url}/current`);
        yield put(mergeEntity(response, { entityName: ENTITY_DELIVERABLES_USER_INFO }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_DELIVERABLES_USER_INFO));
    yield put(finishedAction(action.type));
}

export function* doCreateDeliverableSaga(action) {
    try {
        const url = yield call(getDeliverablesURL);
        const file = action.payload.file;
        delete action.payload.file;
        action.payload = {
            ...action.payload,
            type: action.payload.type.toUpperCase(),
        };
        const response = yield call(postHTTP, url, JSON.stringify(action.payload));
        if (file) {
            yield put(uploadDeliverableFile(response.deliverableId, file));
        } else {
            yield put(hideModal());
            yield put(triggerDataFetcher());
        }
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doDeleteDeliverableSaga(action) {
    try {
        const url = yield call(getDeliverablesURL);
        const deliverableId = action.payload.deliverableId;
        yield call(deleteHTTP, `${url}/${deliverableId}`);
        yield put(hideModal());
        yield put(triggerDataFetcher());
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doAddNewContextSaga(action) {
    yield put(startLoading(ENTITY_DELIVERABLES_CONTEXT_INFO));
    const userProfile = yield select(profileSelector);
    try {
        const url = yield call(getDeliverablesContextURL);
        const payload = {
            name: action.newContextName,
            owners: [
                userProfile.unique_name,
            ],
        };
        yield call(postHTTP, url, JSON.stringify(payload));
        yield put(fetchDeliverablesUserInfo());
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_DELIVERABLES_CONTEXT_INFO));
    yield put(finishedAction(action.type));
}

export function* fetchFilteredDeliverablesSaga() {
    yield takeLatest(FETCH_FILTERED_DELIVERABLES, doFetchFilteredDeliverablesSaga);
}

export function* fetchDeliverablesUserInfoSaga() {
    yield takeLatest(FETCH_DELIVERABLES_USER_INFO, doFetchDeliverablesUserInfoSaga);
}

export function* createDeliverableSaga() {
    yield takeEvery(CREATE_DELIVERABLE, doCreateDeliverableSaga);
}

//
// export function* updateFileDeliverableSaga() {
//     yield takeEvery(UPDATE_FILE_DELIVERABLE, doUpdateFileDeliverableSaga);
// }

export function* deleteDeliverableSaga() {
    yield takeEvery(DELETE_DELIVERABLE, doDeleteDeliverableSaga);
}

export function* addNewContextSaga() {
    yield takeLatest(ADD_NEW_CONTEXT, doAddNewContextSaga);
}


