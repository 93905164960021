import get from 'lodash/fp/get';

import {
    entityStoreSelector,
    aggregationStoreSelector,
} from '~/features/higherorder/selectors/dataSelectors';
import { ENTITY_PACKAGE } from '~/features/base/constants/entities';

const packageAggregationsSelector = state => aggregationStoreSelector(state)[ENTITY_PACKAGE];
export const packageEntitiesSelector = state => entityStoreSelector(state)[ENTITY_PACKAGE];

export const packageCountSelector = state => get('totalCount', packageAggregationsSelector(state));

