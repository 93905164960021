import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import PropTypes from 'prop-types';
import noop from 'lodash/fp/noop';

export class DeliverableRolloutTrackingFormGroup extends Component {

    render() {
        const { onCheckTrackingRollout, onTargetsEstimation, model, deliverableType } = this.props;

        return (
            <ExpanderPanel
                className={`${model.rolloutTracking?.trackingEnabled} margin-bottom-15`}
                titleClassName={model.rolloutTracking?.trackingEnabled}
                open
                title={<FormattedMessage id='intl-msg:deliverableRollout.title' />} bsStyle='default' >

                <div className=' display-flex flex-row justify-content-between align-items-center' >
                    <div className='margin-bottom-15 control-label'>
                        <Checkbox onClick={onCheckTrackingRollout} checked={model.rolloutTracking?.trackingEnabled}>
                            <label className='control-label'>
                                <FormattedMessage id='intl-msg:deliverableRollout.enableTracking' />
                            </label>

                        </Checkbox>
                    </div>

                    <div className=' form-group display-flex flex-row '>
                        <label className='control-label align-self-center padding-left-10 padding-right-10 margin-bottom-0'>
                            <FormattedMessage id='intl-msg:deliverableRollout.targetEstimation' />
                        </label>
                        <div >
                            <FormattedMessage id='intl-msg:deliverableRollout.targetEstimation.prompt'>
                                {placeHolder =>
                                    <input type='number' className='form-control'
                                        value={model.rolloutTracking?.targetsEstimation}
                                        onChange={onTargetsEstimation}
                                        placeholder={placeHolder}
                                        disabled={!model.rolloutTracking?.trackingEnabled}
                                        min={1}
                                    />
                                }
                            </FormattedMessage>
                        </div>
                    </div>


                </div >
                <div className='display-flex align-items-center text-color-primary'>
                    <span className='rioglyph rioglyph-warning-sign text-size-12 margin-right-5' aria-hidden='true' />
                    <span className='text-size-12 '>
                        <FormattedMessage id='intl-msg:deliverableRollout.disclaimer' values={{
                            deliverableType: `${deliverableType}s`,
                        }} />
                    </span >
                </div>
            </ExpanderPanel>
        );
    }
}

export default DeliverableRolloutTrackingFormGroup;

DeliverableRolloutTrackingFormGroup.defaultProps = {
    //  props
    model: {},
    deliverableType: '',
    // functions
    onCheckTrackingRollout: noop,
    onTargetsEstimation: noop,

};

DeliverableRolloutTrackingFormGroup.propTypes = {
    // props
    model: PropTypes.object,
    deliverableType: PropTypes.string,
    // functions
    onCheckTrackingRollout: PropTypes.func,
    onTargetsEstimation: PropTypes.func,
};

//onCheckTrackingRollout, onTargetsEstimation, model, deliverableType