import { call, put, takeLatest } from 'redux-saga/effects';

import { showModal } from '~/features/base/actions/ui/modalsActions';
import {
    changeDeliverableVersionEditor,
    resetDeliverableVersionEditor,
    SHOW_DELIVERABLE_VERSION_EDITOR_DIALOG,
} from '~/features/deliverables/actions/deliverableActions';
import { DELIVERABLE_VERSION_EDITOR_MODAL } from '~/features/base/constants/modals';

export function* doPrepareForNewDeliverableVersionSaga(fileDeliverable) {
    yield put(changeDeliverableVersionEditor({
        isNew: true,
        ...fileDeliverable,
    }));
}

export function* doShowDeliverableVersionEditorDialogSaga(action) {
    yield put(resetDeliverableVersionEditor());
    if (action.payload.isNew) {
        yield call(doPrepareForNewDeliverableVersionSaga, action.payload);
        // } else {
        //     yield call(doPrepareForExistingDeliverableSaga, action.payload);
    }

    // yield call(doFetchFilteredGroupsSaga, fetchFilteredGroups());

    yield put(showModal({
        modalType: DELIVERABLE_VERSION_EDITOR_MODAL,
        modalProps: {},
    }));
}

export function* showDeliverableVersionEditorDialogSaga() {
    yield takeLatest(SHOW_DELIVERABLE_VERSION_EDITOR_DIALOG, doShowDeliverableVersionEditorDialogSaga);
}
