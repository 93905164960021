// export const FETCH_CONTROL_DEVICE_ROLLOUT_STATES = 'FETCH_CONTROL_DEVICE_ROLLOUT_STATES';
export const SET_SHOW_VEHICLE_DETAILS_SIDEBAR = 'SET_SHOW_VEHICLE_DETAILS_SIDEBAR';
// export const SET_SELECTED_TAB_DEVICE_DETAILS_SIDEBAR = 'SET_SELECTED_TAB_DEVICE_DETAILS_SIDEBAR';
export const TOGGLE_VEHICLE_DETAILS_SIDEBAR = 'TOGGLE_VEHICLE_DETAILS_SIDEBAR';
export const HIDE_VEHICLE_DETAILS_SIDEBAR = 'HIDE_VEHICLE_DETAILS_SIDEBAR';
// export const FETCH_DEVICE_STATUS_NAMES = 'FETCH_DEVICE_STATUS_NAMES';
// export const FETCH_DEVICE_STATUS_TYPES = 'FETCH_DEVICE_STATUS_TYPES';

export function setShowVehicleDetailsSidebar(vin) {
    return {
        type: SET_SHOW_VEHICLE_DETAILS_SIDEBAR,
        vin,
    };
}

// export function setSelectedTabDeviceDetailsSidebar(tabId) {
//     return {
//         type: SET_SELECTED_TAB_DEVICE_DETAILS_SIDEBAR,
//         tabId,
//     };
// }

export function toggleVehicleDetailsSidebar() {
    return {
        type: TOGGLE_VEHICLE_DETAILS_SIDEBAR,
    };
}

export function hideVehicleDetailsSidebar() {
    return {
        type: HIDE_VEHICLE_DETAILS_SIDEBAR,
    };
}

// export const fetchControlDeviceRolloutStates = ({ serialNumber, limit }) => {
//     return {
//         type: FETCH_CONTROL_DEVICE_ROLLOUT_STATES,
//         payload: {
//             serialNumber,
//             limit,
//         },
//     };
// };
//
// export const fetchDeviceStatusNames = () => {
//     return {
//         type: FETCH_DEVICE_STATUS_NAMES,
//     };
// };
//
// export const fetchDeviceStatusTypes = () => {
//     return {
//         type: FETCH_DEVICE_STATUS_TYPES,
//     };
// };
