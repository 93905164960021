export const FETCH_USER = 'FETCH_USER';
export const SHOW_MISSING_USER_ROLE_WARNING_DIALOG = 'SHOW_MISSING_USER_ROLE_WARNING_DIALOG';

export function fetchUser() {
    return {
        type: FETCH_USER,
    };
}

export function showMissingUserRoleWarningDialog(payload) {
    return {
        type: SHOW_MISSING_USER_ROLE_WARNING_DIALOG,
        payload,
    };
}
