import React, { PureComponent } from 'react';
import ContentLoader from '@rio-cloud/rio-uikit/lib/es/ContentLoader';

class ReportsBillingInfoBoxContentLoader extends PureComponent {
    render() {
        return (
            <>
                <div className={'display-flex'}>
                    <div>
                        <ContentLoader height={40} width={40}/>
                    </div>
                    <div className={'margin-left-15'}>
                        <ContentLoader/>
                        <ContentLoader/>
                    </div>
                </div>
                <ContentLoader/>
                <ContentLoader/>
                <ContentLoader/>
            </>
        );
    }
}

export default ReportsBillingInfoBoxContentLoader;
