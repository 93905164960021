import includes from 'lodash/fp/includes';
import uniq from 'lodash/fp/uniq';
import without from 'lodash/fp/without';

import {
    MERGE_SELECT,
    MERGE_SELECT_ALL,
} from '~/features/higherorder/actions/selectionActions';

const acceptedActions = [MERGE_SELECT, MERGE_SELECT_ALL];

const defaultState = {
    selectedIds: [],
    selectedAll: false,
};
const mergeSelect = (state, action) => {
    const newState = {
        ...state,
        selectedIds: action.select ? uniq([
            ...state.selectedIds,
            action.id,
        ]) : without([action.id], state.selectedIds),
    };
    return newState;
};
const mergeSelectAll = (state, action) => {
    const newState = {
        ...state,
        selectedIds: [],
        selectedAll: action.selectAll,
    };
    return newState;

};

export const selectionReducer = (state, action) => {
    if (action.type === MERGE_SELECT) {
        return mergeSelect(state, action);
    } else if (action.type === MERGE_SELECT_ALL) {
        return mergeSelectAll(state, action);
    }
    return state;
};
export const createSelectionReducer = (entityName) => {
    return (state = defaultState, action = {}) => {
        if (includes(action.type, acceptedActions)) {
            if (action.entityName === entityName) {
                return selectionReducer(state, action);
            }
        }
        return state;
    };
};

export default createSelectionReducer;
