import { accessToken } from '~/features/tokenHandling/accessToken';

export const prepareHeaders = (headers: Headers) => {
    const token = accessToken.getAccessToken();
    if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('Content-Type', 'application/json');
    }
    return headers;
};
