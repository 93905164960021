import { createSelector } from 'reselect';

import { ENTITY_VEHICLE_SPARE_PART_NUMBERS_ALL } from '~/features/base/constants/entities';
import { entityStoreSelector } from '~/features/higherorder/selectors/dataSelectors';

const vehicleSparePartNumberEntitiesSelector = state => entityStoreSelector(state)[ENTITY_VEHICLE_SPARE_PART_NUMBERS_ALL];

export const vehicleSparePartNumbersSelector = createSelector(
    [vehicleSparePartNumberEntitiesSelector],
    (vehicleSparePartNumbers) => {
        return vehicleSparePartNumbers;
    },
);
