import noop from 'lodash/fp/noop';
import split from 'lodash/fp/split';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { DELIVERABLES_DISTROS_PATH, DELIVERABLES_PATH } from '~/features/base/constants/routes';
import DeliverableTypeTabs from '~/features/deliverables/components/DeliverableTypeTabs';
import DeliverableListContainer from '~/features/deliverables/components/DeliverableListContainer';
import { parseQuery } from '~/features/base/utils/query';
import {
    fetchDeliverablesUserInfo,
    fetchFilteredDeliverableIds,
    showDeliverablePermissionsDialog,
} from '~/features/deliverables/actions/deliverableActions';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
    deliverableIdsTotalCountSelector,
    deliverableTypeSelector,
} from '~/features/deliverables/selectors/deliverableSelectors';
import { getPathWithoutDeliverabeIdAndVersion } from '~/features/deliverables/utils/utils';
import uid from '~/features/base/utils/uid';
import { FETCH_DELIVERABLES_AMOUNT_INCREMENT } from '~/features/deliverables/constants/deliverablesParameters';
import { toSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import { unRegisterAllDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import isEmpty from 'lodash/fp/isEmpty';

/**
 * Component for all artifacts
 */
export class DeliverableDetails extends PureComponent {
    constructor(props) {
        super(props);
        this.name = uid();
    }

    setDefaultDeliverablesRoute = () => {
        const { pathname, match: { params } } = this.props;

        if (!params.deliverableType) {
            this.props.followRoute({
                    route: `${pathname}${DELIVERABLES_DISTROS_PATH}`,
                },
            );
        }
    };
    getSelectedDeliverableId = () => {
        const { match } = this.props;
        const { deliverableId } = match.params;
        return deliverableId;
    };

    getDeliverableVersion = () => {
        const { search, match } = this.props;
        const { deliverableVersion } = match.params;

        const { deliverableVersion: deliverableVersionSearch } = parseQuery(search);
        if (!isEmpty(deliverableVersionSearch) && isEmpty(deliverableVersion)) {
            return deliverableVersionSearch;
        }
        return deliverableVersion;
    };

    onLoadMore = () => {
        const { deliverableType, match, pathname, search } = this.props;
        const query = parseQuery(search);
        const nextQuerySize = !query.size ? FETCH_DELIVERABLES_AMOUNT_INCREMENT * 2 : Number(query.size) > 0 ? Number(
            query.size) + FETCH_DELIVERABLES_AMOUNT_INCREMENT : FETCH_DELIVERABLES_AMOUNT_INCREMENT;
        const querySize = nextQuerySize > this.props.deliverableIdsTotalCount ? this.props.deliverableIdsTotalCount
            : nextQuerySize;
        const newPage = 0;
        this.props.followRoute({
            route: pathname, query: {
                ...query,
                page: newPage,
                size: querySize,
            },
        });
        const { context, deliverableIdPrefix, releaseState, compatibilityCriteria } = query;
        const releaseStateValue = (releaseState && releaseState !== 'all') ? [releaseState] : undefined;
        const { deliverableId } = match.params;
        this.props.fetchFilteredDeliverableIds(newPage, querySize, deliverableType, context, deliverableId,
            deliverableIdPrefix,
            releaseStateValue, compatibilityCriteria);
    };

    render() {
        const content = this.renderContent();
        return (
            <div className='file-details row'>
                {content}
            </div>
        );
    }

    renderContent() {
        const { deliverableType, search } = this.props;
        const deliverableId = this.getSelectedDeliverableId();
        const deliverableVersion = this.getDeliverableVersion();
        const tabs = this.renderTabs();

        return [
            <div key={'tabs'} className='col-md-10'>{tabs}</div>,
            <div key={'permissions'} className='col-md-2 text-right'>
                <a id='grant-permissions-button'
                   className='btn btn-default'
                   onClick={this.props.onDeliverablePermissionsDialog}>
                    <span className='rioglyph rioglyph-user' aria-hidden='true'></span>&nbsp;
                    <FormattedMessage id='intl-msg:permissionsDeliverables'/>
                </a>
            </div>,
            <DeliverableListContainer key={'container'} deliverableType={deliverableType}
                                      selectedDeliverableId={deliverableId}
                                      selectedDeliverableVersion={deliverableVersion} search={search}
                                      onLoadMore={this.onLoadMore}/>,
        ];
    }

    getDeliverableType() {
        const { pathname } = this.props;
        const pathNameWithoutDeliverableIdAndVersion = getPathWithoutDeliverabeIdAndVersion(pathname);
        return split(/[/ ]+/, pathNameWithoutDeliverableIdAndVersion).pop();
    }

    renderTabs() {
        return (
            <DeliverableTypeTabs
                basePath={`/${DELIVERABLES_PATH}`}
                view={this.getDeliverableType()}/>
        );
    }

    registerDataInterest() {
        const { deliverableType, match, search, page, size } = this.props;
        const parsedQuery = parseQuery(search);
        const { context, deliverableIdPrefix, releaseState, compatibilityCriteria, baseSoftwareVersion } = parsedQuery;
        const releaseStateValue = (releaseState && releaseState !== 'all') ? [releaseState] : undefined;
        const distroBaseSoftwareVersion = (baseSoftwareVersion && baseSoftwareVersion !== 'all') ? toSemanticVersion(baseSoftwareVersion) : undefined;
        const { deliverableId } = match.params;

        this.props.registerDataInterest(this.name, [
            fetchFilteredDeliverableIds({
                page: page,
                size: size + FETCH_DELIVERABLES_AMOUNT_INCREMENT,
                searchCriteria: {
                    deliverableType,
                    owningContext: context,
                    deliverableId,
                    deliverableIdPrefix,
                    releaseStates: releaseStateValue,
                    compatibilityCriteria,
                    distroBaseSoftwareVersion,
                },
            }),
            fetchDeliverablesUserInfo(),
        ]);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { deliverableType, match, search } = this.props;
        const parsedQuery = parseQuery(search);
        const {
            page,
            size,
            context,
            deliverableIdPrefix,
            releaseState,
            baseSoftwareVersion,
            compatibilityCriteria,
        } = parsedQuery;
        const { deliverableId } = match.params;
        if (prevProps.deliverableType !== deliverableType || prevProps.search !== search || prevProps.match.params.deliverableId !== deliverableId) {
            this.props.fetchFilteredDeliverableIds(page, size, deliverableType, context, deliverableId, deliverableIdPrefix,
                releaseState, baseSoftwareVersion, compatibilityCriteria);
            this.props.cleanDataInterest();
            this.registerDataInterest();
        }
    }

    componentWillMount() {
        this.registerDataInterest();
        this.props.triggerDataFetcher();
        this.setDefaultDeliverablesRoute();
    }

    componentWillUnmount() {
        this.props.unregisterDataInterest(this.name);
    }
}

const mapStateToProps = (state) => ({
    deliverableType: deliverableTypeSelector(state),
    deliverableIdsTotalCount: deliverableIdsTotalCountSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchFilteredDeliverableIds: (page, size, type, context, deliverableId, deliverableIdPrefix, releaseState,
        baseSoftwareVersion, compatibilityCriteria) => {
        const { singleReleaseState, releaseStateValue } = (releaseState && releaseState !== 'all')
            ? { singleReleaseState: releaseState, releaseStateValue: [releaseState] } : {
                singleReleaseState: undefined,
                releaseStateValue: undefined,
            };
        const {
            singleDistroBaseSoftwareVersion,
            distroBaseSoftwareVersionValue,
        } = (baseSoftwareVersion && baseSoftwareVersion !== 'all')
            ? {
                singleDistroBaseSoftwareVersion: baseSoftwareVersion,
                distroBaseSoftwareVersionValue: toSemanticVersion(baseSoftwareVersion),
            } : {
                singleDistroBaseSoftwareVersion: undefined,
                distroBaseSoftwareVersionValue: undefined,
            };
        dispatch(fetchFilteredDeliverableIds(
            {
                page: Number(page),
                size: Number(size),
                releaseState: singleReleaseState,
                distroBaseSoftwareVersion: singleDistroBaseSoftwareVersion,
                deliverableIdPrefix,
                context,
                searchCriteria: {
                    deliverableType: type,
                    owningContext: context,
                    deliverableId,
                    deliverableIdPrefix: deliverableIdPrefix ? deliverableIdPrefix : undefined,
                    releaseStates: releaseStateValue,
                    distroBaseSoftwareVersion: distroBaseSoftwareVersionValue,
                    compatibilityCriteria,
                },
            },
        ));
    },
    onDeliverablePermissionsDialog: () => {
        dispatch(showDeliverablePermissionsDialog());
    },
    cleanDataInterest: () => {
        dispatch(unRegisterAllDataInterest());
    },

});

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableDetails);

DeliverableDetails.defaultProps = {
    // props
    size: FETCH_DELIVERABLES_AMOUNT_INCREMENT,
    page: 0,
    pathname: '',
    canReadFiles: false,
    canUpdateFiles: false,
    canDeleteFiles: false,
    fileId: '',
    // functions
    fetchFilteredDeliverables: noop,
    fetchFilteredDeliverableIds: noop,
    onDeliverablePermissionsDialog: noop,
    cleanDataInterest: noop,
    // registerDataInterest: noop,
    // unregisterDataInterest: noop,
    // triggerDataFetcher: noop,
    onEditFile: noop,
    onDeleteFile: noop,
};

DeliverableDetails.propTypes = {
    // props
    size: PropTypes.number,
    page: PropTypes.number,
    pathname: PropTypes.string,
    canReadFiles: PropTypes.bool,
    canUpdateFiles: PropTypes.bool,
    canDeleteFiles: PropTypes.bool,
    // functions
    fetchFilteredDeliverables: PropTypes.func,
    fetchFilteredDeliverableIds: PropTypes.func,
    onDeliverablePermissionsDialog: PropTypes.func,
    cleanDataInterest: PropTypes.func,
    // registerDataInterest: PropTypes.func,
    // unregisterDataInterest: PropTypes.func,
    // triggerDataFetcher: PropTypes.func,
    onEditFile: PropTypes.func,
    onDeleteFile: PropTypes.func,
};
