import React from 'react';
import RolloutsTile from '~/features/overviewNew/components/RolloutsTile';
import { FormattedMessage } from 'react-intl';

const AdditionalInfo: React.FC = () => {
    return (
        <div className='additional-info margin-top-50'>
            <div className=''>

                <h1 style={{ fontFamily: 'MANEUROPECONDENSED' }}><FormattedMessage
                    id={'intl-msg:overview.additionalInfo'}/></h1>

                <div className={'text-size-h5'} style={{ fontFamily: 'MANEUROPECONDENSED' }}><FormattedMessage
                    id={'intl-msg:overview.forAllVehicles'}/></div>
            </div>

            <div className='display-grid margin-top-20'>
                <div className='display-grid gap-20 grid-cols-12'>

                    <div className='padding-20  grid-colspan-4 bg-white'>
                        {
                            //Logs information
                        }
                    </div>
                    <div className='padding-20 grid-colspan-8 bg-white'>
                        <div>
                            <RolloutsTile/>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    );
};

export default AdditionalInfo;
