import find from 'lodash/fp/find';
import noop from 'lodash/fp/noop';
import size from 'lodash/fp/size';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { accessKeyHasPlannedValue } from '~/features/sysParams/constants/sysParamGroups';

import { deviceSysParamColumnDescriptors } from '~/features/sysParams/constants/sysParamColumnDescriptors';

import ListTable from '~/features/base/components/table/ListTable';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';

/**
 * Table component for system parameters
 */
export class DeviceSysParamsTable extends PureComponent {
    constructor(props) {
        super(props);
        this.actionsColumn = {
            id: 'actions',
            field: 'accessKey',
            className: 'table-action',
            format: (field, item) => {
                const items = [];
                const {
                    canCreateSysParamSystemValue,
                    canUpdateSysParamSystemValue,
                    canDeleteSysParamSystemValue,
                } = this.props;
                if (accessKeyHasPlannedValue(item.accessKey)) {
                    if (item.systemValue && canUpdateSysParamSystemValue) {
                        items.push({
                            value: (
                                <div className='' onClick={this.onEditButtonClick} data-key={field}>
                                    <span className='rioglyph rioglyph-pencil margin-right-10'></span>
                                    <span><FormattedMessage id='intl-msg:changePlannedValue'/></span>
                                </div>
                            ),
                        });
                    }
                    if (item.systemValue && canDeleteSysParamSystemValue) {
                        items.push({
                            value: (
                                <div onClick={this.onDeleteButtonClick} data-key={field}>
                                    <span className='rioglyph rioglyph-trash margin-right-10'></span>
                                    <span><FormattedMessage id='intl-msg:clearPlannedValue'/></span>
                                </div>
                            ),
                        });
                    }
                    if (!item.systemValue && canCreateSysParamSystemValue) {
                        items.push({
                            value: (
                                <div onClick={this.onEditButtonClick} data-key={field}>
                                    <span className='rioglyph rioglyph-pencil margin-right-10'></span>
                                    <span><FormattedMessage id='intl-msg:definePlannedValue'/></span>
                                </div>
                            ),
                        });
                    }
                    if (size(items)) {
                        return (
                            <ButtonDropdown
                                title={<span className='rioglyph rioglyph-option-vertical' />}
                                className='pull-right'
                                bsStyle='link'
                                iconOnly
                                items={items}/>
                        );
                    }
                }
            },
            formatLabel: () => '',
        };
    }

    onEditButtonClick = (event) => {
        const key = parseInt(event.currentTarget.getAttribute('data-key'), 10);
        const sysParam = find(item => item.accessKey === key, this.props.sysParams);
        this.props.onEditSysParamSystemValue(sysParam);
    }

    onDeleteButtonClick = (event) => {
        const key = parseInt(event.currentTarget.getAttribute('data-key'), 10);
        const sysParam = find(item => item.accessKey === key, this.props.sysParams);
        this.props.onDeleteSysParamSystemValue(sysParam);
    }

    render() {
        const { sysParams } = this.props;
        const columns = [...deviceSysParamColumnDescriptors, this.actionsColumn];
        return [
            <ListTable items={sysParams}
                itemKey='accessKey'
                columnDescriptors={columns}/>,
        ];
    }
}

export default DeviceSysParamsTable;

DeviceSysParamsTable.defaultProps = {
    // props
    canCreateSysParamSystemValue: false,
    canUpdateSysParamSystemValue: false,
    canDeleteSysParamSystemValue: false,
    sysParams: [],
    // functions
    onEditSysParamSystemValue: noop,
    onDeleteSysParamSystemValue: noop,
};

DeviceSysParamsTable.propTypes = {
    // props
    canCreateSysParamSystemValue: PropTypes.bool,
    canUpdateSysParamSystemValue: PropTypes.bool,
    canDeleteSysParamSystemValue: PropTypes.bool,
    shortBaseSwVersion: PropTypes.string,
    sysParams: PropTypes.array,
    // functions
    onEditSysParamSystemValue: PropTypes.func,
    onDeleteSysParamSystemValue: PropTypes.func,
};
