import {
    CHANGE_JIRA_ISSUE_EDITOR,
    RESET_JIRA_ISSUE_EDITOR,
    UPDATE_EXISTING_JIRA_ISSUES,
} from '~/features/atlassian/actions/atlassianJiraActions';
import { MDMOPS } from '~/features/atlassian/constants/projectNameOptions';

const defaultState = {
    isNew: false,
    isInProgress: false,
    controlDevice: undefined,
    description: '',
    summary: '',
    jiraProjectName: MDMOPS,
    existingJiraIssues: []
};

/**
 * Reducer for atlassian jira editor
 * @param {object} state The current state
 * @param {object} action The action to be processed
 * @returns {object} The new state
 */
export default function atlassianJiraIssueEditorReducer(state = defaultState, action = {}) {
    let newState;
    switch (action.type) {
        case RESET_JIRA_ISSUE_EDITOR:
            newState = {
                ...defaultState,
            };
            return newState;
        case CHANGE_JIRA_ISSUE_EDITOR:
            newState = {
                ...state,
                ...action.payload,
            };
            return newState;
        case UPDATE_EXISTING_JIRA_ISSUES:
            newState = {
                ...state,
                ...action.payload,
            };
            return newState;
        default:
            return state;
    }
}
