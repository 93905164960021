import { Deliverable } from '~/api/models/deliverable';

export const SOP1CLUSTER_VERSION_HIGHER_14_C4U_MINUTES = 15;
export const SOP1CLUSTER_VERSION_BELLOW_14_C4U_MINUTES = 6;
export const SOP1CLUSTER_ID = 'SOP1Cluster';

export function verifyCM4DeviceOffline(timestamp: number, deliverables?: Deliverable[]): boolean {
    const thresholdMinutes = getCM4C4U(deliverables);
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - new Date(timestamp).getTime();
    return timeDifference >= (thresholdMinutes * 60 * 1000);
}

export function getCM4C4U(deliverables?: Deliverable[]) {
    const sOP1ClusterVersion = deliverables?.find(d => d.deliverableId === SOP1CLUSTER_ID)?.deliverableVersion.major;
    return sOP1ClusterVersion && sOP1ClusterVersion < 14 ? SOP1CLUSTER_VERSION_BELLOW_14_C4U_MINUTES : SOP1CLUSTER_VERSION_HIGHER_14_C4U_MINUTES;
}
