import PropTypes from 'prop-types';
import React from 'react';
import Tile from '~/features/overview/components/Tile';
import getOr from 'lodash/fp/getOr';
import Carousel from '@rio-cloud/rio-uikit/lib/es/Carousel';
import Legend from '~/features/overview/components/Legend';
import DonutOld from '~/features/overview/components/DonutOld';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import { FormattedMessage } from 'react-intl';
import DistributionTile from '~/features/overview/components/DistributionTile';
import LiveRolloutsTile from './LiveRolloutsTile';
import {
    APPS_ROLLOUT,
    BUNDLES_ROLLOUT,
    CM4G_ROLLOUT,
    DISTROS_ROLLOUT,
} from '~/features/deliverables/constants/deliverablesRollout.js';

const labelWrapperClasses = 'position-absolute bottom-25 padding-left-20 padding-right-20 bg-darkest';
const labelClasses = 'text-size-20 text-bold text-uppercase text-color-white';

export const CarouselTile = (values) => {
    const tiles = values.values.map((tileDescriptor) => {
        const data = getOr(0, tileDescriptor.dataPath, values.props);
        if (tileDescriptor.id === 'devicesByVehicleTile' || tileDescriptor.id === 'devicesByCountryTile') {
            return <Carousel.Item key={tileDescriptor.id} className='width-100pct'>
                <div className='bg-white bg-tile height-500 width-100pct'>
                    <DistributionTile props={values.props}
                                      tileDescriptor={tileDescriptor}
                    />
                </div>
            </Carousel.Item>;
        } else if (tileDescriptor.id === DISTROS_ROLLOUT || tileDescriptor.id === APPS_ROLLOUT || tileDescriptor.id === CM4G_ROLLOUT || tileDescriptor.id === BUNDLES_ROLLOUT) {
            return <Carousel.Item pause={'hover'} interval={100000} key={tileDescriptor.id} className='width-100pct'>
                <LiveRolloutsTile className='height-500 position-relative'
                                  data={data}
                                  label={tileDescriptor.label}
                                  deliverableType={tileDescriptor.id}
                />
            </Carousel.Item>;
        } else {
            return <Carousel.Item key={tileDescriptor.id} className='width-100pct'>
                <div className='bg-white bg-tile height-500 width-100pct'>
                    <div className='position-relative'>
                        <div
                            className='position-absolute padding-top-15 padding-left-10 padding-right-10 width-100pct z-index-1'>
                            <Legend route={tileDescriptor.link} query={tileDescriptor.query} data={data}/>
                        </div>
                    </div>
                    <div className='display-flex justify-content-center align-items-end height-100pct'>
                        <DonutOld tileDescriptor={tileDescriptor} data={data} animationDuration={0}
                                  onClick={tileDescriptor.onDonutTileClick}/>
                    </div>
                    <div className='position-relative'>
                        <div className={labelWrapperClasses}>
                            <span className={labelClasses}>{tileDescriptor.label}</span>
                        </div>
                    </div>
                </div>
            </Carousel.Item>;
        }
    });
    return (
        <Tile>
            <div
                className='width-100pct height-100pct position-absolute display-flex justify-content-end align-items-end padding-bottom-10 padding-right-20'>
                <span className='btn btn-default btn-link z-index-6 text-size-16'>
                    <OverlayTrigger placement='top' overlay={
                        <Tooltip id='carousel-tile-tooltip' className='top-right'>
                            <FormattedMessage id={'Sliding will be temporarily off on hovering.'}/>
                        </Tooltip>}>
                        <i className='rioglyph rioglyph-info-sign text-size-200pct'/>
                    </OverlayTrigger>
                </span>
            </div>
            <Carousel className={'height-500 show-arrows arrow-color-dark show-indicators shadow-default'}>
                {tiles}
            </Carousel>
        </Tile>
    );
};

export default CarouselTile;

CarouselTile.propTypes = {
    // props
    values: PropTypes.array,
};
