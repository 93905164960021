import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { endLoading, startLoading } from '~/features/higherorder/actions/loadingActions';
import {
    ENTITY_ARTIFACT, ENTITY_DELIVERABLE,
    ENTITY_REPORTS_BILLING,
    ENTITY_REPORTS_BILLING_CHARTS_INITIAL_DATA, ENTITY_REPORTS_BILLING_DETAILS,
} from '~/features/base/constants/entities';
import { doHandleErrorSaga, postHTTP } from '~/features/base/sagas/sagaUtil';
import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';
import { finishedAction } from '~/features/higherorder/transforms/actionTransforms';
import {
    getReportsBillingChartsDataBackend, getReportsBillingChartsDataVersionsBackend,
} from '~/features/base/selectors/backendSelectors';
import {
    FETCH_REPORTS_BILLING_INITIAL_DATA, REMOVE_REPORTS_BILLING_INITIAL_DATA,
} from '~/features/reportsbilling/actions/reportsBillingActions';
import { mergeEntity, resetEntity } from '~/features/higherorder/actions/entityActions';

export function* getReportsBillingChartsDataURL() {
    const serviceURL = yield select(getReportsBillingChartsDataBackend);
    return `${serviceURL}`;
}

export function* getReportsBillingChartsDataVersionsURL() {
    const serviceURL = yield select(getReportsBillingChartsDataVersionsBackend);
    return `${serviceURL}`;
}

export function* doFetchReportsBillingDataSaga(action) {
    yield put(startLoading(ENTITY_REPORTS_BILLING));
    try {
        const url = yield call(getReportsBillingChartsDataURL);
        const payload = action.payload;
        const searchCriteria = {
            ...payload.searchCriteria,
        };
        const query = JSON.stringify(searchCriteria);

        const chartsDataResponse = yield call(postHTTP, `${url}`, JSON.stringify(searchCriteria));
        const response = {
            [ENTITY_REPORTS_BILLING_CHARTS_INITIAL_DATA]: chartsDataResponse,
        };
        if(searchCriteria.totalsOnly){
            yield put(mergeEntity(response, { entityName: ENTITY_REPORTS_BILLING }));
        }
        else{
            yield put(mergeEntity(response, { entityName: ENTITY_REPORTS_BILLING_DETAILS }));
        }
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_REPORTS_BILLING));
    yield put(finishedAction(action.type));
}

export function* doRemoveReportsBillingDataSaga(action) {
    yield put(startLoading(ENTITY_REPORTS_BILLING));
    try {
        yield put(resetEntity({}, { entityName: ENTITY_REPORTS_BILLING_DETAILS }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_REPORTS_BILLING));
    yield put(finishedAction(action.type));
}

export function* fetchReportsBillingChartsDataSaga() {
    yield takeLatest(FETCH_REPORTS_BILLING_INITIAL_DATA, doFetchReportsBillingDataSaga);
}
export function* removeReportsBillingChartsDataSaga() {
    yield takeLatest(REMOVE_REPORTS_BILLING_INITIAL_DATA, doRemoveReportsBillingDataSaga);
}
