export const AUTOMATIC = 'AUTOMATIC';
export const FLEET_EVENT = 'FLEET_EVENT';
export const MANUAL = 'MANUAL';
export const VEHICLE_INFO_EVENT = 'VEHICLE_INFO_EVENT';
export const UNKNOWN = 'UNKNOWN';

export const registrationMethods = {
    [AUTOMATIC]: {
        id: AUTOMATIC,
        label: 'intl-msg:registrationMethod.automatic',
        tooltip: 'intl-msg:registrationMethod.automatic.tooltip',
        className: 'label-primary',
    },
    [FLEET_EVENT]: {
        id: FLEET_EVENT,
        label: 'intl-msg:registrationMethod.fleetEvent',
        tooltip: 'intl-msg:registrationMethod.fleetEvent.tooltip',
        className: 'label-success',
    },
    [MANUAL]: {
        id: MANUAL,
        label: 'intl-msg:registrationMethod.manual',
        tooltip: 'intl-msg:registrationMethod.manual.tooltip',
        className: 'label-danger',
    },
    [VEHICLE_INFO_EVENT]: {
        id: VEHICLE_INFO_EVENT,
        label: 'intl-msg:registrationMethod.vehicleInfoEvent',
        tooltip: 'intl-msg:registrationMethod.vehicleInfoEvent.tooltip',
        className: 'label-primary',
    },
    [UNKNOWN]: {
        id: UNKNOWN,
        label: 'intl-msg:registrationMethod.unknown',
        tooltip: 'intl-msg:registrationMethod.unknown.tooltip',
        className: 'label-primary',
    },
};
