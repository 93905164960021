
export const SUCCESS = 'SUCCESS';
export const FAILED = 'FAILED';
export const ONGOING = 'ONGOING';
export const REBOOT_PENDING = 'REBOOT_PENDING';
export const UNKNOWN = 'UNKNOWN';


export const outcomes = {
    [SUCCESS]: {
        id: SUCCESS,
        label: 'intl-msg:result.success',
        tooltip: 'intl-msg:result.success.tooltip',
        className: 'label-success',
    },
    [FAILED]: {
        id: FAILED,
        label: 'intl-msg:result.failed',
        tooltip: 'intl-msg:result.failed.tooltip',
        className: 'label-warning',
    },
    [ONGOING]: {
        id: ONGOING,
        label: 'intl-msg:result.ongoing',
        tooltip: 'intl-msg:result.ongoing.tooltip',
        className: 'label-info',
    },[REBOOT_PENDING]: {
        id: REBOOT_PENDING,
        label: 'intl-msg:result.rebootPending',
        tooltip: 'intl-msg:result.rebootPending',
        className: 'label-info',
    },[UNKNOWN]: {
        id: UNKNOWN,
        label: 'intl-msg:unknown',
        tooltip: 'intl-msg:unknown',
        className: 'label-info',
    },
};
