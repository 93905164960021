/**
 * Action type for following a given route
 * @type {string}
 */
export const FOLLOW_ROUTE = 'FOLLOW_ROUTE';

/**
 * Action creator to follow a given route
 * @param {object} options.route The route
 * @param {object} options.query The query
 * @returns {{type: string, payload: object}} The action to follow a given route
 */
export function followRoute({ route, query }) {
    return {
        type: FOLLOW_ROUTE,
        payload: {
            options: {
                route,
                query,
            },
        },
    };
}
