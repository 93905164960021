import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import SemanticVersionRangeInputFormItem from '~/features/base/components/forms/SemanticVersionRangeInputFormItem';
import omit from 'lodash/fp/omit';

export class VersionDependenciesEditorForm extends Component {
    onBaseSoftwareVersionRangeChange = (baseSoftwareVersionRange) => {
        const { compatibility } = this.props;
        if (baseSoftwareVersionRange.minValue || baseSoftwareVersionRange.maxValue) {
            this.props.onChange({
                ...compatibility,
                baseSoftwareVersionRange,
            });
        } else {
            this.props.onChange(omit(['baseSoftwareVersionRange'], compatibility));
        }
    };

    onMdmcAppVersionRangeChange = (mdmcAppVersionRange) => {
        const { compatibility } = this.props;
        if (mdmcAppVersionRange.minValue || mdmcAppVersionRange.maxValue) {
            this.props.onChange({
                ...compatibility,
                mdmcAppVersionRange,
            });
        } else {
            this.props.onChange(omit(['mdmcAppVersionRange'], compatibility));
        }
    };

    render() {
        const { compatibility } = this.props;
        let baseSoftwareVersionRange = compatibility.baseSoftwareVersionRange;
        if (!baseSoftwareVersionRange) {
            baseSoftwareVersionRange = {
                minValue: {
                    major: 0,
                    minor: 0,
                    patch: 0,
                },
                maxValue: {
                    major: 0,
                    minor: 0,
                    patch: 0,
                },
            };
        }
        let mdmcAppVersionRange = compatibility.mdmcAppVersionRange;
        if (!mdmcAppVersionRange) {
            mdmcAppVersionRange = {
                minValue: {
                    major: 0,
                    minor: 0,
                    patch: 0,
                },
                maxValue: {
                    major: 0,
                    minor: 0,
                    patch: 0,
                },
            };
        }

        return [
            <SemanticVersionRangeInputFormItem range={baseSoftwareVersionRange}
                                               label={'intl-msg:baseSwVersion'}
                                               minLabel={'intl-msg:atLeastVersion'}
                                               maxLabel={'intl-msg:atMostVersion'}
                                               isClearable onChange={this.onBaseSoftwareVersionRangeChange}/>,
            <SemanticVersionRangeInputFormItem range={mdmcAppVersionRange}
                                               label={'intl-msg:mdmcAppVersion'}
                                               minLabel={'intl-msg:atLeastVersion'}
                                               maxLabel={'intl-msg:atMostVersion'}
                                               isClearable onChange={this.onMdmcAppVersionRangeChange}/>,
        ];
    }
}

export default VersionDependenciesEditorForm;

VersionDependenciesEditorForm.defaultProps = {
    // props
    compatibility: {
        baseSoftwareVersionRange: {
            minValue: {
                major: 0,
                minor: 0,
                patch: 0,
            },
            maxValue: {
                major: 0,
                minor: 0,
                patch: 0,
            },
        },
        mdmcAppVersionRange: {
            minValue: {
                major: 0,
                minor: 0,
                patch: 0,
            },
            maxValue: {
                major: 0,
                minor: 0,
                patch: 0,
            },
        },
    },
    // functions
    onChange: noop,
};

VersionDependenciesEditorForm.propTypes = {
    // props
    compatibility: PropTypes.object,
    // functions
    onChange: PropTypes.func,
};
