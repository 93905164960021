import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import {
    devicesLoadingSelector,
    getCurrentlySelectedDeviceInfoSelector,
} from '~/features/devices/selectors/controlDeviceSelectors';
import DevicePropertiesTable from '~/features/devices/components/DevicePropertiesTable';
import { tbmVcmDeviceRowDescriptors } from '~/features/devices/constants/tbmVcmDeviceRowDescriptors';
import DeviceRolloutStatesContainer from '~/features/devices/components/DeviceRolloutStatesContainer';
import uid from '~/features/base/utils/uid';
import { fetchControlDeviceInformation } from '~/features/devices/actions/controlDeviceInfoActions';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import { defaultNetworkCodeSelector } from '~/features/base/selectors/defaultNetworkCodeSelector';
import { CM4 } from '~/features/devices/constants/deviceTypes';
import { cm4DeviceRowDescriptors } from '~/features/devices/constants/cm4DeviceRowDescriptors';

/**
 * Container for the device properties
 */
export class DevicePropertiesContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.name = uid();
    }

    getErrorMessage = () => {
        return (
            <div className='error-message'>
                <FormattedMessage id='intl-msg:controlDevices.fetch.error'/>
            </div>
        );
    };

    onEditDevice = () => {
        const { serialNumber } = this.props.controlDeviceInfo;
        this.props.showControlDeviceEditor({
            isNew: false,
            serialNumber,
        });
    };

    render() {
        const { controlDeviceInfo, defaultNetworkCodeList } = this.props;
        const controlDeviceData = { ...controlDeviceInfo, networkCodeList: defaultNetworkCodeList };
        const descriptors = controlDeviceInfo.type !== CM4 ? tbmVcmDeviceRowDescriptors : cm4DeviceRowDescriptors;

        return [
            <DevicePropertiesTable
                controlDeviceData={controlDeviceData}
                rowDescriptors={descriptors}/>,
            <div className='padding-top-5'>
                <ExpanderPanel bsStyle='primary' className='margin-0' open={true}
                               title={<FormattedMessage id='intl-msg:rolloutProgress'/>}>
                    <DeviceRolloutStatesContainer deviceType={controlDeviceInfo.type} serialNumber={controlDeviceInfo.serialNumber}/>
                </ExpanderPanel>
            </div>,
        ];
    }

    componentWillMount() {
        this.props.registerDataInterest(this.name, [
            fetchControlDeviceInformation({
                serialNumber: this.props.serialNumber,
            }),
        ]);
    }

    componentWillUnmount() {
        this.props.unregisterDataInterest(this.name);
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        controlDeviceInfo: getCurrentlySelectedDeviceInfoSelector(state, ownProps),
        deviceDetailsLoading: devicesLoadingSelector(state),
        defaultNetworkCodeList: defaultNetworkCodeSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        triggerDataFetcher: () => {
            dispatch(triggerDataFetcher());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DevicePropertiesContainer);

DevicePropertiesContainer.defaultProps = {
    // props
    serialNumber: '',
    controlDeviceData: undefined,
    controlDeviceInfo: undefined,
    defaultNetworkCodeList: undefined,
    deviceDetailsLoading: false,
    // functions
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    triggerDataFetcher: noop,
    showControlDeviceEditor: noop,
};

DevicePropertiesContainer.propTypes = {
    // props
    serialNumber: PropTypes.string,
    controlDeviceData: PropTypes.object,
    controlDeviceInfo: PropTypes.object,
    defaultNetworkCodeList: PropTypes.array,
    deviceDetailsLoading: PropTypes.bool,
    // functions
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
    showControlDeviceEditor: PropTypes.func,
};
