import React from 'react';
import { FormattedMessage } from 'react-intl';

interface DeviceStateSummaryItemProps {
    label: string,
    badge: string,
    message: string,
}

const DeviceStateSummaryItem: React.FC<DeviceStateSummaryItemProps> = ({
    label,
    badge,
    message
}: DeviceStateSummaryItemProps) => {
    return (
        <div>
            <span className={`text-size-16 margin-right-10 ${badge}`}/>
            <FormattedMessage id={label}/>:
            <span className='margin-left-5'>
                    <FormattedMessage id={message}/>
                </span>
        </div>
    );
};

export default DeviceStateSummaryItem;


