import {
    ATLASSIAN_CONNECTOR,
    AUTHENTICATION_SERVICE,
    DELIVERABLE_MANAGEMENT_SERVICE,
    DEVICE_LOG_DOWNLOAD_SERVICE,
    DEVICE_MANAGEMENT_SERVICE,
    DEVICE_SYSTEM_STATE_SERVICE, LOG_SERVICE,
    REPORTS_BILLING_CHARTS_DATA_SERVICE,
    REPORTS_BILLING_CHARTS_DATA_VERSIONS_SERVICE,
} from '~/features/base/constants/services';

const backendSelector = state => state.config.backend;

export const getAuthenticationBackend = state => backendSelector(state)[AUTHENTICATION_SERVICE];
export const getDeviceManagementBackend = state => backendSelector(state)[DEVICE_MANAGEMENT_SERVICE];
export const getDeviceSystemStateBackend = state => backendSelector(state)[DEVICE_SYSTEM_STATE_SERVICE];
export const getDeliverableManagementBackend = state => backendSelector(state)[DELIVERABLE_MANAGEMENT_SERVICE];
export const getDeviceLogDownloadBackend = state => backendSelector(state)[DEVICE_LOG_DOWNLOAD_SERVICE];
export const getAtlassianConnectorBackend = state => backendSelector(state)[ATLASSIAN_CONNECTOR];
export const getReportsBillingChartsDataBackend = state => backendSelector(state)[REPORTS_BILLING_CHARTS_DATA_SERVICE];
export const getReportsBillingChartsDataVersionsBackend = state => backendSelector(state)[REPORTS_BILLING_CHARTS_DATA_VERSIONS_SERVICE];
export const getLogsBackend = state => backendSelector(state)[LOG_SERVICE];
