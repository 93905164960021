import { call, put, select, fork, takeLatest } from 'redux-saga/effects';

import { ENTITY_DELIVERABLE_HISTORY } from '~/features/base/constants/entities';

import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';
import { mergeEntity } from '~/features/higherorder/actions/entityActions';

import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';

import { doHandleErrorSaga, getHTTP } from '~/features/base/sagas/sagaUtil';
import { stringifyToQuery } from '~/features/base/utils/query';


import { finishedAction, failedAction } from '~/features/higherorder/transforms/actionTransforms';
import { FETCH_DELIVERABLE_HISTORY } from '~/features/deliverables/actions/deliverableActions';
import { parseDeliverableHistoryResponse } from '~/features/deliverables/transforms/parseDeliverableHistory';

export function* getDeliverableHistoryURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/deliverable/change-history`;
}

export function* doFetchDeliverableHistorySaga(action) {
    try {
        const deliverableHistoryURL = yield call(getDeliverableHistoryURL);
        const { deliverableId, deliverableVersion, deliverableType } = action.payload;
        const params = {
            page: action.payload.page || 0,
            size: 1,
        };
        const query = stringifyToQuery(params);
        const response = yield call(getHTTP, `${deliverableHistoryURL}/${deliverableType}/${deliverableId}/${deliverableVersion}?${query}`);
        const parsedResponse = parseDeliverableHistoryResponse(response);
        yield put(mergeEntity(parsedResponse, { entityName: ENTITY_DELIVERABLE_HISTORY}));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* fetchDeliverableHistorySaga() {
    yield takeLatest(FETCH_DELIVERABLE_HISTORY, doFetchDeliverableHistorySaga);
}
