import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    ARTIFACT_EDITOR_MODAL,
    ARTIFACT_DELETE_MODAL,
    ARTIFACT_FILE_DELETE_MODAL,
    CONTROL_DEVICE_EDITOR_MODAL,
    CONTROL_DEVICE_ASSOCIATED_DELIVERABLES_MODAL,
    COPY_SYS_PARAM_DEFINITIONS_MODAL,
    DELIVERABLE_DELETE_MODAL,
    DELIVERABLE_DELETE_MODAL_NEO,
    DELIVERABLE_EDITOR_MODAL,
    DELIVERABLE_EDITOR_MODAL_NEO,
    DELIVERABLE_FILE_DELETE_MODAL,
    DELIVERABLE_PERMISSIONS_MODAL,
    DELIVERABLE_VERSION_DELETE_MODAL,
    DELIVERABLE_VERSION_EDITOR_MODAL,
    DEVICE_ACTION_DELETE_MODAL,
    GROUP_DELETE_MODAL,
    GROUP_EDITOR_MODAL,
    IMPORT_SYS_PARAM_DEFINITIONS_MODAL,
    JIRA_ISSUE_EDITOR_MODAL,
    MISSING_USER_ROLE_WARNING_MODAL,
    SERVICE_LOG_LEVEL_MODAL,
    SYS_PARAM_DEFINITION_DELETE_MODAL,
    SYS_PARAM_DEFINITION_EDITOR_MODAL,
    SYS_PARAM_SYSTEM_VALUE_DELETE_MODAL,
    SYS_PARAM_SYSTEM_VALUE_EDITOR_MODAL,
    UPDATE_EVENT_REPORT_MODAL,
    CONTROL_DEVICE_QUICK_WHITELISTING_MODAL,
    DELIVERABLE_PERMISSIONS_MODAL_NEO,
} from '~/features/base/constants/modals';

import { hideModal } from '~/features/base/actions/ui/modalsActions';

import { modalPropsSelector, modalTypeSelector } from '~/features/base/selectors/modalSelectors';

import DeviceEditorDialog from '~/features/devices/components/dialogs/DeviceEditorDialog';

import LogRequestDeletionDialog from '~/features/deviceLogs/components/LogRequestDeletionDialog';

import GroupEditorDialog from '~/features/groups/components/GroupEditorDialog';
import GroupDeletionDialog from '~/features/groups/components/GroupDeletionDialog';

import CopySysParamDefinitionsDialog from '~/features/sysParams/components/CopySysParamDefinitionsDialog';
import ImportSysParamDefinitionsDialog from '~/features/sysParams/components/ImportSysParamDefinitionsDialog';

import SysParamDefinitionEditorDialog from '~/features/sysParams/components/SysParamDefinitionEditorDialog';
import SysParamDefinitionDeletionDialog from '~/features/sysParams/components/SysParamDefinitionDeletionDialog';
import SysParamSystemValueEditorDialog from '~/features/sysParams/components/SysParamSystemValueEditorDialog';
import SysParamSystemValueDeletionDialog from '~/features/sysParams/components/SysParamSystemValueDeletionDialog';

import UpdateEventReportDialog from '~/features/updateEvents/components/UpdateEventReportDialog';
import DeliverableEditorDialog from '~/features/artifacts/components/DeliverableEditorDialog';
import DeliverableDeletionDialog from '~/features/artifacts/components/DeliverableDeletionDialog';
import DeliverableDeletionDialogNeo from '~/features/deliverables/components/DeliverableDeletionDialog';
import DeliverableFileDeletionDialog from '~/features/artifacts/components/DeliverableFileDeletionDialog';
import DeliverablePermissionDialog from '~/features/artifacts/components/DeliverablePermissionDialog';
import DeliverablePermissionDialogNeo from '~/features/deliverables/components/DeliverablePermissionDialog';
import MissingUserRoleWarningDialog from '~/features/user/components/MissingUserRoleWarningDialog';
import JiraIssueEditorDialog from '~/features/atlassian/components/dialogs/JiraIssueEditorDialog';

import DeliverableEditorDialogNeo from '~/features/deliverables/components/DeliverableEditorDialog';
import DeliverableVersionEditorDialog from '~/features/deliverables/components/DeliverableVersionEditorDialog';
import DeliverableVersionDeletionDialog from '~/features/deliverables/components/DeliverableVersionDeletionDialog';
import ArtifactEditorDialog from '~/features/deliverables/features/artifacts/components/ArtifactEditorDialog';
import ArtifactDeletionDialog from '~/features/deliverables/features/artifacts/components/ArtifactDeletionDialog';
import ArtifactFileDeletionDialog
    from '~/features/deliverables/features/artifacts/components/ArtifactFileDeletionDialog';
import DeviceQuickWhitelistingDialog
    from '~/features/devices/components/dialogs/quickWhitelisting/DeviceQuickWhitelistingDialog';
import DeviceAssociatedDeliverablesDialog
    from '~/features/devices/components/dialogs/associatedDeliverables/DeviceAssociatedDeliverablesDialog';

/**
 * Container for device management modals
 */
export class DeviceManagementModals extends PureComponent {
    constructor(props) {
        super(props);
        // Mapping of constants to create functions
        this.createDialogFunctions = {
            [CONTROL_DEVICE_EDITOR_MODAL]: this.getControlDeviceEditorModal.bind(this),
            [CONTROL_DEVICE_QUICK_WHITELISTING_MODAL]: this.getControlDeviceQuickWhitelistingModal.bind(this),
            [CONTROL_DEVICE_ASSOCIATED_DELIVERABLES_MODAL]: this.getDeviceAssociatedDeliverablesModal.bind(this),
            [MISSING_USER_ROLE_WARNING_MODAL]: this.getMissingControlDeviceEditorModal.bind(this),
            [DEVICE_ACTION_DELETE_MODAL]: this.getDeviceActionDeleteModal.bind(this),
            [SERVICE_LOG_LEVEL_MODAL]: this.getEditServiceLogLevelModal.bind(this),
            [DELIVERABLE_EDITOR_MODAL]: this.getDeliverableEditorModal.bind(this),
            [ARTIFACT_EDITOR_MODAL]: this.getArtifactEditorModal.bind(this),
            [ARTIFACT_DELETE_MODAL]: this.getArtifactDeletionModal.bind(this),
            [ARTIFACT_FILE_DELETE_MODAL]: this.getArtifactFileDeletionModal.bind(this),
            [DELIVERABLE_EDITOR_MODAL_NEO]: this.getDeliverableEditorModalNeo.bind(this),
            [DELIVERABLE_VERSION_EDITOR_MODAL]: this.getDeliverableVersionEditorModal.bind(this),
            [DELIVERABLE_DELETE_MODAL]: this.getDeliverableDeletionModal.bind(this),
            [DELIVERABLE_DELETE_MODAL_NEO]: this.getDeliverableDeletionModalNeo.bind(this),
            [DELIVERABLE_VERSION_DELETE_MODAL]: this.getDeliverableVersionDeletionModal.bind(this),
            [DELIVERABLE_FILE_DELETE_MODAL]: this.getDeliverableFileDeletionModal.bind(this),
            [DELIVERABLE_PERMISSIONS_MODAL]: this.getDeliverablePermissionModal.bind(this),
            [DELIVERABLE_PERMISSIONS_MODAL_NEO]: this.getDeliverablePermissionModalNeo.bind(this),
            [GROUP_EDITOR_MODAL]: this.getGroupEditorModal.bind(this),
            [GROUP_DELETE_MODAL]: this.getGroupDeleteModal.bind(this),
            [SYS_PARAM_DEFINITION_EDITOR_MODAL]: this.getSysParamDefinitionEditorModal.bind(this),
            [SYS_PARAM_DEFINITION_DELETE_MODAL]: this.getSysParamDefinitionDeleteModal.bind(this),
            [SYS_PARAM_SYSTEM_VALUE_EDITOR_MODAL]: this.getSysParamSystemValueEditorModal.bind(this),
            [SYS_PARAM_SYSTEM_VALUE_DELETE_MODAL]: this.getSysParamSystemValueDeleteModal.bind(this),
            [COPY_SYS_PARAM_DEFINITIONS_MODAL]: this.getCopySysParamsModal.bind(this),
            [IMPORT_SYS_PARAM_DEFINITIONS_MODAL]: this.getImportSysParamsModal.bind(this),
            [UPDATE_EVENT_REPORT_MODAL]: this.getUpdateEventReportModal.bind(this),
            [JIRA_ISSUE_EDITOR_MODAL]: this.getJiraIssueModal.bind(this),
        };
    }

    render() {
        const modalDialog = this.renderModalDialog();
        const result = (
            <div className='devicemanagement-modals'>
                {modalDialog}
            </div>
        );
        return result;
    }

    renderModalDialog() {
        const createDialogFunction = this.getCreateDialogFunction(this.props.modalType);
        if (createDialogFunction) {
            return createDialogFunction();
        }
    }

    getCreateDialogFunction(modalType) {
        return this.createDialogFunctions[modalType];
    }

    getControlDeviceEditorModal() {
        return <DeviceEditorDialog
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getControlDeviceQuickWhitelistingModal() {
        return <DeviceQuickWhitelistingDialog
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getDeviceAssociatedDeliverablesModal() {
        return <DeviceAssociatedDeliverablesDialog
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getMissingControlDeviceEditorModal() {
        return <MissingUserRoleWarningDialog
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getGroupEditorModal() {
        return <GroupEditorDialog
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getGroupDeleteModal() {
        return <GroupDeletionDialog
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getDeviceActionDeleteModal() {
        return <LogRequestDeletionDialog
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getCopySysParamsModal() {
        return <CopySysParamDefinitionsDialog
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getImportSysParamsModal() {
        return <ImportSysParamDefinitionsDialog
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getSysParamDefinitionEditorModal() {
        return <SysParamDefinitionEditorDialog
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getSysParamDefinitionDeleteModal() {
        return <SysParamDefinitionDeletionDialog
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getSysParamSystemValueEditorModal() {
        return <SysParamSystemValueEditorDialog
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getSysParamSystemValueDeleteModal() {
        return <SysParamSystemValueDeletionDialog
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getEditServiceLogLevelModal() {
        return <DeviceServiceLogLevelDialog
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getDeliverableEditorModal() {
        return <DeliverableEditorDialog
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getArtifactEditorModal() {
        return <ArtifactEditorDialog
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getArtifactDeletionModal() {
        return <ArtifactDeletionDialog
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getArtifactFileDeletionModal() {
        return <ArtifactFileDeletionDialog
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getDeliverableEditorModalNeo() {
        return <DeliverableEditorDialogNeo
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getDeliverableVersionEditorModal() {
        return <DeliverableVersionEditorDialog
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getDeliverableDeletionModal() {
        return <DeliverableDeletionDialog
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getDeliverableDeletionModalNeo() {
        return <DeliverableDeletionDialogNeo
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getDeliverableVersionDeletionModal() {
        return <DeliverableVersionDeletionDialog
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getDeliverablePermissionModal() {
        return <DeliverablePermissionDialog
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getDeliverablePermissionModalNeo() {
        return <DeliverablePermissionDialogNeo
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getDeliverableFileDeletionModal() {
        return <DeliverableFileDeletionDialog
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getUpdateEventReportModal() {
        return <UpdateEventReportDialog
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }

    getJiraIssueModal() {
        return <JiraIssueEditorDialog
            hideModal={this.props.hideModal}
            {...this.props.modalProps}/>;
    }
}

export const mapStateToProps = (state) => {
    return {
        modalType: modalTypeSelector(state),
        modalProps: modalPropsSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        hideModal: () => {
            dispatch(hideModal());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceManagementModals);

DeviceManagementModals.propTypes = {
    modalType: PropTypes.string,
    modalProps: PropTypes.object,
};
