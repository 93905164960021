import {
    HIDE_DEVICE_DETAILS_SIDEBAR,
    SET_SELECTED_TAB_DEVICE_DETAILS_SIDEBAR,
    SET_SHOW_DEVICE_DETAILS_SIDEBAR,
    TOGGLE_DEVICE_DETAILS_SIDEBAR,
} from '~/features/devices/actions/ui/controlDeviceDetailsActions';
import { DETAILS } from '~/features/devices/constants/deviceDetailsTabs';

const defaultState = {
    showDeviceDetailsSidebar: false,
    currentSerialNumber: '',
    tabId: DETAILS,
}

export const showDeviceDetailsSidebar = (state, { serialNumber }) => {
    const newState = {
        ...state,
        showDeviceDetailsSidebar: state.currentSerialNumber === serialNumber ? false : true,
        currentSerialNumber: state.currentSerialNumber === serialNumber ? '' : serialNumber,
        tabId: DETAILS,
    };
    return newState;
};

export const setSelectedTabDeviceDetailsSidebar = (state, {tabId}) => {
    const newState = {
        ...state,
        tabId,
    };
    return newState;
};

export const toggleDeviceDetailsSidebar = (state) => {
    const newState = {
        ...state,
        showDeviceDetailsSidebar:  !state.showDeviceDetailsSidebar,
    };
    return newState;
};

export const hideDeviceDetailsSidebar = (state) => {
    if (state.showDeviceDetailsSidebar) {
        const newState = {
            ...state,
            showDeviceDetailsSidebar:  false,
        };
        return newState;
    }
    return state;
};

export const controlDeviceDetailsReducer = (state = defaultState, action = {}) => {
    switch (action.type) {
        case SET_SHOW_DEVICE_DETAILS_SIDEBAR:
            return showDeviceDetailsSidebar(state, action);
        case SET_SELECTED_TAB_DEVICE_DETAILS_SIDEBAR:
            return setSelectedTabDeviceDetailsSidebar(state, action);
        case TOGGLE_DEVICE_DETAILS_SIDEBAR:
            return toggleDeviceDetailsSidebar(state, action);
        case HIDE_DEVICE_DETAILS_SIDEBAR:
            return hideDeviceDetailsSidebar(state, action);
        default:
            return state;
    }
};

export default controlDeviceDetailsReducer;
