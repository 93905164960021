import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// Routes
import {
    DELIVERABLES_APPS_PATH,
    DELIVERABLES_DISTROS_PATH,
    DELIVERABLES_FILES_PATH,
} from '~/features/base/constants/routes';
// files
import DeliverableContainer from '~/features/artifacts/components/DeliverableContainer';
import { sendGoogleAnalyticsEvent } from '~/index';
import { useDataInterestCleaner } from '~/features/base/hooks/useDataInterestCleaner';

export const ArtifactsRoute = ({ match }) => {
    useDataInterestCleaner();
    // send Google Analytics Event
    sendGoogleAnalyticsEvent('Deliverables Page', 'View Deliverables');
    return (
        <Switch>
            <Route path={`${match.path}/${DELIVERABLES_FILES_PATH}`}
                   component={DeliverableContainer}/>
            <Route path={`${match.path}/${DELIVERABLES_APPS_PATH}`}
                   component={DeliverableContainer}/>
            <Route path={`${match.path}/${DELIVERABLES_DISTROS_PATH}`}
                   component={DeliverableContainer}/>
            <Redirect to={`${match.url}/${DELIVERABLES_FILES_PATH}`}/>
        </Switch>
    );
};

export default ArtifactsRoute;
