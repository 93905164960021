import includes from 'lodash/fp/includes';

import { MERGE_AGGREGATION, RESET_AGGREGATION } from '~/features/higherorder/actions/aggregationActions';

const acceptedActions = [MERGE_AGGREGATION, RESET_AGGREGATION];

const defaultState = {};

const mergeAggregation = (state, action, scopeProp) => {
    return {
        ...state,
        [action.mergeProps[scopeProp]]: action.aggregation,
    };
};
export const aggregrationReducer = (state, action, scopeProp) => {
    if (action.type === MERGE_AGGREGATION) {
        return mergeAggregation(state, action, scopeProp);
    }
    if (action.type === RESET_AGGREGATION) {
        return null;
    }
    return state;
};
export const createAggregationReducer = (acceptedScopes = [], acceptProp = 'entityName', scopeProp = 'scope') => {
    return (state = defaultState, action = {}) => {
        if (includes(action.type, acceptedActions)) {
            const acceptScope = action.mergeProps[acceptProp];
            if (includes(acceptScope, acceptedScopes)) {
                return aggregrationReducer(state, action, scopeProp);
            }
        }
        return state;
    };
};

export default createAggregationReducer;
