export const SHOW_COPY_SYS_PARAM_DEFINITIONS_DIALOG = 'SHOW_COPY_SYS_PARAM_DEFINITIONS_DIALOG';
export const SHOW_IMPORT_SYS_PARAM_DEFINITIONS_DIALOG = 'SHOW_IMPORT_SYS_PARAM_DEFINITIONS_DIALOG';

export const RESET_DISTRO_EDITOR = 'RESET_DISTRO_EDITOR';
export const CHANGE_DISTRO_EDITOR = 'CHANGE_DISTRO_EDITOR';

export function showCopySysParamDefinitionsDialog(payload) {
    return {
        type: SHOW_COPY_SYS_PARAM_DEFINITIONS_DIALOG,
        payload,
    };
}

export function showImportSysParamDefinitionsDialog(payload) {
    return {
        type: SHOW_IMPORT_SYS_PARAM_DEFINITIONS_DIALOG,
        payload,
    };
}

export function resetDistroEditor() {
    return {
        type: RESET_DISTRO_EDITOR,
    };
}

export function changeDistroEditor(payload) {
    return {
        type: CHANGE_DISTRO_EDITOR,
        payload,
    };
}
