import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import VehicleListSearch from '~/features/vehicles/components/VehicleListSearch';
import { ControlDeviceSearchCriteria } from '~/api/models';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import VehicleListFiltersDialog from '~/features/vehicles/components/VehiclesListFiltersDialog';
import VehicleFiltersApplied from "~/features/vehicles/components/VehicleFiltersApplied";

interface VehicleListToolbarProps {
    totalVehicles: number;
    onCriteriaChange: (criteria: ControlDeviceSearchCriteria) => void;
}

const VehicleListToolbar: React.FC<VehicleListToolbarProps> = ({ totalVehicles, onCriteriaChange }) => {

    const [showDialog, setShowDialog] = useState(false);

    const renderFilterDialogButton = () => {
        return <div className='margin-left-10 justify-content-center align-items-center'>
            <div className='text-center'>
                <OverlayTrigger placement='top' overlay={
                    <Tooltip id='tooltip' className='top-right'>
                        <FormattedMessage id='intl-msg:deviceFilterTooltip'/>
                    </Tooltip>}>
                    <button className='btn btn-default btn-icon-only'
                            onClick={() => {
                                setShowDialog(true);
                            }} role='button'>
                        <span className='rioglyph rioglyph-filter'></span>
                    </button>
                </OverlayTrigger>
            </div>
        </div>;
    };

    return (
        <>
            <div className={'margin-bottom-20 flex-row display-flex justify-content-between '}>
                <span className='padding-5'>
                    <FormattedMessage id='intl-msg:totalVehicles.phrase'
                                      values={{
                                          totalElements: totalVehicles,
                                          selectedCount: 0,
                                      }}/>
                </span>
                <div className={'display-flex justify-content-end'}>
                    <VehicleListSearch onCriteriaChange={onCriteriaChange}/>
                    {renderFilterDialogButton()}
                </div>
            </div>
            <VehicleListFiltersDialog showDialog={showDialog}
                                      setShowDialog={setShowDialog}
                                      onCriteriaChange={onCriteriaChange}/>
            </>
    );
};

export default VehicleListToolbar;
