export const FETCH_DISTRO_COUNTS_BY_RELEASE_STATE = 'FETCH_DISTRO_COUNTS_BY_RELEASE_STATE';
export const FETCH_FILTERED_DISTROS = 'FETCH_FILTERED_DISTROS';
export const FETCH_DISTRO_WITH_SYS_PARAMS = 'FETCH_DISTRO_WITH_SYS_PARAMS';
export const FETCH_DISTRO = 'FETCH_DISTRO';


export const fetchDistroCountsByReleaseState = () => {
    return {
        type: FETCH_DISTRO_COUNTS_BY_RELEASE_STATE,
    };
};

export const fetchFilteredDistros = ({ page, size, searchCriteria }) => {
    return {
        type: FETCH_FILTERED_DISTROS,
        payload: {
            page,
            size,
            searchCriteria,
        },
    };
};


export const fetchDistro = ({ shortDistroVersion }) => {
    return {
        type: FETCH_DISTRO,
        payload: {
            shortDistroVersion,
        },
    };
};

export function fetchDistroWithSysParams({ shortDistroVersion }) {
    return {
        type: FETCH_DISTRO_WITH_SYS_PARAMS,
        payload: {
            shortDistroVersion,
        },
    };
}
