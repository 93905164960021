import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { SET_SHOW_VEHICLE_DETAILS_SIDEBAR } from '~/features/oldVehicles/actions/ui/vehicleDetailsActions';
// import {
//     ENTITY_CONTROL_DEVICE_ELIGIBLE_FILE_VERSIONS,
//     ENTITY_CONTROL_DEVICE_ELIGIBLE_PACKAGE_VERSIONS,
//     ENTITY_CONTROL_DEVICE_INFO,
// } from '~/features/base/constants/entities';
// import {
//     FETCH_CONTROL_DEVICE_ELIGIBLE_FILE_VERSIONS,
//     FETCH_CONTROL_DEVICE_ELIGIBLE_PACKAGE_VERSIONS,
//     FETCH_CONTROL_DEVICE_INFORMATION,
// } from '~/features/devices/actions/controlDeviceInfoActions';
import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';
import { resetEntity, mergeEntity } from '~/features/higherorder/actions/entityActions';
// import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';
import { doHandleErrorSaga, getHTTP } from '~/features/base/sagas/sagaUtil';
// import { parseControlDeviceInfo } from '~/features/devices/transforms/parseControlDeviceInfo';
import { finishedAction } from '~/features/higherorder/transforms/actionTransforms';
import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';
import { ENTITY_VEHICLE_INFO } from '~/features/base/constants/entities';
import { parseVehicleInfo } from '~/features/oldVehicles/transforms/parseVehicleInfo';

// import { SET_SHOW_DEVICE_DETAILS_SIDEBAR } from '~/features/devices/actions/ui/controlDeviceDetailsActions';

export function* getVehicleURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v2/client/vehicle`;
}

// export function* doFetchControlDeviceInfoSaga(action) {
//     try {
//         const controlDeviceServiceURL = yield call(getControlDeviceURL);
//         const { serialNumber } = action.payload;
//         const response = yield call(getHTTP, `${controlDeviceServiceURL}/${serialNumber}/information`);
//         const parsedResponse = parseControlDeviceInfo(response);
//         yield put(mergeEntity(parsedResponse, { entityName: ENTITY_CONTROL_DEVICE_INFO }));
//     } catch (error) {
//         yield fork(doHandleErrorSaga, action.type, error);
//         yield put(showErrorMessage(action.type, error));
//     }
//     yield put(finishedAction(action.type));
// }

export function* doFeShowVehicleDetailsInSidebar(action) {
    try {
        const url = yield call(getVehicleURL);
        const { vin } = action;
        const response = yield call(getHTTP, `${url}/${vin}/information`);
        const parsedResponse = parseVehicleInfo(response);
        yield put(mergeEntity(parsedResponse, { entityName: ENTITY_VEHICLE_INFO }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

// export function* doFetchControlDeviceEligiblePackageVersionSaga(action) {
//     try {
//         yield put(resetEntity({}, { entityName: ENTITY_CONTROL_DEVICE_ELIGIBLE_PACKAGE_VERSIONS }));
//         const controlDeviceServiceURL = yield call(getControlDeviceURL);
//         const { serialNumber } = action.payload;
//         const response = yield call(getHTTP, `${controlDeviceServiceURL}/${serialNumber}/eligible/package-versions`);
//         yield put(mergeEntity(response, { entityName: ENTITY_CONTROL_DEVICE_ELIGIBLE_PACKAGE_VERSIONS }));
//     } catch (error) {
//         yield fork(doHandleErrorSaga, action.type, error);
//         yield put(showErrorMessage(action.type, error));
//     }
//     yield put(finishedAction(action.type));
// }
//
// export function* doFetchControlDeviceEligibleFileVersionSaga(action) {
//     try {
//         yield put(resetEntity({}, { entityName: ENTITY_CONTROL_DEVICE_ELIGIBLE_FILE_VERSIONS }));
//         const controlDeviceServiceURL = yield call(getControlDeviceURL);
//         const { serialNumber } = action.payload;
//         const response = yield call(getHTTP, `${controlDeviceServiceURL}/${serialNumber}/eligible/file-versions`);
//         yield put(mergeEntity(response, { entityName: ENTITY_CONTROL_DEVICE_ELIGIBLE_FILE_VERSIONS }));
//     } catch (error) {
//         yield fork(doHandleErrorSaga, action.type, error);
//         yield put(showErrorMessage(action.type, error));
//     }
//     yield put(finishedAction(action.type));
// }
//
// export function* fetchControlDeviceInfoSaga() {
//     yield takeLatest(FETCH_CONTROL_DEVICE_INFORMATION, doFetchControlDeviceInfoSaga);
// }

export function* showVehicleDetailsInSidebar() {
    yield takeLatest(SET_SHOW_VEHICLE_DETAILS_SIDEBAR, doFeShowVehicleDetailsInSidebar);
}

// export function* fetchControlDeviceEligiblePackageVersionsSaga() {
//     yield takeLatest(FETCH_CONTROL_DEVICE_ELIGIBLE_PACKAGE_VERSIONS, doFetchControlDeviceEligiblePackageVersionSaga);
// }
//
// export function* fetchControlDeviceEligibleFileVersionsSaga() {
//     yield takeLatest(FETCH_CONTROL_DEVICE_ELIGIBLE_FILE_VERSIONS, doFetchControlDeviceEligibleFileVersionSaga);
// }
