import { call, put, select, fork, takeLatest } from 'redux-saga/effects';

import {
    FETCH_BASE_SW_VERSIONS,
    FETCH_EDITABLE_BASE_SW_VERSIONS,
} from '~/features/baseSwVersions/actions/baseSwActions';

import { mergeEntities } from '~/features/higherorder/actions/entityActions';

import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';
import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';

import { doHandleErrorSaga, getHTTP } from '~/features/base/sagas/sagaUtil';

import {
    ENTITY_BASE_SW,
} from '~/features/base/constants/entities';
import { parseBaseSwVersions } from '~/features/baseSwVersions/transforms/parseBaseSwVersions';
import { finishedAction } from '~/features/higherorder/transforms/actionTransforms';

export function* getDistrosURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/deliverable/base-software-versions`;
}

export function* doFetchBaseSwVersionsSaga(action) {
    try {
        const distrosServiceURL = yield call(getDistrosURL);
        const response = yield call(getHTTP, `${distrosServiceURL}`);
        const entities = parseBaseSwVersions(response.content);
        yield put(mergeEntities(entities, { entityName: ENTITY_BASE_SW, scope: 'allBaseSwVersions' }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchEditableBaseSwVersionsSaga(action) {
    try {
        const distrosServiceURL = yield call(getDistrosURL);
        const response = yield call(getHTTP, `${distrosServiceURL}/editable-sys-params`);
        const entities = parseBaseSwVersions(response.content);
        yield put(mergeEntities(entities, { entityName: ENTITY_BASE_SW, scope: 'editableBaseSwVersions' }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* fetchBaseSwVersionsSaga() {
    yield takeLatest(FETCH_BASE_SW_VERSIONS, doFetchBaseSwVersionsSaga);
}

export function* fetchEditableBaseSwVersionsSaga() {
    yield takeLatest(FETCH_EDITABLE_BASE_SW_VERSIONS, doFetchEditableBaseSwVersionsSaga);
}
