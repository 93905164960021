import size from 'lodash/fp/size';
import noop from 'lodash/fp/noop';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';
import ResultFormItem from "~/features/base/components/forms/ResultFormItem";
import Checkbox from "@rio-cloud/rio-uikit/lib/es/Checkbox";

export class UpdateEventListTableToolbar extends PureComponent {
    render() {
        const { children, filters, onCriteriaChange, showFilters } = this.props;
        const reportButton = this.renderReportButton();
        const exportDropdown = this.renderExportDropdown();
        return (
            <div className='display-flex justify-content-between align-items-end update-event-list-toolbar'>
                <div className=''>
                        {children}
                </div>
                <div className='display-flex justify-content-end align-items-end'>
                    {showFilters && <>
                        <ResultFormItem
                            value={filters?.outcome}
                            onChange={(item) => {
                                onCriteriaChange({...filters, outcome: item?.result})
                            }}
                        />
                        <Checkbox className='padding-top-5 margin-left-20 margin-right-20' onClick={() => {
                            onCriteriaChange({...filters, showOnlyLastEvent: !filters?.showOnlyLastEvent})
                        }}
                                  checked={filters?.showOnlyLastEvent} size='large'>
                            <label className='control-label'>
                                <FormattedMessage id='intl-msg:showLatestUpdateEvents.label'/>
                            </label>
                        </Checkbox>
                    </>}
                    <div className=''>
                        {exportDropdown}
                    </div>
                </div>
            </div>
        );
    }

    renderReportButton() {
        if (!this.props.showReportButton) {
            return null;
        }

        return (
            <a id='report-button'
                className='btn btn-default'
                onClick={this.props.onShowReport}>
                <span className='rioglyph rioglyph-line-chart' aria-hidden='true'></span>&nbsp;
                <FormattedMessage id='intl-msg:showReport'/>
            </a>
        );
    }

    renderExportDropdown() {
        const items = [];
        items.push(
            {
                value: [
                    <span className='rioglyph rioglyph-cards-grid padding-right-5' aria-hidden='true'></span>,
                    <FormattedMessage id='intl-msg:exportCSV'/>,
                ],
                onSelect: () => this.props.onExportUpdateEvents('csv'),
            }
        );
        items.push(
            {
                value: [
                    <span className='rioglyph rioglyph-cards-list padding-right-5' aria-hidden='true'></span>,
                    <FormattedMessage id='intl-msg:exportSerials'/>,
                ],
                onSelect: () => this.props.onExportUpdateEvents('serials'),
            }
        );
        return (
            size(items) ? (
                <ButtonDropdown
                    id='mySimpleButtonDropdown'
                    title={
                        <span>
                            <FormattedMessage id='intl-msg:export'/>
                        </span>
                    }
                    items={items}
                />
            ) : null
        );

    }
}

export default UpdateEventListTableToolbar;

UpdateEventListTableToolbar.defaultProps = {
    // props
    showReportButton: false,
    showFilters: false,
    // functions
    onShowReport: noop,
    onExportUpdateEvents: noop,
};

UpdateEventListTableToolbar.propTypes = {
    // props
    showReportButton: PropTypes.bool,
    showFilters: PropTypes.bool,
    // functions
    onShowReport: PropTypes.func,
    onExportUpdateEvents: PropTypes.func,
};
