import compact from 'lodash/fp/compact';
import noop from 'lodash/fp/noop';
import size from 'lodash/fp/size';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';


import { fetchDistroWithSysParams } from '~/features/deliverables/features/distros/actions/distroActions';
import { fetchEditableBaseSwVersions } from '~/features/baseSwVersions/actions/baseSwActions';

import {
    showSysParamDefinitionEditor,
    showSysParamDefinitionDeletionDialog,
} from '~/features/sysParams/actions/sysParamDefinitionEditorActions';
import { exportSysParams } from '~/features/sysParams/actions/sysParamsActions';
import {
    showCopySysParamDefinitionsDialog, // TODO Move to sysparams
    showImportSysParamDefinitionsDialog, // TODO Move to sysparams
} from '~/features/deliverables/features/distros/actions/distroEditorActions';

import { distroWithBaseSwSelector } from '~/features/baseSwVersions/selectors/baseSwVersionSelectors';

import {
    canCreateSysParamDefinitionsSelector,
    canUpdateSysParamDefinitionsSelector,
    canDeleteSysParamDefinitionsSelector,
} from '~/features/sysParams/selectors/sysParamDefinitionSelectors';
import { canCopySysParamsSelector, sysParamsOfDistroSelector } from '~/features/sysParams/selectors/sysParamSelectors';

import DistroSysParamsTable from '~/features/deliverables/components/DistroSysParamsTable';
import SysParamListToolbar from '~/features/sysParams/components/SysParamListToolbar';
import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';

/**
 * Container for distro system parameters
 */
export class DistroParametersContainer extends PureComponent {
    onShowSysParamDefinitionEditor = () => {
        const distro = this.props.distro;
        const baseSoftwareVersion = distro.softwareVersion;
        const sysParams = this.props.sysParams;
        this.props.showSysParamDefinitionEditor({
            isNew: true,
            baseSoftwareVersion,
            sysParams,
        });
    }

    onEditSysParamDefinition = (sysParam) => {
        this.props.showSysParamDefinitionEditor({
            isNew: false,
            sysParam,
        });
    }

    onDeleteSysParamDefinition = (sysParam) => {
        this.props.showSysParamDefinitionDeletionDialog({
            sysParam,
        });
    }

    onShowCopySysParamDefinitionsDialog = () => {
        const { distro } = this.props;
        this.props.showCopySysParamDefinitionsDialog({
            distro,
        });
    }

    onShowImportSysParamDefinitionsDialog = () => {
        const { distro } = this.props;
        this.props.showImportSysParamDefinitionsDialog({
            distro,
        });
    }

    onExportSysParams = () => {
        const { sysParams } = this.props;
        this.props.exportSysParams(sysParams);
    }

    render() {
        const { canCopySysParams, canCreateSysParamDefinitions, canUpdateSysParamDefinitions,
            canDeleteSysParamDefinitions, shortBaseSwVersion, distro, sysParams } = this.props;

        if (!distro || !distro.shortBaseSwVersion) {
            return (
                <div className='padding-top-20 padding-bottom-20'>
                    <Spinner/>
                </div>
            );
        }
        if (!size(sysParams)) {
            return [
                <div key={'sysParamListToolbar'} className='padding-top-20 clearfix'>
                    <SysParamListToolbar
                        canCopySysParams={this.props.canCopySysParams}
                        canCreateSysParamDefinitions={this.props.canCreateSysParamDefinitions}
                        isBaseSwEditable={distro.isBaseSwEditable}
                        onShowSysParamDefinitionEditor={this.onShowSysParamDefinitionEditor}
                        onShowCopySysParamDefinitionsDialog={this.onShowCopySysParamDefinitionsDialog}
                        onShowImportSysParamDefinitionsDialog={this.onShowImportSysParamDefinitionsDialog}
                        showExportDropDown={false}
                    />
                </div>,
                <div key={'notFoundState'} className='padding-top-20 padding-bottom-20'>
                    <NotFoundState fullWidth
                        headline={<FormattedMessage id='intl-msg:nothingFound'/>}
                        message={<FormattedMessage id='intl-msg:sysParams.notFound'/>}
                    />
                </div>
            ];
        }

        return [
            <div key={'sysParamListToolbar'} className='padding-top-20 clearfix'>
                <SysParamListToolbar
                    canCopySysParams={this.props.canCopySysParams}
                    canCreateSysParamDefinitions={this.props.canCreateSysParamDefinitions}
                    isBaseSwEditable={distro.isBaseSwEditable}
                    onShowSysParamDefinitionEditor={this.onShowSysParamDefinitionEditor}
                    onShowCopySysParamDefinitionsDialog={this.onShowCopySysParamDefinitionsDialog}
                    onShowImportSysParamDefinitionsDialog={this.onShowImportSysParamDefinitionsDialog}
                    onExportSysParams={this.onExportSysParams}/>
            </div>,
            <div key={'distroSysParamsTable'} className='padding-top-20 padding-bottom-20'>
                <DistroSysParamsTable shortBaseSwVersion={shortBaseSwVersion}
                    isBaseSwEditable={distro.isBaseSwEditable}
                    canCopySysParams={canCopySysParams}
                    canCreateSysParamDefinitions={canCreateSysParamDefinitions}
                    canUpdateSysParamDefinitions={canUpdateSysParamDefinitions}
                    canDeleteSysParamDefinitions={canDeleteSysParamDefinitions}
                    sysParams={sysParams}
                    onShowSysParamDefinitionEditor={this.onShowSysParamDefinitionEditor}
                    onEditSysParamDefinition={this.onEditSysParamDefinition}
                    onDeleteSysParamDefinition={this.onDeleteSysParamDefinition}
                    onShowCopySysParamDefinitionsDialog={this.onShowCopySysParamDefinitionsDialog}
                    onShowImportSysParamDefinitionsDialog={this.onShowImportSysParamDefinitionsDialog}
                    onExportSysParams={this.onExportSysParams}/>
            </div>,
        ];
    }

    componentWillMount() {
        const { shortDistroVersion } = this.props;
        this.props.fetchEditableBaseSwVersions();
        this.props.fetchDistroWithSysParams({ shortDistroVersion });
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        canCopySysParams: canCopySysParamsSelector(state),
        canCreateSysParamDefinitions: canCreateSysParamDefinitionsSelector(state),
        canUpdateSysParamDefinitions: canUpdateSysParamDefinitionsSelector(state),
        canDeleteSysParamDefinitions: canDeleteSysParamDefinitionsSelector(state),
        distro: distroWithBaseSwSelector(state, ownProps),
        sysParams: sysParamsOfDistroSelector(state, ownProps),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        fetchEditableBaseSwVersions: () => {
            dispatch(fetchEditableBaseSwVersions());
        },
        fetchDistroWithSysParams: payload => {
            dispatch(fetchDistroWithSysParams(payload));
        },
        showSysParamDefinitionEditor: payload => {
            dispatch(showSysParamDefinitionEditor(payload));
        },
        showSysParamDefinitionDeletionDialog: payload => {
            dispatch(showSysParamDefinitionDeletionDialog(payload));
        },
        showCopySysParamDefinitionsDialog: payload => {
            dispatch(showCopySysParamDefinitionsDialog(payload));
        },
        showImportSysParamDefinitionsDialog: payload => {
            dispatch(showImportSysParamDefinitionsDialog(payload));
        },
        exportSysParams: (definitions, defaultValues) => {
            dispatch(exportSysParams(definitions, defaultValues));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DistroParametersContainer);

DistroParametersContainer.defaultProps = {
    // props
    canCopySysParams: false,
    canCreateSysParamDefinitions: false,
    canUpdateSysParamDefinitions: false,
    canDeleteSysParamDefinitions: false,
    shortDistroVersion: undefined, // changed from ''
    distro: undefined,
    sysParams: [],
    // functions
    fetchEditableBaseSwVersions: noop,
    fetchDistroWithSysParams: noop,
    showSysParamDefinitionEditor: noop,
    showSysParamDefinitionDeletionDialog: noop,
    showCopySysParamDefinitionsDialog: noop,
    showImportSysParamDefinitionsDialog: noop,
    exportSysParams: noop,
};

DistroParametersContainer.propTypes = {
    // props
    canCopySysParams: PropTypes.bool,
    canCreateSysParamDefinitions: PropTypes.bool,
    canUpdateSysParamDefinitions: PropTypes.bool,
    canDeleteSysParamDefinitions: PropTypes.bool,
    shortDistroVersion: PropTypes.string,
    distro: PropTypes.object,
    sysParams: PropTypes.array,
    // functions
    fetchEditableBaseSwVersions: PropTypes.func,
    fetchDistroWithSysParams: PropTypes.func,
    showSysParamDefinitionEditor: PropTypes.func,
    showSysParamDefinitionDeletionDialog: PropTypes.func,
    showCopySysParamDefinitionsDialog: PropTypes.func,
    showImportSysParamDefinitionsDialog: PropTypes.func,
    exportSysParams: PropTypes.func,
};
