import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import map from 'lodash/fp/map';

import { createSelector } from 'reselect';

import {
    ENTITY_CONTROL_DEVICE,
    ENTITY_CONTROL_DEVICE_SCOPE_DEVICES_WITH_LOGS,
} from '~/features/base/constants/entities';

import { indicesStoreSelector } from '~/features/higherorder/selectors/dataSelectors';
import {
    controlDeviceEntitiesSelector,
    controlDeviceAggregationsSelector,
} from '~/features/devices/selectors/controlDeviceSelectors';

const devicesWithLogsPagesSelector = state => getOr({}, ENTITY_CONTROL_DEVICE_SCOPE_DEVICES_WITH_LOGS,
    indicesStoreSelector(state)[ENTITY_CONTROL_DEVICE]);

export const devicesWithLogsCountSelector = state =>
    controlDeviceAggregationsSelector(state).countsOfDevicesWithLogs;

export const devicesWithLogsPageNumberSelector = state => get('number', devicesWithLogsPagesSelector(state));
export const devicesWithLogsPageCountSelector = state => get('totalPages', devicesWithLogsPagesSelector(state));
export const devicesWithLogsTotalElementsSelector = state => get('totalElements', devicesWithLogsPagesSelector(state));

export const devicesWithLogsPageItemsSelector = createSelector(
    [devicesWithLogsPagesSelector, controlDeviceEntitiesSelector, devicesWithLogsPageNumberSelector],
    (pages, entities, pageNumber) => {
        return map(id => entities[id], getOr([], pageNumber, pages));
    }
);
