import find from 'lodash/fp/find';
import includes from 'lodash/fp/includes';
import size from 'lodash/fp/size';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { logRequestColumnDescriptors } from '~/features/deviceLogs/constants/deviceLogColumnDescriptors';

import ListTable from '~/features/base/components/table/ListTable';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';

const findByKey = key => find(item => item.hwSerial === key);

/**
 * Table component for log requests
 */
export class LogRequestsTable extends PureComponent {
    constructor(props) {
        super(props);

        this.actionsColumn = {
            id: 'actions',
            field: 'hwSerial',
            className: 'table-action',
            format: field => {
                const items = [];
                const { canUpdateDeviceActions, canDeleteDeviceActions } = this.props;

                if (canDeleteDeviceActions) {
                    items.push({
                        value: (
                            <div onClick={this.onDeleteButtonRowClick} data-key={field}>
                                <span className='rioglyph rioglyph-trash margin-right-10'></span>
                                <span><FormattedMessage id='intl-msg:deleteLogRequest'/></span>
                            </div>
                        ),
                    });
                }
                if (size(items)) {
                    return (
                        <ButtonDropdown
                            title={<span className='rioglyph rioglyph-option-vertical'/>}
                            className='pull-right'
                            bsStyle='link'
                            iconOnly
                            items={items}/>
                    );
                }
            },
            formatLabel: () => '',
        };
    }

    checkRecursively(node, nodeType, className) {
        if (node.localName === nodeType) {
            return includes(className, node.classList);
        } else if (node.localName === 'td') {
            return false;
        }
        return this.checkRecursively(node.parentNode, nodeType, className);

    }

    onRowClick = (event) => {
        if (event.target.localName === 'input') {
            return;
        }
        const itemKey = event.currentTarget.getAttribute('data-key');
        const isButton = this.checkRecursively(event.target, 'button', 'btn');
        const isDropdown = this.checkRecursively(event.target, 'ul', 'dropdown-menu');
        const isClickable = this.checkRecursively(event.target, 'span', 'clickable');
        if (itemKey) {
            if (isButton) {
                return;
            }
            if (isDropdown) {
                return;
            }
            if (isClickable) {
                return;
            }
            this.props.onShowItem(itemKey);
        }
    };

    onEditButtonRowClick = (event) => {
        const key = event.currentTarget.getAttribute('data-key');
        const deviceAction = findByKey(key)(this.props.deviceActions);
        this.props.onEditDeviceAction(deviceAction);
    };
    onDeleteButtonRowClick = (event) => {
        const key = event.currentTarget.getAttribute('data-key');
        const deviceAction = findByKey(key)(this.props.deviceActions);
        this.props.onDeleteDeviceAction(deviceAction);
    };

    render() {
        const { deviceActions } = this.props;
        const columns = [...logRequestColumnDescriptors, this.actionsColumn];
        return [
            <ListTable items={deviceActions}
                       itemKey='hwSerial'
                       columnDescriptors={columns}
                       onRowClick={this.onRowClick}/>,
        ];
    }
}

export default LogRequestsTable;

LogRequestsTable.defaultProps = {
    canUpdateDeviceActions: false,
    canDeleteDeviceActions: false,
    deviceActions: [],
    onShowItem: noop,
    onEditDeviceAction: noop,
    onDeleteDeviceAction: noop,
};

LogRequestsTable.propTypes = {
    // props
    canUpdateDeviceActions: PropTypes.bool,
    canDeleteDeviceActions: PropTypes.bool,
    deviceActions: PropTypes.array,
    // functions
    onShowItem: PropTypes.func,
    onEditDeviceAction: PropTypes.func,
    onDeleteDeviceAction: PropTypes.func,
};
