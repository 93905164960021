import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { deleteDeliverableFile } from '~/features/artifacts/actions/deliverableFileActions';
import { deliverableEditorSelector } from '~/features/artifacts/selectors/deliverableSelectors';
import noop from 'lodash/fp/noop';
import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';

/**
 * Deliverable file deletion dialog
 */
export class DeliverableFileDeletionDialog extends PureComponent {

    onDeleteDeliverableFile = () => {
        const { deliverableId, fileName, deleteDeliverableFile } = this.props;
        deleteDeliverableFile(deliverableId, fileName);
    };

    render() {
        const { fileName } = this.props;
        return (
            <ConfirmationDialog className='file-deliverable-deletion-dialog'
                                show={true}
                                title={<FormattedMessage id='intl-msg:deleteDeliverableFile'/>}
                                content={<FormattedMessage id='intl-msg:deleteDeliverableFile.prompt' values={{
                                    fileName,
                                }}/>}
                                cancelButtonText={<FormattedMessage id='intl-msg:cancel'/>}
                                confirmButtonText={<FormattedMessage id='intl-msg:ok'/>}
                                onClickCancel={this.props.hideModal}
                                onClickConfirm={this.onDeleteDeliverableFile}/>
        );
    }
}

export const mapStateToProps = (state) => {
    return {};
};

export const mapDispatchToProps = (dispatch) => {
    return {
        deleteDeliverableFile: (deliverableId, fileName) => {
            dispatch(deleteDeliverableFile(deliverableId, fileName));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableFileDeletionDialog);

DeliverableFileDeletionDialog.defaultProps = {
    // functions
    deleteDeliverableFile: noop,
};

DeliverableFileDeletionDialog.propTypes = {
    // functions
    deleteDeliverableFile: PropTypes.func,
};
