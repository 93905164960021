import getOr from 'lodash/fp/getOr';
import flow from 'lodash/fp/flow';
import pick from 'lodash/fp/pick';

import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import {
    FETCH_DISTRO,
    FETCH_DISTRO_COUNTS_BY_RELEASE_STATE,
    FETCH_DISTRO_WITH_SYS_PARAMS,
    FETCH_FILTERED_DISTROS,
} from '~/features/deliverables/features/distros/actions/distroActions';
import { fetchSysParamDefinitions } from '~/features/sysParams/actions/sysParamDefinitionActions';
import { fetchSysParamDefaultValues } from '~/features/sysParams/actions/sysParamDefaultValueActions';

import { doFetchSysParamDefinitionsSaga } from '~/features/sysParams/sagas/sysParamDefinitionsSaga';
import { doFetchSysParamDefaultValuesSaga } from '~/features/sysParams/sagas/sysParamDefaultValuesSaga';

import { mergeEntities, mergeEntity } from '~/features/higherorder/actions/entityActions';
import { mergePage } from '~/features/higherorder/actions/paginationActions';
import { mergeAggregation } from '~/features/higherorder/actions/aggregationActions';
import { endLoading, startLoading } from '~/features/higherorder/actions/loadingActions';

import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';

import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';
import { distroSelector } from '~/features/deliverables/features/distros/selectors/distroSelectors';
import { stringifyToQuery } from '~/features/base/utils/query';

import {
    generatePerReleaseState,
    parseDistro,
    parseDistros,
} from '~/features/deliverables/features/distros/transforms/parseDistros';

import { doHandleErrorSaga, getHTTP, postHTTP, putHTTP } from '~/features/base/sagas/sagaUtil';

import {
    ENTITY_DISTRO,
} from '~/features/base/constants/entities';
import { failedAction, finishedAction } from '~/features/higherorder/transforms/actionTransforms';
import { DeliverableType } from '~/features/deliverables/constants/DeliverableType';

export function* getDistrosURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/deliverable`;
}


export function* doFetchDistroCountsByReleaseStateSaga(action) {
    try {
        const distrosServiceURL = yield call(getDistrosURL);
        const response = yield call(postHTTP, `${distrosServiceURL}/counts-by/release-state`, JSON.stringify({ deliverableType: DeliverableType.DISTRO }));
        const parsedResponse = generatePerReleaseState(response);
        yield put(mergeAggregation({
            entityName: ENTITY_DISTRO,
            scope: 'countsByReleaseState',
        }, parsedResponse));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
    }
    yield put(finishedAction(action.type));
}

export function* doFetchFilteredDistrosSaga(action) {
    yield put(startLoading(ENTITY_DISTRO));
    try {
        const distrosServiceURL = yield call(getDistrosURL);
        const query = flow(pick(['page', 'size']), stringifyToQuery)(action.payload);
        let searchCriteria = {
            ...action.searchCriteria,
            deliverableType: DeliverableType.DISTRO,
        };
        const { content, ...pagination } = yield call(postHTTP, `${distrosServiceURL}/search?${query}`,
            JSON.stringify(searchCriteria));
        const parsedResponse = { content: parseDistros(content), ...pagination };
        yield put(mergeEntities(parsedResponse.content, { entityName: ENTITY_DISTRO }));
        yield put(mergePage({ entityName: ENTITY_DISTRO }, parsedResponse));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_DISTRO));
    yield put(finishedAction(action.type));
}



export function* doFetchDistroSaga(action) {
    try {
        const distrosServiceURL = yield call(getDistrosURL);
        const options = action.payload;
        const encodedShortDistroVersion = encodeURIComponent(options.shortDistroVersion);
        const response = yield call(getHTTP, `${distrosServiceURL}/${DeliverableType.DISTRO}/distro/${encodedShortDistroVersion}`);
        const parsedResponse = parseDistro(response);
        yield put(mergeEntity(parsedResponse, { entityName: ENTITY_DISTRO }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(failedAction(action.type, error));
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchDistroWithSysParamsSaga(action) {
    try {
        yield call(doFetchDistroSaga, action);
        const shortDistroVersion = action.payload.shortDistroVersion;
        const distro = yield select(distroSelector, { shortDistroVersion });
        const shortBaseSwVersion = distro.shortBaseSwVersion;
        yield all([
            call(doFetchSysParamDefinitionsSaga, fetchSysParamDefinitions({ shortBaseSwVersion })),
            call(doFetchSysParamDefaultValuesSaga, fetchSysParamDefaultValues({ shortBaseSwVersion })),
        ]);
    } catch (error) {
        yield put(failedAction(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* fetchDistroCountsByReleaseStateSaga() {
    yield takeLatest(FETCH_DISTRO_COUNTS_BY_RELEASE_STATE, doFetchDistroCountsByReleaseStateSaga);
}

export function* fetchFilteredDistrosSaga() {
    yield takeLatest(FETCH_FILTERED_DISTROS, doFetchFilteredDistrosSaga);
}

export function* fetchDistroWithSysParamsSaga() {
    yield takeLatest(FETCH_DISTRO_WITH_SYS_PARAMS, doFetchDistroWithSysParamsSaga);
}

export function* fetchDistroSaga() {
    yield takeLatest(FETCH_DISTRO, doFetchDistroSaga);
}

