import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import noop from 'lodash/fp/noop';
import uid from '~/features/base/utils/uid';
import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';
import { followRoute } from '~/features/base/actions/ui/routeActions';
import {
    fetchReportsBillingChartsInitialData,
    removeReportsBillingChartsInitialData,
} from '~/features/reportsbilling/actions/reportsBillingActions';
import ReportsBillingChartsDialog from '~/features/reportsbilling/components/ReportsBillingChartsDialog';
import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';

export class ReportsBillingChartsDialogContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.name = uid();
    }

    render() {
        return (
            <DefaultWhiteColumn className='padding-top-25 clearfix min-height-75vh max-height-75vh'>
                <ReportsBillingChartsDialog dialogParams={this.props.dialogParams}
                />
            </DefaultWhiteColumn>
        );
    }

    componentDidMount() {
        const { dialogParams } = this.props;
        dialogParams.requestParams.totalsOnly = false;
        this.props.fetchReportsBillingChartsInitialData(
            dialogParams.requestParams.startDate,
            dialogParams.requestParams.endDate,
            dialogParams.requestParams.totalsOnly,
            dialogParams.requestParams.requestTypes);
    }
    componentWillUnmount() {
        this.props.removeReportsBillingChartsInitialData();
    }
}

const mapStateToProps = (state, ownProps) => ({
    pathname: pathnameSelector(state),
    search: searchSelector(state),
    dialogParams: ownProps.dialogParams,
});

const mapDispatchToProps = (dispatch) => ({
    fetchReportsBillingChartsInitialData: (startDate, endDate, totalsOnly, requestTypes) => {
        dispatch(fetchReportsBillingChartsInitialData(
            {
                searchCriteria: {
                    startDate,
                    endDate,
                    totalsOnly,
                    requestTypes,
                },
            },
        ));
    },
    removeReportsBillingChartsInitialData: () => {
        dispatch(removeReportsBillingChartsInitialData());
    },
    followRoute: (options) => {
        dispatch(followRoute(options));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsBillingChartsDialogContainer);

ReportsBillingChartsDialogContainer.defaultProps = {
    // props
    // functions
    fetchReportsBillingChartsInitialData: noop,
    removeReportsBillingChartsInitialData: noop,
    followRoute: noop,
};

ReportsBillingChartsDialogContainer.propTypes = {
    // props
    // functions
    fetchReportsBillingChartsInitialData: PropTypes.func,
    removeReportsBillingChartsInitialData: PropTypes.func,
    followRoute: noop,
};
