import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import { showControlDeviceEditor } from '~/features/devices/actions/controlDeviceEditorActions';
import uid from '~/features/base/utils/uid';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import {
    getCurrentlySelectedVehicleInfoSelector,
    vehiclesLoadingSelector,
} from '~/features/oldVehicles/selectors/vehicleSelectors';
import VehiclePropertiesTable from '~/features/oldVehicles/components/VehiclePropertiesTable';
import { vehicleRowDescriptors } from '~/features/oldVehicles/constants/vehicleRowDescriptors';

/**
 * Container for the vehicle properties
 */
export class VehiclePropertiesContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.name = uid();
    }

    getErrorMessage = () => {
        return (
            <div className='error-message'>
                <FormattedMessage id='intl-msg:controlDevices.fetch.error'/>
            </div>
        );
    };

    // onEditDevice = () => {
    //     const { vin } = this.props.vehicleInfo;
    // this.props.showControlDeviceEditor({
    //     isNew: false,
    //     serialNumber,
    // });
    // };

    render() {
        const { vehicleInfo } = this.props;
        return [
            <VehiclePropertiesTable
                vehicleInfo={vehicleInfo}
                rowDescriptors={vehicleRowDescriptors}/>,
            // <div className='padding-top-5'>
            //     <ExpanderPanel bsStyle='primary' className='margin-0' open={true}
            //                    title={<FormattedMessage id='intl-msg:rolloutProgress'/>}>
            //         {/*<DeviceRolloutStatesContainer serialNumber={controlDeviceInfo.serialNumber}/>*/}
            //     </ExpanderPanel>
            // </div>,
        ];
    }

    // componentWillMount() {
    //     this.props.registerDataInterest(this.name, [
    //         fetchControlDeviceInformation({
    //             serialNumber: this.props.serialNumber,
    //         }),
    //     ]);
    // }

    // componentWillUnmount() {
    //     this.props.unregisterDataInterest(this.name);
    // }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        vehicleInfo: getCurrentlySelectedVehicleInfoSelector(state, ownProps),
        vehicleDetailsLoading: vehiclesLoadingSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        triggerDataFetcher: () => {
            dispatch(triggerDataFetcher());
        },
        showControlDeviceEditor: payload => {
            dispatch(showControlDeviceEditor(payload));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehiclePropertiesContainer);

VehiclePropertiesContainer.defaultProps = {
    // props
    vin: '',
    controlDeviceData: undefined,
    vehicleInfo: undefined,
    vehicleDetailsLoading: false,
    // functions
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    triggerDataFetcher: noop,
    showControlDeviceEditor: noop,
};

VehiclePropertiesContainer.propTypes = {
    // props
    vin: PropTypes.string,
    controlDeviceData: PropTypes.object,
    vehicleInfo: PropTypes.object,
    vehicleDetailsLoading: PropTypes.bool,
    // functions
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
    showControlDeviceEditor: PropTypes.func,
};
