import noop from 'lodash/fp/noop';
import isEmpty from 'lodash/fp/isEmpty';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import DeviceQuickWhitelistingForm from './DeviceQuickWhitelistingForm';
import {
    addControlDeviceToDeliverableWhitelist,
    changeControlDeviceQuickWhitelisting,
    removeControlDeviceFromDeliverableWhitelist,
} from '~/features/devices/actions/controlDeviceQuickWhitelistingActions';
import {
    controlDeviceQuickWhitelistingSelector,
} from '~/features/devices/selectors/controlDeviceQuickWhitelistingSelectors';
import { hotkeys } from 'react-keyboard-shortcuts';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';

export const DEVICE_QUICK_WHITELISTING_MODE_INSTALL = 'INSTALL';
export const DEVICE_QUICK_WHITELISTING_MODE_UNINSTALL = 'UNINSTALL';

export class DeviceQuickWhitelistingDialog extends PureComponent {

    isValid(model) {
        return !isEmpty(model.selectedDeliverableVersion);
    }

    // eslint-disable-next-line camelcase
    hot_keys = {
        'esc': {
            priority: 1,
            handler: () => {
                this.props.hideModal();
            },
        },
    };

    renderTitle(mode) {
        if (mode === DEVICE_QUICK_WHITELISTING_MODE_INSTALL) {
            return (
                <FormattedMessage id='intl-msg:whitelistControlDevice.title'/>
            );
        } else if (mode === DEVICE_QUICK_WHITELISTING_MODE_UNINSTALL) {
            return (
                <FormattedMessage id='intl-msg:blacklistControlDevice.title'/>
            );
        }
    }

    renderBody(mode, model, serialNumber, deviceType) {
        return (
            <DeviceQuickWhitelistingForm mode={mode}
                                         model={model}
                                         serialNumber={serialNumber}
                                         deviceType={deviceType}
                                         changeControlDeviceQuickWhitelisting={payload => this.props.changeControlDeviceQuickWhitelisting(
                                             payload)}/>
        );
    }

    renderFooter(model) {
        return (
            <div>
                <button className='btn btn-default' onClick={this.props.hideModal}>
                    <FormattedMessage id='intl-msg:close'/>
                </button>
                {/*{*/}
                {/*    model.isAlreadyWhitelisted && model.selectedDeliverableType === DELIVERABLE_TYPE_APP ? <button*/}
                {/*            className={classNames('btn btn-primary', { disabled: !isReady })}*/}
                {/*            onClick={this.onUninstallDeliverable}>*/}
                {/*            {*/}
                {/*                isInProgress ?*/}
                {/*                    <Spinner text={<FormattedMessage id="intl-msg:save"/>}/> :*/}
                {/*                    <FormattedMessage id="intl-msg:removeFromWhitelist"/>*/}
                {/*            }*/}
                {/*        </button>*/}
                {/*        :*/}
                {/*        <button className={classNames('btn btn-primary', { disabled: !isReady })}*/}
                {/*                onClick={this.onInstallDeliverable}>*/}
                {/*            {*/}
                {/*                isInProgress ?*/}
                {/*                    <Spinner text={<FormattedMessage id="intl-msg:save"/>}/> :*/}
                {/*                    <FormattedMessage id="intl-msg:addToWhitelist"/>*/}
                {/*            }*/}
                {/*        </button>*/}
                {/*}*/}
            </div>
        );
    }

    render() {
        const { mode, model, serialNumber, deviceType } = this.props;
        const title = this.renderTitle(mode);
        const body = this.renderBody(mode, model, serialNumber, deviceType);
        const footer = this.renderFooter(model);
        return (
            <Dialog className='device-editor-dialog'
                    show={true}
                    showCloseButton={true}
                    onHide={this.props.hideModal}
                    title={title}
                    body={body}
                    footer={footer}
                    useOverflow
            />
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        model: controlDeviceQuickWhitelistingSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        changeControlDeviceQuickWhitelisting: payload => {
            dispatch(changeControlDeviceQuickWhitelisting(payload));
        },
        addControlDeviceToDeliverableWhitelist: payload => {
            dispatch(addControlDeviceToDeliverableWhitelist(payload));
        },
        removeControlDeviceFromDeliverableWhitelist: payload => {
            dispatch(removeControlDeviceFromDeliverableWhitelist(payload));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(hotkeys(DeviceQuickWhitelistingDialog));

DeviceQuickWhitelistingDialog.defaultProps = {
    // props
    serialNumber: '',
    mode: '',
    model: undefined,
    // functions
    addControlDeviceToDeliverableWhitelist: noop,
    changeControlDeviceQuickWhitelisting: noop,
    removeControlDeviceFromDeliverableWhitelist: noop,
};

DeviceQuickWhitelistingDialog.propTypes = {
    // props
    serialNumber: PropTypes.string,
    mode: PropTypes.string,
    model: PropTypes.object,
    // functions
    addControlDeviceToDeliverableWhitelist: PropTypes.func,
    changeControlDeviceQuickWhitelisting: PropTypes.func,
    removeControlDeviceFromDeliverableWhitelist: PropTypes.func,
};
