import getOr from 'lodash/fp/getOr';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import Group from '~/prop-types/groupPropType';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import { createControlDevice, updateControlDevice } from '../../actions/controlDeviceActions';
import { changeControlDeviceEditor } from '../../actions/controlDeviceEditorActions';
import { controlDeviceEditorSelector } from '../../selectors/controlDeviceSelectors';

import { updateDeviceGroups } from '~/features/groups/actions/groupActions';
import { groupsSelector } from '~/features/groups/selectors/groupSelectors';

import ControlDeviceEditorForm from './DeviceEditorForm';
import { hotkeys } from 'react-keyboard-shortcuts';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';

export class DeviceEditorDialog extends PureComponent {
    onSaveControlDevice = () => {
        const model = this.props.model;
        if (!this.isValid(model)) {
            return;
        }
        if (model.isNew) {
            this.props.createControlDevice(model);
        } else {
            this.props.updateControlDevice({
                ...model.originalControlDevice,
                inCustomerFleet: model.inCustomerFleet,
                updatesActive: model.updatesActive,
                testReleasesActive: model.testReleasesActive,
                description: model.description,
            });
        }
        this.props.updateDeviceGroups(model);
    }
    isValid(model) {
        return getOr(false, 'isValidSerialNumber', model);
    }
    // eslint-disable-next-line camelcase
    hot_keys = {
        'esc': {
            priority: 1,
            handler: () => {
                this.props.hideModal();
            },
        },
        'command+s': {
            priority: 1,
            handler: (event) => {
                event.preventDefault();
                this.onSaveControlDevice();
            },
        },
        'ctrl+s': {
            priority: 1,
            handler: (event) => {
                event.preventDefault();
                this.onSaveControlDevice();
            },
        },
    };
    renderTitle(model) {
        const isNew = getOr(true, 'isNew', model);
        if (isNew) {
            return <FormattedMessage id='intl-msg:createControlDevice'/>;
        }
        return (
            <FormattedMessage id='intl-msg:editControlDevice.title' values={{
                serialNumber: model.serialNumber,
            }}/>
        );
    }
    renderBody(model, groups) {
        return (
            <ControlDeviceEditorForm model={model} groups={groups}
                changeControlDeviceEditor={payload => this.props.changeControlDeviceEditor(payload)} />
        );
    }
    renderFooter(model) {
        const isInProgress = getOr(false, 'isInProgress', model);
        const isValid = this.isValid(model);
        const isReady = isValid && !isInProgress;
        return (
            <div className='btn-toolbar justify-content-end'>
                <button className='btn btn-default' onClick={this.props.hideModal}>
                    <FormattedMessage id='intl-msg:close'/>
                </button>
                <button className={classNames('btn btn-primary', { disabled: !isReady })}
                    onClick={this.onSaveControlDevice}>
                    {
                        isInProgress ?
                            <Spinner text={<FormattedMessage id='intl-msg:save' />} /> :
                            <FormattedMessage id='intl-msg:save' />
                    }
                </button>
            </div>
        );
    }
    render() {
        const { model, groups } = this.props;
        const title = this.renderTitle(model);
        const body = this.renderBody(model, groups);
        const footer = this.renderFooter(model);
        return (
            <Dialog className='device-editor-dialog'
                show={true}
                showCloseButton={true}
                onHide={this.props.hideModal}
                title={title}
                body={body}
                footer={footer}
                useOverflow
            />
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        model: controlDeviceEditorSelector(state),
        groups: groupsSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        createControlDevice: (controlDevice) => {
            dispatch(createControlDevice(controlDevice));
        },
        updateControlDevice: (controlDevice) => {
            dispatch(updateControlDevice(controlDevice));
        },
        updateDeviceGroups: (options) => {
            dispatch(updateDeviceGroups(options));
        },
        changeControlDeviceEditor: payload => {
            dispatch(changeControlDeviceEditor(payload));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(hotkeys(DeviceEditorDialog));

DeviceEditorDialog.defaultProps = {
    // props
    model: undefined,
    groups: [],
    // functions
    createControlDevice: noop,
    updateControlDevice: noop,
    updateDeviceGroups: noop,
    changeControlDeviceEditor: noop,
};

DeviceEditorDialog.propTypes = {
    // props
    model: PropTypes.object,
    groups: PropTypes.arrayOf(Group),
    // functions
    createControlDevice: PropTypes.func,
    updateControlDevice: PropTypes.func,
    updateDeviceGroups: PropTypes.func,
    changeControlDeviceEditor: PropTypes.func,
};
