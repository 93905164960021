import { createSelector } from 'reselect';
import map from 'lodash/fp/map';
import getOr from 'lodash/fp/getOr';
import {
    ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS,
    ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS_SCOPE_DEVICE,
} from '~/features/base/constants/entities';
import {
    entityStoreSelector,
    indicesStoreSelector,
    loadingStoreSelector,
} from '~/features/higherorder/selectors/dataSelectors';
import get from 'lodash/fp/get';

const uiSystemStateSelector = state => state.ui.systemState;
const deviceSystemStateNotificationsIndicesSelector = state => indicesStoreSelector(
    state)[ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS];

const systemStateNotificationsLoadingSelector = state => loadingStoreSelector(
    state)[ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS];

export const deviceSystemStateNotificationsPageNumberSelector = state => get('number',
    deviceSystemStateNotificationsPagesSelector(state));
export const deviceSystemStateNotificationsPageCountSelector = state => get('totalPages',
    deviceSystemStateNotificationsPagesSelector(state));
export const deviceSystemStateNotificationsTotalElementsSelector = state => get('totalElements',
    deviceSystemStateNotificationsPagesSelector(state));

const systemStateNotificationsEntitiesSelector = state => entityStoreSelector(
    state)[ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS];

const deviceSystemStateNotificationsPagesSelector = state => getOr({},
    ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS_SCOPE_DEVICE,
    deviceSystemStateNotificationsIndicesSelector(state));

export const deviceSystemStateNotificationsLoadingSelector = state => getOr(false,
    ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS_SCOPE_DEVICE,
    systemStateNotificationsLoadingSelector(state));

export const deviceSystemStateNotificationsSelector = createSelector(
    [deviceSystemStateNotificationsPagesSelector, systemStateNotificationsEntitiesSelector,
        deviceSystemStateNotificationsPageNumberSelector],
    (pages, entities, pageNumber) => {
        if (pages && entities) {
            return map(id => entities[id], getOr([], pageNumber, pages));
        }
        return [];
    },
);

export const deviceSystemStateNotificationsForSerialNumberSelector = (state, ownProps) => {
    const allSystemStateNotificationMessages = systemStateNotificationsEntitiesSelector(state);
    let filteredSystemStateNotificationMessages = [];
    if (allSystemStateNotificationMessages) {
        Object.values(allSystemStateNotificationMessages).map(systemStateNotificationMessage => {
            if (systemStateNotificationMessage.serialNumber === ownProps.serialNumber) {
                filteredSystemStateNotificationMessages.push(systemStateNotificationMessage);
            }
        });
    }
    return filteredSystemStateNotificationMessages;
}

export const systemStateColumnSettingsSelector = state => uiSystemStateSelector(state).columnSettings;

export const systemStateCheckedColumnSettingsSelector = state => {
    const columnSettings = uiSystemStateSelector(state).columnSettings;
    if (columnSettings) {
        return columnSettings.filter(setting => setting.checked);
    }
    return [];
}
