import React, { PureComponent } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import noop from 'lodash/fp/noop';
import { connect } from 'react-redux';
import {
    fetchSystemStateNotificationsOfDevice,
    setSystemStateColumnSettings,
} from '~/features/systemState/actions/systemStateActions';
import { systemStateColumnSettingsSelector } from '~/features/systemState/selectors/systemStateSelectors';
import {
    systemStateNotificationsColumnDescriptors,
} from '~/features/systemState/constants/systemStateNotificationColumnDescriptors';
import { ButtonDropdown, Checkbox } from '@rio-cloud/rio-uikit';

import Multiselect from '@rio-cloud/rio-uikit/lib/es/Multiselect';
import { systemStatePartitionTypes } from '~/features/systemState/constants/systemStatePartitionTypes';
import map from 'lodash/fp/map';
import includes from 'lodash/fp/includes';
import { systemStateNotificationTypes } from '~/features/systemState/constants/systemStateNotificationTypes';
import FromToFormItem from '~/features/base/components/forms/FromToFormItem';
import { defaultFilters } from '~/features/deliverables/utils/defaultFilters';
import { devicePartitions } from '~/features/devices/components/dialogs/DeviceSystemStatesDialog';
import { keepEntries } from '~/features/base/utils/keepEntries';

export class SystemStateNotificationListTableToolbar extends PureComponent {

    constructor(props) {
        super(props);
    }

    setShowColumn = (id, checked) => {
        const { systemStateColumnSettings, updateColumnSettings } = this.props;
        let columnSettings = [];
        systemStateColumnSettings.map(setting => {
            if (setting.id === id) {
                setting.checked = checked;
            }
            columnSettings.push(setting);
        });
        updateColumnSettings(
            columnSettings,
        );
    };

    onFromToChange = ({ from, to }) => {
        this.props.updateFilter({
            from,
            to,
        });
    };

    onSystemStateNotificationTypesChange = (types) => {
        this.props.updateFilter({
            types,
        });
    };

    onSystemStateNotificationPartitionsChange = (partitions) => {
        this.props.updateFilter({
            partitions,
        });
    };

    selectionByIds = values => map(option =>
        ({
            ...option,
            label: this.props.intl.formatMessage({ id: option.label }),
            selected: includes(option.id, values),
        }),
    );

    clearFilters = () => {
        this.props.updateFilter({
            ...defaultFilters,
            result: undefined,
        });
    };

    onLoadMore = () => {
        this.props.updateFilter(
            {
                size: this.props.filters.size + FETCH_UPDATE_EVENTS_AMOUNT_INCREMENT,
            },
        );
    };

    getColumnSettings = () => {
        const { systemStateColumnSettings, intl } = this.props;
       return <div className='width-250 '>
                <div className='padding-left-5 padding-top-5'>
                    <FormattedMessage   id='intl-msg:showHideColumns'/>
               </div>
                <div className='padding-10'>
                       {systemStateColumnSettings.map(columnSetting => {
                           const descriptor = systemStateNotificationsColumnDescriptors.find(
                               currentDescriptor => currentDescriptor.id === columnSetting.id);
                           return <Checkbox checked={columnSetting.checked}
                                            onClick={() => this.setShowColumn(columnSetting.id, !columnSetting.checked)}>
                                  {intl.formatMessage({ id: descriptor.label })}
                                  </Checkbox>
                       })}
                </div>
        </div>
    };

    render() {
        const { children, columnSelectorEnabled, deviceType } = this.props;
        const { filters } = this.props;
        const messageTypes = this.selectionByIds(filters.types)(systemStateNotificationTypes);
        const partitionTypes = deviceType && this.selectionByIds(filters.partitions)(keepEntries(systemStatePartitionTypes, devicePartitions[deviceType]));

        return (
            <div>
                <div className='h5'><FormattedMessage
                    id='intl-msg:controlDevice.systemstate.notifications'/>
                </div>
                <div className='display-flex justify-content-between'>
                    <div className='btn-toolbar align-content-center'>
                        {children}
                    </div>
                    <div className='display-flex row'>
                        <div className='padding-right-25'>
                            <FromToFormItem inputClassName='filter-input'
                                            from={filters.from}
                                            to={filters.to}
                                            onChange={this.onFromToChange}/>
                        </div>
                        <div className='form-group'>
                            <label className='control-label'>
                                <FormattedMessage id='intl-msg:type'/>
                            </label>
                            <Multiselect placeholder={<FormattedMessage id='intl-msg:multiSelect.prompt'/>}
                                         options={messageTypes}
                                         onChange={this.onSystemStateNotificationTypesChange}
                            />
                        </div>
                        <div className='form-group padding-left-25'>
                            <label className='control-label'>
                                <FormattedMessage id='intl-msg:partition'/>
                            </label>
                            <Multiselect placeholder={<FormattedMessage id='intl-msg:multiSelect.prompt'/>}
                                         options={partitionTypes}
                                         onChange={this.onSystemStateNotificationPartitionsChange}
                            />
                        </div>
                        <div className='padding-left-25 padding-top-25'>
                            <div className='pull-right'>
                                <a id='clear-filters-button'
                                   className='btn btn-default'
                                   onClick={this.clearFilters}>
                                    <span className='rioglyph rioglyph-trash' aria-hidden='true'></span>
                                    <FormattedMessage id='intl-msg:clearFilters'/>
                                </a>
                            </div>
                        </div>
                        {
                            columnSelectorEnabled ?
                                <div className='padding-right-5 margin-top-25 margin-left-25  btn-toolbar pull-right'>
                                    <ButtonDropdown customDropdown={this.getColumnSettings()}
                                                    title={<span className='rioglyph rioglyph-cog' aria-hidden='true'/> }
                                                    iconOnly
                                                    dropdownClassName='width-250'
                                    />
                                </div>
                                : null
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        systemStateColumnSettings: systemStateColumnSettingsSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        updateColumnSettings: (columnSettings) => {
            dispatch(setSystemStateColumnSettings(columnSettings));
        },
        fetchSystemStateNotificationsOfDevice: (fetchFilteredSystemStateNotificationOptions) => {
            dispatch(fetchSystemStateNotificationsOfDevice(fetchFilteredSystemStateNotificationOptions));
        },
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SystemStateNotificationListTableToolbar));

SystemStateNotificationListTableToolbar.defaultProps = {
    // props
    serialNumber: '',
    systemStateColumnSettings: [],
    columnSelectorEnabled: false,
    // functions
    updateColumnSettings: noop,
    updateFilter: noop
};

SystemStateNotificationListTableToolbar.propTypes = {
    // props
    serialNumber: PropTypes.string,
    systemStateColumnSettings: PropTypes.array,
    columnSelectorEnabled: PropTypes.bool,
    // functions
    updateColumnSettings: PropTypes.func,
    updateFilter: PropTypes.func,
};
