import React, { PureComponent } from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';
import PageHeader from '~/features/base/components/PageHeader';
import { ecuTypes, vinNumbers } from '~/features/fotaL3/constants/filterOptions';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import fp from 'lodash/fp';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import { controlDeviceInfoDataSelector } from '~/features/devices/selectors/controlDeviceSelectors';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import noop from 'lodash/fp/noop';
import { fetchControlDeviceInformation } from '~/features/devices/actions/controlDeviceInfoActions';
import uid from '~/features/base/utils/uid';
import DeviceRolloutStatesContainer from '~/features/devices/components/DeviceRolloutStatesContainer';
import FotaL3DeliverableList from '~/features/fotaL3/components/FotaL3DeliverableList';
import { DELIVERABLE_TYPE_APP } from '~/features/deliverables/constants/deliverablesParameters';
import {
    DEVICE_QUICK_WHITELISTING_MODE_INSTALL,
} from '~/features/devices/components/dialogs/quickWhitelisting/DeviceQuickWhitelistingDialog';
import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import { DeviceTypeLabel } from '~/features/devices/components/DeviceType';
import { TBM3, VCM } from '~/features/devices/constants/deviceTypes';
import DeviceProductionMode from '~/features/devices/components/DeviceProductionMode';
import DeviceState from '~/features/devices/components/DeviceState';
import { fetchControlDeviceRolloutStates } from '~/features/devices/actions/ui/controlDeviceDetailsActions';
import {
    fetchFilteredDeliverableIds,
    fetchFilteredDeliverables,
} from '~/features/deliverables/actions/deliverableActions';
import { TEST } from '~/features/base/constants/releaseStates';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';

export class FotaL3Details extends PureComponent {
    constructor(props) {
        super(props);
        this.name = uid();
        this.state = {
            selectedDeviceId: '',
            selectedSerialNumber: '',
            selectedVIN: '',
            selectedECU: '',
        };
    }

    onVinNumbersChange = (value) => {
        this.setState({
            selectedDeviceId: value.id,
            selectedSerialNumber: value.value,
            selectedVIN: value.label,
            selectedECU: '',
        });
        const fetchRolloutStatesOptions = {
            serialNumber: value.value,
            limit: 10,
        };
        this.props.fetchFilteredDeliverableIds(0, DELIVERABLE_TYPE_APP, '', TEST, 'xxxxx');
        this.props.registerDataInterest(this.name, [
            fetchControlDeviceInformation({ serialNumber: value.value }),
            fetchControlDeviceRolloutStates(fetchRolloutStatesOptions),
        ]);
        this.props.triggerDataFetcher();
        clearInterval(this.interval);
        // this.interval = setInterval(() => {
        //     this.props.triggerDataFetcher();
        // }, 5000);
    };

    onECUChange = (value) => {
        this.setState({
            selectedECU: value.id,
        });
        const deliverableIdPrefix = value.id === '1' ? 'rahmen' : '';
        const fetchRolloutStatesOptions = {
            serialNumber: this.state.selectedSerialNumber,
            limit: 10,
        };
        const fetchFilteredDeliverableIdsScope = {
            page: 0,
            searchCriteria: {
            deliverableType: DELIVERABLE_TYPE_APP,
                deliverableIdPrefix,
                releaseStates: [TEST],
            },
        }
        this.props.registerDataInterest(this.name, [
            fetchFilteredDeliverableIds(fetchFilteredDeliverableIdsScope),
            fetchControlDeviceRolloutStates(fetchRolloutStatesOptions),
        ]);
        this.props.triggerDataFetcher();
        clearInterval(this.interval);
        // this.interval = setInterval(() => {
        //     this.props.triggerDataFetcher();
        // }, 5000);
    };

    getPackageMdmcAppVersion = (controlDevice) => {
        let packageMdmcAppVersion = '6.0.5';
        if (controlDevice && controlDevice.lastPackageVersionsReported) {
            const packageMdmcApp = fp.find((currentPackage) => {
                return currentPackage.packageId === 'mdmc-app';
            }, controlDevice.lastPackageVersionsReported);
            if (packageMdmcApp && packageMdmcApp.packageVersion) {
                packageMdmcAppVersion = toShortSemanticVersion(packageMdmcApp.packageVersion);
            }
        }
        return packageMdmcAppVersion;
    };

    render() {
        const { controlDevicesInfo } = this.props;
        const { selectedECU, selectedDeviceId, selectedSerialNumber, selectedVIN } = this.state;
        const controlDeviceInfo = controlDevicesInfo[selectedSerialNumber];

        let vehicleIcon = 'rioglyph-truck';
        if (controlDeviceInfo?.vehicleModel) {
            switch (controlDeviceInfo.vehicleModel) {
                case 'tge':
                    vehicleIcon = 'rioglyph-van';
                    break;
                case 'etge':
                    vehicleIcon = 'rioglyph-van';
                    break;
                default:
                    vehicleIcon = 'rioglyph-truck';
            }
        }
        const type = controlDeviceInfo?.type;
        const showCheckForUpdateTriggered = false;
        const showCheckForUpdateProceeding = false;
        const canTriggerCheck4Update = true;
        const packageMdmcAppVersion = this.getPackageMdmcAppVersion(controlDeviceInfo);

        console.log('selectedVIN ' + selectedVIN);
        console.log('selectedSerialNumber ' + selectedSerialNumber);
        console.log('selectedDeviceId ' + selectedDeviceId);
        console.log('selectedECU ' + selectedECU);

        return (
            <div>
                <div className='row'>
                    <PageHeader pageTitle={<FormattedMessage id='FOTA L3 PoC'/>}/>
                </div>
                <div className='row bg-white border-style-solid border-width-1 border-color-light padding-30'>
                    <div className='col-md-2 padding-25'>
                        <h2>Select vehicle</h2>
                        <Select className='width-250 pull-right padding-right-10'
                                value={selectedDeviceId}
                                options={vinNumbers}
                                onChange={this.onVinNumbersChange}
                        />
                    </div>
                    <div className='col-md-8 padding-25'>
                        <div className='row '>
                            <div className='col-md-6'>
                                <div style={{ height: 400 }}
                                     className='rounded border-style-solid border-width-1 border-color-light padding-25 margin-right-10'>
                                    <h2>Device details</h2>
                                    <div style={{
                                        justifyContent: 'center',
                                        alignItems: 'center' }} className='col-md-12 display-flex'>
                                        {controlDeviceInfo ?
                                            this.renderDeviceDetails(vehicleIcon, controlDeviceInfo, type,
                                                showCheckForUpdateTriggered, packageMdmcAppVersion,
                                                canTriggerCheck4Update, showCheckForUpdateProceeding, selectedECU)
                                            : <DefaultWhiteColumn className=''>
                                                <NotFoundState outerClassName='border-none'
                                                               headline={<FormattedMessage id='intl-msg:nothingFound'/>}
                                                               message={'Please select vehicle'}>
                                                </NotFoundState>
                                            </DefaultWhiteColumn>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: 400 }}
                                 className='rounded col-md-6 border-style-solid border-width-1 border-color-light padding-25'>
                                <h2>Install software</h2>
                                <div style={{ marginTop: 0 }}
                                     // className='row rounded border-style-solid border-width-1 border-color-light padding-15 width-350'>
                                     className='row padding-left-15'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <h4>Selected ECU</h4>
                                        </div>
                                        <div className='col-md-4 padding-top-5'>
                                            <Select className='width-150'
                                                    value={selectedECU}
                                                    options={ecuTypes}
                                                    onChange={this.onECUChange}/>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <FotaL3DeliverableList currentControlDevice={controlDeviceInfo}
                                                     deliverableType={DELIVERABLE_TYPE_APP}
                                                     deliverableHeadline={selectedECU === '1' ? "Flash files" : "Apps" }
                                                     mode={DEVICE_QUICK_WHITELISTING_MODE_INSTALL}
                                        // selectedDeliverableId={model.selectedDeliverableId}
                                        // selectedDeliverableVersion={model.selectedDeliverableVersion}
                                                     serialNumber={selectedSerialNumber}
                                                     onDeliverableIdChange={this.onDeliverableIdChange}
                                                     onDeliverableVersionChange={this.onDeliverableVersionChange}
                                                     onLoadMore={this.onLoadMore}/>
                                </div>
                            </div>
                        </div>
                        <div className='row margin-top-25'>
                            <div
                                className='rounded border-style-solid border-width-1 border-color-light padding-20 margin-left-5'>
                                <h2>Installation history</h2>
                                <div className='overflow-y-auto height-500 padding-25'>
                                    {selectedSerialNumber !== '' ?
                                        <DeviceRolloutStatesContainer autoRefresh={false}
                                                                      serialNumber={selectedSerialNumber}></DeviceRolloutStatesContainer>
                                        : <DefaultWhiteColumn className='padding-top-20'>
                                            <NotFoundState outerClassName='border-none'
                                                           headline={<FormattedMessage id='intl-msg:nothingFound'/>}
                                                           message={''}>
                                            </NotFoundState>
                                        </DefaultWhiteColumn>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderDeviceDetails = (vehicleIcon, controlDeviceInfo, type, showCheckForUpdateTriggered, packageMdmcAppVersion,
        canTriggerCheck4Update, showCheckForUpdateProceeding, selectedECU) => {
        return (
            <div>
                <div className='row padding-top-25 padding-right-25 width-300'>
                    <div className='col-md-3 text-size-16'>
                    <span
                        className={`rioglyph ${vehicleIcon} text-size-300pct margin-top-20 margin-bottom-10 text-color-darker`}
                        aria-hidden='true'></span>
                    </div>
                    <div className='col-md-9'>
                        <div className='row text-color-darker'>
                                            <span
                                                className='text-bold text-size-20'>{controlDeviceInfo?.vin}</span>
                        </div>
                        <div className='text-color-darker'>
                                            <span
                                                className='text-bold text-size-15'>{controlDeviceInfo?.serialNumber}</span>
                        </div>
                        <div className=''>
                            <DeviceTypeLabel deviceType={type === TBM3 ? TBM3 : VCM}/>
                            <DeviceProductionMode controlDevice={controlDeviceInfo}/>
                            <DeviceState controlDevice={controlDeviceInfo}/>
                        </div>
                        <div className=''>
                                            <span className='text-color-darker text-size-12'>
                                                <FormattedMessage id={'intl-msg:lastCheckForUpdateTimestamp'}/>:
                                                <span className='padding-left-5'>
                                                    <FormattedTime
                                                        value={controlDeviceInfo?.lastCheckForUpdateTimestamp}
                                                        year='numeric'
                                                        month='2-digit' day='2-digit'/>
                                                </span>
                                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    componentWillUnmount() {
        this.props.unregisterDataInterest(this.name);
        clearInterval(this.interval);
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        controlDevicesInfo: controlDeviceInfoDataSelector(state, ownProps),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        triggerDataFetcher: () => {
            dispatch(triggerDataFetcher());
        },
        fetchControlDeviceInformation: (serialNumber) => {
            dispatch(fetchControlDeviceInformation({ serialNumber }));
        },
        fetchControlDeviceRolloutStates: (options) => {
            dispatch(fetchControlDeviceRolloutStates(options));
        },
        fetchFilteredDeliverables: (context, deliverableType, deliverableId, releaseState) => {
            dispatch(fetchFilteredDeliverables(
                {
                    page: 0,
                    searchCriteria: {
                        owningContext: context,
                        deliverableType,
                        deliverableId,
                        releaseStates: [TEST],
                    },
                },
            ));
        },
        fetchFilteredDeliverableIds: (page, type, context, releaseState, deliverableIdPrefix) => {
            const releaseStateValue = (releaseState && releaseState !== 'all') ? [releaseState] : undefined;
            dispatch(fetchFilteredDeliverableIds(
                {
                    page: (page && page > 0) ? (page - 1) : 0,
                    searchCriteria: {
                        deliverableType: type,
                        deliverableIdPrefix,
                        releaseStates: releaseStateValue,
                    },
                },
            ));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FotaL3Details);

FotaL3Details.defaultProps = {
    // props
    // functions
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    triggerDataFetcher: noop,
    fetchControlDeviceInformation: noop,
    fetchControlDeviceRolloutStates: noop,
    fetchFilteredDeliverables: noop,
    fetchFilteredDeliverableIds: noop,
};

FotaL3Details.propTypes = {
    // props
    // functions
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
    fetchControlDeviceInformation: PropTypes.func,
    fetchControlDeviceRolloutStates: PropTypes.func,
    fetchFilteredDeliverables: PropTypes.func,
    fetchFilteredDeliverableIds: PropTypes.func,
};
