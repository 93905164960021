import React from 'react';
import Select from '@rio-cloud/rio-uikit/Select';
import { SelectProps } from '@rio-cloud/rio-uikit/types';
import { FormattedMessage } from 'react-intl';

interface SelectOTAProps {
    hasError?: boolean;
}

const SelectOTA: React.FC<SelectOTAProps & Partial<SelectProps>> = (props) => {

    const highlightError = props.hasError ? 'form-group has-feedback has-error' : '';
    return (
        <>
            {props?.title && <label><FormattedMessage id={props.title}/></label>}
            <div className={highlightError}>
                <Select
                    {...props}
                /></div>
            {props.hasError &&
                <div className={'form-group has-feedback has-error  padding-bottom-15'}>
                        <span className='help-block'>
                        <span><FormattedMessage id='intl-msg:form:requiredField'/></span>
                        </span>
                </div>
            }</>
    );
};

export default SelectOTA;
