import React from "react";
import TableSearch from "@rio-cloud/rio-uikit/lib/es/TableSearch";
import MultiSelectOTA from "~/features/base/components/MultiSelectOTA";
import {FormattedMessage} from 'react-intl';
import {adaptOrderStatusOptions, adaptOrderTypesOptions} from "~/features/adaptOrders/constants/AdaptOrderOptions";
import DeliverableIdVersionSelect from "~/features/base/components/forms/DeliverableIdVersionSelect";
import SelectOTA from "~/features/base/components/forms/SelectOTA";

interface AdaptOrdersFiltersProps {
    filters: AdaptOrderFiltersModel;
    onFilterChange: (filters: AdaptOrderFiltersModel) => void;
    clearFilters: () => void;
}

const AdaptOrdersFilters: React.FC<AdaptOrdersFiltersProps> = ({filters, onFilterChange, clearFilters}) => {

    const handleOnFilterChange = (filter, value) => {
        onFilterChange({...filters, [filter]: value })
    }

    return (
        <div>
            <div className={"display-flex gap-10 width-100pct"}>
                <div className={'width-30pct'}>
                    <label><FormattedMessage id={'intl-msg:batchId'}/></label>
                    <TableSearch
                        value={filters.batchId}
                        onChange={(e) => handleOnFilterChange('batchId', e)}
                        placeholder={'Search by batch ID...'}
                    />

                </div>
                <div className={'width-30pct'}>
                    <label><FormattedMessage id={'intl-msg:jobId'}/></label>
                    <TableSearch
                             value={filters.jobId}
                             onChange={(e) => handleOnFilterChange('jobId', e)}
                             placeholder={'Search by job ID...'}/>
                </div>
                <div className={'width-40pct'}>
                    <label><FormattedMessage id={'intl-msg:logs:status'}/></label>
                    <MultiSelectOTA
                    placeholder={<FormattedMessage id={'intl-msg:logs:status'}/>}
                    options={adaptOrderStatusOptions}
                    value={ filters?.statuses ? [...filters?.statuses] : []}
                    onChange={(e) => handleOnFilterChange('statuses', e)}
                />
                </div>
            </div>
            <div className={"display-flex gap-10 width-100pct margin-top-10"}>
                <div className={'width-60pct'}>
                    <DeliverableIdVersionSelect
                        deliverableId={filters.deliverableId}
                        deliverableVersion={filters.deliverableVersion}
                        onDeliverableIdChange={(e) => handleOnFilterChange('deliverableId', e)}
                        onDeliverableVersionChange={(e) => handleOnFilterChange('deliverableVersion', e)}/>
                </div>
                <div className={'width-30pct'}>
                    <SelectOTA
                        title={'intl-msg:type'}
                        placeholder={<FormattedMessage id={'Select type'} />}
                        useFilter
                        useClear
                        options={adaptOrderTypesOptions}
                        value={[filters.type]}
                        onChange={(e) => handleOnFilterChange('type', e.value)}
                    />
                </div>
                <div className='display-flex align-items-end'>
                    <a id='clear-filters-button'
                       className='btn btn-default width-200'
                       onClick={clearFilters}>
                        <span className='rioglyph rioglyph-trash' aria-hidden='true'></span>&nbsp;
                        <FormattedMessage id='intl-msg:clearFilters'/>
                    </a>
                </div>
            </div>
        </div>

    )
}

export default AdaptOrdersFilters;
