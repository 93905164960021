import map from 'lodash/fp/map';
import isEqual from 'lodash/fp/isEqual';

import React from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';
import { ColumnDescriptorModel as RowDescriptorModel } from '~/features/base/models/ColumnDescriptorModel';

const tableClassName = 'table table-layout-fixed table-column-overflow-hidden table-head-filled table-sticky';

interface PropertiesTableProps {
    rowDescriptors: RowDescriptorModel,
    showHeader: boolean,
    className: string,
    data: any,
    historyData?: any,

}

const PropertiesTable = (props: PropertiesTableProps) => {
    const { data, className, rowDescriptors, historyData, showHeader } = props;
    if (!data) {
        return <FormattedMessage id='intl-msg:notFound'/>;
    }

    const renderRow = (rowDescriptor, data, historyData) => {
        if (rowDescriptor.fieldCondition && !rowDescriptor.fieldCondition(data)) {
            return;
        }
        const value = data[rowDescriptor.field];
        const historyValue = (historyData) ? historyData[rowDescriptor.field] : '';
        const valueTd = (<td>{rowDescriptor.format ? rowDescriptor.format(value, data) : value}</td>);

        const historyTd = historyData ?
            <td>{rowDescriptor.format ? rowDescriptor.format(historyValue, historyData) : historyValue}</td> : <></>;

        // compare tds because we need the formatted values
        const hasChanged = (historyData && !(isEqual(valueTd, historyTd)));

        const toLabel = (rowDescriptor) => {
            const { formatLabel, field, label } = rowDescriptor;
            if (formatLabel) {
                return formatLabel(field);
            }
            if (label) {
                return <FormattedMessage id={label}/>;
            }
            return <FormattedMessage id={`intl-msg:field.unknown.${field}`}/>;
        };

        return (
            <tr data-field={rowDescriptor.field} key={rowDescriptor.field} className={hasChanged ? 'success' : ''}>
                <td className='text-color-dark'>
                    {toLabel(rowDescriptor)}
                </td>
                {valueTd}
                {historyTd}
            </tr>
        );
    };
    const renderRows = (rowDescriptors, data, historyData) => {
        return map(rowDescriptor => renderRow(rowDescriptor, data, historyData), rowDescriptors);
    };
    const rows = renderRows(rowDescriptors, data, historyData);
    const historyHead = historyData ? (
        <th className='user-select-none'>
            <FormattedMessage id='intl-msg:previousValue'/>
        </th>
    ) : null;

    return (
        <table className={classNames(tableClassName, className)}>
            {showHeader ?
                <thead className='table-head'>
                <tr>
                    <th className='user-select-none'><FormattedMessage id='intl-msg:field'/></th>
                    <th className='user-select-none'><FormattedMessage id='intl-msg:value'/></th>
                    {historyHead}
                </tr>
                </thead>
                : null
            }
            <tbody>
            {rows}
            </tbody>
        </table>
    );

};

export default PropertiesTable;

