import noop from 'lodash/fp/noop';
import size from 'lodash/fp/size';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import UpdateEventsTable from '~/features/updateEvents/components/UpdateEventsTable';
import UpdateEventListTableToolbar from '~/features/updateEvents/components/UpdateEventListTableToolbar';
import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';

export class UpdateEventList extends PureComponent {
    render() {
        const {
            updateEvents,
            updateEventsLoading,
            totalElements,
            showReportButton,
            showSerialNumber,
            showDeliverableId,
            onShowReport,
            onExportUpdateEvents,
            onLoadMore,
        } = this.props;
        const showLoadMore = totalElements > updateEvents.length;

        if (!size(updateEvents) && !updateEventsLoading) {
            return (
                <DefaultWhiteColumn className='padding-top-20 padding-bottom-20'>
                    <NotFoundState fullWidth
                                   headline={<FormattedMessage id='intl-msg:nothingFound'/>}
                                   message={<FormattedMessage id='intl-msg:noUpdateEventsReportedYet'/>}/>
                </DefaultWhiteColumn>
            );
        }

        return [
            <DefaultWhiteColumn className='padding-top-20 clearfix'>
                <UpdateEventListTableToolbar
                    showReportButton={showReportButton}
                    onShowReport={onShowReport}
                    onExportUpdateEvents={onExportUpdateEvents}>
                    <span className='padding-top-5'>
                        <FormattedMessage id='intl-msg:totalUpdateEvents.phrase'
                                          values={{
                                              totalCount: totalElements,
                                          }}/>
                    </span>
                    <div className='display-inline-block padding-5'>
                        {updateEventsLoading ? <Spinner/> : null}
                    </div>
                </UpdateEventListTableToolbar>
            </DefaultWhiteColumn>,
            <DefaultWhiteColumn className='padding-top-20 padding-bottom-5'>
                <UpdateEventsTable showDeliverableId={showDeliverableId}
                                   showSerialNumber={showSerialNumber}
                                   updateEvents={updateEvents}/>
            </DefaultWhiteColumn>,
            showLoadMore &&
            <DefaultWhiteColumn className='text-center padding-bottom-20'>
                <a id='load-more-button'
                   className='btn btn-default'
                   onClick={onLoadMore}>
                    <FormattedMessage id='intl-msg:loadMore'/>
                </a>
            </DefaultWhiteColumn>,
        ];
    }
}

export default UpdateEventList;

UpdateEventList.defaultProps = {
    // props
    showReportButton: false,
    showSerialNumber: true,
    showDeliverableId: true,
    updateEvents: [],
    updateEventsLoading: false,
    totalElements: 0,
    // functions
    onShowReport: noop,
    onExportUpdateEvents: noop,
    onLoadMore: noop,
};

UpdateEventList.propTypes = {
    // props
    showReportButton: PropTypes.bool,
    showSerialNumber: PropTypes.bool,
    showDeliverableId: PropTypes.bool,
    updateEvents: PropTypes.array,
    updateEventsLoading: PropTypes.bool,
    totalElements: PropTypes.number,
    // functions
    onShowReport: PropTypes.func,
    onExportUpdateEvents: PropTypes.func,
    onLoadMore: PropTypes.func,
};
