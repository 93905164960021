import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// Routes

// Devices
import DevicesListContainer from './DevicesListContainer';
import DeviceDetailsContainer from './DeviceDetailsContainer';
import { sendGoogleAnalyticsEvent } from '~/index';
import { useDataInterestCleaner } from '~/features/base/hooks/useDataInterestCleaner';

export const DeviceDetailsRoute = ({ match }) => {
    const serialNumber = match.params.serialNumber;
    return (
        <DeviceDetailsContainer serialNumber={serialNumber}/>
    );
};
export const DevicesListContainerContainerRoute = () => {
    return (
        <DevicesListContainer/>
    );
};

export const DevicesRoute = ({ match }) => {
    useDataInterestCleaner();
    // send Google Analytics Event
    sendGoogleAnalyticsEvent('Devices Page', 'View Devices');
    return (
        <Switch>
            <Route path={`${match.path}`} component={DevicesListContainerContainerRoute}/>
            <Redirect to={`${match.url}`}/>
        </Switch>
    );
};

export default DevicesRoute;
