import React from 'react';

import { env } from '~/env';

const { isPreview } = env.featureToggles;

export const PreviewBanner = () => {

    /*if (window.location.hostname === 'ota.admin-latam.rio.cloud') {
        return (
            <div className='display-flex justify-content-center position-absolute top-0 right-5 left-5'>
                <div className='bg-highlight rounded-bottom-left rounded-bottom-right z-index-1'>
                    <div className='padding-right-10 padding-bottom-3 padding-left-10'>
                        <span className='text-size-12 text-center text-white padding-top-3'>
                            <span>{'PREVIEW version for RIO LATAM, not all services available.'}</span>
                            <a href='mailto:rio_team_mdm@rio.cloud'
                                className='btn btn-link btn-link-inline text-white text-bold margin-left-5'>
                                <span className='rioglyph rioglyph-envelope text-white'></span>
                                <span className='text-decoration-underline text-bold'>
                                    <span>{'We would appreciate your feedback!'}</span>
                                </span>
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        );
    }*/
    if (isPreview) {
        return (
            <div className='preview-banner-wrapper pointer-events-none'>
                <div className='preview-banner bg-rating-2 text-center'>
                    <span className='text-size-20 text-color-white text-uppercase text-light'>{'Preview'}</span>
                </div>
            </div>
        );
    }
    return null;
};

export default PreviewBanner;
