import React from 'react';
import { FormattedMessage } from 'react-intl';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

export const DistroType = () => {
    return (
        <OverlayTrigger placement='top' overlay={
            <Tooltip id='tooltip' className='top-right'>
                Distro
            </Tooltip>}>
            <span className={'label label-condensed label-primary'}>
                DISTRO
            </span>
        </OverlayTrigger>
    );
};

export default DistroType;
