import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import { DETAILS_PATH, DEVICES_ASSIGNED_PATH, REFERENCES_PATH } from '~/features/base/constants/routes';

const renderNavigationItem = (basePath, view, label, currentView) => {
    const className = {
        active: currentView === view,
    };
    return (
        <li key={view} className={classNames(className)}>
            <Link to={`${basePath}/${view}`}><FormattedMessage id={label} /></Link>
        </li>
    );
};
const renderNavigation = (props) => {
    const { basePath, view } = props;
    return (
        <ul className='nav nav-tabs user-select-none'>
            {renderNavigationItem(basePath, DETAILS_PATH, 'intl-msg:details', view)}
            {renderNavigationItem(basePath, DEVICES_ASSIGNED_PATH, 'intl-msg:devicesAssigned', view)}
            {renderNavigationItem(basePath, REFERENCES_PATH, 'intl-msg:references', view)}
        </ul>
    );
};

export const GroupDetailsTabs = (props) => {
    const navigation = renderNavigation(props);
    return (
        <div className='group-details-tabs row'>
            <div className='col-md-12'>
                {navigation}
            </div>
        </div>
    );
};

export default GroupDetailsTabs;

GroupDetailsTabs.defaultProps = {
    // props
    basePath: '',
    view: DETAILS_PATH,
};

GroupDetailsTabs.propTypes = {
    // props
    basePath: PropTypes.string,
    view: PropTypes.string,
};
