/**
 * Action type for showing the system parameter definition editor
 * @type {string}
 */
export const SHOW_SYS_PARAM_DEFINITION_EDITOR = 'SHOW_SYS_PARAM_DEFINITION_EDITOR';

/**
 * Action type for showing the delete system parameter definition dialog
 * @type {string}
 */
export const SHOW_DELETE_SYS_PARAM_DEFINITION_DIALOG = 'SHOW_DELETE_SYS_PARAM_DEFINITION_DIALOG';

/**
 * Action type for resetting the system parameter definition editor model
 * @type {string}
 */
export const RESET_SYS_PARAM_DEFINITION_EDITOR = 'RESET_SYS_PARAM_DEFINITION_EDITOR';

/**
 * Action type for changing the system parameter definition editor model
 * @type {string}
 */
export const CHANGE_SYS_PARAM_DEFINITION_EDITOR = 'CHANGE_SYS_PARAM_DEFINITION_EDITOR';

/**
 * Action creator for showing system parameter definition editor
 * @param {object} payload The payload
 * @returns {{type: string}} The action for showing system parameter definition editor
 */
export function showSysParamDefinitionEditor(payload) {
    return {
        type: SHOW_SYS_PARAM_DEFINITION_EDITOR,
        payload,
    };
}

/**
 * Action creator for showing system parameter definition deletion dialog
 * @param {object} payload The payload
 * @returns {{type: string}} The action for showing system parameter definition deletion dialog
 */
export function showSysParamDefinitionDeletionDialog(payload) {
    return {
        type: SHOW_DELETE_SYS_PARAM_DEFINITION_DIALOG,
        payload,
    };
}

/**
 * Action creator for resetting system parameter definition editor
 * @returns {{type: string}} The action for resetting system parameter definition editor
 */
export function resetSysParamDefinitionEditor() {
    return {
        type: RESET_SYS_PARAM_DEFINITION_EDITOR,
    };
}

/**
 * Action creator for changing system parameter definition editor
 * @param {object} payload The payload
 * @returns {{type: string}} The action for changing system parameter definition editor
 */
export function changeSysParamDefinitionEditor(payload) {
    return {
        type: CHANGE_SYS_PARAM_DEFINITION_EDITOR,
        payload,
    };
}
