//TODO y Axis scale options
//'auto' | 'linear' | 'pow' | 'sqrt' | 'log' | 'identity' | 'time' | 'band' | 'point'
// | 'ordinal' | 'quantile' | 'quantize' | 'utc' | 'sequential' | 'threshold' | Function
const xAxisOptionsCommon = {
    dataKey: 'name',
    label: { value: 'Timerange', offset: 0, position: 'bottom' },
}

const yAxisOptionsCommon = {
    width: 70,
    tickCount: 6,
    interval: 'preserveStartEnd',
    type: 'number',
    domain: [0, 'dataMax + 1000'],
    allowDataOverflow: false,
    scale: 'sqrt',
}

export const xAxisOptionsDataVolume = {
    ...xAxisOptionsCommon,
};
export const yAxisOptionsDataVolume = {
    ...yAxisOptionsCommon,
    unit: ' GB',
};

export const xAxisOptionsNoDownloads = {
    ...xAxisOptionsCommon,
};
export const yAxisOptionsNoDownloads = {
    ...yAxisOptionsCommon,
    unit: ' k',
    domain: [0, 'dataMax + 50000'],
};

export const xAxisOptionsCosts = {
    ...xAxisOptionsCommon,
};
export const yAxisOptionsCosts = {
    ...yAxisOptionsCommon,
    unit: ' €',
};
