import getOr from 'lodash/fp/getOr';
import includes from 'lodash/fp/includes';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// import { Dialog, Spinner } from 'rio-uikit';

import classNames from 'classnames';

import {
    createSysParamSystemValue,
    updateSysParamSystemValue,
} from '~/features/sysParams/actions/sysParamSystemValueActions';
import {
    fetchEditableBaseSwVersions,
} from '~/features/baseSwVersions/actions/baseSwActions';
import {
    changeSysParamSystemValueEditor,
} from '~/features/sysParams/actions/sysParamSystemValueEditorActions';

import { sysParamSystemValueEditorSelector } from '~/features/sysParams/selectors/sysParamSystemValueSelectors';
import { editableBaseSwVersionsSelector } from '~/features/baseSwVersions/selectors/baseSwVersionSelectors';

import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';

import SysParamSystemValueEditorForm
    from '~/features/sysParams/components/SysParamSystemValueEditorForm';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';

/**
 * System parameter system value editor dialog
 */
export class SysParamSystemValueEditorDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.onCreateSysParamSystemValue = this.onCreateSysParamSystemValue.bind(this);
        this.props.fetchEditableBaseSwVersions();
    }

    onCreateSysParamSystemValue() {
        const model = this.props.model;
        const hwSerial = model.hwSerial;
        const shortBaseSwVersion = toShortSemanticVersion(model.baseSoftwareVersion);
        const sysParamSystemValue = {
            shortBaseSwVersion,
            hwSerial,
            accessKey: model.accessKey,
            baseSoftwareVersion: model.baseSoftwareVersion,
            name: model.name,
            created: model.created,
            value: model.value,
        };
        const isBaseSwEditable = includes(shortBaseSwVersion, this.props.editableBaseSoftwareVersions);
        if (model.isNew || !isBaseSwEditable) {
            this.props.createSysParamSystemValue(sysParamSystemValue);
        } else {
            this.props.updateSysParamSystemValue(sysParamSystemValue);
        }
    }

    render() {
        const { model } = this.props;
        const title = this.renderTitle(model);
        const body = this.renderBody(model);
        const footer = this.renderFooter(model);
        return (
            <Dialog className='sys-param-system-value-editor-dialog'
                show={true}
                showCloseButton={true}
                onHide={this.props.hideModal}
                title={title}
                body={body}
                footer={footer}
                useOverflow
            />
        );
    }

    renderTitle({ isNew, hwSerial, baseSoftwareVersion }) {
        // TODO Check if we really need this
        const shortBaseSwVersion = toShortSemanticVersion(baseSoftwareVersion);
        return (
            <div>
                <FormattedMessage id={isNew ? 'intl-msg:definePlannedValue' : 'intl-msg:changePlannedValue'} />
                {` (${hwSerial}/${shortBaseSwVersion})`}
            </div>
        );
    }
    renderBody(model) {
        return (
            <SysParamSystemValueEditorForm model={model}
                changeSysParamSystemValueEditor={(payload) =>
                    this.props.changeSysParamSystemValueEditor(payload)} />
        );
    }
    renderFooter(model) {
        const isInProgress = getOr(false, 'isInProgress', model);
        const isReady = !isInProgress;
        return (
            <div>
                <button className='btn btn-default' onClick={this.props.hideModal}>
                    <FormattedMessage id='intl-msg:close'/>
                </button>
                <button className={classNames('btn btn-primary', { disabled: !isReady })}
                    onClick={() => this.onCreateSysParamSystemValue()}>
                    {
                        isInProgress ?
                            <Spinner text={<FormattedMessage id='intl-msg:save' />} /> :
                            <FormattedMessage id='intl-msg:save' />
                    }
                </button>
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        model: sysParamSystemValueEditorSelector(state),
        editableBaseSoftwareVersions: editableBaseSwVersionsSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        fetchEditableBaseSwVersions: () => {
            dispatch(fetchEditableBaseSwVersions());
        },
        createSysParamSystemValue: (sysParamSystemValue, sysParamDefaultValue) => {
            dispatch(createSysParamSystemValue(sysParamSystemValue, sysParamDefaultValue));
        },
        updateSysParamSystemValue: (sysParamSystemValue, sysParamDefaultValue) => {
            dispatch(updateSysParamSystemValue(sysParamSystemValue, sysParamDefaultValue));
        },
        changeSysParamSystemValueEditor: payload => {
            dispatch(changeSysParamSystemValueEditor(payload));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SysParamSystemValueEditorDialog);

SysParamSystemValueEditorDialog.defaultProps = {
    model: {},
    editableBaseSoftwareVersions: [],
    fetchEditableBaseSwVersions: noop,
    createSysParamSystemValue: noop,
    updateSysParamSystemValue: noop,
    changeSysParamSystemValueEditor: noop,
};

SysParamSystemValueEditorDialog.propTypes = {
    // props
    model: PropTypes.object,
    editableBaseSoftwareVersions: PropTypes.array,
    // functions
    fetchEditableBaseSwVersions: PropTypes.func,
    createSysParamSystemValue: PropTypes.func,
    updateSysParamSystemValue: PropTypes.func,
    changeSysParamSystemValueEditor: PropTypes.func,
};
