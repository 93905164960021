
export const HIDE_SESSION_EXPIRED_DIALOG = 'app/HIDE_SESSION_EXPIRED_DIALOG';
export const TAB_VISIBILITY_CHANGED = 'app/TAB_VISIBILITY_CHANGED';

export const hideSessionExpiredDialog = () => ({
    type: HIDE_SESSION_EXPIRED_DIALOG,
});

export const onTabVisibilityChanged = (isVisible) => ({
    type: TAB_VISIBILITY_CHANGED,
    isVisible,
});
