import React, { PureComponent } from 'react';
import noop from 'lodash/fp/noop';
import size from 'lodash/fp/size';
import map from 'lodash/fp/map';
import concat from 'lodash/fp/concat';
import uid from '~/features/base/utils/uid';
import { connect } from 'react-redux';
import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';
import PropTypes from 'prop-types';
import {
    reportsBillingInitialDataSelector,
} from '~/features/reportsbilling/selectors/reportsBillingSelectors';
import BarChart from '@rio-cloud/rio-uikit/lib/es/BarChart';
import Bar from '@rio-cloud/rio-uikit/lib/es/Bar';
import {
    chartDomains,
    createIntermediateObject,
    deliverableTypeKeys,
    createTotalChartsData, additionalTypeKeys,
} from '~/features/reportsbilling/utils/processRawChartData';
import
    ReportsBillingChartsDialogContainer
    from '~/features/reportsbilling/components/ReportsBillingChartsDialogContainer';
import ReportsBillingInfoBoxContentLoader
    from '~/features/reportsbilling/components/ReportsBillingInfoBoxContentLoader';
import {
    xAxisOptionsCosts,
    xAxisOptionsDataVolume,
    xAxisOptionsNoDownloads,
    yAxisOptionsCosts,
    yAxisOptionsDataVolume,
    yAxisOptionsNoDownloads,
} from '~/features/reportsbilling/utils/axisDefinitions';

const chartsData = {};
const chartColors = {
    Distros: ['color-warmup-charm', 'color-warmup-salmon', 'color-warmup-cherokee', 'color-warmup-corn'],
    Apps: ['color-coldplay-aubergine', 'color-coldplay-kashmir', 'color-coldplay-fountain',
        'color-coldplay-turquoise'],
    Files: ['color-coldplay-bermuda', 'color-coldplay-moos', 'color-coldplay-primrose', 'color-coldplay-khaki'],
    CheckForUpdate: ['color-warmup-salmon'],
    LogFile: ['color-warmup-raspberry'],
    // SystemParameter: ['color-warmup-victoria'],
};
const legendColors = {
    Distros: { backgroundColor: 'rgb(201, 119, 141)' }, //color-warmup-charm
    Apps: { backgroundColor: 'rgb(90, 72, 118)' }, //color-coldplay-aubergine
    Files: { backgroundColor: 'rgb(121, 212, 179)' }, //color-coldplay-bermuda
    CheckForUpdate: { backgroundColor: 'rgb(241, 149, 136)' }, //color-warmup-salmon
    LogFile: { backgroundColor: 'rgb(178, 54, 114)' }, //color-warmup-raspberry
    // SystemParameter: { backgroundColor: 'rgb(73, 61, 109)' }, //color-warmup-victoria
};
const chartTitles = {
    bytes: 'Data Volume',
    requests: 'Downloads',
    costs: 'Costs',
};
const barSize = 15;

export class ReportsBillingCharts extends PureComponent {
    constructor(props) {
        super(props);
        this.name = uid();
        this.state = {
            show: false,
            selectedChartType: chartTitles['bytes'],
            selectedDomain: 'bytes',
            selectedTimeValue: `${new Date().getFullYear()}-${new Date().getMonth() + 1}`,
            deliverableType: 'distros',
            requestParams: this.props.requestParams,
        };
    }

    createTotalChartsBars() {
        const totalChartsBars = {};
        map((domain) => {
            totalChartsBars[domain] = this.createBars(domain);
        }, chartDomains);
        return totalChartsBars;
    }

    createBars(domain) {
        let bars = [];
        map((deliverableType) => {
            if (deliverableType === 'Files') {
                const id = `${deliverableType}-${domain}-${Date.now()}`;
                bars = concat(bars, this.createStackedBar(id, deliverableType, domain));
                const color = chartColors[deliverableType][0];
                bars.push(this.createStackedBarPart(id, deliverableType, color, domain));
            } else {
                bars.push(this.createSingleBar(deliverableType, domain));
            }
        }, deliverableTypeKeys);
        return bars;
    }

    createStackedBar(id, deliverableType, domain) {
        const bars = [];
        map((additionalType) => {
            const color = chartColors[additionalType][0];
            const bar = this.createStackedBarPart(id, additionalType, color, domain);
            bars.push(bar);
        }, additionalTypeKeys);
        return bars;
    }

    createStackedBarPart(id, key, color, domain) {
        return <Bar key={`${key}`}
                    dataKey={`${key}`}
                    id={`${id}`}
                    stackId={`${id}`}
                    barSize={{ barSize }}
                    color={`${color}`}
        />;
    }

    createSingleBar(type, domain) {
        return <Bar key={`${type}`}
                    dataKey={`${type}`}
                    id={`${type}-${domain}-${Date.now()}`}
                    barSize={{ barSize }}
                    color={`${chartColors[type][0]}`}
                    onClick={(data) => this.onBarClick(data, domain)}
        />;
    }

    onBarClick(data, domain) {
        if (data.tooltipPayload[0].name === 'files') {
            return;
        }
        const timeValue = data.payload.value;
        const deliverableType = data.tooltipPayload[0].dataKey;

        this.setState({
            show: true,
            selectedChartType: chartTitles[domain],
            selectedDomain: domain,
            selectedTimeValue: timeValue,
            deliverableType: deliverableType,
        });
    }

    onCloseDialog = () => {
        this.setState({ show: false });
    };

    createLegend() {
        const classes = 'width-10 height-10 margin-right-5 margin-top-5';
        return ([
            <div className='display-flex col-md-12'>
                <div className={'col-md-4'}>
                    <div className={'display-flex'}>
                        <div className={classes} style={legendColors.Distros}></div>
                        <div>Distros</div>
                    </div>
                </div>
                <div className={'col-md-4'}>
                    <div className={'display-flex'}>
                        <div className={classes} style={legendColors.Apps}></div>
                        <div>Apps</div>
                    </div>
                </div>
                <div className={'col-md-4'}>
                    <div className={'display-flex'}>
                        <div className={classes} style={legendColors.Files}></div>
                        <div>Files</div>
                    </div>
                    <div className={'display-flex'}>
                        <div className={classes} style={legendColors.CheckForUpdate}></div>
                        <div>C4U</div>
                    </div>
                    <div className={'display-flex'}>
                        <div className={classes} style={legendColors.LogFile}></div>
                        <div>LogFile</div>
                    </div>
                </div>
            </div>
        ]);
    }

    createDialog() {
        const dialogParams = {
            show: this.state.show,
            close: this.onCloseDialog,
            chartType: this.state.selectedChartType,
            domain: this.state.selectedDomain,
            month: this.state.selectedTimeValue,
            deliverableType: this.state.deliverableType,
            requestParams: this.state.requestParams,
        };

        return (
            <ReportsBillingChartsDialogContainer dialogParams={dialogParams}/>
        );
    }

    render() {
        const { rawChartsData } = this.props;
        const chartDialog = this.createDialog();
        let legend = null;
        chartsData.totalChartsData = {};
        chartsData.totalChartsBars = {};

        if (size(rawChartsData) > 0) {

            const intermediate = [];
            map((item) => {
                intermediate.push(createIntermediateObject(item));
            }, rawChartsData);
            chartsData['intermediateObj'] = intermediate;
            chartsData.totalChartsData = createTotalChartsData(intermediate);
            chartsData.totalChartsBars = this.createTotalChartsBars();
            legend = this.createLegend();
        }

        return (
            <DefaultWhiteColumn className='padding-top-25 clearfix min-height-75vh max-height-75vh'>
                <div className='row'>
                    <div className='col-md-12 bg-white justify-content-end'>
                        <div className='row'>
                            <div className='col-md-10 bg-white'><h3>Totals</h3></div>
                            <div className='col-md-2 bg-white display-flex align-items-end'>
                                {size(rawChartsData) > 0 && legend}
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12 bg-white'>
                        <div className='row'>
                            <div className='col-md-4 bg-white'>
                                {size(rawChartsData) > 0 ?
                                    <div>
                                        <h4>{chartTitles.bytes}</h4>
                                        <div
                                            className='panel panel-default panel-body margin-bottom-0 height-400 max-width-800'>
                                            <BarChart
                                                data={chartsData.totalChartsData.bytes}
                                                dataKey='name'
                                                showGrid={true}
                                                tooltip={true}
                                                gridOptions={{ vertical: false }}
                                                margin={{ bottom: 15 }}
                                                xAxisOptions={xAxisOptionsDataVolume}
                                                yAxisOptions={yAxisOptionsDataVolume}
                                                bars={
                                                    chartsData.totalChartsBars.bytes
                                                }
                                            />
                                        </div>
                                    </div>
                                    :
                                    <ReportsBillingInfoBoxContentLoader/>
                                }
                            </div>
                            <div className='col-md-4 bg-white'>
                                {size(rawChartsData) > 0 ?
                                    <div>
                                        <h4>{chartTitles.requests}</h4>
                                        <div
                                            className='panel panel-default panel-body margin-bottom-0 height-400 max-width-800'>
                                            <BarChart
                                                data={chartsData.totalChartsData.requests}
                                                dataKey='name'
                                                showGrid={true}
                                                tooltip={true}
                                                margin={{ bottom: 15 }}
                                                gridOptions={{ vertical: false }}
                                                xAxisOptions={xAxisOptionsNoDownloads}
                                                yAxisOptions={yAxisOptionsNoDownloads}
                                                bars={
                                                    chartsData.totalChartsBars.requests
                                                }
                                            />
                                        </div>
                                    </div>
                                    :
                                    <ReportsBillingInfoBoxContentLoader/>
                                }
                            </div>
                            <div className='col-md-4 bg-white position-relative'>
                                {size(rawChartsData) > 0 ?
                                    <div>
                                        <h4>{chartTitles.costs}</h4>
                                        <div
                                            className='panel panel-default panel-body margin-bottom-0 height-400 max-width-800'>
                                            <BarChart
                                                data={chartsData.totalChartsData.costs}
                                                dataKey='name'
                                                showGrid={true}
                                                tooltip={true}
                                                gridOptions={{ vertical: false }}
                                                margin={{ bottom: 15 }}
                                                xAxisOptions={xAxisOptionsCosts}
                                                yAxisOptions={yAxisOptionsCosts}
                                                bars={
                                                    chartsData.totalChartsBars.costs
                                                }
                                            />
                                        </div>
                                    </div>
                                    :
                                    <ReportsBillingInfoBoxContentLoader/>
                                }
                            </div>
                        </div>
                    </div>
                    {this.state.show && chartDialog}
                </div>
            </DefaultWhiteColumn>
        );
    }
}

const mapStateToProps = (state) => ({
    rawChartsData: reportsBillingInitialDataSelector(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsBillingCharts);

ReportsBillingCharts.defaultProps = {
    // props
    rawChartsData: noop,
    search: '',
    // functions
    followRoute: noop,
};

ReportsBillingCharts.propTypes = {
    // props
    rawChartsData: PropTypes.array,
    search: PropTypes.string,
    // functions
    followRoute: PropTypes.func,
};
