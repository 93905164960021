import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import omit from 'lodash/fp/omit';

import { put, all, call, takeEvery } from 'redux-saga/effects';

import download from 'downloadjs';

import {
    EXPORT_SYS_PARAMS,
    IMPORT_SYS_PARAMS,
    DELETE_SYS_PARAM,
} from '~/features/sysParams/actions/sysParamsActions';
import {
    createSysParamDefinition,
    deleteSysParamDefinition,
} from '~/features/sysParams/actions/sysParamDefinitionActions';
import {
    createSysParamDefaultValue,
    deleteSysParamDefaultValue,
} from '~/features/sysParams/actions/sysParamDefaultValueActions';

import { hideModal } from '~/features/base/actions/ui/modalsActions';

import {
    doCreateSysParamDefinitionSaga,
    doDeleteSysParamDefinitionSaga,
} from '~/features/sysParams/sagas/sysParamDefinitionsSaga';
import {
    doCreateSysParamDefaultValueSaga,
    doDeleteSysParamDefaultValueSaga,
} from '~/features/sysParams/sagas/sysParamDefaultValuesSaga';

import { finishedAction } from '~/features/higherorder/transforms/actionTransforms';

export function* doExportSysParamsSaga(action) {
    const sysParams = action.payload.sysParams;

    const result = yield map(sysParam => {
        const value = get('value', sysParam.defaultValue);
        return {
            ...omit([
                'baseSoftwareVersion',
                'created',
                'lastChangeBy',
                'shortBaseSwVersion',
                'parsedCreated',
                'defaultValue',
            ], sysParam),
            defaultValue: value,
        };
    }, sysParams);
    const resultJSON = JSON.stringify(result, undefined, 4);
    download(resultJSON, 'sysparams.json', 'application/json');
}

export function* doImportSysParamsSaga(action) {
    const definitions = action.payload.sysParamDefinitions;
    const defaultValues = action.payload.sysParamDefaultValues;
    const baseSoftwareVersion = action.payload.baseSoftwareVersion;
    const createSysParamDefinitions = map(definition => {
        return call(doCreateSysParamDefinitionSaga,
            createSysParamDefinition({
                ...definition,
                baseSoftwareVersion,
            })
        );
    }, definitions);
    const createSysParamDefaultValues = map(defaultValue => {
        return call(doCreateSysParamDefaultValueSaga,
            createSysParamDefaultValue({
                ...defaultValue,
                baseSoftwareVersion,
            })
        );
    }, defaultValues);
    // Looks like generator.next only accepts one argument
    // So couldn't provide both in test
    yield all(createSysParamDefinitions);
    yield all(createSysParamDefaultValues);

    yield put(hideModal());
    yield put(finishedAction(action.type));

}

export function* doDeleteSysParamSaga(action) {
    const definition = action.payload.sysParamDefinition;
    const defaultValue = action.payload.sysParamDefaultValue;
    // first delete defaultValue if not undefined
    if (defaultValue) {
        yield call(doDeleteSysParamDefaultValueSaga,
            deleteSysParamDefaultValue(defaultValue)
        );
    }
    // second delete definition
    yield call(doDeleteSysParamDefinitionSaga,
        deleteSysParamDefinition(definition)
    );
    // Looks like generator.next only accepts one argument
    // So couldn't provide both in test
    yield put(finishedAction(action.type));
}

export function* exportSysParamsSaga() {
    yield takeEvery(EXPORT_SYS_PARAMS, doExportSysParamsSaga);
}

export function* importSysParamsSaga() {
    yield takeEvery(IMPORT_SYS_PARAMS, doImportSysParamsSaga);
}

export function* deleteSysParamSaga() {
    yield takeEvery(DELETE_SYS_PARAM, doDeleteSysParamSaga);
}
