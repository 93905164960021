import React from 'react';

import GroupChip from '~/features/groups/components/GroupChip';
import GroupType from '~/features/groups/components/GroupType';
import FieldOrNoData from '~/features/base/components/FieldOrNoData';
import { FormattedNumber, FormattedTime } from 'react-intl';
import uid from '~/features/base/utils/uid';

export const groupColumnDescriptors = [{
    id: 'name',
    field: 'name',
    sortable: true,
    label: 'intl-msg:name',
    format: (field, item) => [
        <GroupChip key={uid()} group={item}/>,
    ],
}, {
    id: 'type',
    field: 'type',
    sortable: true,
    label: 'intl-msg:type',
    format: field => <GroupType groupType={field}/>,
}, {
    id: 'description',
    field: 'description',
    label: 'intl-msg:description',
    className: 'hidden-xs hidden-ls',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'groupSize',
    sortable: true,
    field: 'groupSize',
    label: 'intl-msg:devicesAssigned',
    format: field => <FormattedNumber value={field}/>,
}, {
    id: 'creationDate',
    sortable: true,
    field: 'creationDate',
    label: 'intl-msg:creationTime',
    format: field => <FormattedTime value={field} year='numeric' month='2-digit' day='2-digit'/>,
}, {
    id: 'updated',
    sortable: true,
    field: 'updated',
    label: 'intl-msg:updateDate',
    format: field => <FormattedTime value={field} year='numeric' month='2-digit' day='2-digit'/>,
}, {
    id: 'creator',
    field: 'creator',
    label: 'intl-msg:creator',
}];
