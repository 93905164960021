import React from 'react';
import FieldOrNoData from '~/features/base/components/FieldOrNoData';
import TechnicalInfoDetails from "~/features/deliverables/components/TechnicalInfoDetails";
import NoData from "~/features/base/components/NoData";
import UdfDataDetails from "~/features/adaptOrders/components/UdfDataDetails";
import CommaSeparatedList from "~/features/base/components/CommaSeparatedList";

export const AdaptOrderDetailsDescriptors = [
    {
        field: 'ivd',
        label: 'intl-msg:ivd',
        format: (field) => <FieldOrNoData field={field} />,
    },{
            field: 'correlationId',
            label: 'intl-msg:correlationId',
            format: (field) => <FieldOrNoData field={field} />,
        },
    {
        field: 'batchId',
        label: 'intl-msg:batchId',
        format: (field) => <FieldOrNoData field={field} />,
    },
    {
        field: 'jobId',
        label: 'intl-msg:jobId',
        format: (field) => <FieldOrNoData field={field} />,
    },
    {
        field: 'tiIds',
        label: 'intl-msg:technicalInformation',
        format: field => {
            if (!!field && (field?.tiIds?.length > 0) ){
                return (<TechnicalInfoDetails technicalInformation={field.tiIds[0]}/>);
            }
            return <NoData/>
        },
    },
    {
        field: 'udfData',
        label: 'intl-msg:udfData',
        format: (field) => <UdfDataDetails field={field} />,
    },
    {
        field: 'errors',
        label: 'intl-msg:errors',
        format: (field) => {
            if (!field && !field?.length) return <NoData/>
            return <CommaSeparatedList>{field}</CommaSeparatedList>
        },
    }
    ]
;
