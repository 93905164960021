import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React from 'react';

import PropertiesTable from '~/features/base/components/table/PropertiesTable';
import PageNavigationComponent from "~/features/base/components/PageNavigationComponent";

export const DeliverableHistoryTable = (props) => {
    const { deliverable, deliverableHistory, rowDescriptors, activePage, pageCount, onShowPage } = props;
    return (
        <div className='DistroHistoryTable'>
            <PropertiesTable data={deliverable} historyData={deliverableHistory} rowDescriptors={rowDescriptors}/>
            <PageNavigationComponent activePage={activePage} pageCount={pageCount} loadNewPage={onShowPage}/>
        </div>
    );
};

export default DeliverableHistoryTable;

DeliverableHistoryTable.defaultProps = {
    // props
    deliverable: {},
    deliverableHistory: {},
    rowDescriptors: [],
    activePage: 0,
    pageCount: 1,
    // functions
    onShowPage: noop,
};

DeliverableHistoryTable.propTypes = {
    // props
    deliverable: PropTypes.object,
    deliverableHistory: PropTypes.object,
    rowDescriptors: PropTypes.array,
    activePage: PropTypes.number,
    pageCount: PropTypes.number,
    // functions
    onShowPage: PropTypes.func,
};
