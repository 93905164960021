import trim from 'lodash/fp/trim';
import noop from 'lodash/fp/noop';

import classNames from 'classnames';

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { validatePartialHwSerial } from '~/features/base/utils/hwSerialValidator';

export class DeliverableNamePrefixFormItem extends Component {

    onChange = (event) => {
        const deliverableNamePrefix = trim(event.target.value);
        this.props.onChange({
            deliverableNamePrefix: deliverableNamePrefix || undefined,
        });
    }

    render() {
        const { label, value } = this.props;
        return (
            <div className={classNames('form-group')}>
                <label className='control-label'>
                    <FormattedMessage id={label}/>
                </label>
                <span className='input-group width-100pct'>
                    <span className='input-group-addon'>
                        <span className='rioglyph rioglyph-search' aria-hidden='true'></span>
                    </span>
                    <FormattedMessage id='intl-msg:deliverableNamePrefix.prompt'>
                        { placeHolder =>
                            <input className='form-control'
                                type='text'
                                value={value}
                                placeholder={placeHolder}
                                onChange={this.onChange}/>
                        }
                    </FormattedMessage>
                </span>
            </div>

        );
    }
}

export default DeliverableNamePrefixFormItem;

DeliverableNamePrefixFormItem.defaultProps = {
    // props
    value: '',
    label: 'intl-msg:deliverableNamePrefix',
    // functions
    onChange: noop,
};

DeliverableNamePrefixFormItem.propTypes = {
    // props
    value: PropTypes.string,
    label: PropTypes.string,
    // functions
    onChange: PropTypes.func,
};
