import noop from 'lodash/fp/noop';
import getOr from 'lodash/fp/getOr';
import size from 'lodash/fp/size';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import JiraIssueProjectNameFormItem from '~/features/atlassian/components/dialogs/JiraIssueProjectNameFormItem';
import classNames from 'classnames';
import ListTable from '~/features/base/components/table/ListTable';
import { existingIssueColumnDescriptors } from '~/features/atlassian/constants/issueColumnDescriptors';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';


/**
 * Jira issue editor form
 */
export class JiraIssueEditorForm extends PureComponent {
    constructor() {
        super();
        this.summaryInput = React.createRef();
    }

    onJiraProjectNameChange = (jiraProjectName) => {
        this.props.changeJiraIssueEditor({
            jiraProjectName,
        });
    };

    onDescriptionChange = (event) => {
        this.props.changeJiraIssueEditor({
            description: event.target.value,
        });
    };

    onSummaryChange = (event) => {
        this.props.changeJiraIssueEditor({
            summary: event.target.value,
        });
    };

    isValid(model) {
        return size(this.props.model.summary) > 0;
    }

    render() {
        const { model, onCreateJiraIssue } = this.props;
        const summaryInput = this.renderSummaryInput(model);
        const jiraBoardNameInput = this.renderJiraBoardNameInput(model);
        const descriptionInput = this.renderDescriptionInput(model);
        const isInProgress = getOr(false, 'isInProgress', model);
        const isValid = this.isValid(model);
        const isReady = isValid && !isInProgress;
        return (
            <form>
                <div className='shadow-smooth padding-10'>
                    <h3><FormattedMessage id='intl-msg:createJiraBugTicket'/></h3>
                    {jiraBoardNameInput}
                    {summaryInput}
                    {descriptionInput}
                    <div className='text-right'>
                        <button className={classNames('btn btn-primary', { disabled: !isReady })}
                                onClick={onCreateJiraIssue}>
                            {
                                isInProgress ?
                                    <Spinner text={<FormattedMessage id='intl-msg:save'/>}/> :
                                    <FormattedMessage id='intl-msg:create'/>
                            }
                        </button>
                    </div>
                </div>
                <div className='padding-10'>
                    <h3><FormattedMessage id='intl-msg:jiraExistingBugTickets'/></h3>
                    {
                        <ListTable preventRowClickEventPropagation={false}
                                   items={model.existingJiraIssues}
                                   itemKey='fileName'
                                   columnDescriptors={existingIssueColumnDescriptors}/>
                    }
                </div>
            </form>
        );
    }

    renderJiraBoardNameInput(model) {
        return (
            <div className='form-group form-group-in-customer-fleet'>
                <div>
                    <JiraIssueProjectNameFormItem value={model.jiraProjectName}
                                                  onChange={this.onJiraProjectNameChange}/>
                </div>
            </div>
        );
    }

    renderSummaryInput(model) {
        return (
            <div className='form-group form-group-summary'>
                <label className='control-label'>
                    <FormattedMessage id='intl-msg:jiraSummary'/>
                </label>
                <FormattedMessage id='intl-msg:title.prompt'>
                    {placeHolder =>
                        <input type='text'
                               ref={this.summaryInput}
                               className='form-control'
                               value={model.summary}
                               placeholder={placeHolder}
                               onChange={this.onSummaryChange}/>
                    }
                </FormattedMessage>
            </div>
        );
    }

    renderDescriptionInput(model) {
        return (
            <div className='form-group form-group-description'>
                <label className='control-label'>
                    <FormattedMessage id='intl-msg:description'/>
                </label>
                <FormattedMessage id='intl-msg:description.prompt'>
                    {placeHolder =>
                        <textarea className={classNames('form-control')}
                                  value={model.description}
                                  placeholder={placeHolder}
                                  style={{ height: '150px' }}
                                  onChange={this.onDescriptionChange}/>
                    }
                </FormattedMessage>
            </div>
        );
    }

    componentDidMount() {
        this.summaryInput.current.focus();
    }
}

export default JiraIssueEditorForm;

JiraIssueEditorForm.defaultProps = {
    // props
    model: {},
    // functions
    changeJiraIssueEditor: noop,
    onCreateJiraIssue: noop,
};

JiraIssueEditorForm.propTypes = {
    // props
    model: PropTypes.object,
    // functions
    changeJiraIssueEditor: PropTypes.func,
    onCreateJiraIssue: PropTypes.func,
};
