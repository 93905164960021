import { call, put, takeLatest } from 'redux-saga/effects';

import { showModal } from '~/features/base/actions/ui/modalsActions';
import { fetchFilteredGroups } from '~/features/groups/actions/groupActions';

import { doFetchFilteredGroupsSaga } from '~/features/groups/sagas/groupSaga';
import {
    changeDeliverableEditor,
    resetDeliverableEditor,
    SHOW_DELIVERABLE_DELETION_DIALOG,
    SHOW_DELIVERABLE_EDITOR_DIALOG,
    SHOW_DELIVERABLE_FILE_DELETION_DIALOG,
} from '~/features/artifacts/actions/deliverableActions';
import {
    DELIVERABLE_DELETE_MODAL,
    DELIVERABLE_EDITOR_MODAL,
    DELIVERABLE_FILE_DELETE_MODAL,
} from '~/features/base/constants/modals';

export function* doPrepareForNewDeliverableSaga(fileDeliverable) {
    yield put(changeDeliverableEditor({
        isNew: true,
        ...fileDeliverable,
    }));
    // Should suggest next patch  version
}

// export function* doPrepareForExistingDeliverableSaga(fileDeliverable) {
//     const networkCodePreset = guessNetworkCodePreset(fileDeliverable.networkCodeWhitelist);
//     yield put(changeDeliverableEditor({
//         isNew: false,
//         ...fileDeliverable,
// whitelistingInfo: {
//     ...fileDeliverable.whitelistingInfo,
//     isValidDeviceWhitelist: true,
//     isValidDeviceBlacklist: true,
// },
// networkCodePreset: networkCodePreset,
// networkCodeWhitelist: networkCodePresets[networkCodePreset].whitelist,
//     }));
// }

export function* doShowDeliverableEditorDialogSaga(action) {
    yield put(resetDeliverableEditor());
    if (action.payload.isNew) {
        yield call(doPrepareForNewDeliverableSaga, action.payload);
        // } else {
        //     yield call(doPrepareForExistingDeliverableSaga, action.payload);
    }

    yield call(doFetchFilteredGroupsSaga, fetchFilteredGroups());

    yield put(showModal({
        modalType: DELIVERABLE_EDITOR_MODAL,
        modalProps: {},
    }));
}

export function* doShowDeliverableDeletionDialogSaga(action) {
    yield put(resetDeliverableEditor());
    const { deliverableId, deliverableName } = action.payload;
    yield put(showModal({
        modalType: DELIVERABLE_DELETE_MODAL,
        modalProps: {
            deliverableId,
            deliverableName,
        },
    }));
}

export function* doShowDeliverableFileDeletionDialogSaga(action) {
    yield put(resetDeliverableEditor());
    const { deliverableId, fileName } = action.payload;
    yield put(showModal({
        modalType: DELIVERABLE_FILE_DELETE_MODAL,
        modalProps: {
            deliverableId,
            fileName,
        },
    }));
}

export function* showDeliverableEditorDialogSaga() {
    yield takeLatest(SHOW_DELIVERABLE_EDITOR_DIALOG, doShowDeliverableEditorDialogSaga);
}

export function* showDeliverableDeletionDialogSaga() {
    yield takeLatest(SHOW_DELIVERABLE_DELETION_DIALOG, doShowDeliverableDeletionDialogSaga);
}

export function* showDeliverableFileDeletionDialogSaga() {
    yield takeLatest(SHOW_DELIVERABLE_FILE_DELETION_DIALOG, doShowDeliverableFileDeletionDialogSaga);
}
