import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import {
    createSysParamSystemValue,
    deleteSysParamSystemValue,
} from '~/features/sysParams/actions/sysParamSystemValueActions';

import { fetchEditableBaseSwVersions } from '~/features/baseSwVersions/actions/baseSwActions';

import { sysParamSystemValueEditorSelector } from '~/features/sysParams/selectors/sysParamSystemValueSelectors';
import { editableBaseSwVersionsSelector } from '~/features/baseSwVersions/selectors/baseSwVersionSelectors';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';

/**
 * System parameter system value deletion dialog
 */
export class SysParamSystemValueDeletionDialog extends PureComponent {

    onDeleteSysParamSystemValue = () => {
        const { model, editableBaseSoftwareVersions } = this.props;
        const {
            hwSerial,
            accessKey,
            baseSoftwareVersion,
            name,
            created,
            defaultValue,
        } = model;
        const shortBaseSwVersion = toShortSemanticVersion(baseSoftwareVersion);
        const sysParamSystemValue = {
            accessKey,
            baseSoftwareVersion,
            shortBaseSwVersion,
            hwSerial,
            name,
            created,
            value: defaultValue,
        };
        // const isBaseSwEditable = includes(shortBaseSwVersion, editableBaseSoftwareVersions);
        // if (!isBaseSwEditable) {
        //     this.props.createSysParamSystemValue(sysParamSystemValue);
        // } else {
        this.props.deleteSysParamSystemValue(sysParamSystemValue);
        // }
    };

    render() {
        const { model } = this.props;
        const { hwSerial, name, baseSoftwareVersion } = model;
        const shortBaseSwVersion = toShortSemanticVersion(baseSoftwareVersion);
        return (
            <ConfirmationDialog className='sys-param-system-value-deletion-dialog'
                                show={true}
                                title={<FormattedMessage id='intl-msg:clearPlannedValue'/>}
                                content={<FormattedMessage id='intl-msg:clearPlannedValue.prompt' values={{
                                    name: name,
                                    serialNumber: hwSerial,
                                    shortBaseSwVersion,
                                }}/>}
                                cancelButtonText={<FormattedMessage id='intl-msg:cancel'/>}
                                confirmButtonText={<FormattedMessage id='intl-msg:ok'/>}
                                onClickCancel={this.props.hideModal}
                                onClickConfirm={this.onDeleteSysParamSystemValue}/>
        );
    }

    componentWillMount() {
        this.props.fetchEditableBaseSwVersions();
    }
}

export const mapStateToProps = (state) => {
    return {
        model: sysParamSystemValueEditorSelector(state),
        editableBaseSoftwareVersions: editableBaseSwVersionsSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        fetchEditableBaseSwVersions: () => {
            dispatch(fetchEditableBaseSwVersions());
        },
        createSysParamSystemValue: (sysParamSystemValue) => {
            dispatch(createSysParamSystemValue(sysParamSystemValue));
        },
        deleteSysParamSystemValue: (sysParamSystemValue) => {
            dispatch(deleteSysParamSystemValue(sysParamSystemValue));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SysParamSystemValueDeletionDialog);

SysParamSystemValueDeletionDialog.defaultProps = {
    model: {},
    editableBaseSoftwareVersions: [],
    fetchEditableBaseSwVersions: noop,
    createSysParamSystemValue: noop,
    deleteSysParamSystemValue: noop,
};

SysParamSystemValueDeletionDialog.propTypes = {
    model: PropTypes.object,
    editableBaseSoftwareVersions: PropTypes.array,
    fetchEditableBaseSwVersions: PropTypes.func,
    createSysParamSystemValue: PropTypes.func,
    deleteSysParamSystemValue: PropTypes.func,
};
