import noop from 'lodash/fp/noop';
import size from 'lodash/fp/size';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { fetchControlDeviceWithSysParams } from '~/features/devices/actions/controlDeviceActions';

import {
    showSysParamSystemValueDeletionDialog,
    showSysParamSystemValueEditor,
} from '~/features/sysParams/actions/sysParamSystemValueEditorActions';

import { controlDeviceSelector } from '~/features/devices/selectors/controlDeviceSelectors';
import {
    canCreateSysParamSystemValues,
    canDeleteSysParamSystemValues,
    canUpdateSysParamSystemValues,
} from '~/features/sysParams/selectors/sysParamSystemValueSelectors';
import { sysParamsOfDeviceSelector } from '~/features/sysParams/selectors/sysParamSelectors';

import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import DeviceSysParamsTable from '~/features/devices/components/DeviceSysParamsTable';
import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';

import uid from '~/features/base/utils/uid';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';

/**
 * Container for the device parameters
 * Needs to how definition, defaults (for 4th group), device and planned device (for 3rd and 4th group)
 */
export class DeviceParametersContainer extends PureComponent {

    constructor(props) {
        super(props);
        this.name = uid();
    }

    onEditSysParamSystemValue = (sysParam) => {
        this.props.showSysParamSystemValueEditor({
            isNew: !sysParam.systemValue,
            serialNumber: this.props.serialNumber, // required in case we have no systemValue.hwSerial
            sysParamDefinition: sysParam,
            sysParamDefaultValue: sysParam.defaultValue,
            sysParamSystemValue: sysParam.systemValue,
        });
    };

    onDeleteSysParamSystemValue = (sysParam) => {
        this.props.showSysParamSystemValueDeletionDialog({
            isNew: false,
            serialNumber: this.props.serialNumber,
            sysParamDefinition: sysParam,
            sysParamDefaultValue: sysParam.defaultValue,
            sysParamSystemValue: sysParam.systemValue,
        });
    };

    render() {
        const { controlDevice, sysParams } = this.props;

        if (!controlDevice) {
            return (
                <div
                    className={'display-flex justify-content-center align-items-center margin-25 padding-25'}>
                    <Spinner text={'Loading'}/>
                </div>
            );
        }
        if (!size(sysParams)) {
            return (
                <DefaultWhiteColumn className='padding-top-20 padding-bottom-20'>
                    <NotFoundState fullWidth
                                   headline={<FormattedMessage id='intl-msg:nothingFound'/>}
                                   message={<FormattedMessage id='intl-msg:sysParams.notFound'/>}/>
                </DefaultWhiteColumn>
            );
        }

        return (
            <DefaultWhiteColumn className='padding-top-20 padding-bottom-20'>
                <DeviceSysParamsTable {...this.props}
                                      shortBaseSwVersion={toShortSemanticVersion(
                                          controlDevice.lastBaseSoftwareVersionReported)}
                                      onEditSysParamSystemValue={(definition, defaultValue, systemValue) =>
                                          this.onEditSysParamSystemValue(definition, defaultValue, systemValue)}
                                      onDeleteSysParamSystemValue={(sysParam) =>
                                          this.onDeleteSysParamSystemValue(sysParam)}/>
            </DefaultWhiteColumn>
        );
    }

    componentWillMount() {
        const { serialNumber } = this.props;

        this.props.registerDataInterest(this.name, [
            fetchControlDeviceWithSysParams({ serialNumber }),
            // fetchControlDeviceEligiblePackageVersions({ serialNumber }),
            // fetchControlDeviceEligibleFileVersions({ serialNumber }),
        ]);
        this.props.triggerDataFetcher();
        // this.interval = setInterval(() => {
        //     this.props.triggerDataFetcher();
        // }, 60000);
    }

    componentWillUnmount() {
        this.props.unregisterDataInterest(this.name);
        clearInterval(this.interval);
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        canCreateSysParamSystemValue: canCreateSysParamSystemValues(state),
        canUpdateSysParamSystemValue: canUpdateSysParamSystemValues(state),
        canDeleteSysParamSystemValue: canDeleteSysParamSystemValues(state),
        controlDevice: controlDeviceSelector(state, ownProps),
        sysParams: sysParamsOfDeviceSelector(state, ownProps),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        fetchControlDeviceWithSysParams: payload => {
            dispatch(fetchControlDeviceWithSysParams(payload));
        },
        showSysParamSystemValueEditor: (model) => {
            dispatch(showSysParamSystemValueEditor(model));
        },
        showSysParamSystemValueDeletionDialog: (model) => {
            dispatch(showSysParamSystemValueDeletionDialog(model));
        },
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        triggerDataFetcher: () => {
            dispatch(triggerDataFetcher());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceParametersContainer);

DeviceParametersContainer.defaultProps = {
    // props
    canCreateSysParamSystemValue: false,
    canUpdateSysParamSystemValue: false,
    canDeleteSysParamSystemValue: false,
    serialNumber: '',
    sysParams: [],
    // functions
    fetchControlDeviceWithSysParams: noop,
    showSysParamSystemValueEditor: noop,
    showSysParamSystemValueDeletionDialog: noop,
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    triggerDataFetcher: noop,
};

DeviceParametersContainer.propTypes = {
    // props
    canCreateSysParamSystemValue: PropTypes.bool,
    canUpdateSysParamSystemValue: PropTypes.bool,
    canDeleteSysParamSystemValue: PropTypes.bool,
    serialNumber: PropTypes.string,
    controlDevice: PropTypes.object,
    sysParams: PropTypes.array,
    // functions
    fetchControlDeviceWithSysParams: PropTypes.func,
    showSysParamSystemValueEditor: PropTypes.func,
    showSysParamSystemValueDeletionDialog: PropTypes.func,
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
};
