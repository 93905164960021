export const AUTHENTICATION_SERVICE = 'AUTHENTICATION_SERVICE';
export const DEVICE_MANAGEMENT_SERVICE = 'DEVICE_MANAGEMENT_SERVICE';
export const DEVICE_SYSTEM_STATE_SERVICE = 'DEVICE_SYSTEM_STATE_SERVICE';
export const DELIVERABLE_MANAGEMENT_SERVICE = 'DELIVERABLE_MANAGEMENT_SERVICE';
export const DEVICE_LOG_DOWNLOAD_SERVICE = 'DEVICE_LOG_DOWNLOAD_SERVICE';
export const TBM3_MESSAGE_SENDER_SERVICE = 'TBM3_MESSAGE_SENDER_SERVICE';
export const ATLASSIAN_CONNECTOR = 'ATLASSIAN_CONNECTOR';
export const REPORTS_BILLING_CHARTS_DATA_SERVICE = 'REPORTS_BILLING_CHARTS_DATA_SERVICE';
export const REPORTS_BILLING_CHARTS_DATA_VERSIONS_SERVICE = 'REPORTS_BILLING_CHARTS_DATA_VERSIONS_SERVICE';
export const LOG_SERVICE = 'LOG_SERVICE';
