import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { mergeEntity } from '~/features/higherorder/actions/entityActions';

import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';
import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';

import { doHandleErrorSaga, getHTTP } from '~/features/base/sagas/sagaUtil';

import { ENTITY_VEHICLE_SPARE_PART_NUMBERS_ALL } from '~/features/base/constants/entities';
import { finishedAction } from '~/features/higherorder/transforms/actionTransforms';
import { FETCH_VEHICLE_SPARE_PART_NUMBERS } from '~/features/vehicleSparePartNumbers/actions/vehicleSparePartNumbersActions';

export function* getControlDeviceURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/control-device`;
}

export function* doFetchVehicleSparePartNumbersSaga(action) {
    try {
        const vehicleSparePartNumbersServiceURL = yield call(getControlDeviceURL);
        const response = yield call(getHTTP, `${vehicleSparePartNumbersServiceURL}/spare-part-numbers`);
        yield put(mergeEntity(response.content, { entityName: ENTITY_VEHICLE_SPARE_PART_NUMBERS_ALL }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* fetchVehicleSparePartNumbersSaga() {
    yield takeLatest(FETCH_VEHICLE_SPARE_PART_NUMBERS, doFetchVehicleSparePartNumbersSaga);
}
