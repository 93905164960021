import React from 'react';

import ListTableTS from '~/features/base/components/table/ListTableTS';

import { SystemLog } from '~/api/models/system-log';
import { logFilesColumnDescriptors } from '~/features/deviceLogs/components/logsTable/logfilesColumnDescriptors';
import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';

interface LogFilesTableProps {
    logsData: SystemLog[];
}

const LogFilesTable: React.FC<LogFilesTableProps> = ({ logsData }) => {

    return (
        <DefaultWhiteColumn className='padding-left-50 padding-right-50 padding-bottom-20'>
            <ListTableTS
                className='table  table-striped  '
                items={logsData}
                itemKey='operationId'
                columnDescriptors={logFilesColumnDescriptors}
            />
        </DefaultWhiteColumn>
    );
};

export default LogFilesTable;
