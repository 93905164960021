
enum AdaptOrderStatuses {
    REQUESTED = 'REQUESTED',
    ACCEPTED = 'ACCEPTED',
    IN_PROGRESS = 'IN_PROGRESS',
    GENERATED = 'GENERATED',
    DELIVERED = 'DELIVERED',
    INSTALLATION_STARTED = 'INSTALLATION_STARTED',
    INSTALLATION_COMPLETED = 'INSTALLATION_COMPLETED',
    ERROR = 'ERROR',
    CANCELLED = 'CANCELLED',
    BASYS_COMPLETED = 'BASYS_COMPLETED',
}

enum AdaptOrderStatusesClassname {
    REQUESTED = 'label-info',
    ACCEPTED = 'label-info',
    IN_PROGRESS = 'label-info',
    GENERATED = 'label-info',
    DELIVERED = 'label-info',
    INSTALLATION_STARTED = 'label-info',
    INSTALLATION_COMPLETED = 'label-success',
    ERROR = 'label-danger',
    CANCELLED = 'label-warning',
    BASYS_COMPLETED = 'label-success',
}


const adaptOrderStatusOptions = Object.values(AdaptOrderStatuses).map(status => ({
    id: status,
    label: status.replace(/_/g, ' '),
    value: status,
}));

const AdaptOrderFinalStatuses = [
    AdaptOrderStatuses.INSTALLATION_COMPLETED,
    AdaptOrderStatuses.ERROR,
    AdaptOrderStatuses.CANCELLED,
    AdaptOrderStatuses.BASYS_COMPLETED,
];

enum AdaptOrderTypes {
    AUTOMATIC = 'AUTOMATIC',
    MANUAL = 'MANUAL',
}

enum AdaptOrderTypesClassname {
    AUTOMATIC = 'label-info',
    MANUAL = 'label-warning',
}

const adaptOrderTypesOptions = Object.values(AdaptOrderTypes).map(type => ({
    id: type,
    label: type,
    value: type,
}));

const defaultExport = {
    AdaptOrderStatuses,
    adaptOrderStatusOptions,
    AdaptOrderFinalStatuses,
    AdaptOrderTypes,
    adaptOrderTypesOptions,
    AdaptOrderStatusesClassname,
    AdaptOrderTypesClassname
};


export { AdaptOrderStatuses, adaptOrderStatusOptions, AdaptOrderTypes, AdaptOrderFinalStatuses, adaptOrderTypesOptions, AdaptOrderStatusesClassname, AdaptOrderTypesClassname };
export default defaultExport;
