import React from 'react';
import VehicleVariantsSelect from '~/features/overviewNew/components/VehicleVariantsSelect';
import OverviewFooter from '~/features/overviewNew/components/OverviewFooter';
import AdditionalInfo from '~/features/overviewNew/components/AdditionalInfo';

interface OverviewContainerProps {

}

const OverviewContainer: React.FC<OverviewContainerProps> = () => {

    return (
        <div className="overview">
            <VehicleVariantsSelect/>
            <AdditionalInfo/>
            <OverviewFooter/>
        </div>
    );
};

export default OverviewContainer;
