import { isSemanticVersionZero } from '~/features/base/utils/versionNumberConverter';

// TODO by Norbert: Usually we try to not modify objects but to build new ones
export const filterEmptySoftwareDependencies = compatibility => {
    if (compatibility) {
        if (compatibility.baseSoftwareVersionRange) {
            if (isSemanticVersionZero(compatibility.baseSoftwareVersionRange.minValue)) {
                delete compatibility.baseSoftwareVersionRange['minValue'];
            }
            if (isSemanticVersionZero(compatibility.baseSoftwareVersionRange.maxValue)) {
                delete compatibility.baseSoftwareVersionRange['maxValue'];
            }
            if (!compatibility.baseSoftwareVersionRange.minValue
                && !compatibility.baseSoftwareVersionRange.maxValue) {
                delete compatibility['baseSoftwareVersionRange'];
            }
        }
        if (compatibility.mdmcAppVersionRange) {
            if (isSemanticVersionZero(compatibility.mdmcAppVersionRange.minValue)) {
                delete compatibility.mdmcAppVersionRange['minValue'];
            }
            if (isSemanticVersionZero(compatibility.mdmcAppVersionRange.maxValue)) {
                delete compatibility.mdmcAppVersionRange['maxValue'];
            }
            if (!compatibility.mdmcAppVersionRange.minValue
                && !compatibility.mdmcAppVersionRange.maxValue) {
                delete compatibility['mdmcAppVersionRange'];
            }
        }
    }
};
