/*
 * Action type for fetching system parameters device values
 * @type {string}
 */
export const FETCH_SYS_PARAM_DEVICE_VALUES = 'FETCH_SYS_PARAM_DEVICE_VALUES';

/**
 * Action creator for fetching of system parameter device values
 * @param {object} options The options for which to fetch the device values
 * @param {string} options.serialNumber The serial number of the device
 * @param {string} options.shortBaseSwVersion The base software version for which to fetch the device values
 * @returns {{type: string, payload}} The action for fetching of system parameter device values
 */
export function fetchSysParamDeviceValues({ serialNumber, shortBaseSwVersion }) {
    return {
        type: FETCH_SYS_PARAM_DEVICE_VALUES,
        payload: {
            serialNumber,
            shortBaseSwVersion,
        },
    };
}
