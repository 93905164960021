import { DELIVERABLE_TYPE_APP } from '~/features/deliverables/constants/deliverablesParameters';
import {
    CHANGE_CONTROL_DEVICE_QUICK_WHITELISTING,
    RESET_CONTROL_DEVICE_QUICK_WHITELISTING,
} from '~/features/devices/actions/controlDeviceQuickWhitelistingActions';

const defaultState = {
    serialNumber: '',
    selectedDeliverableType: '',
    selectedDeliverableId: '',
    selectedDeliverableVersion: '',
    selectedDeliverable: {},
    isAlreadyWhitelisted: false,
};

/**
 * Reducer for control device whitelisting
 * @param {object} state The current state
 * @param {object} action The action to be processed
 * @returns {object} The new state
 */
export default function controlDeviceQuickWhitelistingReducer(state = defaultState, action = {}) {
    let newState;
    switch (action.type) {
        case RESET_CONTROL_DEVICE_QUICK_WHITELISTING:
            newState = {
                ...defaultState,
            };
            return newState;
        case CHANGE_CONTROL_DEVICE_QUICK_WHITELISTING:
            newState = {
                ...state,
                ...action.payload,
            };
            return newState;
        default:
            return state;
    }
}
