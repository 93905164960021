import noop from 'lodash/fp/noop';
import split from 'lodash/fp/split';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ARTIFACTS_PATH } from '~/features/base/constants/routes';
import PageHeader from '~/features/base/components/PageHeader';
import DeliverableTypeTabs from '~/features/artifacts/components/DeliverableTypeTabs';
import DeliverableListContainer from '~/features/artifacts/components/DeliverableListContainer';
import { parseQuery } from '~/features/base/utils/query';
import {
    fetchDeliverablesUserInfo,
    fetchFilteredDeliverables,
} from '~/features/artifacts/actions/deliverableActions';
import uid from '~/features/base/utils/uid';
import { FormattedMessage } from 'react-intl';
import { deliverableTypeSelector } from '~/features/artifacts/selectors/deliverableSelectors';
import { connect } from 'react-redux';

/**
 * Component for all artifacts
 */
export class DeliverableDetails extends PureComponent {

    constructor(props) {
        super(props);
        this.name = uid();
    }

    onLoadMore = () => {
        const { pathname, search } = this.props;
        const query = parseQuery(search);
        const newPage = query.page ? Number(query.page) + 1 : 2;
        this.props.followRoute({
            route: pathname, query: {
                ...query,
                page: newPage,
            },
        });
    };

    render() {
        const content = this.renderContent();
        this.registerDataInterest();
        this.props.triggerDataFetcher();
        return (
            <div className='file-details row'>
                <PageHeader pageTitle={<FormattedMessage id='intl-msg:subModuleName.deliverables'/>}/>
                {content}
            </div>
        );
    }

    renderContent() {
        const { deliverableType } = this.props;

        const tabs = this.renderTabs();
        return [
            <div className='col-md-12'>{tabs}</div>,
            <DeliverableListContainer deliverableType={deliverableType} onLoadMore={this.onLoadMore}/>,
        ];
    }

    renderTabs() {
        const { pathname } = this.props;
        const view = split(/[/ ]+/, pathname).pop();
        return (
            <DeliverableTypeTabs
                basePath={`/${ARTIFACTS_PATH}`}
                view={view}/>
        );
    }

    registerDataInterest() {
        const { deliverableType, search } = this.props;
        const parsedQuery = parseQuery(search);
        const { page, context, deliverableNamePrefix } = parsedQuery;
        this.props.registerDataInterest(this.name, [
            fetchFilteredDeliverables({
                page: (page && page > 0) ? (page - 1) : 0,
                searchCriteria: {
                    type: deliverableType,
                    contextList: context ? [context] : [],
                    deliverableNamePrefix,
                },
            }),
            fetchDeliverablesUserInfo(),
        ]);
    }
}

const mapStateToProps = (state) => ({
    deliverableType: deliverableTypeSelector(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DeliverableDetails);

DeliverableDetails.defaultProps = {
    // props
    pathname: '',
    canReadFiles: false,
    canUpdateFiles: false,
    canDeleteFiles: false,
    fileId: '',
    // functions
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    triggerDataFetcher: noop,
    onEditFile: noop,
    onDeleteFile: noop,
};

DeliverableDetails.propTypes = {
    // props
    pathname: PropTypes.string,
    canReadFiles: PropTypes.bool,
    canUpdateFiles: PropTypes.bool,
    canDeleteFiles: PropTypes.bool,
    // functions
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
    onEditFile: PropTypes.func,
    onDeleteFile: PropTypes.func,
};
