import noop from 'lodash/fp/noop';
import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import ContextSuggestFormItem from '~/features/base/components/forms/ContextSuggestFormItem';
import { createContextOptions } from '~/features/base/constants/filterOptions';

import {
    DELIVERABLE_TYPE_APP,
    DELIVERABLE_TYPE_BUNDLE,
    DELIVERABLE_TYPE_CM4G,
    DELIVERABLE_TYPE_DISTRO,
    DELIVERABLE_TYPE_FILE,
} from '~/features/deliverables/constants/deliverablesParameters';

export class DeliverableVersionEditorForm extends PureComponent {

    onDeliverableNameChange = (event) => {
        this.props.changeDeliverableEditor({
            deliverableName: event.target.value,
        });
    };

    onFileSelect = (file) => {
        this.props.changeDeliverableEditor({
            file: file,
        });
    };

    render() {
        const { model } = this.props;
        const deliverableNameInput = this.renderDeliverableNameInput(model);
        const deliverableSelectedFileInput = this.renderDeliverableSelectedFileInput(model);

        return (
            <form>
                {deliverableNameInput}
            </form>
        );
    }

    getDeliverableNamePerType = (deliverableType) => {
        switch (deliverableType) {
            case DELIVERABLE_TYPE_FILE:
                return <FormattedMessage id="intl-msg:fileName"/>;
            case DELIVERABLE_TYPE_APP:
                return <FormattedMessage id="intl-msg:appName"/>;
            case DELIVERABLE_TYPE_DISTRO:
                return <FormattedMessage id="intl-msg:distroName"/>;
            case DELIVERABLE_TYPE_CM4G:
                return <FormattedMessage id="intl-msg:cm4gName"/>;
            case DELIVERABLE_TYPE_BUNDLE:
                return <FormattedMessage id="intl-msg:bundleName"/>;
            default:
                return <FormattedMessage id="intl-msg:fileName"/>;
        }
    };

    renderDeliverableNameInput(model) {
        return (
            <div className='form-group form-group-file-id'>
                <label className='control-label'>
                    {this.getDeliverableNamePerType(this.props.deliverableType)}
                </label>
                <FormattedMessage id='intl-msg:name.prompt'>
                    {placeHolder =>
                        <input type='text' className='form-control'
                               value={model.deliverableName}
                               placeholder={placeHolder}
                               onChange={this.onDeliverableNameChange}/>
                    }
                </FormattedMessage>
            </div>
        );
    }

    renderDeliverableContextInput(model, contexts) {
        const contextOptions = createContextOptions(contexts, false);
        return (
            <div className='form-group form-group-file-id'>
                <ContextSuggestFormItem value={model.context}
                                        options={contextOptions}
                                        onChange={this.onContextNameChange}/>
            </div>
        );
    }

    renderDeliverableSelectedFileInput(model) {
        const fileName = model.file ? model.file[0].name : '';
        return (
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-1'>
                        {/*<FilePicker label={<FormattedMessage id='intl-msg:addFile'/>}*/}
                        {/*            onPick={(acceptedFiles) => this.onFileSelect(acceptedFiles)}/>*/}
                    </div>
                    <div className='col-sm-2'>
                        <label>
                            {fileName}
                        </label>
                    </div>
                </div>
            </div>
        );
    }

}

export default DeliverableVersionEditorForm;

// TODO Obviously it doesn't work without knowledge about model
DeliverableVersionEditorForm.defaultProps = {
    // props
    model: {
        deliverableName: '',
        context: '',
        file: undefined,
    },
    deliverableType: '',
    // functions
    changeDeliverableEditor: noop,
};

DeliverableVersionEditorForm.propTypes = {
    // props
    model: {
        deliverableName: PropTypes.string,
        context: PropTypes.string,
        file: PropTypes.object,
    },
    deliverableType: PropTypes.string,
    // functions
    changeDeliverableEditor: PropTypes.func,
};
