import map from 'lodash/fp/map';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import BarChart from '@rio-cloud/rio-uikit/lib/es/BarChart';
import Bar from '@rio-cloud/rio-uikit/lib/es/Bar';
import Line from '@rio-cloud/rio-uikit/lib/es/Line';
import { DAILY, HOURLY, MONTHLY, WEEKLY } from '~/features/updateEvents/constants/timeIntervals';
import LineChart from '@rio-cloud/rio-uikit/lib/es/LineChart';
import moment from 'moment';

export class UpdateEventsChart extends PureComponent {
    render() {
        const { statData, timeInterval } = this.props;

        const barChartData = map(statItem => {
            let time = '';
            const date = moment(statItem.midDate.valueOf());
            switch (timeInterval) {
                case HOURLY:
                    time = date.format('hh:mm');
                    break;
                case DAILY:
                    time = date.format('dd DD/MM');
                    break;
                case WEEKLY:
                    time = `KW ${date.isoWeek()} - ${date.format('DD/MM')}`;
                    break;
                case MONTHLY:
                    time = date.format('MM/YYYY');
                    break;
                default:
                    time = date.format('hh:mm');
            }
            return {
                time,
                amount: statItem.value,
            };
        }, statData);

        let totalAmount = 0;
        const lineChartData = map(statItem => {
            let time = '';
            const date = moment(statItem.midDate.valueOf());
            switch (timeInterval) {
                case HOURLY:
                    time = date.format('hh:mm');
                    break;
                case DAILY:
                    time = date.format('dd DD/MM');
                    break;
                case WEEKLY:
                    time = `KW ${date.isoWeek()} - ${date.format('DD/MM')}`;
                    break;
                case MONTHLY:
                    time = date.format('MM/YYYY');
                    break;
                default:
                    time = date.format('hh:mm');
            }
            totalAmount += statItem.value;
            return {
                time,
                amount: totalAmount,
            };
        }, statData);

        return (
            <div className='flex padding-top-25 justify-content-center'>
                <div className='padding-20 flex justify-content-center'>
                    <h4><FormattedMessage id='intl-msg:updateEvents'/></h4>
                    <div style={{ height: 500 }}
                        className='panel panel-default panel-body margin-bottom-25'>
                        <BarChart
                            data={barChartData}
                            dataKey='time'
                            xAxisOptions={{ dataKey: 'time' }}
                            showYAxis={true}
                            margin={{ bottom: 15 }}
                            bars={[
                                <Bar
                                    dataKey='amount'
                                    color='color-highlight'
                                    label={{
                                        position: 'top',
                                        formatter: amount => `${amount}`,
                                    }}
                                    name='Update events'
                                />,
                            ]}
                        />
                    </div>
                </div>
                <div className='padding-20'>
                    <h4><FormattedMessage id='intl-msg:trendUpdateEvents.total'/></h4>
                    <div style={{ height: 500 }} className='panel panel-default panel-body'>
                        <LineChart
                            data={lineChartData}
                            dataKey={entry => entry.time}
                            xAxisOptions={{ padding: { left: 30, right: 30 } }}
                            showGrid={true}
                            showYAxis={true}
                            gridOptions={{ vertical: false, strokeDasharray: '0', stroke: 'gray-lighter' }}
                            lines={[
                                <Line key={'time'} name='Total update events'
                                      dataKey={entry => entry.amount}/>,
                            ]}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default injectIntl(UpdateEventsChart);

UpdateEventsChart.defaultProps = {
    // props
    timeInterval: '',
};

UpdateEventsChart.propTypes = {
    // props
    intl: intlShape.isRequired,
    timeInterval: PropTypes.string,
};
