export const TPA = 'TPA';
export const TELE = 'TELE';
export const P0 = 'P0';

export const systemStatePartitionTypes = {
    [TPA]: {
        id: TPA,
        label: 'intl-msg:controlDevice.systemstate.partitionType.tpa',
        tooltip: 'intl-msg:controlDevice.systemstate.partitionType.tpa',
        className: 'label-default',
    },
    [TELE]: {
        id: TELE,
        label: 'intl-msg:controlDevice.systemstate.partitionType.tele',
        tooltip: 'intl-msg:controlDevice.systemstate.partitionType.tele',
        className: 'label-default',
    },
    [P0]: {
        id: P0,
        label: 'P0',
        tooltip: 'P0',
        className: 'label-default',
    },

};
